import { useCallback } from 'react';
import { cohortIndexArrayGenerator } from '../../utils/GlobalUtilsTs';
import { cohortRowDaysGenerator } from '../../utils/CohortTableUtilsTs';

const useCPIRow = (
  cpiData: number | undefined,
  predictedValues: number[] | undefined,
  predictionStartDayIndex: number,
  startingIdx: number,
  numOfCols: number
) => {
  const getCPIRow = useCallback(() => {
    const cpi = cpiData ?? 0;
    const predictedVals = predictedValues ?? [];

    const labelIdxCpi = cohortIndexArrayGenerator(
      predictedVals.length !== 0
        ? predictionStartDayIndex + predictedVals.length
        : numOfCols,
      startingIdx
    );

    if (cpi) {
      const cpiChartData: number[] = Array.from(
        { length: labelIdxCpi.length },
        () => cpi
      );

      const rowDays = cohortRowDaysGenerator(labelIdxCpi, cpiChartData, 3);
      const cpiRow = {
        cohortSize: 0,
        installDate: 'CPI',
        ...rowDays,
      };

      return { cpiChartData, cpiRow };
    } else {
      const cpiChartData: [] = [];

      const cpiRow = {
        cohortSize: 0,
        installDate: 'CPI',
      };
      return { cpiChartData, cpiRow };
    }
  }, [
    cpiData,
    predictedValues,
    numOfCols,
    predictionStartDayIndex,
    startingIdx,
  ]);

  return getCPIRow();
};

export default useCPIRow;
