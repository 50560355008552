import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import { map } from 'lodash';
import { Member } from 'types';
import { ServerQueryKey } from 'types/common';

const getMembers = async (all: boolean) => {
  const {
    data: { members },
  } = await axiosInstance.get(`/organizations/members${all ? '?all=1' : ''}`);
  return members;
};

export const useGetMembers = (all = false, options = {}) => {
  const query = useQuery(
    [ServerQueryKey.OrganizationMembers],
    () => getMembers(all),
    options
  );

  // For select
  const members = map(query.data, (member: Member) => {
    return { label: member.name, value: member.id.toString() };
  });
  return { ...query, data: members };
};
