import { map } from 'lodash';
import { ComparingCampaign } from 'types';

export const getOptions = (arr: number[]) => {
  const defaultOption = { value: 'all', label: 'All' };
  return [
    defaultOption,
    ...map(arr, version => ({
      value: String(version),
      label: String(version),
    })),
  ];
};

export const getCompareOptions = (arr: ComparingCampaign[]) => {
  return map(arr, campaign => ({
    value: `${campaign.id},${campaign.name},${campaign.latestAppVersion}`,
    label: `${campaign.name} - ${campaign.latestAppVersion}`,
  }));
};

export const generateCompareVersionsUrl = ({
  studioId,
  gameId,
  campaignId1,
  campaignId2,
  campaignName1,
  campaignName2,
  appVersion1,
  appVersion2,
}: {
  studioId: string;
  gameId: string;
  campaignId1: string;
  campaignId2: string;
  campaignName1: string;
  campaignName2: string;
  appVersion1: string;
  appVersion2: string;
}) =>
  `/studio/${studioId}/game/${gameId}/compare-versions?campaignId1=${campaignId1}&campaignName1=${campaignName1}&appVersion1=${appVersion1}&campaignId2=${campaignId2}&campaignName2=${campaignName2}&appVersion2=${appVersion2}`;
