import { useFetchMonetizationSteps } from 'api';
import { StyledMonetization } from './Monetization.styles';
import { useParams } from 'react-router-dom';
import CardsList from './CardsList/CardsList';
import MonetizationSkeleton from './MonetizationSkeleton';
import SwitchButton from './SwitchButton';
import { useState } from 'react';
import styled from 'styled-components';

const Monetization: React.FC = () => {
  const [os, setOs] = useState<'android' | 'ios'>('android');
  const { gameId } = useParams<{
    gameId: string;
  }>();
  const {
    data: monetizationSteps,
    isSuccess,
    isLoading,
  } = useFetchMonetizationSteps(Number(gameId), os);

  if (isLoading) {
    return <MonetizationSkeleton />;
  }

  if (isSuccess) {
    return (
      <Container>
        <SwitchButton os={os} setOs={setOs} />
        <StyledMonetization>
          {monetizationSteps.map(monetizationStep => (
            <CardsList monetizationStep={monetizationStep} selectedOs={os} />
          ))}
        </StyledMonetization>
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20;
`;

export default Monetization;
