import styled from 'styled-components';
import SidebarNavList from './SidebarNavList';
import { NavCampaign, NavGame, NavStudio } from 'types';

interface SidebarNavBlockProps {
  countText: string;
  data: NavStudio[] | NavGame[] | NavCampaign[];
  type: 'studio' | 'game' | 'campaign';
}

const SidebarNavBlock = ({ countText, data, type }: SidebarNavBlockProps) => {
  return (
    <Container>
      <Count>{countText}</Count>
      <SidebarNavList data={data} type={type} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Count = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[900]};
  background-color: ${({ theme }) => theme.colors.grey.white};
  position: sticky;
  top: 0;
`;

export default SidebarNavBlock;
