import { useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { StudioInfoName } from 'components/StudioHeader/StudioHeader.style';
import {
  Overlay,
  PageHeaderActionButton,
  PageHeaderContainer,
  PageHeaderInfoContainer,
  Popup,
} from 'components/atoms';
import {
  AddCampaignDescription,
  CampaignDescription,
  CampaignStatusBadge,
  MonetizationBadge,
} from 'components';
import styled, { useTheme } from 'styled-components';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  useCreateCampaign,
  useCreateRetryCampaign,
  useFetchCampaign,
  useGetNavigationCampaigns,
  useUpdateCampaignStatus,
  useFetchUser,
} from 'api';
import {
  AccessFeatures,
  CampaignNetworks,
  CampaignTypes,
  createCamapignReqBody,
  FacebookCampaignStatus,
  FacebookParameters,
  UnityParameters,
  VideoFile,
} from 'types';
import CampaignTerminationModal from './CampaignTerminationModal';
import RestartCampaignModal from 'components/RestartCampaignModal/RestartCampaignModal';
import ScheduleActivationModal from 'components/ScheduleActivationModal/ScheduleActivationModal';
import { hasPermission } from 'utils/user';
import TestPageHeaderSkeleton from './TestPageHeaderSkeleton';

interface CTMParametersProps {
  network: CampaignNetworks;
  parameters: FacebookParameters | UnityParameters;
}

const CTMParameters = ({ network, parameters }: CTMParametersProps) => {
  if (network === CampaignNetworks.facebook) {
    parameters = parameters as FacebookParameters;
    return (
      <>
        <span>Network: Facebook</span>
        <span>
          Country:{' '}
          {!!parameters.countryFullName
            ? parameters.countryFullName
            : parameters.country}
        </span>
        <span>
          Total Budget: ${parameters.dailyBudget * parameters.duration}
        </span>
        <span>Daily Budget: ${parameters.dailyBudget}</span>
        <span>Duration: {parameters.duration} days</span>
      </>
    );
  } else {
    parameters = parameters as UnityParameters;
    return (
      <>
        <span>Network: Unity</span>
        <span>
          Country:{' '}
          {!!parameters.countryFullName
            ? parameters.countryFullName
            : parameters.country}
        </span>
        <span>Total Budget: ${parameters.totalBudget}</span>
        <span>Daily Budget: ${parameters.dailyBudget}</span>
        <span>Bid: ${parameters.bid}</span>
      </>
    );
  }
};

interface TestPageHeaderProps {
  media: VideoFile[];
}

export const TestPageHeader = ({ media }: TestPageHeaderProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams<{
    studioId: string;
    gameId: string;
    campaignId: string;
  }>();
  const studioId = Number(params.studioId);
  const gameId = Number(params.gameId);
  const isNewCampaign = location.pathname.includes('/new-test');
  const campaignId = isNewCampaign ? -1 : Number(params.campaignId);

  const { data: user } = useFetchUser();
  const useFetchCampaignQuery = useFetchCampaign(gameId, campaignId);
  const { data: navCampaignObj } = useGetNavigationCampaigns();

  const { mutate: createCampaign } = useCreateCampaign();
  const { mutate: updateCampaignStatus } = useUpdateCampaignStatus(
    gameId,
    campaignId
  );
  const { mutate: retryCampaign } = useCreateRetryCampaign();
  const firstTestRef = useRef<HTMLDivElement>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showRestartAsNewModal, setShowRestartAsNewModal] = useState(false);
  const [showTerminationModal, setShowTerminationModal] =
    useState<boolean>(false);
  const [showScheduleOptions, setShowScheduleOptions] = useState(false);
  const [scheduleActivationModal, setScheduleActivationModal] =
    useState<boolean>(false);

  const handleScheduleOptions = () =>
    setShowScheduleOptions(!showScheduleOptions);

  const handleScheduleActivationModal = () =>
    setScheduleActivationModal(!scheduleActivationModal);

  /**
   * Temporary Solution - localStorage
   * @todo - move this to client state management
   * **/
  const newCampaignDescription: string =
    localStorage.getItem('testDescription') ?? '';
  const newCampaignType: CampaignTypes =
    localStorage.getItem('testType') === CampaignTypes.ipm
      ? CampaignTypes.ipm
      : CampaignTypes.mvp;

  const hasTerminateCampaignAccess = hasPermission(
    user,
    AccessFeatures.TERMINATE_CAMPAIGN
  );

  const hasStartCampaignAccess = hasPermission(
    user,
    AccessFeatures.START_CAMPAIGN
  );

  const hasCtmCampaignPermission = hasPermission(
    user,
    AccessFeatures.CREATE_CUSTOM_CAMPAIGN
  );

  const hasCTMCampaignActionsPermission = hasPermission(
    user,
    AccessFeatures.CTM_CAMPAIGN_ACTIONS
  );

  if (useFetchCampaignQuery.isLoading) {
    return <TestPageHeaderSkeleton />;
  }

  if (useFetchCampaignQuery.isSuccess) {
    const { data: campaign, refetch: refetchCampaigns } = useFetchCampaignQuery;
    const last = campaign.kpis?.updatedAt;
    const next = campaign.kpis?.willBeUpdatedAt;

    const terminateCampaign = () => {
      setShowTerminationModal(true);
    };
    const handleCampaignChangesSuccess = (text: string) => {
      toast.success(text, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    };
    const cancelCampaign = () => {
      setIsSaving(true);
      updateCampaignStatus(
        { status: FacebookCampaignStatus.canceled },
        {
          onSuccess: () => {
            useFetchCampaignQuery.refetch().then(() => setIsSaving(false));
            handleCampaignChangesSuccess('The test was successfully canceled');
          },
        }
      );
    };

    const startCampaign = () => {
      setIsSaving(true);
      updateCampaignStatus(
        { status: FacebookCampaignStatus.active },
        {
          onSuccess: () => {
            useFetchCampaignQuery.refetch().then(() => setIsSaving(false));
            handleCampaignChangesSuccess('The test was successfully started');
          },
        }
      );
    };

    const createNewCampaign = () => {
      setIsSaving(true);
      const mediaIds: number[] = media.map((video: VideoFile) => video.id);
      const reqBody: createCamapignReqBody = {
        gameId: Number(gameId),
        testType: newCampaignType,
        testingPlatform: CampaignNetworks.facebook,
        mediaIds: mediaIds,
        description: newCampaignDescription,
      };

      /**
       * @desc - this is a temporary solution
       * **/
      localStorage.removeItem('testDescription');
      localStorage.removeItem('testType');

      createCampaign(reqBody, {
        onSuccess: () => {
          useFetchCampaignQuery.refetch().then(() => setIsSaving(false));
          if (!user?.isPublisher && navCampaignObj.campaigns.length === 0) {
            setShowPopup(true);
          } else {
            navigate(`/studio/${studioId}/game/${gameId}`); // navigate to GameLink
          }
        },
        onError: () => {
          setIsSaving(false);
        },
      });
    };

    const restartAsNewCampaign = () => {
      setShowRestartAsNewModal(true);
    };

    const handleRetryCampaign = () => {
      setIsSaving(true);
      const reqBody = {
        id: campaignId,
      };
      retryCampaign(reqBody, {
        onSuccess: () => {
          toast.success('Your request was successfully accepted', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          navigate(`/studio/${studioId}/game/${gameId}`);
        },
        onError: () => {
          toast.error('Something went wrong. Please, try again later.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsSaving(false);
        },
      });
    };

    const getFormattedDate = (date: string) => {
      return moment(date).format('DD.MM.YYYY HH:mm');
    };

    const getUpdateDate = (text: string, date: string) => {
      const isTesting = campaign.status === FacebookCampaignStatus.active;
      const isCompleted = campaign.status === FacebookCampaignStatus.completed;
      const isTerminated =
        campaign.status === FacebookCampaignStatus.terminated;

      if (isTesting || isCompleted || isTerminated) {
        const lastUpdate = getFormattedDate(date);
        return (
          <span>
            {text} update: {lastUpdate} CET / CEST
          </span>
        );
      } else {
      }
      return null;
    };

    const CreateCampaignButton = (
      <PageHeaderActionButton
        buttonText="Request testing"
        bgColor={theme.colors.green[500]}
        disabled={media.length === 0}
        isSaving={isSaving}
        onClick={createNewCampaign}
      />
    );

    const CancelCampaignButton = (
      <PageHeaderActionButton
        buttonText="Cancel test"
        bgColor="#F066A7"
        disabled={false}
        isSaving={isSaving}
        onClick={cancelCampaign}
      />
    );

    const TerminateButton = (
      <PageHeaderActionButton
        buttonText="Cancel test"
        bgColor="#F066A7"
        disabled={false}
        isSaving={isSaving}
        onClick={terminateCampaign}
      />
    );

    const StartCampaignButton = (
      <PageHeaderActionButton
        buttonText="Start test"
        bgColor={theme.colors.green[500]}
        disabled={false}
        isSaving={isSaving}
        onClick={startCampaign}
        styles={
          campaign.status === FacebookCampaignStatus.scheduled
            ? { visibility: showScheduleOptions ? 'visible' : 'hidden' }
            : {}
        }
      />
    );

    const RestartAsNewButton = (
      <PageHeaderActionButton
        buttonText="Restart as new"
        bgColor="rgb(45, 161, 248)"
        disabled={false}
        isSaving={isSaving}
        onClick={restartAsNewCampaign}
      />
    );

    const RetryButton = (
      <PageHeaderActionButton
        buttonText="Retry test"
        styles={{ color: '#F066A7', border: '2px solid #F066A7' }}
        bgColor="white"
        disabled={false}
        isSaving={isSaving}
        onClick={handleRetryCampaign}
      />
    );

    const ScheduledButtonText = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ flex: 1 }}>Scheduled</span>
        {showScheduleOptions ? (
          <MdOutlineKeyboardArrowUp size={20} />
        ) : (
          <MdOutlineKeyboardArrowDown size={20} />
        )}
      </div>
    );

    const ScheduledButton = (
      <PageHeaderActionButton
        buttonText={ScheduledButtonText}
        styles={{
          color: theme.colors.blue[500],
          border: `2px solid ${theme.colors.blue[800]}`,
          padding: '12px',
          boxShadow: showScheduleOptions
            ? '0px 0px 1px rgba(179,179,179, 0.5)'
            : '',
        }}
        bgColor="white"
        disabled={false}
        isSaving={isSaving}
        onClick={handleScheduleOptions}
      />
    );

    const ChangeTimeButton = (
      <PageHeaderActionButton
        buttonText={
          campaign.status === FacebookCampaignStatus.scheduled
            ? 'Change time'
            : 'Set time'
        }
        bgColor={theme.colors.blue[800]}
        disabled={false}
        isSaving={isSaving}
        styles={{ visibility: showScheduleOptions ? 'visible' : 'hidden' }}
        onClick={handleScheduleActivationModal}
      />
    );

    const ScheduledPendingStatusButtonsContainer = (
      <span
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}
      >
        {user?.isPublisher && ScheduledButton}
        {CancelCampaignButton}
        {ChangeTimeButton}
        {hasStartCampaignAccess && StartCampaignButton}
      </span>
    );

    const PendingStatusButtonsContainer = (
      <span
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}
      >
        {user?.isPublisher && StartCampaignButton}
        {CancelCampaignButton}
      </span>
    );

    const closeTerminationModal = () => setShowTerminationModal(false);

    const handleCampaignTermination = (reason?: string) => {
      updateCampaignStatus(
        {
          status: FacebookCampaignStatus.terminated,
          ...(reason && { reason }),
        },
        {
          onSuccess: () => {
            useFetchCampaignQuery.refetch().then(() => setIsSaving(false));
          },
        }
      );
      closeTerminationModal();
    };

    return (
      <>
        <PageHeaderContainer ref={firstTestRef}>
          <PageHeaderInfoContainer>
            <HeaderInfoWithBadge>
              <StudioInfoWrapper>
                <StudioInfoName>
                  {isNewCampaign
                    ? newCampaignType.toUpperCase()
                    : campaign.name}
                </StudioInfoName>
                <div>
                  {campaign.description !== '' ? (
                    <CampaignDescription
                      gameId={gameId}
                      campaignId={campaignId}
                      description={campaign.description}
                      onSuccess={refetchCampaigns}
                    />
                  ) : (
                    <AddCampaignDescription
                      gameId={gameId}
                      campaignId={campaignId}
                      onSuccess={refetchCampaigns}
                    />
                  )}
                </div>
              </StudioInfoWrapper>
              <div style={{ display: 'flex', gap: '10px' }}>
                <CampaignStatusBadge
                  campaignStatus={campaign.status}
                  size="lg"
                />
                {campaign.isMonetized && <MonetizationBadge />}
              </div>
              {campaign.parameters && hasCtmCampaignPermission && (
                <ParametersContainer>
                  <CTMParameters
                    network={campaign.platform}
                    parameters={campaign.parameters}
                  />
                </ParametersContainer>
              )}
            </HeaderInfoWithBadge>
          </PageHeaderInfoContainer>
          {campaign.activeFrom && (
            <DateInfoContainer>
              <Date>Test Date</Date>
              <DateInfo>
                {campaign.activeTo
                  ? moment(campaign.activeFrom).format('DD.MM.YYYY') +
                    ' - ' +
                    moment(campaign.activeTo).format('DD.MM.YYYY')
                  : 'Active since ' +
                    moment(campaign.activeFrom).format('DD.MM.YYYY')}
              </DateInfo>
            </DateInfoContainer>
          )}
          <PageHeaderActionContainer>
            {campaign.type === CampaignTypes.ctm &&
            !hasCTMCampaignActionsPermission ? null : (
              <>
                {campaign.status === FacebookCampaignStatus.active &&
                  hasTerminateCampaignAccess &&
                  TerminateButton}
                {campaign.status === FacebookCampaignStatus.review &&
                  CancelCampaignButton}
                {campaign.status === FacebookCampaignStatus.failed &&
                  RetryButton}
                {(campaign.status === FacebookCampaignStatus.completed ||
                  campaign.status === FacebookCampaignStatus.terminated ||
                  campaign.status === FacebookCampaignStatus.reviewFailed) &&
                  RestartAsNewButton}
                {campaign.status === FacebookCampaignStatus.scheduled &&
                  ScheduledPendingStatusButtonsContainer}
                {campaign.status === FacebookCampaignStatus.pending &&
                  PendingStatusButtonsContainer}
                {campaign.status === FacebookCampaignStatus.new &&
                  CreateCampaignButton}
              </>
            )}

            <UpdateInfo>
              {!!last && getUpdateDate('Last', last)}
              {!!next && getUpdateDate('Next', next)}
            </UpdateInfo>
          </PageHeaderActionContainer>
        </PageHeaderContainer>
        {showRestartAsNewModal && (
          <RestartCampaignModal
            isOpen={showRestartAsNewModal}
            onClose={() => setShowRestartAsNewModal(false)}
          />
        )}
        {showTerminationModal && (
          <CampaignTerminationModal
            isOpen={showTerminationModal}
            onClose={closeTerminationModal}
            onSubmit={handleCampaignTermination}
          />
        )}
        <Popup
          visible={showPopup}
          anchor={firstTestRef}
          onClose={() => {}}
          containerStyle={{
            position: 'absolute',
            top: '25%',
            left: '40%',
            borderRadius: '8px',
            padding: '20px',
            width: '500px',
            height: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <img
            alt={'first test created logo'}
            src={'/assets/celebration.png'}
          />
          <PopupTitle>Congratulations!</PopupTitle>
          <PopupContent>You've created your first test!</PopupContent>
          <PopupContent>
            As of now you don't have to do anything except waiting for your
            publishing manager to review the attached creatives and approve the
            test. When it's approved, the test will take off!
          </PopupContent>
          <Button
            color={theme.colors.pink[500]}
            onClick={() => {
              setShowPopup(false);
              navigate(`/studio/${studioId}/game/${gameId}`);
            }}
          >
            I understand
          </Button>
        </Popup>
        {showPopup && <Overlay></Overlay>}
        {showPopup ? (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={1000}
            recycle={false}
          />
        ) : null}
        {scheduleActivationModal && (
          <ScheduleActivationModal
            isOpen={scheduleActivationModal}
            onClose={handleScheduleActivationModal}
          />
        )}
      </>
    );
  }

  return null;
};

const Button = styled.button`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  background-color: ${props => props.color};
  border-radius: 4px;
  border: 0;
  padding: 20px 24px;
  color: ${({ theme }) => theme.colors.grey.white};
  width: 30%;
`;

export const HeaderInfoWithBadge = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const DateInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageHeaderActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 300px;
`;

const UpdateInfo = styled.div`
  color: ${({ theme }) => theme.colors.grey[900]};
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: -35px;
`;

const Date = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
`;

const DateInfo = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  margin-top: 6px;
`;

const PopupTitle = styled.div`
  font-size: 32px;
  padding: 12px;
  font-weight: bold;
  text-align: center;
`;

const PopupContent = styled.p`
  font-size: 16px;
  opacity: 0.8;
  text-align: center;
`;

const StudioInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ParametersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 5px;
  width: 320px;
  margin-top: 10px;

  color: #8c8c8c;
  background-color: #eeeeee;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 18px;
`;
