import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import {
  AllPaymentDetails,
  FetchStudioResponse,
  IndianPaymentDetails,
  Studio,
  ServerQueryKey,
} from 'types';

const fetchStudio = async (id: number): Promise<Studio> => {
  const {
    data: { data },
  } = await axiosInstance.get<FetchStudioResponse>(`/studios/${id}`);
  return data;
};

export const useFetchStudio = (id: number, options = {}) => {
  return useQuery(
    [ServerQueryKey.StudioDetails, id],
    () => fetchStudio(id),
    options
  );
};

const favoriteStudio = async (id: number) => {
  await axiosInstance.post(`/studios/favorite`, { studioId: id });
};

export const usePostFavoriteStudio = (id: number, options: {}) => {
  return useMutation(() => favoriteStudio(id), options);
};

const unFavoriteStudio = async (id: number) => {
  await axiosInstance.delete(`/studios/favorite`, { data: { studioId: id } });
};

export const useDeleteUnFavoriteStudio = (id: number, options = {}) => {
  return useMutation(() => unFavoriteStudio(id), options);
};

const createStudio = async (reqBody: any) => {
  const { data } = await axiosInstance.post(`/studios/create`, reqBody);
  return data as any;
};

export const useCreateStudio = (options = {}) => {
  return useMutation((data: any) => createStudio(data), options);
};

const updateStudio = async (studioId: number, reqBody: any) => {
  const { data } = await axiosInstance.post(
    `/studios/edit/${studioId}`,
    reqBody
  );
  return data as any;
};

export const useUpdateStudio = (studioId: number, options = {}) => {
  return useMutation((data: any) => updateStudio(studioId, data), options);
};

const createLogo = async (url: string, reqBody: any) => {
  const { data } = await axiosInstance.post(url, reqBody);
  return data;
};

export const useCreateLogo = (url: string) => {
  return useMutation((reqBody: any) => createLogo(url, reqBody));
};

const fetchPaymentDetails = async <
  DataT extends IndianPaymentDetails | AllPaymentDetails
>(
  studioId: number
) => {
  const { data } = await axiosInstance.get(
    `/studios/${studioId}/payment-details`
  );
  return data as DataT;
};

export const useFetchPaymentDetails = <
  DataT extends IndianPaymentDetails | AllPaymentDetails
>(
  studioId: number,
  options = {}
) => {
  return useQuery(
    [ServerQueryKey.StudioPaymentDetails, studioId],
    () => fetchPaymentDetails<DataT>(studioId),
    options
  );
};

const updatePaymentDetails = async <
  PayloadT extends IndianPaymentDetails | AllPaymentDetails
>(
  studioId: number,
  reqBody: PayloadT
) => {
  const { data } = await axiosInstance.post(
    `/studios/${studioId}/payment-details`,
    reqBody
  );
  return data as any;
};

export const useUpdatePaymentDetails = <
  PayloadT extends IndianPaymentDetails | AllPaymentDetails
>(
  studioId: number,
  options = {}
) => {
  return useMutation(
    (data: PayloadT) => updatePaymentDetails<PayloadT>(studioId, data),
    options
  );
};
