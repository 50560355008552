import styled, { css } from 'styled-components';

interface Props {
  big?: boolean;
  url?: string | null;
  size: 'sm' | 'md' | 'lg';
}

export const StyledGameLogo = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[1100]};
  margin-right: 10px;

  &:not(.big) {
    border-radius: 6px;
  }

  &.sm {
    max-width: 36px;
    min-width: 36px;
    min-height: 36px;
    max-height: 36px;
    font-size: 18px;
    line-height: 36px;
  }

  &.md {
    max-width: 48px;
    min-width: 48px;
    min-height: 48px;
    max-height: 48px;
    font-size: 24px;
    line-height: 44px;
  }

  &.lg {
    max-width: 120px;
    min-width: 120px;
    min-height: 120px;
    max-height: 120px;
    border-radius: 16px;
    font-size: 60px;
    line-height: 120px;
  }

  ${(props: Props) =>
    props.url
      ? css`
          background-image: ${(props: Props) => `url(${props.url})`};
          background-size: cover;
        `
      : css`
          background-color: ${({ theme }) => theme.colors.blue[800]};
          color: ${({ theme }) => theme.colors.grey.white};
          font-family: 'Rubik', sans-serif;
          text-align: center;
        `};
`;

interface GameLogoProps {
  size?: 'sm' | 'md' | 'lg';
  url?: string | null;
  name: string;
}

export const GameLogo = ({ size = 'sm', url, name }: GameLogoProps) => {
  return (
    <StyledGameLogo className={size} url={url} size={size}>
      {url ? '' : name.charAt(0)}
    </StyledGameLogo>
  );
};
