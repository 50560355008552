import { css, FlattenSimpleInterpolation } from 'styled-components';

export interface Padding {
  p?: number;
  px?: number;
  pl?: number;
  pr?: number;
  py?: number;
  pt?: number;
  pb?: number;
}

export const padding = (
  padding: Padding,
  baseSpacing: number
): FlattenSimpleInterpolation | undefined => {
  const { p, px, pl, pr, py, pt, pb } = padding;

  const paddingAll =
    p !== undefined
      ? css`
          padding: ${p * baseSpacing}px;
        `
      : undefined;

  const paddingLeft =
    pl !== undefined
      ? css`
          padding-left: ${pl * baseSpacing}px;
        `
      : px !== undefined
      ? css`
          padding-left: ${px * baseSpacing}px;
        `
      : undefined;

  const paddingRight =
    pr !== undefined
      ? css`
          padding-right: ${pr * baseSpacing}px;
        `
      : px !== undefined
      ? css`
          padding-right: ${px * baseSpacing}px;
        `
      : undefined;

  const paddingTop =
    pt !== undefined
      ? css`
          padding-top: ${pt * baseSpacing}px;
        `
      : py !== undefined
      ? css`
          padding-top: ${py * baseSpacing}px;
        `
      : undefined;

  const paddingBottom =
    pb !== undefined
      ? css`
          padding-bottom: ${pb * baseSpacing}px;
        `
      : py !== undefined
      ? css`
          padding-bottom: ${py * baseSpacing}px;
        `
      : undefined;

  const paddings = css`
    ${paddingAll}
    ${paddingLeft}
      ${paddingRight}
      ${paddingTop}
      ${paddingBottom}
  `;

  return paddings;
};
