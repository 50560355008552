import { AllPaymentDetails, IndianPaymentDetails, Member } from 'types';
import { create } from 'zustand';

interface location {
  id: number;
  value: string;
}
export interface StudioDetails {
  studioName: string;
  logo: string;
  location: location;
  contactName: string;
  contactEmail: string;
  contactPhoneNumber: string;
  publishingManager: Member;
  mondayURL: string;
}

interface State {
  studioDetails: StudioDetails;
  paymentDetails: AllPaymentDetails | IndianPaymentDetails;
  isPaymentDetailsSkipped: boolean;
  isPaymentDetailsValid: boolean;
  setStudioDetails: (studioDetails: StudioDetails) => void;
  setPaymentDetails: (
    paymentDetails: AllPaymentDetails | IndianPaymentDetails
  ) => void;
  setIsPaymentDetailsSkipped: (isPaymentDetailsSkipped: boolean) => void;
  setIsPaymentDetailsValid: (isPaymentDetailsValid: boolean) => void;
  setIsStudioDetailsValid: (isStudioDetailsValid: boolean) => void;
  clearStore: () => void;
  isStudioDetailsValid: boolean;
}

// Define the initial state
const initialState = {
  isStudioDetailsValid: false,
  studioDetails: {
    studioName: '',
    logo: '',
    location: {
      id: 0,
      value: '',
    },
    contactName: '',
    contactEmail: '',
    contactPhoneNumber: '',
    publishingManager: {
      id: 0,
      name: '',
    },
    mondayURL: '',
  },
  paymentDetails: {
    companyName: '',
    bankName: '',
    companyAddress: '',
    accountName: '',
    taxId: '',
    swiftBic: '',
    currency: '',
    bankRoutingNumber: '',
    iban: '',
    accountNumber: '',
    invoicePattern: '',
  },
  isPaymentDetailsSkipped: false,
  isPaymentDetailsValid: false,
};

// Define the store
const useStudioWizard = create<State>()(set => ({
  studioDetails: initialState.studioDetails,
  paymentDetails: initialState.paymentDetails,
  isPaymentDetailsSkipped: initialState.isPaymentDetailsSkipped,
  isPaymentDetailsValid: initialState.isPaymentDetailsValid,
  isStudioDetailsValid: initialState.isStudioDetailsValid,
  setStudioDetails: (studioDetails: StudioDetails) =>
    set((state: State) => ({ ...state, studioDetails })),
  setPaymentDetails: (
    paymentDetails: AllPaymentDetails | IndianPaymentDetails
  ) => set((state: State) => ({ ...state, paymentDetails })),
  setIsStudioDetailsValid: (isStudioDetailsValid: boolean) =>
    set((state: State) => ({ ...state, isStudioDetailsValid })),
  setIsPaymentDetailsSkipped: (isPaymentDetailsSkipped: boolean) =>
    set((state: State) => ({ ...state, isPaymentDetailsSkipped })),
  setIsPaymentDetailsValid: (isPaymentDetailsValid: boolean) =>
    set((state: State) => ({ ...state, isPaymentDetailsValid })),
  clearStore: () =>
    set(() => ({
      studioDetails: initialState.studioDetails,
      paymentDetails: initialState.paymentDetails,
      isPaymentDetailsSkipped: initialState.isPaymentDetailsSkipped,
    })),
}));

export default useStudioWizard;
