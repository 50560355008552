import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Market,
  initialAppsData,
  IdeationApp,
  GalleryModalTitle,
  initialGalleryModalTitle,
} from 'types';
import styled, { useTheme } from 'styled-components';
import { SearchInput } from 'components/molecules';
import {
  GamesOverviewWrapper,
  GamesOverviewHeader,
  OverviewHeaderLeft,
  GameCounter,
  PlatformIcon,
} from 'features/GamesOverview/GamesOverview.styles';
import OverviewList, {
  EmptyRow,
  OverviewListRow,
  OverviewDetails,
} from 'components/OverviewList/OverviewList';
import { ImageThumbnail } from 'components/molecules/media/ImageThumbnail';
import { useGetApps } from 'api/modules/useApp';
import moment from 'moment';
import { SidebarNavigationLoader } from 'components/Sidebar/Sidebar.styles';
import IdeationGalleryModal from 'components/organisms/IdeationGalleryModal';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Container } from 'assets/styles';
import { Logo } from 'components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface IdeationContentProps {
  selectedMarket: Market;
  selectedGenre: number;
}

const IdeationContent = ({
  selectedMarket,
  selectedGenre,
}: IdeationContentProps) => {
  // api
  const theme = useTheme();
  const { data, isFetching } = useGetApps(selectedMarket, selectedGenre, {
    initialData: initialAppsData,
    cacheTime: 1 * 30000,
  });
  const { apps, genreName, updatedAt } = data || {};
  const [filteredApps, setFilteredApps] = useState<IdeationApp[]>([]);
  const [appsRow, setAppsRow] = useState<OverviewListRow[]>([EmptyRow]);
  const [appsDetail, setAppsDetail] = useState<OverviewDetails>({});

  const [showGalleryModal, setShowGalleryModal] = useState<boolean>(false);
  const [galleryModalTitle, setGalleryModalTitle] = useState<GalleryModalTitle>(
    initialGalleryModalTitle
  );
  const [galleryModalItems, setGalleryModalItems] = useState<
    ReactImageGalleryItem[]
  >([{ original: '' }]);
  const [galleryModalSelectedItem, setGalleryModalSelectedItem] =
    useState<number>(0);

  const [showDetails, setShowDetails] = useState<number[]>([]);

  const addRowDetails = ({ id }: { id: number }) => {
    setShowDetails(prevState => [...prevState, id]);
  };

  const removeRowDetails = ({ id }: { id: number }) => {
    const filteredDetails = showDetails.filter(x => x !== id);
    setShowDetails(filteredDetails);
  };

  const updateTableData = useCallback(() => {
    const generateModalData = (app: IdeationApp) => {
      const titleData = {
        icon: app.icon,
        name: app.name,
        genre: genreName,
        publisher: app.publisher,
        releaseDate: moment(app.releaseDate).format('DD.MM.YYYY'),
        downloads: Number(app.downloads).toLocaleString(),
      };

      const galleryItems: ReactImageGalleryItem[] = app.screenshots.map(
        screenshot => ({
          original: screenshot,
          thumbnail: screenshot,
        })
      );

      return { titleData, galleryItems };
    };
    const handleImageClick = (app: IdeationApp, screenshotIndex: number) => {
      const { titleData, galleryItems } = generateModalData(app);

      setGalleryModalTitle(titleData);
      setGalleryModalItems(galleryItems);
      setGalleryModalSelectedItem(screenshotIndex);
      setShowGalleryModal(true);
    };
    const newAppsRow = filteredApps.map((app: IdeationApp, idx: number) => ({
      id: idx,
      link: '',
      name: app.name,
      rows: [
        {
          columnId: 'name',
          content: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <Logo src={app.icon} name={app.name} />
              <div
                title={app.name}
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  marginLeft: 12,
                }}
              >
                {app.name}
              </div>
            </div>
          ),
          sortableContent: app.name,
        },
        {
          columnId: 'publisher',
          content: app.publisher,
          sortableContent: app.publisher,
        },
        {
          columnId: 'releaseDate',
          content: app.releaseDate
            ? moment(app.releaseDate).format('DD.MM.YYYY')
            : '-',
          sortableContent: app.releaseDate
            ? moment(app.releaseDate).unix().toString()
            : '',
        },
        {
          columnId: 'downloads',
          content: Number(app.downloads).toLocaleString('en-US'),
          sortableContent: Number(app.downloads),
        },
        {
          columnId: 'storeLinks',
          content: (
            <div
              style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'default',
              }}
              onClick={e => e.stopPropagation()}
            >
              {app.androidStoreUrl ? (
                <a
                  href={app.androidStoreUrl!}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <PlatformIcon src={'/assets/icons/icon_android.svg'} />
                </a>
              ) : null}
              {app.iosStoreUrl ? (
                <a href={app.iosStoreUrl!} target={'_blank'} rel="noreferrer">
                  <PlatformIcon src={'/assets/icons/icon_apple.svg'} />
                </a>
              ) : null}
            </div>
          ),
        },
        {
          columnId: 'showMore',
          content: (
            <>
              {showDetails.indexOf(idx) !== -1 ? (
                <FaChevronUp
                  style={{ marginTop: '5px', cursor: 'pointer' }}
                  color={theme.colors.blue[500]}
                  onClick={() => removeRowDetails({ id: idx })}
                />
              ) : (
                <FaChevronDown
                  style={{ marginTop: '5px', cursor: 'pointer' }}
                  color={theme.colors.blue[500]}
                  onClick={() => addRowDetails({ id: idx })}
                />
              )}
            </>
          ),
        },
      ],
    }));

    let appsDetailObj: OverviewDetails = {};
    filteredApps.forEach((app: IdeationApp, appIndex: number) => {
      let detailJsx;
      if (app.screenshots.length > 0) {
        detailJsx = (
          <ImageThumbnailContainer>
            {app.screenshots.map(
              (screenshot: string, screenshotIndex: number) => (
                <ImageThumbnail
                  thumbnail={screenshot}
                  key={screenshotIndex}
                  onImageClick={() => handleImageClick(app, screenshotIndex)}
                />
              )
            )}
          </ImageThumbnailContainer>
        );
      }
      appsDetailObj[appIndex] = detailJsx;
    });

    setAppsRow(newAppsRow);
    setAppsDetail(appsDetailObj);
  }, [filteredApps, genreName, showDetails]);

  useEffect(() => {
    // update filteredApps in first data load
    if (data) setFilteredApps(apps);
  }, [data, apps]);

  useEffect(() => {
    updateTableData();
  }, [filteredApps, updateTableData]);

  const searchGames = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    const filteredBySearch = apps.filter((app: IdeationApp) => {
      return (
        app.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
        app.publisher
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())
      );
    });
    setFilteredApps(filteredBySearch);
  };

  const Loading = <SidebarNavigationLoader style={{ margin: 'auto' }} />;

  const NoData = (
    <NoDataContainer>
      We are processing your data.
      <br />
      Please comeback in a minute.
    </NoDataContainer>
  );

  return (
    <Container>
      {isFetching ? (
        Loading
      ) : data === null ? (
        NoData
      ) : (
        <GamesOverviewWrapper>
          <GamesOverviewHeader>
            <OverviewHeaderLeft>
              <GameCounter>
                {`${genreName} (${appsRow?.length} game${
                  appsRow?.length > 1 ? 's' : ''
                })`}
              </GameCounter>
              <SearchInput
                sidenav={false}
                onChange={searchGames}
                placeholder="Search Games"
              />
            </OverviewHeaderLeft>
            {!!updatedAt && (
              <UpdateInfo>
                <span>
                  Last update: {moment(updatedAt).format('DD.MM.YYYY HH:mm')}{' '}
                  CET / CEST
                </span>
              </UpdateInfo>
            )}
          </GamesOverviewHeader>
          <OverviewList
            columns={[
              {
                id: 'name',
                name: 'Name',
                width: 400,
                ellipsize: true,
                sortable: true,
              },
              {
                id: 'publisher',
                name: 'Publisher',
                width: 100,
                sortable: true,
              },
              {
                id: 'releaseDate',
                name: 'Release Date',
                width: 150,
                sortable: true,
              },
              {
                id: 'downloads',
                name: 'Downloads',
                width: 150,
                sortable: true,
              },
              { id: 'storeLinks', name: 'Store Link', width: 150 },
              { id: 'showMore', name: '', width: 150 },
            ]}
            blocks={[[...appsRow]]}
            gameCount={appsRow.length}
            detailRows={appsDetail}
            showDetails={showDetails}
          />
          <IdeationGalleryModal
            title={galleryModalTitle}
            open={showGalleryModal}
            onClose={() => setShowGalleryModal(false)}
          >
            <ImageGallery
              items={galleryModalItems}
              startIndex={galleryModalSelectedItem}
              showPlayButton={false}
              showFullscreenButton={false}
              additionalClass="gallery-modal"
            />
          </IdeationGalleryModal>
        </GamesOverviewWrapper>
      )}
    </Container>
  );
};

// styles
const ImageThumbnailContainer = styled.div`
  grid-column: span 6 / auto;
  display: flex;
  flex-wrap: wrap;
`;

const NoDataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 96px;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.grey[900]};
  font-family: 'Rubik', sans-serif;
`;

const UpdateInfo = styled.div`
  color: ${({ theme }) => theme.colors.grey[900]};
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  align-items: center;
`;

export default IdeationContent;
