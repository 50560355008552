import { ReactElement, Fragment } from 'react';
import {
  OverviewListColumnHeader,
  OverviewListHeaderWrapper,
  OverviewListRowItem,
} from './OverviewList.styles';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { SidebarSearchNoResult } from 'components/atoms';
import { useEffect, useState } from 'react';
import { BsSortDownAlt, BsSortDown } from 'react-icons/bs';
import clsx from 'clsx';

export type OverviewListColumnDefinition = {
  id: string;
  name: any;
  width: number;
  columnWidth?: string;
  firstColJustify?: string;
  ellipsize?: boolean;
  sortable?: boolean;
  children?: ReactElement;
};

export type OverviewListRow = {
  id: number;
  link: string;
  rows: OverviewListRowProps[];
  hide?: boolean;
  highlight?: string;
};

export type OverviewListRowProps = {
  columnId: string;
  content: any;
  height?: string;
  sortableContent?: string | number;
  firstColJustify?: string;
  sortCampaignName?: boolean;
  ellipsize?: boolean;
};

export interface OverviewDetails {
  [key: string]: any;
}

export type OverviewListProps = {
  columns: OverviewListColumnDefinition[];
  columnWidth?: OverviewListColumnDefinition['columnWidth'];
  firstColJustify?: OverviewListColumnDefinition['firstColJustify'];
  height?: OverviewListRowProps['height'];
  // blocks: OverviewListRow[][];
  blocks: any;
  detailRows?: OverviewDetails;
  showDetails?: number[];
  gameCount?: number;
  hideColumns?: boolean;
  gridTemplateColumns?: string;
  noDataText?: string;
  overflowX?: boolean;
};

export const EmptyRow: OverviewListRow = {
  id: 0,
  link: '',
  rows: [],
  hide: false,
};

export enum SortOrder {
  'NONE',
  'ASC',
  'DESC',
}

export const SortIcon = ({
  active,
  order,
}: {
  active: Boolean;
  order: SortOrder;
}) => {
  const theme = useTheme();
  if (!active) {
    return <BsSortDownAlt color={theme.colors.grey[900]} />;
  }
  return order === SortOrder.ASC ? (
    <BsSortDownAlt color={theme.colors.blue[500]} />
  ) : (
    <BsSortDown color={theme.colors.blue[500]} />
  );
};

interface TableCustomRowProps {
  values: string[];
  containerStyle?: {};
  isNetwork?: boolean;
}

export const TableCustomRow = ({
  values,
  containerStyle = {},
  isNetwork = false,
}: TableCustomRowProps) => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;

    & > span {
      margin-bottom: 10px;
    }

    & span:first-child {
      font-weight: ${isNetwork ? '700' : 'normal'};
    }

    & span:last-child {
      margin-bottom: 0;
      font-weight: normal;
    }
  `;
  return (
    <Container style={{ ...containerStyle }}>
      {values.map((value: string, idx: number) => (
        <span
          key={idx}
          style={{
            borderTop:
              values.length > 1 && idx === values.length - 1 && isNetwork
                ? '1px solid #979797'
                : '',
          }}
        >
          {value}
        </span>
      ))}
    </Container>
  );
};
const OverviewList = (props: OverviewListProps) => {
  const columnwidthValue = props.columns.find(
    column => column.columnWidth
  )?.columnWidth;
  const firstColumnJustify = props.columns.find(
    column => column.firstColJustify
  )?.firstColJustify;
  const [columnSortIndex, setColumnSortIndex] = useState<number | null>(null);
  const [columnSortOrder, setColumnSortOrder] = useState(SortOrder.NONE);
  const [sortedBlocks, setSortedBlocks] = useState(props.blocks);

  useEffect(() => {
    if (columnSortOrder === SortOrder.NONE || columnSortIndex === null) {
      setSortedBlocks([...props.blocks]);
      return;
    }

    const sorted = [];
    const sortByCampaignName = (a: any, b: any) => {
      const sortableA = a.rows[columnSortIndex!].sortableContent;
      const sortableB = b.rows[columnSortIndex!].sortableContent;

      const stringA = String(sortableA.split('_')[0]);
      const stringB = String(sortableB.split('_')[0]);
      if (stringA < stringB) {
        return -1;
      } else if (stringA > stringB) {
        return 1;
      } else {
        const aNum = parseInt(sortableA.split('_')[1]);
        const bNum = parseInt(sortableB.split('_')[1]);
        return aNum - bNum;
      }
    };

    for (let i = 0; i < props.blocks.length; i++) {
      const rows = props.blocks[i];
      if (rows.length) {
        let sortedRows: Array<any>;
        const sortCampaignName =
          !!rows[0].rows[columnSortIndex!].sortCampaignName;
        if (sortCampaignName) {
          sortedRows = [...rows].sort(sortByCampaignName);
        } else {
          sortedRows = [...rows].sort((a, b) => {
            return typeof a.rows[columnSortIndex!].sortableContent ===
              'string' &&
              typeof a.rows[columnSortIndex!].sortableContent === 'string'
              ? a.rows[columnSortIndex!]
                  .sortableContent!.toString()
                  .localeCompare(
                    b.rows[columnSortIndex!].sortableContent!.toString()
                  )
              : Number(a.rows[columnSortIndex!].sortableContent!) -
                  Number(b.rows[columnSortIndex!].sortableContent!);
          });
        }

        if (columnSortOrder === SortOrder.ASC) {
          sorted.push([...sortedRows]);
        } else {
          sorted.push([...sortedRows.reverse()]);
        }
      }
    }
    setSortedBlocks([...sorted]);
  }, [columnSortIndex, columnSortOrder, props.blocks]);

  const updateColumnSort = ({ index }: { index: number }) => {
    if (index === columnSortIndex && columnSortOrder === SortOrder.DESC) {
      setColumnSortOrder(SortOrder.NONE);
      setColumnSortIndex(null);
    } else if (index !== columnSortIndex) {
      setColumnSortIndex(index);
      setColumnSortOrder(SortOrder.ASC);
    } else {
      setColumnSortOrder(SortOrder.DESC);
    }
  };

  const RowWrapperComponent = ({ row }: { row: OverviewListRow }) => {
    if (row.hide) {
      return null;
    }

    return (
      <Fragment key={`${row.id}_fragment`}>
        <RowWrapper
          columnWidth={columnwidthValue}
          firstColJustify={firstColumnJustify}
          key={`${row.id}_row`}
          className={clsx(
            {
              highlightYellow: !!row.highlight && row.highlight === 'yellow',
            },
            {
              highlightPurple: !!row.highlight && row.highlight === 'purple',
            }
          )}
          to={!!row.link ? row.link : undefined}
          as={!!row.link ? Link : 'div'}
        >
          {row.rows.map((rowData: OverviewListRowProps, rowIndex) => {
            let ellipsize = false;
            props.columns.forEach(column => {
              if (column.id === rowData.columnId) {
                ellipsize = !!column.ellipsize;
              }
            });
            return (
              <OverviewListRowItem
                height={props.height}
                ellipsize={ellipsize}
                key={rowIndex}
                style={{ minWidth: ellipsize ? '150px' : '' }}
              >
                {rowData.content}
              </OverviewListRowItem>
            );
          })}
        </RowWrapper>
        {props.showDetails?.includes(row.id) &&
          props.detailRows?.hasOwnProperty(row.id) &&
          props.detailRows[row.id]}
      </Fragment>
    );
  };

  return (
    <>
      <OverviewListHeaderWrapper
        gridTemplateColumns={
          props.gridTemplateColumns ? props.gridTemplateColumns : undefined
        }
        columnCount={props.columns.length}
        overflowX={props.overflowX}
      >
        {props.gameCount !== 0 && props.hideColumns !== true && (
          <>
            {props.columns.map(
              (column: OverviewListColumnDefinition, columnIndex) => {
                if (!!column.children) {
                  return (
                    <Fragment key={columnIndex}>{column.children}</Fragment>
                  );
                }
                return (
                  <OverviewListColumnHeader
                    sortable={column.sortable}
                    width={column.width}
                    key={columnIndex}
                    onClick={
                      column.sortable
                        ? () => updateColumnSort({ index: columnIndex })
                        : undefined
                    }
                  >
                    {column.sortable ? (
                      <SortedColumn>
                        {column.name}
                        <SortIcon
                          active={columnSortIndex === columnIndex}
                          order={columnSortOrder}
                        />
                      </SortedColumn>
                    ) : (
                      column.name
                    )}
                  </OverviewListColumnHeader>
                );
              }
            )}
          </>
        )}

        {sortedBlocks.map((block: OverviewListRow[], blockIndex: number) => {
          return (
            <Fragment key={blockIndex}>
              {/* Block Start */}
              {block?.map((row: OverviewListRow, rowIndex) => (
                <RowWrapperComponent key={rowIndex} row={row} />
              ))}
              {/* Block End */}

              {/* Block Gap Start */}
              <RowWrapper className="block-gap">
                {props.columns.map((_, columnIndex) => {
                  return (
                    <OverviewListRowItem
                      key={columnIndex}
                    ></OverviewListRowItem>
                  );
                })}
              </RowWrapper>
              {/* Block Gap End */}
            </Fragment>
          );
        })}
      </OverviewListHeaderWrapper>
      {props.gameCount === 0 ? (
        <SidebarSearchNoResult>
          {props.noDataText ? props.noDataText : 'Nothing to show'}
        </SidebarSearchNoResult>
      ) : null}
    </>
  );
};

const RowWrapper = styled.div<{
  columnWidth?: string;
  firstColJustify?: string;
}>`
  display: contents;
  &:not(.highlightYellow, .highlightGreen, .block-gap) {
    & > div {
      background-color: ${({ theme }) => theme.colors.grey.white};
    }
  }

  &.highlightYellow {
    & > div {
      background-color: #fef7e5;
    }
  }

  &.highlightPurple {
    & > div {
      background-color: #e7dbfe;
    }
  }

  &.block-gap {
    & > div {
      background-color: transparent;
    }
  }

  & > div {
    color: ${({ theme }) => theme.colors.grey.black};
    font-size: 16px;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      justify-content: flex-start;
      border-top-left-radius: 8px;
      padding-left: 20px;
      border-bottom-left-radius: 8px;
      width: ${props => (props.columnWidth ? props.columnWidth : '100%')};
      justify-content: ${props =>
        props.firstColJustify ? props.firstColJustify : 'flex-start'};
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 16px;
    }
  }
`;

export const SortedColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
`;

export default OverviewList;
