import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { ServerQueryKey, Status } from 'types';

const fetchStatus = async () => {
  const { data } = await axiosInstance.get<Status>('/status');
  return data;
};

const fetchStatusOptions = {
  refetchInterval: 60000,
  refetchIntervalInBackground: true,
};

export const useFetchStatus = () => {
  return useQuery([ServerQueryKey.Status], fetchStatus, fetchStatusOptions);
};

const deleteNotification = async (id?: number) => {
  const { data } = await axiosInstance.delete(
    !!id ? '/notifications/remove' : '/notifications/remove-all',
    { data: { id } }
  );
  return data;
};

export const useDeleteNotification = () =>
  useMutation((id?: number) => deleteNotification(id));
