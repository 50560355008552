import { useCreateMedia } from 'api';
import { SpinnerContainer } from 'assets/styles';
import { ErrorPopup, Spinner } from 'components/atoms';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { MediaType } from 'types';
import { ProgressBar, ProgressWrapper } from './Thumbnail.styles';
import { useTheme } from 'styled-components';

enum mediaError {
  'dimension' = 1,
  'mimeType',
  'generic',
  'sizeLimit',
}

const uploadFileSizeLimit = 99999999;
const validDimensions = ['1080 x 1350'];

const getErrorPopupTitle = ({ error }: { error: mediaError }) => {
  switch (error) {
    case mediaError.dimension:
      return 'Incorrect video Dimensions';
    case mediaError.sizeLimit:
      return 'File size should not exceed 100MB';

    default:
      return 'Error';
  }
};

interface ProgressThumbnailProps {
  file: File;
  onFileProcessed: ({ file, status }: { file: File; status?: Boolean }) => void;
  gameId: number;
}

const ProgressThumbnail = ({
  file,
  onFileProcessed,
  gameId,
}: ProgressThumbnailProps) => {
  const theme = useTheme();
  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setProgress(percentCompleted);
  };
  const { mutate: uploadMedia } = useCreateMedia(
    MediaType.FACEBOOK_VIDEO,
    onUploadProgress
  );
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<mediaError | null>(null);
  const [videoDimension, setVideoDimension] = useState<string | null>(null);

  const errorPopupRef = useRef<HTMLDivElement>(null);
  const videoValidationRef = useRef<HTMLVideoElement>(null);

  const handleGameChangesSuccess = (text: string) => {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  useEffect(() => {
    // Size validation
    if (file.size > uploadFileSizeLimit) {
      setError(mediaError.sizeLimit);
      return;
    }

    // Dimension validation
    if (!videoDimension) {
      return;
    }
    if (!validDimensions.includes(videoDimension)) {
      setError(mediaError.dimension);
      return;
    }

    const reqBody = new FormData();
    reqBody.append('video', file);
    reqBody.append('gameId', String(gameId));
    uploadMedia(reqBody, {
      onSuccess: () => {
        onFileProcessed({ file, status: true });
        handleGameChangesSuccess('Video was successfully uploaded')
      },
      onError: () => {
        setError(mediaError.dimension);
      },
    });
  }, [file, onFileProcessed, gameId, videoDimension, uploadMedia]);

  useEffect(() => {
    const ref = videoValidationRef?.current;
    const handler = () => {
      setVideoDimension(
        `${videoValidationRef?.current?.videoWidth} x ${videoValidationRef?.current?.videoHeight}`
      );
    };
    ref?.addEventListener('loadedmetadata', handler);
    return () => {
      ref?.removeEventListener('loadedmetadata', handler);
    };
  }, [videoValidationRef]);

  return (
    <ProgressWrapper ref={errorPopupRef}>
      <video
        style={{ display: 'none' }}
        ref={videoValidationRef}
        src={URL.createObjectURL(file)}
      />
      <SpinnerContainer>
        <Spinner size={24} color="#bebebe" />
        <span>Uploading...</span>
      </SpinnerContainer>
      <ProgressBar percentage={progress} />
      {error && (
        <ErrorPopup
          visible={!!error}
          onClose={() => onFileProcessed({ file, status: false })}
          anchor={errorPopupRef}
          title={`${getErrorPopupTitle({
            error: error!,
          })}`}
        >
          {error === mediaError.dimension && (
            <>
              <div style={{ fontSize: '12px', marginTop: '12px' }}>
                Video dimension needs to be{' '}
                <span style={{ color: theme.colors.blue[500] }}>
                  1080 x 1350 px
                </span>
              </div>
            </>
          )}
        </ErrorPopup>
      )}
    </ProgressWrapper>
  );
};

export default ProgressThumbnail;
