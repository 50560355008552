import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { IPM, MediaBenchmark } from 'types';
import { Spacing } from 'assets/styles';
import { StyledMediaBadge } from './MediaBadge.styles';

interface MediaBadgeProps extends Spacing {
  ipm?: IPM;
}

const MediaBadge = ({ ipm, ...spacing }: MediaBadgeProps) =>
  isEmpty(ipm) ? (
    <StyledMediaBadge className="gray" {...spacing}>
      Untested
    </StyledMediaBadge>
  ) : (
    <StyledMediaBadge
      className={clsx(
        { red: ipm.benchmark === MediaBenchmark.low },
        { yellow: ipm.benchmark === MediaBenchmark.medium },
        { green: ipm.benchmark === MediaBenchmark.high }
      )}
      {...spacing}
    >
      IPM: {ipm.value}
    </StyledMediaBadge>
  );

export default MediaBadge;
