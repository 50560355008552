import { useCreateConfigCSV, useFetchGameConfigs } from 'api';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BiDownload } from 'react-icons/bi';
import { GameConfigsContent } from './GameConfigsContent';
import { downloadFile } from 'api/utils';
import { convertConfigsToJSON } from './GameConfigs.utils';
import { GameConfig } from 'types';
import { UseQueryResult } from '@tanstack/react-query';

export const GameConfigs = () => {
  const params = useParams<{ gameId: string }>();
  const gameId = Number(params.gameId);
  const { refetch: refetchConfigs } = useFetchGameConfigs(gameId, {
    enabled: false,
  });
  const { mutate: downloadCSV } = useCreateConfigCSV(gameId);

  const handleDownloadCSV = () => {
    downloadCSV(undefined, {
      onSuccess: (response: any) => {
        const name = response.headers['content-disposition'].split(' ')[1];
        const fileName = name.split('=')[1];
        const blob = new Blob([response.data]);
        downloadFile(blob, fileName);
      },
    });
  };

  const handleDownloadJSON = () => {
    refetchConfigs().then((query: UseQueryResult<GameConfig[]>) => {
      const { data } = query;
      const jsonData = convertConfigsToJSON(data ?? []);

      const filename = `game_id_${gameId}`;
      const jsonBlob = new Blob([JSON.stringify(jsonData)], {
        type: 'application/json',
      });
      downloadFile(jsonBlob, filename);
    });
  };

  return (
    <>
      <Header>
        <ConfigsCounter>configurations</ConfigsCounter>
        <div>
          <DownloadConfigs onClick={handleDownloadCSV}>
            <DownloadIcon size={20} />
            <span>DOWNLOAD CSV</span>
          </DownloadConfigs>
          <DownloadConfigs onClick={handleDownloadJSON}>
            <DownloadIcon size={20} />
            <span>DOWNLOAD JSON</span>
          </DownloadConfigs>
        </div>
      </Header>
      <GameConfigsContent />
    </>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const ConfigsCounter = styled.div`
  color: ${({ theme }) => theme.colors.grey[900]};
  display: block;
  float: left;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px 0;
`;

const DownloadConfigs = styled.span`
  color: ${({ theme }) => theme.colors.blue[500]};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
`;

const DownloadIcon = styled(BiDownload)`
  margin-right: 5px;
`;
