import { SpinnerContainer } from 'assets/styles';
import { Spinner } from 'components/atoms';

const LoadingResults = () => {
  return (
    <section className="results">
      <article className="loading-results">
        <SpinnerContainer>
          <Spinner size={32} />
        </SpinnerContainer>
      </article>
    </section>
  );
};

export default LoadingResults;
