import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import {
  FetchInvoiceComments,
  FetchInvoiceNumberResponse,
  FetchInvoiceReponse,
  InvoiceData,
  InvoiceItem,
  ServerQueryKey,
  UpdateInvoiceReqPayload,
  ValidatePrototypeNameResponse,
} from 'types';

const fetchInvoiceNumber = async (studioId: number) => {
  const { data } = await axiosInstance.get<FetchInvoiceNumberResponse>(
    `/studios/${studioId}/invoice-number`
  );
  return data.invoiceNumber;
};

export const useFetchInvoiceNumber = (studioId: number, options = {}) => {
  return useQuery(
    [ServerQueryKey.InvoiceNumber, studioId],
    () => fetchInvoiceNumber(studioId),
    options
  );
};

const createInvoice = async (studioId: number, reqBody: InvoiceItem[]) => {
  await axiosInstance.post(`/studios/${studioId}/invoice`, {
    items: reqBody,
  });
};

export const useCreateInvoice = (studioId: number) => {
  return useMutation((reqBody: InvoiceItem[]) =>
    createInvoice(studioId, reqBody)
  );
};

const updateInvoice = async (
  studioId: number,
  invoiceId: number,
  reqBody: UpdateInvoiceReqPayload
) => {
  await axiosInstance.put(
    `/studios/${studioId}/invoices/${invoiceId}`,
    reqBody
  );
};

export const useUpdateInvoice = (studioId: number, invoiceId: number) => {
  return useMutation((reqBody: UpdateInvoiceReqPayload) =>
    updateInvoice(studioId, invoiceId, reqBody)
  );
};

const fetchInvoice = async (studioId: number, invoiceId: number) => {
  const { data } = await axiosInstance.get<FetchInvoiceReponse>(
    `/studios/${studioId}/invoices/${invoiceId}`
  );
  return data;
};

export const useFetchInvoice = (
  studioId: number,
  invoiceId: number,
  options = {}
) => {
  return useQuery(
    [ServerQueryKey.InvoiceDetails, studioId, invoiceId],
    () => fetchInvoice(studioId, invoiceId),
    options
  );
};

const validatePrototypeName = async (reqBody: { text: string }) => {
  const { data } = await axiosInstance.post<ValidatePrototypeNameResponse>(
    `/studios/payment-details/validate-invoice-item`,
    reqBody
  );
  return data;
};

export const useValidatePrototypeName = () => {
  return useMutation((reqBody: { text: string }) =>
    validatePrototypeName(reqBody)
  );
};

const uploadOldInvoicePdf = async (reqBody: FormData, studioId: number) => {
  let url = `/studios/${studioId}/old-invoice`;
  return await axiosInstance.post(url, reqBody);
};

export const useUploadOldInvoice = (studioId: number) =>
  useMutation((reqBody: FormData) => uploadOldInvoicePdf(reqBody, studioId));

const fetchInvoices = async (StudioId: number) => {
  const { data } = await axiosInstance.get<InvoiceData>(
    `/studios/${StudioId}/invoices`
  );
  return data;
};

export const useFetchInvoices = (studioId: number) => {
  return useQuery([ServerQueryKey.InvoicesList, studioId], () =>
    fetchInvoices(studioId)
  );
};

export const fetchPDFDownloadData = async (
  studioId: number,
  invoiceId: number
) => {
  const { data, headers } = await axiosInstance.get<any>(
    `/studios/${studioId}/invoices/download/${invoiceId}`,
    { responseType: 'blob' }
  );
  return { data, headers };
};

export const useFetchPDFDownloadData = (
  studioId: number,
  invoiceId: number,
  options = {}
) => {
  return useQuery(
    [ServerQueryKey.InvoicePDFDownloadData, invoiceId],
    () => fetchPDFDownloadData(studioId, invoiceId),
    { cacheTime: 0, ...options }
  );
};

const modifyInvoiceReview = async (
  studioId: number,
  invoiceId: number,
  type: string,
  reqBody: any
) => {
  const { data } = await axiosInstance.post(
    `/studios/${studioId}/invoices/${type}/${invoiceId}`,
    reqBody
  );
  return data as any;
};

export const useModifyInvoiceReview = (
  studioId: number,
  invoiceId: number,
  type: string,
  options = {}
) => {
  return useMutation(
    (data: any) => modifyInvoiceReview(studioId, invoiceId, type, data),
    options
  );
};

const fetchInvoiceComments = async (studioId: number, invoiceId: number) => {
  const { data } = await axiosInstance.get<FetchInvoiceComments>(
    `/studios/${studioId}/invoices/${invoiceId}/comments`
  );
  return data;
};

export const useFetchInvoiceComments = (
  studioId: number,
  invoiceId: number
) => {
  return useQuery([ServerQueryKey.InvoiceComments, studioId, invoiceId], () =>
    fetchInvoiceComments(studioId, invoiceId)
  );
};
