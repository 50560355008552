import styled from 'styled-components';

export const StyledTestCreativeLibrary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;

  .add-button {
    background-color: ${({ theme }) => theme.colors.blue[700]};
    color: ${({ theme }) => theme.colors.blue[500]};
    border: none;
    border-radius: 4px;
    height: 40px;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.grey[1000]};
      border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
      color: ${({ theme }) => theme.colors.grey.white};
      cursor: default;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  @media (max-width: 1799px) {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }

  @media (min-width: 1800px) {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }
`;
