import { ModalTitle } from 'assets/styles';
import {
  GameLogo,
  ModalBackdrop,
  ModalCloseIcon,
  ModalContainer,
  ModalHeader,
  ModalHeaderCloseButton,
  ModalOuterContainer,
} from 'components/atoms';
import { ModalContent } from 'components/atoms/modal/ModalContent';

interface GamesOverviewGalleryModalProps {
  title: {
    ipm: number;
    testsPassed: number;
    gameIcon: string;
    gameName: string;
    studioName: string;
  };
  children: JSX.Element;
  open: boolean;
  onClose: () => void;
}
const GamesOverviewGalleryModal = ({
  title,
  children,
  open,
  onClose,
}: GamesOverviewGalleryModalProps) => {
  const padding = 10;
  return open ? (
    <>
      <ModalBackdrop />
      <ModalOuterContainer>
        <ModalContainer>
          <ModalHeader
            style={{
              padding,
              fontWeight: 'normal',
              alignItems: 'flex-start',
              borderBottom: '2px solid #E0E0E0',
            }}
          >
            <ModalTitle padding={padding}>
              <GameLogo url={title.gameIcon} name={title.gameName} size="md" />
              <div className="app-info-column">
                <span
                  className="top-column"
                  style={{
                    fontWeight: 'bold',
                    textOverflow: 'ellipsis',
                    maxWidth: 200,
                    overflow: 'hidden',
                  }}
                >
                  {title.gameName}
                </span>
                <span className="bottom-column" style={{ color: 'inherit' }}>
                  Studio: {title.studioName}
                </span>
              </div>
              <div className="app-info-column">
                <span className="top-column">
                  {title.ipm ? title.ipm.toFixed(2) : '-'}
                </span>
                <span className="bottom-column">IPM</span>
              </div>
              <div className="app-info-column">
                <span className="top-column">{title.testsPassed}</span>
                <span className="bottom-column">
                  Test{title.testsPassed !== 1 ? 's' : null} completed
                </span>
              </div>
            </ModalTitle>
            <ModalHeaderCloseButton
              style={{ padding, display: 'flex' }}
              onClick={onClose}
            >
              <ModalCloseIcon />
            </ModalHeaderCloseButton>
          </ModalHeader>
          <ModalContent style={{ padding, justifyContent: 'center' }}>
            {children}
          </ModalContent>
        </ModalContainer>
      </ModalOuterContainer>
    </>
  ) : null;
};

export default GamesOverviewGalleryModal;
