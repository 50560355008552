import { RiNotification2Fill } from 'react-icons/ri';
import styled, { useTheme } from 'styled-components';

interface NotificationIconProps {
  notificationsExists: boolean;
}

export const NotificationIcon = ({
  notificationsExists,
}: NotificationIconProps) => {
  const theme = useTheme();
  return (
    <NotificationIconContainer>
      {notificationsExists && <NotificationIndicator></NotificationIndicator>}
      <RiNotification2Fill color={theme.colors.grey.white} size={25} />
    </NotificationIconContainer>
  );
};

const NotificationIconContainer = styled.div`
  position: relative;
  display: flex;
  padding: 10px;
  cursor: pointer;
`;

const NotificationIndicator = styled.div`
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.colors.pink[500]};
  border-radius: 20px;
  padding: 5px;
`;
