import styled from 'styled-components';

interface StyledCheckboxProps {
  accentColor?: string;
  checkboxSize: 'sm' | 'lg';
}

export const StyledCheckbox = styled.input.attrs({
  type: 'checkbox',
})<StyledCheckboxProps>`
  height: ${props => (props.checkboxSize === 'sm' ? '18px' : '28px')};
  width: ${props => (props.checkboxSize === 'sm' ? '18px' : '28px')};
  outline: none;
  cursor: pointer;
  accent-color: ${props => props.accentColor || '#34e360'};
  color: white;
`;
