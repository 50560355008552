import { ModalTitle } from 'assets/styles';
import {
  GameLogo,
  ModalBackdrop,
  ModalCloseIcon,
  ModalContainer,
  ModalHeader,
  ModalHeaderCloseButton,
  ModalOuterContainer,
} from 'components/atoms';
import { ModalContent } from 'components/atoms/modal/ModalContent';
import { GalleryModalTitle } from 'types';

interface IdeationGalleryModalProps {
  children: JSX.Element;
  title: GalleryModalTitle;
  open: boolean;
  onClose: () => void;
}

const IdeationGalleryModal = ({
  children,
  title,
  open,
  onClose,
}: IdeationGalleryModalProps) => {
  const { icon, name, genre, publisher, releaseDate, downloads } = title;
  const padding = 10;

  return open ? (
    <>
      <ModalBackdrop />
      <ModalOuterContainer>
        <ModalContainer>
          <ModalHeader
            style={{
              padding,
              fontWeight: 'normal',
              alignItems: 'flex-start',
              borderBottom: '2px solid #E0E0E0',
            }}
          >
            <ModalTitle padding={padding}>
              <GameLogo url={icon} name={name} size="md" />
              <div className="app-info-column">
                <span className="top-column" style={{ fontWeight: 'bold' }}>
                  {name}
                </span>
                <span className="bottom-column" style={{ color: 'inherit' }}>
                  Genre: {genre}
                </span>
              </div>
              <div className="app-info-column">
                <span className="top-column">{publisher}</span>
                <span className="bottom-column">Publisher</span>
              </div>
              <div className="app-info-column">
                <span className="top-column">{releaseDate}</span>
                <span className="bottom-column">Release Date</span>
              </div>
              <div className="app-info-column">
                <span className="top-column">{downloads}</span>
                <span className="bottom-column">Downloads</span>
              </div>
            </ModalTitle>
            <ModalHeaderCloseButton
              style={{ padding, display: 'flex' }}
              onClick={onClose}
            >
              <ModalCloseIcon />
            </ModalHeaderCloseButton>
          </ModalHeader>
          <ModalContent style={{ padding, justifyContent: 'center' }}>
            {children}
          </ModalContent>
        </ModalContainer>
      </ModalOuterContainer>
    </>
  ) : null;
};

export default IdeationGalleryModal;
