import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.grey.white};
  padding: 20px 40px;
  justify-content: space-between;
  height: 200px;

  .block {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 32px;
    line-height: 38px;
  }

  .dropdown-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }
`;

export const LoadingWrapper = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
