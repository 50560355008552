import { Modal } from 'components/molecules';
import { PrivacyPolicy } from 'components/atoms/PrivacyPolicy';

type PrivacyPolicyModalProps = {
  visible: boolean;
  onClose: () => void;
};

export const PrivacyPolicyModal = ({
  visible,
  onClose,
}: PrivacyPolicyModalProps) => {
  return (
    <>
      {visible ? (
        <Modal
          title={''}
          onClose={onClose}
          containerStyle={{ width: '850px', height: '700px' }}
        >
          <div
            style={{ height: '500px', overflowY: 'scroll' }}
            dangerouslySetInnerHTML={{ __html: PrivacyPolicy }}
          />
        </Modal>
      ) : null}
    </>
  );
};
