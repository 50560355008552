import { useState } from 'react';
import { VideoFile } from 'types';
import { TestPageHeader } from 'components/organisms/TestPageHeader';
import { TestMediaSelection } from 'components/organisms/TestMediaSelection';
import { Container } from 'assets/styles';

const NewTest = () => {
  const [media, setMedia] = useState<VideoFile[]>([]);

  return (
    <Container>
      <TestPageHeader media={media} />
      <TestMediaSelection media={media} setMedia={setMedia} />
    </Container>
  );
};

export default NewTest;
