import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useUpdateGame } from 'api';
import { ServerQueryKey, TagType } from 'types';
import { Modal, TagModifier } from 'components';
import { Button } from 'components/atoms';
import styled from 'styled-components';

interface ModifyTagsModalProps {
  gameId: number;
  gameTags: TagType[];
  onClose: () => void;
}

const handleModifyTagsSuccess = (text: string) => {
  toast.success(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};
const ModifyTagsModal = ({
  gameId,
  gameTags,
  onClose,
}: ModifyTagsModalProps) => {
  const queryClient = useQueryClient();
  const [selectedTags, setSelectedTags] = useState<TagType[]>(gameTags);
  const { mutate: updateGame } = useUpdateGame(gameId);

  const updateGameTags = () => {
    const selectedTagsId = selectedTags.map(tag => tag.id);
    const tagsPayload = { tags: selectedTagsId };

    updateGame(tagsPayload, {
      onSuccess: () => {
        queryClient
          .invalidateQueries([ServerQueryKey.GameDetails, gameId])
          .then(onClose);
        handleModifyTagsSuccess('Tags were successfully modified');
      },
    });
  };

  const handleSelectedTags = (newSelectedTags: TagType[]) =>
    setSelectedTags(newSelectedTags);

  return (
    <Modal title="Modify Tags" isOpen={true} onClose={onClose}>
      <ModifyTagsWrapper>
        <TagModifier
          selectedTags={selectedTags}
          onSelect={handleSelectedTags}
        />
        <Button primary onClick={updateGameTags}>
          Save
        </Button>
      </ModifyTagsWrapper>
    </Modal>
  );
};

export default ModifyTagsModal;

const ModifyTagsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
