import { PageHeaderContainer, PageHeaderInfoContainer } from 'components/atoms';
import {
  DateInfoContainer,
  HeaderInfoWithBadge,
  PageHeaderActionContainer,
} from './TestPageHeader';
import Skeleton from 'components/Skeleton/Skeleton';

const TestPageHeaderSkeleton = () => {
  return (
    <PageHeaderContainer>
      <PageHeaderInfoContainer style={{ gap: 10 }}>
        <HeaderInfoWithBadge>
          <Skeleton height="32px" width="150px" />
        </HeaderInfoWithBadge>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Skeleton height="26px" width="150px" />
          <Skeleton height="26px" width="150px" />
        </div>
        <Skeleton height="60px" width="320px" />
      </PageHeaderInfoContainer>
      <DateInfoContainer style={{ gap: 10 }}>
        <Skeleton height="16px" width="70px" />
        <Skeleton height="18px" width="170px" />
      </DateInfoContainer>
      <PageHeaderActionContainer>
        <Skeleton height="48px" width="170px" />
      </PageHeaderActionContainer>
    </PageHeaderContainer>
  );
};

export default TestPageHeaderSkeleton;
