import styled, { css } from 'styled-components';

interface EmptyLogoSquareProps {
  url?: string | null;
}

export const EmptyLogoSquare = styled.div<EmptyLogoSquareProps>`
  background: #b5b5b5;
  width: 155px;
  height: 155px;
  border-radius: 15px;

  ${(props: EmptyLogoSquareProps) =>
    props.url &&
    css`
      background-image: ${(props: EmptyLogoSquareProps) => `url(${props.url})`};
      background-size: cover;
    `};
`;
