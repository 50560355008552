import moment from 'moment';
import { isEmpty, round } from 'lodash';
import { CampaignDetails, BusinessChartType } from 'types';
import { chartTitleGenerator } from './utils/GlobalUtilsTsx';
import {
  chartDataGenerator,
  getChartTitleAndTooltip,
} from './utils/GlobalUtilsTs';
import {
  ChartContainer,
  ChartInnerContainer,
  ChartNoDataContainer,
  ChartInfoContainer,
} from './Charts.styles';
import { Line } from 'react-chartjs-2';

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index' as 'index',
  },
  plugins: {
    legend: {
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        padding: 20,
      },
    },
  },
  scales: {
    xAxes: {
      offset: true,
      grid: {
        drawBorder: false,
        lineWidth: 0,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
      },
    },
    yAxes: {
      ticks: {
        callback: (label: string | number) => `${round(Number(label))}s`,
      },
    },
  },
};

const lineColors = {
  dayOne: '#57B4F9',
  dayTwo: '#ffc043',
  daySeven: '#8242fc',
};

interface ChartProps {
  campaign: CampaignDetails;
}

const PlayTimeChart = ({ campaign }: ChartProps) => {
  const playTimeData = campaign.kpis?.playTime ?? {};

  const hasPlayTimeData = !isEmpty(playTimeData);
  const dayOneValues = playTimeData.dayOnePlayTime
    ? chartDataGenerator(playTimeData.dayOnePlayTime)
    : [];
  const dayTwoValues = playTimeData.dayTwoPlayTime
    ? chartDataGenerator(playTimeData.dayTwoPlayTime)
    : [];
  const daySevenValues = playTimeData.daySevenPlayTime
    ? chartDataGenerator(playTimeData.daySevenPlayTime)
    : [];

  const labels = Object.keys({...playTimeData.dayOnePlayTime}).map(value =>
    moment(value).format('DD.MM')
  );

  const { title, tooltip } = getChartTitleAndTooltip(
    BusinessChartType.PLAYTIME
  );

  const today = moment(new Date()).format('DD.MM');
  const todayIndex =
    labels.indexOf(today) === -1 ? 1000 : labels.indexOf(today);

  const dottedGenerator = (ctx: any) =>
    ctx.p1DataIndex >= todayIndex ? [6, 6] : [6, 0];

  const getChartData = ({
    label,
    data,
    color,
  }: {
    label: string;
    data: number[];
    color: string;
  }) => {
    return {
      label,
      data,
      borderColor: color,
      pointBorderColor: color,
      pointBackgroundColor: color,
      segment: {
        borderDash: (ctx: any) => dottedGenerator(ctx),
      },
      spanGaps: true,
    };
  };

  const chartDatasets = {
    clip: false,
    labels: labels,
    datasets: [
      ...(dayOneValues.length > 0
        ? [getChartData({ label: 'D1', data: dayOneValues, color: lineColors.dayOne })]
        : []),
      ...(dayTwoValues.length > 0
        ? [getChartData({ label: 'D2', data: dayTwoValues, color: lineColors.dayTwo })]
        : []),
      ...(daySevenValues.length > 0
        ? [getChartData({ label: 'D7', data: daySevenValues, color: lineColors.daySeven })]
        : []),
    ],
  };

  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
      </ChartInfoContainer>
      <ChartInnerContainer>
        {hasPlayTimeData ? null : (
          <ChartNoDataContainer>No Data</ChartNoDataContainer>
        )}
        <Line options={chartOptions} data={chartDatasets} height={400} />
      </ChartInnerContainer>
    </ChartContainer>
  );
};

export default PlayTimeChart;
