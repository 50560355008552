import { Label } from 'components/atoms';
import {
  InputContainer,
  StyledTextInput,
  TopFormElementsWrapper,
} from '../AddStudioModal.styles';
import { renderFormFieldError } from 'utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { allCountriesSchema } from './schema';
import { Controller, useForm } from 'react-hook-form';
import { SelectCurrency } from './SelectCurrency';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useStudioWizard } from 'store';
import { AllPaymentDetails } from 'types';
import { SubmitPaymentDetailsRef } from '../AddStudioModal';

const AllCountriesForm = forwardRef<SubmitPaymentDetailsRef, {}>((_, ref) => {
  const { paymentDetails, setIsPaymentDetailsValid, setPaymentDetails } =
    useStudioWizard(state => state);
  const {
    control,
    trigger,
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(allCountriesSchema) });

  useEffect(() => {
    const allPaymentDetails = paymentDetails as AllPaymentDetails;
    const fields = {
      companyName: allPaymentDetails.companyName,
      bankName: allPaymentDetails.bankName,
      companyAddress: allPaymentDetails.companyAddress,
      accountName: allPaymentDetails.accountName,
      taxId: allPaymentDetails.taxId,
      swiftBic: allPaymentDetails.swiftBic,
      currency: allPaymentDetails.currency,
      bankRoutingNumber: allPaymentDetails.bankRoutingNumber,
      iban: allPaymentDetails.iban,
      invoicePattern: allPaymentDetails.invoicePattern,
      accountNumber: allPaymentDetails.accountNumber,
    };
    Object.entries(fields).forEach(([name, value]) => {
      setValue(name, value);
    });
  }, [paymentDetails, setValue]);

  useEffect(() => {
    setIsPaymentDetailsValid(isValid);
  }, [isValid, setIsPaymentDetailsValid]);

  const submitPaymentDetails = () => {
    setPaymentDetails({
      ...getValues(),
    } as AllPaymentDetails);
  };

  useImperativeHandle(ref, () => ({
    submitPaymentDetails,
  }));

  return (
    <TopFormElementsWrapper style={{ gap: '5px 90px' }}>
      <InputContainer>
        <Label htmlFor="companyName">Company Name *</Label>
        <StyledTextInput
          error={!!errors.companyName}
          {...register('companyName')}
          placeholder="Type the company name"
          onBlur={() => trigger('companyName')}
        />
        {renderFormFieldError(errors, 'companyName')}
      </InputContainer>
      <InputContainer>
        <Label htmlFor="bankName">Bank Name *</Label>
        <StyledTextInput
          error={!!errors.bankName}
          {...register('bankName')}
          placeholder="Type the bank name"
          onBlur={() => trigger('bankName')}
        />
        {renderFormFieldError(errors, 'bankName')}
      </InputContainer>
      <InputContainer>
        <Label htmlFor="companyAddress">Company Address *</Label>
        <StyledTextInput
          error={!!errors.companyAddress}
          {...register('companyAddress')}
          placeholder="Type full company address"
          onBlur={() => trigger('companyAddress')}
        />
        {renderFormFieldError(errors, 'companyAddress')}
      </InputContainer>
      <InputContainer>
        <Label htmlFor="accountName">Account Name *</Label>
        <StyledTextInput
          error={!!errors.accountName}
          {...register('accountName')}
          placeholder="Type the account name"
          onBlur={() => trigger('accountName')}
        />
        {renderFormFieldError(errors, 'accountName')}
      </InputContainer>
      <InputContainer>
        <Label htmlFor="taxId">Tax ID / PAN</Label>
        <StyledTextInput
          error={!!errors.taxId}
          {...register('taxId')}
          placeholder="E.g.: 1234567890"
          onBlur={() => trigger('taxId')}
        />
        {renderFormFieldError(errors, 'taxId')}
      </InputContainer>
      <InputContainer>
        <Label htmlFor="swiftBic">SWIFT / BIC *</Label>
        <StyledTextInput
          error={!!errors.swiftBic}
          {...register('swiftBic')}
          placeholder="E.g.: AAAABBCC123"
          onBlur={() => trigger('swiftBic')}
        />
        {renderFormFieldError(errors, 'swiftBic')}
      </InputContainer>
      <InputContainer>
        <Label style={{ marginBottom: '6px' }} htmlFor="currency">
          Currency *
        </Label>
        <Controller control={control} name="currency" render={SelectCurrency} />
        {renderFormFieldError(errors, 'currency')}
      </InputContainer>
      <InputContainer>
        <Label htmlFor="bankRoutingNumber">Bank Routing Number</Label>
        <StyledTextInput
          error={!!errors.bankRoutingNumber}
          {...register('bankRoutingNumber')}
          placeholder="E.g.: 123454321"
          onBlur={() => trigger('bankRoutingNumber')}
        />
      </InputContainer>
      <InputContainer>
        <Label htmlFor="iban">IBAN</Label>
        <StyledTextInput
          error={!!errors.iban}
          {...register('iban')}
          placeholder="E.g.: DE12 1234 5678 9012 34"
          onBlur={() => trigger('iban')}
        />
        {renderFormFieldError(errors, 'iban')}
      </InputContainer>
      <InputContainer>
        <Label htmlFor="accountNumber">Account Number</Label>
        <StyledTextInput
          error={!!errors.accountNumber}
          {...register('accountNumber')}
          placeholder="E.g.: 053201300023"
          onBlur={() => trigger('accountNumber')}
        />
        {renderFormFieldError(errors, 'accountNumber')}
      </InputContainer>
      <InputContainer style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
        <Label htmlFor="invoicePattern">Invoice Pattern</Label>
        <StyledTextInput
          error={!!errors.invoicePattern}
          {...register('invoicePattern')}
          placeholder="E.g.: SUN###"
          onBlur={() => trigger('invoicePattern')}
        />
        {renderFormFieldError(errors, 'invoicePattern')}
      </InputContainer>
    </TopFormElementsWrapper>
  );
});

export default AllCountriesForm;
