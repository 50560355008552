import {
  ModalBackdrop,
  ModalCloseIcon,
  ModalContainer,
  ModalHeader,
  ModalHeaderCloseButton,
  ModalOuterContainer,
} from 'components/atoms';
import { ModalContent } from 'components/atoms/modal/ModalContent';

interface MediaGalleryModalProps {
  children: JSX.Element;
  open: boolean;
  onClose: () => void;
}

const MediaGalleryModal = ({
  children,
  open,
  onClose,
}: MediaGalleryModalProps) => {
  return open ? (
    <>
      <ModalBackdrop />
      <ModalOuterContainer>
        <ModalContainer>
          <ModalHeader
            style={{
              padding: 10,
              fontWeight: 'normal',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              borderBottom: '2px solid #E0E0E0',
            }}
          >
            <ModalHeaderCloseButton
              style={{ padding: 10, display: 'flex' }}
              onClick={onClose}
            >
              <ModalCloseIcon />
            </ModalHeaderCloseButton>
          </ModalHeader>
          <ModalContent style={{ padding: 10, justifyContent: 'center' }}>
            {children}
          </ModalContent>
        </ModalContainer>
      </ModalOuterContainer>
    </>
  ) : null;
};

export default MediaGalleryModal;
