import { spacing, Spacing } from 'assets/styles';
import styled from 'styled-components';

export const HeaderContainer = styled.header<Spacing>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey.white};
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: stretch;
  ${spacing};
`;

interface HeaderColumnProps extends Spacing {
  minWidth?: number;
  minHeight?: number;
}

export const HeaderColumn = styled.div<HeaderColumnProps>`
  display: flex;
  flex-direction: column;
  ${spacing};

  min-width: ${({ minWidth }) => minWidth && `${minWidth}px;`};
  min-height: ${({ minHeight }) => minHeight && `${minHeight}px;`};

  &.full-width {
    flex-grow: 1;
  }

  &.border-left {
    border-left: 2px solid #efefef;
  }

  &.x-start {
    align-items: flex-start;
  }

  &.x-center {
    align-items: center;
  }

  &.x-end {
    align-items: flex-end;
  }

  &.y-start {
    justify-content: flex-start;
  }

  &.y-center {
    justify-content: center;
  }

  &.y-end {
    justify-content: flex-end;
  }
`;

interface HeaderInnerRowProps extends Spacing {
  minHeight?: number;
}

export const HeaderInnerRow = styled.div<HeaderInnerRowProps>`
  display: flex;
  ${spacing};

  min-height: ${({ minHeight }) => minHeight && `${minHeight}px;`};

  &:not(.y-baseline) {
    align-items: center;
  }

  &.y-baseline {
    align-items: baseline;
  }

  &.x-space {
    justify-content: space-between;
  }

  &.warp {
    flex-wrap: wrap;
  }

  .name {
    font-weight: 600;
    margin: 0;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .archived {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.pink[500]};
    margin-left: 12px;
  }

  .date-icon {
    flex-shrink: 0;
  }

  .date-text {
    font-size: 14px;
    margin-left: 6px;
    white-space: nowrap;
  }

  .section-title {
    text-transform: uppercase;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.grey[600]};
  }
`;
