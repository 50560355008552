import { CSSProperties, FC } from 'react';
import { Popup, PopupAnchor, PopupPosition } from './Popup';
import styled, { useTheme } from 'styled-components';
import { ModalCloseIcon } from './modal/ModalCloseIcon';

interface Props {
  onClose: () => void;
  anchor: PopupAnchor;
  visible?: boolean;
  position?: PopupPosition;
  containerStyle?: CSSProperties;
  title?: string;
  children?: React.ReactNode;
}

const ContentContainer = styled.div`
  padding: 12px 16px 24px;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.pink[500]};
`;

const HeaderClose = styled.div`
  cursor: pointer;
`;

export const ErrorPopup: FC<Props> = ({
  children,
  containerStyle,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Popup
      {...props}
      containerStyle={{
        borderRadius: '4px',
        borderWidth: '2px 2px 2px 12px',
        borderStyle: 'solid',
        borderColor: theme.colors.pink[500],
        padding: 0,
        width: '320px',
        ...containerStyle,
      }}
    >
      <ContentContainer>
        <HeaderContainer>
          <HeaderTitle>{props.title ?? ''}</HeaderTitle>
          <HeaderClose onClick={props.onClose}>
            <ModalCloseIcon style={{ color: theme.colors.grey[900] }} />
          </HeaderClose>
        </HeaderContainer>
        {children}
      </ContentContainer>
    </Popup>
  );
};
