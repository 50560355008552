import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { Market, ServerQueryKey } from 'types';

const getApps = async (market: Market, genreId: number) => {
  const {
    data: { data },
  } = await axiosInstance.get(`/ideation/genres/${market}/apps/${genreId}`);
  return data;
};

export const useGetApps = (market: Market, genreId: number, options = {}) => {
  return useQuery(
    [ServerQueryKey.IdeationApps, market, genreId],
    () => getApps(market, genreId),
    options
  );
};
