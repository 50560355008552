import styled from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GameCounter } from 'features/GamesOverview/GamesOverview.styles';
import { Button, Spinner, VideoContainer } from 'components/atoms';
import { VideoThumbnail } from 'components/molecules';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import {
  VideoUploadProgress,
  validMimeTypes,
  FileProcessedProps,
} from './VideoUploadProgress';
import { DragAndDrop } from 'components/DragAndDrop/DragAndDrop';
import { OverviewListColumnHeader } from 'components/OverviewList/OverviewList.styles';
import {
  SortedColumn,
  SortIcon,
  SortOrder,
} from 'components/OverviewList/OverviewList';
import { useDeleteMedia, useFetchMedias } from 'api/modules/useMedia';
import { useParams } from 'react-router-dom';
import {
  MediaByDateGeneric,
  MediaSortType,
  VideoFile,
  VideoReviewStatus,
} from 'types';
import { SpinnerContainer } from 'assets/styles';

export const getMediaGroupCount = (
  data: MediaByDateGeneric<VideoFile>
): number => {
  let count = 0;
  for (let key in data) {
    if (!!data[key as keyof typeof data]?.length) {
      count++;
    }
  }
  return count;
};

export const MediaOverview = () => {
  const params = useParams<{ gameId: string }>();
  const gameId = Number(params.gameId);
  const useFetchMediasQuery = useFetchMedias(gameId, MediaSortType.IPM, {
    onSuccess: (medias: VideoFile[]) => setVideos(medias),
  });
  const { mutate: deleteMedia, isLoading } = useDeleteMedia(gameId);
  const [videos, setVideos] = useState<VideoFile[]>([]);
  const [sortedVideos, setSortedVideos] = useState<VideoFile[]>([]);
  const [preview, setPreview] = useState<VideoFile | null>(null);
  const [columnSortOrder, setColumnSortOrder] = useState(SortOrder.DESC);
  const [showUntested, setShowUntested] = useState<boolean>(true);
  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const ipmVideos: VideoFile[] = [];
    const untestedVideos: VideoFile[] = [];
    videos.forEach((video: VideoFile) =>
      !!video.ipm ? ipmVideos.push(video) : untestedVideos.push(video)
    );

    if (ipmVideos.length > 0) {
      ipmVideos.sort((a: VideoFile, b: VideoFile) => {
        const first: number = !!a.ipm ? a.ipm.value : 0;
        const second: number = !!b.ipm ? b.ipm?.value : 0;
        return columnSortOrder === SortOrder.ASC
          ? first - second
          : second - first;
      });

      if (showUntested) {
        setSortedVideos([...ipmVideos, ...untestedVideos.reverse()]);
      } else {
        setSortedVideos([...ipmVideos]);
      }
    } else {
      if (showUntested) {
        setSortedVideos([...untestedVideos.reverse()]);
      } else {
        setSortedVideos([]);
      }
    }
  }, [videos, columnSortOrder, showUntested]);

  const getVideosCount = (videos: VideoFile[]) => {
    return videos.length !== 1
      ? `${videos.length} Videos`
      : `${videos.length} Video`;
  };

  const fileProcessed = useCallback(
    ({ file, success }: FileProcessedProps) => {
      if (!success) {
        setFiles(prevState => prevState.filter(f => f.name !== file.name));
        return;
      }
      useFetchMediasQuery.refetch().then(() => {
        setFiles(prevState => prevState.filter(f => f.name !== file.name));
      });
    },
    [useFetchMediasQuery]
  );

  const deleteMediaFile = (mediaId: number) => {
    deleteMedia(mediaId, {
      onSuccess: () => {
        useFetchMediasQuery.refetch();
      },
    });
  };

  const fileHandler = (uploadedFiles: FileList) => {
    let newFiles: File[] = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      if (!files.find(f => f.name === file.name)) {
        newFiles.push(file);
      }
    }

    setFiles(prevState => [...prevState, ...newFiles.reverse()]);
  };

  if (useFetchMediasQuery.isSuccess) {
    return (
      <ContentContainerWithPreview>
        <ContentContainer>
          <ContentHeaderContainer>
            <div
              style={{
                width: 250,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <GameCounter>{getVideosCount(videos)}</GameCounter>
              <OverviewListColumnHeader
                sortable={true}
                width={200}
                onClick={() =>
                  setColumnSortOrder(
                    columnSortOrder === SortOrder.ASC
                      ? SortOrder.DESC
                      : SortOrder.ASC
                  )
                }
              >
                <SortedColumn>
                  IPM
                  <SortIcon active={true} order={columnSortOrder} />
                </SortedColumn>
              </OverviewListColumnHeader>
            </div>
            {!!files.length && (
              <InfoBanner>
                <AiOutlineInfoCircle />
                &nbsp;&nbsp;Please do not refresh the page!
              </InfoBanner>
            )}
            <input
              type="file"
              accept={validMimeTypes.join(',')}
              style={{ display: 'none' }}
              ref={inputRef}
              multiple={true}
              onChange={event => {
                if (event.target.files) {
                  fileHandler(event.target.files);
                  if (inputRef?.current) {
                    inputRef.current.value = '';
                  }
                }
              }}
            />
            <div>
              <Button
                secondary={true}
                onClick={() => setShowUntested(!showUntested)}
              >
                {showUntested ? 'Hide ' : 'Show '} Untested
              </Button>
              <Button
                onClick={() => {
                  inputRef.current?.click();
                }}
              >
                + Upload New
              </Button>
            </div>
          </ContentHeaderContainer>
          {isLoading ? (
            <SpinnerContainer>
              <Spinner size={48} color="#bababa" />
            </SpinnerContainer>
          ) : (
            <DragAndDrop dropHandler={fileHandler}>
              <MediaContainer style={{ minHeight: '230px' }}>
                <MediaContainerInfo>
                  Required Dimensions: 1080 x 1350 pixels
                </MediaContainerInfo>

                {(!!videos?.length || !!files.length) && (
                  <ThumbnailContainer>
                    {files.map(file => {
                      return (
                        <VideoUploadProgress
                          gameId={gameId}
                          file={file}
                          fileProcessed={fileProcessed}
                          key={file.name}
                        />
                      );
                    })}
                    {sortedVideos?.map((video: any) => {
                      const videoName = video.url.split('/').pop() ?? video.url;
                      return (
                        <VideoContainer
                          key={video.id}
                          remove={() => deleteMediaFile(video.id)}
                        >
                          <VideoThumbnail
                            onClick={() => setPreview(video)}
                            thumbnail={video.thumbnail}
                            ipm={video.ipm}
                            hasError={
                              video.reviewStatus ===
                              VideoReviewStatus.reviewFailed
                            }
                          />
                          <VideoTitle
                            title={`${videoName}`}
                          >{`${videoName}`}</VideoTitle>
                        </VideoContainer>
                      );
                    })}
                  </ThumbnailContainer>
                )}
              </MediaContainer>
            </DragAndDrop>
          )}
        </ContentContainer>
        {preview && (
          <PreviewContainer key={preview.id}>
            <VideoPreview controls={true}>
              <source src={preview.url} />
            </VideoPreview>
            <VideoPreviewTitle title={preview.name}>
              {preview.name}
            </VideoPreviewTitle>
          </PreviewContainer>
        )}
      </ContentContainerWithPreview>
    );
  }

  return null;
};

export const ContentContainerWithPreview = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  margin: 42px;
  flex: 1;
`;

export const ContentHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
`;

export const MediaContainer = styled.div`
  padding: 10px;
  border: 2px solid ${({ theme }) => theme.colors.grey[1100]};
  background-color: ${({ theme }) => theme.colors.grey.white};
`;

export const MediaContainerInfo = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-family: 'Rubik', sans-serif;
  color: ${({ theme }) => theme.colors.grey[700]};
  margin-top: 5px;
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px 60px;
`;

export const VideoTitle = styled.div`
  text-align: center;
  width: 138px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey[900]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px;
`;

export const PreviewContainer = styled.div`
  margin: 42px 42px 0 0;
  display: flex;
  flex-direction: column;
`;

export const VideoPreview = styled.video`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.grey.white};
  border-radius: 32px;
  border: 2px solid ${({ theme }) => theme.colors.grey[1100]};
  width: 292px;
  height: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VideoPreviewTitle = styled.div`
  text-align: center;
  width: 292px;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.grey[900]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 24px;
`;

const InfoBanner = styled.div`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.blue[500]};
  color: ${({ theme }) => theme.colors.grey.white};
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

export const MediaCategoryTitle = styled.div`
  color: ${({ theme }) => theme.colors.grey[900]};
  margin-left: 20px;
  font-weight: 600;
`;
