import { CSSProperties } from 'react';
import { TooltipPlacement } from 'types';
import { TooltipWrapper, TooltipTitle } from './Tooltip.styles';

interface TooltipProps {
  title: string;
  children: JSX.Element | string;
  placement?: TooltipPlacement;
  size?: 'xx-sm' | 'x-sm' | 'sm' | 'md';
  offset?: number;
  style?: CSSProperties;
  textAlign?: string;
  titleStyle?: CSSProperties;
}

const Tootltip = ({
  title,
  children,
  placement = 'top',
  size = 'sm',
  offset = 10,
  style,
  titleStyle,
  textAlign= 'justify'
}: TooltipProps) => {
  const convertedOffset = offset > 0 ? `-${offset}px` : '0';

  return (
    <TooltipWrapper style={style}>
      {children}
      <TooltipTitle style={titleStyle} className={`${placement} ${size}`} offset={convertedOffset} textAlign={textAlign}>
        {title}
      </TooltipTitle>
    </TooltipWrapper>
  );
};

export default Tootltip;
