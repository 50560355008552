import { ButtonGroup } from 'assets/styles';
import { Button } from 'components/atoms';
import { CSSProperties } from 'react';

interface TabSelectorProps<T extends string> {
  tabs: readonly T[];
  selectedTab: T;
  onTabSelect: (tab: T) => void;
  containerStyle?: CSSProperties;
}

const TabSelector = <T extends string>({
  tabs,
  selectedTab,
  onTabSelect,
  containerStyle = {},
}: TabSelectorProps<T>) => {
  return (
    <ButtonGroup style={{ ...containerStyle }}>
      {tabs.map(tab => (
        <Button
          key={tab}
          primary={selectedTab === tab}
          onClick={() => onTabSelect(tab)}
        >
          {tab.toUpperCase()}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default TabSelector;
