import { BusinessChartType, PlayTimeChartData } from 'types';
import {
  ChartContainer,
  ChartInfoContainer,
  ChartInnerContainer,
} from '../CompareChart.styles';
import { Line } from 'react-chartjs-2';
import {
  cohortIndexArrayGenerator,
  cohortLabelsGenerator,
  getChartTitleAndTooltip,
} from 'pages/Test/Charts/utils/GlobalUtilsTs';
import { chartTitleGenerator } from 'pages/Test/Charts/utils/GlobalUtilsTsx';
import {
  comparisonColors,
  generateCampaignDatasets,
  lineColors,
} from './PlayTimeCompare.utils';
import { chartOptions } from '../CompareChart.utils';
import { useCampaignAndAppVersionParams } from 'hooks';

interface PlayTimeCompareProps {
  dataOne: PlayTimeChartData;
  dataTwo: PlayTimeChartData;
}

const PlayTimeCompare: React.FC<PlayTimeCompareProps> = ({
  dataOne,
  dataTwo,
}) => {
  const { campaignName1, campaignName2, appVersion1, appVersion2 } =
    useCampaignAndAppVersionParams();

  const datasetsOne = generateCampaignDatasets(
    dataOne,
    lineColors,
    campaignName1,
    appVersion1
  );

  const datasetsTwo = generateCampaignDatasets(
    dataTwo,
    comparisonColors,
    campaignName2,
    appVersion2
  );
  const datasets = [...datasetsOne, ...datasetsTwo];

  const labelsOne = Object.keys(dataOne.dayOnePlayTime || {});
  const labelsTwo = Object.keys(dataTwo.dayOnePlayTime || {});
  const numOfCols = Math.max(labelsOne.length, labelsTwo.length);
  const labels = cohortLabelsGenerator(
    cohortIndexArrayGenerator(numOfCols, 1),
    'Day '
  );

  const { title, tooltip } = getChartTitleAndTooltip(
    BusinessChartType.PLAYTIME
  );
  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
      </ChartInfoContainer>
      <ChartInnerContainer>
        <Line options={chartOptions} data={{ labels, datasets }} height={400} />
      </ChartInnerContainer>
    </ChartContainer>
  );
};

export default PlayTimeCompare;
