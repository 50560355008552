import styled from 'styled-components';

export const HomeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const HomeDateWrapper = styled.div`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.grey.white};
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

export const HomeFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #afafaf;
`;

export const SelectOption = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.pink[500]};
`;

export const DateLabel = styled.span`
  margin: 0 10px;
  display: flex;
  align-items: center;

  > span {
    font-size: 16px;
    font-weight: 300;
  }
`;
