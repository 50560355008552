import { Alert } from 'components/molecules';

interface LoginErrorBoxProps {
  error: string | null;
}

function LoginErrorBox(props: LoginErrorBoxProps) {
  return props.error ? <Alert severity="error">{props.error}</Alert> : null;
}

export default LoginErrorBox;
