import { cohortRowDaysGenerator } from '../utils/CohortTableUtilsTs';
import { cohortIndexArrayGenerator } from '../utils/GlobalUtilsTs';

export const getPredictedRowAndLabelIdx = (
  dataAveragePredictedValues: number[] | undefined,
  predictionStartDayIndex: number,
  startingIdx: number
) => {
  const predictedVals = dataAveragePredictedValues ?? [];
  const nullFilledPredictedVals = [
    ...Array(predictionStartDayIndex).fill(null),
    ...predictedVals,
  ];
  const labelIdx = cohortIndexArrayGenerator(
    predictionStartDayIndex + predictedVals.length,
    startingIdx
  );
  const rowDays = cohortRowDaysGenerator(labelIdx, nullFilledPredictedVals, 3);
  const predictedRow = {
    cohortSize: 0,
    installDate: 'Predicted',
    ...rowDays,
  };

  return { predictedRow, labelIdx };
};
