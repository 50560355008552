import { useCreateMedia } from 'api';
import clsx from 'clsx';
import { DragAndDrop } from 'components/DragAndDrop/DragAndDrop';
import { ChangeEvent, useState } from 'react';
import { AiOutlineUpload } from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TiTimes } from 'react-icons/ti';
import { useParams } from 'react-router-dom';
import { MediaType, PackMediaEnum, PackMediaObjType } from 'types';
import {
  ProgressBar,
  SyledCreativePackUploader,
} from './CreativePackUploader.styles';
import { useTheme } from 'styled-components';

const packToMediaConverter = (packType: PackMediaEnum) => {
  switch (packType) {
    case PackMediaEnum.landscapeVideo:
      return MediaType.UNITY_LANDSCAPE_VIDEO;
    case PackMediaEnum.portraitVideo:
      return MediaType.UNITY_PORTRAIT_VIDEO;
    case PackMediaEnum.image:
      return MediaType.UNITY_IMAGE;
    default:
      return MediaType.UNITY_IMAGE;
  }
};

const is16by9Aspect = (aspectRatio: number) =>
  aspectRatio >= 1.78 && aspectRatio < 1.79;

const is9by16Aspect = (aspectRatio: number) =>
  aspectRatio >= 0.56 && aspectRatio < 0.57;

function checkAspectRatio(videoFile: File, validateFor: '16:9' | '9:16') {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');
    videoElement.src = URL.createObjectURL(videoFile);
    videoElement.addEventListener('loadedmetadata', () => {
      const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
      const roundedAspectRatio = Number(aspectRatio.toFixed(2));
      if (validateFor === '16:9' && is16by9Aspect(roundedAspectRatio))
        resolve('');
      else if (validateFor === '9:16' && is9by16Aspect(roundedAspectRatio))
        resolve('');
      else reject();
    });
    videoElement.addEventListener('error', () => {
      reject(new Error('Error loading video file'));
    });
  });
}

function checkDimension(imageFile: File) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      if (img.height === 800 && img.width === 800) resolve('');
      else reject('');
    };
    img.src = URL.createObjectURL(imageFile);
  });
}

export const validMimeTypes = [
  'video/x-matroska',
  'video/mp4',
  'video/x-msvideo',
  'video/webm',
  'video/quicktime', // mov format
];

enum CurrentView {
  uploadNew,
  uploading,
  uploadComplete,
  error,
}

interface CreativePackUploaderProps {
  uploadType: PackMediaEnum;
  disabled: boolean;
  onMediaChange: (type: string, packMediaId: number) => void;
}

const CreativePackUploader = ({
  uploadType,
  disabled,
  onMediaChange,
}: CreativePackUploaderProps) => {
  const theme = useTheme();
  const params = useParams<{ gameId: string }>();
  const gameId = Number(params.gameId);
  const [currentView, setCurrentView] = useState<CurrentView>(
    CurrentView.uploadNew
  );
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [progress, setProgress] = useState(0);
  const [uploadedMedia, setUploadedMedia] = useState<PackMediaObjType | null>(
    null
  );
  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setProgress(percentCompleted);
  };
  const mediaType = packToMediaConverter(uploadType);
  const { mutate: uploadMedia } = useCreateMedia(mediaType, onUploadProgress);

  const validate = async (file: File) => {
    if (file.size > 99999999) {
      setErrorMsg('File size greater than 100MB');
      return false;
    }

    try {
      if (uploadType === PackMediaEnum.image) {
        await checkDimension(file);
      } else {
        await checkAspectRatio(
          file,
          uploadType === PackMediaEnum.landscapeVideo ? '16:9' : '9:16'
        );
      }
      return true;
    } catch {
      return false;
    }
  };

  const handleUploadedFiles = async (files: FileList) => {
    const isValid = await validate(Object.values(files!)[0]);
    if (!isValid) {
      setErrorMsg('Media is not valid');
      setCurrentView(CurrentView.error);
      return;
    }
    const reqBody = new FormData();
    reqBody.append(
      uploadType === PackMediaEnum.image ? 'image' : 'video',
      Object.values(files!)[0]
    );
    reqBody.append('gameId', String(gameId));

    setCurrentView(CurrentView.uploading);

    uploadMedia(reqBody, {
      onSuccess: (data: any) => {
        const uploaded = {
          mediaId: data.id,
          creativePackName: data.name,
          mediaThumbnail: data.thumbnail,
        };
        setUploadedMedia(uploaded);
        setCurrentView(CurrentView.uploadComplete);
        onMediaChange('PACK_UPLOADED', data.id);
      },
      onError: (err: any) => {
        console.log('ERR', err);
        setCurrentView(CurrentView.error);
        setErrorMsg('Failed to upload Media');
      },
    });
  };

  const onInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    handleUploadedFiles(e.target.files!);
  };

  const handleRemoveFile = () => {
    onMediaChange('PACK_UPLOAD_REMOVE', 0);
    setCurrentView(CurrentView.uploadNew);
  };

  const mediaAcceptType =
    mediaType === MediaType.UNITY_IMAGE ? 'image/*' : validMimeTypes.join(',');

  return (
    <SyledCreativePackUploader
      className={clsx(
        { disabled },
        { pointer: currentView === CurrentView.uploadNew }
      )}
    >
      {currentView === CurrentView.uploadNew && (
        <div className="upload-new">
          <DragAndDrop
            dropHandler={handleUploadedFiles}
            containerStyles={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input
              id={`add_media_${uploadType}`}
              type={'file'}
              accept={mediaAcceptType}
              onChange={onInputChange}
              className="upload-input"
            />
            <label className="input-label" htmlFor={`add_media_${uploadType}`}>
              <AiOutlineUpload size={18} />
              <span className="text">Upload new</span>
              <span className="max-size">Max Size: 100MB</span>
            </label>
          </DragAndDrop>
        </div>
      )}
      {currentView === CurrentView.uploading && (
        <div className="uploading">
          <div className="info">
            <span></span>
            <RiDeleteBinLine size={18} color={theme.colors.pink[500]} />
          </div>
          <ProgressBar percentage={progress} />
        </div>
      )}
      {currentView === CurrentView.uploadComplete && (
        <div className="upload-complete">
          <div className="media-details">
            <img
              src={uploadedMedia?.mediaThumbnail}
              alt=""
              height={28}
              width={28}
            />
            <span className="file-name" title={uploadedMedia?.creativePackName}>
              {uploadedMedia?.creativePackName}
            </span>
          </div>
          <TiTimes
            size={18}
            color={theme.colors.pink[500]}
            onClick={handleRemoveFile}
          />
        </div>
      )}
      {currentView === CurrentView.error && (
        <div className="error">
          <span>{errorMsg}</span>
          <TiTimes
            size={18}
            color={theme.colors.pink[500]}
            onClick={handleRemoveFile}
          />
        </div>
      )}
    </SyledCreativePackUploader>
  );
};

export default CreativePackUploader;
