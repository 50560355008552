import styled, { css } from 'styled-components';

interface ImageThumbnailProps {
  thumbnail: string;
  onImageClick?: () => void;
}

export const ImageThumbnail = ({
  thumbnail,
  onImageClick,
}: ImageThumbnailProps) => {
  return <StyledImageThumbnail thumbnail={thumbnail} onClick={onImageClick} />;
};

export const StyledImageThumbnail = styled.div<ImageThumbnailProps>`
  position: relative;
  width: 138px;
  height: 204px;
  border-radius: 16px;
  margin: 7px;
  cursor: pointer;
  ${(props: ImageThumbnailProps) =>
    props.thumbnail &&
    css`
      background-image: ${(props: ImageThumbnailProps) =>
        `url(${props.thumbnail})`};
      background-size: cover;
      background-position: center center;
    `};
`;
