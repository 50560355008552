import styled from 'styled-components';

interface Props {
  error: boolean;
}

export const TextArea = styled.textarea<Props>`
  border: ${props =>
    props.error
      ? `1px solid ${props.theme.colors.pink[500]}`
      : `1px solid ${props.theme.colors.grey[900]}`};
  outline-color: ${props =>
    props.error ? props.theme.colors.pink[500] : props.theme.colors.grey[900]};
  border-radius: 4px;
  padding: 20px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 19px;
  margin: 5px 0;
  width: 100%;
  resize: none;

  ::placeholder {
    color: #b5b5b5;
  }
`;
