import styled from 'styled-components';

interface StyledActionButtonProps {
  width?: string;
  height?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
}

export const StyledActionButton = styled.button<StyledActionButtonProps>`
  border: 2px solid ${({ theme }) => theme.colors.blue[500]};
  border-radius: 4px;
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  color: ${({ theme }) => theme.colors.blue[500]};
  background: ${({ theme }) => theme.colors.grey.white};
  font-family: 'Rubik';

  width: ${props => (props.width ? props.width : '195px')};
  height: ${props => (props.width ? props.height : '48px')};
  font-size: ${props => (props.width ? props.fontSize : '16px')};
  line-height: ${props => (props.width ? props.lineHeight : '19px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};

  &:hover {
    background: ${({ theme }) => theme.colors.blue[500]} 0% 0% no-repeat
      padding-box;
    color: ${({ theme }) => theme.colors.grey.white};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
    color: ${({ theme }) => theme.colors.grey.white};
    cursor: default;
  }
`;
