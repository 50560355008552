import {
  InvoiceStatus,
  UserRole,
  AccessFeatures,
  InvoiceItem,
  CommentsStatus,
  FullAccessRoles,
} from 'types';
import { OverviewListRow } from 'components/OverviewList/OverviewList';
import { StyledAgreementCell } from './InvoicesOverview.styles';
import { commentGrey, commentRead, commentUnread } from 'assets/images';

const getCommentsAction = (
  invoiceStatus: InvoiceStatus,
  userRole: UserRole,
  commentStatus: CommentsStatus
) => {
  if (FullAccessRoles.includes(userRole)) {
    if (invoiceStatus === InvoiceStatus.imported) return undefined;
    else {
      if (commentStatus === CommentsStatus.NONE) return commentGrey;
      else return commentRead;
    }
  } else {
    if (invoiceStatus === InvoiceStatus.imported) return undefined;
    else {
      if (commentStatus === CommentsStatus.READ) return commentRead;
      else if (commentStatus === CommentsStatus.UNREAD) return commentUnread;
      else return commentGrey;
    }
  }
};

export function getActions(
  status: InvoiceStatus,
  userRole: UserRole,
  commentStatus: CommentsStatus
) {
  const commentIcon = getCommentsAction(status, userRole, commentStatus);

  let actions;

  switch (status) {
    case InvoiceStatus.imported:
      actions = {
        [AccessFeatures.EDIT_INVOICE]: false,
        [AccessFeatures.DOWNLOAD_INVOICE]: [
          UserRole.EXTERNAL,
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.ARCHIVE_INVOICE]: false,
        [AccessFeatures.DELETE_INVOICE]: [
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.COMMENT_INVOICE]: commentIcon,
      };
      break;
    case InvoiceStatus.open:
      actions = {
        [AccessFeatures.EDIT_INVOICE]: [
          UserRole.EXTERNAL,
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.DOWNLOAD_INVOICE]: [
          UserRole.EXTERNAL,
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.ARCHIVE_INVOICE]: [
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.DELETE_INVOICE]: false,
        [AccessFeatures.COMMENT_INVOICE]: commentIcon,
      };
      break;
    case InvoiceStatus.approved:
      actions = {
        [AccessFeatures.EDIT_INVOICE]: false,
        [AccessFeatures.DOWNLOAD_INVOICE]: [
          UserRole.EXTERNAL,
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.ARCHIVE_INVOICE]: [
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.DELETE_INVOICE]: false,
        [AccessFeatures.COMMENT_INVOICE]: commentIcon,
      };
      break;
    case InvoiceStatus.editRequired:
      actions = {
        [AccessFeatures.EDIT_INVOICE]: [
          UserRole.EXTERNAL,
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.DOWNLOAD_INVOICE]: [
          UserRole.EXTERNAL,
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.ARCHIVE_INVOICE]: [
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.DELETE_INVOICE]: false,
        [AccessFeatures.COMMENT_INVOICE]: commentIcon,
      };
      break;
    case InvoiceStatus.archived:
      actions = {
        [AccessFeatures.EDIT_INVOICE]: false,
        [AccessFeatures.DOWNLOAD_INVOICE]: [
          UserRole.EXTERNAL,
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.ARCHIVE_INVOICE]: [
          UserRole.INTERNAL,
          UserRole.DEVELOPER,
        ].includes(userRole),
        [AccessFeatures.DELETE_INVOICE]: false,
        [AccessFeatures.COMMENT_INVOICE]: commentIcon,
      };
      break;
    default:
      actions = {
        [AccessFeatures.EDIT_INVOICE]: false,
        [AccessFeatures.DOWNLOAD_INVOICE]: false,
        [AccessFeatures.ARCHIVE_INVOICE]: false,
        [AccessFeatures.DELETE_INVOICE]: false,
        [AccessFeatures.COMMENT_INVOICE]: undefined,
      };
  }

  return actions;
}

interface AgreementCellProps {
  prototypeName: string;
  agreementName: string;
}
const AgreementCell = ({
  prototypeName,
  agreementName,
}: AgreementCellProps) => {
  return (
    <StyledAgreementCell>
      <span className="title">{prototypeName}</span>
      <span className="subtitle">{agreementName}</span>
    </StyledAgreementCell>
  );
};

export const invoiceReviewRows = (
  invoiceItems: InvoiceItem[],
  currency: string
): OverviewListRow[] => {
  return invoiceItems.map((invoiceItem: InvoiceItem) => ({
    id: 0,
    link: '',
    rows: [
      {
        columnId: 'agreement',
        content: (
          <AgreementCell
            prototypeName={invoiceItem.prototypeName}
            agreementName={invoiceItem.agreementName}
          />
        ),
      },
      {
        columnId: 'quantity',
        content: <span style={{ maxWidth: 150 }}>{invoiceItem.quantity}</span>,
      },
      {
        columnId: 'price',
        content: (
          <span
            style={{ maxWidth: 150 }}
          >{`${currency}${invoiceItem.price.toLocaleString('en-US')}`}</span>
        ),
      },
      {
        columnId: 'sum',
        content: (
          <span style={{ maxWidth: 150 }}>{`${currency}${(
            invoiceItem.price * invoiceItem.quantity
          ).toLocaleString('en-US')}`}</span>
        ),
      },
    ],
  }));
};
