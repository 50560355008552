import { yupResolver } from '@hookform/resolvers/yup';
import { useValidatePrototypeName } from 'api';
import { InputContainer } from 'components/AddStudioModal/AddStudioModal.styles';
import { Button, Label, TextInput } from 'components/atoms';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { InvoiceItem, ValidatePrototypeNameResponse } from 'types';
import * as Yup from 'yup';
import FeedbackModal from '../FeedbackModal';
import { useState } from 'react';
import { renderFormFieldError } from 'utils';

interface AddItemsFormProps {
  addItem: (item: InvoiceItem) => void;
}

const AddItemsForm = ({ addItem }: AddItemsFormProps) => {
  const { mutate: validatePrototypeName } = useValidatePrototypeName();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [explicitWords, setExplicitWords] = useState<Array<string>>([]);

  const handleFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };

  const onValidateComplete = (
    response: ValidatePrototypeNameResponse,
    fieldValues: FieldValues
  ) => {
    if (response.safe) {
      const invoiceItem: InvoiceItem = {
        prototypeName: fieldValues.prototypeName,
        agreementName: fieldValues.agreementName,
        quantity: fieldValues.quantity,
        price: fieldValues.price,
      };
      reset({
        prototypeName: '',
        agreementName: '',
        quantity: '',
        price: '',
      });
      addItem(invoiceItem);
      return;
    }
    setExplicitWords(response.explicitWords!);
    handleFeedbackModal();
  };

  const submitItem = (fieldValues: FieldValues) => {
    const payload = { text: fieldValues.prototypeName };
    validatePrototypeName(payload, {
      onSuccess: (data: ValidatePrototypeNameResponse) =>
        onValidateComplete(data, fieldValues),
    });
  };

  const validationSchema = Yup.object().shape({
    prototypeName: Yup.string()
      .required('Prototype Name is a required field')
      .max(200, 'Prototype Name cannot exceed 200 characters'),
    agreementName: Yup.string()
      .required('Agreement Name is a required field')
      .max(200, 'Agreement Name cannot exceed 200 characters'),
    quantity: Yup.number()
      .typeError('Quantity must be a number')
      .required('Quantity is a required field')
      .min(1, 'Quantity must be greater than zero')
      .integer('Quantity must be an integer'),
    price: Yup.number()
      .typeError('Price must be a number')
      .required('Price is a required field')
      .positive('Price must be greater than zero')
      .test(
        'decimal-places',
        'Price must have exactly 2 decimal places',
        (value: any) => /^\d+(\.\d{1,2})?$/.test(value)
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) });

  return (
    <>
      <Form>
        <Grid>
          <InputContainer style={{ gridColumn: '1/5' }}>
            <Label htmlFor="">Item Name *</Label>
            <TextInput
              defaultValue={''}
              {...register('prototypeName')}
              placeholder="Type something ..."
            />
            {renderFormFieldError(errors, 'prototypeName')}
          </InputContainer>
          <InputContainer style={{ gridColumn: '1/3' }}>
            <Label htmlFor="">Agreement Name *</Label>
            <TextInput
              defaultValue={''}
              {...register('agreementName')}
              placeholder="Type something ..."
            />
            {renderFormFieldError(errors, 'agreementName')}
          </InputContainer>
          <InputContainer style={{ gridColumn: '3/4' }}>
            <Label htmlFor="">Quantity *</Label>
            <TextInput
              step="1"
              type="number"
              defaultValue={''}
              {...register('quantity')}
              placeholder="Type something ..."
            />
            {renderFormFieldError(errors, 'quantity')}
          </InputContainer>
          <InputContainer style={{ gridColumn: '4/5' }}>
            <Label htmlFor="">Price *</Label>
            <TextInput
              type="number"
              defaultValue={''}
              {...register('price')}
              placeholder="Type something ..."
            />
            {renderFormFieldError(errors, 'price')}
          </InputContainer>
        </Grid>
        <Button style={{ width: '100%' }} onClick={handleSubmit(submitItem)}>
          Add to Invoice
        </Button>
      </Form>
      <FeedbackModal
        visible={showFeedbackModal}
        onClose={handleFeedbackModal}
        onClick={handleFeedbackModal}
        modalTitle="Request Invoice"
        contentTitle="Warning!"
        content={
          <span>
            We've noticed the presence of explicit language in your invoice.
            These words:{' '}
            <span style={{ color: '#E6006C', fontWeight: 'bold' }}>
              {explicitWords.join(', ')}
            </span>{' '}
            are prohibited.
          </span>
        }
        footerMsg={'Kindly remove any inappropriate wording.'}
        buttonTitle="I understand"
      />
    </>
  );
};

export default AddItemsForm;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;
