import styled from 'styled-components';

interface PopupButtonProps {
  color?: string;
}

export const PopupButton = styled.div<PopupButtonProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 36px;
  color: ${props => (!props.color ? '#57B4F9' : props.color)};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.offWhite};
  }
`;
