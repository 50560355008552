import { useFetchCampaign } from 'api';
import { useCampaignAndAppVersionParams } from 'hooks';
import Header from './Header';
import { NoDataContainer, SpinnerContainer } from 'assets/styles';
import { Spinner } from 'components/atoms';
import { LoadingWrapper } from './CompareVersions.style';
import Content from './Content';
import { useParams } from 'react-router-dom';

const QueryOptions = {
  staleTime: 0,
};

const CompareVersions = () => {
  const params = useParams<{ gameId: string }>();
  const { campaignId1, campaignId2, appVersion1, appVersion2 } =
    useCampaignAndAppVersionParams();

  const {
    data: campaignOneData,
    isSuccess: isCampaignOneSuccess,
    isLoading: isCampaignOneLoading,
    isError: isCampaignOneError,
  } = useFetchCampaign(
    Number(params.gameId),
    Number(campaignId1),
    QueryOptions,
    Number(appVersion1)
  );

  const {
    data: campaignTwoData,
    isSuccess: isCampaignTwoSuccess,
    isLoading: isCampaignTwoLoading,
    isError: isCampaignTwoError,
  } = useFetchCampaign(
    Number(params.gameId),
    Number(campaignId2),
    QueryOptions,
    Number(appVersion2)
  );

  const isCampaignLoading = isCampaignOneLoading || isCampaignTwoLoading;
  const isCampaignError = isCampaignOneError || isCampaignTwoError;

  return (
    <div>
      <Header />
      {isCampaignError && (
        <NoDataContainer>Something went wrong... </NoDataContainer>
      )}
      {isCampaignOneSuccess && isCampaignTwoSuccess && (
        <Content
          campaignOneData={campaignOneData}
          campaignTwoData={campaignTwoData}
        />
      )}
      {isCampaignLoading && (
        <LoadingWrapper>
          <SpinnerContainer>
            <Spinner size={48} color="#bebebe" />
          </SpinnerContainer>
        </LoadingWrapper>
      )}
    </div>
  );
};

export default CompareVersions;
