import styled, { css, keyframes } from 'styled-components';
import { spacing, Spacing } from 'assets/styles';

const slideIn = keyframes({
  from: {
    transform: 'translateX(-100%)',
  },
  to: {
    transform: 'translateX(0)',
  },
});

export const SidebarWrapper = styled.aside.attrs({
  className: 'sidebar',
})<{ isRemoving?: boolean }>`
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border-right: 2px solid ${({ theme }) => theme.colors.grey[1100]};
  background: ${({ theme }) => theme.colors.grey.white};
  position: relative;
  ${({ isRemoving }) =>
    isRemoving
      ? css`
          transition: transform 0.3s ease-out;
          transform: translateX(-100%);
        `
      : css`
          transition: transform 0.3s ease-in-out;
          animation: ${slideIn} 0.3s ease-in-out;
        `};

  .collapse-button {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.grey.white};
    top: 64px;
    right: -18px;
    max-width: 18px;
    height: 30px;
    color: #fff;
    border-right: 2px solid ${({ theme }) => theme.colors.grey[1100]};
    border-top: 2px solid ${({ theme }) => theme.colors.grey[1100]};
    border-bottom: 2px solid ${({ theme }) => theme.colors.grey[1100]};
    border-left: 2px solid ${({ theme }) => theme.colors.grey.white};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
    z-index: 100;
    padding: 0;
  }
`;

export const ContentWrapper = styled.section.attrs({
  className: 'content',
})`
  grid-area: content;
  overflow-y: auto;
`;

export const Container = styled.div<Spacing>`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  ${spacing};
`;
