import styled from 'styled-components';

interface Props {
  spacing: number;
  iconSize: number;
}

export const StyledActivity = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 40px;
`;

export const StyledActivityItem = styled.li<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({ theme }) => theme.colors.grey.white};
  font-size: 14px;
  font-weight: 300;
  border-radius: 4px;
  margin-bottom: ${props => props.spacing + 'px'};
  padding: ${props => props.spacing + 'px'};

  &::after {
    content: '';
    position: absolute;
    bottom: ${props => props.spacing * -1 + 'px'};
    left: ${props => props.spacing + props.iconSize * 0.5 + 'px'};
    height: ${props => props.spacing + 'px'};
    width: 1px;
    background-color: ${({ theme }) => theme.colors.pink[500]};
  }

  &:last-child {
    margin-bottom: 0;

    &::after {
      display: none;
    }
  }

  .activity-header {
    display: flex;
    align-items: center;

    .icon {
      width: ${props => props.iconSize + 'px'};
      height: ${props => props.iconSize + 'px'};
    }

    .text-wrapper {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding-left: ${props => props.spacing * 0.5 + 'px'};
      padding-right: ${props => props.spacing * 0.5 + 'px'};

      .text {
        margin-left: ${props => props.spacing * 0.5 + 'px'};
        margin-right: ${props => props.spacing * 0.5 + 'px'};
      }

      .toggle-json {
        display: flex;
        align-items: center;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.blue[500]};
        margin-left: ${props => props.spacing * 0.5 + 'px'};
        margin-right: ${props => props.spacing * 0.5 + 'px'};

        .toggle-icon {
          font-size: 18px;
          margin-left: ${props => props.spacing * 0.5 + 'px'};
        }
      }
    }

    .date {
      color: ${({ theme }) => theme.colors.grey[900]};
      white-space: nowrap;
    }
  }

  .activity-content {
    display: none;
    justify-content: center;

    &.show {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .code {
      margin-top: ${props => props.spacing + 'px'};
      width: 90%;
      border-radius: 7px;
      overflow: hidden;
    }
  }
`;
