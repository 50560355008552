import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useFetchUser } from 'api/modules/useUser';
import Main from './layout.styles';
import { Header, Sidebar } from 'views/layout';
import { ContentWrapper } from 'assets/styles';
import { sidebarTypeGenerator } from 'views/layout/sidebar/sidebar.utils';
import { SidebarType } from 'types';
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';
import { useTheme } from 'styled-components';

const Layout = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { data: user } = useFetchUser();
  const [sidebarType, setSidebarType] = useState<SidebarType>(
    sidebarTypeGenerator(pathname, user?.isPublisher)
  );
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  const toggleSidebar = () => setShowSidebar(sidebar => !sidebar);

  // This extra condition is a quick fix for the ideation page.
  // Because the ideation page's sidebar has totally different content.
  const isIdeation = pathname.includes('/ideation');

  useEffect(() => {
    const sidebarType = sidebarTypeGenerator(pathname, user?.isPublisher);
    setSidebarType(sidebarType);
  }, [pathname, user]);

  if (isIdeation) return <Outlet />;

  return (
    <Main sidebarVisible={showSidebar}>
      <Header profilePicture={user?.picture} name={user?.name!} />
      {showSidebar ? (
        <Sidebar type={sidebarType} toggleSidebar={toggleSidebar} />
      ) : (
        <button className="expand-button" onClick={toggleSidebar}>
          <MdOutlineKeyboardDoubleArrowRight
            color={theme.colors.blue[500]}
            size={14}
          />
        </button>
      )}
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </Main>
  );
};

export default Layout;
