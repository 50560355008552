import styled from 'styled-components';

const NoMedia = () => {
  return <StyledNoMedia>No media added</StyledNoMedia>;
};

export default NoMedia;

const StyledNoMedia = styled.span`
  color: rgb(191, 191, 191);
`;
