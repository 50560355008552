import moment from 'moment';
import { round, entries, zipObject } from 'lodash';
import { ColumnDef } from '@tanstack/react-table';
import { CohortRawData, CohortTableRow, CohortTableDays } from 'types';
import { cohortCellGenerator } from './CohortTableUtilsTsx';
import { cohortLabelsGenerator } from './GlobalUtilsTs';

const cohortColumnDaysGenerator = (
  numberOfColumns: number,
  startFrom: number,
  cohortAvgUnit: string
): ColumnDef<CohortTableRow>[] =>
  [...Array(numberOfColumns)].map((_, index) => ({
    accessorKey: 'day' + (index + startFrom),
    header: 'Day ' + (index + startFrom),
    size: 80,
    cell: context =>
      cohortCellGenerator(
        context.getValue() as number | undefined,
        cohortAvgUnit
      ),
    meta: {
      isCustomCell: true,
    },
  }));

export const cohortTableColumnsGenerator = (
  numberOfColumns: number,
  startFrom: number,
  cohortAvgUnit: string
): ColumnDef<CohortTableRow>[] => {
  const daysColumns = cohortColumnDaysGenerator(
    numberOfColumns,
    startFrom,
    cohortAvgUnit
  );

  return [
    { accessorKey: 'installDate', header: 'Install date', size: 110 },
    { accessorKey: 'cohortSize', header: 'Cohort size', size: 110 },
    ...daysColumns,
  ];
};

export const cohortRowDaysGenerator = (
  indexes: number[],
  values: number[],
  roundDecimal: number =2
): CohortTableDays => {
  const keys = cohortLabelsGenerator(indexes, 'day');
  const roundedValues = values.map(value =>
    value === null ? null : value !== undefined ? round(value, roundDecimal) : undefined
  );
  const daysObject = zipObject(keys, roundedValues) as CohortTableDays;
  return daysObject;
};

export const cohortTableRowsGenerator = (
  cohortData: CohortRawData,
  cohortLabelsIndex: number[],
  roundDecimal?: number
  
): CohortTableRow[] => {
  // Cohort rows
  const cohortDataRowsArray = entries(cohortData.rows);
  const filteredCohortDataRows = cohortDataRowsArray.filter(
    row => row[1].values.length !== 0
  ); // remove rows with empty values
  const cohortDataRows = filteredCohortDataRows.map(row => {
    const rowInstallDate = moment(row[0]).format('YYYY-MM-DD');
    const rowSize = row[1].cohortSize;
    const rowValues = row[1].values;
    const rowDays = cohortRowDaysGenerator(cohortLabelsIndex, rowValues,roundDecimal);
    const cohortRow = {
      installDate: rowInstallDate,
      cohortSize: rowSize,
      ...rowDays,
    } as CohortTableRow;
    return cohortRow;
  });

  // Cohort average
  const averageValues = cohortData.average.values;
  const averageDays = cohortRowDaysGenerator(cohortLabelsIndex, averageValues,roundDecimal);
  const cohortDataAverage = {
    installDate: 'Average',
    cohortSize: round(cohortData.average.cohortSize),
    ...averageDays,
  } as CohortTableRow;

  return [cohortDataAverage, ...cohortDataRows];
};
