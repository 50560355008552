import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { NewslettersResponse, NewsletterDetail } from 'types';

const getNewslettersList = async (page: number = 1, size: number = 7) => {
  const { data } = await axiosInstance.get(
    `/newsletters/?page=${page}&size=${size}`
  );
  return data as NewslettersResponse;
};

export const useGetNewslettersList = (options = {}) => {
  return useInfiniteQuery(
    ['getNewslettersList'],
    ({ pageParam }) => getNewslettersList(pageParam),
    options
  );
};

const getNewsletterDetail = async (newsletterId: number) => {
  const { data } = await axiosInstance.get(`/news/${newsletterId}`);
  return data as NewsletterDetail[];
};

export const useGetNewsletterDetail = (newsletterId: number) => {
  return useQuery(['getNewsletterDetail'], () =>
    getNewsletterDetail(newsletterId)
  );
};
