export const objectiveOptions = [
  {
    value: 'appInstalls',
    label: 'App installs',
  },
];

export const bidStrategyOptions = [
  {
    value: 'lowestCost',
    label: 'Lowest cost (automated)',
  },
];

export const billingEventOptions = [
  {
    value: 'impressions',
    label: 'Impressions',
  },
];

export const operatingSystemOptions = [
  {
    value: 'android',
    label: 'Android',
  },
];

export const genderOptions = [
  {
    value: 'any',
    label: 'Any',
  },
];

export const ageGroupOptions = [
  {
    value: 'any',
    label: 'Any',
  },
];

export const goalOptions = [
  {
    value: 'installs',
    label: 'App installs',
  },
  {
    value: 'creativeTesting',
    label: 'Creative testing',
    isDisabled: true,
  },
  {
    value: 'retention',
    label: 'Retention',
    isDisabled: true,
  },
  {
    value: 'roas',
    label: 'Roas',
    isDisabled: true,
  },
];

export const unityBidStrategyOptions = [
  {
    value: 'automated',
    label: 'Automated',
  },
];

export const billingTypeOptions = [
  {
    value: 'cpm',
    label: 'CPM',
  },
];

export const allowedDevicesOptions = [
  {
    value: 'android',
    label: 'Android',
  },
];

const highlightStyles = {
  backgroundColor: '#ececef',
  borderRadius: '4px',
  padding: '0px 5px',
};

const goalJSX = (
  <div>
    <span>
      This parameter tells Unity what you're optimizing your ad set for. You can
      select one of the following options:
    </span>
    <ul>
      <li>
        <span style={highlightStyles}>installs:</span> cost per install -
        meaning the campaign is optimized for getting the most installs possible
        with your target cost.
      </li>
      <li>
        <span style={highlightStyles}>roas:</span> return on ad spend - meaning
        the campaign is optimized for finding users who are predicted to bring
        your target return on ad spend during the first 7 days in your app.
      </li>
      <li>
        <span style={highlightStyles}>retention:</span> meaning the campaign is
        optimized for finding users who are likely to remain in your app for 7
        days or more after installing.
      </li>
      <li>
        <span style={highlightStyles}>creativeTesting:</span> meaning the
        campaign is optimized for testing and comparing the creative
        performance.
      </li>
    </ul>
  </div>
);

const unityBidStrategyJSX = (
  <div>
    <span>Type of campaign bidding strategy. Possible options:</span>
    <ul>
      <li>
        <span style={highlightStyles}>manual</span> Control your target cost by
        setting country-level static CPI bids. We recommend this if you want to
        maintain an average CPI.
      </li>
      <li>
        <span style={highlightStyles}>automated</span> Let's us automatically
        determine the best possible bids for maximizing the amount of installs
        with your daily budget. We recommend this if you want to use a daily
        budget below $500 or save time from manually adjusting bids to optimise
        performance.
      </li>
    </ul>
    <span>
      <b>Note:</b> Automated bidding strategy is only available for campaigns
      using CPM billing type.
    </span>
  </div>
);

const billingType = (
  <div>
    <span>Indicates the type of the campaign billing. Possible options:</span>
    <ul>
      <li>
        <span style={highlightStyles}>cpi</span> cost per install, you will be
        billed based on each install your app receives. This is only available
        where we receive non-aggregated postback data.
      </li>
      <li>
        <span style={highlightStyles}>cpm</span> cost per impression, you will
        be billed based on each impression the app receives. It supports
        aggregated postback data for campaigns.
      </li>
    </ul>
    <span>
      <b>Note:</b> Automated bidding strategy is only available for campaigns
      using CPM billing type.
    </span>
  </div>
);

const facebookDailyBudget = (
  <div>
    <span>
      This is the average amount you're willing to spend on a specific ad set
      per day. Facebook will try to spend your budget evenly throughout the day.
      On each day, Facebook might actually spend up to 125% of the average daily
      budget. However, you will not spend more than 7 times the average daily
      budget over a calendar week.
    </span>
    <br />
    <div style={{ margin: '10px 0px' }}>Daily limit is $1,000.</div>
  </div>
);

const unityDailyBudget = (
  <div>
    <span>
      When setting up a campaign for a new app, Unity Ads has an exploration
      phase of 1-3 days. Having a low daily cap might result in overspending
      during the exploration phase, as the system might not be able to
      accurately predict the performance of your campaign and creatives.
    </span>
    <br />
    <div style={{ margin: '10px 0px' }}>Daily limit is $1,000.</div>
    <br />
    <span>
      To enable higher impression volumes, you should increase or remove the
      campaign and daily budget limits.
    </span>
  </div>
);

const unityTotalBudget = (
  <div>
    <span>
      Task actions Convert to task Delete Total amount of money in the budget
      for the campaign in dollars. Unity will spend all money and will try to
      bring as many quality users as possible in return. Unity recommends a
      minimum campaign budget of $2,000, while our recommendation is $1,500.
    </span>
    <br />
    <div style={{ margin: '10px 0px' }}>Total limit is $7,000.</div>
  </div>
);

export const DescriptionTitle = {
  objective: 'Objective',
  dailyBudget: 'Daily budget',
  daysOfCampaign: 'Days of Campaign',
  bidStrategy: 'Bid strategy',
  billingEvent: 'Billing event',
  operatingSystem: 'Operating system',
  country: 'Country',
  gender: 'Gender',
  ageGroup: 'Age group',
  goal: 'Goal',
  totalBudget: 'Total budget',
  unityDailyBudget: 'Daily budget',
  bid: 'Bid',
  unityBidStrategy: 'Bid strategy',
  billingType: 'Billing type',
  allowedDevices: 'Allowed devices',
  unityCountry: 'Country',
};

export const DescriptionContent = {
  objective:
    "This tells Facebook what you're optimizing your ad set for. This could be link clicks, landing page views, impressions, conversions, etc. Facebook will show your ads to people who are more likely to take the action you're optimizing for. By default we use app installs as the main goal, aiming for people more likely to install your game.",
  dailyBudget: facebookDailyBudget,
  daysOfCampaign:
    'This parameter is responsible for the duration of the advertising campaign. The minimum value is 2 days, while the maximum value is 7 days. 1 day is equivalent to 24 hours. Minimum and maximum values are set to have reasonable and representative data from the test.',
  bidStrategy:
    'This is how you control your ad cost. You can set it to the lowest cost (automatic bidding), or you can use a cost cap, bid cap, or target cost (manual bidding strategies. By default, we use the lowest cost without cap, since it’s designed to get the most results for your budget based on your ad set optimization goal without limiting your bid amount. This is the best strategy if you care most about cost efficiency. However, with this strategy, it may be harder to get stable average costs as you spend.',
  billingEvent:
    'A billing event represents when you pay for your ad. It can be when someone sees your ad (impressions), or when someone clicks on your ad (link clicks), etc. By default, we use impressions for billing, meaning we pay when the ads are shown to people.',
  operatingSystem:
    'This targeting parameter enables you to select the operating system(s) of the devices your audience uses to access Facebook. Targeting by the operating system can help you optimize the user experience by tailoring your ad format to the capabilities of specific platforms.',
  country:
    'This targeting parameter allows you to specify the countries or regions where you want your Facebook ads to be displayed. Geographic targeting is essential for reaching audiences based on language, culture, local preferences, and regulatory considerations.',
  gender:
    'This targeting parameter allows you to choose the gender of the audience you want to reach with your Facebook ads. Gender-based targeting helps tailor your ad messaging and imagery to align with the preferences and needs of different genders.',
  ageGroup:
    'This targeting parameter allows you to define the age range of individuals you want to reach with your Facebook ads. By selecting specific age groups, you can tailor your content to resonate with the preferences, interests, and behaviors of different age demographics.',
  goal: goalJSX,
  unityDailyBudget: unityDailyBudget,
  totalBudget: unityTotalBudget,
  bid: 'Value of the bid in dollars. The value must be between 0.001 and 100.000 dollars (the limits include the lower and the upper bound). Please note that the upper dollar limit for a bid can be further limited based on the selected country. The value can be set in 3 decimal precision. Precisions higher than 3 decimals are not supported.',
  unityBidStrategy: unityBidStrategyJSX,
  billingType: billingType,
  allowedDevices:
    'This targeting parameter enables you to control the types of devices on which your Unity application can be installed and run. This can help optimize the user experience by ensuring compatibility with device capabilities.',
  unityCountry:
    'This targeting parameter allows you to specify the geographical locations (countries) where your Unity application will be available or visible to users. This is crucial for catering to regional preferences, languages, regulations, and content relevance.',
};
