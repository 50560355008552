import {
  BigErrorBoxWrapper,
  CenteredWrapper,
  StyledWrapper,
  TextSpan,
} from './NotFound.styles';
import BigErrorBox from 'components/BigErrorBox/BigErrorBox';

function NotFound() {
  return (
    <CenteredWrapper>
      <StyledWrapper>
        <BigErrorBoxWrapper>
          <BigErrorBox />
        </BigErrorBoxWrapper>
        <BigErrorBoxWrapper>
          <TextSpan>
            Sorry, an error occurred :( Please, try again later.
          </TextSpan>
        </BigErrorBoxWrapper>
      </StyledWrapper>
    </CenteredWrapper>
  );
}

export default NotFound;
