import {
  GameCounter,
  GamesOverviewHeader,
} from 'features/GamesOverview/GamesOverview.styles';
import OverviewList, {
  OverviewDetails,
  TableCustomRow,
} from 'components/OverviewList/OverviewList';
import { TabSelector } from 'components';
import { IoMdPlayCircle } from 'react-icons/io';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useState } from 'react';
import { ContentContainerWithPreview } from './MediaOverview';
import { LineChart } from 'components/atoms';
import Tootltip from 'components/atoms/Tooltip/Tootltip';
import { useParams } from 'react-router-dom';
import { useFetchCampaign } from 'api';
import {
  AgeKpis,
  CampaignNetworks,
  CreativePackMedia,
  FacebookCampaignStatus,
  GenderKpis,
  Kpis,
  NetworkKpis,
  VideoReviewStatus,
  VideoReviewStatusTooltip,
} from 'types';
import {
  TableBlockPositionTop,
  TableOverflowTextWrapper,
} from 'components/OverviewList/OverviewList.styles';
import VideoStatusBadge from 'components/VideoStatusBadge/VideoStatusBadge';
import { isEmpty, round } from 'lodash';
import MediaGalleryModal from './MediaGalleryModal';
import MediaRenderer from 'components/MediaRenderer/MediaRenderer';

interface DailyChartsContainerProps {
  gridColumns: number;
}

const DailyChartsContainer = styled.div<DailyChartsContainerProps>`
  grid-column: ${props => `span ${props.gridColumns}`};
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  overflow-x: auto;
`;

const NoChartText = styled.div<DailyChartsContainerProps>`
  grid-column: ${props => `span ${props.gridColumns}`};
  color: ${({ theme }) => theme.colors.grey[1200]};
  font-size: 18px;
  padding: 8px 32px;
`;

export const ChartOuterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.offWhite};
  padding: 10px;
  margin: 0 10px;
`;

export const ChartContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.white};
  padding: 38px;
  border-radius: 5px;
`;

export const ChartInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.grey.black};
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const ChartInnerContainer = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const ChartRightInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 60px;
`;

const ChartTitle = styled.div`
  display: flex;
  align-items: center;

  .tooltip {
    margin-left: 15px;
    font-size: 18px;
    color: #ccc;
  }
`;

const PlayButton = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue[500]};
`;

const TestOverviewWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[900]};
  margin: 10px 0;
`;

const chartTitleGenerator = (chartitle: string, tooltipTitle: string) => (
  <ChartTitle>
    <span>{chartitle}</span>
    <Tootltip title={tooltipTitle} placement="right">
      <span className="tooltip">(?)</span>
    </Tootltip>
  </ChartTitle>
);

const DailyKpiChart = ({ title, labels, data, avgValue }: any) => {
  return (
    <ChartOuterContainer>
      <ChartContainer>
        <ChartInfoContainer>
          <div>{title}</div>
          <ChartRightInfo>
            <div>{avgValue}</div>
          </ChartRightInfo>
        </ChartInfoContainer>
        <ChartInnerContainer>
          <LineChart labels={labels} data={data} fill={true} />
        </ChartInnerContainer>
      </ChartContainer>
    </ChartOuterContainer>
  );
};

const negativeAdStatus = [
  VideoReviewStatus.withIssues,
  VideoReviewStatus.reviewPassedWithDelay,
  VideoReviewStatus.reviewFailed,
  VideoReviewStatus.inReview,
];

const getRowDecoration = (
  campaignStatus: FacebookCampaignStatus,
  adKpi: Kpis
) => {
  if (adKpi.isDominant) {
    const styles = {
      width: '80px',
      margin: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    };
    return {
      highlightColor: 'purple',
      icon: (
        <Tootltip
          title={'Dominant creative'}
          size="x-sm"
          offset={0}
          placement="right"
        >
          <div style={styles}>🏆</div>
        </Tootltip>
      ),
    };
  } else {
    const labelDisplayStatuses = [
      FacebookCampaignStatus.pending,
      FacebookCampaignStatus.active,
      FacebookCampaignStatus.completed,
      FacebookCampaignStatus.reviewFailed,
      FacebookCampaignStatus.canceled,
      FacebookCampaignStatus.terminated,
      FacebookCampaignStatus.scheduled,
    ];
    const tooltipText: string = getTooltipText(adKpi.reviewStatus);

    if (
      (campaignStatus === FacebookCampaignStatus.review &&
        adKpi.reviewStatus === VideoReviewStatus.withIssues) ||
      (labelDisplayStatuses.includes(campaignStatus) &&
        negativeAdStatus.includes(adKpi.reviewStatus))
    )
      return {
        highlightColor: 'yellow',
        icon: (
          <Tootltip title={tooltipText} size="md" placement="top-right">
            <VideoStatusBadge status={adKpi.reviewStatus} size={'medium'} />
          </Tootltip>
        ),
      };
    else return undefined;
  }
};

const getTooltipText = (reviewStatus: VideoReviewStatus) => {
  switch (reviewStatus) {
    case VideoReviewStatus.reviewFailed:
      return VideoReviewStatusTooltip.reviewFailed;
    case VideoReviewStatus.withIssues:
      return VideoReviewStatusTooltip.withIssues;
    case VideoReviewStatus.reviewPassedWithDelay:
      return VideoReviewStatusTooltip.reviewPassedWithDelay;
    default:
      return '';
  }
};

interface TestCompletedProps {
  hideHeader?: boolean;
}

export const TestCompleted = ({ hideHeader }: TestCompletedProps) => {
  const theme = useTheme();
  const params = useParams<{ gameId: string; campaignId: string }>();
  const gameId = Number(params.gameId);
  const campaignId = Number(params.campaignId);

  const useFetchCampaignQuery = useFetchCampaign(gameId, campaignId);
  const [showModal, setShowModal] = useState(false);
  const [galleryItems, setGalleryItems] = useState<ReactImageGalleryItem[]>([]);
  const [showDetails, setShowDetails] = useState<number[]>([]);
  let tabs = ['network'];
  type Tab = typeof tabs[number];
  const [tab, setTab] = useState<Tab>(tabs[0]);

  const handleTab = (tab: Tab) => setTab(tab);

  const toggleRowDetails = ({ id }: { id: number }) => {
    setShowDetails(prevState =>
      showDetails.includes(id)
        ? prevState.filter(x => x !== id)
        : [...prevState, id]
    );
  };

  const showMediaGallery = (url: string, thumbnail: string) => {
    setGalleryItems([
      { original: url, thumbnail: thumbnail, description: 'video' },
    ]);
    setShowModal(true);
  };

  const getGalleryMediaFromPackMedia = (
    packMedia: CreativePackMedia,
    type: 'img' | 'video'
  ) => {
    return {
      original: packMedia.url,
      thumbnail: packMedia.thumbnail,
      description: type,
    };
  };
  const showPackGallery = (packMediaObj: any) => {
    const galleryMedias = [];
    const portrait = packMediaObj.unityPortraitVideo
      ? getGalleryMediaFromPackMedia(packMediaObj.unityPortraitVideo, 'video')
      : undefined;
    const landscape = packMediaObj.unityLandscapeVideo
      ? getGalleryMediaFromPackMedia(packMediaObj.unityLandscapeVideo, 'video')
      : undefined;
    const image = packMediaObj.unityImage
      ? getGalleryMediaFromPackMedia(packMediaObj.unityImage, 'img')
      : undefined;
    if (portrait) galleryMedias.push(portrait);
    if (landscape) galleryMedias.push(landscape);
    if (image) galleryMedias.push(image);

    setGalleryItems([...galleryMedias]);
    setShowModal(true);
  };

  if (useFetchCampaignQuery.isSuccess) {
    const { data: campaign } = useFetchCampaignQuery;

    const { flags } = campaign.kpis ?? {};
    if (flags?.haveSplittedByAgeKpis) tabs = [...tabs, 'age'];
    if (flags?.haveSplittedByGenderKpis) tabs = [...tabs, 'gender'];

    const columns =
      campaign.platform === CampaignNetworks.unity
        ? [
            {
              id: 'creative',
              name: 'Creative',
              width: 360,
              ellipsize: true,
              sortable: true,
            },
            { id: 'dummyColumn', name: '', width: 120, ellipsize: true },
            { id: 'spend', name: 'Spend', width: 120, sortable: true },
            {
              id: 'impressions',
              name: 'Impressions',
              width: 90,
              ellipsize: true,
              sortable: true,
            },
            { id: 'installs', name: 'Installs', width: 90, sortable: true },
            { id: 'ctr', name: 'CTR', width: 90, sortable: true },
            { id: 'ipm', name: 'IPM', width: 90, sortable: true },
            { id: 'cpi', name: 'CPI', width: 90, sortable: true },
            { id: 'cpc', name: 'CPC', width: 90, sortable: true },
            { id: 'play', name: '', width: 0 },
          ]
        : [
            {
              id: 'creative',
              name: 'Creative',
              width: 360,
              ellipsize: true,
              sortable: true,
            },
            { id: 'networkTypes', name: '', width: 120, ellipsize: true },
            { id: 'spend', name: 'Spend', width: 120, sortable: true },
            {
              id: 'impressions',
              name: 'Impressions',
              width: 90,
              ellipsize: true,
              sortable: true,
            },
            { id: 'installs', name: 'Installs', width: 90, sortable: true },
            { id: 'ctr', name: 'CTR', width: 90, sortable: true },
            { id: 'cvr', name: 'CVR', width: 90, sortable: true },
            { id: 'ipm', name: 'IPM', width: 90, sortable: true },
            { id: 'cpi', name: 'CPI', width: 90, sortable: true },
            { id: 'cpc', name: 'CPC', width: 90, sortable: true },
            { id: 'play', name: '', width: 0 },
          ];

    let rows;
    if (campaign.platform === CampaignNetworks.unity) {
      rows = campaign.kpis?.adsTestKpis?.map((testDetails, index) => {
        const rowDecoration = getRowDecoration(campaign.status, testDetails);

        return {
          id: index,
          link: '',
          rows: [
            {
              columnId: 'creative',
              content: (
                <TableBlockPositionTop
                  style={{
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}
                >
                  <TableOverflowTextWrapper title={testDetails.name}>
                    {testDetails.name}
                  </TableOverflowTextWrapper>
                  {rowDecoration && (
                    <span style={{ maxWidth: 50 }}>{rowDecoration.icon}</span>
                  )}
                </TableBlockPositionTop>
              ),
              sortableContent: testDetails.name,
            },
            {
              columnId: 'dummyColumn',
              content: '',
            },
            {
              columnId: 'spend',
              content: testDetails.spend
                ? `$${testDetails.spend?.toFixed(2)}`
                : '-',
              sortableContent: testDetails.spend ?? 0,
            },
            {
              columnId: 'impressions',
              content: testDetails.impressions
                ? `${testDetails.impressions?.toLocaleString('en-US')}`
                : '-',
              sortableContent: testDetails.impressions ?? 0,
            },
            {
              columnId: 'installs',
              content: testDetails.installs
                ? `${testDetails.installs?.toLocaleString('en-US')}`
                : '-',
              sortableContent: testDetails.installs ?? 0,
            },
            {
              columnId: 'ctr',
              content: testDetails.ctr
                ? `${testDetails.ctr?.toFixed(2)}%`
                : '-',
              sortableContent: testDetails.ctr ?? 0,
            },
            {
              columnId: 'ipm',
              content: testDetails.ipm ? `${testDetails.ipm?.toFixed(2)}` : '-',
              sortableContent: testDetails.ipm ?? 0,
            },
            {
              columnId: 'cpi',
              content: testDetails.cpi
                ? `$${testDetails.cpi?.toFixed(2)}`
                : '-',
              sortableContent: testDetails.cpi ?? 0,
            },
            {
              columnId: 'cpc',
              content: testDetails.cpc
                ? `$${testDetails.cpc?.toFixed(2)}`
                : '-',
              sortableContent: testDetails.cpc ?? 0,
            },
            {
              columnId: 'play',
              content: (
                <TableBlockPositionTop
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-around',
                    width: 55,
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    toggleRowDetails({ id: index });
                  }}
                >
                  <PlayButton
                    onClick={() => showPackGallery(testDetails.medias)}
                  >
                    <IoMdPlayCircle size={24} />
                  </PlayButton>
                  {showDetails.includes(index) ? (
                    <FaChevronUp
                      style={{ marginTop: '5px', cursor: 'pointer' }}
                      color={theme.colors.blue[500]}
                    />
                  ) : (
                    <FaChevronDown
                      style={{ marginTop: '5px', cursor: 'pointer' }}
                      color={theme.colors.blue[500]}
                    />
                  )}
                </TableBlockPositionTop>
              ),
            },
          ],
          highlight: rowDecoration ? rowDecoration.highlightColor : '',
        };
      });
    } else {
      rows = campaign.kpis?.adsTestKpis?.map((testDetails, index) => {
        const networkNames: string[] = [];
        const spend: string[] = [];
        const impressions: string[] = [];
        const installs: string[] = [];
        const ctr: string[] = [];
        const cvr: string[] = [];
        const ipm: string[] = [];
        const cpi: string[] = [];
        const cpc: string[] = [];

        const rowDecoration = getRowDecoration(campaign.status, testDetails);

        if (tab === 'network') {
          testDetails.splittedKpisByNetwork?.forEach(
            (networkKpis: NetworkKpis) => {
              networkNames.push(networkKpis.networkName);
              spend.push(
                networkKpis.spend ? `$${networkKpis.spend?.toFixed(2)}` : '-'
              );
              impressions.push(
                networkKpis.impressions
                  ? `${networkKpis.impressions?.toLocaleString('en-US')}`
                  : '-'
              );
              installs.push(
                networkKpis.installs
                  ? `${networkKpis.installs?.toLocaleString('en-US')}`
                  : '-'
              );
              ctr.push(
                networkKpis.ctr ? `${networkKpis.ctr?.toFixed(2)}%` : '-'
              );
              cvr.push(
                networkKpis.cvr ? `${networkKpis.cvr?.toFixed(2)}%` : '-'
              );
              ipm.push(
                networkKpis.ipm ? `${networkKpis.ipm?.toFixed(2)}` : '-'
              );
              cpi.push(
                networkKpis.cpi ? `$${networkKpis.cpi?.toFixed(2)}` : '-'
              );
              cpc.push(
                networkKpis.cpc ? `$${networkKpis.cpc?.toFixed(2)}` : '-'
              );
            }
          );
        } else if (tab === 'age') {
          testDetails.splittedByAge?.forEach((ageKpis: AgeKpis) => {
            networkNames.push(ageKpis.age);
            spend.push(ageKpis.spend ? `$${ageKpis.spend?.toFixed(2)}` : '-');
            impressions.push(
              ageKpis.impressions
                ? `${ageKpis.impressions?.toLocaleString('en-US')}`
                : '-'
            );
            installs.push(
              ageKpis.installs
                ? `${ageKpis.installs?.toLocaleString('en-US')}`
                : '-'
            );
            ctr.push(ageKpis.ctr ? `${ageKpis.ctr?.toFixed(2)}%` : '-');
            cvr.push(ageKpis.cvr ? `${ageKpis.cvr?.toFixed(2)}%` : '-');
            ipm.push(ageKpis.ipm ? `${ageKpis.ipm?.toFixed(2)}` : '-');
            cpi.push(ageKpis.cpi ? `$${ageKpis.cpi?.toFixed(2)}` : '-');
            cpc.push(ageKpis.cpc ? `$${ageKpis.cpc?.toFixed(2)}` : '-');
          });
        } else {
          testDetails.splittedByGender?.forEach((genderKpis: GenderKpis) => {
            networkNames.push(genderKpis.gender);
            spend.push(
              genderKpis.spend ? `$${genderKpis.spend?.toFixed(2)}` : '-'
            );
            impressions.push(
              genderKpis.impressions
                ? `${genderKpis.impressions?.toLocaleString('en-US')}`
                : '-'
            );
            installs.push(
              genderKpis.installs
                ? `${genderKpis.installs?.toLocaleString('en-US')}`
                : '-'
            );
            ctr.push(genderKpis.ctr ? `${genderKpis.ctr?.toFixed(2)}%` : '-');
            cvr.push(genderKpis.cvr ? `${genderKpis.cvr?.toFixed(2)}%` : '-');
            ipm.push(genderKpis.ipm ? `${genderKpis.ipm?.toFixed(2)}` : '-');
            cpi.push(genderKpis.cpi ? `$${genderKpis.cpi?.toFixed(2)}` : '-');
            cpc.push(genderKpis.cpc ? `$${genderKpis.cpc?.toFixed(2)}` : '-');
          });
        }

        return {
          id: index,
          link: '',
          rows: [
            {
              columnId: 'creative',
              content:
                (
                  <TableBlockPositionTop
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <TableOverflowTextWrapper title={testDetails.name}>
                      {testDetails.name}
                    </TableOverflowTextWrapper>
                    {rowDecoration && (
                      <span style={{ maxWidth: 50 }}>{rowDecoration.icon}</span>
                    )}
                  </TableBlockPositionTop>
                ) ?? '',
              sortableContent: testDetails.name,
            },
            {
              columnId: 'networkTypes',
              content: (
                <TableCustomRow
                  values={networkNames}
                  containerStyle={{ textAlign: 'end', opacity: '0.5' }}
                  isNetwork={tab === 'network'}
                />
              ),
              sortableContent: testDetails.spend ?? 0,
            },
            {
              columnId: 'spend',
              content: <TableCustomRow values={spend} />,
              sortableContent: testDetails.spend ?? 0,
            },
            {
              columnId: 'impressions',
              content: <TableCustomRow values={impressions} />,
              sortableContent: testDetails.impressions ?? 0,
            },
            {
              columnId: 'installs',
              content: <TableCustomRow values={installs} />,
              sortableContent: testDetails.impressions ?? 0,
            },
            {
              columnId: 'ctr',
              content: <TableCustomRow values={ctr} />,
              sortableContent: testDetails.ctr ?? 0,
            },
            {
              columnId: 'cvr',
              content: <TableCustomRow values={cvr} />,
              sortableContent: testDetails.cvr ?? 0,
            },
            {
              columnId: 'ipm',
              content: <TableCustomRow values={ipm} />,
              sortableContent: testDetails.ipm ?? 0,
            },
            {
              columnId: 'cpi',
              content: <TableCustomRow values={cpi} />,
              sortableContent: testDetails.cpi ?? 0,
            },
            {
              columnId: 'cpc',
              content: <TableCustomRow values={cpc} />,
              sortableContent: testDetails.cpc ?? 0,
            },
            {
              columnId: 'play',
              content: testDetails.videoUrl ? (
                <TableBlockPositionTop
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-around',
                    width: 55,
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    toggleRowDetails({ id: index });
                  }}
                >
                  <PlayButton
                    onClick={e => {
                      e.stopPropagation();
                      showMediaGallery(
                        testDetails.videoUrl!,
                        testDetails.thumbnail!
                      );
                    }}
                  >
                    <IoMdPlayCircle size={24} />
                  </PlayButton>
                  {showDetails.includes(index) ? (
                    <FaChevronUp
                      style={{ marginTop: '5px', cursor: 'pointer' }}
                      color={theme.colors.blue[500]}
                    />
                  ) : (
                    <FaChevronDown
                      style={{ marginTop: '5px', cursor: 'pointer' }}
                      color={theme.colors.blue[500]}
                    />
                  )}
                </TableBlockPositionTop>
              ) : null,
            },
          ],
          highlight: rowDecoration ? rowDecoration.highlightColor : '',
        };
      });
    }

    const detailRows: OverviewDetails = {};
    if (campaign.kpis?.adsTestKpis) {
      campaign.kpis.adsTestKpis.forEach((adsTest, index) => {
        const dailyKpis = adsTest.dailyKpis;
        if (dailyKpis) {
          const cpiLabels = Object.keys(dailyKpis.cpi ?? {}).map(value =>
            moment(value).format('DD.MM')
          );
          const cpiData = Object.values(dailyKpis.cpi).map((value: any) => {
            if (value === 'n/a') return 0;
            else return parseFloat(value);
          });
          const cpiAvgValue = !!adsTest.cpi ? `${adsTest.cpi.toFixed(2)}` : '-';
          const ctrLabels = Object.keys(dailyKpis.ctr).map(value =>
            moment(value).format('DD.MM')
          );
          const ctrData = Object.values(dailyKpis.ctr).map((value: any) =>
            parseFloat(value)
          );
          const ctrAvgValue = `${adsTest.ctr.toFixed(2)}%`;
          const impressionsLabels = Object.keys(dailyKpis.impressions).map(
            value => moment(value).format('DD.MM')
          );
          const impressionsData = Object.values(dailyKpis.impressions).map(
            (value: any) => parseFloat(value)
          );
          const impressionsAvgValue = `${adsTest.impressions.toLocaleString(
            'en-US'
          )}`;
          const installsLabels = Object.keys(dailyKpis.installs).map(value =>
            moment(value).format('DD.MM')
          );
          const installsData = Object.values(dailyKpis.installs).map(
            (value: any) => parseFloat(value)
          );
          const ipmLabels = Object.keys(dailyKpis.ipm).map(value =>
            moment(value).format('DD.MM')
          );
          const ipmData = Object.values(dailyKpis.ipm).map((value: any) =>
            parseFloat(value)
          );
          const ipmAvgValue = adsTest.ipm.toFixed(2);
          const spendLabels = Object.keys(dailyKpis.spend).map(value =>
            moment(value).format('DD.MM')
          );
          const spendData = Object.values(dailyKpis.spend).map((value: any) =>
            parseFloat(value)
          );
          detailRows[index] = (
            <DailyChartsContainer gridColumns={columns.length}>
              <DailyKpiChart
                title={chartTitleGenerator(
                  'Spend',
                  'Amount of money spent per ad campaign.'
                )}
                labels={spendLabels}
                data={spendData}
                avgValue={`$${round(adsTest.spend, 2)}`}
              />
              <DailyKpiChart
                title={chartTitleGenerator(
                  'Impressions',
                  'Impressions refer to the total number of times the ad is viewed by users (not unique). '
                )}
                labels={impressionsLabels}
                data={impressionsData}
                avgValue={impressionsAvgValue}
              />
              <DailyKpiChart
                title={chartTitleGenerator(
                  'Installs',
                  'Total app (game installs).'
                )}
                labels={installsLabels}
                data={installsData}
                avgValue={adsTest.installs}
              />
              <DailyKpiChart
                title={chartTitleGenerator(
                  'CTR',
                  'CTR is the number of clicks that your ad receives divided by the number of times your ad is shown: clicks ÷ impressions = CTR.'
                )}
                labels={ctrLabels}
                data={ctrData}
                avgValue={ctrAvgValue}
              />
              <DailyKpiChart
                title={chartTitleGenerator(
                  'IPM',
                  'It stands for Installs per Mille. It is a metric that is used to track the number of app installs per thousand ad impressions.'
                )}
                labels={ipmLabels}
                data={ipmData}
                avgValue={ipmAvgValue}
              />
              <DailyKpiChart
                title={chartTitleGenerator(
                  'CPI',
                  'It is the price to acquire a new customer from paid advertisements. This metric refers to paid installs.'
                )}
                labels={cpiLabels}
                data={cpiData}
                avgValue={`$${cpiAvgValue}`}
              />
            </DailyChartsContainer>
          );
        } else {
          detailRows[index] = (
            <NoChartText gridColumns={columns.length}>
              Nothing to show for {adsTest.name}
            </NoChartText>
          );
        }
      });
    }

    const hasTableData =
      !isEmpty(campaign.kpis) && campaign.kpis.adsTestKpis.length !== 0;

    return (
      <ContentContainerWithPreview
        style={{
          flexDirection: 'column',
          border: `2px dashed ${theme.colors.grey[1300]}`,
          borderRadius: 4,
          margin: 10,
        }}
      >
        {campaign.platform === CampaignNetworks.facebook && tabs.length > 1 ? (
          <div
            style={{
              marginRight: '20px',
              marginTop: '12px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <div>
              <Label>Breakdown by:</Label>
              <TabSelector
                tabs={tabs}
                selectedTab={tab}
                onTabSelect={handleTab}
                containerStyle={{ margin: 0 }}
              />
            </div>
          </div>
        ) : null}
        {hasTableData && (
          <TestOverviewWrapper>
            {!hideHeader && (
              <GamesOverviewHeader>
                <GameCounter>
                  {Object.keys(campaign.kpis?.adsTestKpis ?? {}).length} Test
                  {Object.keys(campaign.kpis?.adsTestKpis ?? {}).length !== 1
                    ? 's'
                    : null}
                </GameCounter>
              </GamesOverviewHeader>
            )}
            <OverviewList
              columns={columns}
              blocks={rows !== undefined ? [[...rows]] : []}
              detailRows={detailRows}
              showDetails={showDetails}
            />
          </TestOverviewWrapper>
        )}
        <MediaGalleryModal open={showModal} onClose={() => setShowModal(false)}>
          <ImageGallery
            items={galleryItems}
            startIndex={0}
            showPlayButton={false}
            showFullscreenButton={false}
            additionalClass="gallery-modal"
            renderItem={MediaRenderer}
          />
        </MediaGalleryModal>
      </ContentContainerWithPreview>
    );
  }

  return null;
};

export default TestCompleted;
