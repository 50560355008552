import { useState, useEffect, useMemo } from 'react';
import { round } from 'lodash';
import { Line } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import {
  BenchmarkLevels,
  BusinessChartType,
  CohortRawData,
  CohortTableRow,
} from 'types';
import { DataGrid, Collapse } from 'components';
import { chartTitleGenerator } from './utils/GlobalUtilsTsx';
import {
  cohortIndexArrayGenerator,
  getChartTitleAndTooltip,
} from './utils/GlobalUtilsTs';
import {
  cohortTableRowsGenerator,
  cohortTableColumnsGenerator,
} from './utils/CohortTableUtilsTs';
import { cohortCollapseTitle } from './utils/CohortTableUtilsTsx';
import {
  initialCohortChartData,
  cohortChartDataGenerator,
  cohortChartOptionsGenerator,
} from './utils/CohortChartUtilsTs';
import {
  ChartContainer,
  ChartInnerContainer,
  ChartInfoContainer,
  ChartRightInfo,
  ChartInfoValue,
} from './Charts.styles';

const startingIdx = 1;

interface ChartProps {
  data: CohortRawData;
  benchmarks: BenchmarkLevels | undefined;
}

const SundayRetentionChart = ({ data,  }: ChartProps) => {
  const [collapse, setCollapse] = useState(true);
  const [numOfCols, setNumOfCols] = useState(0);
  const [rows, setRows] = useState<CohortTableRow[]>([]);
  const [selectedRows, setSelectedRows] = useState<CohortTableRow[]>([]);
  const [chartData, setChartData] = useState<ChartData<'line'>>(
    initialCohortChartData
  );

  const dataUnit = data.unit;
  const avgValue = round(data.average.values[0], 1);

  useEffect(() => {
    // number of columns
    setNumOfCols(data.average.values.length);

    // Table rows
    const labelIdx = cohortIndexArrayGenerator(numOfCols, startingIdx);
    const rows = cohortTableRowsGenerator(data, labelIdx);
    setRows(rows);

    const initialSelectedRow = [rows[0]];
    setSelectedRows(initialSelectedRow);
  }, [data, numOfCols]);

  useEffect(() => {
    // chartData
    const labelIdx = cohortIndexArrayGenerator(numOfCols, startingIdx);
    const chartData = cohortChartDataGenerator(selectedRows, labelIdx);
    setChartData(chartData);
  }, [selectedRows, numOfCols]);

  const chartOptions = useMemo(
    () =>
      cohortChartOptionsGenerator(
        selectedRows,
        dataUnit,
        BusinessChartType.SUNDAY_RETENTION
      ),
    [selectedRows, dataUnit]
  );

  const columns = useMemo(
    () => cohortTableColumnsGenerator(numOfCols, startingIdx, dataUnit),
    [numOfCols, dataUnit]
  );

  const { title, tooltip } = getChartTitleAndTooltip(
    BusinessChartType.SUNDAY_RETENTION
  );

  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
        <ChartRightInfo>
          {!isNaN(avgValue)? <ChartInfoValue>{`${avgValue} ${dataUnit}`}</ChartInfoValue> : null} 
        </ChartRightInfo>
      </ChartInfoContainer>
      <ChartInnerContainer>
        <Line options={chartOptions} data={chartData} height={400} />
      </ChartInnerContainer>
      <Collapse
        open={collapse}
        onToggle={setCollapse}
        title={cohortCollapseTitle}
        mt={5}
      >
        <DataGrid<CohortTableRow>
          rows={rows}
          columns={columns}
          selectedRows={{ 0: true }}
          onRowSelection={setSelectedRows}
          disableSelectAll
        />
      </Collapse>
    </ChartContainer>
  );
};

export default SundayRetentionChart;
