import { GrAndroid } from 'react-icons/gr';
import styled from 'styled-components';
import { IconBaseProps } from 'react-icons';

interface Props extends IconBaseProps {
  $active?: boolean;
}

export const AndroidIcon = styled(GrAndroid)<Props>`
  color: ${props =>
    props.$active
      ? props.theme.colors.blue[500]
      : props.theme.colors.grey[1000]};
  cursor: ${(props: Props) => (props.$active ? 'pointer' : 'default')};
  font-size: 19px;
  display: block;
  margin: 0 5px;
`;
