import { useStudioWizard } from 'store';
import { forwardRef } from 'react';
import AllCountriesForm from './AllCountriesForm';
import { SubmitPaymentDetailsRef } from '../AddStudioModal';
import IndianForm from './IndianForm';

const INDIA = 'India';

const PaymentDetailsStep = forwardRef<SubmitPaymentDetailsRef, {}>((_, ref) => {
  const { studioDetails } = useStudioWizard(state => state);
  const locationSplit = studioDetails.location.value.split(', ');
  const country = locationSplit[locationSplit.length - 1];

  return (
    <>
      {country === INDIA ? (
        <IndianForm ref={ref} />
      ) : (
        <AllCountriesForm ref={ref} />
      )}
    </>
  );
});

export default PaymentDetailsStep;
