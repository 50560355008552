import { useStudioWizard } from 'store';
import styled from 'styled-components';
import { AllPaymentDetails, IndianPaymentDetails } from 'types';
import { EmptyLogoCircle } from '../AddStudioModal.styles';
import { useFetchCurrency } from 'api/modules/useCurrency';

const INDIA = 'India';

const ConfirmationStep = () => {
  const { data: currencies } = useFetchCurrency();
  const { studioDetails, paymentDetails } = useStudioWizard(state => state);
  const country = studioDetails.location.value.split(', ')[1];
  const indianPaymentData = paymentDetails as IndianPaymentDetails;
  const allPaymentData = paymentDetails as AllPaymentDetails;
  const locationSplit = studioDetails.location.value.split(', ');
  const studioCountry = locationSplit[locationSplit.length - 1];

  const getCurrency = (value: string) => {
    return currencies?.find(currency => currency.value === value)?.label;
  };

  return (
    <ConfirmMainWrapper>
      <ConfirmTopBox>
        <ConfirmTopLeftBox>
          <TextBox>
            <HeaderText>Summary</HeaderText>
          </TextBox>
          <TextBox>
            <MainText>Studio Name:</MainText>
            <SubText>{studioDetails.studioName}</SubText>
          </TextBox>
          <TextBox>
            <MainText>Studio Location:</MainText>
            <SubText>{studioCountry}</SubText>
          </TextBox>
          <TextBox>
            <MainText>Publishing Manager:</MainText>
            <SubText>{studioDetails.publishingManager.name}</SubText>
          </TextBox>
        </ConfirmTopLeftBox>
        <ConfirmTopRightBox>
          {studioDetails.logo ? (
            <EmptyLogoCircle url={studioDetails.logo} />
          ) : (
            <EmptyLogoBox>
              <EmptyLogoCircle url={''} />
            </EmptyLogoBox>
          )}
        </ConfirmTopRightBox>
      </ConfirmTopBox>
      <ConfirmBottomBox>
        <ConfirmBottomLeftBox>
          {' '}
          <TextBox>
            <HeaderText>Contact Information</HeaderText>
          </TextBox>
          <TextBox>
            <MainText>Contact Name:</MainText>
            <SubText>{studioDetails.contactName}</SubText>
          </TextBox>
          <TextBox>
            <MainText>Contact Email:</MainText>
            <SubText>{studioDetails.contactEmail}</SubText>
          </TextBox>
          <TextBox>
            <MainText>Contact Phone:</MainText>
            <SubText>
              {studioDetails.contactPhoneNumber
                ? studioDetails.contactPhoneNumber
                : 'N/A'}
            </SubText>
          </TextBox>
        </ConfirmBottomLeftBox>
        <ConfirmBottomRightBox>
          <div>
            {' '}
            <TextBox>
              <HeaderText>Payment Details</HeaderText>
            </TextBox>
            {paymentDetails.companyName ? (
              <>
                <TextBox>
                  <MainText>Company:</MainText>
                  <SubText>{paymentDetails.companyName}</SubText>
                </TextBox>
                <TextBox>
                  <MainText>Address:</MainText>
                  <SubText>{paymentDetails.companyAddress}</SubText>
                </TextBox>
                <TextBox>
                  <MainText>Tax ID/Pan:</MainText>
                  <SubText>
                    {paymentDetails.taxId ? paymentDetails.taxId : 'N/A'}
                  </SubText>
                </TextBox>
                <TextBox>
                  <MainText>Currency:</MainText>
                  <SubText>{getCurrency(paymentDetails.currency)}</SubText>
                </TextBox>
                {studioCountry === INDIA ? (
                  <TextBox>
                    <MainText>IFSC Code:</MainText>
                    <SubText>
                      {indianPaymentData.ifscCode
                        ? indianPaymentData.ifscCode
                        : 'N/A'}
                    </SubText>
                  </TextBox>
                ) : (
                  <TextBox>
                    <MainText>IBAN:</MainText>
                    <SubText>
                      {allPaymentData.iban ? allPaymentData.iban : 'N/A'}
                    </SubText>
                  </TextBox>
                )}
                <TextBox>
                  <MainText>Invoice Pattern:</MainText>
                  <SubText>
                    {allPaymentData.invoicePattern
                      ? allPaymentData.invoicePattern
                      : 'N/A'}
                  </SubText>
                </TextBox>
              </>
            ) : (
              <TextBox>
                <MainText>
                  You did not add any payment details; these can be added later.
                </MainText>
              </TextBox>
            )}
          </div>
          {paymentDetails.companyName ? (
            <div style={{ margin: '20px 0 0 7%' }}>
              {' '}
              <TextBox>
                <HeaderText> </HeaderText>
              </TextBox>
              <TextBox>
                <MainText>Bank Name:</MainText>
                <SubText>
                  {allPaymentData.bankName ? allPaymentData.bankName : 'N/A'}
                </SubText>
              </TextBox>
              <TextBox>
                <MainText>Account Name:</MainText>
                <SubText>
                  {allPaymentData.accountName
                    ? allPaymentData.accountName
                    : 'N/A'}
                </SubText>
              </TextBox>
              <TextBox>
                <MainText>SWIFT/BIC:</MainText>
                <SubText>
                  {allPaymentData.swiftBic ? allPaymentData.swiftBic : 'N/A'}
                </SubText>
              </TextBox>
              <TextBox>
                <MainText>Bank Routing Number:</MainText>
                <SubText>
                  {allPaymentData.bankRoutingNumber
                    ? allPaymentData.bankRoutingNumber
                    : 'N/A'}
                </SubText>
              </TextBox>
              <TextBox>
                <MainText>Account Number:</MainText>
                <SubText>
                  {allPaymentData.accountNumber
                    ? allPaymentData.accountNumber
                    : 'N/A'}
                </SubText>
              </TextBox>
              {country === INDIA ? (
                <TextBox>
                  <MainText>Purpose Code:</MainText>
                  <SubText>
                    {indianPaymentData.purposeCode
                      ? indianPaymentData.purposeCode
                      : 'N/A'}
                  </SubText>
                </TextBox>
              ) : null}
            </div>
          ) : null}
        </ConfirmBottomRightBox>
      </ConfirmBottomBox>
    </ConfirmMainWrapper>
  );
};

export default ConfirmationStep;

export const ConfirmMainWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 1.5%;
  height: 440px;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

export const ConfirmTopBox = styled.div`
  display: flex;
  justify-content: space-between;
  height: 37%;
`;
export const ConfirmTopRightBox = styled.div``;

export const ConfirmTopLeftBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConfirmBottomBox = styled.div`
  display: flex;
  justify-content: space-between;
  height: 63%;
  gap: 2%;
`;

export const ConfirmBottomRightBox = styled.div`
  display: flex;
  border: 1px solid #dfdfdf;
  width: 70%;
  border-radius: 8px;
  padding: 1.5%;
`;

export const ConfirmBottomLeftBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dfdfdf;
  width: 30%;
  border-radius: 8px;
  padding: 1.5%;
`;
export const TextBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
export const HeaderText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #757575;
  margin: 0 0 15px 4px;
`;
export const MainText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin: 4px;
`;
export const SubText = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin: 6px 2px;
`;
export const Logo = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
`;
export const EmptyLogoBox = styled.div`
  padding: 15px;
`;
