import { useCampaignActivity } from 'api';
import ActivityItem from './ActivityItem';
import { NoDataContainer } from 'assets/styles';
import { StyledActivity } from './Activity.styles';
import ActivitySkeleton from './ActivitySkeleton';

interface ActivityProps {
  gameId: number;
  campaignId: number;
}

const Activity = ({ gameId, campaignId }: ActivityProps) => {
  const {
    data: activities,
    isFetching,
    isSuccess,
    isError,
  } = useCampaignActivity(gameId, campaignId);

  return isFetching ? (
    <ActivitySkeleton />
  ) : isError ? (
    <NoDataContainer>Something went wrong, Please try later.</NoDataContainer>
  ) : isSuccess && activities.length === 0 ? (
    <NoDataContainer>No recorded activity</NoDataContainer>
  ) : (
    <StyledActivity>
      {activities.map((activity, index) => (
        <ActivityItem activity={activity} key={index} />
      ))}
    </StyledActivity>
  );
};

export default Activity;
