import Select, { CSSObjectWithLabel, SingleValue, Theme } from 'react-select';
import { useTheme } from 'styled-components';

const height = {
  minHeight: '40px',
  maxHeight: '40px',
};
const width = {
  width: '250px',
};

export type OptionValue = number | string | undefined;

export type Option = {
  value: OptionValue;
  label: string;
};

interface StyledSelectProps {
  options: Option[];
  onChange: (option: SingleValue<Option>) => void;
  selectedOption: OptionValue;
}

const StyledSelect: React.FC<StyledSelectProps> = ({
  options,
  selectedOption,
  onChange,
}) => {
  const theme = useTheme();
  const selectTheme = (selectTheme: Theme) => ({
    ...selectTheme,
    borderColor: theme.colors.blue[500],
  });

  const SelectStyles = {
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      ...width,
      ...height,
    }),
    indicatorSeparator: () => ({
      color: 'none',
    }),
    dropdownIndicator: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      color: theme.colors.blue[500],
    }),
    menuList: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      maxHeight: '200px',
      borderRadius: '4px',
    }),
  };

  const getValue = (appVersion: OptionValue) => {
    return options.find(option => option.value === appVersion);
  };

  return (
    <Select
      value={getValue(selectedOption)}
      placeholder="Select App Version"
      options={options}
      onChange={onChange}
      isSearchable={false}
      styles={SelectStyles}
      theme={selectTheme}
    />
  );
};

export default StyledSelect;
