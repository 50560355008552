import { StatusBadge, StatusBadgeSize } from 'components/atoms';
import { VideoReviewStatus } from 'types';

interface VideoStatusBadgeProps {
  status: VideoReviewStatus;
  size: StatusBadgeSize;
}

const VideoStatusBadge = ({ status, size }: VideoStatusBadgeProps) => {
  switch (status) {
    case VideoReviewStatus.reviewFailed:
      return (
        <StatusBadge context="error" size={size}>
          Review Failed
        </StatusBadge>
      );
    case VideoReviewStatus.reviewPassedWithDelay:
      return (
        <StatusBadge context="warning" size={size}>
          Late review
        </StatusBadge>
      );
    case VideoReviewStatus.inReview:
      return (
        <StatusBadge context="warning" size={size}>
          In review
        </StatusBadge>
      );
    case VideoReviewStatus.withIssues:
      return (
        <StatusBadge context="warning" size={size}>
          Misconfigured
        </StatusBadge>
      );
    default:
      return <></>;
  }
};

export default VideoStatusBadge;
