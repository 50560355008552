import { FiLoader } from 'react-icons/fi';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FiLoader)`
  animation: spin 2s linear infinite;
  animation-name: ${spin};
`;
