import { InvoiceStatus } from 'types';
import { memoize } from 'lodash';

const commonStyles = {
  height: '32px',
  width: '109px',
};

const bgColors: Record<InvoiceStatus, string> = {
  [InvoiceStatus.open]: '#2DA1F8',
  [InvoiceStatus.archived]: '#D8D8D8',
  [InvoiceStatus.imported]: '#AFAFAF',
  [InvoiceStatus.approved]: '#34E360',
  [InvoiceStatus.editRequired]: '#F066A7',
};

const colors: Record<InvoiceStatus, string> = {
  [InvoiceStatus.open]: '#FFFFFF',
  [InvoiceStatus.archived]: '#AFAFAF',
  [InvoiceStatus.imported]: '#4D4D4D',
  [InvoiceStatus.approved]: '#FFFFFF',
  [InvoiceStatus.editRequired]: '#FFFFFF',
};

const texts: Record<InvoiceStatus, string> = {
  [InvoiceStatus.open]: 'Open',
  [InvoiceStatus.archived]: 'Archived',
  [InvoiceStatus.imported]: 'Imported',
  [InvoiceStatus.approved]: 'Approved',
  [InvoiceStatus.editRequired]: 'Edit Required',
};

export const getTextByInvoiceType = (invoiceStatus: InvoiceStatus) =>
  texts[invoiceStatus];

export const getBadgeColorsByInvoiceType = (invoiceStatus: InvoiceStatus) => ({
  backgroundColor: bgColors[invoiceStatus],
  color: colors[invoiceStatus],
});

export const getBadgeProperties = memoize((invoiceStatus: InvoiceStatus) => {
  return {
    styles: {
      ...commonStyles,
      ...getBadgeColorsByInvoiceType(invoiceStatus),
    },
    text: getTextByInvoiceType(invoiceStatus),
  };
});
