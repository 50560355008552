import styled from 'styled-components';
import { Notification } from 'components/molecules';
import { useDeleteNotification, useFetchStatus } from 'api';
import { NotificationType } from 'types';

export const NotificationsList = ({ handler }: { handler: () => void }) => {
  const { data: status, refetch: refetchNotification } = useFetchStatus();
  const { mutate: deleteNotification } = useDeleteNotification();

  const deleteAllNotification = () => {
    deleteNotification(undefined, {
      onSuccess: () => {
        refetchNotification();
        handler();
      },
    });
  };

  return (
    <NotificationsWrapper>
      <NotificationHeader>
        <NotificationsTitle>Notifications</NotificationsTitle>
        {!!status?.notifications.length && (
          <NotificationDismissAll onClick={deleteAllNotification}>
            Dismiss all
          </NotificationDismissAll>
        )}
      </NotificationHeader>
      <NotificationContent>
        {!!status?.notifications.length ? (
          status.notifications.map((notification: NotificationType) => (
            <Notification {...notification} handler={handler} />
          ))
        ) : (
          <span style={{ opacity: 0.6 }}>No new notifications</span>
        )}
      </NotificationContent>
    </NotificationsWrapper>
  );
};

const NotificationsWrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
`;

const NotificationHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const NotificationsTitle = styled.span`
  color: ${({ theme }) => theme.colors.grey.black};
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
`;

export const NotificationContent = styled.div`
  min-height: 50px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  border-radius: 8px;
`;

export const NotificationDismissAll = styled.span`
  color: ${({ theme }) => theme.colors.pink[500]};
  cursor: pointer;
`;
