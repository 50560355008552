import { Modal } from 'components';
import { ModalSize } from 'types';
import { Button } from 'components/atoms/Button';
import CongratulationsWrapper from './CongratulationsModal.styles';
import congratulationsSrc from 'assets/images/celebration.png';

interface CongratulationsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CongratulationsModal = ({
  isOpen,
  onClose,
}: CongratulationsModalProps) => {
  return (
    <Modal
      size={ModalSize.sm}
      isOpen={isOpen}
      title="Congratulations!"
      onClose={() => onClose()}
    >
      <CongratulationsWrapper>
        <img className="image" alt="Congratulations" src={congratulationsSrc} />
        <span className="title">You've created your first test!</span>
        <p className="body">
          As of now you don't have to do anything except waiting for your
          publishing manager to review the attached creatives and approve the
          test. When it's approved, the test will take off!
        </p>
        <Button className="submit" onClick={() => onClose()}>
          I understand
        </Button>
      </CongratulationsWrapper>
    </Modal>
  );
};

export default CongratulationsModal;
