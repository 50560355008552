import { ReactNode, MouseEvent } from 'react';
import { Spacing } from 'assets/styles';
import StyledCollapse from './Collapse.styles';
import clsx from 'clsx';

interface CollapseProps extends Spacing {
  children: ReactNode;
  open: boolean;
  onToggle: (status: boolean) => void;
  title?: ReactNode;
}

const Collapse = ({
  children,
  open,
  onToggle,
  title,
  ...spacing
}: CollapseProps) => {
  const handleToggle = (event: MouseEvent) => {
    event.preventDefault();
    onToggle(!open);
  };

  return (
    <StyledCollapse {...spacing}>
      <a className="header" href="/#" onClick={handleToggle}>
        {title}
      </a>
      <section className={clsx('body', { open })}>{children}</section>
    </StyledCollapse>
  );
};

export default Collapse;
