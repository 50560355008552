import { reduce } from 'lodash';
import { GameConfig } from 'types';

export const convertConfigsToJSON = (configs: GameConfig[]) => {
  return reduce(
    configs,
    (acc, curConfig) => {
      const key = curConfig.key;
      const value = curConfig.value;
      return { ...acc, [key]: value };
    },
    {}
  );
};
