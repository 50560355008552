import styled from 'styled-components';

export const SyledCreativePackUploader = styled.div`
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.pointer {
    cursor: pointer;
  }

  svg {
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .upload-new {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    input {
      display: none;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      cursor: pointer;

      .text {
        font-size: 14px;
        margin-left: 10px;
        margin-right: 30px;
      }

      .max-size {
        font-size: 12px;
        color: gray;
      }
    }
  }

  .uploading {
    display: flex;
    flex-direction: column;

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
    }
  }

  .upload-complete {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;

    .media-details {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
        border-radius: 2px;
      }

      .file-name {
        width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .error {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.pink[500]};
  }
`;

interface ProgressBarProps {
  percentage: number;
}

export const ProgressBar = styled.div<ProgressBarProps>`
  background-color: ${props => props.theme.colors.grey[1400]};
  height: 8px;
  width: 300px;
  pointer-events: none;
  border-radius: 4px;
  margin: 10px 0;

  ::after {
    content: '';
    display: block;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.pink[500]};
    width: ${(props: ProgressBarProps) => `${props.percentage}%`};
    height: 100%;
  }
`;
