export enum InvoiceStatus {
  open = 'open',
  archived = 'archived',
  imported = 'imported',
  editRequired = 'editRequired',
  approved = 'approved',
}

export interface InvoiceData {
  invoices: Invoice[];
  summary: Summary;
  currencySymbol: string;
}

export interface Invoice {
  id: number;
  name: string;
  status: InvoiceStatus;
  amount: number;
  submitDate: string;
  commentsStatus: CommentsStatus;
}

export interface Actions {
  edit: boolean;
  download: boolean;
  archive: boolean;
  delete: boolean;
}

export interface Summary {
  totalAmount: string;
  totalUnarchivedAmount: string;
}

export interface InvoiceItem {
  prototypeName: string;
  agreementName: string;
  quantity: number;
  price: number;
}

export interface InvoiceItemWithId extends InvoiceItem {
  id: number;
}
export interface InvoiceData {
  invoices: Invoice[];
  summary: Summary;
  vat: number;
}

export interface Comment {
  userName: string;
  content: string;
  createdAt: string;
}

export enum CommentsStatus {
  NONE = 'none',
  UNREAD = 'unread',
  READ = 'read',
}

export interface Actions {
  edit: boolean;
  download: boolean;
  archive: boolean;
  delete: boolean;
}

export interface Summary {
  totalAmount: string;
  totalUnarchivedAmount: string;
}

export interface InvoiceItem {
  prototypeName: string;
  agreementName: string;
  quantity: number;
  price: number;
}

export interface InvoiceItemWithId extends InvoiceItem {
  id: number;
}

export interface FetchInvoiceReponse {
  items: InvoiceItem[];
  number: number;
  pattern: string;
  usedNumbers: number[];
  currencySymbol: string;
}

export interface UpdateInvoiceReqPayload {
  items: InvoiceItem[];
  number: number;
}

export interface FetchInvoiceComments {
  comments: Comment[];
  invoiceNumber: string;
}
