import 'normalize.css';
import { css } from 'styled-components';

// Customization
const reset = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    width: 100vw;
    min-height: 100vh;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  img {
    max-width: 100%;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  /* input {
    padding: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  } */
`;

export default reset;
