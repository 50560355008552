import * as Yup from 'yup';

const commonValidations = {
  companyName: Yup.string().required('Company Name is a required field'),
  bankName: Yup.string().required('Bank Name is a required field'),
  companyAddress: Yup.string().required('Company Address is a required field'),
  accountName: Yup.string().required('Account Name is a required field'),
  taxId: Yup.string().nullable(),
  swiftBic: Yup.string().required('SWIFT / BIC is a required field'),
  currency: Yup.string().required('Currency is a required field'),
  bankRoutingNumber: Yup.string(),
  accountNumber: Yup.string(),
  invoicePattern: Yup.string().test(
    'has-hash',
    'Field must contain at least one # symbol',
    value => {
      if (!value) {
        return true;
      }
      return value.includes('#');
    }
  ),
};

export const indianSchema = Yup.object().shape({
  ...commonValidations,
  ifscCode: Yup.string(),
  purposeCode: Yup.string(),
});

export const allCountriesSchema = Yup.object().shape({
  ...commonValidations,
  iban: Yup.string(),
});
