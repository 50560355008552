import styled from 'styled-components';

export const SyledCreativePackSelector = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 16px;
  height: 140px;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .title {
    margin-bottom: 10px;
  }
`;

export const StyledSelectOption = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .thumbnail {
    border-radius: 2px;
    margin-right: 10px;
  }
`;
