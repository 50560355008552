import styled from 'styled-components';
import SidebarHeaderText from './SidebarHeaderText';
import { useParams } from 'react-router-dom';
import {Breadcrumbprops} from 'types';
import { newArchive,unArchive,filter } from 'assets/images';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'; 

interface SidebarProps {
  type: 'studio' | 'game' | 'campaign';
  showArchived?: boolean;
  toggleArchive?: () => void;
  togglePopup?: () => void;
  studiodata?:Breadcrumbprops;
  gameData?:Breadcrumbprops;
}

const SidebarHeader = ({
  type, 
  showArchived,
  toggleArchive,
  togglePopup,
  studiodata,
  gameData
}: SidebarProps) => {
  const params = useParams<{
    studioId: string;
    gameId: string;
    campaignId: string;
  }>();
  const studioId = Number(params.studioId);
  const gameId = Number(params.gameId);
 
  const studioBeardcrumbItem =[{label:'Overview',link:'/home',rootUrl:'',boldLinks: true,fontSize:'18px'}]
  
  const gameBeardcrumbItem = [{label:'Overview',link:'/home',rootUrl:'',boldLinks: true,fontSize:'18px'},{label:`${studiodata?.name}`,link:`studio/${studioId}`,rootUrl:'/',logo:`${studiodata?.logo}`,smallLogo:true}]

  const campaignBeardcrumbItem = [{label:'Overview',link:'/home',rootUrl:'',boldLinks: true,fontSize:'18px'},{label:`${studiodata?.name}`,link:`studio/${studioId}`,rootUrl:'/',logo:`${studiodata?.logo}`,smallLogo:true},{label:`${gameData?.name}`,link:`studio/${studioId}/game/${gameId}`,rootUrl:'/',logo:`${gameData?.logo}`,boldLinks: true}]

  return (
    <Header>
      {type === 'studio' && (
      <HeaderWrapper>
         <BreadcrumbWrapper className='breadcrumb'>
         { params.studioId ?<Breadcrumb items={studioBeardcrumbItem}/> : null }
         { params.studioId ? null : <SidebarHeaderText title="Overview" /> }
         </BreadcrumbWrapper>
         <ActionsWrapper>
         <Icon src={filter} alt='archive-Icon'onClick={togglePopup}/> 
         <Icon src={showArchived? unArchive :newArchive} alt='archive-Icon'onClick={toggleArchive}/> 
          </ActionsWrapper>
      </HeaderWrapper>        
      )}
      {type === 'game' && (
        <>
        <Breadcrumb items={gameBeardcrumbItem} /> 
          <ArchiveIcon
          src={showArchived? unArchive :newArchive} alt='archive-Icon'
            onClick={toggleArchive}
          />
        </>
      )}
      {type === 'campaign' && (
        <>
       { gameData?.name && studiodata?.name ? <Breadcrumb items={campaignBeardcrumbItem}/> : null }
        </>
      )}
    </Header>
  );
};

const Header = styled.header`
  max-height: 100px;
  display: flex;
  justify-content: space-between;

`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-evenly;
`;
const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 45px;
  justify-content: space-around;
`;
const BreadcrumbWrapper = styled.div`
   margin-right:40px;
   margin-top: 4px;
`;

const ArchiveIcon = styled.img`
  cursor: pointer;
  margin-top: 0;
  max-height: 23px;
`;

const Icon = styled.img`
cursor: pointer;
margin-right: 8px;
`

export default SidebarHeader;
