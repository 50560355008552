import Tootltip from 'components/atoms/Tooltip/Tootltip';
import { MediaSortType, VideoFile } from 'types';
import {
  Button,
  MediaWrapper,
  PopupContent,
  PopupTitle,
} from './Thumbnail.styles';
import { RiCloseCircleFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useRef, useState } from 'react';
import { Popup } from 'components/atoms';
import { Checkbox } from 'components';
import clsx from 'clsx';
import { useDeleteMedia, useFetchMedias } from 'api';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

const getBadgeValue = (media: VideoFile) => {
  if (media.ipm) {
    return media.hasLowImpressions ? 'N/A' : `${media.ipm.value} IPM`;
  } else {
    return 'Untested';
  }
};

interface ThumbnailProps {
  mediaLimit?: number;
  media: VideoFile;
  hasError?: boolean;
  isRemovable?: boolean;
  hasCheckbox?: boolean;
  selectedMedias?: number[];
  onCheckboxClick?: () => void;
  handleClick?: () => void;
}

const handleMediaDeletationSuccess = (text: string) => {
  toast.success(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};
const MediaThumbnail = ({
  mediaLimit,
  media,
  isRemovable,
  selectedMedias,
  hasError,
  hasCheckbox,
  onCheckboxClick,
  handleClick,
}: ThumbnailProps) => {
  const theme = useTheme();
  const params = useParams<{ gameId: string }>();
  const gameId = Number(params.gameId);
  const useFetchMediasQuery = useFetchMedias(gameId, MediaSortType.IPM, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const { mutate: deleteMedia } = useDeleteMedia(gameId);
  const [removeButton, setRemoveButton] = useState(false);
  const [removePopup, setRemovePopup] = useState(false);
  const badgeValue = getBadgeValue(media);

  const handleRemoveButtonClick = () => {
    setRemovePopup(!removePopup);
  };

  const handleDelete = () => {
    deleteMedia(media.id, {
      onSuccess: () => {
        useFetchMediasQuery.refetch();
        handleMediaDeletationSuccess('Video was successfully deleted');
      },
    });
  };

  const isSelected: boolean = !!selectedMedias?.includes(media.id);
  const isUnselected =
    !!mediaLimit && selectedMedias?.length === mediaLimit && !isSelected;

  const removeConfirmRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <MediaWrapper
        ref={removeConfirmRef}
        ipm={media ? media.ipm : null}
        onMouseOver={() => setRemoveButton(true)}
        onMouseLeave={() => setRemoveButton(false)}
        onClick={handleClick}
      >
        <img
          className={clsx('thumbnail-img', {
            'thumbnail-unselected': isUnselected,
            'thumbnail-error-border': hasError,
          })}
          src={media?.thumbnail}
          alt=""
        />
        <div
          className={clsx(
            'thumbnail-badge',
            {
              high: media.ipm?.benchmark === 'High' && !media.hasLowImpressions,
            },
            {
              medium:
                media.ipm?.benchmark === 'Medium' && !media.hasLowImpressions,
            },
            {
              low: media.ipm?.benchmark === 'Low' && !media.hasLowImpressions,
            }
          )}
        >
          {media.hasLowImpressions && media.ipm ? (
            <Tootltip
              title="Low impressions"
              placement="right-bottom"
              offset={0}
            >
              {badgeValue}
            </Tootltip>
          ) : (
            badgeValue
          )}
        </div>

        {/* Remove Button */}
        {isRemovable && removeButton && (
          <RiCloseCircleFill
            style={{ zIndex: 'var(--z-index-media-close-icon)' }}
            size={35}
            className="thumbnail-remove-button"
            onClick={e => {
              e.stopPropagation();
              handleRemoveButtonClick();
            }}
          />
        )}

        {/* Remove Confirm */}
        {removePopup && (
          <Popup
            visible={removePopup}
            anchor={removeConfirmRef}
            onClose={() => setRemovePopup(false)}
            containerStyle={{
              borderRadius: '4px',
              padding: '20px',
              width: '200px',
              height: '150px',
              top: '40px',
            }}
          >
            <PopupTitle>Do you want to delete this video?</PopupTitle>
            <PopupContent>
              <Button
                color={theme.colors.pink[500]}
                onClick={e => {
                  e.stopPropagation();
                  handleDelete();
                }}
              >
                Yes
              </Button>
              <Button
                color={'darkgray'}
                onClick={e => {
                  e.stopPropagation();
                  setRemovePopup(false);
                }}
              >
                No
              </Button>
            </PopupContent>
          </Popup>
        )}

        {/* Error Outline */}
        {hasError && (
          <>
            <div className="thumbnail-error-icon-wrapper">
              <Tootltip
                title={'Video review failed due to facebook error.'}
                placement="bottom"
              >
                <span className="thumbnail-error-icon">?</span>
              </Tootltip>
            </div>
          </>
        )}

        {/* Checkbox */}
        {hasCheckbox && (
          <span className="thumbnail-checkbox" onClick={onCheckboxClick}>
            <Checkbox
              isChecked={isSelected}
              isDisabled={selectedMedias?.length === mediaLimit && !isSelected}
            />
          </span>
        )}

        {hasCheckbox && <div className="thumbnail-checkbox-overlay"></div>}
      </MediaWrapper>
    </>
  );
};

export default MediaThumbnail;
