import clsx from 'clsx';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { ActivationTypes } from 'types';

interface RadioProps {
  type: ActivationTypes;
  label: string;
  description: string;
  hasError: boolean;
  errorMessage: string;
  selected: ActivationTypes;
  onSelect: (type: ActivationTypes) => void;
}

const Radio = ({
  selected,
  onSelect,
  type,
  label,
  description,
  hasError,
  errorMessage,
}: RadioProps) => {
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selected = event.target.checked;
    selected && onSelect(type);
  };

  return (
    <StyledRadio>
      <label
        className={clsx('radio-wrapper', { 'has-error': hasError })}
        htmlFor={type}
      >
        <input
          className="radio"
          type="radio"
          name="campain-types"
          id={type}
          disabled={hasError}
          checked={type === selected}
          onChange={handleRadioChange}
        />
        <span className="label">{label}</span>
        <span className="description">{description}</span>
      </label>
      {hasError && <span className="error-message">{errorMessage}</span>}
    </StyledRadio>
  );
};

export default Radio;

const StyledRadio = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .radio-wrapper {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;

    &:not(.has-error) {
      cursor: pointer;
      border-color: #dfdfdf;
    }

    &.has-error {
      cursor: default;
      border-color: ${({ theme }) => theme.colors.pink[500]};

      .label,
      .description {
        color: #999999;
      }

      .description {
        flex-grow: 1;
      }
    }

    .radio {
      cursor: inherit;
      transform: scale(1.3);

      &:checked + .label {
        color: ${({ theme }) => theme.colors.blue[500]};
      }
    }

    .label {
      font-size: 20px;
      margin-left: 12px;
    }

    .description {
      font-weight: 300;
      margin-left: 24px;
    }
  }

  .error-message {
    color: ${({ theme }) => theme.colors.pink[500]};
    margin-bottom: 16px;
  }
`;
