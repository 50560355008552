import { GameStatus } from 'types';
import { Badge } from '../Badge';
import { getBadgeProperties } from './GameStatusBadge.utils';

interface GameStatusBadgeProps {
  gameStatus: GameStatus;
}

const GameStatusBadge = ({ gameStatus }: GameStatusBadgeProps) => {
  const { text, styles } = getBadgeProperties(gameStatus);
  return <Badge styles={styles}>{text}</Badge>;
};

export default GameStatusBadge;
