import { FC } from 'react';
import styled from 'styled-components';

import { BiErrorCircle } from 'react-icons/bi';

interface AlertProps {
  severity: 'error' | 'success' | 'warning' | 'info';
  children?: React.ReactNode;
}

const getAlertIcon = (severity: string) => {
  switch (severity) {
    case 'error':
      return <BiErrorCircle size={20} />;
    default:
      return '';
  }
};

export const Alert: FC<AlertProps> = ({ severity, children }) => {
  return (
    <AlertContainer>
      {getAlertIcon(severity)}
      <div>{children}</div>
    </AlertContainer>
  );
};

export const AlertContainer = styled.div`
  border-radius: 4px;
  font-weight: 400;
  padding: 10px 0px;
  font-size: 16px;
  height: 30px;
  min-width: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fdeded;
  color: #672b2a;
`;
