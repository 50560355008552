import { CampaignOs } from './campaign';

export enum GameTabs {
  tests = 'campaigns',
  videos = 'videos',
  configurations = 'configurations',
  monetization = 'monetization',
}
export const preSelectedGameTab = GameTabs.tests;

export type GameParams = {
  studioId: string;
  gameId: string;
  tabName: GameTabs;
};
export interface GameLinkGenerator {
  studioId: number;
  gameId: number;
  tabName?: GameTabs;
}
export interface TagType {
  id: number;
  name: string;
  isIdeationGenre: number;
}

export enum GameOrientation {
  portrait = 'portrait',
  landscape = 'landscape',
}

export interface CreatTagPayload {
  name: string;
}

export interface CreatTagResponse {
  message: string;
}

export enum OverviewGamesDateRange {
  last2Weeks = '14',
  lastMonth = '30',
  last3Months = '90',
  last6Months = '180',
}

export enum OverviewMediaType {
  video = 'video',
  image = 'image',
}

export enum GameStatus {
  new = 'new',
  ipm = 'ipm',
  mvp = 'mvp',
  scb = 'scb',
  ctm = 'ctm',
}

export interface OverviewMedia {
  id: number;
  gameId: number;
  url: string;
  thumbnail: string;
  type: OverviewMediaType;
}
export interface OverviewGame {
  id: number;
  name: string;
  logo: string;
  studioId: number;
  studioName: string;
  testCount: number;
  ipm: number;
  medias: OverviewMedia[];
  tags: TagType[];
}
export interface GameOverviewCategory {
  id: number;
  name: string;
  logo: string;
  games: OverviewGame[];
}

export interface FetchGamesOverviewResponse {
  publishingManagers: GameOverviewCategory[];
  studios: GameOverviewCategory[];
}

export interface Game {
  id: number;
  name: string;
  status: GameStatus;
  logo: string;
  createdAt: string;
  isArchived: boolean;
  isTikTokConfigured: boolean;
  isFacebookConfigured: boolean;
  facebookAppId: string;
  facebookAppSecret: string;
  facebookUserAccessToken: string;
  installReferrerDecryptionKey: string;
  ipm: number;
  studioId: number;
  studioName: string;
  appStoreUrl: string;
  iosPackageName: string;
  playMarketUrl: string;
  platform: CampaignOs;
  latestTestStartDate: string;
  justTrackToken: string | null;
  unityId: string | null;
  mondayItemId: number | null;
  bundleId: string;
  tags: TagType[];
  orientation: GameOrientation;
}

export interface FetchGameResponse {
  data: Game;
  status: boolean;
}

export interface FetchGamesResponse {
  games: Game[];
  status: boolean;
}

export const defaultGame: Game = {
  id: 0,
  isArchived: false,
  name: '',
  createdAt: '',
  studioId: 0,
  studioName: '',
  logo: '',
  status: GameStatus.ipm,
  platform: CampaignOs.ANDROID,
  latestTestStartDate: '',
  ipm: 0,
  playMarketUrl: '',
  appStoreUrl: '',
  iosPackageName: '',
  isFacebookConfigured: false,
  isTikTokConfigured: false,
  installReferrerDecryptionKey: '',
  justTrackToken: null,
  unityId: null,
  mondayItemId: null,
  bundleId: '',
  facebookAppId: '',
  facebookAppSecret: '',
  facebookUserAccessToken: '',
  tags: [],
  orientation: GameOrientation.portrait,
};

export interface GameConfig {
  name: string;
  value: string;
  key: string;
  editable: boolean;
  description?: string;
}

export enum MonetizationStatus {
  notSetup = 'notSetup',
  processing = 'processing',
  successful = 'successful',
  conflict = 'conflict',
  failed = 'failed',
}

export interface MonetizationStep {
  step: string;
  displayName: string;
  status: MonetizationStatus;
  clickable: boolean;
  subSteps: MonetizationSubStep[];
  message?: string;
}

export enum MonetizationSubStepGroup {
  bidding = 'bidding',
  nonBidding = 'nonBidding',
}

export interface MonetizationSubStep
  extends Omit<MonetizationStep, 'subSteps'> {
  group: MonetizationSubStepGroup;
}

export interface FetchGameConfigsResponse {
  data: GameConfig[];
  status: boolean;
}

export interface editGameConfigReqBody {
  key: string;
  value: string | null;
}

export interface SetupMonetizationReqBody {
  parentStep?: string;
  step: string;
  os: 'android' | 'ios';
}
