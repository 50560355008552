import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { initialGenresData, Market, ServerQueryKey } from 'types';

const getGenres = async (market: Market) => {
  const {
    data: { data },
  } = await axiosInstance.get(`/ideation/genres/${market}`);
  if (data === null) return initialGenresData;
  return data;
};

export const useGetGenres = (market: Market, options = {}) => {
  return useQuery(
    [ServerQueryKey.IdeationGenres, market],
    () => getGenres(market),
    options
  );
};
