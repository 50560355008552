import { css, FlattenSimpleInterpolation } from 'styled-components';
import { padding, Padding } from './padding';
import { margin, Margin } from './margin';

export interface Spacing extends Padding, Margin {}

export const spacing = (
  spacing: Spacing,
  baseSpacing: number = 8
): FlattenSimpleInterpolation | undefined => {
  const { p, px, pl, pr, py, pt, pb, ...marginProps } = spacing;
  const paddingProps = { p, px, pl, pr, py, pt, pb };

  const paddings = padding(paddingProps, baseSpacing);
  const margins = margin(marginProps, baseSpacing);

  const spacings = css`
    ${paddings}
    ${margins}
  `;

  return spacings;
};
