import { Modal } from 'components/molecules';
import AddItemsForm from './AddItemsForm';
import ListItems from './ListItems';
import {
  useCreateInvoice,
  useFetchInvoice,
  useFetchInvoiceNumber,
  useUpdateInvoice,
} from 'api';
import { useParams } from 'react-router-dom';
import { Button } from 'components/atoms';
import styled from 'styled-components';
import { FetchInvoiceReponse, InvoiceItem, InvoiceItemWithId } from 'types';
import { useInvoiceItems } from './useInvoiceItems';
import InvoiceNumber from './InvoiceNumber';
import { useEffect, useState } from 'react';
import { round } from 'lodash';

const stripIdsFromInvoiceItems = (
  itemsWithId: InvoiceItemWithId[]
): InvoiceItem[] => {
  return itemsWithId.map(
    ({ prototypeName, agreementName, quantity, price }) => ({
      prototypeName,
      agreementName,
      quantity,
      price,
    })
  );
};

interface GenerateInvoiceModalComponentProps {
  onClose: () => void;
  onSuccess: () => void;
  onError: () => void;
  invoiceId?: number;
  vat: number;
  currency: string;
}

interface GenerateInvoiceModalProps extends GenerateInvoiceModalComponentProps {
  visible: boolean;
}

export const GenerateInvoiceModal = ({
  visible,
  ...props
}: GenerateInvoiceModalProps) => {
  if (!visible) {
    return null;
  }

  return <GenerateInvoiceModalComponent {...props} />;
};

const GenerateInvoiceModalComponent = (
  props: GenerateInvoiceModalComponentProps
) => {
  const params = useParams<{
    studioId: string;
  }>();
  const studioId = Number(params.studioId);
  const [isInvoiceNumChanged, setIsInvoiceNumChanged] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState<number>();
  const { data: newInvoiceNumber } = useFetchInvoiceNumber(studioId, {
    enabled: !props.invoiceId,
  });
  const { data: invoiceDetails, isSuccess: isInvoiceDetailsSuccess } =
    useFetchInvoice(studioId, props.invoiceId!, {
      enabled: !!props.invoiceId,
      onSuccess: (data: FetchInvoiceReponse) => {
        setInvoiceNumber(data.number);
        addInvoiceItemsList(data.items);
      },
    });
  const { mutate: createInvoice } = useCreateInvoice(studioId);
  const { mutate: updateInvoice } = useUpdateInvoice(
    studioId,
    props.invoiceId || 0
  );

  const {
    isItemsChanged,
    invoiceItems,
    subTotal,
    addInvoiceItem,
    addInvoiceItemsList,
    removeInvoiceItem,
  } = useInvoiceItems();
  const handleCreateInvoice = () => {
    const payload = stripIdsFromInvoiceItems(invoiceItems);
    createInvoice(payload, {
      onSuccess: props.onSuccess,
      onError: props.onError,
    });
  };
  const invoiceTotal = () => {
    return subTotal && (((props.vat / 100) * subTotal) as number);
  };
  const handleUpdateInvoice = () => {
    const items = stripIdsFromInvoiceItems(invoiceItems);
    updateInvoice(
      {
        items,
        number: invoiceNumber!,
      },
      {
        onSuccess: props.onSuccess,
        onError: props.onError,
      }
    );
  };

  useEffect(() => {
    if (!!props.invoiceId && !!invoiceDetails?.number && !!invoiceNumber) {
      if (invoiceDetails?.number !== invoiceNumber) {
        setIsInvoiceNumChanged(true);
      }
    }
  }, [invoiceNumber, props.invoiceId, invoiceDetails?.number]);

  return (
    <Modal
      onClose={props.onClose}
      title={props.invoiceId ? 'Edit Invoice' : 'Request Invoice'}
      containerStyle={{ minWidth: 1200 }}
    >
      <Header>
        <span className="label">Invoice Number: </span>
        {props.invoiceId && isInvoiceDetailsSuccess ? (
          <InvoiceNumber
            invoiceNumber={invoiceNumber!}
            setInvoiceNumber={setInvoiceNumber}
            usedInvoiceNumbers={invoiceDetails.usedNumbers}
            invoicePattern={invoiceDetails.pattern}
          />
        ) : (
          <span className="value">#{newInvoiceNumber}</span>
        )}
      </Header>
      <SummaryBox>
        {!!invoiceItems.length && (
          <ListItems
            invoiceItems={invoiceItems}
            removeItem={removeInvoiceItem}
          />
        )}
        {!!invoiceItems.length && (
          <TotalValueWrapper>
            <ValuesWrapper>
              {' '}
              <Text>Sub Total: </Text>
              <Value>{`${props.currency}${subTotal?.toLocaleString(
                'en-US'
              )}`}</Value>{' '}
            </ValuesWrapper>
            <ValuesWrapper>
              {' '}
              <Text>{`VAT (${props.vat}%):`}</Text>
              <Value>{`${props.currency}${round(
                invoiceTotal() as number,
                2
              ).toLocaleString('en-US')}`}</Value>
            </ValuesWrapper>
            <ValuesWrapper>
              {' '}
              <Text>Invoice Total:</Text>
              <Value>{`${props.currency}${(
                round(subTotal as number, 2) +
                round(invoiceTotal() as number, 2)
              ).toLocaleString('en-US')}`}</Value>
            </ValuesWrapper>
          </TotalValueWrapper>
        )}
      </SummaryBox>

      <AddItemsForm addItem={addInvoiceItem} />
      <Button
        primary
        onClick={props.invoiceId ? handleUpdateInvoice : handleCreateInvoice}
        disabled={
          invoiceItems.length === 0 || (!isItemsChanged && !isInvoiceNumChanged)
        }
        style={{ marginTop: 20 }}
      >
        {props.invoiceId ? 'Update Invoice' : 'Generate Invoice'}
      </Button>
    </Modal>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;

  .label {
    font-weight: bold;
  }

  .value {
    font-weight: 400;
  }
`;
const ValuesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;
const TotalValueWrapper = styled.div`
  min-width: 171px;
`;
const Text = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
`;
const Value = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
const SummaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
