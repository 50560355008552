export enum ServerQueryKey {
  NavStudios = 'navigation-studios',
  NavGames = 'navigation-games',
  NavCampaigns = 'navigation-campaigns',

  StudioDetails = 'studio-details',
  StudioPaymentDetails = 'studio-payment-details',

  GamesList = 'games-list',
  GamesOverviewList = 'games-overview-list',
  GameDetails = 'game-details',
  GameTags = 'game-tags',

  CampaignsList = 'campaigns-list',
  CampignOverviewList = 'campaigns-overview-list',
  CampaignDetails = 'campaign-details',
  CampaignActivity = 'campaign-activity',
  LevelProgression = 'level-progression',
  AppVersionsList = 'app-versions-list',

  OrganizationMembers = 'organization-members',

  GameConfigs = 'game-configs',
  CheckPlaystoreUrl = 'check-playstore-url',
  MonetizationSteps = 'monetization-steps',
  CompareCampaigns = 'compare-campaigns',

  InvoicesList = 'invoices-list',
  InvoiceDetails = 'invoice-details',
  InvoiceComments = 'invoice-comments',
  InvoiceNumber = 'invoice-number',
  InvoicePDFDownloadData = 'invoice-pdf-download-data',

  IdeationApps = 'ideation-apps',
  IdeationGenres = 'ideation-genres',

  GlobalSearch = 'global-search',
  Status = 'status',
  Currency = 'currency',
  Countries = 'countries',
}

export enum ThemeMode {
  Light = 'light',
  Dark = 'dark',
}

export interface Currency {
  label: string;
  value: string;
}

export interface Country {
  id: number;
  name: string;
  isoAlpha2Name: string;
  isEu: boolean;
}

export interface SelectOption {
  label: string | React.ReactNode;
  value: string;
}

export interface Option {
  label: string;
  value: string;
}
