import { useCampaignAndAppVersionParams } from 'hooks';
import { StyledHeader } from './CompareVersions.style';
import { useFetchAppVersions, useFetchCompareAppVersions } from 'api';
import { useState } from 'react';
import StyledSelect, {
  Option,
  OptionValue,
} from 'features/Kpi/JustrackKpi/StyledSelect';
import {
  generateCompareVersionsUrl,
  getCompareOptions,
  getOptions,
} from 'features/Kpi/JustrackKpi/JustrackKpi.utils';
import { SingleValue } from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const params = useParams<{ studioId: string; gameId: string }>();
  const {
    campaignId1,
    campaignId2,
    campaignName1,
    campaignName2,
    appVersion1,
    appVersion2,
  } = useCampaignAndAppVersionParams();
  const [selectedAppVersion, setSelectedAppVersion] =
    useState<OptionValue>('all');
  const [selectedCompareAppVersion, setSelectedCompareAppVersion] =
    useState<OptionValue>('');
  const { data: appVersions, isSuccess: isAppVersionsSuccess } =
    useFetchAppVersions(params.gameId!, campaignId1!, {
      onSuccess: () => setSelectedAppVersion(appVersion1!),
    });
  const {
    data: comparingAppVersions,
    isSuccess: isComparingAppVersionsSuccess,
  } = useFetchCompareAppVersions(params.gameId!, campaignId1!, {
    onSuccess: () => {
      setSelectedCompareAppVersion(
        `${campaignId2},${campaignName2},${appVersion2}`
      );
    },
  });

  const handleAppVersionChange = (option: SingleValue<Option>) => {
    setSelectedAppVersion(option?.value);
    const redirectUrl = generateCompareVersionsUrl({
      studioId: params.studioId!,
      gameId: params.gameId!,
      campaignId1: campaignId1,
      campaignId2: campaignId2,
      campaignName1: campaignName1,
      campaignName2: campaignName2,
      appVersion1: String(option?.value),
      appVersion2: appVersion2,
    });
    navigate(redirectUrl);
  };

  const handleCompareAppVersionChange = (option: SingleValue<Option>) => {
    setSelectedCompareAppVersion(option?.value);
    const [campaignId, campaignName, appVersion] = String(option?.value).split(
      ','
    );
    const redirectUrl = generateCompareVersionsUrl({
      studioId: params.studioId!,
      gameId: params.gameId!,
      campaignId1: campaignId1!,
      campaignId2: campaignId,
      campaignName1: campaignName1!,
      campaignName2: campaignName,
      appVersion1: appVersion1!,
      appVersion2: appVersion,
    });
    navigate(redirectUrl);
  };

  if (isAppVersionsSuccess && isComparingAppVersionsSuccess) {
    const appVersionsOptions = getOptions(appVersions);
    const comparingAppVersionsOptions = isComparingAppVersionsSuccess
      ? getCompareOptions(comparingAppVersions)
      : [];

    return (
      <StyledHeader>
        <div className="block">
          <p className="title">
            {campaignName1} - {appVersion1 !== 'all' ? appVersion1 : 'All'}
          </p>
          {isAppVersionsSuccess && appVersionsOptions.length > 1 ? (
            <div className="dropdown-wrapper">
              <label>App Version:</label>
              <StyledSelect
                options={appVersionsOptions}
                selectedOption={selectedAppVersion}
                onChange={handleAppVersionChange}
              />
            </div>
          ) : null}
        </div>
        <div className="block">
          <p className="title">
            {campaignName2} - {appVersion2}
          </p>
          {comparingAppVersionsOptions.length > 0 && (
            <div className="dropdown-wrapper">
              <label>Compare App Version:</label>
              <StyledSelect
                options={comparingAppVersionsOptions}
                selectedOption={selectedCompareAppVersion}
                onChange={handleCompareAppVersionChange}
              />
            </div>
          )}
        </div>
      </StyledHeader>
    );
  }

  return null;
};

export default Header;
