import styled, { css, keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

interface SkeletonProps extends React.CSSProperties {
  height?: string;
  width?: string;
}

const Skeleton = styled.div<SkeletonProps>`
  background: linear-gradient(to right, #e5e6e7 8%, #d9dbdc 18%, #e5e6e7 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 2s infinite;
  border-radius: 4px;
  ${({ height, width, ...props }) => css`
    height: ${height};
    width: ${width};
    ${Object.entries(props)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join('\n')}
  `}
`;

export default Skeleton;
