import styled, { css } from 'styled-components';
import { Spinner } from 'components/atoms';
import { IPM } from 'services/api/MediaService';
import Tootltip from 'components/atoms/Tooltip/Tootltip';

interface ProgressBarProps {
  percentage?: number;
}

interface Props extends ProgressBarProps {
  onClick?: () => void;
  showActivityIndicator?: boolean;
  thumbnail?: string;
  ipm?: IPM;
  hasError?: boolean;
}

export const StyledVideoThumbnail = styled.div<Props>`
  position: relative;
  width: 138px;
  height: 204px;
  border-radius: 16px;
  background-color: ${(props: Props) =>
    props.showActivityIndicator ? '#eeeeee' : '#dadada'};
  cursor: pointer;

  &:before {
    min-width: 40px;
    height: 20px;
    padding: 2px 3px;
    color: ${props =>
      props.ipm === null
        ? '#a5acae'
        : props.ipm?.benchmark === 'High'
        ? props.theme.colors.blue[500]
        : props.ipm?.benchmark === 'Medium'
        ? props.theme.colors.orange[500]
        : props.ipm?.benchmark === 'Low'
        ? props.theme.colors.pink[500]
        : ''};
    background-color: ${props =>
      props.ipm === null
        ? '#eeeeee'
        : props.ipm?.benchmark === 'High'
        ? '#d5ecfe'
        : props.ipm?.benchmark === 'Medium'
        ? '#fff2d5'
        : props.ipm?.benchmark === 'Low'
        ? props.theme.colors.pink[700]
        : ''};
    border-radius: 4px;
    position: absolute;
    display: block;
    top: 20px;
    left: 15px;
    content: ${(props: Props): string => {
      if (props.ipm === undefined) return "''";
      else if (props.ipm === null) return "'Untested'";
      return "'" + props.ipm?.value + " IPM'";
    }};
  }

  ${(props: Props) =>
    props.thumbnail &&
    css`
      background-image: ${(props: Props) => `url(${props.thumbnail})`};
      background-size: cover;
      background-position: center center;
    `};

  .error-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid #eb3347;
    border-radius: 16px;
    z-index: 10;
  }

  .error-Icon-wrapper {
    position: absolute;
    bottom: -4px;
    right: -6px;
    z-index: 20;

    .error-Icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 600;
      background-color: #eb3347;
      color: ${({ theme }) => theme.colors.grey.white};
    }
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Text = styled.div`
  font-family: 'Rubik', sans-serif;
  color: #bebebe;
  font-size: 12px;
  padding-top: 6px;
`;

const ProgressBar = styled.div<ProgressBarProps>`
  background: #eeeeee;
  height: 16px;
  width: 100%;
  pointer-events: none;
  overflow: hidden;
  border-radius: 0 0 16px 16px;

  ::after {
    content: '';
    display: block;
    background: #bebebe;
    width: ${(props: ProgressBarProps) =>
      props.percentage ? `${props.percentage}%` : 0};
    height: 100%;
  }
`;

export const VideoThumbnail = ({
  percentage,
  showActivityIndicator,
  onClick,
  thumbnail,
  ipm,
  hasError = false,
}: Props) => {
  return (
    <StyledVideoThumbnail
      onClick={onClick}
      showActivityIndicator={showActivityIndicator}
      thumbnail={thumbnail}
      ipm={ipm}
      hasError={hasError}
    >
      {hasError ? (
        <>
          <div className="error-border"></div>
          <div className="error-Icon-wrapper">
            <Tootltip
              title={'This video has failed to pass Facebook video review'}
              placement="bottom"
            >
              <span className="error-Icon">?</span>
            </Tootltip>
          </div>
        </>
      ) : null}
      {showActivityIndicator && (
        <InnerContainer>
          <SpinnerContainer>
            <Spinner size={24} color="#bebebe" />
            <Text>Uploading...</Text>
          </SpinnerContainer>
          <ProgressBar percentage={percentage} />
        </InnerContainer>
      )}
    </StyledVideoThumbnail>
  );
};
