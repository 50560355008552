import styled from 'styled-components';
import Facebook from './Facebook';
import { CampaignNetworks, FacebookParameters, UnityParameters } from 'types';
import Unity from './Unity';
import { useFetchGame } from 'api';

interface AdvancedDetailsStepProps {
  gameId: number;
  network: CampaignNetworks;
  handleNetworkChange: (network: CampaignNetworks) => void;
  parameters: FacebookParameters | UnityParameters | null;
  handleParameters: (
    parameters: FacebookParameters | UnityParameters | null
  ) => void;
}

const AdvancedDetailsStep: React.FC<AdvancedDetailsStepProps> = ({
  gameId,
  network,
  handleNetworkChange,
  parameters,
  handleParameters,
}) => {
  const { data: game, isSuccess } = useFetchGame(gameId);
  if (isSuccess) {
    const disableUnity = !game.unityId;
    const disableFacebook = !game.isFacebookConfigured;

    return (
      <StyledAdvancedDetailsStep>
        {network === CampaignNetworks.facebook ? (
          <Facebook
            disableUnity={disableUnity}
            handleNetworkChange={handleNetworkChange}
            parameters={parameters as FacebookParameters}
            handleParameters={handleParameters}
          />
        ) : (
          <Unity
            disableFacebook={disableFacebook}
            handleNetworkChange={handleNetworkChange}
            parameters={parameters as UnityParameters}
            handleParameters={handleParameters}
          />
        )}
      </StyledAdvancedDetailsStep>
    );
  }
  return null;
};

export default AdvancedDetailsStep;

const StyledAdvancedDetailsStep = styled.div`
  height: 100%;
  padding: 10px 40px;
`;
