import OverviewListSkeleton from 'components/OverviewList/OverviewListSkeleton';
import Skeleton from 'components/Skeleton/Skeleton';
import { GamesOverviewHeader } from 'features/GamesOverview/GamesOverview.styles';
import { OverviewHeaderLeft } from 'features/InvoicesOverview/InvoicesOverview.styles';

const OverviewSkeleton = () => {
  return (
    <>
      <GamesOverviewHeader>
        <OverviewHeaderLeft>
          <Skeleton height="36px" width="160px" />
        </OverviewHeaderLeft>
      </GamesOverviewHeader>
      <OverviewListSkeleton rowLength={5} />
    </>
  );
};

export default OverviewSkeleton;
