import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  useFetchGame,
  useFetchUser,
  usePostArchive,
  usePostUnarchive,
} from 'api';
import {
  defaultGame,
  ArchivePageName,
  AccessFeatures,
  Game,
  GameOrientation,
} from 'types';
import {
  HeaderContainer,
  HeaderColumn,
  HeaderInnerRow,
} from './PagesHeader.styles';
import { toast } from 'react-toastify';
import { InfoModal } from 'components/molecules';
import { Logo, AddGameModal, TagList } from 'components';
import ActionButton from './ActionButton';
import StoreLink from './StoreLink';
import ModifyTagsModal from './ModifyTagsModal';
import playMarketIcon from 'assets/images/pagesHeader/playMarket.svg';
import appStoreIcon from 'assets/images/pagesHeader/appStore.svg';
import calendarIcon from 'assets/images/Calendar.svg';
import editIcon from 'assets/images/pagesHeader/edit.svg';
import archiveIcon from 'assets/images/pagesHeader/archive.svg';
import unarchiveIcon from 'assets/images/pagesHeader/unarchive.svg';
import { IoIosPhonePortrait, IoIosPhoneLandscape } from 'react-icons/io';
import { hasPermission } from 'utils/user';
import { useDocumentTitle } from 'hooks';
import styled, { useTheme } from 'styled-components';

export const ModalTitle = styled.div`
  font-size: 18px;
  padding: 12px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
`;
export const ButtonContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-evenly;
`;

export const Button = styled.button`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  background-color: ${props => props.color};
  border-radius: 4px;
  border: 0;
  padding: 10px 24px;
  color: ${({ theme }) => theme.colors.grey.white};
`;
const GameHeader = () => {
  const theme = useTheme();
  const { data: user } = useFetchUser();
  const [showAddGameModal, setShowAddGameModal] = useState<boolean>(false);
  const [showUnarchiveModal, setShowUnarchiveModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showModifyTagsModal, setShowModifyTagsModal] =
    useState<boolean>(false);
  const queryClient = useQueryClient();
  const params = useParams<{ gameId: string }>();
  const { updateDocumentTitle } = useDocumentTitle();
  const gameId = Number(params.gameId);
  const handleSuccess = (game: Game) =>
    updateDocumentTitle(game.name, game.studioName);
  const { data: game, refetch: gameRefetch } = useFetchGame(gameId, {
    initialData: defaultGame,
    onSuccess: handleSuccess,
  });
  const { mutate: postArchive } = usePostArchive(ArchivePageName.game);
  const { mutate: postUnarchive } = usePostUnarchive(ArchivePageName.game);
  const handleGameChangesSuccess = (text: string) => {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleArchiveModalResponse = (isConfirmed: boolean) => {
    if (isConfirmed) {
      postArchive(gameId, {
        onSuccess: () => {
          gameRefetch();
          queryClient.invalidateQueries(['getNavigationGames']);
          handleGameChangesSuccess('The game was successfully archived');
        },
      });
    }
    setShowArchiveModal(false);
  };
  const handleUnrchiveModalResponse = (isConfirmed: boolean) => {
    if (isConfirmed) {
      postUnarchive(gameId, {
        onSuccess: () => {
          gameRefetch();
          queryClient.invalidateQueries(['getNavigationGames']);
          handleGameChangesSuccess('The game was successfully unarchived');
        },
      });
    }
    setShowUnarchiveModal(false);
  };

  const hasEditGameAccess = hasPermission(user, AccessFeatures.EDIT_GAME);
  const hasArchiveGameAccess = hasPermission(user, AccessFeatures.ARCHIVE_GAME);

  return (
    <HeaderContainer p={5}>
      <HeaderColumn className="y-center" minHeight={80}>
        <Logo src={game?.logo} name={game?.name} size={80} borderRadius={10} />
      </HeaderColumn>
      <HeaderColumn className="y-center full-width" pl={3} pr={2}>
        <HeaderInnerRow minHeight={27} className="y-baseline" mb={1}>
          <h2 className="name" title={game?.name}>
            {game?.name}
          </h2>
          {game?.isArchived && <span className="archived">(Archived)</span>}
        </HeaderInnerRow>
        <HeaderInnerRow minHeight={24}>
          <img src={calendarIcon} alt="calendar" className="date-icon" />
          <span className="date-text">Created: {game?.createdAt}</span>
        </HeaderInnerRow>
        <HeaderInnerRow minHeight={24}>
          {game?.orientation === GameOrientation.portrait ? (
            <IoIosPhonePortrait size={24} />
          ) : (
            <IoIosPhoneLandscape size={24} />
          )}
          <span className="date-text">
            Orientation:{' '}
            {game?.orientation === GameOrientation.portrait
              ? 'Portrait'
              : 'Landscape'}
          </span>
        </HeaderInnerRow>
      </HeaderColumn>
      <HeaderColumn className="border-left" minWidth={250} p={2}>
        <HeaderInnerRow minHeight={24} className="x-space" mb={1}>
          <span className="section-title">Tags</span>
          <ActionButton
            iconSrc={editIcon}
            buttonText="Modify"
            color="blue"
            onClick={() => setShowModifyTagsModal(true)}
          />
        </HeaderInnerRow>
        {game && showModifyTagsModal && (
          <ModifyTagsModal
            gameId={game.id}
            gameTags={game.tags}
            onClose={() => setShowModifyTagsModal(false)}
          />
        )}
        <HeaderInnerRow>
          {game && (
            <TagList
              tags={game?.tags ?? []}
              maxVisibleTags={2}
              smallSize={false}
            />
          )}
        </HeaderInnerRow>
      </HeaderColumn>
      <HeaderColumn className="border-left" minWidth={170} p={2}>
        <HeaderInnerRow mb={1}>
          <span className="section-title">Download</span>
        </HeaderInnerRow>
        <HeaderInnerRow minHeight={30} className="warp">
          {game?.playMarketUrl && (
            <StoreLink
              iconSrc={playMarketIcon}
              iconAlt="playMarket"
              linkUrl={game?.playMarketUrl}
              mr={1.5}
            />
          )}
          {game?.appStoreUrl && (
            <StoreLink
              iconSrc={appStoreIcon}
              iconAlt="appStore"
              linkUrl={game?.appStoreUrl}
            />
          )}
        </HeaderInnerRow>
      </HeaderColumn>
      <HeaderColumn className="border-left" p={2} pr={0}>
        <HeaderInnerRow mb={1}>
          <span className="section-title">Actions</span>
        </HeaderInnerRow>
        <HeaderInnerRow minHeight={24} className="x-space warp">
          {hasEditGameAccess && (
            <ActionButton
              iconSrc={editIcon}
              buttonText="Edit details"
              color="blue"
              mr={1.5}
              onClick={() => setShowAddGameModal(true)}
            />
          )}
          {hasArchiveGameAccess && game?.isArchived && (
            <ActionButton
              iconSrc={unarchiveIcon}
              buttonText="Unarchive"
              color="pink"
              onClick={() => setShowUnarchiveModal(true)}
            />
          )}
          {hasArchiveGameAccess && !game?.isArchived && (
            <ActionButton
              iconSrc={archiveIcon}
              buttonText="Archive"
              color="pink"
              onClick={() => setShowArchiveModal(true)}
            />
          )}

          {game && showAddGameModal && (
            <AddGameModal
              isOpen={showAddGameModal}
              onClose={() => setShowAddGameModal(false)}
              isCreateMode={false}
            />
          )}
        </HeaderInnerRow>
      </HeaderColumn>
      {showUnarchiveModal ? (
        <InfoModal>
          <ModalTitle>
            Are you sure you would like to unarchive this Game?
          </ModalTitle>
          <ButtonContainer>
            <Button
              color={theme.colors.pink[500]}
              onClick={() => handleUnrchiveModalResponse(true)}
            >
              Yes
            </Button>
            <Button
              color={'darkgray'}
              onClick={() => handleUnrchiveModalResponse(false)}
            >
              No
            </Button>
          </ButtonContainer>
        </InfoModal>
      ) : null}
      {showArchiveModal ? (
        <InfoModal>
          <ModalTitle>
            Are you sure you would like to archive this Game?
          </ModalTitle>
          <ButtonContainer>
            <Button
              color={theme.colors.pink[500]}
              onClick={() => handleArchiveModalResponse(true)}
            >
              Yes
            </Button>
            <Button
              color={'darkgray'}
              onClick={() => handleArchiveModalResponse(false)}
            >
              No
            </Button>
          </ButtonContainer>
        </InfoModal>
      ) : null}
    </HeaderContainer>
  );
};

export default GameHeader;
