import { useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosInstance, updateCampaignStatus } from 'api';
import { map, sortBy } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  ServerQueryKey,
  FetchNavCampaignsResponse,
  FetchNavGamesResponse,
  FetchNavStudiosResponse,
  NavCampaign,
  NavStudio,
} from 'types';

const getNavigationStudios = async () => {
  const { data } = await axiosInstance.get<FetchNavStudiosResponse>(
    '/navigation/studios'
  );
  return data;
};

const getNavigationGames = async (studioId?: string) => {
  const { data } = await axiosInstance.get<FetchNavGamesResponse>(
    `/navigation/studio/${studioId}/games`
  );
  return data;
};

const getNavigationCampaigns = async (gameId?: string) => {
  const { data } = await axiosInstance.get<FetchNavCampaignsResponse>(
    `/navigation/game/${gameId}/campaigns`
  );
  return data;
};

export const useGetNavigationStudios = (options = {}) => {
  const query = useQuery(
    [ServerQueryKey.NavStudios],
    getNavigationStudios,
    options
  );

  const studios = query.data?.studios ?? [];

  return { ...query, data: studios };
};

export const useGetCountries = () => {
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData<FetchNavStudiosResponse>([
    ServerQueryKey.NavStudios,
  ]);

  const studios = cacheData?.studios ?? [];
  const countries = sortBy(
    [...new Set(map(studios, (studio: NavStudio) => studio.country))],
    country => country
  );

  // For select
  const selectCountries = map(countries, (country: string, idx: number) => {
    return { label: country, value: idx };
  });

  return { data: selectCountries };
};

export const useGetNavigationGames = (options = {}) => {
  const { studioId } = useParams<{
    studioId: string;
  }>();

  return useQuery(
    [ServerQueryKey.NavGames],
    () => getNavigationGames(studioId),
    options
  );
};

export const useGetNavigationCampaigns = () => {
  const { gameId } = useParams<{
    gameId: string;
  }>();

  const query = useQuery([ServerQueryKey.NavCampaigns], () =>
    getNavigationCampaigns(gameId)
  );

  const campaigns = useMemo(() => {
    const campaigns = query.data?.campaigns ?? [];
    const updatedCampaigns = map(campaigns, (campaign: NavCampaign) =>
      updateCampaignStatus(campaign)
    );
    return updatedCampaigns;
  }, [query.data]);

  return { ...query, data: { ...query.data, campaigns } };
};
