import { MonetizationStep } from 'types';
import { StyledCard } from './Card.styles';
import {
  getMonetizationIcon,
  getMonetizationStatusColors,
  getMonetizationStatusText,
} from './Card.utils';
import { Icon } from 'assets/styles';
import { TiInfo } from 'react-icons/ti';
import Tootltip from 'components/atoms/Tooltip/Tootltip';
import { useTheme } from 'styled-components';

interface CardProps extends Omit<MonetizationStep, 'subSteps'> {
  onClick?: () => void;
  isDisabled?: boolean;
}

const Card: React.FC<CardProps> = ({
  step,
  displayName,
  status,
  clickable,
  message,
  onClick,
  isDisabled,
}) => {
  const theme = useTheme();
  const handleClick = () => clickable && onClick && onClick();

  const styles = getMonetizationStatusColors(status);
  const icon = getMonetizationIcon(step);

  return (
    <StyledCard
      onClick={handleClick}
      clickable={clickable}
      disabled={isDisabled}
    >
      <div className="content">
        <Icon src={icon} style={{ maxWidth: '44px', maxHeight: '44px' }} />
        <span className="title">{displayName}</span>
        {message && (
          <Tootltip title={message}>
            <TiInfo size={24} color={theme.colors.blue[500]} />
          </Tootltip>
        )}
      </div>
      <div className="footer" style={{ ...styles }}>
        {getMonetizationStatusText(status)}
      </div>
    </StyledCard>
  );
};

export default Card;
