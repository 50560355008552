import { Genre, initialGenresData, Market } from 'types';
import { SidebarWrapper } from 'assets/styles';
import { ItemList } from 'components/Sidebar/Sidebar.styles';
import {
  Button,
  SidebarLink,
  SidebarLinkText,
  StudioInfoContainer,
} from 'components/atoms';
import { useGetGenres } from 'api';
import { ButtonGroup } from 'assets/styles';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { useSidebarRemoval } from 'hooks';

interface IdeationSidebarProps {
  selectedMarket: Market;
  selectedGenre: number;
  onMarketChange: (selected: Market) => void;
  onGenreChange: (selected: number) => void;
  toggleSidebar: () => void;
}

const IdeationSidebar = ({
  selectedMarket,
  selectedGenre,
  onMarketChange,
  onGenreChange,
  toggleSidebar,
}: IdeationSidebarProps) => {
  const theme = useTheme();
  const {
    data: { genres },
  } = useGetGenres(selectedMarket, {
    initialData: initialGenresData,
  });
  const [isRemoving, handleRemove] = useSidebarRemoval(toggleSidebar);

  return (
    <SidebarWrapper isRemoving={isRemoving}>
      <ButtonGroup>
        <Button
          primary={selectedMarket === 'sunday'}
          onClick={() => onMarketChange('sunday')}
        >
          Sunday
        </Button>
        <Button
          primary={selectedMarket === 'marketplace'}
          onClick={() => onMarketChange('marketplace')}
        >
          Marketplace
        </Button>
      </ButtonGroup>
      <ItemList>
        {genres.map((genre: Genre) => (
          <SidebarLink
            key={genre.id}
            onClick={() => onGenreChange(genre.id)}
            $active={selectedGenre === genre.id}
          >
            <StudioInfoContainer>
              <SidebarLinkText style={{ padding: 0 }}>
                {genre.name}
              </SidebarLinkText>
            </StudioInfoContainer>
          </SidebarLink>
        ))}
      </ItemList>
      <button className="collapse-button" onClick={handleRemove}>
        <MdOutlineKeyboardDoubleArrowLeft color={theme.colors.blue[500]} />
      </button>
    </SidebarWrapper>
  );
};

export default IdeationSidebar;
