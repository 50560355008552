import styled from 'styled-components';

export const StyledTagList = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .show-more {
    font-size: 12px;
    margin-left: 5px;
    min-width: 30px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .show-more-small {
    font-size: 11px;
    margin-left: 5px;
    min-width: 30px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid gray;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-result {
    color: ${({ theme }) => theme.colors.grey[1200]};
    font-weight: bold;
    font-size: 14px;
  }

  .no-result-small {
    color: ${({ theme }) => theme.colors.grey[1200]};
    font-size: 12px;
  }
`;
