import { createGlobalStyle } from 'styled-components';
import reset from './reset';
import app from './app';
import variables from './variables';
import gallery from './gallery';

export const GlobalStyles = createGlobalStyle`
${reset}
${app}
${variables}
${gallery}
`;
