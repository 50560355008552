import { useEffect, useState } from 'react';

export const useDocumentTitle = (...args: string[]) => {
  const [documentTitle, setDocumentTitle] = useState<string[]>(args);
  useEffect(() => {
    if (documentTitle) {
      documentTitle.push('Sundash');
      document.title = documentTitle.join(' · ');
    }
    return () => {
      document.title = 'Sundash';
    };
  }, [documentTitle]);

  const updateDocumentTitle = (...args: string[]) => {
    setDocumentTitle(args);
  };

  return { documentTitle, updateDocumentTitle };
};
