import { MouseEventHandler } from 'react';
import { spacing, Spacing } from 'assets/styles';
import styled from 'styled-components';

interface ActionButtonProps extends Spacing {
  iconSrc: string;
  buttonText: string;
  onClick: MouseEventHandler;
  color?: 'blue' | 'pink';
}

const ActionButton = ({
  iconSrc,
  buttonText,
  onClick,
  color = 'blue',
  ...spacing
}: ActionButtonProps) => (
  <StyledActionButton className={color} onClick={onClick} {...spacing}>
    <img src={iconSrc} alt={buttonText} className="icon" />
    <span className="text">{buttonText}</span>
  </StyledActionButton>
);

export default ActionButton;

const StyledActionButton = styled.button<Spacing>`
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  ${spacing}

  .icon {
    flex-shrink: 0;
  }

  .text {
    margin-left: 6px;
    font-size: 14px;
  }

  &.blue {
    color: ${({ theme }) => theme.colors.blue[500]};
  }

  &.pink {
    color: ${({ theme }) => theme.colors.pink[500]};
  }
`;
