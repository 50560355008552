import { DefaultTheme } from 'styled-components';
import { ThemeMode } from 'types';

const lightColorTokens = {
  pink: {
    500: '#e6006c',
    700: '#facce2',
  },
  grey: {
    500: '#808080',
    600: '#b0b0b0',
    700: '#8c8c8c',
    800: '#333333',
    900: '#afafaf',
    1000: '#cbcbcb',
    1100: '#e2e2e2',
    1200: '#c3c3c3',
    1300: '#d9d9d9',
    1400: '#d8d8d8',
    white: '#ffffff',
    offWhite: '#f6f6f6',
    black: '#000000',
  },
  blue: {
    500: '#2da1f8',
    600: '#2ea1f8',
    700: '#e5f5ff',
    800: '#abcdef',
  },
  green: {
    500: '#38e360',
    600: '#c4ffd',
  },
  brown: {
    500: '#bf7b49',
  },
  purple: {
    500: '#8242fc',
  },
  yellow: {
    500: '#ffe5ab',
  },
  orange: {
    500: '#ffc043',
  },
  lavender: {
    500: '#e7dbfe',
  },
};

const darkColorTokens = {};

export const themeSettings = (mode: ThemeMode): DefaultTheme => {
  const colors = mode === ThemeMode.Light ? lightColorTokens : darkColorTokens;
  return {
    mode,
    colors: { ...colors },
  } as DefaultTheme;
};
