import Skeleton from 'components/Skeleton/Skeleton';
import { HeaderColumn, HeaderContainer } from './PagesHeader.styles';
import styled from 'styled-components';

const GameHeaderSkeleton = () => {
  return (
    <HeaderContainer p={5}>
      <StyledHeaderColumn className="y-center" minHeight={80}>
        <Skeleton width="120px" height="120px" />
      </StyledHeaderColumn>
      <StyledHeaderColumn className="y-center full-width" pl={3} pr={2}>
        <Skeleton width="200px" height="32px" />
        <Skeleton width="200px" height="14px" />
        <Skeleton width="200px" height="14px" />
      </StyledHeaderColumn>
      <StyledHeaderColumn className="border-left" minWidth={250} p={2}>
        <Skeleton width="200px" height="32px" />
        <Skeleton width="200px" height="14px" />
      </StyledHeaderColumn>
      <StyledHeaderColumn className="border-left" minWidth={170} p={2}>
        <Skeleton width="200px" height="32px" />
        <Skeleton width="200px" height="14px" />
      </StyledHeaderColumn>
      <StyledHeaderColumn className="border-left" p={2} pr={0}>
        <Skeleton width="200px" height="32px" />
        <Skeleton width="200px" height="14px" />
      </StyledHeaderColumn>
    </HeaderContainer>
  );
};

const StyledHeaderColumn = styled(HeaderColumn)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default GameHeaderSkeleton;
