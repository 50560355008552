import { useQuery } from '@tanstack/react-query';
import { axiosFetch } from 'api/utils';
import { RawUser, User } from 'types';

const fetchUser = axiosFetch<RawUser, User>('/user');

export const useFetchUser = () => {
  return useQuery(['fetchUser'], fetchUser, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
