import { MonetizationStatus } from 'types';
import {
  applovin,
  unity,
  mintegral,
  liftoff,
  pangle,
  dtExchange,
  ironSource,
  max,
  smaato,
} from 'assets/images';

export const getMonetizationStatusText = (status: MonetizationStatus) => {
  let text;

  switch (status) {
    case MonetizationStatus.notSetup:
      text = 'Not Started';
      break;
    case MonetizationStatus.conflict:
      text = 'Conflict';
      break;
    case MonetizationStatus.failed:
      text = 'Failed';
      break;
    case MonetizationStatus.processing:
      text = 'Processing';
      break;
    case MonetizationStatus.successful:
      text = 'Successful';
      break;
    default:
      text = '';
  }

  return text;
};

export const getMonetizationStatusColors = (status: MonetizationStatus) => {
  let colors;

  switch (status) {
    case MonetizationStatus.notSetup:
      colors = {
        color: '#afafaf',
        backgroundColor: '#f6f6f6',
      };
      break;
    case MonetizationStatus.conflict:
      colors = {
        color: '#e6006c',
        backgroundColor: '#facce2',
      };
      break;
    case MonetizationStatus.failed:
      colors = {
        color: '#e6006c',
        backgroundColor: '#facce2',
      };
      break;
    case MonetizationStatus.processing:
      colors = {
        color: '#ffc043',
        backgroundColor: '#fff2d5',
      };
      break;
    case MonetizationStatus.successful:
      colors = {
        color: '#38e360',
        backgroundColor: '#c4ffd2',
      };
      break;
    default:
      colors = { color: '', backgroundColor: '' };
  }

  return colors;
};

export const getMonetizationIcon = (step: string) => {
  switch (step) {
    case 'appLovin':
      return applovin;
    case 'levelPlayApp':
      return unity;
    case 'mintegral':
      return mintegral;
    case 'pangle':
      return pangle;
    case 'liftoff':
      return liftoff;
    case 'dtExchange':
      return dtExchange;
    case 'ironSource':
      return ironSource;
    case 'max':
      return max;
    case 'smaato':
      return smaato;
    default:
      return '';
  }
};
