import { entries } from 'lodash';
import archiveIcon from 'assets/images/searchBar/Archived_Grey.svg';
import { GlobalSearch, GlobalSearchItem } from 'types';

const resultJSX = (title: string, result: GlobalSearchItem[]): JSX.Element => {
  return (
    <article className="result" key={title}>
      <span className="title">{title}</span>
      <ul className="items">
        {result.map(item => (
          <li className="item" key={item.id}>
            <a className="link global-search-links" href={item.url}>
              <span dangerouslySetInnerHTML={{ __html: item.name }} />
              {item.archived && (
                <img src={archiveIcon} alt="archived" height={16} width={16} />
              )}
            </a>
          </li>
        ))}
      </ul>
    </article>
  );
};

export const resultsJSX = (results: GlobalSearch): JSX.Element => {
  let resultsArray: JSX.Element[] = [];
  for (let [title, result] of entries(results)) {
    resultsArray = [...resultsArray, resultJSX(title, result)];
  }
  return <section className="results">{resultsArray}</section>;
};

export const numberOfResults = (results: GlobalSearch): number => {
  let totalResults: number = 0;
  for (let [, result] of entries(results)) {
    totalResults = totalResults + result.length;
  }
  return totalResults;
};
