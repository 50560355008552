import { basketBlack, invoiceUpload } from 'assets/images';
import clsx from 'clsx';
import { Modal } from 'components';
import { Button, DateCustomInput } from 'components/atoms';
import { ChangeEvent, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import styled, { useTheme } from 'styled-components';
import { BsCalendar } from 'react-icons/bs';
import { ModalSize } from 'types';
import { useUploadOldInvoice } from 'api';
import { DragAndDrop } from 'components/DragAndDrop/DragAndDrop';
import { useQueryClient } from '@tanstack/react-query';

interface CampaignDescriptionModalProps {
  onClose: () => void;
  onSubmit?: (text: string) => void;
  modalTitle: string;
  descriptionText: string;
  studioId: number;
}

const UploadPDFModal = ({
  onClose,
  modalTitle,
  descriptionText,
  studioId,
}: CampaignDescriptionModalProps) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [amount, setAmount] = useState('');
  const [amountHasError, setAmountHasError] = useState(false);
  const [fileHasError, setFileHasError] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedFile, setSelectedFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { mutate: uploadInvoice } = useUploadOldInvoice(studioId);

  const formattedDate = moment(selectedDate).format('DD.MM.YYYY');

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const validateAndHandleFile = (file: File) => {
    if (file.type === 'application/pdf' && file.size <= 10000000) {
      setFileHasError(false);
      setSelectedFile(file);
    } else {
      setFileHasError(true);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      validateAndHandleFile(selectedFile);
    }
  };

  const dropHandler = (files: FileList) => {
    const selectedFile = files[0];
    validateAndHandleFile(selectedFile);
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = Number(event.target.value);

    if (isNaN(inputValue)) {
      setAmountHasError(true);
      return;
    }
    setAmountHasError(!(inputValue > 0));
    setAmount(event.target.value);
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };
  const reqBody = new FormData();
  const handleUploadOldPdf = () => {
    if (selectedFile) {
      reqBody.append('file', selectedFile);
    }
    reqBody.append('submitDate', formattedDate.toString());
    reqBody.append('amount', amount);
    uploadInvoice(reqBody, {
      onSuccess: () => {
        toast.success('The invoice was successfully uploaded', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        queryClient.invalidateQueries(['getInvoices', studioId]);
        onClose();
      },
      onError: () => {
        toast.error('Something went wrong. Please, try again later.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        onClose();
      },
    });
  };

  useEffect(() => {
    setAmount(descriptionText);
  }, [descriptionText]);

  const disableSave =
    (descriptionText.length === 0 && amount.length === 0) ||
    amountHasError ||
    fileHasError ||
    !selectedFile ||
    (descriptionText.length !== 0 && descriptionText === amount);

  return (
    <Modal
      size={ModalSize.sm_md}
      isOpen={true}
      title={modalTitle}
      onClose={onClose}
    >
      <Container>
        <MainBoxWrapper>
          <RightColumnWrapper>
            <InputWrapper>
              <span>Date:</span>
              <DatePicker
                selected={selectedDate}
                onChange={(date: Date) => handleDateChange(date)}
                customInput={
                  <DateCustomInput
                    style={{ height: '29px', border: '1px solid #dfdfdf' }}
                  >
                    {moment(selectedDate).format('DD.MM.YYYY')}
                    <BsCalendar />
                  </DateCustomInput>
                }
              />
            </InputWrapper>
            <InputWrapper>
              <span>Amount:</span>
              <Input
                type="number"
                className={clsx({
                  'has-error': amountHasError,
                })}
                placeholder="Type something"
                value={amount}
                onChange={handleAmountChange}
              />
              {amountHasError && (
                <StyledErrorMessage>{`Amount must be greater than zero`}</StyledErrorMessage>
              )}
            </InputWrapper>
          </RightColumnWrapper>
          <LeftColumnWrapper>
            <DragAndDrop dropHandler={dropHandler}>
              <FileUploadBox onDragEnter={event => event.preventDefault()}>
                {selectedFile ? (
                  <FileBox>
                    <div>
                      <UploadLogoSm src={invoiceUpload} alt="upload invoice" />
                    </div>
                    <div>
                      <UploadText>{selectedFile?.name}</UploadText>
                    </div>
                    <div>
                      <DeleteIcon
                        onClick={() => setSelectedFile(undefined)}
                        src={basketBlack}
                        alt="delete basket"
                      />
                    </div>
                  </FileBox>
                ) : (
                  <UploadLogo src={invoiceUpload} alt="Your Logo" />
                )}

                <UploadButton onClick={handleFileButtonClick}>
                  {selectedFile ? 'Change file' : 'Upload PDF'}
                </UploadButton>
                <FileInput
                  type="file"
                  accept=".pdf"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                <TypeText>type: pdf</TypeText>
              </FileUploadBox>
            </DragAndDrop>
            {fileHasError && (
              <StyledErrorMessage>{`File size should not exceed 10MB`}</StyledErrorMessage>
            )}
          </LeftColumnWrapper>
        </MainBoxWrapper>
        <div className="button-wrapper">
          <StyledButton primary bgColor="#F066A7" onClick={onClose}>
            Cancel
          </StyledButton>
          <StyledButton
            primary
            bgColor={theme.colors.green[500]}
            disabled={disableSave}
            onClick={() => {
              handleUploadOldPdf();
            }}
          >
            Save
          </StyledButton>
        </div>
      </Container>
    </Modal>
  );
};

export default UploadPDFModal;

interface StyledButtonProps {
  bgColor: string;
}

const StyledButton = styled(Button)<StyledButtonProps>`
  background-color: ${props => props.bgColor};
  width: 140px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;
const RightColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const LeftColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const MainBoxWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
const FileUploadBox = styled.div`
  height: 200px;
  width: 300px;
  border: 2px dashed ${({ theme }) => theme.colors.blue[500]};
  background-color: ${({ theme }) => theme.colors.blue[700]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px;
  border-radius: 4px;
`;
const FileBox = styled.div`
  margin-top: 10px;
  display: flex;
  width: 80%;
  height: 56px;
  background-color: #ffffff;
  padding: 8px;
  margin-bottom: 12px;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
  border: 0.75px solid #d8d8d8;
`;
const UploadLogo = styled.img`
  width: 80px;
  height: 80px;
`;
const FileInput = styled.input`
  display: none;
`;
const UploadLogoSm = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;
const DeleteIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  cursor: pointer;
`;
const UploadButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blue[700]};
  color: ${({ theme }) => theme.colors.blue[500]};
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.colors.blue[500]};
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  overflow: hidden;

  input[type='file'] {
    display: none; /* Hide the file input */
  }
`;

const TypeText = styled.p`
  font-size: 16px;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.grey[700]};
  text-align: center;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .title {
    font-size: 20px;
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const UploadText = styled.p`
  font-size: 16px;
  color: #000;
  text-align: center;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2;
`;

const Input = styled.input`
  height: 29px;
  margin-top: 6px;
  width: 250px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  resize: none;
  outline: none;
  ::placeholder {
    color: #dfdfdf;
  }

  &.has-error {
    border-color: ${({ theme }) => theme.colors.pink[500]};
  }

  &:not(.has-error) {
    border-color: #dfdfdf;
  }
`;

const StyledErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.pink[500]};
  margin-top: 5px;
`;
