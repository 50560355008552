import { StyledMediaContainer } from './TestMediaContainer.styles';
import { BiSortDown, BiSortUp } from 'react-icons/bi';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { IPMSortOrder, TestMediaType } from 'types';
import { useState } from 'react';
import TestMediaLibrary from '../TestMediaLibrary/TestMediaLibrary';
import TestCreativeLibrary from '../TestCreativeLibrary/TestCreativeLibrary';

interface TestMediaContainerProps {
  title: string;
  testMediaType: TestMediaType;
}

const TestMediaContainer = ({
  title,
  testMediaType,
}: TestMediaContainerProps) => {
  const [ipmOrder, setIpmOrder] = useState(IPMSortOrder.DESC);
  const [hideUntested, setHideUntested] = useState(false);

  const handleIpmOrder = () =>
    setIpmOrder(
      ipmOrder === IPMSortOrder.ASC ? IPMSortOrder.DESC : IPMSortOrder.ASC
    );

  const handleHideUntested = () => setHideUntested(!hideUntested);

  return (
    <StyledMediaContainer>
      <div className="header">
        <span className="title">{title}</span>
        <div className="settings">
          {/* IPM */}
          <div className="config-setting" onClick={handleIpmOrder}>
            {ipmOrder === IPMSortOrder.DESC ? (
              <BiSortDown data-testid="sort-down-icon" />
            ) : (
              <BiSortUp data-testid="sort-up-icon" />
            )}
            <span style={{ marginLeft: 5 }}>IPM SORT</span>
          </div>
          {/* HIDE UNtested */}
          <div className="config-setting" onClick={handleHideUntested}>
            {hideUntested ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            <span style={{ marginLeft: 5 }}>
              {hideUntested ? 'VIEW' : 'HIDE'} UNTESTED
            </span>
          </div>
        </div>
      </div>
      <div className="body">
        {testMediaType === TestMediaType.VIDEO ? (
          <TestMediaLibrary ipmSort={ipmOrder} hideUntested={hideUntested} />
        ) : (
          <TestCreativeLibrary ipmSort={ipmOrder} hideUntested={hideUntested} />
        )}
      </div>
    </StyledMediaContainer>
  );
};

export default TestMediaContainer;
