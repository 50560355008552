import styled, { CSSObject } from 'styled-components';

interface BadgeProps {
  styles?: CSSObject;
}

export const Badge = styled.span<BadgeProps>`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props: BadgeProps) => props.styles}
`;
