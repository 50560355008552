// import { useEffect } from 'react';
import styled from 'styled-components';
import { FacebookCampaignStatus } from 'types';
import SidebarNavBlock from './SidebarNavBlock';
import { NavCampaign, NavGame, NavStudio } from 'types';
import { filter } from 'lodash';
import { useEffect, useRef } from 'react';
import SidebarNavSkeleton from './SidebarNavSkeleton';

interface SidebarNavProps {
  type: 'studio' | 'game' | 'campaign';
  data: NavStudio[] | NavGame[] | NavCampaign[];
  loading: boolean;
  showArchived?: boolean;
}
const SidebarNav = ({ type, data, loading, showArchived }: SidebarNavProps) => {
  const archivedBlockRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (showArchived && archivedBlockRef.current) {
      archivedBlockRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [showArchived]);

  if (type === 'studio' || type === 'game') {
    let activeData;
    let archivedData;
    if (type === 'studio') {
      const studios = data as NavStudio[];
      activeData = filter(studios, (item: NavStudio) => !item.isArchived);
      archivedData = filter(studios, (item: NavStudio) => item.isArchived);
    } else {
      const games = data as NavGame[];
      activeData = filter(games, (item: NavGame) => !item.isArchived);
      archivedData = filter(games, (item: NavGame) => item.isArchived);
    }

    return (
      <Container>
        {loading ? (
          <SidebarNavSkeleton type={type} />
        ) : (
          <>
            <SidebarNavBlock
              type={type}
              countText={`${activeData.length} Active`}
              data={activeData}
            />
            {showArchived && (
              <div ref={archivedBlockRef}>
                <SidebarNavBlock
                  countText={`${archivedData.length} Archived`}
                  data={archivedData}
                  type={type}
                />
              </div>
            )}
          </>
        )}
      </Container>
    );
  } else {
    if (loading) {
      return (
        <Container>
          <SidebarNavSkeleton type={type} />
        </Container>
      );
    }

    const campaigns = data as NavCampaign[];
    const pendingData = campaigns?.filter(
      (item: NavCampaign) =>
        item.status === FacebookCampaignStatus.pending ||
        item.status === FacebookCampaignStatus.review ||
        item.status === FacebookCampaignStatus.processing ||
        item.status === FacebookCampaignStatus.queued ||
        item.status === FacebookCampaignStatus.new ||
        item.status === FacebookCampaignStatus.scheduled
    );
    const testingData = campaigns?.filter(
      (item: NavCampaign) => item.status === FacebookCampaignStatus.active
    );
    const completedData = campaigns?.filter(
      (item: NavCampaign) =>
        item.status === FacebookCampaignStatus.completed ||
        item.status === FacebookCampaignStatus.failed ||
        item.status === FacebookCampaignStatus.terminated
    );

    return (
      <Container>
        <SidebarNavBlock
          countText={`${pendingData.length} Pending`}
          data={pendingData}
          type={type}
        />
        <SidebarNavBlock
          countText={`${testingData.length} Testing`}
          data={testingData}
          type={type}
        />
        <SidebarNavBlock
          countText={`${completedData.length} Completed`}
          data={completedData}
          type={type}
        />
      </Container>
    );
  }
};

const Container = styled.section`
  flex-grow: 1;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default SidebarNav;
