import { chartTitleGenerator } from 'pages/Test/Charts/utils/GlobalUtilsTsx';
import {
  ChartContainer,
  ChartInfoContainer,
  ChartInnerContainer,
} from '../CompareChart.styles';
import {
  cohortIndexArrayGenerator,
  cohortLabelsGenerator,
  getChartTitleAndTooltip,
} from 'pages/Test/Charts/utils/GlobalUtilsTs';
import { AvgAdImpPerUserChartData, BusinessChartType } from 'types';
import {
  comparisonColors,
  generateCampaignDatasets,
  lineColors,
} from './AvgAdImpPerUserCompare.utils';
import { Line } from 'react-chartjs-2';
import { chartOptions } from '../CompareChart.utils';
import { useCampaignAndAppVersionParams } from 'hooks';

interface AvgAdImpPerUserCompareProps {
  dataOne: AvgAdImpPerUserChartData;
  dataTwo: AvgAdImpPerUserChartData;
}

const AvgAdImpPerUserCompare: React.FC<AvgAdImpPerUserCompareProps> = ({
  dataOne,
  dataTwo,
}) => {
  const { campaignName1, campaignName2, appVersion1, appVersion2 } =
    useCampaignAndAppVersionParams();

  const datasetsOne = generateCampaignDatasets(
    dataOne,
    lineColors,
    campaignName1,
    appVersion1
  );

  const datasetsTwo = generateCampaignDatasets(
    dataTwo,
    comparisonColors,
    campaignName2,
    appVersion2
  );
  const datasets = [...datasetsOne, ...datasetsTwo];

  const labelsOne = Object.keys(dataOne.interstitialRewardedTotal || {});
  const labelsTwo = Object.keys(dataTwo.interstitialRewardedTotal || {});
  const numOfCols = Math.max(labelsOne.length, labelsTwo.length);
  const labels = cohortLabelsGenerator(
    cohortIndexArrayGenerator(numOfCols, 1),
    'Day '
  );

  const { title, tooltip } = getChartTitleAndTooltip(
    BusinessChartType.AD_IMPRESSIONS_PER_AVG_USER
  );
  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
      </ChartInfoContainer>
      <ChartInnerContainer>
        <Line options={chartOptions} data={{ labels, datasets }} height={400} />
      </ChartInnerContainer>
    </ChartContainer>
  );
};

export default AvgAdImpPerUserCompare;
