import styled from 'styled-components';

export const AccordionContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
`;

export const Header = styled.div<{ isDisabled: boolean }>`
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #ffff;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  padding: 0 16px;
  color: ${({ isDisabled }) => (isDisabled ? '#999' : 'inherit')};
`;

export const HeaderText = styled.div`
  flex-grow: 1;
  text-align: center;
`;

export const ContentWrapper = styled.div<{
  isOpen: boolean;
  contentHeight: number;
}>`
  max-height: ${({ isOpen, contentHeight }) =>
    isOpen ? `${contentHeight}px` : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
`;

export const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Arrow = styled.div<{ isOpen: boolean }>`
  border: solid #666;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-135deg)' : 'rotate(45deg)')};
  transition: transform 0.3s;
  margin-left: auto;
`;
