import styled from 'styled-components';

interface Props {
  cellWidth: number;
}

const StyledDataGrid = styled.div<Props>`
  /* display: flex; */
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1 0 auto;
  width: 100%;

  .table {
    display: flex;
    flex-direction: column;
    width: fit-content;
    color: #424242;

    .table-head,
    .table-body {
      display: flex;
      flex-direction: column;

      .table-row {
        display: flex;

        .table-cell {
          display: flex;
          font-size: 14px;

          &:not(.select) {
            min-width: ${props => props.cellWidth + 'px'};
            max-width: ${props => props.cellWidth + 'px'};
          }

          &.select {
            min-width: 42px;
            max-width: 42px;

            .cell-content {
              display: flex;
              justify-content: center;

              .checkbox {
                cursor: pointer;
                margin: 0;
              }
            }
          }

          .cell-content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 12px;
          }

          .cell-content,
          .custom-cell-content {
            width: 100%;
          }
        }
      }
    }

    .table-head {
      .table-row {
        .table-cell {
          font-weight: bold;
        }
      }
    }

    .table-body {
      .table-row {
        transition: background-color 0.2s;

        &:last-of-type {
          border-bottom: none;

          .table-cell {
            font-weight: normal;
          }
        }

        &:hover,
        &.selected {
          background-color: #f5f5f5;
        }
      }
    }
  }
`;

export default StyledDataGrid;
