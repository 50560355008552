import styled, { css } from 'styled-components';

interface Props {
  error?: boolean;
  hasAsterisk?: boolean;
  sidenav?: boolean;
  fullWidth?: boolean;
}

export const TextInput = styled.input<Props>`
  border: ${props =>
    props.error
      ? `2px solid ${props.theme.colors.pink[500]}`
      : `1px solid ${props.theme.colors.grey[900]}`};
  outline-color: ${props =>
    props.error ? props.theme.colors.pink[500] : props.theme.colors.grey[900]};
  border-radius: 4px;
  text-indent: 19px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 19px;
  margin: 6px 0 20px;
  width: 100%;
  height: 54px;

  ${() =>
    window.innerHeight <= 720
      ? css`
          height: 44px;
        `
      : css`
          height: 54px;
        `}

  ::placeholder {
    color: ${props =>
      props.hasAsterisk ? props.theme.colors.grey.black : '#B5B5B5'};
  }
`;
