import React from 'react';
import Select, { CSSObjectWithLabel, Theme } from 'react-select';
import { useTheme } from 'styled-components';
import { SelectOption } from 'types';

interface StyledSelectProps {
  isSearchable?: boolean;
  isDisabled: boolean;
  options: SelectOption[];
  defaultValue?: SelectOption;
  onMouseEnter: () => void;
  onChange: (option: any) => void;
}
const StyledSelect: React.FC<StyledSelectProps> = React.forwardRef(
  (
    {
      isSearchable = false,
      isDisabled,
      options,
      defaultValue,
      onMouseEnter,
      onChange,
    },
    ref
  ) => {
    // sundash theme
    const theme = useTheme();

    // react-select theme
    const selectTheme = (selectTheme: Theme) => ({
      ...selectTheme,
      borderColor: theme.colors.blue[500],
    });

    const height = {
      minHeight: '28px',
      maxHeight: '28px',
    };
    const width = {
      width: '250px',
    };

    const styles = {
      control: (baseStyles: CSSObjectWithLabel, state: any) => ({
        ...baseStyles,
        backgroundColor: state.isDisabled ? theme.colors.grey[1400] : '',
        boxShadow: 'none',
        ...width,
        ...height,
      }),
      valueContainer: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        ...height,
      }),
      indicatorSeparator: () => ({
        color: 'none',
      }),
      indicatorsContainer: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        ...height,
      }),
      container: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        ...width,
        ...height,
      }),
      dropdownIndicator: (baseStyles: CSSObjectWithLabel, state: any) => ({
        ...baseStyles,
        color: state.isDisabled ? '#afafaf' : theme.colors.blue[500],
      }),
      menuPortal: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        zIndex: 9999,
      }),
      menuList: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        maxHeight: '200px',
        borderRadius: '4px',
      }),
    };
    return (
      <div onMouseEnter={onMouseEnter}>
        <Select
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          options={options}
          defaultValue={defaultValue}
          styles={styles}
          theme={selectTheme}
          menuPortalTarget={document.body}
          onChange={onChange}
          ref={ref as any}
        />
      </div>
    );
  }
);

export default StyledSelect;
