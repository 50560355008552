import { Modal } from 'components';
import StepThree from 'components/AddCampaignModal/ActivationStep/ActivationStep';
import { Button } from 'components/atoms';
import { useState } from 'react';
import { ActivationTypes } from 'types';
import { toast } from 'react-toastify';
import { Wrapper } from './RestartCampaignModal.styles';
import { makeGameLink, useRecreateCampaign } from 'api';
import { useNavigate, useParams } from 'react-router-dom';

interface RestartCampaignModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const RestartCampaignModal = ({
  isOpen,
  onClose,
}: RestartCampaignModalProps) => {
  const navigate = useNavigate();
  const params = useParams<{
    studioId: string;
    gameId: string;
    campaignId: string;
  }>();
  const gameId = Number(params.gameId);
  const studioId = Number(params.studioId);
  const campaignId = Number(params.campaignId);

  const { mutate: restartCampaign } = useRecreateCampaign();
  const [activationType, setActivationType] = useState<ActivationTypes>(
    ActivationTypes.asap
  );
  const [activationStartDate, setActivationStartDate] = useState('');
  const [activationStartTime, setActivationStartTime] = useState('');

  const disableRestartButton =
    activationType === ActivationTypes.scheduled &&
    (activationStartDate === '' || activationStartTime === '');

  const handleActivationType = (type: ActivationTypes) => {
    setActivationType(type);
  };

  const handleActivationStartDate = (startDate: string) => {
    setActivationStartDate(startDate);
  };

  const handleActivationStartTime = (startTime: string) => {
    setActivationStartTime(startTime);
  };

  const handleCancel = () => {
    onClose();
  };
  const handleRestartCampaignSuccess = (text: string) => {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  const handleSubmit = () => {
    restartCampaign(
      {
        id: campaignId,
        activationType: activationType,
        startAt: `${activationStartDate} ${activationStartTime}`,
      },
      {
        onSuccess: () => {
          navigate(makeGameLink({ studioId, gameId }));
          handleRestartCampaignSuccess('The test was successfully restarted')
        },
        onError: () => {},
      }
    );
  };

  return (
    <Modal isOpen={isOpen} title="Restart as new" onClose={onClose}>
      <Wrapper>
        <div className="content">
          <StepThree
            selectedType={activationType}
            onTypeSelect={handleActivationType}
            onDateSelect={handleActivationStartDate}
            onTimeSelect={handleActivationStartTime}
          />
        </div>
        <div>
          <Button onClick={handleCancel}>CANCEL</Button>
          <Button
            primary
            onClick={handleSubmit}
            disabled={disableRestartButton}
          >
            RESTART AS NEW
          </Button>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default RestartCampaignModal;
