import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { routes } from 'configs/routes';
import NotFound from 'pages/NotFound/NotFound';
import Home from 'pages/Home/Home';
import Login from 'pages/Login/Login';
import DefaultRedirect from 'pages/DefaultRedirect/DefaultRedirect';
import { Layout } from 'views/layout';
import Logout from 'pages/Logout/Logout';
import StudioPage from 'pages/Studio/StudioPage';
import GamePage from 'pages/Game/GamePage';
import {
  TestPage,
  NewslettersList,
  NewsletterDetail,
  CompareVersions,
} from 'pages';
import NewTest from 'pages/Test/new/NewTest';
import IdeationPage from 'pages/Ideation/IdeationPage';
import { GlobalStyles } from 'assets/styles';
import PrivacyPolicy from 'pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { checkTokenExists, setPrevLocation } from 'services/api';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { themeSettings } from 'theme/theme';
import { ThemeMode } from 'types';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  setPrevLocation(location.pathname);
  if (!checkTokenExists()) {
    return <Navigate to={routes.login} replace={true} />;
  }
  return children;
};

const App = () => {
  const theme: DefaultTheme = themeSettings(ThemeMode.Light);
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={<DefaultRedirect isAuthenticated={checkTokenExists()} />}
          />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.logout} element={<Logout />} />
          <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={routes.notFound} element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
          <Route
            element={
              <RequireAuth>
                <Layout />
              </RequireAuth>
            }
          >
            <Route path={routes.home} element={<Home />} />

            <Route path={'/studio/:studioId'} element={<StudioPage />} />
            <Route
              path={'/studio/:studioId/:tabName'}
              element={<StudioPage />}
            />

            <Route
              path={'/studio/:studioId/game/:gameId/:tabName'}
              element={<GamePage />}
            />
            <Route
              path={'/studio/:studioId/game/:gameId'}
              element={<GamePage />}
            />
            <Route
              path={'/studio/:studioId/game/:gameId/test/:campaignId'}
              element={<TestPage />}
            />
            <Route
              path={'/studio/:studioId/game/:gameId/test/:campaignId/:tabName'}
              element={<TestPage />}
            />
            <Route
              path="/studio/:studioId/game/:gameId/compare-versions"
              element={<CompareVersions />}
            />
            <Route
              path={'/studio/:studioId/game/:gameId/new-test'}
              element={<NewTest />}
            />
            <Route
              path={'/ideation/:market/:genreId'}
              element={<IdeationPage />}
            />
            <Route path={'/newsletters'} element={<NewslettersList />} />
            <Route
              path={'/newsletters/:newsletterId'}
              element={<NewsletterDetail />}
            />
            {/* For old routes */}
            <Route
              path={'/game/:gameId/test/:campaignId'}
              element={<TestPage />}
            />
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  );
};

export default App;
