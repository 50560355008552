import { Modal } from 'components/molecules';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { InputContainer } from 'components/AddStudioModal/AddStudioModal.styles';
import { Button, DateCustomInput, Label, Spinner } from 'components/atoms';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import moment from 'moment';
import { BsCalendar } from 'react-icons/bs';
import styled, { useTheme } from 'styled-components';
import { SpinnerContainer } from 'assets/styles';
import { useState } from 'react';
import { useCreateReport } from 'api';
import { CreateReportRequestBody } from 'types';
import { range } from 'lodash';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { AxiosResponse } from 'axios';

interface RequestReportModalComponentProps {
  onClose: () => void;
}

interface RequestReportModalProps extends RequestReportModalComponentProps {
  visible: boolean;
}

export const RequestReportModal = ({
  visible,
  ...props
}: RequestReportModalProps) => {
  if (!visible) {
    return null;
  }

  return <RequestReportModalComponent {...props} />;
};

const RequestReportModalComponent = ({
  onClose,
}: RequestReportModalComponentProps) => {
  const theme = useTheme();
  const { mutate: requestReport } = useCreateReport();
  const [reportFeedbackMessage, setReportFeedbackMessage] =
    useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const validationSchema = Yup.object().shape({
    startDate: Yup.date().max(
      new Date(),
      'Start date should not be future dates'
    ),
    endDate: Yup.date().when('startDate', (startDate: any, schema: any) => {
      if (startDate) {
        const dayAfter = new Date(startDate.getTime() + 86400000);
        return schema.min(dayAfter, 'End date has to be after than start date');
      }
      return schema;
    }),
    testType: Yup.array().min(1, 'At least one test type must be selected'),
    finishedTests: Yup.bool(),
    onlyMyGames: Yup.bool(),
  });

  const submit = (fieldValues: FieldValues) => {
    setLoading(true);
    const data: CreateReportRequestBody = {
      dateFrom: moment(fieldValues.startDate).format('DD.MM.YYYY'),
      dateTo: moment(fieldValues.endDate).format('DD.MM.YYYY'),
      testType: fieldValues.testType,
      finishedTests: fieldValues.finishedTests,
      myGames: fieldValues.onlyMyGames,
    };
    requestReport(data, {
      onSuccess: (response: AxiosResponse<any, any>) => {
        if (response.status === 200)
          setReportFeedbackMessage(
            'Your report has been generated and sent to you via email.'
          );
        else if (response.status === 204)
          setReportFeedbackMessage(
            'No data found for your report. Please, refine your filters and try again.'
          );
        setLoading(false);
      },
    });
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const firstDayOfTheCurrentMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );

  const getMonth = (date: Date) => {
    return date.getMonth();
  };

  const getYear = (date: Date) => {
    return date.getFullYear();
  };

  const years = range(2021, getYear(new Date()) + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const getCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
  }: ReactDatePickerCustomHeaderProps) => (
    <DatePickerHeader>
      <AiOutlineArrowLeft className="pointer" onClick={decreaseMonth} />

      <select
        className="pointer"
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(Number(value))}
      >
        {years.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        className="pointer"
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <AiOutlineArrowRight className="pointer" onClick={increaseMonth} />
    </DatePickerHeader>
  );

  return (
    <Modal
      onClose={onClose}
      title={'Request Report'}
      containerStyle={{
        minHeight: 450,
      }}
    >
      {loading ? (
        <SpinnerContainer>
          <Spinner size={48} color="#bababa" />
        </SpinnerContainer>
      ) : !loading && !!reportFeedbackMessage.length ? (
        <FeedbackContainer>
          <FeedbackTitle>{reportFeedbackMessage}</FeedbackTitle>
          <Button color={theme.colors.pink[500]} onClick={onClose}>
            I understand
          </Button>
        </FeedbackContainer>
      ) : (
        <>
          <FormContainer>
            <InputContainer>
              <Label htmlFor="startDate">Start Date *:</Label>
              <Controller
                control={control}
                name="startDate"
                defaultValue={firstDayOfTheCurrentMonth}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value}
                    onChange={(date: Date) => onChange(date)}
                    renderCustomHeader={getCustomHeader}
                    customInput={
                      <DateCustomInput
                        style={{ margin: 0 }}
                        error={!!errors.startDate}
                      >
                        {moment(value).format('DD.MM.YYYY')}
                        <BsCalendar />
                      </DateCustomInput>
                    }
                  />
                )}
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="endDate">End date: </Label>
              <Controller
                control={control}
                name="endDate"
                defaultValue={new Date()}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value}
                    onChange={(date: Date) => onChange(date)}
                    renderCustomHeader={getCustomHeader}
                    customInput={
                      <DateCustomInput
                        style={{ margin: 0 }}
                        error={!!errors.endDate}
                      >
                        {moment(value).format('DD.MM.YYYY')}
                        <BsCalendar />
                      </DateCustomInput>
                    }
                  />
                )}
              />
            </InputContainer>
            <InputContainer>
              <CheckboxContainer error={!!errors.testType}>
                <Label>Test Type: </Label>
                <span>
                  <Label>IPM </Label>
                  <input
                    type="checkbox"
                    {...register('testType')}
                    value="ipm"
                    defaultChecked
                  />
                </span>
                <span>
                  <Label>MVP </Label>
                  <input
                    type="checkbox"
                    {...register('testType')}
                    value="mvp"
                    defaultChecked
                  />
                </span>
                <span>
                  <Label>SCB </Label>
                  <input
                    type="checkbox"
                    {...register('testType')}
                    value="scb"
                    defaultChecked
                  />
                </span>
                <span>
                  <Label>CTM </Label>
                  <input
                    type="checkbox"
                    {...register('testType')}
                    value="ctm"
                    defaultChecked
                  />
                </span>
              </CheckboxContainer>
            </InputContainer>
            <InputContainer>
              <CheckboxContainer>
                <Label>Only Finished Tests: </Label>
                <input type="checkbox" {...register('finishedTests')} />
              </CheckboxContainer>
            </InputContainer>
            <InputContainer>
              <CheckboxContainer>
                <Label>Only My Games: </Label>
                <input type="checkbox" {...register('onlyMyGames')} />
              </CheckboxContainer>
            </InputContainer>
          </FormContainer>
          <Button onClick={handleSubmit(submit)}>Submit</Button>
        </>
      )}
    </Modal>
  );
};

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 32px;
  width: 100%;
  margin-bottom: 20px;
`;

interface CheckboxContainerProps {
  error?: boolean;
}

const CheckboxContainer = styled.div<CheckboxContainerProps>`
  height: 54px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey[900]};
  border: ${props =>
    props.error
      ? `2px solid ${props.theme.colors.pink[500]}`
      : `2px solid ${props.theme.colors.grey[900]}`};
`;

const FeedbackContainer = styled.div`
  margin: auto;
  text-align: center;
`;

const FeedbackTitle = styled.div`
  font-size: 22px;
  padding: 12px;
  text-align: center;
`;

const DatePickerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;

  .pointer {
    cursor: pointer;
  }
`;
