// import { StudioLogo } from 'components/atoms';
import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardCount,
  CardFooter,
  CardHeader,
  CardHeaderInfo,
  CardTitle,
} from './PublishingCard.styles';
import { PublishingCardRow } from './PublishingCardRow';
import { GameOverviewCategory, OverviewGame } from 'types';
import { StudioLogo } from 'components/atoms';
import clsx from 'clsx';
import { useTheme } from 'styled-components';

interface PublishingCardProps {
  data: GameOverviewCategory;
  headerURL: string;
}

export const PublishingCard = ({ data, headerURL }: PublishingCardProps) => {
  const theme = useTheme();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [cardRows, setCardRows] = useState<OverviewGame[]>([]);

  const hasLongData = data.games.length > 4;

  const handleCollapse = () => setShowAll(!showAll);

  useEffect(() => {
    const updateCardRows = (showAll: boolean, hasLongData: boolean) => {
      const filteredData: OverviewGame[] =
        hasLongData && !showAll ? data.games.slice(0, 4) : data.games;
      setCardRows(filteredData);
    };
    updateCardRows(showAll, hasLongData);
  }, [showAll, hasLongData, data.games]);

  return (
    <Card>
      <CardHeader
        to={headerURL}
        target="_blank"
        rel="noopener noreferrer"
        className={clsx({ 'disable-link': headerURL.length === 0 })}
      >
        <StudioLogo
          name={data.name}
          url={data.logo}
          border={`2px solid ${theme.colors.pink[500]}`}
          medium
        />
        <CardHeaderInfo>
          <CardTitle>{data.name}</CardTitle>
          <CardCount>
            {data.games.length} game{data.games.length !== 1 ? 's' : null}
          </CardCount>
        </CardHeaderInfo>
      </CardHeader>
      <CardContent>
        {cardRows.map((game: OverviewGame) => (
          <PublishingCardRow key={game.id} data={game} />
        ))}
      </CardContent>
      {hasLongData && (
        <CardFooter onClick={handleCollapse}>
          {showAll ? 'View less' : `View all ${data.games.length} games`}
        </CardFooter>
      )}
    </Card>
  );
};
