import { Badge } from '../Badge';
import { getBadgeProperties } from './InvoiceStatusBadge.utils';
import { InvoiceStatus } from 'types/invoice';

interface InvoiceStatusBadgeProps {
  invoiceStatusData: InvoiceStatus;
}

const InvoiceStatusBadge = ({invoiceStatusData}: InvoiceStatusBadgeProps) => {
  const { text, styles } = getBadgeProperties(invoiceStatusData);
  return <Badge styles={styles}>{text}</Badge>;
};


export default InvoiceStatusBadge;
