import OverviewList from 'components/OverviewList/OverviewList';
import styled from 'styled-components';
import { InvoiceItem } from 'types';
import { invoiceReviewRows } from '../InvoiceOverview.utils';

interface ListItemsProps {
  invoiceItems: InvoiceItem[];
  currency: string;
}
const ReviewListItems = ({ invoiceItems, currency }: ListItemsProps) => {
  const columns = [
    {
      id: 'agreement',
      name: 'Item / Agreement',
      width: 500,
    },
    {
      id: 'quantity',
      name: 'Quantity',
      width: 10,
    },
    {
      id: 'price',
      name: 'Price',
      width: 10,
    },
    {
      id: 'sum',
      name: 'Sum',
      width: 10,
    },
  ];

  return (
    <Container>
      <OverviewList
        columns={columns}
        blocks={[[...invoiceReviewRows(invoiceItems, currency)]]}
        noDataText="No Items added"
      />
    </Container>
  );
};

export default ReviewListItems;

const Container = styled.div`
  max-height: 350px;
  min-height: 200px;
  overflow: auto;
`;
