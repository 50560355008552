import { useState, ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { Button, Spinner, TextArea } from 'components/atoms';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'components/molecules';
import { useFetchGame, newCampaignLinkGenerator } from 'api';
import { CampaignTypes, defaultGame } from 'types';

interface AddTestModalComponentProps {
  studioId: number;
  gameId: number;
  onClose: () => void;
}

interface AddTestModalProps extends AddTestModalComponentProps {
  visible: boolean;
}

export const AddTestModal = ({ visible, ...props }: AddTestModalProps) => {
  if (!visible) {
    return null;
  }

  return <AddTestModalComponent {...props} />;
};

const descriptionMaxLength = 1000;

const AddTestModalComponent = ({
  studioId,
  gameId,
  onClose,
}: AddTestModalComponentProps) => {
  const navigate = useNavigate();
  const useFetchGameQuery = useFetchGame(gameId, {
    initialData: defaultGame,
  });
  const [type, setType] = useState<CampaignTypes>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = useState<string>('');
  const [hasDescriptionError, sethasDescriptionError] =
    useState<boolean>(false);

  const close = () => {
    setIsSaving(false);
    setType(undefined);
    onClose();
  };

  const submit = () => {
    setIsSaving(!isSaving);
    /**
     * @todo handle test-description and test-type in React-Query
     * **/
    localStorage.setItem('testDescription', descriptionValue);
    localStorage.setItem('testType', type ?? '');
    close();
    const newCampaignLink = newCampaignLinkGenerator(studioId, gameId);
    navigate(newCampaignLink);
  };

  const handleDescriptionValue = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newDescription = event.target.value;
    setDescriptionValue(newDescription);
    newDescription.length >= descriptionMaxLength
      ? sethasDescriptionError(true)
      : sethasDescriptionError(false);
  };

  const handleShowDescription = () => {
    setShowDescription(!showDescription);
    setDescriptionValue('');
    sethasDescriptionError(false);
  };

  if (useFetchGameQuery.isSuccess) {
    const { data: game } = useFetchGameQuery;
    return (
      <Modal onClose={close} title={''} containerStyle={{ width: '850px' }}>
        <FormTitle> Please select the Test type</FormTitle>
        <FormLargeButtonContainer>
          <FormLargeButton
            selected={type === CampaignTypes.ipm}
            onClick={() => setType(CampaignTypes.ipm)}
            disabled={!game?.isFacebookConfigured}
          >
            <FormLargeButtonHeader>IPM</FormLargeButtonHeader>
            <FormLargeButtonDetails>
              An initial test in social networks to see the players' engagement
              with the game idea.
            </FormLargeButtonDetails>
          </FormLargeButton>
          <FormLargeButton
            selected={type === CampaignTypes.mvp}
            onClick={() => setType(CampaignTypes.mvp)}
            disabled={!game?.isFacebookConfigured || !game?.justTrackToken}
            style={{ marginBottom: '30px' }}
          >
            <FormLargeButtonHeader>MVP</FormLargeButtonHeader>
            <FormLargeButtonDetails>
              Detailed test with more game levels to understand the players'
              retention and how to improve it.
            </FormLargeButtonDetails>
          </FormLargeButton>
          {showDescription ? (
            <DescriptionWrapper>
              <DescriptionHeader>
                <span>Test Description</span>
                <DescriptionLink onClick={handleShowDescription}>
                  &#215; Remove
                </DescriptionLink>
              </DescriptionHeader>
              <TextArea
                rows={4}
                autoFocus
                error={hasDescriptionError}
                maxLength={descriptionMaxLength}
                value={descriptionValue}
                onChange={handleDescriptionValue}
              />
              {hasDescriptionError ? (
                <ErrorMessage>
                  {`The maximum length for this field is ${descriptionMaxLength} characters.`}
                </ErrorMessage>
              ) : null}
            </DescriptionWrapper>
          ) : (
            <DescriptionLink onClick={handleShowDescription}>
              &#43; Add Description
            </DescriptionLink>
          )}
          <Button
            style={{ marginTop: '40px', width: '180px' }}
            primary
            onClick={submit}
            disabled={isSaving || !type || hasDescriptionError}
          >
            {isSaving ? <Spinner size="14px" /> : 'Add Videos'}
          </Button>
        </FormLargeButtonContainer>
      </Modal>
    );
  }
  return null;
};

const FormTitle = styled.div`
  margin: 30px 0;
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  white-space: nowrap;
  text-align: center;
`;

const FormLargeButtonContainer = styled.div`
  max-width: 800px;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  align-items: center;
`;

const FormLargeButtonHeader = styled.h1`
  width: 30%;
  margin: auto;
  font-size: 32px;
`;

const FormLargeButtonDetails = styled.div`
  width: 80%;
  margin: auto;
  font-size: 20px;
  text-align: left;
`;

interface FormLargeButtonProps {
  selected: boolean;
}

const FormLargeButton = styled.button<FormLargeButtonProps>`
  background-color: ${({ theme }) => theme.colors.grey.white};
  border: 2px solid ${({ theme }) => theme.colors.blue[500]};
  color: ${({ theme }) => theme.colors.blue[500]};
  height: 90px;
  width: 100%;
  border-radius: 4px;
  font-family: 'Rubik', sans-serif;
  display: flex;
  justify-content: space-around;
  margin: 5px 0px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.offWhite};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey.white};
    color: ${({ theme }) => theme.colors.grey[1000]};
    border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
    cursor: not-allowed;

    &:hover {
      border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
      background-color: ${({ theme }) => theme.colors.grey[1000]};
      color: ${({ theme }) => theme.colors.grey.white};
    }
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${({ theme }) => theme.colors.blue[500]};
      color: ${({ theme }) => theme.colors.grey.white};

      &:hover {
        background-color: ${({ theme }) => theme.colors.blue[500]};
        color: ${({ theme }) => theme.colors.grey.white};
      }
    `};
`;

const DescriptionLink = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blue[500]};
  padding: 7px;
  cursor: pointer;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
`;

const DescriptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.pink[500]};
`;
