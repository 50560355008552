import { Button } from 'components/atoms';
import { Modal } from 'components/molecules';
import styled from 'styled-components';

interface FeedbackModalComponentProps {
  onClose: () => void;
  modalTitle: string;
  contentTitle: string;
  content: string | React.ReactNode;
  footerMsg: string;
  buttonTitle: string;
  onClick: () => void;
}

interface FeedbackModalProps extends FeedbackModalComponentProps {
  visible: boolean;
}

const FeedbackModal = ({ visible, ...props }: FeedbackModalProps) => {
  if (!visible) {
    return null;
  }
  return <FeedbackModalComponent {...props} />;
};

const FeedbackModalComponent = (props: FeedbackModalComponentProps) => {
  return (
    <Modal onClose={props.onClose} title={props.modalTitle}>
      <>
        <FeedbackContainer>
          <span>{props.contentTitle}</span>
          <span className="content">{props.content}</span>
          <span className="footer">{props.footerMsg}</span>
        </FeedbackContainer>
        <Button onClick={props.onClick}>{props.buttonTitle}</Button>
      </>
    </Modal>
  );
};

export default FeedbackModal;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 18px;

  .content {
    color: #afafaf;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .footer {
    color: #afafaf;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
