import { useEditGameConfig, useFetchGameConfigs } from 'api';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { GameConfig } from 'types';
import { useState } from 'react';
import { convertConfigsToJSON } from './GameConfigs.utils';
import GameConfigsList from './GameConfigsList';
import { OverviewListSkeleton } from 'components';

export const GameConfigsContent = () => {
  const params = useParams<{ gameId: string }>();
  const gameId = Number(params.gameId);
  const [configsJSON, setConfigsJSON] = useState<any>({});
  const [activeConfigKey, setActiveConfigKey] = useState('');
  const { mutate: editGameConfig } = useEditGameConfig(gameId);
  const {
    data: configs,
    isSuccess: isConfigsSuccess,
    refetch: refetchGameConfigs,
    isLoading: isConfigsLoading,
  } = useFetchGameConfigs(gameId, {
    initialData: [],
    onSuccess: (configs: GameConfig[]) => {
      const configsCopy = convertConfigsToJSON(configs);
      setConfigsJSON(configsCopy);
    },
  });

  const handleActiveConfig = (configKey: string) => {
    setActiveConfigKey(configKey);
  };

  const handleEditConfigSuccess = (text: string) => {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleEditConfigError = () => {
    toast.error('Something went wrong. Please, try again later.', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleInputChange = (key: string, value: string | null) => {
    setConfigsJSON({ ...configsJSON, [key]: value });
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    configKey: string
  ) => {
    if (e.key === 'Enter') {
      handleOnSave(configKey);
    }
  };

  const handleOnSave = (key: string) => {
    const actualValue = configs?.find(
      (config: GameConfig) => config.key === key
    )?.value;
    const editedValue = configsJSON[key];

    if (actualValue !== editedValue) {
      const value =
        String(configsJSON[key]).length === 0 ? null : configsJSON[key];
      editGameConfig(
        { key, value },
        {
          onSuccess: () => {
            handleEditConfigSuccess('Value was successfully edited');
            refetchGameConfigs();
            handleActiveConfig('');
          },
          onError: () => {
            handleEditConfigError();
            const value = actualValue ? actualValue : '';
            handleInputChange(key, value);
          },
        }
      );
    }
  };

  const handleClear = (key: string) => {
    editGameConfig(
      { key, value: null },
      {
        onSuccess: () => {
          handleEditConfigSuccess('Value was successfully cleared');
          refetchGameConfigs();
        },
        onError: handleEditConfigError,
      }
    );
  };

  const gameConfigs = !!configs ? configs : [];
  const filteredGameConfigs = gameConfigs.filter(
    config => config.key !== 'appsFlyerKey'
  );

  if (isConfigsLoading) {
    return (
      <div style={{ padding: '0 20px' }}>
        <OverviewListSkeleton rowLength={10} />
      </div>
    );
  }

  if (isConfigsSuccess) {
    return (
      <Wrapper>
        <Header>
          <Label style={{ width: '50%' }}>Key</Label>
          <Label>Value</Label>
        </Header>
        <GameConfigsList
          configs={filteredGameConfigs}
          activeConfigKey={activeConfigKey}
          configsJSON={configsJSON}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          handleOnSave={handleOnSave}
          handleActiveConfig={handleActiveConfig}
          handleClear={handleClear}
        />
      </Wrapper>
    );
  }

  return null;
};

const Wrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
`;

const Header = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.colors.grey[900]};
  margin-left: 25px;
  margin-bottom: 5px;
  font-weight: bold;
`;
