import { chartDataGenerator } from 'pages/Test/Charts/utils/GlobalUtilsTs';
import { PlayTimeChartData } from 'types';
import { formatAppVersion } from '../CompareChart.utils';

type Colors = {
  dayOne: string;
  dayTwo: string;
  daySeven: string;
};

const getChartData = ({
  label,
  data,
  color,
}: {
  label: string;
  data: number[];
  color: string;
}) => {
  return {
    label,
    data,
    borderColor: color,
    pointBorderColor: color,
    pointBackgroundColor: color,
    spanGaps: true,
  };
};

export const generateCampaignDatasets = (
  data: PlayTimeChartData,
  colors: Colors,
  campaignName: string,
  appVersion: string
) => {
  const datasets: any[] = [];
  const dayDataArray = [
    { data: data.dayOnePlayTime, label: 'D1', color: colors.dayOne },
    { data: data.dayTwoPlayTime, label: 'D2', color: colors.dayTwo },
    { data: data.daySevenPlayTime, label: 'D7', color: colors.daySeven },
  ];

  dayDataArray.forEach(({ data, label, color }) => {
    if (data) {
      let chartData = chartDataGenerator(data);

      const fullLabel = `${campaignName} - ${formatAppVersion(
        appVersion
      )} - ${label}`;
      datasets.push(getChartData({ label: fullLabel, data: chartData, color }));
    }
  });
  return datasets;
};

export const lineColors: Colors = {
  dayOne: '#57B4F9',
  dayTwo: '#ffc043',
  daySeven: '#8242fc',
};

export const comparisonColors: Colors = {
  dayOne: '#89CFF0',
  dayTwo: '#e6b800',
  daySeven: '#b19cd9',
};
