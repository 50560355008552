import { useGetNewslettersList } from 'api';
import { Wrapper } from './List.styles';
import { NewsCardColor, Newsletter, NewslettersResponse } from 'types';
import ActionButton from 'components/ActionButton/ActionButton';
import Card from '../Card/Card';
import moment from 'moment';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Spinner } from 'components/atoms';
import { SpinnerContainer } from 'assets/styles';

const NewslettersList = () => {
  const getNextPageParam = (lastPage: NewslettersResponse) =>
    lastPage.nextPageUrl ? lastPage.currentPage + 1 : null;
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetNewslettersList({
      getNextPageParam,
    });

  const { pages } = data ?? { pages: [] };
  const latestNewsLetter: Newsletter = pages[0]?.data[0];

  const newsColorGenerator = (id: number): NewsCardColor =>
    id % 2 === 0 ? 'yellow' : 'blue';

  return (
    <Wrapper>
      <div className="heading">
        <span className="title">
          Whats new at <b>Sundash?</b>
        </span>
      </div>

      {isLoading ? (
        <SpinnerContainer>
          <Spinner size={48} color="#bababa" />
        </SpinnerContainer>
      ) : pages.length === 0 ? (
        <p className="no-data">Nothing to show</p>
      ) : (
        <>
          {/* Latest Newsletter (START) */}
          <Link
            to={`/newsletters/${latestNewsLetter.id}`}
            className="newsletter-spotlight"
            style={{ backgroundColor: '#8144fc' }}
          >
            <div className="top">
              <div className="left">
                <span style={{ fontWeight: 500, lineHeight: '1.3' }}>
                  {moment(latestNewsLetter?.createdAt).format(
                    `Do MMM YYYY, ddd`
                  )}
                  <span className="new-badge">New</span>
                </span>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '1.5',
                  }}
                >
                  Release date
                </span>
              </div>
              <img
                className="img"
                src={latestNewsLetter?.previewImageUrl}
                alt=""
              />
              <div className="right">
                <div className="link">
                  <FiExternalLink size={36} />
                  <span style={{ wordWrap: 'break-word', textAlign: 'center' }}>
                    Read full newsletter
                  </span>
                </div>
              </div>
            </div>
            <div className="bottom">
              <span className="title">{latestNewsLetter?.title}</span>
              <span className="subtitle">{latestNewsLetter?.subline}</span>
            </div>
          </Link>
          {/* Latest Newsletter (END) */}

          <div className="newsletter-grid">
            {pages.map((page: NewslettersResponse, pageIdx: number) =>
              page.data.map((newsLetter: Newsletter, newsLetterIdx: number) => {
                return pageIdx === 0 && newsLetterIdx === 0 ? null : (
                  <Card
                    key={newsLetter.id}
                    newsLetter={newsLetter}
                    color={newsColorGenerator(newsLetter.id)}
                  />
                );
              })
            )}
          </div>
        </>
      )}
      {hasNextPage && (
        <span>
          {isFetchingNextPage ? (
            <SpinnerContainer>
              <Spinner size={48} color="#bababa" />
            </SpinnerContainer>
          ) : (
            <ActionButton
              width="100%"
              height="40px"
              onClick={fetchNextPage}
              text={'Show more news'}
            />
          )}
        </span>
      )}
    </Wrapper>
  );
};

export default NewslettersList;
