import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled(Link)`
  color: ${({ theme }) => theme.colors.grey.black};
  border-radius: 10px;
  height: 340px;
  display: flex;
  flex-direction: column;

  &.blue {
    background-color: ${({ theme }) => theme.colors.blue[500]};
    .info {
      color: ${({ theme }) => theme.colors.grey.white};
    }
  }

  &.yellow {
    background-color: ${({ theme }) => theme.colors.orange[500]};
    .info {
      color: ${({ theme }) => theme.colors.grey.black};
    }
  }

  .top {
    flex-grow: 1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
      padding: 20px 16px;

      .date {
        font-weight: 600;
      }
    }

    .image {
      position: relative;
      bottom: -40px;
      margin: 0 auto;
      border-radius: 10px;
    }
  }

  .bottom {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 16px;
    height: 120px;
    text-align: left;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 20px;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-weight: 600;
    }

    .subtitle {
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      overflow: hidden;
    }
  }
`;
