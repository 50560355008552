import { useState, useCallback, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container } from 'assets/styles';
// import { Tab, TabContainer } from 'components/atoms';
import {
  GameLinkGenerator,
  preSelectedGameTab,
  GameTabs,
  GameParams,
} from 'types';
import { GameHeader } from 'components';
import { useFetchUser, gameLinkGenerator, useFetchGame } from 'api';
import { hasPermission } from 'utils/user';
import { AccessFeatures } from 'types';
import TabHandler from 'components/TabHandler/TabHandler';
import { GameConfigs, Monetization, TestMedia, TestsOverview } from 'features';
import GameHeaderSkeleton from 'components/PagesHeader/GameHeaderSkeleton';

const GamePage = () => {
  const { pathname } = useLocation();
  const params = useParams<GameParams>();
  const gameId = Number(params.gameId);
  const studioId = Number(params.studioId);
  const { data: user } = useFetchUser();
  const [selectedTab, setSelectedTab] = useState<GameTabs>(preSelectedGameTab);
  const {
    data: game,
    isLoading: isGameLoading,
    isSuccess: isGameSuccess,
  } = useFetchGame(gameId);

  const isTabNameValid = (
    ValidationSelectedTab: GameTabs | undefined
  ): boolean =>
    ValidationSelectedTab &&
    (ValidationSelectedTab === GameTabs.tests ||
      ValidationSelectedTab === GameTabs.videos ||
      ValidationSelectedTab === GameTabs.configurations ||
      ValidationSelectedTab === GameTabs.monetization)
      ? true
      : false;

  const hasGameConfigAccess = hasPermission(user, AccessFeatures.GAME_CONFIG);
  const hasMonetizationAccess = hasPermission(
    user,
    AccessFeatures.MONETIZATION
  );

  const redirectToUrl = useCallback(
    ({ studioId, gameId, tabName }: GameLinkGenerator) => {
      const gameLink = gameLinkGenerator({
        studioId,
        gameId,
        ...(tabName && { tabName }),
      });
      return gameLink;
    },
    []
  );

  useEffect(() => {
    if (!pathname.includes('/studio') && user?.studioId) {
      redirectToUrl({ studioId: user.studioId, gameId });
    }
  }, [user, gameId, redirectToUrl, pathname]);

  useEffect(() => {
    isTabNameValid(params.tabName)
      ? setSelectedTab(params.tabName!)
      : redirectToUrl({ studioId, gameId });
  }, [params, studioId, gameId, redirectToUrl, pathname]);

  const tabData = [
    {
      tabName: GameTabs.tests,
      tabHeaderText: 'TESTS',
      redirectUrl: redirectToUrl({
        studioId,
        gameId,
        tabName: GameTabs.tests,
      }),
      selectedTab,
      component: () => <TestsOverview key={`tests-${gameId}`} />,
    },
    {
      tabName: GameTabs.videos,
      tabHeaderText: 'TEST MEDIA',
      redirectUrl: redirectToUrl({
        studioId,
        gameId,
        tabName: GameTabs.videos,
      }),
      selectedTab,
      component: () => <TestMedia />,
    },
    {
      tabName: GameTabs.configurations,
      redirectUrl: redirectToUrl({
        studioId,
        gameId,
        tabName: GameTabs.configurations,
      }),
      selectedTab,
      component: () => <GameConfigs key={`tests-${gameId}`} />,
    },
    {
      tabName: GameTabs.monetization,
      redirectUrl: redirectToUrl({
        studioId,
        gameId,
        tabName: GameTabs.monetization,
      }),
      selectedTab,
      component: () => <Monetization />,
    },
  ];
  const filteredTabData = tabData.filter(tab => {
    if (tab.tabName === GameTabs.configurations) {
      return hasGameConfigAccess;
    } else if (tab.tabName === GameTabs.monetization) {
      return hasMonetizationAccess && !!game?.playMarketUrl;
    }
    return true;
  });

  if (isGameLoading) {
    return <GameHeaderSkeleton />;
  }

  if (isGameSuccess) {
    return (
      <Container>
        <GameHeader />
        <TabHandler tabData={filteredTabData} />
      </Container>
    );
  }

  return null;
};

export default GamePage;
