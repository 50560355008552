import styled from 'styled-components';

export const StudioHeaderWrapper = styled.div`
  min-height: 120px;
  background: ${({ theme }) => theme.colors.grey.white};
  padding: 40px 0 40px 40px;
  display: flex;
  overflow: hidden;
  column-gap: 12px;
  justify-content: space-between;
`;

export const StudioLogoInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const StudioInfo = styled.div`
  margin-left: 24px;
  min-width: 205px;
`;

export const StudioInfoLine = styled.div`
  height: auto;
  margin: 10px 0;
`;

export const StudioInfoLineExternal = styled(StudioInfoLine)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue[500]};
`;

export const StudioInfoName = styled.h1`
  margin: 0 5px;
  font-size: 32px;
  line-height: 38px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StudioInfoIcon = styled.img`
  filter: brightness(0);
  height: 15px;
  width: auto;
  margin-right: 8px;
  float: left;
`;

export const StudioInfoIconExternal = styled(StudioInfoIcon)`
  height: 12px;
`;

export const StudioInfoString = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-family: 'Rubik', sans-serif;
  color: ${({ theme }) => theme.colors.grey[700]};
  float: left;
`;

export const StudioInfoStringExternal = styled(StudioInfoString)`
  color: ${({ theme }) => theme.colors.blue[500]};
`;

export const StudioContacts = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StudioContact = styled.div`
  width: 210px;
  height: 100%;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StudioContactHeader = styled.span`
  color: ${({ theme }) => theme.colors.grey[900]};
  font-size: 16px;
  text-transform: uppercase;
`;

export const StudioContactName = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: #eeeeee;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey.black};
  font-size: 16px;
  line-height: 37px;
  height: 37px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 8px;
  border-radius: 4px;
`;

/**
 * Shortens the name of the contact to fit the container, adding "..." at the end of the string.
 */
export const StudioContactNameShortener = styled.div`
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;
`;

export const StudioContactAction = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

export const StudioContactActionIcon = styled.img`
  filter: invert(51%) sepia(27%) saturate(1718%) hue-rotate(171deg)
    brightness(104%) contrast(94%);
  height: 14px;
  width: auto;
`;

export const StudioOptions = styled.div`
  display: flex;
  align-items: center;
`;

export const StudioOptionsButton = styled.img`
  filter: invert(51%) sepia(27%) saturate(1718%) hue-rotate(171deg)
    brightness(104%) contrast(94%);
  height: 18px;
  width: auto;
  padding-top: 55px;
  cursor: pointer;
`;

export const StudioHeaderLink = styled.a`
  color: ${({ theme }) => theme.colors.blue[500]};
`;
