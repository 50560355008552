import { SearchInput } from 'components/molecules';
import { ChangeEvent, useState } from 'react';
import { GameOverviewCategory } from 'types';
import { PublishingCard } from './PublishingCard';
import {
  Heading,
  NoResult,
  Section,
  SectionContent,
  SectionHeader,
} from './PublishingSection.styles';
import PublishingCardSkeleton from './PublishingCardSkeleton';

interface PublishingSectionProps {
  headerText: string;
  placeholderText: string;
  data: GameOverviewCategory[];
  loading: boolean;
  cardClickable?: boolean;
}

export const PublishingSection = ({
  headerText,
  placeholderText,
  data,
  loading,
  cardClickable,
}: PublishingSectionProps) => {
  const [searchText, setSearchText] = useState<string>('');

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const getCardHeaderURL = (id: number) => {
    return cardClickable ? `/studio/${id}` : '';
  };

  const filterBySearchText = (element: any) =>
    element.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
  const filteredData: GameOverviewCategory[] = data.filter(filterBySearchText);

  return (
    <Section>
      {loading ? (
        <PublishingCardSkeleton />
      ) : (
        <>
          <SectionHeader>
            <Heading>
              {headerText} ({filteredData.length})
            </Heading>
            <SearchInput
              sidenav={false}
              onChange={handleSearchText}
              placeholder={placeholderText}
            />
          </SectionHeader>
          <SectionContent>
            {!!filteredData.length ? (
              filteredData.map((data: GameOverviewCategory) => (
                <PublishingCard
                  key={data.id}
                  data={data}
                  headerURL={getCardHeaderURL(data.id)}
                />
              ))
            ) : (
              <NoResult>Nothing to show</NoResult>
            )}
          </SectionContent>
        </>
      )}
    </Section>
  );
};
