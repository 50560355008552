import { Wrapper } from './MediaLibrary.styles';
import { useFetchMedias } from 'api';
import { useParams } from 'react-router-dom';
import { MediaSortType, VideoFile, VideoReviewStatus } from 'types';
import { MediaThumbnail, ProgressThumbnail, UploadThumbnail } from 'components';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { SpinnerContainer } from 'assets/styles';
import { Spinner } from 'components/atoms';

interface MediaLibraryProps {
  mediaLimit: number;
  selectedMedias: number[];
  handleSelectMedia: (id: number) => void;
}

const MediaLibrary = ({
  mediaLimit,
  selectedMedias,
  handleSelectMedia,
}: MediaLibraryProps) => {
  const params = useParams<{ gameId: string }>();
  const gameId = Number(params.gameId);
  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchMedias,
  } = useFetchMedias(gameId, MediaSortType.DATE);
  const [uploadCompleteMediaFiles, setUploadCompleteMediaFiles] = useState<
    File[]
  >([]);
  const [uploadedMediaFiles, setUploadedMediaFiles] = useState<File[]>([]);

  const handleUploadedMediaFiles = (uploadedFiles: FileList) => {
    let newFiles: File[] = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      if (!uploadedMediaFiles.find(f => f.name === file.name)) {
        newFiles.push(file);
      }
    }
    setUploadedMediaFiles(prevState => [...prevState, ...newFiles.reverse()]);
  };

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleUploadedMediaFiles(event.target.files);
    }
  };

  const uploadFileProcessed = useCallback(
    ({ file, status }: { file: File; status?: Boolean }) => {
      if (status) {
        setUploadCompleteMediaFiles(prevState => [...prevState, file]);
      } else {
        setUploadedMediaFiles(prevState =>
          prevState.filter(f => f.name !== file.name)
        );
      }
    },
    []
  );

  // For rendering ProgressThumbnail when required
  useEffect(() => {
    if (uploadCompleteMediaFiles.length === uploadedMediaFiles.length) {
      refetchMedias();
    }
  }, [uploadCompleteMediaFiles, uploadedMediaFiles, refetchMedias]);

  const mediaLoading = isLoading || isFetching;
  const medias = data as VideoFile[];

  return (
    <Wrapper>
      {mediaLoading ? (
        <SpinnerContainer>
          <Spinner size={48} color="#bebebe" />
        </SpinnerContainer>
      ) : (
        <>
          {/* Add Button */}
          <UploadThumbnail
            onUpload={onUpload}
            dropHandler={handleUploadedMediaFiles}
          />

          {/* Processing Media */}
          {uploadedMediaFiles.map((file, idx) => {
            if (
              !uploadCompleteMediaFiles.map(f => f.name).includes(file.name)
            ) {
              return (
                <ProgressThumbnail
                  key={idx}
                  file={file}
                  onFileProcessed={uploadFileProcessed}
                  gameId={gameId}
                />
              );
            }
            return null;
          })}

          {/* Medias */}
          {medias.map(media => {
            const mediaName = media.url.split('/').pop() ?? media.url;
            return (
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={media.id}
              >
                <MediaThumbnail
                  mediaLimit={mediaLimit}
                  media={media}
                  hasError={
                    media.reviewStatus === VideoReviewStatus.reviewFailed
                  }
                  isRemovable={false}
                  hasCheckbox={true}
                  // onCheckboxClick={() => handleSelectMedia(media.id)} you can add this for checkbox action 
                  handleClick={() => handleSelectMedia(media.id)}
                  selectedMedias={selectedMedias}
                />
                <span title={mediaName} className="media-title">
                  {mediaName}
                </span>
              </div>
            );
          })}
        </>
      )}
    </Wrapper>
  );
};

export default MediaLibrary;
