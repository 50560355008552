import { useQuery, useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import {
  TagType,
  CreatTagPayload,
  CreatTagResponse,
  ServerQueryKey,
} from 'types';

const getTags = async () => {
  const { data } = await axiosInstance.get(`/tags`);
  return data as TagType[];
};

export const useGetTags = () =>
  useQuery([ServerQueryKey.GameTags], getTags, {
    initialData: [],
  });

const createTag = async (tagPayload: CreatTagPayload) => {
  const { data } = await axiosInstance.post('/tags', tagPayload);
  return data as CreatTagResponse;
};

export const useCreateTag = () => useMutation(createTag);
