import styled from 'styled-components';

export const StyledMonetization = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px;

  .top-container {
    width: 40%;
    min-height: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .bottom-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .substep-container {
    width: 40%;
    border-radius: 4px;
    border: 2px dashed ${props => props.theme.colors.grey[1300]};
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heading {
    color: ${({ theme }) => theme.colors.grey[900]};
    font-size: 24px;
    line-height: 2;
  }

  .flex-between {
    justify-content: space-between;
  }
`;
