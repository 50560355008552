import moment from 'moment';
import { min, max, round, floor, ceil } from 'lodash';
import { BusinessChartType, ChartData } from 'types';

export const chartDataGenerator = (chartData: ChartData) =>
  Object.values(chartData).map(item => round(item, 1));

export const chartLabelsTodayIndex = (
  chartLabels: string[],
  format: string = 'DD.MM'
) => {
  const today = moment(new Date()).format(format);
  return chartLabels.indexOf(today) === -1 ? 1000 : chartLabels.indexOf(today);
};

export const chartLimitGenerator = (
  values: number[],
  percentage: number = 0.2
) => {
  const minValue = min(values) ?? 0;
  const maxValue = max(values) ?? 0;
  const lowerLimit = floor(minValue - minValue * percentage);
  let upperLimit = ceil(maxValue + maxValue * percentage);
  upperLimit = upperLimit === 0 ? 1 : upperLimit;

  return { lowerLimit, upperLimit };
};

export const cohortIndexArrayGenerator = (
  numberOfIndex: number,
  startFrom: number
): number[] => [...Array(numberOfIndex)].map((_, index) => index + startFrom);

export const cohortLabelsGenerator = (
  indexes: number[],
  prefixText?: string,
  suffixText?: string
): string[] =>
  indexes.map(index => (prefixText ?? '') + index + (suffixText ?? ''));

export const getChartTitleAndTooltip = (chartType: BusinessChartType) => {
  switch (chartType) {
    case BusinessChartType.ARPI:
      return {
        title: 'Average Revenue Per Install (ARPI)',
        tooltip:
          'How much revenue the game/app is generating per active user / install.',
      };
    case BusinessChartType.PLAYTIME:
      return {
        title: 'Play Time (D1,D2,D7)',
        tooltip:
          'How much time people spend within the game on their first, second, and seventh day / first 24 hours of installation.',
      };
    case BusinessChartType.PLAYTIME_PER_AVG_USER:
      return {
        title: 'Average Play Time per User (APPU)',
        tooltip: 'How much time an average user spends in the app.',
      };
    case BusinessChartType.SUNDAY_RETENTION:
      return {
        title: 'Sunday Retention',
        tooltip:
          'It shows how many people open the app again on the next day (UTC).',
      };
    case BusinessChartType.AD_IMPRESSIONS_PER_AVG_USER:
      return {
        title: 'Average Ad Impressions per User',
        tooltip:
          'It is the average number of times a person is shown a video ad from your campaign in the game.',
      };
    case BusinessChartType.LEVEL_PROGRESSION:
      return {
        title: 'Level Progression',
        tooltip: 'How many people reach level 10 out of all installers.',
      };
  }
};
