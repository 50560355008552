import styled from 'styled-components';
import { TextInput } from 'components/atoms';

export const StyledCreativePackGenerator = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .pack-name {
    display: flex;
    flex-direction: column;

    .label {
      margin-bottom: 10px;
    }
  }

  .pack-content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
`;

export const StyledTextInput = styled(TextInput)`
  margin: 0;
  height: 40px;
  width: 400px;
`;
