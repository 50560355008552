import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateCampaign,
  useFetchCampaigns,
  useFetchGame,
  useFetchUser,
} from 'api';
import {
  AccessFeatures,
  ActivationTypes,
  CampaignNetworks,
  CampaignTypes,
  FacebookParameters,
  GameTabs,
  UnityParameters,
  createCamapignReqBody,
} from 'types';
import { Modal, Stepper } from 'components';
import { toast } from 'react-toastify';
import BasicDetails from './BasicDetailsStep/BasicDetailsStep';
import AddMedia from './AddMediaStep/AddMediaStep';
import Activation from './ActivationStep/ActivationStep';
import { hasPermission } from 'utils/user';
import AdvancedDetailsStep from './AdvancedDetailsStep/AdvancedDetailsStep';
import { Button } from 'components/atoms';
import { Controls } from 'components/Stepper/Stepper.styles';

enum CreateCampaignStep {
  BASIC_DETAILS = 'Basic details',
  ADVANCED_DETAILS = 'Advanced details',
  ADD_MEDIA = 'Add media',
  ACTIVATION = 'Activation',
}

const campaignStepsLookup: Record<CampaignTypes, CreateCampaignStep[]> = {
  [CampaignTypes.ipm]: [
    CreateCampaignStep.BASIC_DETAILS,
    CreateCampaignStep.ADD_MEDIA,
  ],
  [CampaignTypes.mvp]: [
    CreateCampaignStep.BASIC_DETAILS,
    CreateCampaignStep.ADD_MEDIA,
  ],
  [CampaignTypes.scb]: [
    CreateCampaignStep.BASIC_DETAILS,
    CreateCampaignStep.ADD_MEDIA,
  ],
  [CampaignTypes.ctm]: [
    CreateCampaignStep.BASIC_DETAILS,
    CreateCampaignStep.ADVANCED_DETAILS,
    CreateCampaignStep.ADD_MEDIA,
  ],
};
const handleAddCampaignSuccess = (text: string) => {
  toast.success(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};
const getStepsByCampaignType = (campaignType: CampaignTypes) => {
  return campaignStepsLookup[campaignType];
};

const descriptionMaxLength = 250;

interface AddCampaignModalProps {
  isOpen: boolean;
  onClose: (isSubmitted: boolean) => void;
}

const AddCampaignModal = ({ isOpen, onClose }: AddCampaignModalProps) => {
  const navigate = useNavigate();
  const params = useParams<{
    studioId: string;
    gameId: string;
  }>();
  const gameId = Number(params.gameId);
  const { data: user } = useFetchUser();
  const hasActivationPermission = hasPermission(
    user,
    AccessFeatures.CAMPAIGN_ACTIVATION
  );
  const { refetch: refetchCampaigns } = useFetchCampaigns(gameId, {
    enabled: false,
  });
  const { data: game, isSuccess } = useFetchGame(gameId);
  const { mutate: createCampaign } = useCreateCampaign();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [campaignType, setCampaignType] = useState<CampaignTypes | null>(null);
  const [activationType, setActivationType] = useState<ActivationTypes>(
    !hasActivationPermission ? ActivationTypes.manual : ActivationTypes.asap
  );
  const [activationStartDate, setActivationStartDate] = useState('');
  const [activationStartTime, setActivationStartTime] = useState('');

  const [descriptionText, setDescriptionText] = useState<string>('');
  const [hasDescriptionError, setHasDescriptionError] =
    useState<boolean>(false);
  const [selectedMedias, setSelectedMedias] = useState<number[]>([]);
  const [network, setNetwork] = useState<CampaignNetworks>(
    CampaignNetworks.facebook
  );
  const [parameters, setParameters] = useState<
    FacebookParameters | UnityParameters | null
  >(null);
  const [validationError, setValidationError] = useState<string>('');

  const isUnity =
    campaignType === CampaignTypes.scb || network === CampaignNetworks.unity;

  // steps
  const commonSteps = campaignType
    ? getStepsByCampaignType(campaignType)
    : [CreateCampaignStep.BASIC_DETAILS, CreateCampaignStep.ADD_MEDIA];
  const activationStep = [CreateCampaignStep.ACTIVATION];
  const steps = [
    ...commonSteps,
    ...(hasActivationPermission ? activationStep : []),
  ];

  const isBasicDetailsStep =
    steps[currentStep] === CreateCampaignStep.BASIC_DETAILS;
  const isAddMediaStep = steps[currentStep] === CreateCampaignStep.ADD_MEDIA;
  const isAdvancedDetailsStep =
    steps[currentStep] === CreateCampaignStep.ADVANCED_DETAILS;
  const isActivationStep = steps[currentStep] === CreateCampaignStep.ACTIVATION;

  const disableNextButton =
    (isBasicDetailsStep && (campaignType === null || hasDescriptionError)) ||
    (isAdvancedDetailsStep && parameters === null) ||
    (isAddMediaStep && selectedMedias.length === 0) ||
    (isActivationStep &&
      activationType === ActivationTypes.scheduled &&
      (activationStartDate === '' || activationStartTime === ''));

  const handleNetworkChange = useCallback((network: CampaignNetworks) => {
    setNetwork(network);
  }, []);

  const handleParameters = useCallback(
    (parameters: FacebookParameters | UnityParameters | null) => {
      setParameters(parameters);
    },
    []
  );

  const handleCampaignType = useCallback((type: CampaignTypes | null) => {
    setCampaignType(type);
  }, []);

  const handleActivationType = useCallback((type: ActivationTypes) => {
    setActivationType(type);
  }, []);

  const handleActivationStartDate = useCallback((startDate: string) => {
    setActivationStartDate(startDate);
  }, []);

  const handleActivationStartTime = useCallback((startTime: string) => {
    setActivationStartTime(startTime);
  }, []);

  const handleDescriptionText = useCallback((description: string) => {
    if (description.length >= descriptionMaxLength) {
      setHasDescriptionError(true);
    } else {
      setHasDescriptionError(false);
      setDescriptionText(description);
    }
  }, []);

  const onError = (error: any) => {
    if (!!error.response.data['gameId']) {
      setValidationError(error.response.data['gameId'][0]);
    }
  };

  const handleBackStep = useCallback((backStep: number) => {
    setValidationError('');
    setSelectedMedias([]);
    setCurrentStep(backStep);
  }, []);

  const handleNextStep = (nextStep: number) => {
    if (nextStep < steps.length) {
      // all steps except last one
      setCurrentStep(nextStep);
    } else {
      // last step
      if (campaignType !== null) {
        const reqBody: createCamapignReqBody = {
          gameId,
          testType: campaignType,
          description: descriptionText,
          mediaIds: selectedMedias,
          testingPlatform: isUnity
            ? CampaignNetworks.unity
            : CampaignNetworks.facebook,
          activationType: activationType,
          startAt: `${activationStartDate} ${activationStartTime}`,
          ...(parameters !== null && { parameters }),
        };
        setValidationError('');
        createCampaign(reqBody, {
          onSuccess: () => {
            navigate(
              `/studio/${params.studioId}/game/${params.gameId}/${GameTabs.tests}`
            );
            refetchCampaigns();
            handleAddCampaignSuccess('The test was successfully created');
            handleCloseModal(true);
          },
          onError,
        });
      }
    }
  };

  const handleCloseModal = (isSubmitted: boolean) => {
    setCurrentStep(0);
    setCampaignType(null);
    setDescriptionText('');
    setSelectedMedias([]);
    onClose(isSubmitted);
  };

  const mediaLimit: number = (() => {
    if (campaignType !== null) {
      return campaignType === CampaignTypes.ctm ? 10 : 4;
    }
    return 0;
  })();

  useEffect(() => {
    if (isSuccess) {
      setNetwork(
        game.isFacebookConfigured
          ? CampaignNetworks.facebook
          : CampaignNetworks.unity
      );
    }
  }, [isSuccess, game?.isFacebookConfigured]);

  return (
    <Modal
      isOpen={isOpen}
      title="Create new test"
      onClose={() => handleCloseModal(false)}
    >
      <Stepper
        steps={steps}
        currentStep={currentStep}
        controls={{
          onBack: handleBackStep,
          onNext: handleNextStep,
          disableNextButton: disableNextButton,
        }}
        renderControls={(controls, isFirstStep, isLastStep) => (
          <Controls>
            {!isFirstStep && (
              <Button onClick={() => controls.onBack(currentStep - 1)}>
                BACK
              </Button>
            )}
            <Button
              style={{ minWidth: '200px' }}
              primary
              onClick={() => controls.onNext(currentStep + 1)}
              disabled={disableNextButton ?? false}
            >
              {isLastStep ? 'CREATE TEST' : 'NEXT'}
            </Button>
          </Controls>
        )}
      >
        {isBasicDetailsStep && (
          <BasicDetails
            gameId={gameId}
            selectedType={campaignType}
            onTypeSelect={handleCampaignType}
            descriptionText={descriptionText}
            onDescriptionText={handleDescriptionText}
            hasDescriptionError={hasDescriptionError}
            descriptionMaxLength={descriptionMaxLength}
          />
        )}
        {isAddMediaStep && (
          <AddMedia
            mediaLimit={mediaLimit}
            campaignType={
              campaignType === null ? CampaignTypes.ipm : campaignType
            }
            isUnity={isUnity}
            gameId={gameId}
            onSelectMedia={setSelectedMedias}
          />
        )}
        {isAdvancedDetailsStep && (
          <AdvancedDetailsStep
            gameId={gameId}
            network={network}
            handleNetworkChange={handleNetworkChange}
            parameters={parameters}
            handleParameters={handleParameters}
          />
        )}
        {isActivationStep && (
          <Activation
            selectedType={activationType}
            onTypeSelect={handleActivationType}
            onDateSelect={handleActivationStartDate}
            onTimeSelect={handleActivationStartTime}
            error={validationError}
          />
        )}
      </Stepper>
    </Modal>
  );
};

export default AddCampaignModal;
