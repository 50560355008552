import {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  FocusEvent,
} from 'react';
import { debounce, isEmpty } from 'lodash';
import { useGlobalSearch } from 'api';
import { GlobalSearch } from 'types';
import { useKeyPress } from 'hooks';
import StyledSearchBar from './searchBar.styles';
import { resultsJSX, numberOfResults } from './results';
import LoadingResults from './loadingResults';
import NoResults from './noResults';
import searchSrc from 'assets/images/searchBar/search.svg';
import closeSrc from 'assets/images/searchBar/close.svg';

interface SearchBarProps {
  onClose: () => void;
}

let selectedElement = 0;

const SearchBar = ({ onClose }: SearchBarProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [enableSearch, setEnableSearch] = useState<boolean>(false);
  const [resultsLength, setResultsLength] = useState<number>(0);
  const [elementsList, setElementsList] = useState<HTMLElement[]>([]);
  const escKeyPress: boolean = useKeyPress('Escape');
  const arrowDownKeyPress: boolean = useKeyPress('ArrowDown');
  const arrowUpKeyPress: boolean = useKeyPress('ArrowUp');

  const {
    data: results,
    isFetching,
    refetch,
  } = useGlobalSearch(searchText, enableSearch);

  const handleResultsLength = (results: GlobalSearch) => {
    const length = isEmpty(results) ? 0 : numberOfResults(results);
    setResultsLength(length);
  };

  const handleElementsList = () => {
    const elements: HTMLElement[] = [];

    // input element
    const inputElement = document.querySelector<HTMLElement>(
      '.global-search-input'
    );
    inputElement && elements.push(inputElement);

    // links element
    const linksElements = document.querySelectorAll<HTMLElement>(
      '.global-search-links'
    );
    linksElements.forEach(linkElement => elements.push(linkElement));

    setElementsList(elements);
  };

  const debouncedSearch = useCallback(
    debounce(text => {
      if (text.length >= 2) {
        setEnableSearch(true);
        refetch();
      } else {
        setEnableSearch(false);
      }
    }, 500),
    []
  );

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setSearchText(text);
    debouncedSearch(text);
  };

  const handleClickOutside = (event: FocusEvent) => {
    const currentTarget = event.currentTarget;
    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        onClose();
      }
    });
  };

  const handleArrowDown = () => {
    if (selectedElement < elementsList.length - 1) {
      selectedElement += 1;
      elementsList[selectedElement].focus();
    }
  };

  const handleArrowUp = () => {
    if (selectedElement > 0) {
      selectedElement -= 1;
      elementsList[selectedElement].focus();
    }
  };

  const resetFocusToInput = () => {
    selectedElement = 0;
    const inputElement = document.querySelector<HTMLElement>(
      '.global-search-input'
    );
    inputElement && inputElement.focus();
  };

  useEffect(() => {
    // On component mount
    resetFocusToInput();
  }, []);

  useEffect(() => {
    handleResultsLength(results);
  }, [results]);

  useEffect(() => {
    handleElementsList();
  }, [resultsLength]);

  useEffect(() => {
    escKeyPress && onClose();
    if (arrowDownKeyPress && enableSearch) handleArrowDown();
    if (arrowUpKeyPress && enableSearch) handleArrowUp();
  }, [escKeyPress, arrowDownKeyPress, arrowUpKeyPress]);

  const resultsContent = (
    isFetching: boolean,
    results: GlobalSearch,
    resultsLength: number
  ) =>
    isFetching ? (
      <LoadingResults />
    ) : resultsLength > 0 ? (
      resultsJSX(results)
    ) : (
      <NoResults />
    );

  return (
    <StyledSearchBar onBlur={handleClickOutside} tabIndex={0}>
      <div className="input-wrapper">
        <img className="icon" src={searchSrc} alt="search" />
        <input
          className="input global-search-input"
          placeholder="Search anything"
          onFocus={() => resetFocusToInput()}
          value={searchText}
          onChange={handleSearchChange}
        />
        {enableSearch && resultsLength > 0 && (
          <span className="number-of-results">{resultsLength} results</span>
        )}
        <img className="icon" src={closeSrc} alt="close" onClick={onClose} />
      </div>
      <div className="results-wrapper">
        {enableSearch
          ? resultsContent(isFetching, results, resultsLength)
          : null}
      </div>
    </StyledSearchBar>
  );
};

export default SearchBar;
