import styled from 'styled-components';

export const ModalOuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-modal);
  pointer-events: none;
`;
