import React from 'react';
import styled from 'styled-components';
import { GameLogo, StudioLogo } from 'components/atoms';
import { Link } from 'react-router-dom';


interface BreadcrumbItem {
  label: string;
  link?: string;
  rootUrl?: string;
  boldLinks?: boolean;
  fontSize?: string; 
  logo?:string;
  smallLogo?:boolean;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = React.memo (({ items }) => {
  return (
    <BreadcrumbList>
      <BreadcrumbListStyled className="breadcrumb">
        {items.map((item, index) => {
          const link = item.rootUrl && item.link ? `${item.rootUrl}${item.link}` : item.link;
          return (
            <BreadcrumbItemElement
              key={index}
              aria-current={index === items.length - 1 ? 'page' : undefined}
              fontSize={item.fontSize}
            > 
            {item.logo || item.logo=== ''? 
            <LogoContainer smallLogo={item.smallLogo}>
              {item.smallLogo ?
              <StudioLogoContainer>
               <StudioLogo name={item.label} url={item.logo} xSmall={item.smallLogo}/>
              </StudioLogoContainer>:
               <GameLogo name={item.label} url={item.logo} size="sm"/>
              }
            </LogoContainer>
             :
              null
            }
              {link ? (
                <BreadcrumbLink to={link} boldLinks={item.boldLinks}>{item.label}</BreadcrumbLink>
              ) : (
                <span>{item.label}</span>
              )}
            </BreadcrumbItemElement>
          );
        })}
      </BreadcrumbListStyled>
    </BreadcrumbList>
  );
});

const BreadcrumbList = styled.nav`
  aria-label: breadcrumb;
  display: flex;
  flex-direction: column;
`;

const StudioLogoContainer = styled.div`
  margin-right: 10px;
`;
const LogoContainer = styled.div<{smallLogo?: boolean }>`
  border: 1px solid #FFF;
  border-radius: ${(props) => props.smallLogo ? "30px":'10px'};
`;
const BreadcrumbItemElement = styled.li<{fontSize?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: ${(props) => props.fontSize };
`;

const BreadcrumbLink = styled(Link)<{ boldLinks?: boolean }>`
text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width:150px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue[500]};
  &:hover {
    text-decoration: underline;
  }
  ${(props) =>
    props.boldLinks ?
    `
    font-weight: 500;
  `:`
  font-weight: 300;
`}

`;

const BreadcrumbListStyled = styled.ol`
  margin: 0;
  padding: 0;
`;
export default Breadcrumb;
