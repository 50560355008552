import React from 'react';
import styled from 'styled-components';
import { TextInput } from 'components/atoms';
import ClearButton, { Button } from './ClearButton';
import { GameConfig } from 'types';

interface GameConfigsItemProps {
  config: GameConfig;
  activeConfigKey: string;
  configsJSON: any;
  handleInputChange: (key: string, value: string | null) => void;
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    configKey: string
  ) => void;
  handleOnSave: (key: string) => void;
  handleActiveConfig: (configKey: string) => void;
  handleClear: (key: string) => void;
}

const GameConfigItem = ({
  config,
  activeConfigKey,
  configsJSON,
  handleInputChange,
  handleKeyDown,
  handleOnSave,
  handleActiveConfig,
  handleClear,
}: GameConfigsItemProps) => {
  return (
    <StyledConfigElement role="listitem">
      <ConfigText>{config.name}</ConfigText>
      <StyledTextInput
        disabled={!config.editable || config.key !== activeConfigKey}
        placeholder={config.description ? config.description : 'Enter value'}
        onChange={event => handleInputChange(config.key, event.target.value)}
        onKeyDown={e => handleKeyDown(e, config.key)}
        value={configsJSON[config.key] ?? ''}
      />
      <ButtonContainer>
        {config.key !== activeConfigKey && (
          <Button
            disabled={!config.editable}
            onClick={() => handleActiveConfig(config.key)}
          >
            Edit
          </Button>
        )}
        {config.key === activeConfigKey && (
          <SaveButton onClick={() => handleOnSave(config.key)}>Save</SaveButton>
        )}
        {config.key === activeConfigKey && (
          <CancelButton onClick={() => handleActiveConfig('')}>
            Cancel
          </CancelButton>
        )}
        <ClearButton
          isDisabled={
            configsJSON[config.key] === null ||
            configsJSON[config.key]?.length === 0 ||
            !config.editable
          }
          onClear={() => handleClear(config.key)}
        />
      </ButtonContainer>
    </StyledConfigElement>
  );
};

const StyledConfigElement = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  margin: 10px 0;
`;

const ConfigText = styled.span`
  text-align: start;
  width: 20%;
  padding-left: 25px;
`;

const StyledTextInput = styled(TextInput)`
  height: 32px;
  width: 760px;
  margin: 0;
  border: 1px solid #dfdfdf;

  &:disabled {
    color: #4d4d4d;
    background-color: #dfdfdf;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 300px;
  gap: 5px;
`;

const SaveButton = styled(Button)`
  color: ${props => props.theme.colors.green[500]};
  border-color: ${props => props.theme.colors.green[500]};
`;

const CancelButton = styled(Button)`
  color: ${props => props.theme.colors.pink[500]};
  border-color: ${props => props.theme.colors.pink[500]};
`;

export default GameConfigItem;
