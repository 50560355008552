import styled from 'styled-components';
import { ActivationTypes } from 'types';
import Radio from './Radio';
import { DateTimePicker } from 'components';
import { FormFieldError } from 'components/AddStudioModal/AddStudioModal.styles';

const getFormattedDateString = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const dateString = `${day}.${month}.${year}`;
  return dateString;
};

interface ActivationStepProps {
  selectedType: ActivationTypes;
  onTypeSelect: (type: ActivationTypes) => void;
  onDateSelect: (date: string) => void;
  onTimeSelect: (time: string) => void;
  error?: string;
}

const ActivationStep = ({
  selectedType,
  onTypeSelect,
  onDateSelect,
  onTimeSelect,
  error,
}: ActivationStepProps) => {
  const onDateChange = (date: Date) => {
    onDateSelect(getFormattedDateString(date));
  };
  const onTimeChange = (time: string) => {
    onTimeSelect(time);
  };
  return (
    <StyledActivationStep>
      <span className="title">Select activation method</span>
      <Radio
        type={ActivationTypes.asap}
        label="ASAP"
        description="Activate the campaign automatically as soon as the review process is successfully finished"
        hasError={false}
        errorMessage=""
        selected={selectedType}
        onSelect={onTypeSelect}
      />
      <Radio
        type={ActivationTypes.manual}
        label="MANUAL"
        description="Manually activate the campaign after the review process is successfully finished"
        hasError={false}
        errorMessage=""
        selected={selectedType}
        onSelect={onTypeSelect}
      />
      <Radio
        type={ActivationTypes.scheduled}
        label="SCHEDULED"
        description="Automatically activate the campaign at a given time after the review process is successfully finished"
        hasError={false}
        errorMessage=""
        selected={selectedType}
        onSelect={onTypeSelect}
      />
      {selectedType === ActivationTypes.scheduled && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <DateTimePicker
            showTimeSelect={false}
            onDateChange={onDateChange}
            onTimeChange={onTimeChange}
          />
        </div>
      )}
      <div className="error-wrapper">
        {error?.length !== 0 && <ErrorText>{error}</ErrorText>}
      </div>
    </StyledActivationStep>
  );
};

export default ActivationStep;

export const StyledActivationStep = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px;
    font-weight: 500;
    margin: 24px 0 12px;
  }

  .error-wrapper {
    margin-top: auto;
  }
`;

const ErrorText = styled(FormFieldError)`
  text-align: center;
  margin: 5px 0;
`;
