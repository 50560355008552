import styled from 'styled-components';

export const CenteredWrapper = styled.div`
  text-align: center;
`;

export const StyledWrapper = styled.div`
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  width: 300px;
`;

export const PrivacyPolicyWrapper = styled.div`
  opacity: 0.7;
  font-size: 12px;
  border: 1px dashed ${({ theme }) => theme.colors.blue[500]};
  border-radius: 5px;
  padding: 10px;
`;

export const BigLogoBoxWrapper = styled.div`
  display: block;
  width: 100%;
  float: left;
  clear: left;
`;

export const Button = styled.button.attrs(props => {
  return {
    props,
  };
})`
  border: 2px solid ${({ theme }) => theme.colors.blue[500]};
  border-radius: 4px;
  display: block;
  width: 80%;
  font-style: normal;
  font-variant: normal;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Rubik';
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.blue[500]};
  background: transparent;
  padding: 13px 0;
  margin: 20% auto;
  transition: width 0.3s, height 0.3s, color 0.3s, background 0.3s,
    line-height 0.3s;

  &:hover {
    width: 100%;
    background: ${({ theme }) => theme.colors.blue[500]} 0% 0% no-repeat
      padding-box;
    color: ${({ theme }) => theme.colors.grey.white};
  }
`;
