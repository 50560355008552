import { Modal } from 'components';
import {
  Controls,
  IconButton,
  ApproveModalContainer,
  ApproveModalHeader,
  TextArea,
  ApproveModalSpanText,
} from '../InvoicesOverview.styles';
import { Button } from 'components/atoms';
import { Icon } from 'assets/styles';
import { downloadBlue } from 'assets/images';
import { ModalSize } from 'types';
import { useModifyInvoiceReview } from 'api';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';


interface InvoiceReviewModalProps {
  isOpen: boolean;
  modalType: string;
  onClose: () => void;
  downloadPDF: (invoiceId: number) => void;
  invoiceId: number;
  handleOpenReviewModal: (invoiceId: number) => void;
}

const ApproveModal: React.FC<InvoiceReviewModalProps> = ({
  isOpen,
  onClose,
  modalType,
  downloadPDF,
  invoiceId,
  handleOpenReviewModal,
}) => {
    const [textareaValue, setTextAreaValue]= useState<string>()
    const queryClient = useQueryClient();
    const updateStudio = useModifyInvoiceReview(invoiceId, invoiceId,modalType, {
        onSuccess: () => {
          queryClient.invalidateQueries(['getInvoices']);
          if(modalType === 'approve'){
            handleModifyReviewSuccess('The invoice was successfully approved')
          }else{
            handleModifyReviewSuccess('The invoice was successfully rejected')
          }
          onClose()
        },
        onError:()=>  handleModifyReviewError("Something went wrong. Please, try again later.")
      });
     const handleTexareaChange = (event:ChangeEvent<HTMLTextAreaElement>)=>{
        setTextAreaValue(event.target.value)
     }

     const handleModifyReviewSuccess = (text: string) => {
        toast.success(text, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      };
      
      const handleModifyReviewError = (text: string) => {
        toast.error(text, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      };
  return (
    <>
      <Modal
        size={ModalSize.sm_md}
        isOpen={isOpen}
        title={modalType === 'approve' ? 'APPROVE INVOICE' : 'REJECT INVOICE'}
        onClose={onClose}
      >
        <ApproveModalContainer>
            <div >
            <ApproveModalHeader style={{ marginBottom: '15px'}}>
            {modalType === 'approve' ? (
              <>
                <span>Comments </span>
                <ApproveModalSpanText>(Optional) </ApproveModalSpanText>
              </>
            ) : (
              <>
                <span>Reason to reject this invoice </span>{' '}
              </>
            )}

            <span>: </span>
          </ApproveModalHeader>
          <TextArea onChange={handleTexareaChange} />
            </div>
         
          <Controls>
            <div>
              <IconButton
                onClick={() => {
                  downloadPDF(invoiceId);
                }}
              >
                <Icon
                  style={{ marginRight: '5px' }}
                  src={downloadBlue}
                  alt="download button"
                />
                Download
              </IconButton>
            </div>
            <div>
              <Button
                onClick={() => {
                  onClose();
                  handleOpenReviewModal(invoiceId);
                }}
                color="#F066A7"
                style={{ width: '188px' }}
              >
                Back
              </Button>
              {modalType === 'approve' ? (
                <Button
                 onClick={()=> updateStudio.mutate({commentContent:textareaValue})}
                  style={{
                    width: '188px',
                    backgroundColor: '#2DA1F8',
                    color: '#FFF',
                  }}
                >
                  Approve Invoice
                </Button>
              ) : (
                <Button
                onClick={()=> updateStudio.mutate({commentContent:textareaValue})}
                 disabled={!textareaValue}
                 style={{
                    width: '188px',
                    backgroundColor: textareaValue ? '#F066A7' : undefined,
                    color: textareaValue ? '#FFF' : undefined,
                    border: textareaValue ? '2px solid #F066A7' : undefined,
                  }}
                >
                  Reject Invoice{' '}
                </Button>
              )}
            </div>
          </Controls>
        </ApproveModalContainer>
      </Modal>{' '}
    </>
  );
};

export default ApproveModal;
