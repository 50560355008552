import Skeleton from 'components/Skeleton/Skeleton';

interface OverviewListSkeletonProps {
  rowLength: number;
}

const OverviewListSkeleton: React.FC<OverviewListSkeletonProps> = ({
  rowLength,
}) => {
  return (
    <div
      style={{
        minHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginTop: '64px',
      }}
    >
      {new Array(rowLength).fill(0).map((_, idx) => (
        <Skeleton key={idx} height="64px" width="100%" />
      ))}
    </div>
  );
};

export default OverviewListSkeleton;
