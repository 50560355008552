import { IoMdClose } from 'react-icons/io';
import styled from 'styled-components';

interface AppliedFiltersProps {
  manager: string;
  country: string;
  setManager: () => void;
  setCountry: () => void;
}

const AppliedFilters = ({
  manager,
  country,
  setManager,
  setCountry,
}: AppliedFiltersProps) => {
  return (
    <FilterByContainer>
      <FilterByLabel>Filter By:</FilterByLabel>
      {manager && (
        <FilterByItem>
          {manager}
          <FilterByCloseIcon onClick={setManager} />
        </FilterByItem>
      )}
      {country && (
        <FilterByItem>
          {country}
          <FilterByCloseIcon onClick={setCountry} />
        </FilterByItem>
      )}
    </FilterByContainer>
  );
};

const FilterByContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 6px;
  row-gap: 6px;
  margin-bottom: 16px;
`;

const FilterByLabel = styled.div`
  color: ${({ theme }) => theme.colors.grey[900]};
  font-size: 11px;
`;

const FilterByItem = styled.div`
  background-color: #57b4f9;
  color: ${({ theme }) => theme.colors.grey.white};
  font-size: 11px;
  padding: 4px 12px;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterByCloseIcon = styled(IoMdClose)`
  cursor: pointer;
  margin-left: 4px;
  font-size: 12px;
`;

export default AppliedFilters;
