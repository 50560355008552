import {
  ArpiCompare,
  AvgAdImpPerUserCompare,
  AvgPlayTimeUserCompare,
  LevelProgressionCompare,
  PlayTimeCompare,
  SundayRetentionCompare,
} from 'components/charts';
import { CampaignDetails } from 'types';

interface ContentProps {
  campaignOneData: CampaignDetails;
  campaignTwoData: CampaignDetails;
}

const Content: React.FC<ContentProps> = ({
  campaignOneData,
  campaignTwoData,
}) => {
  const kpisOne = campaignOneData.kpis;
  const kpisTwo = campaignTwoData.kpis;
  const showPlayTimeChart =
    kpisOne?.playTime !== undefined && kpisTwo?.playTime !== undefined;
  const showAvgPlayTimePerUserChart =
    kpisOne?.cohortAveragePlaytimePerUser !== undefined &&
    kpisTwo?.cohortAveragePlaytimePerUser !== undefined;
  const showSundayRetentionChart =
    kpisOne?.sevenDayRetention !== undefined &&
    kpisTwo?.sevenDayRetention !== undefined;
  const showArpiChart =
    kpisOne?.arpiCohort !== undefined && kpisTwo?.arpiCohort !== undefined;
  const showAvgAdImpPerUserChart =
    kpisOne?.adImpressionsAveragePerUser !== undefined &&
    kpisTwo?.adImpressionsAveragePerUser !== undefined;

  const showLevelProgressionChart =
    kpisOne?.levelProgression !== undefined &&
    kpisTwo?.levelProgression !== undefined &&
    kpisOne?.churnRatePerLevel !== undefined &&
    kpisTwo?.churnRatePerLevel !== undefined;
  return (
    <div style={{ padding: '20px' }}>
      {showPlayTimeChart && (
        <PlayTimeCompare
          dataOne={kpisOne.playTime!!}
          dataTwo={kpisTwo.playTime!!}
        />
      )}
      {showAvgPlayTimePerUserChart && (
        <AvgPlayTimeUserCompare
          dataOne={kpisOne?.cohortAveragePlaytimePerUser.average.values}
          dataTwo={kpisTwo?.cohortAveragePlaytimePerUser.average.values}
        />
      )}
      {showSundayRetentionChart && (
        <SundayRetentionCompare
          dataOne={kpisOne.sevenDayRetention?.average.values!!}
          dataTwo={kpisTwo.sevenDayRetention?.average.values!!}
        />
      )}
      {showArpiChart && (
        <ArpiCompare
          dataOne={kpisOne.arpiCohort?.average.values!!}
          dataTwo={kpisTwo.arpiCohort?.average.values!!}
        />
      )}
      {showAvgAdImpPerUserChart && (
        <AvgAdImpPerUserCompare
          dataOne={kpisOne.adImpressionsAveragePerUser!!}
          dataTwo={kpisTwo.adImpressionsAveragePerUser!!}
        />
      )}
      {showLevelProgressionChart && (
        <LevelProgressionCompare
          levelProgressionDataOne={kpisOne?.levelProgression!!}
          levelProgressionDataTwo={kpisTwo?.levelProgression!!}
          churnRatePerLevelDataOne={kpisOne?.churnRatePerLevel!!}
          churnRatePerLevelDataTwo={kpisTwo?.churnRatePerLevel!!}
        />
      )}
    </div>
  );
};

export default Content;
