import noResultSrc from 'assets/images/searchBar/cat-gray.png';

const NoResults = () => {
  return (
    <section className="results">
      <article className="no-results">
        <img src={noResultSrc} alt="no result" className="no-results-img" />
        <span className="no-results-text">No Results</span>
      </article>
    </section>
  );
};

export default NoResults;
