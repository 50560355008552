import styled from 'styled-components';

export const ConfigsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 10px;

  & > * {
    width: 50%;
  }

  .configs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .general_parameters {
      flex: 1;
      width: 90%;
    }

    .target_parameters {
      padding: 20px;
      border-radius: 4px;
      border: 1px solid #dfdfdf;

      .title {
        color: #757575;
        font-weight: 300;
        margin-bottom: 20px;
      }
    }
  }

  .description {
    margin-left: 72px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 28px;
  margin: 10px 0px;

  label {
    font-size: 20px;
    width: 50%;
  }
`;

export const RadioWrapper = styled.div`
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextInputStyles = {
  height: '28px',
  width: '250px',
  margin: 0,
  textIndent: '8px',
};
