import axios from 'axios';
import { getToken, removeToken } from 'services/api/authService';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  headers: {},
});

/*
  todo: convert any type to specific types
  TODO: remove axios instance and use axios global configuration
*/
axiosInstance.interceptors.request.use(
  (request: any) => {
    if (request.url !== '/authenticate') {
      const { token, refreshToken } = getToken();
      request.headers['Authenticate'] = token;
      request.headers['Refresh'] = refreshToken;
    }
    return request;
  },
  error => Promise.reject(error)
);

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: any = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((tokens: any) => {
            originalRequest.headers['Authenticate'] = tokens.token;
            originalRequest.headers['Refresh'] = tokens.refreshToken;
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const sessionState = JSON.parse(localStorage.getItem('sessionState')!);
      return new Promise(function (resolve, reject) {
        axios
          .request({
            baseURL: `${process.env.REACT_APP_BACKEND_URL}/validateSession`,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authenticate: sessionState.token,
              Refresh: sessionState.refreshToken,
            },
          })
          .then(({ data }) => {
            const session = {
              token: data?.token,
              refreshToken: data?.refreshToken,
            };
            localStorage.setItem('sessionState', JSON.stringify(session));
            originalRequest.headers['Authenticate'] = data.token;
            originalRequest.headers['Refresh'] = data.refreshToken;
            processQueue(null, {
              token: data.token,
              refreshToken: data.refreshToken,
            });
            resolve(axios(originalRequest));
          })
          .catch(err => {
            if (err.response.status === 403) {
              removeToken();
              window.location.href = '/logout';
            }
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    } else if (error.response.status === 403) {
      removeToken();
      window.location.href = '/logout';
    }

    return Promise.reject(error);
  }
);

export { axiosInstance };
