import {
  CampaignNetworks,
  Country,
  SelectOption,
  UnityParameters,
} from 'types';
import {
  ConfigsContainer,
  InputContainer,
  RadioWrapper,
  TextInputStyles,
} from './AdvancedDetailsStep.styles';
import Description from './Description';
import Radio from './Radio';
import StyledSelect from './StyledSelect';
import {
  allowedDevicesOptions,
  billingTypeOptions,
  goalOptions,
  unityBidStrategyOptions,
} from './constants';
import { TextInput } from 'components/atoms';
import { DescriptionType, useDescription } from './useDescription';
import { map } from 'lodash';
import { useFetchCountries } from 'api/modules/useCountries';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { unitySchema } from './schema';
import { useCallback, useEffect } from 'react';
import { getDefaultCountryOption } from './utils';

interface FormData {
  totalBudget: number;
  dailyBudget: number;
  bid: number;
  country: { label: string; value: string } | null;
}

interface UnityProps {
  disableFacebook: boolean;
  handleNetworkChange: (network: CampaignNetworks) => void;
  parameters: UnityParameters | null;
  handleParameters: (parameters: UnityParameters | null) => void;
}

const Unity: React.FC<UnityProps> = ({
  disableFacebook,
  handleNetworkChange,
  parameters,
  handleParameters,
}) => {
  const { data: countries, isSuccess } = useFetchCountries();
  let countriesOptions: SelectOption[] = [];

  if (isSuccess) {
    countriesOptions = map(countries, (country: Country) => ({
      label: country.name,
      value: country.isoAlpha2Name,
    }));
  }
  const {
    descriptionType,
    descriptionTitle,
    descriptionContent,
    handleDescriptionType,
  } = useDescription(DescriptionType.goal);

  const handleNetwork = () => {
    handleNetworkChange(CampaignNetworks.facebook);
    handleParameters(null);
  };

  const defaultValues = {
    dailyBudget: parameters?.dailyBudget ? parameters?.dailyBudget : 0,
    totalBudget: parameters?.totalBudget ? parameters?.totalBudget : 0,
    bid: parameters?.bid ? parameters?.bid : 0,
    country: getDefaultCountryOption(parameters, countriesOptions),
  };

  const {
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(unitySchema),
    defaultValues: {
      totalBudget: defaultValues.totalBudget,
      dailyBudget: defaultValues.dailyBudget,
      bid: defaultValues.bid,
      country: defaultValues.country,
    },
  });

  const { totalBudget, dailyBudget, bid, country } = getValues();

  // To trigger form-validation on mount
  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (
      !!errors.totalBudget ||
      !!errors.dailyBudget ||
      !!errors.bid ||
      !!errors.country
    ) {
      handleParameters(null);
      return;
    }

    const parameters: UnityParameters = {
      goal: goalOptions[0].value,
      totalBudget: Number(totalBudget),
      dailyBudget: Number(dailyBudget),
      bid: Number(bid),
      country: country?.value!,
    };

    handleParameters(parameters);
  }, [
    errors.totalBudget,
    errors.dailyBudget,
    errors.bid,
    errors.country,
    handleParameters,
    totalBudget,
    dailyBudget,
    bid,
    country,
  ]);

  const isTotalBudgetError = (() =>
    !!errors.totalBudget && getValues().totalBudget !== 0)();

  const isDailyBudgetError = (() =>
    !!errors.dailyBudget && getValues().dailyBudget !== 0)();
  const isBidError = (() => !!errors.bid && getValues().bid !== 0)();

  const getErrorMessage = useCallback((): string | undefined => {
    const errorDescriptionList = [
      DescriptionType.totalBudget,
      DescriptionType.dailyBudget,
      DescriptionType.bid,
    ];
    if (!errorDescriptionList.includes(descriptionType)) return undefined;

    const { totalBudget, dailyBudget, bid } = errors;

    if (descriptionType === DescriptionType.totalBudget && isTotalBudgetError)
      return totalBudget?.message;
    else if (
      descriptionType === DescriptionType.dailyBudget &&
      isDailyBudgetError
    )
      return dailyBudget?.message;
    else if (descriptionType === DescriptionType.bid && isBidError)
      return bid?.message;
    else return undefined;
  }, [
    errors,
    descriptionType,
    isTotalBudgetError,
    isDailyBudgetError,
    isBidError,
  ]);

  return (
    <ConfigsContainer>
      <div className="configs">
        <div className="general_parameters">
          <InputContainer>
            <label>Network:</label>
            <RadioWrapper>
              <Radio
                type={CampaignNetworks.facebook}
                label="Facebook"
                isChecked={false}
                isDisabled={disableFacebook}
                onChange={handleNetwork}
              />
              <Radio
                type={CampaignNetworks.unity}
                label="Unity"
                isChecked={true}
                isDisabled={false}
                onChange={() => {}}
              />
            </RadioWrapper>
          </InputContainer>
          <InputContainer>
            <label>Goal:</label>
            <StyledSelect
              isDisabled={true}
              options={goalOptions}
              defaultValue={goalOptions[0]}
              onMouseEnter={() => handleDescriptionType(DescriptionType.goal)}
              onChange={() => {}}
            />
          </InputContainer>
          <InputContainer>
            <label>Total budget ($):</label>
            <Controller
              name="totalBudget"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  value={field.value || ''}
                  type="number"
                  placeholder="Type something..."
                  style={TextInputStyles}
                  onMouseEnter={() =>
                    handleDescriptionType(DescriptionType.totalBudget)
                  }
                  onChange={e => {
                    field.onChange(e);
                    trigger('totalBudget');
                  }}
                  error={isTotalBudgetError}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <label>Daily budget ($):</label>

            <Controller
              name="dailyBudget"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  value={field.value || ''}
                  type="number"
                  placeholder="Type something..."
                  style={TextInputStyles}
                  onMouseEnter={() =>
                    handleDescriptionType(DescriptionType.dailyBudget)
                  }
                  onChange={e => {
                    field.onChange(e);
                    trigger('dailyBudget');
                  }}
                  error={isDailyBudgetError}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <label>Bid ($):</label>

            <Controller
              name="bid"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  value={field.value || ''}
                  type="number"
                  placeholder="Type something..."
                  style={TextInputStyles}
                  onMouseEnter={() =>
                    handleDescriptionType(DescriptionType.bid)
                  }
                  onChange={e => {
                    field.onChange(e);
                    trigger('bid');
                  }}
                  error={isBidError}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <label>Bid strategy:</label>
            <StyledSelect
              isDisabled={true}
              options={unityBidStrategyOptions}
              defaultValue={unityBidStrategyOptions[0]}
              onMouseEnter={() =>
                handleDescriptionType(DescriptionType.unityBidStrategy)
              }
              onChange={() => {}}
            />
          </InputContainer>
          <InputContainer>
            <label>Billing type:</label>
            <StyledSelect
              isDisabled={true}
              options={billingTypeOptions}
              defaultValue={billingTypeOptions[0]}
              onMouseEnter={() =>
                handleDescriptionType(DescriptionType.billingType)
              }
              onChange={() => {}}
            />
          </InputContainer>
        </div>
        <div className="target_parameters">
          <div className="title">Targeting parameters</div>
          <InputContainer>
            <label>Allowed devices:</label>
            <StyledSelect
              isDisabled={true}
              options={allowedDevicesOptions}
              defaultValue={allowedDevicesOptions[0]}
              onMouseEnter={() =>
                handleDescriptionType(DescriptionType.allowedDevices)
              }
              onChange={() => {}}
            />
          </InputContainer>
          <InputContainer>
            <label>Country:</label>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  isSearchable={true}
                  isDisabled={false}
                  options={countriesOptions}
                  onMouseEnter={() =>
                    handleDescriptionType(DescriptionType.unityCountry)
                  }
                  onChange={selectedOption => {
                    field.onChange(selectedOption);
                    trigger('country');
                  }}
                  defaultValue={defaultValues.country as SelectOption}
                />
              )}
            />
          </InputContainer>
        </div>
      </div>
      <div className="description">
        {descriptionTitle && descriptionContent && (
          <Description
            title={descriptionTitle}
            content={descriptionContent}
            error={getErrorMessage()}
          />
        )}
      </div>
    </ConfigsContainer>
  );
};

export default Unity;
