import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removePrevLocation, removeToken } from 'services/api';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    removeToken();
    removePrevLocation();
    navigate('/');
  });

  return null;
}

export default Logout;
