import styled, { css, useTheme } from 'styled-components';
import { TextInput } from "components/atoms";

export const StyledTextInput = styled(TextInput)`
  height: 32px;
`;

export const useStudioSelectStyles = () => {
  const theme = useTheme();

    return {
      control: (provided:any) => ({
        ...provided,
        minHeight: '32px',
        height: '32px',
      }),
      container: (provided: any) => ({
        ...provided,    
        borderRadius: '4px',
        fontFamily: 'Rubik,sans-serif',
        fontSize: '16px',
        margin: '6px 0 20px',
        width: '100%',
        height:'32px',
      }),
      valueContainer: (provided:any) => ({
        ...provided,
        height: '32px',
        padding: '4px 14px'
      }),
    
      input: (provided:any) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      indicatorsContainer: (provided:any) => ({
        ...provided,
        height: '32px',
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: '#B5B5B5',
        padding: '0px 4px'
      }),
      dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        color: state.selectProps.error
          ? theme.colors.pink[500]
          : theme.colors.blue[500],
      }),
      clearIndicator: (provided: any, state: any) => ({
        ...provided,
        color: state.selectProps.error
          ? theme.colors.pink[500]
          : theme.colors.blue[500],
      }),
    };
  };

export const FormWrapper = styled.form`
  display: block;
  padding: 15px 100px 25px;
`;

export const TopFormElementsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 0fr;
  width: 100%;
  column-gap: 32px;
  ${() =>
    window.innerHeight <= 720
      ? css`
          row-gap: 0;
        `
      : css`
          row-gap: 12px;
        `}
`;

export const LogoUploadWrapper = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;

  .upload-button-container {
    display: flex;
    flex-direction: column;
  }
`;

export const StudioLogoUploadWrapper = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-height: 198px;
  background-color: #F6F6F6;
  border-radius: 8px;
  padding: 20px;

  .upload-button-container {
    display: flex;
    flex-direction: column;
  }
`;
interface EmptyLogoCircleProps {
  url?: string | null;
}

export const EmptyLogoCircle = styled.div<EmptyLogoCircleProps>`
  background: #D9D9D9;
  width: 96px;
  height: 96px;
  border-radius: 50%;

  ${(props: EmptyLogoCircleProps) =>
    props.url &&
    css`
      background-image: ${(props: EmptyLogoCircleProps) => `url(${props.url})`};
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    `};
`;
export const GameEmptyLogo = styled.div<EmptyLogoCircleProps>`
  background: #D9D9D9;
  width: 155px;
  height: 155px;
  border-radius: 8px;

  ${(props: EmptyLogoCircleProps) =>
    props.url &&
    css`
      background-image: ${(props: EmptyLogoCircleProps) => `url(${props.url})`};
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    `};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
`;

export const FormErrorHint = styled.div`
  color: ${({ theme }) => theme.colors.pink[500]};
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  margin-top: 8px;
`;

export const FormFieldError = styled.div`
  color: ${({ theme }) => theme.colors.pink[500]};
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  margin: -12px 0 8px 0;
`;

export const ErrorPopupDescription = styled.div`
  font-size: 12px;
  margin-top: 12px;
`;

export const LogoRequirements = styled.div`
  color: #686868;
  text-align: center;
  line-height: 1.4;
  font-size: 12px;
`;