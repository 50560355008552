import { useState } from 'react';

export const useSidebarRemoval = (toggleSidebar: () => void) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const handleRemove = () => {
    setIsRemoving(true);
    setTimeout(() => {
      toggleSidebar();
      setIsRemoving(false);
    }, 300);
  };

  return [isRemoving, handleRemove] as const;
};
