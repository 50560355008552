import {
  MonetizationStep,
  MonetizationSubStep,
  MonetizationSubStepGroup,
} from 'types';
import Card from '../Card/Card';
import { useState } from 'react';
import ConfirmMonetizationModal from '../ConfirmMonetizationModal';
import { useFetchMonetizationSteps, useSetupMonetization } from 'api';
import { useParams } from 'react-router-dom';
import CardsAccordion from '../CardsAccordion/CardsAccordion';

interface CardsListProps {
  monetizationStep: MonetizationStep;
  selectedOs: 'android' | 'ios';
}

const CardsList: React.FC<CardsListProps> = ({
  monetizationStep,
  selectedOs,
}) => {
  const { gameId } = useParams<{
    gameId: string;
  }>();
  const { refetch: refetchMonetizationSteps } = useFetchMonetizationSteps(
    Number(gameId),
    selectedOs,
    { enabled: false }
  );
  const { mutate: setupMonetization } = useSetupMonetization(Number(gameId));
  const [selectedStep, setSelectedStep] = useState<
    MonetizationStep | MonetizationSubStep | null
  >(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleCardClick = (step: MonetizationStep | MonetizationSubStep) => {
    setSelectedStep(step);
    setShowConfirmModal(true);
  };

  const handleSubmit = () => {
    if (selectedStep !== null) {
      let reqBody;
      if (selectedStep.step === 'levelPlayApp' || selectedStep.step === 'max') {
        reqBody = {
          step: selectedStep.step,
          os: selectedOs,
        };
      } else {
        reqBody = {
          parentStep: monetizationStep.step,
          step: selectedStep.step,
          os: selectedOs,
        };
      }
      setupMonetization(reqBody, {
        onSuccess: () => {
          refetchMonetizationSteps();
          setSelectedStep(null);
          setShowConfirmModal(false);
        },
        onError: () => {
          setSelectedStep(null);
          setShowConfirmModal(false);
        },
      });
    }
  };

  const monetizationSubSteps = monetizationStep.subSteps;
  const biddingSteps = monetizationSubSteps.filter(
    step => step.group === MonetizationSubStepGroup.bidding
  );
  const nonBiddingSteps = monetizationSubSteps.filter(
    step => step.group === MonetizationSubStepGroup.nonBidding
  );
  return (
    <>
      <div className="top-container">
        <Card
          step={monetizationStep.step}
          displayName={monetizationStep.displayName}
          status={monetizationStep.status}
          clickable={monetizationStep.clickable}
          onClick={() => handleCardClick(monetizationStep)}
        />
        <CardsAccordion title="Bidding" isDisabled={biddingSteps.length === 0}>
          {biddingSteps.map(subStep => (
            <Card
              key={subStep.step}
              step={subStep.step}
              displayName={subStep.displayName}
              status={subStep.status}
              clickable={subStep.clickable}
              message={subStep.message ?? ''}
              onClick={() => handleCardClick(subStep)}
              isDisabled={!subStep.clickable}
            />
          ))}
        </CardsAccordion>
        <CardsAccordion
          title="Waterfall"
          isDisabled={nonBiddingSteps.length === 0}
        >
          {nonBiddingSteps.map(subStep => (
            <Card
              key={subStep.step}
              step={subStep.step}
              displayName={subStep.displayName}
              status={subStep.status}
              clickable={subStep.clickable}
              message={subStep.message ?? ''}
              onClick={() => handleCardClick(subStep)}
              isDisabled={!subStep.clickable}
            />
          ))}
        </CardsAccordion>
        <ConfirmMonetizationModal
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          handleConfirm={handleSubmit}
          stepName={selectedStep?.displayName ?? ''}
          message={selectedStep?.message ?? ''}
        />
      </div>
    </>
  );
};

export default CardsList;
