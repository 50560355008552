import { spacing, Spacing } from 'assets/styles';
import styled from 'styled-components';

const StyledCollapse = styled.div<Spacing>`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  ${spacing}

  .header {
    width: 100%;
    background-color: #eeeeee;
    padding: 12px 16px;
    cursor: pointer;
  }

  .body {
    padding: 16px;
    display: none;

    &.open {
      display: block;
    }
  }
`;

export default StyledCollapse;
