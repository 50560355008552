import { useEditDescription } from 'api';
import CampaignDescriptionModal from 'components/CampaignDescriptionModal/CampaignDescriptionModal';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

interface AddCampaignDescriptionProps {
  gameId: number;
  campaignId: number;
  onSuccess: () => void;
}

const AddCampaignDescription = ({
  gameId,
  campaignId,
  onSuccess,
}: AddCampaignDescriptionProps) => {
  const { mutate: editDescription } = useEditDescription(gameId, campaignId);
  const [modal, setModal] = useState(false);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleEditDescription = (text: string) => {
    editDescription(text, {
      onSuccess: () => {
        toast.success('The description was successfully added', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        onSuccess();
      },
      onError: () =>
        toast.error('Something went wrong. Please, try again later.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        }),
    });
    handleModal();
  };
  return (
    <>
      <AddButton onClick={handleModal}>Add description</AddButton>
      {modal && (
        <CampaignDescriptionModal
          onClose={handleModal}
          onSubmit={handleEditDescription}
          modalTitle="add test description"
          descriptionText={''}
        />
      )}
    </>
  );
};

export default AddCampaignDescription;

const AddButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: ${props => props.theme.colors.grey[900]};
  border-bottom: 1px dashed ${props => props.theme.colors.grey[900]};
`;
