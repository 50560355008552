import { DragAndDrop } from 'components/DragAndDrop/DragAndDrop';
import { ChangeEvent } from 'react';
import { UploadWrapper } from './Thumbnail.styles';

export const validMimeTypes = [
  'video/x-matroska',
  'video/mp4',
  'video/x-msvideo',
  'video/webm',
  'video/quicktime', // mov format
];

interface UploadThumbnailProps {
  onUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  dropHandler?: any;
}

const UploadThumbnail = ({ onUpload, dropHandler }: UploadThumbnailProps) => {
  return (
    <UploadWrapper>
      <DragAndDrop
        dropHandler={dropHandler}
        containerStyles={{ height: '100%', width: '100%' }}
      >
        <input
          id="add_video"
          type="file"
          accept={validMimeTypes.join(',')}
          multiple={true}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onUpload(e)}
          className="upload-input"
        />
        <label className="upload-label" htmlFor="add_video">
          <span className="upload-icon">+</span>
          <span className="upload-text">Add new</span>
          <span style={{ fontSize: 14, color: 'gray', marginTop: 10 }}>
            size: 1080*1350
          </span>
          <span style={{ fontSize: 14, color: 'gray', marginTop: 10 }}>
            type: mp4, mov
          </span>
        </label>
      </DragAndDrop>
    </UploadWrapper>
  );
};

export default UploadThumbnail;
