import { spacing, Spacing } from 'assets/styles';
import styled from 'styled-components';

interface StyledTagProps extends Spacing {
  fontSize: string
}

export const StyledTag = styled.div<StyledTagProps>`
  display: inline-flex;
  align-items: center;
  font-size: ${props => props.fontSize};
  border-radius: 4px;
  padding: 4px 8px;
  height: 24px;
  flex-shrink: 0;
  ${spacing};

  .tag-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tag-icon {
    cursor: pointer;
    margin-left: 8px;
    flex-shrink: 0;
  }

  &.small-size {
    max-width: 200px;
  }

  &.large-size {
    max-width: 350px;
  }

  &.blue {
    background-color: #e2faff;
  }

  &.yellow {
    background-color: #ffeab1;
  }
`;
export const StyledSmallTag = styled.div<Spacing>`
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  font-size: 11px;
  padding: 4px 8px;
  height: 20px;
  flex-shrink: 0;
  ${spacing};

  .tag-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tag-icon {
    cursor: pointer;
    margin-left: 8px;
    flex-shrink: 0;
  }

  &.small-size {
    max-width: 100px;
  }

  &.large-size {
    max-width: 170px;
  }

  &.blue {
    background-color: #e2faff;
  }

  &.yellow {
    background-color: #ffeab1;
  }
`;