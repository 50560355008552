import { Modal } from 'components';
import Comment from './Comment';
import { CommentsModalContainer } from './CommentsModal.styles';
import { useFetchInvoiceComments } from 'api';
import clsx from 'clsx';
import moment from 'moment';
import { Button } from 'components/atoms';
import { downloadBlue } from 'assets/images';
import { Icon } from 'assets/styles';

interface CommentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  downloadPDF: (invoiceId: number) => void;
  handleEditInvoice: () => void;
  isEditMode: boolean;
  studioId: number;
  invoiceId: number;
}

const getFormattedDate = (date: string) => {
  return moment(date).format('DD.MM.YYYY HH:mm');
};

const CommentsModal = ({
  isOpen,
  onClose,
  downloadPDF,
  handleEditInvoice,
  isEditMode,
  studioId,
  invoiceId,
}: CommentsModalProps) => {
  const { data, isSuccess } = useFetchInvoiceComments(studioId, invoiceId);
  return (
    <Modal title="comments" isOpen={isOpen} onClose={onClose}>
      {isSuccess && (
        <CommentsModalContainer>
          <div className="header">
            <span style={{ fontWeight: 'bold' }}>Invoice Number: </span>
            <span>#{data.invoiceNumber}</span>
          </div>
          <div className="content">
            {data.comments.map((comment, idx) => (
              <Comment
                key={idx}
                name={comment.userName}
                date={`${getFormattedDate(comment.createdAt)} CET / CEST`}
                commentText={comment.content}
              />
            ))}
          </div>
          <div
            className={clsx(
              'controls',
              { 'flex-between': isEditMode },
              { 'flex-center': !isEditMode }
            )}
          >
            {isEditMode ? (
              <>
                <Button
                  onClick={() => downloadPDF(invoiceId)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Icon src={downloadBlue} style={{ marginRight: 10 }} />
                  Download
                </Button>
                <Button primary onClick={handleEditInvoice}>
                  Edit Invoice
                </Button>
              </>
            ) : (
              <Button primary onClick={onClose}>
                Close
              </Button>
            )}
          </div>
        </CommentsModalContainer>
      )}
    </Modal>
  );
};

export default CommentsModal;
