import { useMemo } from 'react';
import { BusinessChartType, CohortTableRow } from 'types';
import { cohortChartOptionsGenerator } from '../utils/CohortChartUtilsTs';

const useChartOptions = (
  selectedRows: CohortTableRow[],
  tab: 'cumulative' | 'average',
  predictedRow: any,
  dataUnit: string
) => {
  const chartOptions = useMemo(() => {
    let rows = [];
    const avgSelected = !!selectedRows.find(
      row => row.installDate === 'Average'
    );

    if (avgSelected && predictedRow && tab === 'cumulative') {
      rows = [...selectedRows, predictedRow];
    } else {
      rows = [...selectedRows];
    }

    return cohortChartOptionsGenerator(
      rows,
      dataUnit,
      BusinessChartType.PLAYTIME_PER_AVG_USER
    );
  }, [selectedRows, tab, predictedRow, dataUnit]);

  return chartOptions;
};

export default useChartOptions;
