import 'normalize.css';
import { css } from 'styled-components';

const variables = css`
  :root {
    /* z-indexes */
    --z-index-popup: 1000;
    --z-index-modal-backdrop: 1010;
    --z-index-modal: 1010;
    --z-index-video-container: 900;
    --z-index-overlay: 1020;
    --z-index-react-select: 1030;
    --z-index-media-error-border: 800;
    --z-index-media-error-border: 900;
    --z-index-media-close-icon: 1000;
  }
`;

export default variables;
