import { campaignLinkGenerator } from 'api';
import { filter, map } from 'lodash';
import { FacebookCampaignStatus, OverviewCampaign } from 'types';
import {
  CampaignInfo,
  ContentWrapper,
  GameBadgeWrapper,
  NoContent,
  RowTitle,
  RowWrapper,
  Separator,
} from './CampaignOverview.styles';
import Logo from 'components/Logo/Logo';
import TagList from 'components/TagList/TagList';
import GameStatusBadge from 'components/Badge/GameStatusBadge/GameStatusBadge';
import CampaignDescription from 'components/CampaignDescription/CampaignDescription';
import moment from 'moment';
import CampaignStatusBadge from 'components/Badge/CampaignStatusBadge/CampaignStatusBadge';
import Tootltip from 'components/atoms/Tooltip/Tootltip';
import { Icon } from 'assets/styles';
import {
  brazil,
  facebook,
  monetization,
  unity,
  usa,
  philippines,
} from 'assets/images';

export const getTableRowsWithRefetch =
  (refetchCampaigns: () => void) => (campaigns: OverviewCampaign[]) => {
    return map(campaigns, campaign => ({
      id: campaign.id,
      link: campaignLinkGenerator({
        studioId: campaign.studioId,
        gameId: campaign.gameId,
        campaignId: campaign.id,
      }),
      highlight: campaign.relatedToUser ? 'yellow' : '',
      rows: [
        {
          columnId: 'search',
          content: (
            <CampaignInfo>
              <div className="title-wrapper">
                <Logo src={campaign.logo} name={campaign.gameName} />
                <RowWrapper>
                  <RowTitle
                    title={campaign.gameName}
                    isHeader={true}
                    fontWeight="600"
                    fontSize="17px"
                  >
                    {campaign.gameName}
                  </RowTitle>
                  <RowTitle>{campaign.studioName}</RowTitle>
                  <RowTitle fontWeight="300">{campaign.testName}</RowTitle>
                </RowWrapper>
              </div>
              <Separator />
              <TagList
                tags={campaign.tags ?? []}
                maxVisibleTags={2}
                smallSize={true}
              />
            </CampaignInfo>
          ),
        },
        {
          columnId: 'status',
          content: (
            <GameBadgeWrapper>
              <GameStatusBadge gameStatus={campaign.testType} />
              {campaign.description && (
                <div className="description-wrapper">
                  <CampaignDescription
                    gameId={campaign.gameId}
                    campaignId={campaign.id}
                    description={campaign.description}
                    onSuccess={refetchCampaigns}
                  />
                </div>
              )}
            </GameBadgeWrapper>
          ),
          sortableContent: campaign.testType,
        },
        {
          columnId: 'cpc',
          content: !!campaign.cpc ? (
            <ContentWrapper>${campaign.cpc.toFixed(2)}</ContentWrapper>
          ) : (
            <NoContent />
          ),
          sortableContent: !!campaign.cpc ? campaign.cpc : 0,
        },
        {
          columnId: 'cpi',
          content: !!campaign.cpi ? (
            <ContentWrapper>${campaign.cpi.toFixed(2)}</ContentWrapper>
          ) : (
            <NoContent />
          ),
          sortableContent: !!campaign.cpi ? campaign.cpi : 0,
        },
        {
          columnId: 'ctr',
          content: !!campaign.ctr ? (
            <ContentWrapper>{campaign.ctr.toFixed(2)}%</ContentWrapper>
          ) : (
            <NoContent />
          ),
          sortableContent: !!campaign.ctr ? campaign.ctr : 0,
        },
        {
          columnId: 'dayOneRetentionAverage',
          content: !!campaign.dayOneRetentionAverage ? (
            <ContentWrapper>
              {`${campaign.dayOneRetentionAverage.toFixed(2)}%`}
            </ContentWrapper>
          ) : (
            <NoContent />
          ),
          sortableContent: !!campaign.dayOneRetentionAverage
            ? campaign.dayOneRetentionAverage
            : 0,
        },
        {
          columnId: 'startedDate',
          content: !!campaign.startedAt ? (
            <ContentWrapper>
              {moment(campaign.startedAt).format('DD.MM.YYYY')}
            </ContentWrapper>
          ) : (
            <NoContent />
          ),
          sortableContent: campaign.startedAt
            ? moment(campaign.startedAt).unix().toString()
            : '',
        },
        {
          columnId: 'testingStatus',
          content: (
            <>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <CampaignStatusBadge
                  campaignStatus={campaign.status}
                  size="md"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '5px',
                  width: '100%',
                }}
              >
                {campaign.isMonetized && (
                  <Tootltip
                    title={`Monetized Test`}
                    placement="top"
                    size="x-sm"
                    textAlign="center"
                    titleStyle={{ fontSize: '12px' }}
                  >
                    <Icon style={{ minWidth: '32px' }} src={monetization} />
                  </Tootltip>
                )}
                <Tootltip
                  title={`Testing on ${campaign.platform}`}
                  placement="top"
                  size="xx-sm"
                  textAlign="center"
                  titleStyle={{ fontSize: '12px' }}
                >
                  <Icon
                    style={{ minWidth: '32px' }}
                    src={campaign.platform === 'facebook' ? facebook : unity}
                  />
                </Tootltip>
                <Tootltip
                  title={`Testing in ${campaign.countryDetails.name}`}
                  placement="top"
                  size="xx-sm"
                  textAlign="center"
                  titleStyle={{ fontSize: '12px' }}
                >
                  <Icon
                    style={{
                      marginTop: '4px',
                      marginLeft: '5px',
                      width: '24px',
                      height: '24px',
                      borderRadius: '7px',
                      minWidth: '24px',
                    }}
                    src={
                      campaign.countryDetails.name === 'USA'
                        ? usa
                        : campaign.countryDetails.name === 'Brazil'
                        ? brazil
                        : campaign.countryDetails.name === 'Philippines'
                        ? philippines
                        : campaign.countryDetails.flag
                    }
                  />
                </Tootltip>
              </div>
            </>
          ),
        },
      ],
    }));
  };

export const filterNegativeStatusCampaigns = (
  campaigns: OverviewCampaign[]
) => {
  const negativeStatus = [
    FacebookCampaignStatus.terminated,
    FacebookCampaignStatus.canceled,
    FacebookCampaignStatus.failed,
  ];

  return filter(
    campaigns,
    campaign => !negativeStatus.includes(campaign.status)
  );
};

export const filterBySearchText = (
  campaigns: OverviewCampaign[],
  searchText: string
): OverviewCampaign[] => {
  const checkStringMatch = (searchText: string) => (matcherStr: string) =>
    matcherStr.includes(searchText);

  return filter(campaigns, (campaign: OverviewCampaign) => {
    const { studioName, gameName, testName, tags } = campaign;
    const tagsArrString = map(tags, tag => tag.name.toLocaleLowerCase()).join(
      ', '
    );
    const isStringMatch = checkStringMatch(searchText.toLocaleLowerCase());
    return (
      isStringMatch(studioName.toLocaleLowerCase()) ||
      isStringMatch(gameName.toLocaleLowerCase()) ||
      isStringMatch(testName.toLocaleLowerCase()) ||
      isStringMatch(tagsArrString)
    );
  });
};
