import Description from './Description';
import { TextInput } from 'components/atoms';
import Radio from './Radio';
import {
  ageGroupOptions,
  bidStrategyOptions,
  billingEventOptions,
  genderOptions,
  objectiveOptions,
  operatingSystemOptions,
} from './constants';
import {
  CampaignNetworks,
  Country,
  FacebookParameters,
  SelectOption,
} from 'types';
import {
  ConfigsContainer,
  InputContainer,
  RadioWrapper,
  TextInputStyles,
} from './AdvancedDetailsStep.styles';
import StyledSelect from './StyledSelect';
import { DescriptionType, useDescription } from './useDescription';
import { useFetchCountries } from 'api/modules/useCountries';
import { map } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { facebookSchema } from './schema';
import { getDefaultCountryOption } from './utils';

interface FormData {
  dailyBudget: number;
  daysOfCampaign: number;
  country: { label: string; value: string } | null;
  totalBudget: number;
}

interface FacebookProps {
  disableUnity: boolean;
  handleNetworkChange: (network: CampaignNetworks) => void;
  parameters: FacebookParameters | null;
  handleParameters: (parameters: FacebookParameters | null) => void;
}

const Facebook: React.FC<FacebookProps> = ({
  disableUnity,
  handleNetworkChange,
  parameters,
  handleParameters,
}) => {
  const { data: countries, isSuccess } = useFetchCountries();
  let countriesOptions: SelectOption[] = [];

  if (isSuccess) {
    countriesOptions = map(countries, (country: Country) => ({
      label: country.name,
      value: country.isoAlpha2Name,
    }));
  }
  const {
    descriptionType,
    descriptionTitle,
    descriptionContent,
    handleDescriptionType,
  } = useDescription(DescriptionType.objective);

  const handleNetwork = () => {
    handleNetworkChange(CampaignNetworks.unity);
    handleParameters(null);
  };

  const defaultValues = {
    dailyBudget: parameters?.dailyBudget ? parameters?.dailyBudget : 0,
    daysOfCampaign: parameters?.duration ? parameters?.duration : 0,
    country: getDefaultCountryOption(parameters, countriesOptions),
  };

  const {
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(facebookSchema),
    defaultValues: {
      dailyBudget: defaultValues.dailyBudget,
      daysOfCampaign: defaultValues.daysOfCampaign,
      country: defaultValues.country,
    },
  });

  const { dailyBudget, daysOfCampaign, country } = getValues();

  // To trigger form-validation on mount
  useEffect(() => {
    trigger();
  }, [trigger]);

  // To update parameters on-change
  useEffect(() => {
    if (
      !!errors.dailyBudget ||
      !!errors.daysOfCampaign ||
      !!errors.totalBudget ||
      !!errors.country
    ) {
      handleParameters(null);
      return;
    }

    const parameters: FacebookParameters = {
      dailyBudget: Number(dailyBudget),
      duration: Number(daysOfCampaign),
      country: country?.value!,
    };

    handleParameters(parameters);
  }, [
    errors.dailyBudget,
    errors.daysOfCampaign,
    errors.totalBudget,
    errors.country,
    handleParameters,
    dailyBudget,
    daysOfCampaign,
    country,
  ]);

  const isDailyBudgetError = (() =>
    (!!errors.dailyBudget && getValues().dailyBudget !== 0) ||
    (!!errors.totalBudget &&
      getValues().dailyBudget !== 0 &&
      getValues().daysOfCampaign !== 0))();

  const isDaysOfCampaignError = (() =>
    (!!errors.daysOfCampaign && getValues().daysOfCampaign !== 0) ||
    (!!errors.totalBudget &&
      getValues().daysOfCampaign !== 0 &&
      getValues().dailyBudget !== 0))();

  const isTotalBudgetError = (() =>
    !!errors.totalBudget &&
    getValues().dailyBudget !== 0 &&
    getValues().daysOfCampaign !== 0)();

  const getErrorMessage = useCallback((): string | undefined => {
    const errorDescriptionList = [
      DescriptionType.dailyBudget,
      DescriptionType.daysOfCampaign,
    ];
    if (!errorDescriptionList.includes(descriptionType)) return undefined;

    const { dailyBudget, daysOfCampaign, totalBudget } = errors;

    if (
      (descriptionType === DescriptionType.dailyBudget ||
        descriptionType === DescriptionType.daysOfCampaign) &&
      isTotalBudgetError
    )
      return totalBudget?.message;
    else if (
      descriptionType === DescriptionType.dailyBudget &&
      isDailyBudgetError
    )
      return dailyBudget?.message;
    else if (
      descriptionType === DescriptionType.daysOfCampaign &&
      isDaysOfCampaignError
    )
      return daysOfCampaign?.message;
    else return undefined;
  }, [
    errors,
    descriptionType,
    isDailyBudgetError,
    isDaysOfCampaignError,
    isTotalBudgetError,
  ]);

  return (
    <ConfigsContainer>
      <div className="configs">
        <div className="general_parameters">
          <InputContainer>
            <label>Network:</label>
            <RadioWrapper>
              <Radio
                type={CampaignNetworks.facebook}
                label="Facebook"
                isChecked={true}
                isDisabled={false}
                onChange={() => {}}
              />
              <Radio
                type={CampaignNetworks.unity}
                label="Unity"
                isChecked={false}
                isDisabled={disableUnity}
                onChange={handleNetwork}
              />
            </RadioWrapper>
          </InputContainer>
          <InputContainer>
            <label>Objective:</label>
            <StyledSelect
              isDisabled={true}
              options={objectiveOptions}
              defaultValue={objectiveOptions[0]}
              onMouseEnter={() =>
                handleDescriptionType(DescriptionType.objective)
              }
              onChange={() => {}}
            />
          </InputContainer>
          <InputContainer>
            <label>Daily budget ($):</label>
            <Controller
              name="dailyBudget"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  value={field.value || ''}
                  type="number"
                  placeholder="Type something..."
                  style={TextInputStyles}
                  onMouseEnter={() =>
                    handleDescriptionType(DescriptionType.dailyBudget)
                  }
                  onChange={e => {
                    field.onChange(e);
                    trigger('dailyBudget');
                    trigger('totalBudget');
                  }}
                  error={isDailyBudgetError}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <label>Days of campaign:</label>
            <Controller
              name="daysOfCampaign"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  value={field.value || ''}
                  type="number"
                  placeholder="Type something..."
                  style={TextInputStyles}
                  onMouseEnter={() =>
                    handleDescriptionType(DescriptionType.daysOfCampaign)
                  }
                  onChange={e => {
                    field.onChange(e);
                    trigger('daysOfCampaign');
                    trigger('totalBudget');
                  }}
                  error={isDaysOfCampaignError}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <label>Bid strategy:</label>
            <StyledSelect
              isDisabled={true}
              options={bidStrategyOptions}
              defaultValue={bidStrategyOptions[0]}
              onMouseEnter={() =>
                handleDescriptionType(DescriptionType.bidStrategy)
              }
              onChange={() => {}}
            />
          </InputContainer>
          <InputContainer>
            <label>Billing event:</label>
            <StyledSelect
              isDisabled={true}
              options={billingEventOptions}
              defaultValue={billingEventOptions[0]}
              onMouseEnter={() =>
                handleDescriptionType(DescriptionType.billingEvent)
              }
              onChange={() => {}}
            />
          </InputContainer>
        </div>

        <div className="target_parameters">
          <div className="title">Targeting parameters</div>
          <InputContainer>
            <label>Operating system:</label>
            <StyledSelect
              isDisabled={true}
              options={operatingSystemOptions}
              defaultValue={operatingSystemOptions[0]}
              onMouseEnter={() =>
                handleDescriptionType(DescriptionType.operatingSystem)
              }
              onChange={() => {}}
            />
          </InputContainer>
          <InputContainer>
            <label>Country:</label>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  isSearchable={true}
                  isDisabled={false}
                  options={countriesOptions}
                  onMouseEnter={() =>
                    handleDescriptionType(DescriptionType.country)
                  }
                  onChange={selectedOption => {
                    field.onChange(selectedOption);
                    trigger('country');
                  }}
                  defaultValue={defaultValues.country as SelectOption}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <label>Gender:</label>
            <StyledSelect
              isDisabled={true}
              options={genderOptions}
              defaultValue={genderOptions[0]}
              onMouseEnter={() => handleDescriptionType(DescriptionType.gender)}
              onChange={() => {}}
            />
          </InputContainer>
          <InputContainer>
            <label>Age group:</label>
            <StyledSelect
              isDisabled={true}
              options={ageGroupOptions}
              defaultValue={ageGroupOptions[0]}
              onMouseEnter={() =>
                handleDescriptionType(DescriptionType.ageGroup)
              }
              onChange={() => {}}
            />
          </InputContainer>
        </div>
      </div>
      <div className="description">
        {descriptionTitle && descriptionContent && (
          <Description
            title={descriptionTitle}
            content={descriptionContent}
            error={getErrorMessage()}
          />
        )}
      </div>
    </ConfigsContainer>
  );
};

export default Facebook;
