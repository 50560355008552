import styled from 'styled-components';

export const CenteredWrapper = styled.div`
  text-align: center;
`;

export const StyledWrapper = styled.div`
  display: inline-block;
  text-align: left;
  align-items: center;
  width: 80%;
`;
