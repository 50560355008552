import clsx from 'clsx';
import { GameLogo, StudioLogo } from 'components/atoms';
import { CampaignStatusBadge } from 'components';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import FavIcon from './FavIcon';
import ListItemText from './ListItemText';
import { NavCampaign, NavGame, NavStudio } from 'types';
import { Icon } from 'assets/styles';
import { monetization } from 'assets/images';

type NavListItemProps =
  | {
      type: 'studio';
      data: NavStudio;
    }
  | {
      type: 'game';
      data: NavGame;
    }
  | {
      type: 'campaign';
      data: NavCampaign;
    };

const NavListItem = (props: NavListItemProps) => {
  const params = useParams<{
    studioId: string;
    gameId: string;
    campaignId: string;
  }>();
  if (props.type === 'studio') {
    const { data } = props;
    const isCurrentStudio = Number(params.studioId) === data.id;
    return (
      <Item className={clsx({ 'active-item': isCurrentStudio })}>
        <StudioLogo name={data.name} url={data.logo} />
        <ListItemText title={data.name} />
        {!data.isArchived && data.isFavorite && (
          <FavIcon id={data.id} favorite={true} />
        )}
        {!data.isArchived && !data.isFavorite && (
          <FavIcon id={data.id} favorite={false} />
        )}
      </Item>
    );
  } else if (props.type === 'game') {
    const { data } = props;
    const isCurrentGame = Number(params.gameId) === data.id;
    return (
      <Item className={clsx({ 'active-item': isCurrentGame })}>
        <GameLogo url={data.logo} name={data.name} size="sm" />
        <ListItemText title={data.name} subtitle={data.status} />
      </Item>
    );
  } else {
    const { data } = props;
    const isCurrentCampaign = Number(params.campaignId) === data.id;
    return (
      <Item className={clsx({ 'active-item': isCurrentCampaign })}>
        <ListItemText title={data.name} />
        {data.isMonetized && (
          <Icon style={{ minWidth: '32px' }} src={monetization} />
        )}
        <CampaignStatusBadge campaignStatus={data.status} size="sm" />
      </Item>
    );
  }
};

const Item = styled.li`
  min-height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 10px;

  &.active-item {
    background-color: ${({ theme }) => theme.colors.grey[1300]};
  }

  &:hover {
    background-color: rgb(217, 217, 217);
  }
`;

export default NavListItem;
