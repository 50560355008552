import { useFetchCampaign, useUpdateScheduleCampaign } from 'api';
import { DateTimePicker, Modal } from 'components';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ModalSize } from 'types';
import { toast } from 'react-toastify';
import { Wrapper } from './ScheduleActivationModal.styles';
import { Button } from 'components/atoms';
import { getFormattedDateString } from 'utils';

interface ScheduleActivationModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const handleScheduleActivationSuccess = (text: string) => {
  toast.success(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};
const ScheduleActivationModal = ({
  isOpen,
  onClose,
}: ScheduleActivationModalProps) => {
  const params = useParams<{
    gameId: string;
    campaignId: string;
  }>();
  const gameId = Number(params.gameId);
  const campaignId = Number(params.campaignId);

  const { refetch: fetchCampaign } = useFetchCampaign(gameId, campaignId, {
    enabled: false,
  });

  const [activationStartDate, setActivationStartDate] = useState('');
  const [activationStartTime, setActivationStartTime] = useState('');

  const { mutate: scheduleCampaign } = useUpdateScheduleCampaign(
    gameId,
    campaignId
  );

  const disableSubmitButton =
    activationStartDate === '' || activationStartTime === '';

  const onDateChange = (date: Date) => {
    setActivationStartDate(getFormattedDateString(date));
  };

  const onTimeChange = (time: string) => {
    setActivationStartTime(time);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    scheduleCampaign(
      {
        startAt: `${activationStartDate} ${activationStartTime}`,
      },
      {
        onSuccess: () => {
          handleScheduleActivationSuccess('The schedule was successfully updated')
          // Call Campaign API and close modal
          onClose();
          fetchCampaign();
        },
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Schedule test"
      onClose={onClose}
      size={ModalSize.sm_md}
    >
      <Wrapper>
        <div className="content">
          <DateTimePicker
            onDateChange={onDateChange}
            onTimeChange={onTimeChange}
          />
        </div>
        <div>
          <Button onClick={handleCancel}>CANCEL</Button>
          <Button primary onClick={handleSubmit} disabled={disableSubmitButton}>
            SCHEDULE TEST
          </Button>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default ScheduleActivationModal;
