import { useFetchGame } from 'api';
import { EmptyLogoSquare } from 'components/AddGame/AddGame.styles';
import { FormFieldError } from 'components/AddStudioModal/AddStudioModal.styles';
import Tag from 'components/Tag/Tag';
import { TextInput } from 'components/atoms';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGameWizard } from 'store';
import styled, { css } from 'styled-components';
import { GameOrientation, GameParams } from 'types';

export const TextInputStyles = {
  height: '28px',
  width: '250px',
  margin: 0,
  textIndent: '8px',
};

interface InputContainerGeneratorProps {
  hasAsterisk?: boolean;
  labelText: string;
  value: any;
  placeholder?: string;
}

const InputContainerGenerator = ({
  labelText,
  value,
  placeholder,
  hasAsterisk = false,
}: InputContainerGeneratorProps) => (
  <InputContainer>
    <label>{labelText}</label>
    <StyledTextInput
      placeholder={placeholder}
      hasAsterisk={hasAsterisk}
      disabled
      style={TextInputStyles}
      value={value}
    />
  </InputContainer>
);

interface ConfirmationStepProps {
  isCreateMode: boolean;
  error: string;
}

const ConfirmationStep = ({ isCreateMode, error }: ConfirmationStepProps) => {
  const params = useParams<GameParams>();
  const { networkDetails, basicDetails } = useGameWizard(state => state);
  const { data: game, isSuccess } = useFetchGame(Number(params.gameId));
  const [fbAppSecret, setFbAppSecret] = useState('');
  const [fbUserAccess, setFbUserAccess] = useState('');

  useEffect(() => {
    if (!isCreateMode && isSuccess) {
      setFbAppSecret(game.facebookAppSecret);
      setFbUserAccess(game.facebookUserAccessToken);
    }
  }, [isCreateMode, isSuccess]);

  const facebookDetailsExists =
    (isCreateMode && networkDetails.facebook.appId) ||
    networkDetails.facebook.appSecret ||
    networkDetails.facebook.userAccessToken ||
    networkDetails.facebook.installReferrerDecryptionKey ||
    (!isCreateMode && networkDetails.facebook.appId);
  const unityDetailsExists = networkDetails.unity.unityId;
  return (
    <StyledConfirmationStep>
      <div className="summary">
        <div className="top">
          <div className="left">
            <span className="title">Summary</span>
            <InputContainer>
              <Label>Game Name:</Label>
              <Value>{basicDetails.gameName}</Value>
            </InputContainer>
            <InputContainer>
              <Label>Tags:</Label>
              <TagsContainer>
                {basicDetails.tags.length !== 0 ? (
                  basicDetails.tags.map(tag => (
                    <Tag
                      text={tag.name}
                      key={tag.id}
                      mr={1.5}
                      mb={1.5}
                      size="lg"
                    />
                  ))
                ) : (
                  <NoTags>No tags added</NoTags>
                )}
              </TagsContainer>
            </InputContainer>
            <InputContainer>
              <Label>Orientation:</Label>
              <Value style={{ fontWeight: 'normal' }}>
                {basicDetails.orientation === GameOrientation.portrait
                  ? 'Portrait'
                  : 'Landscape'}
              </Value>
            </InputContainer>
          </div>
          <div>
            <EmptyLogoSquare url={basicDetails.logoUrl} />
          </div>
        </div>
        <div className="bottom">
          {facebookDetailsExists && (
            <div className="network-wrapper" style={{ width: '55%' }}>
              <span className="title">Facebook</span>
              <InputContainerGenerator
                labelText="App ID:"
                value={networkDetails.facebook.appId}
              />
              <InputContainerGenerator
                hasAsterisk={!isCreateMode}
                labelText="App Secret:"
                value={networkDetails.facebook.appSecret}
                placeholder={fbAppSecret}
              />
              <InputContainerGenerator
                hasAsterisk={!isCreateMode}
                labelText="User Access Token:"
                value={networkDetails.facebook.userAccessToken}
                placeholder={fbUserAccess}
              />
              <InputContainerGenerator
                hasAsterisk={!isCreateMode}
                labelText="Install Referrer Decryption Key:"
                value={networkDetails.facebook.installReferrerDecryptionKey}
                placeholder={fbUserAccess}
              />
            </div>
          )}
          {unityDetailsExists && (
            <div className="network-wrapper" style={{ width: '45%' }}>
              <span className="title">Unity</span>
              <InputContainerGenerator
                labelText="Campaign Set ID:"
                value={
                  networkDetails.unity.unityId !== null
                    ? networkDetails.unity.unityId
                    : ''
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="error-wrapper">
        {error.length !== 0 && <ErrorText>{error}</ErrorText>}
      </div>
    </StyledConfirmationStep>
  );
};

export default ConfirmationStep;

const StyledConfirmationStep = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    color: #757575;
  }

  .summary {
    min-height: 85%;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .top {
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }

    .bottom {
      display: flex;
      justify-content: flex-start;
      align-items: start;
      gap: 10px;

      .network-wrapper {
        border-radius: 4px;
        border: 1px solid #dfdfdf;
        width: 50%;
        min-height: 200px;
        height: 100%;
        padding: 20px;
      }
    }
  }

  .error-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const Label = styled.label`
  max-width: 120px !important;
  margin-right: 10px;
`;

const Value = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const ErrorText = styled(FormFieldError)`
  text-align: center;
  margin: 5px 0;
`;

const NoTags = styled.span`
  color: ${({ theme }) => theme.colors.grey[1200]};
  font-weight: bold;
  font-size: 14px;
`;

const StyledTextInput = styled(TextInput)`
  ${props =>
    props.disabled &&
    css`
      background-color: #dfdfdf;
      border: none;
    `}
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 28px;
  margin: 10px 0px;

  label {
    font-size: 20px;
    width: 50%;
  }
`;
