import styled from 'styled-components';

export const StyledTestMediaLibrary = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;

  .media-title {
    text-align: center;
    width: 138px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.grey[900]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px;
  }
`;
