export enum ActivityType {
  request = 'CAMPAIGN_REQUEST',
  activated = 'CAMPAIGN_ACTIVATED',
  process = 'CAMPAIGN_PROCESS',
  created = 'CAMPAIGN_CREATED',
  creationFailed = 'CAMPAIGN_CREATION_FAILED',
  videoUploadFailed = 'CAMPAIGN_VIDEO_UPLOAD_FAILED',
  fbCampaignCreationFailed = 'CAMPAIGN_FB_CAMPAIGN_CREATION_FAILED',
  jtCampaignCreationFailed = 'CAMPAIGN_JT_CAMPAIGN_CREATION_FAILED',
  adSetCreationFailed = 'CAMPAIGN_AD_SET_CREATION_FAILED',
  terminated = 'CAMPAIGN_TERMINATED',
  cancelled = 'CAMPAIGN_CANCELLED',
  completed = 'CAMPAIGN_COMPLETED',
  videoUpload = 'CAMPAIGN_VIDEO_UPLOAD',
  videoUploaded = 'CAMPAIGN_VIDEO_UPLOADED',
  getVideoThumbnailsRequest = 'CAMPAIGN_GET_VIDEO_THUMBNAILS_REQUEST',
  getVideoThumbnailsResponse = 'CAMPAIGN_GET_VIDEO_THUMBNAILS_RESPONSE',
  fbCampaignCreateRequest = 'CAMPAIGN_FB_CAMPAIGN_CREATE_REQUEST',
  fbCampaignCreated = 'CAMPAIGN_FB_CAMPAIGN_CREATED',
  adSetCreateRequest = 'CAMPAIGN_AD_SET_CREATE_REQUEST',
  adSetCreated = 'CAMPAIGN_AD_SET_CREATED',
  adCreativeCreateRequest = 'CAMPAIGN_AD_CREATIVE_CREATE_REQUEST',
  adCreativeCreateResponse = 'CAMPAIGN_AD_CREATIVE_CREATE_RESPONSE',
  videoReviewFailed = 'CAMPAIGN_VIDEO_REVIEW_FAILED',
  videoReviewPassed = 'CAMPAIGN_VIDEO_REVIEW_PASSED',
  scbCampaignCreateRequest = 'CAMPAIGN_UNITY_CAMPAIGN_CREATE_REQUEST',
  scbCampaignCreated = 'CAMPAIGN_UNITY_CAMPAIGN_CREATED',
  scbCampaignCreationFailed = 'CAMPAIGN_UNITY_CAMPAIGN_CREATION_FAILED',
  creativePackAssignRequest = 'CAMPAIGN_CREATIVE_PACK_ASSIGN_REQUEST',
  creativePackAssigned = 'CAMPAIGN_CREATIVE_PACK_ASSIGNED',
  creativePackAssignFailed = 'CAMPAIGN_CREATIVE_PACK_ASSIGNING_FAILED',
  scbUpdateBudgetRequest = 'CAMPAIGN_UPDATING_BUDGET_REQUEST',
  scbUpdateTargetingOptionsRequest = 'CAMPAIGN_UPDATING_TARGETING_OPTIONS_REQUEST',
  scbTargetingOptionsUpdated = 'CAMPAIGN_TARGETING_OPTIONS_UPDATED',
  scbTargetingOptionsUpdateFailed = 'CAMPAIGN_TARGETING_OPTIONS_UPDATE_FAILED',
  scbBudgetUpdated = 'CAMPAIGN_BUDGET_UPDATED',
  scbCampaignUpdateFailed = 'CAMPAIGN_BUDGET_UPDATE_FAILED',
  scbUpdateCpiBidRequest = 'CAMPAIGN_UPDATING_CPI_BID_REQUEST',
  scbCpiBidUpdated = 'CAMPAIGN_CPI_BID_UPDATED',
  scbCpiBidUpdateFailed = 'CAMPAIGN_CPI_BID_UPDATE_FAILED',
  campaignAdCreateRequest = 'CAMPAIGN_AD_CREATE_REQUEST',
  campaignAdCreateResponse = 'CAMPAIGN_AD_CREATE_RESPONSE',
  justrackCreateCampaignRequest = 'CAMPAIGN_JT_CAMPAIGN_CREATE_REQUEST',
  justrackCampaignCreated = 'CAMPAIGN_JT_CAMPAIGN_CREATED',
  campaignChangeStatus = 'CAMPAIGN_CHANGE_STATUS',
  campaignChangeStatusFailed = 'CAMPAIGN_CHANGE_STATUS_FAILED',
  campaignDescriptionAdd = 'CAMPAIGN_DESCRIPTION_ADD',
  campaignDescriptionEdit = 'CAMPAIGN_DESCRIPTION_EDIT',
  campaignDescriptionRemove = 'CAMPAIGN_DESCRIPTION_REMOVE',
}

interface JsonDetails {
  newDescription?: string;
  oldDescription?: string;
}

export interface CampaignActivity {
  type: ActivityType;
  text: string;
  date: string;
  jsonDetails?: JsonDetails;
}
