export const isImageFormatValid = (image: File, format: string[]): boolean =>
  format.includes(image.type);

export const isImageSizeValid = (image: File, size: number): boolean =>
  Math.ceil(image.size / 1024) <= size;

export const isImageDimensionsValid = (
  image: File,
  dimensions: number[]
): Promise<unknown> => {
  const [maxHeight, maxWidth] = dimensions;
  const isValid = (file: File) =>
    new Promise(resolve => {
      let reader = new FileReader();

      reader.onload = () => {
        let img = new Image();

        img.onload = () => {
          resolve(
            img.width <= maxWidth && img.height <= maxHeight ? true : false
          );
        };

        img.src = reader.result as string;
      };

      reader.readAsDataURL(file);
    });

  return isValid(image);
};
