import { TagType } from 'types';
import { Tag } from 'components';
import styled from 'styled-components';

interface TagsListProps {
  tagsList: TagType[];
  selectedTags: TagType[];
  onItemClick: (newSelectedTag: TagType) => void;
}

const TagsList = ({ tagsList, selectedTags, onItemClick }: TagsListProps) => {
  const isChecked = (tag: TagType): boolean =>
    selectedTags.some(selected => selected.id === tag.id);

  return (
    <StyledTagsList>
      {tagsList.map(tag => (
        <label className="item" key={tag.id} htmlFor={tag.name}>
          <input
            type="checkbox"
            className="checkbox"
            id={tag.name}
            onChange={() => onItemClick(tag)}
            checked={isChecked(tag)}
          />
          <Tag text={tag.name} ml={2} size="lg" />
        </label>
      ))}
    </StyledTagsList>
  );
};

export default TagsList;

const StyledTagsList = styled.div`
  list-style-type: none;
  margin: 8px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey.white};

  .item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.grey.offWhite};
    }

    .checkbox {
      cursor: inherit;
    }
  }
`;
