import styled, { useTheme } from 'styled-components';
import { useRef, useState, FC } from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';
import { Popup } from 'components/atoms/Popup';

interface VideoContainerProps {
  remove?: () => void;
  children?: React.ReactNode;
}

const StyledVideoContainer = styled.div`
  width: 160px;
  height: 250px;
`;

const VideoContainerHeader = styled.div`
  position: relative;
  height: 0;
  width: 100%;
`;

const StyledRemoveButton = styled(RiCloseCircleFill)`
  color: darkgray;
  position: absolute;
  margin-top: -16px;
  margin-left: 116px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.grey.white};
  border-radius: 50%;
  z-index: var(--z-index-video-container);

  &:hover {
    color: ${({ theme }) => theme.colors.pink[500]};
  }
`;

const PopupTitle = styled.div`
  padding: 12px;
  color: darkgray;
  font-weight: bold;
  text-align: center;
`;

const PopupContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const Button = styled.button`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  background-color: ${props => props.color};
  border-radius: 4px;
  border: 0;
  padding: 10px 24px;
  color: ${({ theme }) => theme.colors.grey.white};
`;

export const VideoContainer: FC<VideoContainerProps> = ({
  remove,
  children,
}) => {
  const theme = useTheme();
  const [showRemove, setShowRemove] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const showRemoveButton = (state: boolean) => {
    if (remove) {
      setShowRemove(state);
    }
  };

  const deleteConfirmRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <StyledVideoContainer
        onMouseOver={() => showRemoveButton(true)}
        onMouseLeave={() => showRemoveButton(false)}
      >
        <VideoContainerHeader
          onClick={() => setShowPopup(true)}
          ref={deleteConfirmRef}
        >
          {showRemove && <StyledRemoveButton size={36} />}
        </VideoContainerHeader>
        {children}
      </StyledVideoContainer>
      {remove ? (
        <div style={{ position: 'relative' }}>
          <Popup
            visible={showPopup}
            anchor={deleteConfirmRef}
            onClose={() => setShowPopup(false)}
            containerStyle={{
              borderRadius: '4px',
              padding: '20px',
              width: '200px',
              height: '100px',
              top: '40px',
              left: '-200px',
            }}
          >
            <PopupTitle>Do you want to delete this video?</PopupTitle>
            <PopupContent>
              <Button color={theme.colors.pink[500]} onClick={remove}>
                Yes
              </Button>
              <Button color={'darkgray'} onClick={() => setShowPopup(false)}>
                No
              </Button>
            </PopupContent>
          </Popup>
        </div>
      ) : null}
    </>
  );
};
