import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import {
  FetchGameResponse,
  Game,
  FetchGamesResponse,
  GameConfig,
  FetchGamesOverviewResponse,
  OverviewGamesDateRange,
  ServerQueryKey,
  editGameConfigReqBody,
  MonetizationStep,
  SetupMonetizationReqBody,
} from 'types';

const fetchOverviewGames = async (dateRange: OverviewGamesDateRange) => {
  const { data } = await axiosInstance.get(`games/overview?days=${dateRange}`);
  return data as FetchGamesOverviewResponse;
};

export const useFetchOverviewGames = (
  dateRange: OverviewGamesDateRange,
  options = {}
) => {
  return useQuery(
    [ServerQueryKey.GamesOverviewList, dateRange],
    () => fetchOverviewGames(dateRange),
    options
  );
};

// Get Games
const fetchGames = async (id: number) => {
  const {
    data: { games },
  } = await axiosInstance.get<FetchGamesResponse>(`/games/studio/${id}`);
  return games;
};

export const useFetchGames = (studioId: number) => {
  return useQuery([ServerQueryKey.GamesList, studioId], () =>
    fetchGames(studioId)
  );
};

const fetchGame = async (gameId: number): Promise<Game> => {
  const {
    data: { data },
  } = await axiosInstance.get<FetchGameResponse>(`/games/${gameId}`);
  return data;
};

export const useFetchGame = (gameId: number, options = {}) => {
  return useQuery(
    [ServerQueryKey.GameDetails, gameId],
    () => fetchGame(gameId),
    options
  );
};

const createGame = async (reqBody: any) => {
  const { data } = await axiosInstance.post('/games/create', reqBody);
  return data as any;
};

export const useCreateGame = () => {
  return useMutation((data: any) => createGame(data));
};

const updateGame = async (gameId: number, reqBody: any) => {
  const { data } = await axiosInstance.post(`/games/edit/${gameId}`, reqBody);
  return data as any;
};

export const useUpdateGame = (id: number) => {
  return useMutation((data: any) => updateGame(id, data));
};

const fetchGameConfig = async (gameId: number) => {
  const { data } = await axiosInstance.get(`/games/${gameId}/configs`);
  return data as GameConfig[];
};

export const useFetchGameConfigs = (gameId: number, options = {}) => {
  return useQuery(
    [ServerQueryKey.GameConfigs, gameId],
    () => fetchGameConfig(gameId),
    options
  );
};

const editGameConfig = async (
  gameId: number,
  reqBody: editGameConfigReqBody
) => {
  const { data } = await axiosInstance.put(`/games/${gameId}/configs`, reqBody);
  return data;
};

export const useEditGameConfig = (gameId: number) => {
  return useMutation((reqBody: editGameConfigReqBody) =>
    editGameConfig(gameId, reqBody)
  );
};

const createConfigCSV = async (gameId: number) => {
  const { data, headers } = await axiosInstance.post(
    `/games/${gameId}/config/download`
  );
  return { data, headers };
};

export const useCreateConfigCSV = (gameId: number) => {
  return useMutation(() => createConfigCSV(gameId));
};

const checkPlaystoreUrl = async (url: string) => {
  const { data } = await axiosInstance.get(`/games/playstore-data?url=${url}`);
  return data;
};

export const useCheckPlaystoreUrl = () => {
  return useMutation((url: string) => checkPlaystoreUrl(url));
};

const fetchMonetizationSteps = async (
  gameId: number,
  os: 'android' | 'ios'
) => {
  const { data } = await axiosInstance.get(
    `/games/${gameId}/monetization?os=${os}`
  );
  return data as MonetizationStep[];
};

export const useFetchMonetizationSteps = (
  gameId: number,
  os: 'android' | 'ios',
  options = {}
) => {
  return useQuery(
    [ServerQueryKey.MonetizationSteps, gameId, os],
    () => fetchMonetizationSteps(gameId, os),
    options
  );
};

const setupMonetization = async (
  gameId: number,
  reqBody: SetupMonetizationReqBody
) => {
  const { data } = await axiosInstance.post(
    `/games/${gameId}/monetization`,
    reqBody
  );
  return data;
};

export const useSetupMonetization = (gameId: number) => {
  return useMutation((reqBody: SetupMonetizationReqBody) =>
    setupMonetization(gameId, reqBody)
  );
};
