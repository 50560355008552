import { useState } from 'react';
import { PackMediaEnum, PackMediaObjType } from 'types';
import CreativePackSelector from '../CreativePackSelector/CreativePackSelector';
import CreativePackUploader from '../CreativePackUploader/CreativePackUploader';
import { StyledCreativePackInput } from './CreativePackInput.styles';

const getTitleByPackMediaType = (pactType: PackMediaEnum) => {
  switch (pactType) {
    case PackMediaEnum.image:
      return 'End card image (800x800)';
    case PackMediaEnum.portraitVideo:
      return 'Portrait video (9:16)';
    case PackMediaEnum.landscapeVideo:
      return 'Landscape video (16:9)';
  }
};

interface CreativePackInputProps {
  inputType: PackMediaEnum;
  onInputUpdate: (packMediaId: number) => void;
  packMedias: PackMediaObjType[];
}

const CreativePackInput = ({
  inputType,
  onInputUpdate,
  packMedias,
}: CreativePackInputProps) => {
  const [isPackSelected, setIsPackSelected] = useState(false);
  const [isPackUploaded, setIsPackUploaded] = useState(false);

  const handlePackSelectedOrUploaded = (type: string, packMediaId: number) => {
    switch (type) {
      case 'PACK_SELECTED':
        setIsPackUploaded(false);
        setIsPackSelected(true);
        break;
      case 'PACK_UNSELECTED':
        setIsPackUploaded(false);
        setIsPackSelected(false);
        break;
      case 'PACK_UPLOADED':
        setIsPackSelected(false);
        setIsPackUploaded(true);
        break;
      case 'PACK_UPLOAD_REMOVE':
        setIsPackUploaded(false);
        setIsPackSelected(false);
        break;
      default:
        setIsPackUploaded(false);
        setIsPackSelected(false);
    }

    onInputUpdate(packMediaId);
  };

  const title = getTitleByPackMediaType(inputType);
  const isSelectDisabled = isPackUploaded;
  const isUploadDisabled = isPackSelected;

  return (
    <StyledCreativePackInput>
      <span className="title">{title}</span>
      <div className="body">
        <CreativePackUploader
          uploadType={inputType}
          disabled={isUploadDisabled}
          onMediaChange={handlePackSelectedOrUploaded}
        />
        <div className="divider" />
        <CreativePackSelector
          disabled={isSelectDisabled}
          onMediaChange={handlePackSelectedOrUploaded}
          packMedias={packMedias}
        />
      </div>
    </StyledCreativePackInput>
  );
};

export default CreativePackInput;
