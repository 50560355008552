import { chartDataGenerator } from 'pages/Test/Charts/utils/GlobalUtilsTs';
import { AvgAdImpPerUserChartData } from 'types';
import { formatAppVersion } from '../CompareChart.utils';

type Colors = {
  adInterstitialSuccess: string;
  adRewardedSuccess: string;
  interstitialRewardedTotal: string;
};

export const lineColors: Colors = {
  adInterstitialSuccess: '#57B4F9',
  adRewardedSuccess: '#ffc043',
  interstitialRewardedTotal: '#8242fc',
};

export const comparisonColors: Colors = {
  adInterstitialSuccess: '#89CFF0',
  adRewardedSuccess: '#e6b800',
  interstitialRewardedTotal: '#b19cd9',
};

const getChartData = ({
  label,
  data,
  color,
}: {
  label: string;
  data: number[];
  color: string;
}) => {
  return {
    label,
    data,
    borderColor: color,
    pointBorderColor: color,
    pointBackgroundColor: color,
    spanGaps: true,
  };
};

export const generateCampaignDatasets = (
  data: AvgAdImpPerUserChartData,
  colors: Colors,
  campaignName: string,
  appVersion: string
) => {
  const datasets: any[] = [];
  const dayDataArray = [
    {
      data: data.adInterstitialSuccess,
      label: 'Interstitial',
      color: colors.adInterstitialSuccess,
    },
    {
      data: data.adRewardedSuccess,
      label: 'Rewarded',
      color: colors.adRewardedSuccess,
    },
    {
      data: data.interstitialRewardedTotal,
      label: 'Total',
      color: colors.interstitialRewardedTotal,
    },
  ];

  dayDataArray.forEach(({ data, label, color }) => {
    if (data) {
      let chartData = chartDataGenerator(data);

      const fullLabel = `${campaignName} - ${formatAppVersion(
        appVersion
      )} - ${label}`;
      datasets.push(getChartData({ label: fullLabel, data: chartData, color }));
    }
  });
  return datasets;
};
