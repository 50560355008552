import styled from 'styled-components';

export const NoDataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.grey[900]};
  font-family: 'Rubik', sans-serif;
`;

export const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 40px;
  margin: 10px;
  border: 2px dashed ${props => props.theme.colors.grey[1300]};
  border-radius: 4px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }

  label {
    font-size: 22px;
    color: ${props => props.theme.colors.grey[900]};
  }
`;

export const ChartContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.white};
  padding: 40px;
  margin-bottom: 36px;
`;

export const ChartInnerContainer = styled.div`
  min-height: 300px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const ChartNoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.grey.white};
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[500]};
  font-size: 32px;
`;

export const ChartInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.grey.black};
  font-family: 'Rubik', sans-serif;
  font-size: 32px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 48px;

  .title-container {
    display: flex;
    flex-direction: column;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
  font-size: 25px;
  color: ${({ theme }) => theme.colors.grey[900]};

  img {
    margin-bottom: 20px;
  }
`;

export const ChartRightInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ChartInfoValue = styled.div`
  margin-bottom: 5px;
`;

export const ChartTitle = styled.div`
  display: flex;
  align-items: center;

  .chart-title {
    text-transform: capitalize;
  }

  .tooltip {
    margin-left: 15px;
    font-size: 18px;
    color: #ccc;
  }
`;

export const ArpiFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #afafaf;
  font-size: 16px;
`;

export const InfoBanner = styled.div`
  background-color: ${({ theme }) => theme.colors.blue[800]};
  color: ${({ theme }) => theme.colors.grey.white};
  align-self: center;
  padding: 20px 58px;
  margin-top: 40px;
  border-radius: 4px;
  text-align: center;
`;

interface CohortBadgeProps {
  bgOpacity: number;
}

export const CohortBadge = styled.div<CohortBadgeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 2px);
  margin: 1px;
  border-radius: 5px;
  background-color: ${({ bgOpacity }) => `rgba(255, 192, 67, ${bgOpacity})`};
`;

export const CohortCollapseTitle = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-right: 8px;
    flex-shrink: 0;
  }
`;
export const ArpiCohortBanner = styled.div`
  margin-top: 24px;
  width: 290px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: #fff2d9;
  padding: 6px;
  border-radius: 4px;
`;
export const ArpiBannerText = styled.p`
  font-size: 10px;
  font-weight: 400;
  margin-left: 4px;
`;
