import { useRef, useState } from 'react';
import { StyledTagList } from './TagList.styles';
import { TagType } from 'types';
import { Tag } from 'components';
import { Popup } from 'components/atoms';

interface TagListProps {
  tags: Array<TagType>;
  maxVisibleTags: number;
  fontSize?: string;
  height?: string;
  smallSize?:boolean;
}

const TagList = ({ tags, maxVisibleTags,smallSize=false }: TagListProps) => {
  const tagListRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);

  const handlePopup = () => setShowPopup(!showPopup);

  const showMoreClick = (event: any) => {
    event.preventDefault();
    handlePopup();
  };

  return (
    <StyledTagList ref={tagListRef}>
      {tags.length === 0 ? (
        <span className={smallSize ? 'no-result-small' : 'no-result'}>No tags added</span>
      ) : (
        <>
          {tags?.slice(0, maxVisibleTags).map((tag: TagType) => (
            <Tag key={tag.id} text={tag.name} mr={0.6} size='lg' smallSize={smallSize}  />
          ))}
          {tags.length > maxVisibleTags && (
            <span
              className={smallSize ? "show-more-small":"show-more"}
              onClick={(e: any) => showMoreClick(e)}
            >{`+${tags.length - maxVisibleTags}`}</span>
          )}
          <span onClick={(e: React.MouseEvent) => e.preventDefault()}>
            <Popup
              anchor={tagListRef}
              visible={showPopup}
              smallFont={smallSize}
              onClose={handlePopup}
              containerStyle={{
                minWidth: 300,
                maxWidth: 300,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 10,
                top: 30,
                left: 10,
                padding: 10,
                cursor: 'default',
              }}
            >
              {tags?.slice(maxVisibleTags).map((tag: TagType) => (
                <Tag key={tag.id} text={tag.name} size="lg" smallSize={smallSize} />
              ))}
            </Popup>
          </span>
        </>
      )}
    </StyledTagList>
  );
};

export default TagList;
