import { useState, useEffect, useMemo } from 'react';
import { round } from 'lodash';
import { Line } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import { BusinessChartType, CohortRawData, CohortTableRow } from 'types';
import { DataGrid, Collapse, Checkbox } from 'components';
import { chartTitleGenerator } from '../utils/GlobalUtilsTsx';
import {
  cohortIndexArrayGenerator,
  getChartTitleAndTooltip,
} from '../utils/GlobalUtilsTs';
import {
  cohortTableRowsGenerator,
  cohortTableColumnsGenerator,
} from '../utils/CohortTableUtilsTs';
import { cohortCollapseTitle } from '../utils/CohortTableUtilsTsx';
import { initialCohortChartData } from '../utils/CohortChartUtilsTs';
import {
  ChartContainer,
  ChartInnerContainer,
  ChartInfoContainer,
  ChartRightInfo,
  ChartInfoValue,
  ArpiCohortBanner,
  ArpiBannerText,
  ArpiFilterWrapper,
} from '../Charts.styles';
import { arpiBanner } from 'assets/images';
import { Icon } from 'assets/styles';
import usePredictedRow from './hooks/usePredictedRow';
import useCPIRow from './hooks/useCpiRow';
import useArpiChartOptions from './hooks/useArpiChartOptions';
import useArpiChartData from './hooks/useArpiChartData';

const startingIdx = 0;

interface ChartProps {
  data: CohortRawData;
}

const ArpiChart = ({ data }: ChartProps) => {
  const [collapse, setCollapse] = useState(true);
  const [numOfCols, setNumOfCols] = useState(0);
  const [rows, setRows] = useState<CohortTableRow[]>([]);
  const [selectedRows, setSelectedRows] = useState<CohortTableRow[]>([]);
  const [showCpi, setShowCpi] = useState(true);
  const [chartData, setChartData] = useState<ChartData<'line'>>(
    initialCohortChartData
  );

  const showCpiHandler = () => {
    setShowCpi(!showCpi);
  };

  // For showing the average value day 29 was picked, for cases we don't have this value 0 will shown
  const chartValueDayNumber = data.average.values[29];
  const dataUnit = data.unit;
  const avgValue = round(chartValueDayNumber, 3);
  const predictionStartDayIndex = data.average.predictionStartDayIndex ?? 0;

  // hooks
  const { predictedRow, labelIdx } = usePredictedRow(
    data.average.predictedValues,
    predictionStartDayIndex,
    startingIdx
  );

  const { cpiRow, cpiChartData } = useCPIRow(
    data.cpiData,
    data.average.predictedValues,
    predictionStartDayIndex,
    startingIdx,
    numOfCols
  );

  const chartOptions = useArpiChartOptions(
    dataUnit,
    predictedRow,
    cpiChartData,
    cpiRow,
    selectedRows,
    showCpi,
    data.average.predictedValues
  );

  const { handleChartData } = useArpiChartData(
    selectedRows,
    data.average.predictedValues,
    labelIdx,
    cpiChartData,
    showCpi,
    numOfCols,
    startingIdx,
    predictedRow,
    cpiRow
  );
  useEffect(() => {
    const updatedChartData = handleChartData();
    setChartData(updatedChartData);
  }, [
    numOfCols,
    selectedRows,
    data.average.predictedValues,
    usePredictedRow,
    showCpi,
  ]);

  // Arpi Cohort Table
  useEffect(() => {
    setNumOfCols(data.average.values.length);
    const labelIdx = cohortIndexArrayGenerator(numOfCols, startingIdx);
    const rows = cohortTableRowsGenerator(data, labelIdx, 3);
    setRows(rows);
    const initialSelectedRow = [rows[0]];
    setSelectedRows(initialSelectedRow);
  }, [data, numOfCols]);

  const columns = useMemo(
    () => cohortTableColumnsGenerator(numOfCols, startingIdx, dataUnit),
    [numOfCols, dataUnit]
  );

  const { title, tooltip } = getChartTitleAndTooltip(BusinessChartType.ARPI);
  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
        <ChartRightInfo>
          {cpiChartData.length > 0 && (
            <ArpiFilterWrapper>
              <span>show CPI</span>
              <span onClick={showCpiHandler}>
                <Checkbox
                  isChecked={showCpi}
                  isDisabled={false}
                  accentColor="#EEEEEE"
                  size="sm"
                />
              </span>
            </ArpiFilterWrapper>
          )}
          {!isNaN(avgValue) ? (
            <div>
              {avgValue && (
                <ChartInfoValue>
                  LTV{` ${dataUnit}${avgValue.toFixed(3)}`}
                </ChartInfoValue>
              )}
            </div>
          ) : null}
        </ChartRightInfo>
      </ChartInfoContainer>
      <ChartInnerContainer>
        <Line options={chartOptions} data={chartData} height={400} />
      </ChartInnerContainer>
      {predictedRow.hasOwnProperty('day0') && (
        <ArpiCohortBanner>
          <Icon style={{ width: '12px', height: '12px' }} src={arpiBanner} />
          <ArpiBannerText>
            Predictions will become more accurate with more data
          </ArpiBannerText>
        </ArpiCohortBanner>
      )}
      <Collapse
        open={collapse}
        onToggle={setCollapse}
        title={cohortCollapseTitle}
        mt={5}
      >
        <DataGrid<CohortTableRow>
          rows={rows}
          columns={columns}
          selectedRows={{ 0: true }}
          onRowSelection={setSelectedRows}
          disableSelectAll
        />
      </Collapse>
    </ChartContainer>
  );
};

export default ArpiChart;
