import { BusinessChartType } from 'types';
import { ChartsContainer } from '../../../pages/Test/Charts/Charts.styles';
import {
  AdImpressionsAvgPerUserChart,
  LevelProgressionChart,
  NoChartData,
  PlayTimeChart,
  PlayTimePerAvgUser,
  SundayRetentionChart,
} from '../../../pages/Test/Charts';
import { SpinnerContainer } from 'assets/styles';
import { Spinner } from 'components/atoms';
import { useNavigate, useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import {
  useFetchAppVersions,
  useFetchCampaign,
  useFetchCompareAppVersions,
} from 'api';
import { useState } from 'react';
import StyledSelect, { Option, OptionValue } from './StyledSelect';
import {
  generateCompareVersionsUrl,
  getCompareOptions,
  getOptions,
} from './JustrackKpi.utils';
import ArpiChart from '../../../pages/Test/Charts/Arpi/ArpiChart';

const JustrackKpi = () => {
  const navigate = useNavigate();
  const params = useParams<{
    studioId: string;
    gameId: string;
    campaignId: string;
  }>();
  const [selectedAppVersion, setSelectedAppVersion] =
    useState<OptionValue>('all');
  const { data: appVersions, isSuccess: isAppVersionsSuccess } =
    useFetchAppVersions(params.gameId!, params.campaignId!);
  const {
    data: comparingAppVersions,
    isSuccess: isComparingAppVersionsSuccess,
  } = useFetchCompareAppVersions(params.gameId!, params.campaignId!);

  const {
    data: campaign,
    isSuccess: isCampaignSuccess,
    isLoading: isCampaignLoading,
    isFetching: isCampaignFetching,
  } = useFetchCampaign(
    Number(params.gameId),
    Number(params.campaignId),
    {},
    selectedAppVersion
  );

  const handleAppVersionChange = (option: SingleValue<Option>) =>
    setSelectedAppVersion(option?.value);

  const handleCompareAppVersionChange = (option: SingleValue<Option>) => {
    const [campaignId2, campaignName2, appVersion2] = String(
      option?.value
    ).split(',');
    const redirectUrl = generateCompareVersionsUrl({
      studioId: params.studioId!,
      gameId: params.gameId!,
      campaignId1: params.campaignId!,
      campaignId2: campaignId2,
      campaignName1: campaign?.name!,
      campaignName2: campaignName2!,
      appVersion1: String(selectedAppVersion),
      appVersion2: appVersion2!,
    });
    navigate(redirectUrl);
  };

  if (isCampaignLoading || isCampaignFetching) {
    return (
      <ChartsContainer style={{ minHeight: 200 }}>
        <SpinnerContainer>
          <Spinner size={48} color="#bebebe" />
        </SpinnerContainer>
      </ChartsContainer>
    );
  }

  if (isCampaignSuccess && isAppVersionsSuccess) {
    const retentionData = campaign.kpis?.sevenDayRetention;
    const retentionBenchmarks =
      campaign.kpis?.benchmarks?.sevenDayRetention ?? undefined;
    const playTimePerAvgUser = campaign.kpis?.cohortAveragePlaytimePerUser;
    const playTimePerAvgUserCumulative =
      campaign.kpis?.cohortAveragePlaytimePerUserCumulative;

    const arpiData = campaign.kpis?.arpiCohort;
    const cpiData = campaign.kpis?.adSetTestKpis?.weightedCpi;

    const appVersionsOptions = getOptions(appVersions);
    const comparingAppVersionsOptions = isComparingAppVersionsSuccess
      ? getCompareOptions(comparingAppVersions)
      : [];

    return (
      <ChartsContainer>
        <div className="top">
          {isAppVersionsSuccess && appVersionsOptions.length > 1 ? (
            <div className="dropdown-wrapper">
              <label>App Version:</label>
              <StyledSelect
                options={appVersionsOptions}
                selectedOption={selectedAppVersion}
                onChange={handleAppVersionChange}
              />
            </div>
          ) : null}
          {comparingAppVersionsOptions.length > 0 && (
            <div className="dropdown-wrapper">
              <label>Compare App Version:</label>
              <StyledSelect
                options={comparingAppVersionsOptions}
                selectedOption={undefined}
                onChange={handleCompareAppVersionChange}
              />
            </div>
          )}
        </div>

        <PlayTimeChart campaign={campaign} />
        {playTimePerAvgUser && playTimePerAvgUserCumulative ? (
          <PlayTimePerAvgUser
            data={playTimePerAvgUser}
            cumulativeData={playTimePerAvgUserCumulative}
          />
        ) : (
          <NoChartData chartType={BusinessChartType.PLAYTIME_PER_AVG_USER} />
        )}
        {retentionData ? (
          <SundayRetentionChart
            data={retentionData}
            benchmarks={retentionBenchmarks}
          />
        ) : (
          <NoChartData chartType={BusinessChartType.SUNDAY_RETENTION} />
        )}
        {arpiData ? (
          <ArpiChart data={{ ...arpiData, cpiData }} />
        ) : (
          <NoChartData chartType={BusinessChartType.ARPI} />
        )}
        <AdImpressionsAvgPerUserChart campaign={campaign} />
        <LevelProgressionChart
          levelData={campaign.kpis?.levelProgression}
          churnData={campaign.kpis?.churnRatePerLevel}
          appVersion={selectedAppVersion}
        />
      </ChartsContainer>
    );
  }
  return null;
};

export default JustrackKpi;
