import { BusinessChartType } from 'types';
import {
  ChartContainer,
  ChartInfoContainer,
  ChartInnerContainer,
  ChartNoDataContainer,
} from './Charts.styles';
import { getChartTitleAndTooltip } from './utils/GlobalUtilsTs';
import { chartTitleGenerator } from './utils/GlobalUtilsTsx';

interface Props {
  chartType: BusinessChartType;
  message?: string;
}

const NoChartData: React.FC<Props> = ({ chartType, message = 'No Data' }) => {
  const { title, tooltip } = getChartTitleAndTooltip(chartType);
  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
      </ChartInfoContainer>
      <ChartInnerContainer>
        <ChartNoDataContainer>{message}</ChartNoDataContainer>
      </ChartInnerContainer>
    </ChartContainer>
  );
};

export default NoChartData;
