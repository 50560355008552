import styled, { css } from 'styled-components';

interface Props {
  active?: boolean;
}

export const Tab = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 16px 32px;
  color: ${props =>
    props.active ? props.theme.colors.pink[500] : props.theme.colors.grey[900]};
  cursor: ${(props: Props) => (props.active ? 'default' : 'pointer')};

  ${(props: Props) =>
    props.active &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.pink[500]};
      }
    `};
`;
