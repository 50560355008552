import {
  useGetNavigationCampaigns,
  useFetchUser,
  useFetchCampaigns,
  useFetchGame,
  useFetchStudio,
} from 'api';
import ActionButton from 'components/ActionButton/ActionButton';
import { SearchInput } from 'components/molecules';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonContainer } from 'assets/styles';
import SidebarHeader from './SidebarHeader';
import SidebarNav from './SidebarNav';
import {
  AddCampaignModal,
  AddGameModal,
  CongratulationsModal,
  PlaystoreMissingModal,
} from 'components';
import { hasPermission } from 'utils/user';
import { AccessFeatures } from 'types';

const CampaignSidebar = () => {
  const params = useParams<{ studioId: string; gameId: string }>();
  const gameId = Number(params.gameId);
  const studioId = Number(params.studioId);
  const { data: user } = useFetchUser();
  const { data: campaigns } = useFetchCampaigns(gameId, {
    enabled: !isNaN(gameId),
  });

  const { data: game } = useFetchGame(gameId, {
    enabled: !isNaN(gameId),
  });

  const { data: studio } = useFetchStudio(studioId, {
    enabled: !isNaN(studioId),
  });

  const { data, isLoading } = useGetNavigationCampaigns();
  const [searchText, setSearchText] = useState<string>('');
  const [showAddGameModal, setShowAddGameModal] = useState(false);
  const [showAddCampaignModal, setShowAddCampaignModal] = useState(false);
  const [showPlaystoreModal, setShowPlaystoreModal] = useState(false);
  const [showCongratulationsModal, setShowCongratulationsModal] =
    useState(false);

  const updateSearchText = (e: any) => {
    setSearchText(e.target.value);
  };

  const handleShowAddCampaignModal = (isSubmitted: boolean) => {
    setShowAddCampaignModal(false);

    if (isSubmitted && !user?.isPublisher && campaigns?.length === 0)
      setShowCongratulationsModal(true);
  };

  const handleAddCampaignClick = () => {
    if (game?.playMarketUrl) setShowAddCampaignModal(true);
    else {
      setShowPlaystoreModal(true);
    }
  };

  const hasAddCampaignAccess = hasPermission(
    user,
    AccessFeatures.CREATE_CAMPAIGN
  );

  return (
    <>
      <SidebarHeader
        type="campaign"
        gameData={data.game}
        studiodata={data.studio}
      />
      <SearchInput
        sidenav={true}
        onChange={updateSearchText}
        placeholder={'Search Test'}
      />
      <SidebarNav
        type="campaign"
        data={data.campaigns.filter((campaign: any) =>
          campaign.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
        )}
        loading={isLoading}
      />
      {hasAddCampaignAccess && (
        <ButtonContainer>
          <ActionButton
            disabled={game?.isArchived || studio?.archived}
            onClick={handleAddCampaignClick}
            text={'+ Add Test'}
          />
        </ButtonContainer>
      )}
      {showAddCampaignModal && (
        <AddCampaignModal
          isOpen={showAddCampaignModal}
          onClose={handleShowAddCampaignModal}
        />
      )}
      {showCongratulationsModal && (
        <CongratulationsModal
          isOpen={showCongratulationsModal}
          onClose={() => setShowCongratulationsModal(false)}
        />
      )}
      {showPlaystoreModal && (
        <PlaystoreMissingModal
          isOpen={showPlaystoreModal}
          onClose={() => setShowPlaystoreModal(false)}
          onSuccess={() => {
            setShowPlaystoreModal(false);
            setShowAddGameModal(true);
          }}
        />
      )}
      {showAddGameModal && (
        <AddGameModal
          isOpen={showAddGameModal}
          onClose={() => setShowAddGameModal(false)}
          isCreateMode={false}
        />
      )}
    </>
  );
};

export default CampaignSidebar;
