import styled from 'styled-components';

export const StyledCreativePackInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 366px;

  .title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .body {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    border-radius: 10px;

    .divider {
      border-top: 1px dashed #979797;
    }
  }
`;
