import styled from 'styled-components';
export const Section = styled.section`
  width: 49%;
  display: flex;
  flex-direction: column;
`;

export const SectionHeader = styled.header`
  height: 50px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading = styled.span`
  color: ${({ theme }) => theme.colors.grey[900]};
  font-size: 16px;
  font-weight: bold;
`;

export const SectionContent = styled.div`
  flex-grow: 1;
  margin-bottom: 5px;
  overflow-y: auto;

  > * {
    margin-bottom: 10px;
  }
`;

export const NoResult = styled.span`
  color: ${({ theme }) => theme.colors.grey[1200]};
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`;
