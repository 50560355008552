import { axiosInstance } from 'api';
import { BenchmarkLevels } from 'types';

interface FetchMediaFileProps {
  gameId: number;
  sortBy?: 'ipm' | 'date';
}

interface DeleteMediaProps extends FetchMediaFileProps {
  mediaId: number;
}

type MediaTypes = 'video';

interface RawMediaFile {
  id: number;
  type: MediaTypes;
  url: string;
  thumbnail: string;
  ipm: IPM;
  game_id: number;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
}

export type IPM = {
  value: number;
  benchmark: BenchmarkLevels;
} | null;

export interface VideoFile {
  id: number;
  name: string;
  url: string;
  thumbnail: string;
  gameId: number;
  ipm: IPM;
}

interface UploadMediaFileProps extends FetchMediaFileProps {
  file: File;
  onProgress?: (percentage: number) => void;
}

export interface MediaFilesByDate {
  today?: VideoFile[];
  'last 7 days'?: VideoFile[];
  'last 30 days'?: VideoFile[];
  old?: VideoFile[];
}

interface RawMediaFilesByDate {
  today?: RawMediaFile[];
  'last 7 days'?: RawMediaFile[];
  'last 30 days'?: RawMediaFile[];
  old?: RawMediaFile[];
}

interface FetchMediaFilesResponse {
  data: RawMediaFilesByDate | RawMediaFile[];
  status: boolean;
}

export const uploadMediaFileRequest = ({
  gameId,
  file,
  onProgress,
}: UploadMediaFileProps): Promise<void> => {
  const data = new FormData();
  data.append('media', file);
  data.append('game_id', gameId.toString());
  return axiosInstance
    .post('/games/upload-media', data, {
      onUploadProgress: progressEvent => {
        if (onProgress) {
          onProgress((progressEvent.loaded / progressEvent.total) * 100);
        }
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(e => {
      console.log('ERROR', e);
      return Promise.reject();
    });
};

export const fetchMediaFilesRequest = ({
  gameId,
  sortBy = 'date',
}: FetchMediaFileProps): Promise<any> => {
  const getMedia = (rawMediaArr: RawMediaFile[]): VideoFile[] => {
    return rawMediaArr.map((rawMedia: RawMediaFile) => {
      return {
        id: rawMedia.id,
        url: rawMedia.url,
        name: rawMedia.url.split('/').pop() ?? rawMedia.url,
        gameId: rawMedia.game_id,
        thumbnail: rawMedia.thumbnail,
        ipm: rawMedia.ipm,
      };
    });
  };
  return axiosInstance
    .get<FetchMediaFilesResponse>(
      `/games/${gameId}/medias?sortBy=${sortBy === 'ipm' ? 'ipm' : ''}`
    )
    .then(response => {
      if (sortBy === 'ipm') {
        const rawMediaFiles: RawMediaFile[] = response.data
          .data as RawMediaFile[];
        const mediaFiles: VideoFile[] = getMedia(rawMediaFiles);
        return Promise.resolve(mediaFiles);
      }
      const mediaByDate: RawMediaFilesByDate = response.data
        .data as RawMediaFilesByDate;

      const files: MediaFilesByDate = {
        today: !!mediaByDate.today?.length ? getMedia(mediaByDate.today) : [],
        'last 7 days': !!mediaByDate['last 7 days']?.length
          ? getMedia(mediaByDate['last 7 days'])
          : [],
        'last 30 days': !!mediaByDate['last 30 days']?.length
          ? getMedia(mediaByDate['last 30 days'])
          : [],
        old: !!mediaByDate.old ? getMedia(mediaByDate.old) : [],
      };
      // todo: fix (any type)
      return Promise.resolve(files as any);
    })
    .catch(e => {
      console.log('ERROR', e);
      return Promise.reject();
    });
};

export const deleteMediaFileRequest = ({
  gameId,
  mediaId,
}: DeleteMediaProps): Promise<void> => {
  return axiosInstance
    .delete<FetchMediaFilesResponse>(`/games/${gameId}/medias/${mediaId}`)
    .then(() => {
      return Promise.resolve();
    })
    .catch(e => {
      console.log('ERROR', e);
      return Promise.reject();
    });
};
