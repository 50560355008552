import { useEffect, useMemo, useState } from 'react';
import { BusinessChartType, CohortRawData, CohortTableRow } from 'types';
import { ChartData } from 'chart.js';
import {
  cohortIndexArrayGenerator,
  getChartTitleAndTooltip,
} from '../utils/GlobalUtilsTs';
import {
  cohortTableColumnsGenerator,
  cohortTableRowsGenerator,
} from '../utils/CohortTableUtilsTs';
import {
  cohortChartDataGenerator,
  initialCohortChartData,
} from '../utils/CohortChartUtilsTs';
import { round } from 'lodash';
import {
  ChartContainer,
  ChartInfoContainer,
  ChartInfoValue,
  ChartInnerContainer,
  ChartRightInfo,
} from '../Charts.styles';
import { chartTitleGenerator } from '../utils/GlobalUtilsTsx';
import { ButtonGroup } from 'assets/styles';
import { Button } from 'components/atoms';
import { Line } from 'react-chartjs-2';
import { Collapse, DataGrid } from 'components';
import { cohortCollapseTitle } from '../utils/CohortTableUtilsTsx';
import { getPredictedRowAndLabelIdx } from './utils';
import usePredictedRow from '../Arpi/hooks/usePredictedRow';
import useChartOptions from './useChartOptions';

const dataUnit = 's';
const startingIdx = 0;

interface Props {
  data: CohortRawData;
  cumulativeData: CohortRawData;
}

const PlayTimePerAvgUser: React.FC<Props> = ({ data, cumulativeData }) => {
  const [collapse, setCollapse] = useState(true);
  const [tab, setTab] = useState<'average' | 'cumulative'>('cumulative');
  const [numOfCols, setNumOfCols] = useState(0);
  const [rows, setRows] = useState<CohortTableRow[]>([]);
  const [selectedRows, setSelectedRows] = useState<CohortTableRow[]>([]);
  const [chartData, setChartData] = useState<ChartData<'line'>>(
    initialCohortChartData
  );

  const avgValue = round(data.average.values[0], 1);
  const predictionStartDayIndex =
    cumulativeData.average.predictionStartDayIndex ?? 0;

  const { predictedRow } = usePredictedRow(
    cumulativeData.average.predictedValues,
    predictionStartDayIndex,
    startingIdx
  );

  useEffect(() => {
    const selectedData = tab === 'average' ? data : cumulativeData;

    // number of columns
    setNumOfCols(selectedData.average.values.length);

    // Table Rows
    const labelIdx = cohortIndexArrayGenerator(numOfCols, startingIdx);
    const rows = cohortTableRowsGenerator(selectedData, labelIdx);
    setRows(rows);

    const initialSelectedRow = [rows[0]];
    setSelectedRows(initialSelectedRow);
  }, [data, cumulativeData, tab, numOfCols]);

  useEffect(() => {
    let chartData;
    if (tab === 'cumulative') {
      const predictedExists = cumulativeData.average.predictedValues;
      const avgSelected = !!selectedRows.find(
        (row: CohortTableRow) => row.installDate === 'Average'
      );
      if (predictedExists && avgSelected) {
        const { predictedRow, labelIdx } = getPredictedRowAndLabelIdx(
          cumulativeData.average.predictedValues,
          cumulativeData.average.predictionStartDayIndex!,
          startingIdx
        );

        const newRows = [
          selectedRows[0],
          predictedRow,
          ...selectedRows.slice(1),
        ];

        chartData = cohortChartDataGenerator(newRows, labelIdx);
      } else {
        const labelIdx = cohortIndexArrayGenerator(numOfCols, startingIdx);
        chartData = cohortChartDataGenerator(selectedRows, labelIdx);
      }
    } else {
      const labelIdx = cohortIndexArrayGenerator(numOfCols, startingIdx);
      chartData = cohortChartDataGenerator(selectedRows, labelIdx);
    }
    setChartData(chartData);
  }, [selectedRows, numOfCols]);

  const chartOptions = useChartOptions(
    selectedRows,
    tab,
    predictedRow,
    dataUnit
  );

  const columns = useMemo(
    () => cohortTableColumnsGenerator(numOfCols, startingIdx, dataUnit),
    [numOfCols]
  );

  const { title, tooltip } = getChartTitleAndTooltip(
    BusinessChartType.PLAYTIME_PER_AVG_USER
  );

  return (
    <ChartContainer>
      <ChartInfoContainer>
        <div className="title-container">
          {chartTitleGenerator(title, tooltip)}
          <ButtonGroup>
            <Button
              primary={tab === 'cumulative'}
              onClick={() => setTab('cumulative')}
            >
              CUMULATIVE
            </Button>
            <Button
              primary={tab === 'average'}
              onClick={() => setTab('average')}
            >
              AVERAGE
            </Button>
          </ButtonGroup>
        </div>
        <ChartRightInfo>
          {!isNaN(avgValue) ? (
            <ChartInfoValue>{`${avgValue} ${dataUnit}`}</ChartInfoValue>
          ) : null}
        </ChartRightInfo>
      </ChartInfoContainer>
      <ChartInnerContainer>
        <Line options={chartOptions} data={chartData} height={400} />
      </ChartInnerContainer>
      <Collapse
        open={collapse}
        onToggle={setCollapse}
        title={cohortCollapseTitle}
        mt={5}
      >
        <DataGrid<CohortTableRow>
          rows={rows}
          columns={columns}
          selectedRows={{ 0: true }}
          onRowSelection={setSelectedRows}
          disableSelectAll
        />
      </Collapse>
    </ChartContainer>
  );
};

export default PlayTimePerAvgUser;
