import { useState } from 'react';
import {
  AddGameButton,
  GameCounter,
  GamesOverviewHeader,
  GamesOverviewWrapper,
} from 'features/GamesOverview/GamesOverview.styles';
import OverviewList from 'components/OverviewList/OverviewList';
import { AndroidIcon, AppleIcon, Button } from 'components/atoms';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  useFetchCampaigns,
  campaignLinkGenerator,
  useFetchUser,
  useFetchGame,
  useFetchStudio,
} from 'api';
import { AccessFeatures, CampaignDetailsOverview, CampaignOs } from 'types';
import {
  AddCampaignModal,
  AddGameModal,
  CampaignDescription,
  CampaignStatusBadge,
  CongratulationsModal,
  OverviewSkeleton,
  PlaystoreMissingModal,
} from 'components';
import { hasPermission } from 'utils/user';
import Tootltip from 'components/atoms/Tooltip/Tootltip';
import { Icon } from 'assets/styles';
import {
  brazil,
  facebook,
  monetization,
  unity,
  usa,
  philippines,
} from 'assets/images';

const TestOsIcon = ({ os }: { os?: CampaignOs }) => {
  switch (os) {
    case 'android':
      return <AndroidIcon $active={true} />;
    case 'ios':
      return <AppleIcon $active={true} />;
    default:
      return null;
  }
};

export const TestsOverview = () => {
  const theme = useTheme();
  const params = useParams<{
    studioId: string;
    gameId: string;
  }>();
  const studioId = Number(params.studioId);
  const gameId = Number(params.gameId);
  const { data: game } = useFetchGame(gameId);
  const { data: studio } = useFetchStudio(studioId);
  const useFetchCampaignsQuery = useFetchCampaigns(gameId);
  const { data: user } = useFetchUser();
  const [showAddGameModal, setShowAddGameModal] = useState(false);
  const [showAddCampaignModal, setShowAddCampaignModal] = useState(false);
  const [showPlaystoreModal, setShowPlaystoreModal] = useState(false);
  const [showCongratulationsModal, setShowCongratulationsModal] =
    useState(false);

  if (useFetchCampaignsQuery.isLoading) {
    return (
      <GamesOverviewWrapper>
        <OverviewSkeleton />
      </GamesOverviewWrapper>
    );
  }

  if (useFetchCampaignsQuery.isSuccess) {
    const { data: campaigns, refetch: refetchCampaigns } =
      useFetchCampaignsQuery;
    const rows = campaigns.map((campaign: CampaignDetailsOverview) => ({
      id: campaign.id,
      link: campaignLinkGenerator({
        studioId,
        gameId,
        campaignId: campaign.id,
      }),
      rows: [
        {
          columnId: 'name',
          content: (
            <NameWithIcon>
              <NameContainer title={campaign.name}>
                {campaign.name}
              </NameContainer>
              {campaign.description !== '' ? (
                <CampaignDescription
                  gameId={gameId}
                  campaignId={campaign.id}
                  description={campaign.description}
                  onSuccess={refetchCampaigns}
                />
              ) : null}
              <TestOsIcon os={campaign.os} />
            </NameWithIcon>
          ),
          sortableContent: campaign.name,
          sortCampaignName: true,
        },
        {
          columnId: 'impressions',
          content: campaign.kpis?.impressions
            ? `${campaign.kpis?.impressions.toLocaleString('en-US')}`
            : '-',
          sortableContent: campaign.kpis?.impressions
            ? campaign.kpis?.impressions
            : 0,
        },
        {
          columnId: 'd1',
          content: !!campaign.kpis?.dayOneRetention
            ? `${campaign.kpis.dayOneRetention.toFixed(2)}%`
            : '-',
          sortableContent: !!campaign.kpis?.dayOneRetention
            ? campaign.kpis?.dayOneRetention
            : 0,
        },
        {
          columnId: 'playtime',
          content: !!campaign.kpis?.dayOnePlayTime
            ? campaign.kpis?.dayOnePlayTime.toFixed(2)
            : '-',
          sortableContent: !!campaign.kpis?.dayOnePlayTime
            ? campaign.kpis?.dayOnePlayTime
            : 0,
        },
        {
          columnId: 'ltv',
          content: !!campaign.kpis?.ltv
            ? `$${campaign.kpis?.ltv.toFixed(2)}`
            : '-',
          sortableContent: !!campaign.kpis?.ltv ? campaign.kpis?.ltv : 0,
        },
        {
          columnId: 'cpc',
          content: campaign.kpis?.cpc
            ? `$${campaign.kpis?.cpc.toFixed(2)}`
            : '-',
          sortableContent: campaign.kpis?.cpc ? campaign.kpis?.cpc : 0,
        },
        {
          columnId: 'cpi',
          content: campaign.kpis?.cpi
            ? `$${campaign.kpis?.cpi.toFixed(2)}`
            : '-',
          sortableContent: campaign.kpis?.cpi ? campaign.kpis?.cpi : 0,
        },
        {
          columnId: 'ctr',
          content: campaign.kpis?.ctr
            ? `${campaign.kpis.ctr.toFixed(2)}%`
            : '-',
          sortableContent: !!campaign.kpis?.ctr ? campaign.kpis.ctr : 0,
        },

        {
          columnId: 'start',
          content: campaign.activeFrom
            ? moment(campaign.activeFrom).format('DD.MM.YYYY')
            : '-',
          sortableContent: campaign.activeFrom
            ? moment(campaign.activeFrom).unix().toString()
            : '',
        },
        {
          columnId: 'end',
          content: campaign.activeTo
            ? moment(campaign.activeTo).format('DD.MM.YYYY')
            : '-',
          sortableContent: campaign.activeTo
            ? moment(campaign.activeTo).unix().toString()
            : '',
        },
        {
          columnId: 'testingStatus',
          content: (
            <>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <CampaignStatusBadge
                  campaignStatus={campaign.status}
                  size="md"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  marginTop: '20px',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '5px',
                }}
              >
                {campaign.isMonetized && (
                  <Tootltip
                    title={`Monetized Test`}
                    placement="top"
                    size="x-sm"
                    textAlign="center"
                    titleStyle={{ fontSize: '12px' }}
                  >
                    <Icon style={{ minWidth: '32px' }} src={monetization} />
                  </Tootltip>
                )}
                <Tootltip
                  title={`Testing on ${campaign.platform}`}
                  placement="top"
                  size="xx-sm"
                  textAlign="center"
                  titleStyle={{ fontSize: '12px' }}
                >
                  <Icon
                    style={{ minWidth: '32px' }}
                    src={campaign.platform === 'facebook' ? facebook : unity}
                  />
                </Tootltip>
                <Tootltip
                  title={`Testing in ${campaign.countryDetails.name}`}
                  placement="left"
                  size="xx-sm"
                  textAlign="center"
                  titleStyle={{ fontSize: '12px' }}
                >
                  <Icon
                    style={{
                      marginTop: '4px',
                      marginLeft: '5px',
                      width: '24px',
                      height: '24px',
                      borderRadius: '7px',
                      minWidth: '24px',
                    }}
                    src={
                      campaign.countryDetails.name === 'USA'
                        ? usa
                        : campaign.countryDetails.name === 'Brazil'
                        ? brazil
                        : campaign.countryDetails.name === 'Philippines'
                        ? philippines
                        : campaign.countryDetails.flag
                    }
                  />
                </Tootltip>
              </div>
            </>
          ),
        },
      ],
    }));

    const handleShowAddCampaignModal = (isSubmitted: boolean) => {
      setShowAddCampaignModal(false);

      if (isSubmitted && !user?.isPublisher && campaigns?.length === 0)
        setShowCongratulationsModal(true);
    };

    const hasAddCampaignAccess = hasPermission(
      user,
      AccessFeatures.CREATE_CAMPAIGN
    );

    const handleAddCampaignClick = () => {
      if (game?.playMarketUrl) setShowAddCampaignModal(true);
      else {
        setShowPlaystoreModal(true);
      }
    };

    const noData = !!!campaigns.length;
    return (
      <>
        {noData ? (
          <ContentContainer>
            <div
              style={{
                fontSize: '24px',
                color: theme.colors.grey[900],
                padding: '0 0 40px 0',
              }}
            >
              There are no tests running right now
            </div>
            {hasAddCampaignAccess && (
              <Button
                disabled={game?.isArchived || studio?.archived}
                onClick={handleAddCampaignClick}
              >
                + New Test
              </Button>
            )}
          </ContentContainer>
        ) : (
          <GamesOverviewWrapper>
            <GamesOverviewHeader>
              <GameCounter>
                {campaigns.length} Test{campaigns.length !== 1 ? 's' : null}
              </GameCounter>
              {hasAddCampaignAccess && (
                <AddGameButton
                  disabled={game?.isArchived || studio?.archived}
                  onClick={handleAddCampaignClick}
                >
                  + New Test
                </AddGameButton>
              )}
            </GamesOverviewHeader>
            <OverviewList
              columns={[
                { id: 'name', name: 'Name', width: 190, sortable: true },
                {
                  id: 'impressions',
                  name: 'Imp.',
                  width: 90,
                  sortable: true,
                },
                { id: 'd1', name: 'D1 Ret.', width: 90, sortable: true },
                {
                  id: 'playtime',
                  name: 'D1 PT',
                  width: 90,
                  sortable: true,
                },
                { id: 'ltv', name: 'LTV', width: 90, sortable: true },
                { id: 'cpc', name: 'CPC', width: 90, sortable: true },
                { id: 'cpi', name: 'CPI', width: 90, sortable: true },
                { id: 'ctr', name: 'CTR', width: 90, sortable: true },
                { id: 'start', name: 'Start', width: 110, sortable: true },
                { id: 'end', name: 'End', width: 110, sortable: true },
                { id: 'testingStatus', name: '', width: 30 },
              ]}
              blocks={[[...rows]]}
              overflowX
            />
          </GamesOverviewWrapper>
        )}
        {showAddCampaignModal && (
          <AddCampaignModal
            isOpen={showAddCampaignModal}
            onClose={handleShowAddCampaignModal}
          />
        )}
        {showCongratulationsModal && (
          <CongratulationsModal
            isOpen={showCongratulationsModal}
            onClose={() => setShowCongratulationsModal(false)}
          />
        )}
        {showPlaystoreModal && (
          <PlaystoreMissingModal
            isOpen={showPlaystoreModal}
            onClose={() => setShowPlaystoreModal(false)}
            onSuccess={() => {
              setShowPlaystoreModal(false);
              setShowAddGameModal(true);
            }}
          />
        )}
        {showAddGameModal && (
          <AddGameModal
            isOpen={showAddGameModal}
            onClose={() => setShowAddGameModal(false)}
            isCreateMode={false}
          />
        )}
      </>
    );
  }

  return null;
};

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NameWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NameContainer = styled.div`
  padding-right: 5px;
`;

export default TestsOverview;
