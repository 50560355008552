import { FacebookParameters, SelectOption, UnityParameters } from 'types';

export const getDefaultCountryOption = (
  parameters: FacebookParameters | UnityParameters | null,
  countryOptions: SelectOption[]
) => {
  if (parameters) {
    const country = countryOptions.find(
      country => country.value === parameters?.country
    );
    return {
      label: country?.label as string,
      value: country?.value as string,
    };
  }
  return null;
};
