import { spacing, Spacing } from 'assets/styles';
import styled from 'styled-components';

interface StyledLogoProps extends Spacing {
  size: number;
  borderRadius: number;
}

export const StyledLogo = styled.div<StyledLogoProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(246, 246, 246);
  flex-shrink: 0;
  ${spacing};

  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  border-radius: ${({ borderRadius }) => borderRadius + 'px'};

  .name {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.blue[800]};
    color: ${({ theme }) => theme.colors.grey.white};
    font-size: ${({ size }) => `calc(${size}px * 0.6)`};
    border-radius: ${({ borderRadius }) => borderRadius + 'px'};
  }

  .image {
    max-height: 100%;
    border-radius: ${({ borderRadius }) => borderRadius + 'px'};
  }
`;
