import { Skeleton } from 'components';
import {
  Card,
  CardContent,
  CardHeader,
  CardHeaderInfo,
  CardRow,
  CardRowLeft,
  CardRowRight,
} from './PublishingCard.styles';

const PublishingCardSkeleton = () => {
  return (
    <Card>
      <CardHeader to={''} target="_blank" rel="noopener noreferrer">
        <Skeleton style={{ borderRadius: '50%' }} width="48px" height="48px" />
        <CardHeaderInfo style={{ gap: 5 }}>
          <Skeleton width="200px" height="24px" />
          <Skeleton width="180px" height="16px" />
        </CardHeaderInfo>
      </CardHeader>
      <CardContent>
        {new Array(3).fill(0).map((_, idx) => (
          <CardRowSkeleton key={idx} />
        ))}
      </CardContent>
    </Card>
  );
};

const CardRowSkeleton = () => (
  <CardRow>
    <CardRowLeft to={``} target="_blank">
      <Skeleton style={{ borderRadius: '4px' }} width="48px" height="48px" />
      <div className="card-block">
        <Skeleton width="200px" height="20px" />
        <Skeleton width="180px" height="14px" />
      </div>
      <div className="card-block">
        <Skeleton width="80px" height="24px" />
        <Skeleton width="80px" height="16px" />
      </div>
      <div className="card-block">
        <Skeleton width="80px" height="24px" />
        <Skeleton width="80px" height="16px" />
      </div>
    </CardRowLeft>
    <CardRowRight>
      {new Array(4).fill(0).map((_, idx) => (
        <Skeleton
          key={idx}
          width="32px"
          height="48px"
          style={{ marginLeft: 12 }}
        />
      ))}
    </CardRowRight>
  </CardRow>
);

export default PublishingCardSkeleton;
