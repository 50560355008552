import 'react-image-gallery/styles/css/image-gallery.css';
import { css } from 'styled-components';

// Customization
const gallery = css`
  .gallery-modal {
    width: 50%;
  }

  .gallery-modal .image-gallery-slide .image-gallery-image {
    /* width: auto; */
    height: 500px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.grey.white};
  }

  .gallery-modal .image-gallery-thumbnails {
    padding: 10px 0;
  }

  .gallery-modal .image-gallery-thumbnail {
    width: auto;
    border: 2px solid transparent;
    border-radius: 6px;
    padding: 2px;
  }

  .gallery-modal .image-gallery-thumbnail:hover,
  .gallery-modal .image-gallery-thumbnail.active {
    border: 2px solid ${({ theme }) => theme.colors.blue[500]};
  }

  .gallery-modal .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 6px;
  }

  .gallery-modal .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 6px;
  }

  .gallery-modal .image-gallery-icon {
    color: ${({ theme }) => theme.colors.grey.black};
    background-color: #f2f2f2;
    filter: none;
    padding: 20px 5px;
  }

  .gallery-modal .image-gallery-icon.image-gallery-left-nav {
    border-radius: 10px 0 0 10px;
  }

  .gallery-modal .image-gallery-icon.image-gallery-right-nav {
    border-radius: 0 10px 10px 0;
  }

  .gallery-modal .image-gallery-icon:hover,
  .gallery-modal .image-gallery-icon:focus {
    color: ${({ theme }) => theme.colors.blue[500]};
    outline: none;
  }

  .gallery-modal .image-gallery-icon .image-gallery-svg {
    height: auto;
    width: 30px;
  }

  .gallery-modal .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1);
  }
`;

export default gallery;
