import { useFetchOverviewGames } from 'api';
import { FetchGamesOverviewResponse, OverviewGamesDateRange } from 'types';
import { Wrapper } from './PublishingOverview.styles';
import { PublishingSection } from './PublishingSection';

interface PublishingOverviewProps {
  dateRange?: OverviewGamesDateRange;
}

const PublishingOverview = ({ dateRange }: PublishingOverviewProps) => {
  const { data, isFetching, isLoading } = useFetchOverviewGames(dateRange!, {
    enabled: !!dateRange,
  });
  const loading = isFetching || isLoading;
  const publishingData: FetchGamesOverviewResponse = data ?? {
    publishingManagers: [],
    studios: [],
  };

  return (
    <Wrapper>
      <PublishingSection
        headerText={'Publishing managers'}
        placeholderText={'Filter by manager'}
        data={publishingData?.publishingManagers}
        loading={loading}
        cardClickable={false}
      />
      <PublishingSection
        headerText={'Studios'}
        placeholderText={'Filter by studio'}
        data={publishingData?.studios}
        loading={loading}
        cardClickable={true}
      />
    </Wrapper>
  );
};

export default PublishingOverview;
