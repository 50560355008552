import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Card = styled.article`
  min-height: 420px;
  background-color: ${({ theme }) => theme.colors.grey.white};
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
`;

export const CardHeader = styled(Link)`
  background-color: #f5f5f7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;

  &.disable-link {
    pointer-events: none;
  }
`;

export const CardHeaderInfo = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 12px;
`;

export const CardTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const CardCount = styled.span`
  font-size: 14px;
`;

export const CardContent = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
`;

export const CardRow = styled.li`
  position: relative;
  display: flex;
  padding: 16px;
  cursor: pointer;
  height: 100px;

  ::after {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: calc(100% - 24px);
    left: 12px;
    background-color: #f5f5f7;
    content: '';
  }
`;

export const CardRowLeft = styled(Link)`
  flex-grow: 1;
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;

  .game-logo {
    margin: 0;
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
  }

  .card-block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    margin: 0 10px;
  }
`;

export const CardRowTitle = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey[800]};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardRowDataBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const CardRowStatName = styled.span`
  font-size: 14px;
  opacity: 0.5;
  color: ${({ theme }) => theme.colors.grey[800]};
`;

export const CardRowStatValue = styled.span`
  text-align: center;
  margin-right: 10px;
`;

export const CardRowRight = styled.div`
  width: calc(30% - 12px); // 12px is for margin left
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 2px solid #f5f5f7;
  margin-left: 12px;
`;

interface CardRowThumbnailProps {
  imageUrl?: string;
}

export const CardRowThumbnail = styled.span<CardRowThumbnailProps>`
  position: relative;
  height: 48px;
  width: 32px;
  background-image: ${(props: CardRowThumbnailProps) =>
    `url("${props.imageUrl}")`};
  background-size: cover;
  background-position: center center;
  border: 1px solid rgba(0, 0, 0, 0.244673);
  border-radius: 5px;
  cursor: pointer;
  margin-left: 12px;

  &:before {
    position: absolute;
    font-size: 10px;
    text-align: center;
    top: 8px;
    content: ${(props: CardRowThumbnailProps) =>
      props.imageUrl ? '' : "'View all'"};
  }
`;

export const CardFooter = styled.footer`
  height: 30px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blue[600]};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
