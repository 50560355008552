import { useFetchGame } from 'api';
import { FaApple } from 'react-icons/fa';
import { IoLogoAndroid } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface SwitchButtonProps {
  os: 'ios' | 'android';
  setOs: (os: 'ios' | 'android') => void;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({ os, setOs }) => {
  const params = useParams<{
    gameId: string;
  }>();
  const gameId = Number(params.gameId);
  const { data: game } = useFetchGame(gameId);

  const enableIOS = game?.appStoreUrl && game.iosPackageName;
  return (
    <StyledSwitchButton>
      <Button isActive={os === 'android'} onClick={() => setOs('android')}>
        <IoLogoAndroid
          size={60}
          color={os === 'android' ? '#92ba3c' : 'gray'}
        />
        <span>Android</span>
      </Button>
      {enableIOS && (
        <Button isActive={os === 'ios'} onClick={() => setOs('ios')}>
          <FaApple size={60} color={os === 'ios' ? 'black' : 'gray'} />
          <span>iOS</span>
        </Button>
      )}
    </StyledSwitchButton>
  );
};

const StyledSwitchButton = styled.div`
  display: flex;
  gap: 10;
  align-items: center;
`;

const Button = styled.button<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;

  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    `}

  &:focus {
    outline: none;
  }
`;

export default SwitchButton;
