export const routes: {
  login: string;
  logout: string;
  notFound: string;
  home: string;
  privacyPolicy: string;
  studio: string;
  game: string;
} = {
  login: '/login',
  logout: '/logout',
  notFound: '/notFound',
  home: '/home',
  privacyPolicy: '/privacy-policy',
  studio: '/studio/:id',
  game: '/game/:id',
};
