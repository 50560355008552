import { maxBy, round } from 'lodash';
import { BusinessChartType, ChurnRatePerLevel, LevelProgression } from 'types';
import {
  ChartContainer,
  ChartInfoContainer,
  ChartInnerContainer,
} from '../CompareChart.styles';
import { chartTitleGenerator } from 'pages/Test/Charts/utils/GlobalUtilsTsx';
import { MultipleCharts } from 'components/atoms';
import { formatAppVersion } from '../CompareChart.utils';
import { useCampaignAndAppVersionParams } from 'hooks';
import { getChartTitleAndTooltip } from 'pages/Test/Charts/utils/GlobalUtilsTs';

interface LevelProgressionCompareProps {
  levelProgressionDataOne: LevelProgression[];
  levelProgressionDataTwo: LevelProgression[];
  churnRatePerLevelDataOne: ChurnRatePerLevel[];
  churnRatePerLevelDataTwo: ChurnRatePerLevel[];
}

const LevelProgressionCompare: React.FC<LevelProgressionCompareProps> = ({
  levelProgressionDataOne,
  levelProgressionDataTwo,
  churnRatePerLevelDataOne,
  churnRatePerLevelDataTwo,
}) => {
  const { campaignName1, campaignName2, appVersion1, appVersion2 } =
    useCampaignAndAppVersionParams();
  const levelProgressionValuesOne = levelProgressionDataOne.map(level =>
    round(level.usersOfStepOne, 1)
  );
  const levelProgressionValuesTwo = levelProgressionDataTwo.map(level =>
    round(level.usersOfStepOne, 1)
  );
  const levelProgressionLabelsOne = levelProgressionDataOne.map(
    level => level.event
  );
  const levelProgressionLabelsTwo = levelProgressionDataTwo.map(
    level => level.event
  );
  const levelProgressionColorOne = '#57B4F9';
  const levelProgressionColorTwo = '#8144fd';

  const churnRatePerLevelValuesOne = churnRatePerLevelDataOne.map(churnRate =>
    round(churnRate.churnRate, 1)
  );
  const churnRatePerLevelValuesTwo = churnRatePerLevelDataTwo.map(churnRate =>
    round(churnRate.churnRate, 1)
  );
  const churnRatePerLevelLabelsOne = churnRatePerLevelDataOne.map(
    churnRate => churnRate.event
  );
  const churnRatePerLevelLabelsTwo = churnRatePerLevelDataTwo.map(
    churnRate => churnRate.event
  );

  const churnRatePerLevelColorOne = '#ffc043';
  const churnRatePerLevelColorTwo = '#34e360';

  const chartData = {
    labels: maxBy(
      [
        levelProgressionLabelsOne,
        levelProgressionLabelsTwo,
        churnRatePerLevelLabelsOne,
        churnRatePerLevelLabelsTwo,
      ],
      labels => labels.length
    ),
    datasets: [
      {
        type: 'line' as const,
        label: `${campaignName1} - ${formatAppVersion(
          appVersion1
        )} - Churn Rate Per Level (%)`,
        data: churnRatePerLevelValuesOne,
        yAxisID: 'churnRatePerLevel',
        pointStyle: 'circle',
        borderColor: churnRatePerLevelColorOne,
        backgroundColor: churnRatePerLevelColorOne,
      },
      {
        type: 'line' as const,
        label: `${campaignName2} - ${appVersion2} - Churn Rate Per Level (%)`,
        data: churnRatePerLevelValuesTwo,
        yAxisID: 'churnRatePerLevel',
        pointStyle: 'circle',
        borderColor: churnRatePerLevelColorTwo,
        backgroundColor: churnRatePerLevelColorTwo,
      },
      {
        type: 'bar' as const,
        label: `${campaignName1} - ${formatAppVersion(
          appVersion1
        )} - Level Progression (%)`,
        data: levelProgressionValuesOne,
        yAxisID: 'levelProgression',
        maxBarThickness: 60,
        backgroundColor: levelProgressionColorOne,
      },
      {
        type: 'bar' as const,
        label: `${campaignName2} - ${appVersion2} - Level Progression (%)`,
        data: levelProgressionValuesTwo,
        yAxisID: 'levelProgression',
        maxBarThickness: 60,
        backgroundColor: levelProgressionColorTwo,
      },
    ],
  };

  const chartOptions = {
    levelProgression: {
      type: 'linear',
      position: 'left',
      min: 0,
      max: 100,
      ticks: {
        count: 5,
        color: '#57B4F9',
        callback: function (label: number) {
          return `${label}%`;
        },
      },
    },
    churnRatePerLevel: {
      type: 'linear',
      position: 'right',
      min: 0,
      ticks: {
        count: 5,
        color: '#ffc043',
        callback: function (label: number) {
          return `${label}%`;
        },
      },
    },
  };

  const { title, tooltip } = getChartTitleAndTooltip(
    BusinessChartType.LEVEL_PROGRESSION
  );

  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
      </ChartInfoContainer>
      <ChartInnerContainer>
        <MultipleCharts
          chartData={chartData}
          customScaleOptions={chartOptions}
        />
      </ChartInnerContainer>
    </ChartContainer>
  );
};

export default LevelProgressionCompare;
