import { Modal, Stepper } from 'components';
import { Button } from 'components/atoms';
import { useRef, useState } from 'react';
import { useStudioWizard } from 'store';
import styled from 'styled-components';
import PaymentDetailsStep from './PaymentDetailsStep/PaymentDetailsStep';
import { useCreateStudio, useUpdateStudio } from 'api';
import { toast } from 'react-toastify';
import ConfirmationStep from './ConfirmationStep/ConfirmationStep';
import StudioDetailStep from './StudioDetailStep/StudioDetailStep';
import { useQueryClient } from '@tanstack/react-query';
import { ServerQueryKey } from 'types';
import { useNavigate } from 'react-router-dom';

enum CreateStudioStep {
  STUDIO_DETAILS = 'Studio details',
  PAYMENT_DETAILS = 'Payment details',
  CONFIRMATION = 'Confirmation',
}

export type SubmitStudioDetailsRef = {
  submitStudioDetails: () => void;
};

export type SubmitPaymentDetailsRef = {
  submitPaymentDetails: () => void;
};

interface AddStudioModalProps {
  isOpen: boolean;
  onClose: () => void;
  isCreateMode: boolean;
  studioId?: number;
}

const AddStudioModal = ({
  isOpen,
  onClose,
  isCreateMode,
  studioId,
}: AddStudioModalProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const submitStudioDetailsRef = useRef<SubmitStudioDetailsRef>(null);
  const submitPaymentDetailsRef = useRef<SubmitPaymentDetailsRef>(null);
  const {
    clearStore,
    setIsPaymentDetailsSkipped,
    isPaymentDetailsValid,
    isStudioDetailsValid,
    isPaymentDetailsSkipped,
    studioDetails,
    paymentDetails,
  } = useStudioWizard(state => state);
  const { mutate: createStudio } = useCreateStudio();
  const { mutate: updateStudio } = useUpdateStudio(studioId!, {
    enabled: !!studioId,
  });
  const steps = [
    CreateStudioStep.STUDIO_DETAILS,
    CreateStudioStep.PAYMENT_DETAILS,
    CreateStudioStep.CONFIRMATION,
  ];
  const [currentStep, setCurrentStep] = useState(0);

  const isStudioDetailsStep =
    steps[currentStep] === CreateStudioStep.STUDIO_DETAILS;
  const isPaymentDetailsStep =
    steps[currentStep] === CreateStudioStep.PAYMENT_DETAILS;
  const isConfirmationStep =
    steps[currentStep] === CreateStudioStep.CONFIRMATION;

  const onSuccess = (message: string) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    onClose();
  };

  const onError = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const onSubmit = () => {
    const studio = {
      name: studioDetails.studioName,
      contactName: studioDetails.contactName,
      contactEmail: studioDetails.contactEmail,
      contactPhone: studioDetails.contactPhoneNumber,
      externalUrl: studioDetails.mondayURL,
      cityId: String(studioDetails.location.id),
      managerId: String(studioDetails.publishingManager.id),
      logo: studioDetails.logo,
    };

    const reqBody: any = {
      studioDetails: studio,
    };

    if (!isPaymentDetailsSkipped) {
      reqBody['paymentDetails'] = paymentDetails;
    }

    if (isCreateMode) {
      createStudio(reqBody, {
        onSuccess: ({ id }: any) => {
          onSuccess('Studio was successfully created');
          queryClient.invalidateQueries([ServerQueryKey.NavStudios]);
          navigate(`/studio/${id}`);
          clearStore();
        },
        onError: () => onError('Failed to create the studio'),
      });
    } else {
      updateStudio(reqBody, {
        onSuccess: () => {
          onSuccess('Studio was successfully updated');
          queryClient.invalidateQueries([ServerQueryKey.NavStudios]);
          queryClient.invalidateQueries([
            ServerQueryKey.StudioDetails,
            studioId,
          ]);
          clearStore();
        },
        onError: () => onError('Failed to update the studio'),
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        clearStore();
        onClose();
      }}
      title={isCreateMode ? 'create studio' : 'edit studio'}
    >
      <Stepper
        steps={steps}
        currentStep={currentStep}
        controls={{ onBack: () => {}, onNext: () => {} }}
        renderControls={() => (
          <StepperControls>
            <div className="left">
              <Button
                onClick={() => {
                  clearStore();
                  onClose();
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="right">
              {isStudioDetailsStep && (
                <>
                  <SubmitButton
                    primary
                    disabled={!isStudioDetailsValid}
                    onClick={() => {
                      submitStudioDetailsRef.current?.submitStudioDetails();
                      setCurrentStep(currentStep + 1);
                    }}
                  >
                    Next
                  </SubmitButton>
                </>
              )}
              {isPaymentDetailsStep && (
                <>
                  <Button onClick={() => setCurrentStep(currentStep - 1)}>
                    Back
                  </Button>
                  <Button
                    onClick={() => {
                      setIsPaymentDetailsSkipped(true);
                      setCurrentStep(currentStep + 1);
                    }}
                  >
                    Skip
                  </Button>
                  <SubmitButton
                    primary
                    disabled={!isPaymentDetailsValid}
                    onClick={() => {
                      setIsPaymentDetailsSkipped(false);
                      submitPaymentDetailsRef.current?.submitPaymentDetails();
                      setCurrentStep(currentStep + 1);
                    }}
                  >
                    Next
                  </SubmitButton>
                </>
              )}
              {isConfirmationStep && (
                <>
                  <Button
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    Back
                  </Button>
                  <SubmitButton primary onClick={onSubmit}>
                    {isCreateMode ? 'CREATE STUDIO' : 'CONFIRM DETAILS'}
                  </SubmitButton>
                </>
              )}
            </div>
          </StepperControls>
        )}
      >
        <StepperCompsWrapper>
          {isStudioDetailsStep && (
            <StudioDetailStep
              isCreateMode={isCreateMode}
              ref={submitStudioDetailsRef}
            />
          )}
          {isPaymentDetailsStep && (
            <PaymentDetailsStep ref={submitPaymentDetailsRef} />
          )}
          {isConfirmationStep && <ConfirmationStep />}
        </StepperCompsWrapper>
      </Stepper>
    </Modal>
  );
};

export default AddStudioModal;

const StepperControls = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
  }
`;
const StepperCompsWrapper = styled.div`
  margin: 0 1%;
`;

const SubmitButton = styled(Button)`
  min-width: 200px;
`;
