import { useState, ChangeEvent } from 'react';
import { useTheme } from 'styled-components';
import { SearchInput } from 'components/molecules';
import OverviewList from 'components/OverviewList/OverviewList';
import { CenteredWrapper, TextSpan } from 'pages/NotFound/NotFound.styles';
import { useFetchOverviewCampaigns } from 'api';
import { OverviewGamesDateRange } from 'types';
import { isEmpty } from 'lodash';
import {
  filterBySearchText,
  filterNegativeStatusCampaigns,
  getTableRowsWithRefetch,
} from './CampaignOverview.utils';
import { Wrapper } from './CampaignOverview.styles';
import { OverviewListSkeleton } from 'components';

interface CampaignOverviewProps {
  dateRange?: OverviewGamesDateRange;
  showNegativeCampaigns: boolean;
}

const CampaignOverview = ({
  dateRange,
  showNegativeCampaigns,
}: CampaignOverviewProps) => {
  const theme = useTheme();
  const {
    data: campaigns,
    refetch: refetchCampaigns,
    isSuccess,
    isLoading,
  } = useFetchOverviewCampaigns(dateRange!, { enabled: !!dateRange });
  const [searchText, setSearchText] = useState<string>('');

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  if (isLoading) {
    return (
      <Wrapper hasContent={true} data-testid="campaign-overview-container">
        <OverviewListSkeleton rowLength={10} />
      </Wrapper>
    );
  }

  if (isSuccess) {
    const filteredActiveCampaigns = filterBySearchText(
      campaigns.active,
      searchText
    );
    let filteredPassedCampaigns = filterBySearchText(
      campaigns.passed,
      searchText
    );

    if (!showNegativeCampaigns) {
      filteredPassedCampaigns = filterNegativeStatusCampaigns(
        filteredPassedCampaigns
      );
    }

    const getTableRows = getTableRowsWithRefetch(refetchCampaigns);

    const filteredActiveRows = getTableRows(filteredActiveCampaigns);
    const filteredPassedRows = getTableRows(filteredPassedCampaigns);

    const rows = [[...filteredActiveRows], [...filteredPassedRows]];

    const columns = [
      {
        id: 'search',
        name: 'Search',
        width: 390,
        children: (
          <SearchInput
            containerStyle={{
              margin: 0,
              position: 'sticky',
              top: 0,
              zIndex: 900,
              backgroundColor: theme.colors.grey.offWhite,
            }}
            fullWidth={true}
            placeholder={'Filter by Game, Studio or Tag'}
            onChange={handleSearchText}
          />
        ),
      },
      { id: 'status', name: 'Type', width: 130, sortable: true },
      { id: 'cpc', name: 'CPC', width: 90, sortable: true },
      { id: 'cpi', name: 'CPI', width: 90, sortable: true },
      { id: 'ctr', name: 'CTR', width: 90, sortable: true },
      {
        id: 'dayOneRetentionAverage',
        name: 'Avg. D1 Retention',
        width: 120,
        sortable: true,
      },
      {
        id: 'startedDate',
        name: 'Started Date',
        width: 120,
        sortable: true,
      },
      { id: 'testingStatus', name: '', width: 30 },
    ];

    const hasCampaigns =
      !isEmpty(campaigns.active) || !isEmpty(campaigns.passed);

    return (
      <Wrapper hasContent={true} data-testid="campaign-overview-container">
        {hasCampaigns ? (
          <OverviewList
            columns={columns}
            gridTemplateColumns={'2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'}
            blocks={rows}
          />
        ) : (
          <CenteredWrapper data-testid="campaign-centered-container">
            <img src="/assets/empty_campaigns.png" alt="empty campaigns" />
            <TextSpan color={'#bababa'}>
              Your activity is empty :(
              <br />
              It's time to start a new test!
            </TextSpan>
          </CenteredWrapper>
        )}
      </Wrapper>
    );
  }
  return null;
};

export default CampaignOverview;
