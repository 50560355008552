import { useGetCreativePackMedias, useCreateCreativePack } from 'api';
import { Button } from 'components/atoms';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PackMediaEnum } from 'types';
import {
  StyledCreativePackGenerator,
  StyledTextInput,
} from './CreativePackGenerator.styles';
import CreativePackInput from './CreativePackInput/CreativePackInput';

type PackMediaIdsObjType = {
  [key in PackMediaEnum]: number;
};

interface CreativePackGeneratorProps {
  onSuccess: () => void;
}

const handleCreativePackGeneratorSuccess = (text: string) => {
  toast.success(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};
const CreativePackGenerator = ({ onSuccess }: CreativePackGeneratorProps) => {
  const params = useParams<{
    gameId: string;
  }>();
  const gameId = Number(params.gameId);
  const { mutate: createCreativePack } = useCreateCreativePack(gameId);
  const { data: packMediaByCategory } = useGetCreativePackMedias(gameId);
  const [packName, setPackName] = useState<string>('');
  const [disableCreateButton, setDisableCreateButton] =
    useState<boolean>(false);
  const [packMediaIdsObj, setPackMediaIdsObj] = useState<PackMediaIdsObjType>({
    portraitVideo: 0,
    landscapeVideo: 0,
    image: 0,
  });
  const handlePackNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPackName(event.target.value);
  };

  const updatePackMediaIds = (
    packMediaType: PackMediaEnum,
    packMediaId: number
  ) =>
    setPackMediaIdsObj(prevState => ({
      ...prevState,
      [packMediaType]: packMediaId,
    }));

  const onInputUpdate =
    (packMediaType: PackMediaEnum) => (packMediaId: number) =>
      updatePackMediaIds(packMediaType, packMediaId);

  const getPackMediaIds = () =>
    Object.values(packMediaIdsObj).filter((value: number) => value !== 0);

  const handleSubmitCreativePack = () => {
    const reqBody = {
      mediaIds: getPackMediaIds(),
      name: packName,
    };
    createCreativePack(reqBody, {
      onSuccess: () => {
        onSuccess();
        handleCreativePackGeneratorSuccess(
          'Video Pack was successfully created'
        );
      },
    });
  };

  useEffect(() => {
    const validatePackMedias = () =>
      packMediaIdsObj.image === 0 ||
      (packMediaIdsObj.portraitVideo === 0 &&
        packMediaIdsObj.landscapeVideo === 0)
        ? false
        : true;

    if (packName.length === 0 || !validatePackMedias())
      setDisableCreateButton(true);
    else setDisableCreateButton(false);
  }, [
    packName,
    packMediaIdsObj.image,
    packMediaIdsObj.portraitVideo,
    packMediaIdsObj.landscapeVideo,
  ]);

  return (
    <StyledCreativePackGenerator>
      <div className="pack-name">
        <span className="label">Pack name</span>
        <StyledTextInput
          placeholder="Enter name"
          onChange={handlePackNameChange}
          value={packName}
        />
      </div>
      <div className="pack-content">
        <CreativePackInput
          inputType={PackMediaEnum.portraitVideo}
          onInputUpdate={onInputUpdate(PackMediaEnum.portraitVideo)}
          packMedias={packMediaByCategory?.unityPortraitVideo ?? []}
        />
        <CreativePackInput
          inputType={PackMediaEnum.landscapeVideo}
          onInputUpdate={onInputUpdate(PackMediaEnum.landscapeVideo)}
          packMedias={packMediaByCategory?.unityLandscapeVideo ?? []}
        />
        <CreativePackInput
          inputType={PackMediaEnum.image}
          onInputUpdate={onInputUpdate(PackMediaEnum.image)}
          packMedias={packMediaByCategory?.unityImage ?? []}
        />
      </div>
      <Button
        primary
        type="button"
        onClick={handleSubmitCreativePack}
        style={{ margin: 0 }}
        disabled={disableCreateButton}
      >
        CREATE PACK
      </Button>
    </StyledCreativePackGenerator>
  );
};

export default CreativePackGenerator;
