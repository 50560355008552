import { values, pickBy, startsWith, max, ceil, map, round } from 'lodash';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { BusinessChartType, CohortTableRow } from 'types';
import { cohortLabelsGenerator } from './GlobalUtilsTs';

export const initialCohortChartData: ChartData<'line'> = {
  labels: [],
  datasets: [],
};

const cohortChartColors = [
  '#2da1f8',
  '#ffc043',
  '#8242fc',
  '#38e360',
  '#e6006c',
  '#bf7b49',
  '#e7dbfe',
  '#facce2',
  '#c4ffd2',
  '#afafaf',
  '#abcdef',
  '#8a70db',
];

const cumulativeSum = (sum: number) => {
  return (value: number) => {
    return round((sum += value), 2);
  };
};

export const getCumulativeSum = (arr: number[]) => {
  return map(arr, cumulativeSum(0));
};

const cohortChartDatasetGenerator = (
  row: CohortTableRow,
  color: string,
  cumulative: boolean
): ChartDataset<'line'> => {
  let data = values(
    pickBy(row, (_value, key) => startsWith(key, 'day'))
  ) as number[];

  if (cumulative) {
    data = getCumulativeSum(data);
  }

  const dataset = {
    label: row.installDate.replace(/\d{4}-/, ''),
    data,
    borderColor: color,
    backgroundColor: color,
    borderDash: row.installDate === 'predicted' ? [5, 5] : [],
    ...(row.installDate === 'CPI'
      ? {
          pointRadius: 0,
          pointHoverRadius: 0,
        }
      : {}),
  };

  return dataset;
};

export const cohortChartDataGenerator = (
  rows: CohortTableRow[],
  cohortLabelsIndex: number[],
  cumulative: boolean = false
): ChartData<'line'> => {
  if (rows.length !== 0) {
    const labels = cohortLabelsGenerator(cohortLabelsIndex, 'Day ');
    const datasets = rows.map((row, index) =>{
      const color = row.installDate === 'CPI' ? "#CAC9C9": cohortChartColors[index]
     return cohortChartDatasetGenerator(row, color, cumulative)
      
    }
    );

    return {
      labels,
      datasets,
    };
  } else return initialCohortChartData;
};

const calculateMaxOfSelectedRows = (
  rows: CohortTableRow[],
  precision: number
): number => {
  const maxOfEachRows = rows.map(row =>
    max(values(pickBy(row, (_value, key) => startsWith(key, 'day'))))
  ) as number[];

  const maxOfAllRows = max(maxOfEachRows) as number;
  const roundedMaxValue = maxOfAllRows
    ? ceil(maxOfAllRows + maxOfAllRows * 0.1, precision)
    : 10;

  return roundedMaxValue;
};

export const cohortChartOptionsGenerator = (
  rows: CohortTableRow[],
  unit: string,
  chartType: BusinessChartType
): ChartOptions<'line'> => {
  const yAxisPrecision = chartType === BusinessChartType.ARPI ? 2 : 0;
  const max = calculateMaxOfSelectedRows(rows, yAxisPrecision);

  return {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index' as 'index',
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
        },
      },
    },
    scales: {
      xAxes: {
        offset: true,
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
      yAxes: {
        offset: true,
        min: 0,
        max,
        ticks: {
          callback: props =>
            unit === '%' || unit === 's' ? props + unit : unit + props,
        },
      },
    },
  };
};
