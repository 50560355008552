import styled from 'styled-components';

interface Props {
  offset: string;
  textAlign?: string;
}

export const TooltipTitle = styled.div<Props>`
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  overflow-wrap: break-word;
  text-align: ${(props: Props) => props.textAlign };
  color: #fff;
  background: #717171;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  border-radius: 10px;
  padding: 10px;
  z-index: var(--z-index-popup);

  &.xx-sm {
    width: 75px;
  }

  &.x-sm {
    width: 140px;
  }

  &.sm {
    width: 200px;
  }

  &.md {
    width: 400px;
  }

  &.top {
    left: 50%;
    top: ${(props: Props) => props.offset};
    transform: translate(-50%, -100%);
  }

  &.bottom {
    left: 50%;
    bottom: ${(props: Props) => props.offset};
    transform: translate(-50%, 100%);
  }

  &.left {
    top: 50%;
    left: ${(props: Props) => props.offset};
    transform: translate(-100%, -50%);
  }

  &.right {
    top: 50%;
    right: ${(props: Props) => props.offset};
    transform: translate(100%, -50%);
  }

  &.top-left,
  &.left-top {
    left: ${(props: Props) => props.offset};
    top: ${(props: Props) => props.offset};
    transform: translate(-100%, -100%);
  }

  &.top-right,
  &.right-top {
    right: ${(props: Props) => props.offset};
    top: ${(props: Props) => props.offset};
    transform: translate(100%, -100%);
  }

  &.bottom-left,
  &.left-bottom {
    left: ${(props: Props) => props.offset};
    bottom: ${(props: Props) => props.offset};
    transform: translate(-100%, 100%);
  }

  &.bottom-right,
  &.right-bottom {
    right: ${(props: Props) => props.offset};
    bottom: ${(props: Props) => props.offset};
    transform: translate(100%, 100%);
  }
`;

export const TooltipWrapper = styled.div`
  display: inline-flex;
  position: relative;

  &:hover ${TooltipTitle} {
    visibility: visible;
    opacity: 1;
  }
`;
