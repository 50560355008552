import { CSSProperties, FC } from 'react';
import {
  ModalBackdrop,
  ModalContainer,
  ModalOuterContainer,
} from 'components/atoms';
import { ModalContent } from 'components/atoms/modal/ModalContent';

export interface Props {
  containerStyle?: CSSProperties;
  children?: React.ReactNode;
}

export const InfoModal: FC<Props> = ({ children, containerStyle }) => {
  return (
    <>
      <ModalBackdrop />
      <ModalOuterContainer>
        <ModalContainer style={{ ...(containerStyle ?? {}) }}>
          <ModalContent>{children}</ModalContent>
        </ModalContainer>
      </ModalOuterContainer>
    </>
  );
};
