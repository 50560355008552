import styled from 'styled-components';
import Logo from './Logo';
import {
  FormFieldError,
  InputContainer,
  TopFormElementsWrapper,
} from 'components/AddStudioModal/AddStudioModal.styles';
import { FaArrowCircleRight } from 'react-icons/fa';
import { Label, TextInput } from 'components/atoms';
import { ChangeEvent, useState } from 'react';
import { useGameWizard } from 'store';
import TagModifier from 'components/TagModifier/TagModifier';
import { GameOrientation, TagType } from 'types';
import { useCheckPlaystoreUrl } from 'api';
import { Icon } from 'assets/styles';
import { info } from 'assets/images';
import Radio from './Radio';

const BasicDetailsStep = () => {
  const { mutate: checkPlaystoreUrl } = useCheckPlaystoreUrl();
  const { basicDetails, setBasicDetails } = useGameWizard(state => state);
  const [playstoreUrlError, setPlaystoreUrlError] = useState('');
  const [disableLogo, setDisableLogo] = useState(false);

  const handlePlaystoreUrl = (event: ChangeEvent<HTMLInputElement>) =>
    setBasicDetails({ ...basicDetails, playstoreUrl: event.target.value });

  const handleGameName = (event: ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({ ...basicDetails, gameName: event.target.value });
  };

  const handleBundleId = (event: ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({ ...basicDetails, bundleId: event.target.value });
  };

  const handleAppStoreUrl = (event: ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({ ...basicDetails, appStoreUrl: event.target.value });
  };

  const handleIosPackageName = (event: ChangeEvent<HTMLInputElement>) => {
    setBasicDetails({ ...basicDetails, iosPackageName: event.target.value });
  };

  const handleMondayItemId = (event: ChangeEvent<HTMLInputElement>) =>
    setBasicDetails({
      ...basicDetails,
      mondayItemId: Number(event.target.value),
    });

  const handleSelectedTags = (newSelectedTags: TagType[]) =>
    setBasicDetails({
      ...basicDetails,
      tags: newSelectedTags,
    });

  const handleOrientation = (orientation: GameOrientation) => {
    setBasicDetails({
      ...basicDetails,
      orientation,
    });
  };

  const handleGetDetails = () => {
    const { playstoreUrl } = basicDetails;
    if (playstoreUrl.length !== 0) {
      setPlaystoreUrlError('');
      setDisableLogo(true);
      checkPlaystoreUrl(playstoreUrl, {
        onSuccess: (data: { iconUrl: string; name: string }) => {
          const url = new URL(playstoreUrl);
          const extractedBundleId: string = url.searchParams.get('id') ?? '';
          setBasicDetails({
            ...basicDetails,
            logoUrl: data.iconUrl,
            gameName: data.name,
            playstoreUrl,
            bundleId: extractedBundleId,
          });
          setDisableLogo(false);
        },
        onError: (error: any) => {
          if (error.response.data && Array.isArray(error.response.data.url)) {
            setPlaystoreUrlError(error.response.data.url[0]);
            setBasicDetails({
              ...basicDetails,
              logoUrl: '',
              gameName: '',
              playstoreUrl: '',
            });
          }
          setDisableLogo(false);
        },
      });
    }
  };

  return (
    <StyledBasicDetailsStep>
      <div className="details-wrapper">
        <FormWrapper>
          <StyledInputContainer gridColumn="1/5">
            <Label htmlFor="playStoreUrl">Play Store URL</Label>
            <div style={{ position: 'relative' }}>
              <StyledTextInput
                id="playStoreUrl"
                value={basicDetails.playstoreUrl}
                onChange={handlePlaystoreUrl}
                placeholder="Add your game URL"
              />
              <IconWrapper isDisabled={basicDetails.playstoreUrl.length === 0}>
                <FaArrowCircleRight size={30} onClick={handleGetDetails} />
              </IconWrapper>
            </div>
            {playstoreUrlError.length !== 0 && (
              <ErrorField>{playstoreUrlError}</ErrorField>
            )}
            <Banner>
              <Icon src={info} style={{ marginRight: '5px' }} />
              <div className="text">
                <span>
                  Games can be created without a Play Store URL by entering a
                  game name.
                </span>
                <span>
                  Tests cannot be run without a URL, but can be entered later.
                </span>
              </div>
            </Banner>
          </StyledInputContainer>
          <StyledInputContainer gridColumn="1/3" style={{ paddingTop: 10 }}>
            <Label htmlFor="gameName">Game Name *</Label>
            <StyledTextInput
              id="gameName"
              disabled={disableLogo}
              value={basicDetails.gameName}
              onChange={handleGameName}
              placeholder="Type something..."
            />
          </StyledInputContainer>
          <StyledInputContainer
            gridColumn="3/5"
            style={{
              padding: 10,
              border: '1px solid #dfdfdf',
              borderRadius: 4,
            }}
          >
            <Label htmlFor="bundleId">Bundle ID *</Label>
            <StyledTextInput
              id="bundleId"
              disabled={disableLogo}
              value={basicDetails.bundleId}
              onChange={handleBundleId}
              placeholder="E.g.: abc.studioname.gamename"
            />
            <Label htmlFor="appStoreUrl">Apple Store URL</Label>
            <StyledTextInput
              id="appStoreUrl"
              disabled={disableLogo}
              value={basicDetails.appStoreUrl}
              onChange={handleAppStoreUrl}
              placeholder="Type something..."
            />
            <Label htmlFor="appStoreUrl">iOS Package Name</Label>
            <StyledTextInput
              id="iosPackageName"
              disabled={disableLogo}
              value={basicDetails.iosPackageName}
              onChange={handleIosPackageName}
              placeholder="Type something..."
            />
          </StyledInputContainer>
          <StyledInputContainer gridColumn="1/5">
            <TagModifier
              selectedTags={basicDetails.tags}
              onSelect={handleSelectedTags}
            />
          </StyledInputContainer>
          <StyledInputContainer>
            <Label htmlFor="mondayItemId">Monday.com Item ID</Label>
            <StyledTextInput
              id="mondayItemId"
              type="number"
              value={
                basicDetails.mondayItemId === null
                  ? ''
                  : basicDetails.mondayItemId
              }
              onChange={handleMondayItemId}
              placeholder="Type something..."
            />
          </StyledInputContainer>
          <StyledInputContainer gridColumn="3/5">
            <Label htmlFor="">Orientation</Label>
            <RadioWrapper>
              <Radio
                type={GameOrientation.portrait}
                label="Portrait"
                isChecked={
                  basicDetails.orientation === GameOrientation.portrait
                }
                onChange={() => handleOrientation(GameOrientation.portrait)}
              />
              <Radio
                type={GameOrientation.landscape}
                label="Landscape"
                isChecked={
                  basicDetails.orientation === GameOrientation.landscape
                }
                onChange={() => handleOrientation(GameOrientation.landscape)}
              />
            </RadioWrapper>
          </StyledInputContainer>
        </FormWrapper>
      </div>
      <div className="logo-wrapper">
        <Logo isButtonDisabled={disableLogo} />
      </div>
    </StyledBasicDetailsStep>
  );
};

export default BasicDetailsStep;

const StyledBasicDetailsStep = styled.div`
  height: 100%;
  display: flex;

  .details-wrapper {
    flex: 1;
  }

  .logo-wrapper {
    width: 30%;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: 44px;
  margin-bottom: 5px;
`;

interface StyledInputContainerProps {
  gridColumn?: string;
}

const StyledInputContainer = styled(InputContainer)<StyledInputContainerProps>`
  grid-column: ${props => props.gridColumn ?? '1/3'};
`;

const ErrorField = styled(FormFieldError)`
  margin-top: 5px;
  margin-left: 10px;
`;

const Banner = styled.div`
  background-color: #f3f3f3;
  border-radius: 4px;
  height: 60px;
  padding: 10px;
  display: flex;
  align-items: start;
  font-size: 14px;

  span {
    line-height: 1.5;
  }

  .text {
    display: flex;
    flex-direction: column;
  }
`;

const FormWrapper = styled(TopFormElementsWrapper)`
  grid-template-columns: repeat(4, 1fr);
`;

interface IconWrapperProps {
  isDisabled: boolean;
}

const IconWrapper = styled.span<IconWrapperProps>`
  color: ${props =>
    props.isDisabled
      ? props.theme.colors.grey[1000]
      : props.theme.colors.blue[500]};
  position: absolute;
  top: 55%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: ${props => props.isDisabled && 'none'};
`;

const RadioWrapper = styled.div`
  flex: 1;
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
