import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { CampaignActivity, ServerQueryKey } from 'types';

const getCampaignActivity = async (gameId: number, campaignId: number) => {
  const {
    data: { data },
  } = await axiosInstance.get(
    `/games/${gameId}/campaigns/${campaignId}/activities`
  );
  return data as CampaignActivity[];
};

export const useCampaignActivity = (gameId: number, campaignId: number) => {
  return useQuery(
    [ServerQueryKey.CampaignActivity, campaignId],
    () => getCampaignActivity(gameId, campaignId),
    { initialData: [] }
  );
};
