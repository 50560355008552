import { StyledActionButton } from './ActionButton.styles';

interface ActionButtonProps {
  text: string;

  width?: string;
  height?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  disabled?: boolean;
  onClick?: any;
}

const ActionButton = (props: ActionButtonProps) => {
  return (
    <StyledActionButton
      width={props.width}
      height={props.height}
      fontSize={props.fontSize}
      lineHeight={props.lineHeight}
      fontWeight={props.fontWeight}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text}
    </StyledActionButton>
  );
};

export default ActionButton;
