import { ChangeEvent, CSSProperties } from 'react';
import { TextInput } from 'components/atoms';
import styled, { css, useTheme } from 'styled-components';
import { AiOutlineSearch } from 'react-icons/ai';

interface Props {
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  containerStyle?: CSSProperties;
  sidenav?: boolean;
  fullWidth?: boolean;
  searchIcon?: boolean;
}

export const SearchInput = ({
  onChange,
  containerStyle,
  placeholder,
  sidenav,
  fullWidth,
  searchIcon = true,
}: Props) => {
  const theme = useTheme();
  return (
    <SearchContainer style={{ ...(containerStyle ?? {}) }}>
      {searchIcon && <Icon size={22} color={theme.colors.grey[900]} />}
      <StyledTextInput
        sidenav={sidenav}
        fullWidth={fullWidth}
        placeholder={placeholder ?? 'Search'}
        onChange={onChange}
      />
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  position: relative;
  margin: 16px 0;
  display: flex;
  align-items: center;
`;

const Icon = styled(AiOutlineSearch)`
  position: absolute;
  left: 10px;
`;

export const StyledTextInput = styled(TextInput)`
  background-color: ${props =>
    props.sidenav
      ? props.theme.colors.grey.offWhite
      : props.theme.colors.grey.white};
  border: 0;
  height: 34px;
  margin: 0;
  text-indent: 40px;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  ${(props: Props) =>
    !props.sidenav &&
    css`
      width: 200px;
    `};

  ${(props: Props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
`;
