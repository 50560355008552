import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GameLogo } from 'components/atoms';
import moment from 'moment';
import { useDeleteNotification } from 'api';
import { NotificationType } from 'types';
import { useQueryClient } from '@tanstack/react-query';

interface NotificationProps extends NotificationType {
  handler: () => void;
}

export const Notification = (props: NotificationProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: deleteNotification } = useDeleteNotification();

  const handleClick = () => {
    deleteNotification(props.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetchStatus']);
        props.handler();
        navigate(props.url);
      },
    });
  };
  return (
    <NotificationContainer onClick={handleClick}>
      <NotificationLogo>
        <GameLogo url={props.logo} name={''} size="sm" />
      </NotificationLogo>
      <NotificationContent>
        <NotificationTop>
          <NotificationTitle>{props.title}</NotificationTitle>
          <NotificationTime>{moment(props.date).fromNow()}</NotificationTime>
        </NotificationTop>
        <NotificationText>{props.text}</NotificationText>
      </NotificationContent>
    </NotificationContainer>
  );
};

const NotificationContainer = styled.div`
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.offWhite};
  }
`;

const NotificationLogo = styled.div`
  width: 20%;
  vertical-align: top;
`;

const NotificationContent = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const NotificationTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotificationTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

const NotificationTime = styled.span`
  font-size: 12px;
  opacity: 0.6;
`;

const NotificationText = styled.span`
  font-size: 12px;
  word-wrap: break-word;
  font-weight: 100;
  flex-grow: 1;
  text-align: justify;
`;
