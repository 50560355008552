import { Icon } from 'assets/styles';
import { Badge } from '../Badge';
import { monetization } from 'assets/images';

const MonetizationBadge = () => {
  return (
    <Badge
      styles={{
        backgroundColor: '#FFEE92',
        padding: '6px 10px 6px 6px',
        height: 30,
      }}
    >
      <Icon src={monetization} style={{ cursor: 'default' }} />
      {'Monetization'}
    </Badge>
  );
};

export default MonetizationBadge;
