import styled from 'styled-components';

export const StyledMediaContainer = styled.div`
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey.white};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .header {
    font-size: 14px;
    padding: 0 16px;
    background-color: #eeeeee;
    height: 40px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 14px;
    }

    .settings {
      display: flex;
      justify-content: space-evenly;

      .config-setting {
        color: ${({ theme }) => theme.colors.blue[500]};
        display: flex;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }

  .body {
    flex-grow: 1;
  }
`;
