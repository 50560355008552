import { round } from 'lodash';

export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index' as 'index',
  },
  plugins: {
    legend: {
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        padding: 20,
      },
    },
  },
  scales: {
    xAxes: {
      offset: true,
      grid: {
        drawBorder: false,
        lineWidth: 0,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
      },
    },
    yAxes: {
      ticks: {
        callback: (label: string | number) => `${round(Number(label))}s`,
      },
    },
  },
};

export const formatAppVersion = (appVersion: string) => {
  return appVersion !== 'all' ? appVersion : 'All';
};
