import { Skeleton } from 'components';
import { StyledMonetization } from './Monetization.styles';

const MonetizationSkeleton = () => {
  return (
    <StyledMonetization>
      {new Array(2).fill(0).map(() => (
        <div className="top-container">
          <Skeleton width="300px" height="150px" />
          <Skeleton width="100%" height="60px" />
          <Skeleton width="100%" height="60px" />
        </div>
      ))}
    </StyledMonetization>
  );
};

export default MonetizationSkeleton;
