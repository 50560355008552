import styled, { css } from 'styled-components';

export const ModalHeaderTitle = styled.div`
  flex: 1;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey[900]};
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  margin-right: -84px;
  ${() =>
    window.innerHeight <= 720
      ? css`
          padding: 12px 0 8px;
        `
      : css`
          padding: 32px 0 24px;
        `}
`;
