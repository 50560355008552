import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { Currency, ServerQueryKey } from 'types';

const fetchCurrency = async () => {
  const { data } = await axiosInstance.get(
    `/studios/payment-details/currencies`
  );
  return data as Array<Currency>;
};

export const useFetchCurrency = () => {
  return useQuery([ServerQueryKey.Currency], () => fetchCurrency(), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
