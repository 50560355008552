import {
  AddInvoicesButton,
  InvoicesCounter,
  InvoicesOverviewHeader,
  InvoicesOverviewWrapper,
  OverviewHeaderLeft,
  CheckboxWrapper,
  InvoiceDataWrapper,
  InvoicesSum,
  ContentContainer,
  ActionsContainer,
  ModalTitle,
  ButtonContainer,
  ModalButton,
  LeftBoxHeader,
  CommentIcon,
} from './InvoicesOverview.styles';
import { useState, ChangeEvent } from 'react';
import OverviewList, {
  OverviewListRow,
} from 'components/OverviewList/OverviewList';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { GenerateInvoiceModal } from 'components/organisms';
import {
  invoiceDollar,
  editBlack,
  downloadBlack,
  archiveBlack,
  deleteBlack,
  unarchiveInvoice,
  invoiceImported,
} from 'assets/images';
import { Checkbox, InvoiceUpload, OverviewSkeleton } from 'components';
import { Button } from 'components/atoms';
import { Icon } from 'assets/styles';
import { useTheme } from 'styled-components';
import { SearchInput, InfoModal } from 'components/molecules';
import {
  AccessFeatures,
  Invoice,
  InvoiceStatus,
  InvoiceActions,
  UserRole,
  CommentsStatus,
} from 'types';
import {
  downloadFile,
  useFetchInvoices,
  useFetchPDFDownloadData,
  useFetchUser,
  useInvoiceArchive,
  useInvoiceDelete,
} from 'api';
import moment from 'moment';
import { hasPermission } from 'utils/user';
import InvoiceStatusBadge from 'components/Badge/InvoiceStatusBadge/InvoiceStatusBadge';
import { getActions } from './InvoiceOverview.utils';
import InvoiceReviewModal from './Modals/InvoiceReviewModal';
import ApproveModal from './Modals/ApproveModal';
import CommentsModal from './Modals/CommentsModal/CommentsModal';
import Tootltip from 'components/atoms/Tooltip/Tootltip';

interface InvoicesOverviewProps {
  studioId: number;
}

export const InvoicesOverview = ({ studioId }: InvoicesOverviewProps) => {
  const theme = useTheme();
  const { data: user, isSuccess: userSuccess } = useFetchUser();
  const queryClient = useQueryClient();
  const {
    data: invoiceData,
    isSuccess: FetchInvoicesSuccess,
    isLoading: isInvoiceLoading,
  } = useFetchInvoices(studioId);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<number>(0);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showReviewInvoiceModal, setShowReviewInvoiceModal] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [isCommentsEditMode, setIsCommentsEditMode] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [approveModalText, setApproveModalText] = useState('');
  const [showUnarchiveModal, setShowUnarchiveModall] = useState(false);
  const [showUploadPDFModal, setShowUploadPDFModal] = useState(false);
  const [showArchiveModal, setShowArchiveModall] = useState(false);
  const [showDeleteModal, setShowDeleteModall] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [showArchivedInvoices, setShowArchivedInvoices] = useState(false);

  const { mutate: invoiceArchive } = useInvoiceArchive(
    studioId,
    InvoiceActions.archive
  );
  const { mutate: invoiceUnarchive } = useInvoiceArchive(
    studioId,
    InvoiceActions.unarchive
  );
  const { mutate: invoiceDelete } = useInvoiceDelete(studioId);
  const { refetch: downloadInvoicePDF } = useFetchPDFDownloadData(
    studioId,
    selectedInvoiceId,
    { enabled: selectedInvoiceId !== 0 }
  );

  const onCloseReviewInvoiceModal = () => {
    setShowReviewInvoiceModal(false);
    setSelectedInvoiceId(0);
  };

  const onCloseCommentsModal = () => {
    setSelectedInvoiceId(0);
    setShowCommentsModal(false);
    queryClient.invalidateQueries(['getInvoices']);
  };

  const handleOpenApproveModal = (modalType: string) => {
    setShowReviewInvoiceModal(false);
    setShowApproveModal(!showApproveModal);
    setApproveModalText(modalType);
  };

  const handleOpenReviewModal = (invoiceId: number) => {
    setShowReviewInvoiceModal(true);
    setSelectedInvoiceId(invoiceId);
  };

  const handleCloseApproveModal = () => {
    setShowApproveModal(false);
  };
  const downloadPDFHandler = async (invoiceId: number) => {
    await setSelectedInvoiceId(invoiceId);
    downloadInvoicePDF().then(response => {
      const { data } = response;
      const name = data?.headers['content-disposition'].replace(
        'attachment; filename=',
        ''
      );
      const blob = new Blob([data?.data], { type: 'application/pdf' });
      downloadFile(blob, name);
      handleInvoiceActionsSuccess('Your request was successfully accepted');
    });
  };
  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setShowArchivedInvoices(true);
    } else {
      setShowArchivedInvoices(false);
    }
    setSearchText(event.target.value);
  };

  const handleToggleInvoiceModal = () => {
    setShowInvoiceModal(!showInvoiceModal);
  };

  const clearSelectedInvoiceId = () => setSelectedInvoiceId(0);

  const invoiceOnSuccess = () => {
    setShowInvoiceModal(false);
    clearSelectedInvoiceId();
    queryClient.invalidateQueries(['getInvoices']);
    const toastText = `The invoice was successfully ${
      selectedInvoiceId === 0 ? 'created' : 'edited'
    }`;
    toast.success(toastText, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleErrorToast = () => {
    toast.error('Something went wrong. Please, try again later.', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  const handleInvoiceActionsSuccess = (text: string) => {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  const invoiceOnError = () => {
    setShowInvoiceModal(false);
    handleErrorToast();
  };

  const handleArchiveAction = (isConfirmed: boolean) => {
    if (isConfirmed) {
      invoiceArchive(selectedInvoiceId, {
        onSuccess: () => {
          queryClient.invalidateQueries(['getInvoices']);
          handleInvoiceActionsSuccess('The invoice was successfully archived');
        },
        onError: () => {
          handleErrorToast();
        },
      });
    }
    setShowArchiveModall(false);
  };
  const handleUnarchiveAction = (isConfirmed: boolean) => {
    if (isConfirmed) {
      invoiceUnarchive(selectedInvoiceId, {
        onSuccess: () => {
          queryClient.invalidateQueries(['getInvoices']);
          handleInvoiceActionsSuccess(
            'The invoice was successfully unarchived'
          );
        },
        onError: () => {
          handleErrorToast();
        },
      });
    }
    setShowUnarchiveModall(false);
  };
  const handleDeleteActions = (isConfirmed: boolean) => {
    if (isConfirmed) {
      invoiceDelete(selectedInvoiceId, {
        onSuccess: () => {
          queryClient.invalidateQueries(['getInvoices']);
          handleInvoiceActionsSuccess('The invoice was successfully deleted');
        },
        onError: () => {
          handleErrorToast();
        },
      });
    }
    setShowDeleteModall(false);
  };
  const handleUploadPDFModal = () => {
    setShowUploadPDFModal(!showUploadPDFModal);
  };

  const handleEditInvoice = () => {
    setShowCommentsModal(false);
    setShowInvoiceModal(true);
  };
  const CommentOnClickHandler = (
    commentsStatus: CommentsStatus,
    invoiceStatus: InvoiceStatus,
    invoiceId: number
  ) => {
    if (commentsStatus === CommentsStatus.NONE) return;
    setShowCommentsModal(true);
    setIsCommentsEditMode(
      [InvoiceStatus.open, InvoiceStatus.editRequired].includes(invoiceStatus)
    );
    setSelectedInvoiceId(invoiceId);
  };

  if (isInvoiceLoading) {
    return (
      <InvoicesOverviewWrapper>
        <OverviewSkeleton />
      </InvoicesOverviewWrapper>
    );
  }

  if (FetchInvoicesSuccess && userSuccess) {
    const filteredInvoices: Invoice[] = invoiceData.invoices.filter(
      (invoice: Invoice) =>
        invoice.name
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())
    );

    const activeInvoices: Invoice[] = filteredInvoices.filter(
      (invoice: Invoice) => invoice.status !== InvoiceStatus.archived
    );
    const archivedInvoices: Invoice[] = filteredInvoices.filter(
      (invoice: Invoice) => invoice.status === InvoiceStatus.archived
    );

    const hasReviewInvoiceAccess = hasPermission(
      user,
      AccessFeatures.REVIEW_INVOICE
    );

    const invoiceTableRows = (invoices: Invoice[], userRole: UserRole) => {
      return invoices.map((invoice: Invoice) => {
        const actions = getActions(
          invoice.status,
          userRole,
          invoice.commentsStatus
        );

        return {
          id: invoice.id,
          link: '',
          relatedToUser: false,
          rows: [
            {
              columnId: 'name',
              content: (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={
                      invoice.status === InvoiceStatus.imported
                        ? invoiceImported
                        : invoiceDollar
                    }
                    alt={invoice.name}
                  />
                  <div
                    title={invoice.name}
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      marginLeft: 40,
                    }}
                  >
                    {invoice.name}
                  </div>
                </div>
              ),
              sortableContent: invoice.name,
            },
            {
              columnId: 'status',
              content: (
                <InvoiceStatusBadge invoiceStatusData={invoice.status} />
              ),
              sortableContent: invoice.status,
            },
            {
              columnId: 'amount',
              content: !!invoice.amount
                ? `${invoiceData.currencySymbol}${invoice.amount.toLocaleString(
                    'en-US'
                  )}`
                : '-',
              sortableContent: invoice.amount ? invoice.amount : 0,
            },
            {
              columnId: 'submitDate',
              content: <div>{invoice.submitDate}</div>,
              sortableContent: invoice.submitDate
                ? moment(invoice.submitDate, 'DD.MM.YYYY').unix().toString()
                : '',
            },
            {
              columnId: 'actions',
              content: (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  {invoice.status === InvoiceStatus.open &&
                  hasReviewInvoiceAccess ? (
                    <ActionsContainer>
                      <Button
                        onClick={() => {
                          setShowReviewInvoiceModal(true);
                          setSelectedInvoiceId(invoice.id);
                        }}
                        style={{ width: '80px', padding: '8px', margin: '0px' }}
                      >
                        Review
                      </Button>
                    </ActionsContainer>
                  ) : null}
                  {actions[AccessFeatures.COMMENT_INVOICE] && (
                    <ActionsContainer>
                      {invoice.commentsStatus === CommentsStatus.NONE ? (
                        <Tootltip
                          title={`No comments on this invoice.`}
                          placement="top"
                          size="x-sm"
                          textAlign="center"
                          titleStyle={{ fontSize: '12px' }}
                        >
                          <CommentIcon
                            cursor="default"
                            src={actions[AccessFeatures.COMMENT_INVOICE]}
                            alt="comment icon"
                            onClick={() => {
                              CommentOnClickHandler(
                                invoice.commentsStatus,
                                invoice.status,
                                invoice.id
                              );
                            }}
                          />
                        </Tootltip>
                      ) : (
                        <CommentIcon
                          cursor="pointer"
                          src={actions[AccessFeatures.COMMENT_INVOICE]}
                          alt="comment icon"
                          onClick={() => {
                            CommentOnClickHandler(
                              invoice.commentsStatus,
                              invoice.status,
                              invoice.id
                            );
                          }}
                        />
                      )}
                    </ActionsContainer>
                  )}
                  {actions[AccessFeatures.DOWNLOAD_INVOICE] ? (
                    <ActionsContainer>
                      <Icon
                        style={{ minWidth: '22px' }}
                        onClick={() => {
                          downloadPDFHandler(invoice.id);
                        }}
                        src={downloadBlack}
                        alt="download button"
                      />
                    </ActionsContainer>
                  ) : null}
                  {actions[AccessFeatures.EDIT_INVOICE] ? (
                    <ActionsContainer
                      onClick={() => {
                        setShowInvoiceModal(true);
                        setSelectedInvoiceId(invoice.id);
                      }}
                    >
                      <Icon
                        style={{ minWidth: '22px' }}
                        src={editBlack}
                        alt="edit button"
                      />
                    </ActionsContainer>
                  ) : null}
                  {actions[AccessFeatures.ARCHIVE_INVOICE] ? (
                    <ActionsContainer>
                      {invoice.status === 'archived' ? (
                        <Icon
                          style={{ minWidth: '22px' }}
                          onClick={() => {
                            setShowUnarchiveModall(true);
                            setSelectedInvoiceId(invoice.id);
                          }}
                          src={unarchiveInvoice}
                          alt="archive button"
                        />
                      ) : (
                        <Icon
                          style={{ minWidth: '22px' }}
                          onClick={() => {
                            setShowArchiveModall(true);
                            setSelectedInvoiceId(invoice.id);
                          }}
                          src={archiveBlack}
                          alt="archive button"
                        />
                      )}
                    </ActionsContainer>
                  ) : null}
                  {actions[AccessFeatures.DELETE_INVOICE] ? (
                    <ActionsContainer>
                      <Icon
                        onClick={() => {
                          setShowDeleteModall(true);
                          setSelectedInvoiceId(invoice.id);
                        }}
                        src={deleteBlack}
                        alt="delete button"
                      />
                    </ActionsContainer>
                  ) : null}
                </div>
              ),
            },
          ],
        };
      });
    };

    const showArchiveDataHandler = () => {
      return !!archivedInvoiceRows.length && showArchivedInvoices;
    };

    const activeInvoiceRows: OverviewListRow[] = invoiceTableRows(
      activeInvoices,
      user.role
    );
    const archivedInvoiceRows: OverviewListRow[] = invoiceTableRows(
      archivedInvoices,
      user.role
    );

    const activeInvoicesCount = activeInvoices.length;
    const archivedInvoicesCount = archivedInvoices.length;

    const hasAddInvoiceAccess = hasPermission(
      user,
      AccessFeatures.GENERATE_INVOICE
    );
    const hasUploadInvoiceAccess = hasPermission(
      user,
      AccessFeatures.UPLOAD_INVOICE
    );

    return (
      <>
        {!invoiceData.invoices.length && (
          <ContentContainer>
            <div
              style={{
                fontSize: '24px',
                color: theme.colors.grey[900],
                padding: '0 0 40px 0',
              }}
            >
              There are no Invoices right now
            </div>
            <div>
              {hasUploadInvoiceAccess && (
                <Button onClick={handleUploadPDFModal}>+ Upload PDF</Button>
              )}
              {hasAddInvoiceAccess && (
                <Button primary onClick={handleToggleInvoiceModal}>
                  + New Invoice
                </Button>
              )}
            </div>
          </ContentContainer>
        )}
        {!!invoiceData.invoices.length && (
          <InvoicesOverviewWrapper>
            <InvoicesOverviewHeader>
              <OverviewHeaderLeft>
                <InvoiceDataWrapper>
                  <LeftBoxHeader>
                    <InvoicesCounter>
                      {showArchivedInvoices
                        ? activeInvoicesCount + archivedInvoicesCount
                        : activeInvoicesCount}{' '}
                      INVOICE
                      {activeInvoicesCount !== 1 ? 's' : null}
                    </InvoicesCounter>
                    <SearchInput
                      sidenav={false}
                      onChange={onSearchTextChange}
                      placeholder="Search Invoices"
                    />
                  </LeftBoxHeader>
                  <div>
                    <InvoicesSum>
                      {showArchivedInvoices
                        ? invoiceData.summary.totalAmount
                        : invoiceData.summary.totalUnarchivedAmount}
                      {invoiceData.vat > 0
                        ? ` (All invoices are taxed with ${invoiceData.vat}% VAT.)`
                        : null}
                    </InvoicesSum>
                  </div>
                </InvoiceDataWrapper>
              </OverviewHeaderLeft>
              <OverviewHeaderLeft>
                <CheckboxWrapper>
                  <span>show archived</span>
                  <span
                    onClick={() =>
                      setShowArchivedInvoices(!showArchivedInvoices)
                    }
                  >
                    <Checkbox
                      isChecked={showArchivedInvoices}
                      isDisabled={false}
                      accentColor="#EEEEEE"
                      size="sm"
                    />
                  </span>
                </CheckboxWrapper>
                {hasAddInvoiceAccess && (
                  <AddInvoicesButton onClick={handleToggleInvoiceModal}>
                    + New Invoice
                  </AddInvoicesButton>
                )}
                {hasUploadInvoiceAccess && (
                  <AddInvoicesButton onClick={handleUploadPDFModal}>
                    + Upload PDF
                  </AddInvoicesButton>
                )}
              </OverviewHeaderLeft>
            </InvoicesOverviewHeader>
            <OverviewList
              height="80px"
              columns={[
                {
                  id: 'name',
                  name: 'Name',
                  width: 50,
                  ellipsize: true,
                  sortable: true,
                  columnWidth: '550px',
                },
                { id: 'status', name: 'Status', width: 10, sortable: true },
                { id: 'amount', name: 'Amount', width: 10, sortable: true },
                {
                  id: 'submitDate',
                  name: 'Submit Date',
                  width: 20,
                  sortable: true,
                },
                { id: 'actions', name: '', width: 50 },
              ]}
              blocks={
                showArchiveDataHandler()
                  ? [[...activeInvoiceRows, ...archivedInvoiceRows]]
                  : [[...activeInvoiceRows]]
              }
              gameCount={
                showArchiveDataHandler()
                  ? archivedInvoicesCount + activeInvoicesCount
                  : activeInvoicesCount
              }
              noDataText={'No active invoices'}
            />
          </InvoicesOverviewWrapper>
        )}
        <GenerateInvoiceModal
          vat={invoiceData.vat}
          currency={invoiceData.currencySymbol}
          visible={showInvoiceModal}
          onClose={() => {
            clearSelectedInvoiceId();
            handleToggleInvoiceModal();
          }}
          onSuccess={invoiceOnSuccess}
          onError={invoiceOnError}
          invoiceId={selectedInvoiceId === 0 ? undefined : selectedInvoiceId}
        />
        {showArchiveModal ? (
          <InfoModal>
            <ModalTitle>
              Are you sure you would like to archive this Invoice?
            </ModalTitle>
            <ButtonContainer>
              <ModalButton
                color={theme.colors.pink[500]}
                onClick={() => handleArchiveAction(true)}
              >
                Yes
              </ModalButton>
              <ModalButton
                color={'darkgray'}
                onClick={() => handleArchiveAction(false)}
              >
                No
              </ModalButton>
            </ButtonContainer>
          </InfoModal>
        ) : null}
        {showUnarchiveModal ? (
          <InfoModal>
            <ModalTitle>
              Are you sure you would like to unarchive this Invoice?
            </ModalTitle>
            <ButtonContainer>
              <ModalButton
                color={theme.colors.pink[500]}
                onClick={() => handleUnarchiveAction(true)}
              >
                Yes
              </ModalButton>
              <ModalButton
                color={'darkgray'}
                onClick={() => handleUnarchiveAction(false)}
              >
                No
              </ModalButton>
            </ButtonContainer>
          </InfoModal>
        ) : null}
        {showDeleteModal ? (
          <InfoModal>
            <ModalTitle>
              Are you sure you would like to delete this Invoice?
            </ModalTitle>
            <ButtonContainer>
              <ModalButton
                color={theme.colors.pink[500]}
                onClick={() => handleDeleteActions(true)}
              >
                Yes
              </ModalButton>
              <ModalButton
                color={'darkgray'}
                onClick={() => handleDeleteActions(false)}
              >
                No
              </ModalButton>
            </ButtonContainer>
          </InfoModal>
        ) : null}
        {showUploadPDFModal && (
          <InvoiceUpload
            onClose={handleUploadPDFModal}
            modalTitle="upload an invoice"
            descriptionText={''}
            studioId={studioId}
          />
        )}
        {showReviewInvoiceModal && (
          <InvoiceReviewModal
            vat={invoiceData.vat}
            invoiceId={selectedInvoiceId}
            downloadPDF={downloadPDFHandler}
            studioId={studioId}
            isOpen={showReviewInvoiceModal}
            onClose={onCloseReviewInvoiceModal}
            openApproveModal={handleOpenApproveModal}
            modalTitle="Review invoice"
          />
        )}
        {showApproveModal && (
          <ApproveModal
            isOpen={showApproveModal}
            invoiceId={selectedInvoiceId}
            downloadPDF={downloadPDFHandler}
            handleOpenReviewModal={handleOpenReviewModal}
            onClose={handleCloseApproveModal}
            modalType={approveModalText}
          />
        )}
        {showCommentsModal && (
          <CommentsModal
            isOpen={showCommentsModal}
            onClose={onCloseCommentsModal}
            downloadPDF={downloadPDFHandler}
            handleEditInvoice={handleEditInvoice}
            isEditMode={isCommentsEditMode}
            studioId={studioId}
            invoiceId={selectedInvoiceId}
          />
        )}
      </>
    );
  }
  return null;
};

export default InvoicesOverview;
