import { Styles } from 'react-modal';
import styled from 'styled-components';
import { ModalSize } from 'types';

const modalDimensions = {
  sm: { width: '600px', height: '330px' },
  sm_md: { width: '900px', height: '500px' },
  md: { width: '75%', height: '75%' },
  lg: { width: '90%', height: '90%' },
};

const modelStylesGenerator = (size: ModalSize): Styles => {
  return {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 'var(--z-index-modal-backdrop)',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '8px',
      border: 'none',
      backgroundColor: 'white',
      margin: 'auto',
      padding: '0px',
      width: modalDimensions[size].width,
      height: modalDimensions[size].height,
      zIndex: 'var(--z-index-modal)',
      overflow: 'hidden',
    },
  };
};

const headerHeight = '56px';

export const Header = styled.header`
  font-size: 20px;
  text-transform: uppercase;
  height: ${headerHeight};
  background-color: #ffeab1;
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  flex-grow: 1;
  padding: 16px;
`;

export const CloseIcon = styled.span`
  cursor: pointer;
  padding: 16px;

  svg {
    width: 24px;
  }
`;

export const Body = styled.section`
  position: relative;
  height: calc(100% - ${headerHeight});
  padding: 16px;
`;

export default modelStylesGenerator;
