import { SidebarWrapper } from 'assets/styles';
import CampaignSidebar from 'components/Sidebar/CampaignSidebar';
import GameSidebar from 'components/Sidebar/GameSidebar';
import StudioSidebar from 'components/Sidebar/StudioSidebar';
import { useSidebarRemoval } from 'hooks';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import { useTheme } from 'styled-components';

interface SidebarProps {
  type: 'studio' | 'game' | 'campaign';
  toggleSidebar: () => void;
}

const Sidebar = ({ type, toggleSidebar }: SidebarProps) => {
  const theme = useTheme();
  const [isRemoving, handleRemove] = useSidebarRemoval(toggleSidebar);

  return (
    <SidebarWrapper isRemoving={isRemoving}>
      {type === 'studio' && <StudioSidebar />}
      {type === 'game' && <GameSidebar />}
      {type === 'campaign' && <CampaignSidebar />}
      <button className="collapse-button" onClick={handleRemove}>
        <MdOutlineKeyboardDoubleArrowLeft
          color={theme.colors.blue[500]}
          size={14}
        />
      </button>
    </SidebarWrapper>
  );
};

export default Sidebar;
