export enum BusinessChartType {
  ARPI = 'arpi',
  PLAYTIME = 'playtime',
  PLAYTIME_PER_AVG_USER = 'playtime_per_avg_user',
  SUNDAY_RETENTION = 'sunday_retention',
  AD_IMPRESSIONS_PER_AVG_USER = 'ad_impressions_per_avg_user',
  LEVEL_PROGRESSION = 'LEVEL_PROGRESSION',
}

export interface ChartData {
  [key: string]: number;
}

type Numbers =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13';

export type CohortTableDays = {
  [key in `day${Numbers}`]: number | undefined | null;
};

export interface CohortTableRow extends CohortTableDays {
  installDate: string;
  cohortSize: number;
}

export interface AdImpressions {
  adRewardedSuccess: ChartData;
  adInterstitialSuccess: ChartData;
  interstitialRewardedTotal: ChartData;
}

export interface LevelProgression {
  event: string;
  usersOfStepOne: number;
}

export interface ChurnRatePerLevel {
  event: string;
  churnRate: number;
}
