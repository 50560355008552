import { spacing, Spacing } from 'assets/styles';
import styled from 'styled-components';

interface StoreLinkProps extends Spacing {
  iconSrc: string;
  iconAlt: string;
  linkUrl: string;
}

const StoreLink = ({
  iconSrc,
  iconAlt,
  linkUrl,
  ...spacing
}: StoreLinkProps) => (
  <StyledStoreLink href={linkUrl} target="_blank" {...spacing}>
    <img src={iconSrc} alt={iconAlt} />
  </StyledStoreLink>
);

export default StoreLink;

const StyledStoreLink = styled.a<Spacing>`
  flex-shrink: 0;
  display: flex;
  ${spacing}
`;
