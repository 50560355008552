import { PackMediaObjType } from 'types';
import {
  StyledSelectOption,
  SyledCreativePackSelector,
} from './CreativePackSelector.styles';
import Select, { CSSObjectWithLabel, SingleValue, Theme } from 'react-select';
import clsx from 'clsx';

const SelectTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#B4B4B4',
  },
});

const SelectStyles = {
  container: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    minWidth: '100%',
    fontSize: 14,
  }),
  valueContainer: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    '& img': {
      height: 32,
      width: 32,
      borderRadius: 2,
      marginRight: 5,
    },
  }),
  option: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: 'black',
  }),
};

type SelectOptionType = SingleValue<{ value: number; label: JSX.Element }>;

interface CreativePackSelectorProps {
  disabled: boolean;
  onMediaChange: (type: string, packMediaId: number) => void;
  packMedias: PackMediaObjType[];
}

const CreativePackSelector = ({
  disabled,
  packMedias,
  onMediaChange,
}: CreativePackSelectorProps) => {
  const onOptionChange = (newValue: SelectOptionType | null) => {
    const type = newValue === null ? 'PACK_UNSELECTED' : 'PACK_SELECTED';
    onMediaChange(type, newValue?.value ?? 0);
  };

  const selectOptions: SelectOptionType[] = packMedias.map(
    (packMedia: PackMediaObjType) => ({
      value: packMedia.mediaId,
      label: (
        <StyledSelectOption>
          <img
            className="thumbnail"
            src={packMedia.mediaThumbnail}
            height="32"
            width="32"
            alt=""
          />
          <span>{packMedia.creativePackName}</span>
        </StyledSelectOption>
      ),
    })
  );
  return (
    <SyledCreativePackSelector className={clsx({ disabled })}>
      <span className="title">Select from existing packs</span>
      <Select
        theme={SelectTheme}
        styles={SelectStyles}
        options={selectOptions}
        isClearable={true}
        onChange={onOptionChange}
      />
    </SyledCreativePackSelector>
  );
};

export default CreativePackSelector;
