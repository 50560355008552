import styled from 'styled-components';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  fetchMediaFilesRequest,
  MediaFilesByDate,
} from 'services/api/MediaService';
import { GameCounter } from 'features/GamesOverview/GamesOverview.styles';
import {
  ContentContainer,
  ContentContainerWithPreview,
  ContentHeaderContainer,
  getMediaGroupCount,
  MediaCategoryTitle,
  MediaContainer,
  PreviewContainer,
  ThumbnailContainer,
  VideoPreview,
  VideoTitle,
} from './MediaOverview';
import { ErrorPopup, VideoContainer } from 'components/atoms';
import { VideoThumbnail } from 'components/molecules';
import { useParams } from 'react-router-dom';
import { VideoFile } from 'types';

interface TestMediaSelectionProps {
  media: VideoFile[];
  setMedia: Dispatch<SetStateAction<VideoFile[]>>;
}

export const TestMediaSelection = ({
  media,
  setMedia,
}: TestMediaSelectionProps) => {
  const { gameId } = useParams<{ gameId: string }>();
  const [videos, setVideos] = useState<MediaFilesByDate>({});
  const [mediaGroupCount, setMediaGroupCount] = useState<number>(0);
  const [preview, setPreview] = useState<VideoFile | null>(null);
  const [maxVideosReached, setMaxVideosReached] = useState(false);

  const libraryRef = useRef<HTMLDivElement>(null);

  const addMedia = (video: VideoFile) => {
    if (media.length === 4) {
      setMaxVideosReached(true);
      return;
    }
    if (media.findIndex(v => v.id === video.id) === -1) {
      setMedia([...media, video]);
    }
  };

  const removeMedia = (id: number) => {
    setMedia([...media.filter(v => v.id !== id)]);
  };

  useEffect(() => {
    if (gameId) {
      fetchMediaFilesRequest({ gameId: Number(gameId) }).then(data => {
        let count = getMediaGroupCount(data);
        setMediaGroupCount(count);
        setVideos(data);
      });
    }
  }, [gameId]);

  const mediaByDate = {
    today: '-- Today --',
    'last 7 days': '-- Last Week --',
    'last 30 days': '-- Last Month --',
    old: '-- Older --',
  };

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <ContentContainerWithPreview>
        <ContentContainer style={{ width: 'calc(100% - 320px)' }}>
          <ContentHeaderContainer>
            <GameCounter>
              {media.length} Video{media.length !== 1 ? 's' : null}
            </GameCounter>
          </ContentHeaderContainer>
          <MediaContainer
            style={{
              height: '230px',
              display: 'flex',
              padding: '10px',
              maxWidth: '100%',
            }}
          >
            {media.map((video: VideoFile) => {
              return (
                <VideoContainer
                  key={video.id}
                  remove={() => removeMedia(video.id)}
                >
                  <VideoThumbnail
                    onClick={() => setPreview(video)}
                    thumbnail={video.thumbnail}
                    ipm={video.ipm}
                  />
                  <VideoTitle title={video.name}>{video.name}</VideoTitle>
                </VideoContainer>
              );
            })}
          </MediaContainer>
        </ContentContainer>
        {preview && (
          <PreviewContainer key={preview.id}>
            <VideoPreview controls={true}>
              <source src={preview.url} />
            </VideoPreview>
          </PreviewContainer>
        )}
      </ContentContainerWithPreview>
      <BottomContentContainer>
        <ContentHeaderContainer>
          <GameCounter ref={libraryRef} style={{ marginLeft: '48px' }}>
            Library
          </GameCounter>
          <ErrorPopup
            visible={maxVideosReached}
            anchor={libraryRef}
            onClose={() => setMaxVideosReached(false)}
            containerStyle={{ margin: '-24px 0 0 24px' }}
            title="Limit reached"
          >
            <div style={{ fontSize: '12px', marginTop: '12px' }}>
              You can attach a maximum of 4 videos.
            </div>
          </ErrorPopup>
        </ContentHeaderContainer>
        <MediaContainer
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            minHeight: '210px',
            flexWrap: 'wrap',
            overflow: 'scroll',
            maxWidth: '100%',
          }}
        >
          {Object.keys(mediaByDate).map((key: string, index) => {
            return (
              !!videos[key as keyof typeof mediaByDate]?.length && (
                <div key={index}>
                  {mediaGroupCount > 1 ? (
                    <MediaCategoryTitle>
                      {mediaByDate[key as keyof typeof mediaByDate]}
                    </MediaCategoryTitle>
                  ) : null}
                  <ThumbnailContainer>
                    {videos[key as keyof typeof mediaByDate]?.map(
                      (video: any) => {
                        return (
                          <VideoContainer key={video.id}>
                            <VideoThumbnail
                              onClick={() => addMedia(video)}
                              thumbnail={video.thumbnail}
                              ipm={video.ipm}
                            />
                            <VideoTitle title={video.name}>
                              {video.name}
                            </VideoTitle>
                          </VideoContainer>
                        );
                      }
                    )}
                  </ThumbnailContainer>
                </div>
              )
            );
          })}
        </MediaContainer>
      </BottomContentContainer>
    </div>
  );
};

const BottomContentContainer = styled.div`
  margin: 42px;
`;
