import {
  BigLogoBoxWrapper,
  Button,
  CenteredWrapper,
  PrivacyPolicyWrapper,
  StyledWrapper,
} from './Login.styles';
import { useState } from 'react';
import BigLogoBox from 'components/BigLogoBox/BigLogoBox';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import LoginErrorBox from 'components/LoginErrorBox/LoginErrorBox';
import { PrivacyPolicyModal } from 'components/organisms/PrivacyPolicyModal';
import { authenticate, getPrevLocation, setToken } from 'services/api';
import { DefaultATag } from 'assets/styles';
import { useDocumentTitle } from 'hooks';
import { isStaging } from "../../utils";

const Login = () => {
  useDocumentTitle();
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSuccess = async (
    response: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>
  ) => {
    if ('code' in response) {
      try {
        const { data } = await authenticate({ code: response.code });
        setToken({
          token: data?.token,
          refreshToken: data?.refreshToken,
        });

        const prevLocation = getPrevLocation() ?? '/home';
        navigate(prevLocation, { replace: true });
      } catch (error: any) {
        setError(error.response.data.message);
        navigate('/login', { replace: true });
      }
    } else {
      setError('Received unexpected structure from oAuth');
    }
  };

  const onError = (
    error: Pick<CodeResponse, 'error' | 'error_description' | 'error_uri'>
  ) => {
    setError(error.error_description ?? '');
  };

  const googleLogin = useGoogleLogin({
    onSuccess,
    onError,
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/admob.readonly'
  });

  const toggleModal = () => setShowModal(!showModal);
  const showEnvVars = localStorage.getItem('SpectraScope') === 'true';

  return (
    <div
      className="body-login"
      style={{
        backgroundImage: 'url(/assets/img_login_bkg.svg)',
      }}
      data-testid="login-container"
    >
      <CenteredWrapper>
        <StyledWrapper>
          <BigLogoBoxWrapper>
            <BigLogoBox />
          </BigLogoBoxWrapper>
          <BigLogoBoxWrapper>
            <Button onClick={googleLogin}>Login with Google</Button>
            <PrivacyPolicyWrapper>
              By continuing to use{' '}
              <DefaultATag href="https://sundash.sunday.gg/">
                Sundash
              </DefaultATag>
              , you agree to our{' '}
              <DefaultATag
                href="#/"
                onClick={e => {
                  e.preventDefault();
                  if (e.metaKey || e.ctrlKey) {
                    window.open('/privacy-policy', '_blank');
                  } else {
                    toggleModal();
                  }
                }}
              >
                privacy policy
              </DefaultATag>
              .
            </PrivacyPolicyWrapper>
          </BigLogoBoxWrapper>
          <LoginErrorBox error={error} />
        </StyledWrapper>
      </CenteredWrapper>
      <PrivacyPolicyModal visible={showModal} onClose={toggleModal} />
      {showEnvVars && isStaging() && (
          <CenteredWrapper>
            <StyledWrapper>
              <div>
                <h3>Local Environment Variables</h3>
                <p>
                  <b>REACT_APP_ENV:</b> {process.env.REACT_APP_ENV}
                </p>
                <p>
                  <b>Google Client ID:</b> "{process.env.REACT_APP_GOOGLE_CLIENT_ID}"
                </p>
              </div>
            </StyledWrapper>
          </CenteredWrapper>
      )}
    </div>
  );
};

export default Login;
