import styled from 'styled-components';

export const ChartContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.white};
  padding: 40px;
  margin-bottom: 36px;
`;

export const ChartInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.grey.black};
  font-family: 'Rubik', sans-serif;
  font-size: 32px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 48px;

  .title-container {
    display: flex;
    flex-direction: column;
  }
`;

export const ChartInnerContainer = styled.div`
  min-height: 300px;
  display: flex;
  align-items: center;
  position: relative;
`;
