import { useEffect, useState } from 'react';
import { InvoiceItem, InvoiceItemWithId } from 'types';

let idCounter = 0;

export const useInvoiceItems = (initialItems: InvoiceItem[] = []) => {
  const [isItemsChanged, setIsItemsChanged] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItemWithId[]>([]);
  const [subTotal, setSubTotal] = useState<number>();

  const addInvoiceItem = (item: InvoiceItem) => {
    const newItem: InvoiceItemWithId = {
      ...item,
      id: idCounter++,
    };
    setInvoiceItems(prevItems => [...prevItems, newItem]);
    setIsItemsChanged(true);
  };

  const addInvoiceItemsList = (items: InvoiceItem[]) => {
    const newItems: InvoiceItemWithId[] = items.map(item => ({
      ...item,
      id: idCounter++,
    }));
    setInvoiceItems(prevItems => [...prevItems, ...newItems]);
  };

  const removeInvoiceItem = (id: number) => {
    setInvoiceItems(prevItems => prevItems.filter(item => item.id !== id));
    setIsItemsChanged(true);
  };

  useEffect(() => {
    const initializedItems = initialItems.map(item => ({
      ...item,
      id: idCounter++,
    }));
    setInvoiceItems(initializedItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const totalPrice = invoiceItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setSubTotal(totalPrice);
  }, [invoiceItems]);

  return {
    isItemsChanged,
    invoiceItems,
    subTotal,
    addInvoiceItem,
    addInvoiceItemsList,
    removeInvoiceItem,
  };
};
