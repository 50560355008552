import { useState, useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container } from 'assets/styles';
import { studioLinkGenerator, useFetchStudio } from 'api';
import { useDocumentTitle } from 'hooks';
import {
  Studio,
  StudioTabs,
  preSelectedStudioTab,
  StudioLinkGenerator,
  StudioParams,
} from 'types';
import TabHandler from 'components/TabHandler/TabHandler';
import { GamesOverview, InvoicesOverview } from 'features';
import { StudioHeader, StudioHeaderSkeleton } from 'components';

function StudioPage() {
  const { pathname } = useLocation();
  const params = useParams<StudioParams>();
  const { updateDocumentTitle } = useDocumentTitle();
  const [selectedTab, setSelectedTab] =
    useState<StudioTabs>(preSelectedStudioTab);

  const isTabNameValid = (
    ValidationSelectedTab: StudioTabs | undefined
  ): boolean =>
    ValidationSelectedTab &&
    (ValidationSelectedTab === StudioTabs.games ||
      ValidationSelectedTab === StudioTabs.invoices)
      ? true
      : false;

  const redirectToUrl = useCallback(
    ({ studioId, tabName }: StudioLinkGenerator) => {
      const studioLink = studioLinkGenerator({
        studioId,
        ...(tabName && { tabName }),
      });
      return studioLink;
    },
    []
  );
  const studioId = Number(params.studioId);
  const handleSuccess = (studio: Studio) => updateDocumentTitle(studio.name);

  const useFetchStudioQuery = useFetchStudio(studioId, {
    onSuccess: handleSuccess,
  });
  const { data: studio } = useFetchStudioQuery;

  const handleInvoiceAccess = () => {
    return studio?.hasPaymentDetails && studio?.invoiceAccess;
  };
  useEffect(() => {
    if (isTabNameValid(params.tabName)) {
      params.tabName === StudioTabs.invoices && studio?.invoiceAccess
        ? setSelectedTab(StudioTabs.invoices)
        : setSelectedTab(StudioTabs.games);
    } else {
      setSelectedTab(StudioTabs.games);
      redirectToUrl({ studioId });
    }
  }, [params, studioId, redirectToUrl, pathname, studio?.invoiceAccess]);

  if (useFetchStudioQuery.isLoading) {
    return <StudioHeaderSkeleton />;
  }

  if (useFetchStudioQuery.isSuccess) {
    const { data: studio } = useFetchStudioQuery;
    const tabData = [
      {
        tabName: StudioTabs.games,
        redirectUrl: redirectToUrl({
          studioId: studio.id,
          tabName: StudioTabs.games,
        }),
        selectedTab,
        component: () => (
          <GamesOverview
            studioId={studio.id}
            isStudioArchived={studio.archived}
          />
        ),
      },
      {
        tabName: StudioTabs.invoices,
        redirectUrl: redirectToUrl({
          studioId: studio.id,
          tabName: StudioTabs.invoices,
        }),
        selectedTab,
        component: () => <InvoicesOverview studioId={studio.id} />,
      },
    ];

    const filteredTabData = tabData.filter(tab => {
      if (tab.tabName === StudioTabs.invoices) {
        return handleInvoiceAccess();
      }
      return true;
    });

    return (
      <Container>
        <StudioHeader studio={studio} />
        <TabHandler tabData={filteredTabData} />
      </Container>
    );
  }

  return null;
}

export default StudioPage;
