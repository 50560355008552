import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  line-height: 1;
`;
