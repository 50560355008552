import { useFetchCurrency } from 'api/modules/useCurrency';
import Select from 'react-select';
import { useStudioSelectStyles } from '../AddStudioModal.styles';

export const SelectCurrency = ({
  field: { onChange, value },
}: {
  field: { onChange: any; value: any };
}) => {
  const { data: currencies } = useFetchCurrency();
  const selectStyles = useStudioSelectStyles();
  return (
    <Select
      styles={selectStyles}
      options={currencies}
      placeholder="Choose a currency..."
      value={currencies?.find(cur => cur.value === value)}
      onChange={entry => onChange(entry?.value)}
      isSearchable={false}
    />
  );
};
