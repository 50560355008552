export const PrivacyPolicy = `
<h1>Data Privacy Statement</h1>

<p>Thank you for your interest in our services and your visit to our website.</p>

<h4>Data Privacy on the Webpages of Sunday GmbH</h4>

<p>Data privacy and data security during the use of our website are a matter of course for us. Therefore, we would like to inform you below about which of your personal data we collect while you visit our website and for what purposes they are used.</p>
<p>This Data Privacy Statement applies for the internet offering of Sunday GmbH, which can be seen on the website accessible at the following domain: https://sundash.sunday.gg/ (hereinafter referred to as “our website”).</p>
<p>This Data Privacy Statement can be accessed, stored and printed at any time via the following URL:  https://sundash.sunday.gg/privacy-policy/</p>
<p>As legislative changes or changes to our internal company processes could make an adjustment of this Data Privacy Statement necessary, please read through this Data Privacy Statement regularly.</p>

<h4>Data Controller</h4>

<p>Sunday GmbH, An der Alster 42, 20099 Hamburg (you will find exact contact details in the legal notice at https://sunday.gg/imprint/) is the “controller” in accordance with the applicable data protection law, in particular the EU General Data Protection Regulation (GDPR), for the processing of personal data in these webpages.</p>

<h4>Personal Data</h4>

<p>Personal data is information that may be used to find out personal or factual characteristics about you (e.g. name, address, telephone number, date of birth or email address). Information with which we cannot create a link (or can only do so with disproportionate effort) to you personally, e.g. via anonymisation of information, is not personal data.</p>
<p>What Personal Data is Collected and Processed by Us?</p>
<p>You can visit our webpages without informing us about who you are and without stating to us any corresponding personal data (e.g. names, address, telephone number or email address), unless you provide them to us voluntarily or the corresponding legal provisions on the protection of your data allow this.</p>

<h4>Purpose</h4>

<p>We will collect, process and use the personal data provided by you online only for the purposes stated to you.</p>
<p>Some data that we may collect about you is necessary in order for us to:</p>
<ul>
<li>provide you with the services you desire;</li>
<li>perform our contracts with you;</li>
<li>comply with legal provisions (e.g. billing).</li>
</ul>

<p>If we directly collect data from you, we may ask for your permission and clearly label mandatory information (e.g. with a star [*]). Any other details without a star will be provided by you voluntarily.</p>

<h4>Legal Basis</h4>

<p>The legal basis for the processing of your data may be the following:</p>
<ul>
<li>your consent to data use in accordance with Art. 6(1)(a) GDPR;</li>
<li>the fulfillment of our service obligations resulting from the contracts concluded with you, in order to provide you with the desired services, in accordance with Art. 6(1)(b) GDPR;</li>
<li>our legitimate interest in accordance with Art. 6(1)(f) GDPR, e.g.
  <ul>
    <li>our commercial interests in the improvement of our services, so that we can better understand your needs and expectations and therefore improve our services for you;</li>
    <li>for the prevention of fraud and to guarantee that the use of our webpages takes place completely and without fraudulent conduct;</li>
    <li>in order to guarantee the security of our services and ensure that our offering is technically safe and works properly;</li>
    <li>to secure and implement our contractual entitlements and claims.</li>
  </ul>
</li>
<li>Legal bases in accordance with Art. 6(1)(c) GDPR, if the collection, storage, transfer or other processing of the data is legally prescribed or necessary for the processing, in order to fulfill our statutory obligations.</li>
</ul>

<h4>Forwarding of Data</h4>

<p>We will neither sell nor market your personal data to third parties, nor will we pass it on for other reasons, if this is not listed within this Data Privacy Statement. The passing on of your personal data without your explicit prior permission will only take place in the following cases, in addition to the other cases named in this Data Privacy Statement:</p>
<ul>
<li>If necessary for the resolution of unlawful use or misuse of our webpages or for prosecution, personal data will be passed on to the criminal prosecution authorities and, if necessary, to third parties who have been harmed. However, this will only take place if there are specific indications of unlawful conduct or misuse. We are also legally obliged to provide information to certain public bodies upon request. These are criminal prosecution authorities, authorities that pursue administrative offenses punishable by a financial penalty, and the financial authorities.</li>
<li>Forwarding to third parties bound to professional secrecy can only take place if this is necessary for the implementation of the contractual terms and conditions or other agreements, and our claims from contracts that you have concluded with us.</li>
<li>For the provision of our service, we are occasionally reliant on contractually bound third-party companies and external service providers, e.g. for the hosting. In such cases, information will be passed on to these companies or individual people in order to enable further processing. These external service providers are carefully selected by us and checked regularly, to ensure that your privacy remains protected, and they may only process the data for the purposes specified by us. They are also contractually obliged by us to only handle your data exclusively in accordance with this Data Privacy Statement and the German data privacy laws.</li>
<li>As part of the further development of our business, the structure of our company may change, in that the legal form is changed, or subsidiary companies, business units or components are founded, purchased or sold. In the event of such transactions, the customer information will be passed on, together with the part of the company to be transferred, with your consent. During any transfer of personal data to third parties to the specified extent, we will ensure that the further use takes place in accordance with this Data Privacy Statement and the relevant data protection laws, and we will ask for your permission.</li>
</ul>

<h4>Use of Cookies</h4>

<p>We do not create personal user profiles. In connection with the displaying of information requested by you, data is only stored on our servers in anonymised form, for the provision of our various services or for analysis purposes. General information is hereby logged, e.g. which content of our offering is accessed and when, and what pages are visited the most frequently. For these purposes, we use so-called “cookies” (small text files with configuration information). The cookies used serve in particular to determine the frequency of use and the number of users of our websites. This enables us to find out which parts of our websites and which other websites our visitors have visited.</p>
<p>However, these use data cannot be traced back to the users. None of this anonymously compiled use data will be matched with your personal data, and it will be promptly erased after the end of the statistical analysis.</p>
<p>The legal bases for this data processing are Art. 6(1)(f) GDPR (which permits the processing of data for the protection of legitimate interests of the data controller) and Art. 6(1)(a) GDPR (which permits data processing based on your consent).</p>
<p>Most browsers are configured so that they automatically accept cookies. However, you can deactivate the storage of cookies or set your browser so that it notifies you before cookies are stored. Users who do not accept cookies may not be able to access certain parts of our website.</p>
<p>You can prevent the storage of cookies by setting your browser software accordingly; however, we would like to point out to you that in this case, you may not be able to use all functions of this website to their full extent.</p>

<h4>Server Log Files</h4>

<p>Your visit to our websites will be automatically logged by our web servers.</p>
<p>In connection with the information requested by you from our websites, data is hereby logged for the provision of our various services or for analysis and security purposes, and may be stored in anonymous form (non-personalised). The web server used by us automatically stores data about the displaying of our webpages in so-called server log files. This data is as follows:</p>
<ul>
<p>IP address</p>
<p>Referrer URL (the site from which you visit us)</p>
<p>Time of server request</p>
<p>Host name of the accessing end device (the name of your internet service provider)</p>
<p>Browser type and browser version</p>
<p>Operating system used and its settings</p>
</ul>

<p>The processing of the above-named data hereby takes place for security purposes, for general fraud prevention and as a precaution against attacks on our websites. An automated amalgamation of this data with data from other data sources does not take place.</p>
<p>If your IP address is automatically logged, it will be automatically deleted after 30 days at the latest. Otherwise, only general information is logged, e.g. when which content of our offering is accessed and what pages are visited the most frequently, the names of the requested files and their dates and times of access. This data is used to improve our services and does not enable matches to be made with you personally.</p>
<p>We will not use this information for any other purposes.</p>
<p>The legal basis for the data processing is Art. 6(1)(f) GDPR, which allows the processing of data to protect legitimate interests of the data controller.</p>

<h6>Data Storage</h6>

<p>We only store personal data that you send to us for as long as we require it, in order to fulfill the purposes for which this data has been sent, or as long as this is stipulated by law:</p>
<ul>
<li>If you conclude contracts with us, we will store and process your personal data for the duration of the contract and beyond for the fulfillment of your post-contractual obligations and matters, and for the duration of the statutory retention periods (maximum 10 years).</li>
<li>If you have agreed to the use of your email address for marketing purposes, we will store your email address within our mailing database until you de-register or request that we erase the data.</li>
<li>If you send us a query, we will process your personal data for the duration of the processing of your query.</li>
</ul>

<p>When we no longer require your personal data, we will erase it from our systems and records, or anonymise it so that it can no longer be identified.</p>
<p>We can retain certain personal data in order to comply with our statutory and regulatory obligations, and to enable us to manage our rights (e.g. the assertion of our rights in court), or for statistical purposes (in anonymised form).</p>

<h4>Data Security</h4>

<p>For the secure transmission of your personal data, we use so-called SSL encryption. This form of transfer is recognised as a secure form of data transfer based on our current knowledge. We endeavor to take technical and organizational security measures to protect your personal data against unintentional or unlawful erasure, changes or loss, and against unauthorized forwarding or unauthorized access. Our employees are accordingly obliged to maintain secrecy and data privacy.</p>
<p>In order to prevent the loss or misuse of the data stored by us, we take comprehensive technical and organizational safety precautions, which are regularly reviewed and adjusted to meet technological advances. If it is within our sphere of influence, we use in particular modern encryption techniques as well as a variety of other measures to prevent third parties from unauthorized information access. You will recognise an encrypted connection when the address bar of the browser changes from “http://” to “https://” and you will see a lock symbol in your browser bar. If there is an SSL or TLS encryption, the data you exchange with us cannot be seen by third parties.</p>
<p>However, we would like to point out to you that due to the structure of the internet, it is possible that the data protection regulations and the above-named security measures of other persons or institutions not within our area of responsibility cannot be observed. In particular, data passed on in a non-encrypted form can be read by third parties, even if it is sent via email. We have no technical influence on this occurrence. In these cases, it is the responsibility of the user to protect the data it provides against misuse, by encryption or other methods.</p>

<h4>Contact</h4>

<p>Should you have any questions or comments about the handling or use of your personal data, or should you require information about the personal data stored about you, or should you wish to exercise your other rights named below, please contact us at:</p>

<address>
Sunday GmbH
An der Alster 42
20099 Hamburg
Tel: 040 – 37 03 – 5440

Email: contact@sunday.gg
</address>

<p>You can also use the contact details for other general queries and contact requests.</p>
<p>Your contact details will hereby be collected and processed by us. You can generally decide yourself which data you provide to us when contacting us. In this case, we will only use your data to respond to your query.</p>
<p>The processing of this data takes place based on your permission, in order to fulfill statutory obligations and to safeguard legitimate interests of Sunday GmbH as the provider of these websites.</p>

<h4>Your Rights as a Data Subject</h4>

<p>As a data subject in the data processing, you have the following rights listed in this section.</p>
<p>If you would like to exercise one of your rights named below, please contact us using the contact details named in the following “Contact” section.</p>
<p>Please note that we may request proof of your identity and extensive information about your query before we can process it.</p>

<h4>Information, Restriction of Processing and Erasure</h4>

<p>Within the framework of the applicable statutory provisions, you have the right at any time to obtain free information about the data stored about you personally, its origin and recipients, and the purpose of the data processing. On presentation of the respective prerequisites, you may also have the right to the rectification of incorrect data, the restriction of the processing, and the erasure of data.</p>

<h4>Withdrawal of Your Consent to Data Processing</h4>

<p>Some forms of data processing are only possible on the basis of your explicit consent. You can withdraw consent already given at any time. The legitimacy of the data processing carried out until the withdrawal will remain unaffected by the withdrawal.</p>

<h4>Right to Data Portability</h4>

<p>Regarding the data that we process automatically on the basis of your consent or in the performance of a contract, you generally have the right to access it yourself or have it provided to a third party in a customary, machine-readable format. If you require the direct transfer of this data to a third party, this will only take place if this is technically possible with reasonable effort.</p>

<h4>Right to Lodge a Complaint with the Competent Supervisory Authority</h4>

<p>In the event of data privacy violations caused by us, you have the right to lodge a complaint with the competent supervisory authority. The supervisory authority responsible for Sunday GmbH in data protection law matters is the Hamburgische Beauftragte für Datenschutz und Informationsfreiheit (HmbBfDI), the contact details of which can be found in the following link: https://www.datenschutz-hamburg.de/wir-ueber-uns-kontakt/wie-erreichen-sie-uns.html.</p>

<p>Operational Data Protection Officer of Sunday GmbH</p>

<p>As legally stipulated, we have appointed a data protection officer for our company:</p>

<address>
Mr Rechtsanwalt Stephan Krämer, LL.M.
KINAST Rechtsanwaltsgesellschaft mbH
Hohenzollernring 54
D-50672 Köln
</address>

<p>You can contact our data protection officer via its website at http://www.kinast-partner.de.</p>

<h4>Hyperlinks to Other Websites</h4>

<p>Our website contains so-called hyperlinks to websites of other providers. When activating these hyperlinks, you will be forwarded directly from our website to the websites of the other providers. Regarding these links to external companies and other third parties, Sunday GmbH is not responsible for the data privacy requirements or the content of these websites.</p>
<p>Version: July 2022</p>
`;
