import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const NoDataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 96px;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.grey[900]};
  font-family: 'Rubik', sans-serif;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 24px 0;

  button {
    margin: 0;
    border-radius: 0;
    font-size: 15px;
    padding: 7px 14px;

    :first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: 0;
    }

    :last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: 0;
    }
  }
`;

interface StylesProps {
  padding?: number;
}

export const ModalTitle = styled.div<StylesProps>`
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;

  .app-info-column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${(props: StylesProps) => props.padding + 'px'};

    .top-column {
      margin-bottom: ${(props: StylesProps) => props.padding + 'px'};
    }
    .bottom-column {
      color: ${({ theme }) => theme.colors.grey[900]};
    }
  }
`;

export const ButtonContainer = styled.div`
  min-height: 70px;
  background-color: ${({ theme }) => theme.colors.grey.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DefaultATag = styled.a`
  text-decoration: underline;
  color: blue;
`;

export const Icon = styled.img`
  cursor: pointer;
`;

