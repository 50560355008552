import { chartTitleGenerator } from 'pages/Test/Charts/utils/GlobalUtilsTsx';
import {
  ChartContainer,
  ChartInfoContainer,
  ChartInnerContainer,
} from '../CompareChart.styles';
import { chartOptions, formatAppVersion } from '../CompareChart.utils';
import { Line } from 'react-chartjs-2';
import {
  cohortIndexArrayGenerator,
  cohortLabelsGenerator,
  getChartTitleAndTooltip,
} from 'pages/Test/Charts/utils/GlobalUtilsTs';
import { BusinessChartType } from 'types';
import { Colors } from './AvgPlayTimeUserCompare.utils';
import { useCampaignAndAppVersionParams } from 'hooks';

const startingIdx = 0;

interface AvgPlayTimeUserCompareProps {
  dataOne: number[];
  dataTwo: number[];
}

const AvgPlayTimeUserCompare: React.FC<AvgPlayTimeUserCompareProps> = ({
  dataOne,
  dataTwo,
}) => {
  const { campaignName1, campaignName2, appVersion1, appVersion2 } =
    useCampaignAndAppVersionParams();
  const numOfCols = Math.max(dataOne.length, dataTwo.length);

  const labels = cohortLabelsGenerator(
    cohortIndexArrayGenerator(numOfCols, startingIdx),
    'Day '
  ).slice(0, 14);

  const datasets = [
    {
      backgroundColor: Colors.campaignOne,
      borderColor: Colors.campaignOne,
      label: `${campaignName1} - ${formatAppVersion(appVersion1)}`,
      data: dataOne.slice(0, 14),
    },
    {
      backgroundColor: Colors.campaignTwo,
      borderColor: Colors.campaignTwo,
      label: `${campaignName2} - ${appVersion2}`,
      data: dataTwo.slice(0, 14),
    },
  ];

  const { title, tooltip } = getChartTitleAndTooltip(
    BusinessChartType.PLAYTIME_PER_AVG_USER
  );
  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
      </ChartInfoContainer>
      <ChartInnerContainer>
        <Line options={chartOptions} data={{ labels, datasets }} height={400} />
      </ChartInnerContainer>
    </ChartContainer>
  );
};

export default AvgPlayTimeUserCompare;
