import { Chart } from 'react-chartjs-2';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index' as 'index',
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        boxWidth: 40,
        usePointStyle: true,
        pointStyle: 'circle',
      },
    },
  },
  scales: {
    xAxes: {
      offset: true,
      grid: {
        drawBorder: false,
        lineWidth: 0,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
      },
    },
  },
};

export const MultipleCharts = ({
  chartData,
  customScaleOptions = {},
}: {
  chartData: any;
  customScaleOptions?: object;
}) => {
  return (
    <Chart
      type="bar"
      options={{
        ...options,
        scales: { ...options.scales, ...customScaleOptions },
      }}
      data={chartData}
      height={400}
    />
  );
};
