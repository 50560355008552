import styled, { css } from 'styled-components';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  primary?: boolean;
  secondary?: boolean;
  bgColor?: string;
}

export const Button = styled.button`
  margin: 0px 5px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  padding: 12px 32px;
  background-color: ${props =>
    props.primary
      ? !props.bgColor
        ? props.theme.colors.blue[500]
        : props.bgColor
      : props.secondary
      ? props.theme.colors.grey.offWhite
      : 'initial'};
  border: 2px solid
    ${props =>
      props.secondary
        ? '#959595'
        : !props.bgColor
        ? props.theme.colors.blue[500]
        : props.bgColor};
  color: ${props =>
    props.primary
      ? props.theme.colors.grey.white
      : props.secondary
      ? '#959595'
      : !props.bgColor
      ? props.theme.colors.blue[500]
      : props.secondary
      ? '#959595'
      : props.bgColor};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
    color: ${({ theme }) => theme.colors.grey.white};
    cursor: default;
  }

  ${(props: ButtonProps) =>
    !props.primary &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.blue[500]}
        color: ${({ theme }) => theme.colors.grey.white};
      }
    `};

  ${(props: ButtonProps) =>
    props.secondary &&
    css`
      &:hover {
        background-color: #959595;
        color: ${({ theme }) => theme.colors.grey.offWhite};
        border: none;
      }
    `};
`;
