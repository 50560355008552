import styled from 'styled-components';

export const PopupAnchorButton = styled.a`
  display: block;
  flex-direction: row;
  padding: 12px 24px;
  color: #57b4f9;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.offWhite};
  }
`;
