import { spacing, Spacing } from 'assets/styles';
import styled from 'styled-components';

export const StyledCreativePack = styled.div<Spacing>`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  ${spacing}

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(153, 153, 153, 0.4);

    .cursor-pointer {
      cursor: pointer;
    }

    .pl-12 {
      padding-left: 12px;
    }

    .name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .badge {
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin: 0 12px;
    }

    .delete {
      all: unset;
      flex-shrink: 0;
      display: inline-flex;
      cursor: pointer;
    }
  }

  .body {
    aspect-ratio: 6 / 5;
    display: grid;
    gap: 12px;
    grid-template-areas:
      'landscape portrait'
      'square portrait';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 3fr;

    &.disable {
      pointer-events: none;
    }
  }
`;

export const PopupTitle = styled.div`
  padding: 12px;
  color: darkgray;
  font-weight: bold;
  text-align: center;
`;

export const PopupContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const Button = styled.button`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  background-color: ${props => props.color};
  border-radius: 4px;
  border: 0;
  padding: 10px 24px;
  color: ${({ theme }) => theme.colors.grey.white};
`;
