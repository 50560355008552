import { Wrapper } from './Card.styles';
import { FiExternalLink } from 'react-icons/fi';
import { NewsCardColor, Newsletter } from 'types';
import moment from 'moment';

interface CardProps {
  newsLetter: Newsletter;
  color: NewsCardColor;
}

const Card = ({ newsLetter, color }: CardProps) => {
  const { id, title, subline, previewImageUrl, createdAt } = newsLetter;
  const formattedDate = moment(createdAt).format(`Do MMM YYYY, ddd`);
  return (
    <Wrapper to={`/newsletters/${id}`} className={color}>
      <div className="top">
        <div className="info">
          <span className="date">{formattedDate}</span>
          <FiExternalLink size={24} />
        </div>
        <img
          className="image"
          height="150"
          width="250"
          src={previewImageUrl}
          alt=""
        />
      </div>
      <div className="bottom">
        <span className="title">{title}</span>
        <span className="subtitle">{subline}</span>
      </div>
    </Wrapper>
  );
};

export default Card;
