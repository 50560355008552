import { FormFieldError } from 'components/AddStudioModal/AddStudioModal.styles';
import { FieldErrors, FieldValues } from 'react-hook-form';

export const renderFormFieldError = (
  errors: FieldErrors<FieldValues>,
  fieldName: string
) => {
  const error = errors[fieldName];

  if (error) {
    return <FormFieldError>{error.message as string}</FormFieldError>;
  }

  return null;
};
