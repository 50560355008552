import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import {
  FetchMediasResponse,
  MediaByDateGeneric,
  MediaSortType,
  VideoFile,
  CreativePackType,
  CreativePackPayload,
  MediaType,
  PackMediaByCategoryResponse,
} from 'types';

const fetchMedias = async (gameId: number, sortBy: MediaSortType) => {
  const {
    data: { data },
  } = await axiosInstance.get<
    FetchMediasResponse<VideoFile[] | MediaByDateGeneric<VideoFile>>
  >(
    `/games/${gameId}/medias?sortBy=${
      sortBy === MediaSortType.IPM ? MediaSortType.IPM : MediaSortType.DATE
    }`
  );
  return data;
};

export const useFetchMedias = (
  gameId: number,
  sortBy: MediaSortType,
  options = {}
) => {
  return useQuery(
    ['useFetchMedias', gameId],
    () => fetchMedias(gameId, sortBy),
    options
  );
};

const deleteMedia = async (gameId: number, mediaId: number) => {
  const { data } = await axiosInstance.delete(
    `/games/${gameId}/medias/${mediaId}`
  );
  return data;
};

export const useDeleteMedia = (gameId: number) => {
  return useMutation((mediaId: number) => deleteMedia(gameId, mediaId));
};

type OnUploadProgress = (progressEvent: any) => void;

const createMedia = async (
  type: MediaType,
  reqBody: any,
  onUploadProgress: OnUploadProgress
) => {
  let url;
  if (type === MediaType.UNITY_IMAGE) {
    url = '/games/upload-image';
  } else {
    url = `/games/upload-video?type=${type}`;
  }
  const { data } = await axiosInstance.post(url, reqBody, {
    onUploadProgress,
  });
  return data;
};

export const useCreateMedia = (
  type: MediaType,
  onUploadProgress: OnUploadProgress
) =>
  useMutation((reqBody: any) => createMedia(type, reqBody, onUploadProgress));

const getCreativePacks = async (gameId: number) => {
  const { data } = await axiosInstance.get(`/games/${gameId}/creative-packs`);

  return data as CreativePackType[];
};

export const useGetCreativePacks = (gameId: number) =>
  useQuery(['getCreativePacks', gameId], () => getCreativePacks(gameId), {
    initialData: [],
  });

const createCreativePack = async (
  gameId: number,
  creativePackPayload: CreativePackPayload
) => {
  const { data } = await axiosInstance.post(
    `/games/${gameId}/creative-packs`,
    creativePackPayload
  );
  return data as any;
};

export const useCreateCreativePack = (gameId: number) =>
  useMutation((reqBody: CreativePackPayload) =>
    createCreativePack(gameId, reqBody)
  );

const getCreativePackMedias = async (gameId: number) => {
  const { data } = await axiosInstance.get(
    `games/${gameId}/creative-packs?groupBy=category`
  );
  return data as PackMediaByCategoryResponse;
};

export const useGetCreativePackMedias = (gameId: number) =>
  useQuery(['getCreativePackMedias'], () => getCreativePackMedias(gameId));

const deleteCreativePack = async (gameId: number, packId: number) => {
  const { data } = await axiosInstance.delete(
    `/games/${gameId}/creative-packs/${packId}`
  );
  return data as any;
};

export const useDeleteCreativePack = (gameId: number) =>
  useMutation((packId: number) => deleteCreativePack(gameId, packId));
