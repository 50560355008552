import { InputContainer } from 'components/AddCampaignModal/AdvancedDetailsStep/AdvancedDetailsStep.styles';
import { TextInput } from 'components/atoms';
import { ChangeEvent } from 'react';
import { useGameWizard } from 'store';
import styled from 'styled-components';
import { ErrorText } from '../ConfirmationStep/ConfirmationStep';

export const TextInputStyles = {
  height: '28px',
  width: '250px',
  margin: 0,
  textIndent: '8px',
};

const NetworkDetailsStep = () => {
  const placeholder = 'Type something...';
  const { networkDetails, setNetworkDetails } = useGameWizard(state => state);

  const handleAppId = (event: ChangeEvent<HTMLInputElement>) =>
    setNetworkDetails({
      ...networkDetails,
      facebook: { ...networkDetails.facebook, appId: event.target.value },
    });

  const handleAppSecret = (event: ChangeEvent<HTMLInputElement>) =>
    setNetworkDetails({
      ...networkDetails,
      facebook: { ...networkDetails.facebook, appSecret: event.target.value },
    });

  const handleUserAccessToken = (event: ChangeEvent<HTMLInputElement>) =>
    setNetworkDetails({
      ...networkDetails,
      facebook: {
        ...networkDetails.facebook,
        userAccessToken: event.target.value,
      },
    });

  const handleInstallReferrerDecryptionKey = (
    event: ChangeEvent<HTMLInputElement>
  ) =>
    setNetworkDetails({
      ...networkDetails,
      facebook: {
        ...networkDetails.facebook,
        installReferrerDecryptionKey: event.target.value,
      },
    });

  const handleUnityId = (event: ChangeEvent<HTMLInputElement>) =>
    setNetworkDetails({
      ...networkDetails,
      unity: { unityId: event.target.value },
    });

  const {
    facebook: {
      userAccessToken,
      appId,
      appSecret,
      installReferrerDecryptionKey,
    },
  } = networkDetails;
  const isUserAccessTokenError =
    userAccessToken.length !== 0 &&
    (appId.length === 0 || appSecret.length === 0);

  return (
    <StyledNetworkDetailsStep>
      <div className="details-wrapper">
        <div className="wrapper">
          <span className="title">Facebook</span>
          <InputContainer>
            <label>App ID:</label>
            <TextInput
              placeholder={placeholder}
              style={TextInputStyles}
              value={appId}
              onChange={handleAppId}
            />
          </InputContainer>
          <InputContainer>
            <label>App Secret:</label>
            <TextInput
              placeholder={placeholder}
              style={TextInputStyles}
              value={appSecret}
              onChange={handleAppSecret}
            />
          </InputContainer>
          <InputContainer>
            <label>User Access Token:</label>
            <TextInput
              placeholder={placeholder}
              style={TextInputStyles}
              value={userAccessToken}
              onChange={handleUserAccessToken}
            />
          </InputContainer>
          <InputContainer>
            <label>Install Referrer Decryption Key:</label>
            <TextInput
              placeholder={placeholder}
              style={TextInputStyles}
              value={installReferrerDecryptionKey}
              onChange={handleInstallReferrerDecryptionKey}
            />
          </InputContainer>
        </div>
        <div className="wrapper">
          <span className="title">Unity</span>
          <InputContainer>
            <label>Campaign Set ID:</label>
            <TextInput
              placeholder="Type something..."
              style={TextInputStyles}
              value={
                networkDetails.unity.unityId !== null
                  ? networkDetails.unity.unityId
                  : ''
              }
              onChange={handleUnityId}
            />
          </InputContainer>
        </div>
      </div>
      <div className="error-wrapper">
        {isUserAccessTokenError && (
          <ErrorText>
            {
              'App ID and App Secret are required when User Access Token is filled.'
            }
          </ErrorText>
        )}
      </div>
    </StyledNetworkDetailsStep>
  );
};

export default NetworkDetailsStep;

const StyledNetworkDetailsStep = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .details-wrapper {
    display: flex;
    gap: 20px;
  }

  .wrapper {
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    width: 50%;
    min-height: 200px;
    padding: 20px;
  }

  .error-wrapper {
    text-align: center;
  }

  .title {
    color: #757575;
    font-weight: 300;
    margin-bottom: 20px;
  }
`;
