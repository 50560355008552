export enum AccessFeatures {
  REQUEST_REPORT = 'REQUEST_REPORT',
  IDEATION = 'IDEATION',
  PUBLISHING_OVERVIEW = 'PUBLISHING_OVERVIEW',
  CREATE_STUDIO = 'CREATE_STUDIO',
  EDIT_STUDIO = 'EDIT_STUDIO',
  ARCHIVE_STUDIO = 'ARCHIVE_STUDIO',
  ADD_PAYMENT_DETAILS = 'ADD_PAYMENT_DETAILS',
  EDIT_PAYMENT_DETAILS = 'EDIT_PAYMENT_DETAILS',
  GENERATE_INVOICE = 'GENERATE_INVOICE',
  CREATE_GAME = 'CREATE_GAME',
  EDIT_GAME = 'EDIT_GAME',
  ARCHIVE_GAME = 'ARCHIVE_GAME',
  GAME_CONFIG = 'GAME_CONFIG',
  CREATE_CAMPAIGN = 'CREATE_CAMPAIGN',
  SHOW_INVOICE = 'SHOW_INVOICE',
  CREATE_CUSTOM_CAMPAIGN = 'CREATE_CUSTOM_CAMPAIGN',
  TERMINATE_CAMPAIGN = 'TERMINATE_CAMPAIGN',
  START_CAMPAIGN = 'START_CAMPAIGN',
  CAMPAIGN_ACTIVATION = 'CAMPAIGN_ACTIVATION',
  CTM_CAMPAIGN_ACTIONS = 'CTM_CAMPAIGN_ACTIONS',
  UPLOAD_INVOICE = 'UPLOAD_INVOICE',
  DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE',
  EDIT_INVOICE = 'EDIT_INVOICE',
  ARCHIVE_INVOICE = 'ARCHIVE_INVOICE',
  DELETE_INVOICE = 'DELETE_INVOICE',
  COMMENT_INVOICE = 'COMMENT_INVOICE',
  REVIEW_INVOICE = 'REVIEW_INVOICE',
  MONETIZATION = 'MONETIZATION',
}

export enum UserRole {
  GUEST = 1,
  INTERNAL = 2,
  EXTERNAL = 3,
  DEVELOPER = 4,
  MANAGER = 5,
}

export const FullAccessRoles: UserRole[] = [
  UserRole.INTERNAL,
  UserRole.DEVELOPER,
  UserRole.MANAGER,
];

export interface RawUser {
  name: string;
  status: boolean;
  isPublisher: boolean;
  picture: string;
  studioId: number;
  role: UserRole;
}

export interface User extends RawUser {}
