import styled, { css } from 'styled-components';

export type StatusBadgeSize = 'small' | 'normal' | 'medium';

interface Props {
  context?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'warning'
    | 'ipm'
    | 'mvp'
    | 'scb'
    | 'new'
    | 'testing'
    | 'review'
    | 'scheduled';
  size?: StatusBadgeSize;
}

export const StatusBadge = styled.div<Props>`
  font-family: 'Rubik', sans-serif;
  text-align: center;

  ${props =>
    (!props.size || props.size === 'normal') &&
    css`
      font-size: 16px;
      border-radius: 4px;
      height: 30px;
      line-height: 30px;
      padding: 0 16px;
    `};
  ${props =>
    props.size === 'small' &&
    css`
      min-width: 60px;
      font-size: 9px;
      border-radius: 4px;
      height: 18px;
      line-height: 18px;
    `};
  ${props =>
    props.size === 'medium' &&
    css`
      min-width: 120px;
      font-size: 12px;
      border-radius: 4px;
      height: 25px;
      line-height: 25px;
    `}

  ${props =>
    !props.context &&
    css`
      background-color: ${({ theme }) => theme.colors.grey.white};
      color: ${({ theme }) => theme.colors.grey.black};
    `};
  ${props =>
    props.context === 'primary' &&
    css`
      background-color: #d5ecfe;
      color: ${({ theme }) => theme.colors.blue[500]};
    `};
  ${props =>
    props.context === 'secondary' &&
    css`
      background-color: ${({ theme }) => theme.colors.grey.offWhite};
      color: ${({ theme }) => theme.colors.grey[900]};
    `};
  ${props =>
    props.context === 'warning' &&
    css`
      background-color: #fff2d5;
      color: ${({ theme }) => theme.colors.orange[500]};
    `};
  ${props =>
    props.context === 'error' &&
    css`
      background-color: ${({ theme }) => theme.colors.pink[700]};
      color: ${({ theme }) => theme.colors.pink[500]};
    `};
  ${props =>
    props.context === 'success' &&
    css`
      background-color: ${({ theme }) => theme.colors.green[600]};
      color: ${({ theme }) => theme.colors.green[500]};
    `};
  ${props =>
    props.context === 'ipm' &&
    css`
      background-color: #fff2d5;
      color: ${({ theme }) => theme.colors.brown[500]};
    `};
  ${props =>
    props.context === 'mvp' &&
    css`
      background-color: ${({ theme }) => theme.colors.orange[500]};
      color: ${({ theme }) => theme.colors.grey.white};
    `};
  ${props =>
    props.context === 'new' &&
    css`
      background-color: #6736ca;
      color: ${({ theme }) => theme.colors.grey.white};
    `};

  ${props =>
    props.context === 'scb' &&
    css`
      background-color: #e6dafe;
      color: #8144fc;
    `};

  ${props =>
    props.context === 'testing' &&
    css`
      background-color: ${({ theme }) => theme.colors.lavender[500]};
      color: ${({ theme }) => theme.colors.purple[500]};
    `};
  ${props =>
    props.context === 'review' &&
    css`
      background-color: #e2faff;
      color: #b4cacd;
    `};
  ${props =>
    props.context === 'scheduled' &&
    css`
      background-color: ${({ theme }) => theme.colors.blue[800]};
      color: ${({ theme }) => theme.colors.grey.white};
    `};
`;
