import { Skeleton } from 'components';
import { StyledActivity } from './Activity.styles';
import styled from 'styled-components';

const ActivitySkeleton = () => {
  return (
    <StyledActivity>
      {new Array(10).fill(0).map((_, idx) => (
        <ActivityItemSkeleton
          spacing={12}
          iconSize={24}
          height="48px"
          width="100%"
          key={idx}
        />
      ))}
    </StyledActivity>
  );
};

export default ActivitySkeleton;

interface Props {
  spacing: number;
  iconSize: number;
}

const ActivityItemSkeleton = styled(Skeleton)<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({ theme }) => theme.colors.grey.white};
  font-size: 14px;
  font-weight: 300;
  border-radius: 4px;
  margin-bottom: ${props => props.spacing + 'px'};
  padding: ${props => props.spacing + 'px'};

  &::after {
    content: '';
    position: absolute;
    bottom: ${props => props.spacing * -1 + 'px'};
    left: ${props => props.spacing + props.iconSize * 0.5 + 'px'};
    height: ${props => props.spacing + 'px'};
    width: 1px;
    background-color: grey;
  }

  &:last-child {
    margin-bottom: 0;

    &::after {
      display: none;
    }
  }
`;
