import styled from 'styled-components';

export const SidebarTitle = styled.div`
  font-family: 'Rubik', sans-serif;
  color: ${({ theme }) => theme.colors.grey.black};
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
