import { useEffect, useState } from 'react';
import { useFetchGame, useFetchUser } from 'api';
import {
  AccessFeatures,
  CampaignTypes,
  campaignTypesArray,
  CampaignTypesArray,
} from 'types';
import styled from 'styled-components';
import Radio from './Radio';
import Description from './Description';
import { hasPermission } from 'utils/user';

interface BasicDetailsStepProps {
  gameId: number;
  selectedType: CampaignTypes | null;
  onTypeSelect: (type: CampaignTypes | null) => void;
  descriptionText: string;
  onDescriptionText: (descriptionText: string) => void;
  hasDescriptionError: boolean;
  descriptionMaxLength: number;
}

const BasicDetailsStep = ({
  gameId,
  selectedType,
  onTypeSelect,
  descriptionText,
  onDescriptionText,
  hasDescriptionError,
  descriptionMaxLength,
}: BasicDetailsStepProps) => {
  const { data: user } = useFetchUser();
  const hasCtmCampaignPermission = hasPermission(
    user,
    AccessFeatures.CREATE_CUSTOM_CAMPAIGN
  );
  const [types, setTypes] = useState<CampaignTypesArray[]>([]);
  const { data: game, isSuccess } = useFetchGame(gameId);

  useEffect(() => {
    if (game) {
      const generateHasError = (type: CampaignTypes): boolean => {
        switch (type) {
          case CampaignTypes.ipm:
            return !game?.isFacebookConfigured;
          case CampaignTypes.mvp:
            return !game?.isFacebookConfigured;
          case CampaignTypes.scb:
            return !game?.unityId;
          case CampaignTypes.ctm:
            return !game?.isFacebookConfigured && !game?.unityId;
          default:
            return false;
        }
      };
      const updatedTypes = campaignTypesArray
        .filter(item => {
          if (!hasCtmCampaignPermission && item.type === CampaignTypes.ctm)
            return false;
          return true;
        })
        .map(item => ({
          ...item,
          hasError: generateHasError(item.type),
        }));
      setTypes(updatedTypes);
    }
  }, [game]);

  return (
    <StyledBasicDetailsStep>
      <span className="title">Select test type</span>
      {isSuccess &&
        types.map(item => (
          <Radio
            key={item.type}
            {...item}
            selected={selectedType}
            onSelect={onTypeSelect}
          />
        ))}
      <span className="title">Test description</span>
      <Description
        selectedType={selectedType}
        description={descriptionText}
        onDescription={onDescriptionText}
        hasError={hasDescriptionError}
        maxLength={descriptionMaxLength}
      />
    </StyledBasicDetailsStep>
  );
};

export default BasicDetailsStep;

export const StyledBasicDetailsStep = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px;
    font-weight: 500;
    margin: 24px 0 12px;
  }
`;
