import { ReactImageGalleryItem } from 'react-image-gallery';

const MediaRenderer = (item: ReactImageGalleryItem) => {
  const styles = { height: 500 };
  if (item.description === 'img') {
    return <img style={styles} src={item.original} alt="" />;
  } else {
    return (
      <video style={styles} className="image-gallery-image" controls={true}>
        <source src={item.original} />
      </video>
    );
  }
};

export default MediaRenderer;
