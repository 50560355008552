import BigLogoBox from 'components/BigLogoBox/BigLogoBox';
import HeaderProfile from 'components/HeaderProfile/HeaderProfile';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import styled, { useTheme } from 'styled-components';
import { Popup } from 'components/atoms';
import NotificationActive from 'assets/images/Notification_Active.svg';
import NotificationInActive from 'assets/images/Notification_Inactive.svg';
import searchCircleSrc from 'assets/images/searchBar/search_circle.svg';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { NotificationsList } from 'components/organisms/NotificationsList';
import { TbReport, TbIceCream } from 'react-icons/tb';
import { RiLightbulbLine } from 'react-icons/ri';
import { RequestReportModal } from 'components/organisms/RequestReportModal';
import { AccessFeatures, allGenres } from 'types';
import { useFetchStatus } from 'api';
import { useFetchUser } from 'api/modules/useUser';
import SearchBar from './searchBar/searchBar';
import { hasPermission } from 'utils/user';
// import { IoNewspaperOutline } from 'react-icons/io5';

interface HeaderProps {
  profilePicture?: string;
  name: string;
}
const popupPosition = {
  top: 'calc(100% + 1px)',
  right: '0',
};

const sundayDocsURL: string =
  'https://drive.google.com/drive/folders/1kmgjt5dLGrEdSJ9q0zDF37ujfFbamxSt';

const Header = (props: HeaderProps) => {
  const theme = useTheme();
  const { data: user } = useFetchUser();
  const { data: status } = useFetchStatus();
  const location = useLocation();

  const navigate = useNavigate();
  const [reportModal, setReportModal] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);

  const isIdeation = location.pathname.includes('/ideation/');

  const handleToggleSearchBar = useCallback(
    () => setShowSearchBar(!showSearchBar),
    [showSearchBar]
  );

  const onProfileClick = () => {
    showMenu ? setShowMenu(false) : setShowMenu(true);
  };

  const navigateAndClosePopup = (url: string) => {
    navigate(url);
    setShowMenu(false);
  };

  const menuRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef<HTMLDivElement>(null);

  const hasIdeationAccess =
    !isIdeation && hasPermission(user, AccessFeatures.IDEATION);
  const hasRequestReportAccess = hasPermission(
    user,
    AccessFeatures.REQUEST_REPORT
  );

  const pinkColor = theme.colors.pink[500];

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Mac: CMD + K, Windows/Linux: CTRL + K
      if (
        (event.metaKey && event.key === 'k') ||
        (event.ctrlKey && event.key === 'k')
      ) {
        event.preventDefault();
        handleToggleSearchBar();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleToggleSearchBar]);

  return (
    <HeaderWrapper>
      <HeaderLogo to={'/home'}>
        <BigLogoBox />
      </HeaderLogo>
      <HeaderMenu>
        <MenuItemWrapper ref={menuRef}>
          <HeaderProfile
            profilePicture={props.profilePicture}
            onClick={onProfileClick}
            name={props.name}
          />
          <Popup
            visible={showMenu}
            anchor={menuRef}
            onClose={() => setShowMenu(false)}
            containerStyle={{
              minWidth: '170px',
              padding: '10px 0',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              ...popupPosition,
            }}
          >
            {isIdeation && (
              <PopupItem onClick={() => navigateAndClosePopup('/home')}>
                <TbIceCream color={pinkColor} size={20} />
                <PopupItemText>Sundash</PopupItemText>
              </PopupItem>
            )}
            {hasIdeationAccess && (
              <PopupItem
                onClick={() =>
                  navigateAndClosePopup(`/ideation/sunday/${allGenres.id}`)
                }
              >
                <RiLightbulbLine color={pinkColor} size={20} />
                <PopupItemText>Ideation</PopupItemText>
              </PopupItem>
            )}
            {hasRequestReportAccess && (
              <PopupItem onClick={() => setReportModal(!reportModal)}>
                <TbReport color={pinkColor} size={20} />
                <PopupItemText>Create Report</PopupItemText>
              </PopupItem>
            )}
            <a href={sundayDocsURL} target="_blank" rel="noreferrer">
              <PopupItem>
                <HiOutlineDocumentDuplicate color={pinkColor} size={20} />
                <PopupItemText>Sunday Docs</PopupItemText>
              </PopupItem>
            </a>
            <PopupItem onClick={() => navigate('/logout')}>
              <FiLogOut color={pinkColor} size={20} />
              <PopupItemText>Logout</PopupItemText>
            </PopupItem>
          </Popup>
        </MenuItemWrapper>
        <MenuItemWrapper>
          <span
            ref={notificationsRef}
            onClick={() =>
              setShowNotifications(showNotifications => !showNotifications)
            }
            style={{ cursor: 'pointer', height: '100%' }}
          >
            <img
              src={
                !!status?.notifications.length
                  ? NotificationActive
                  : NotificationInActive
              }
              alt="notification icon"
            />
          </span>
          <Popup
            visible={showNotifications}
            anchor={notificationsRef}
            onClose={() => setShowNotifications(false)}
            containerStyle={popupPosition}
          >
            <NotificationsList handler={() => setShowNotifications(false)} />
          </Popup>
        </MenuItemWrapper>
        <MenuItemWrapper>
          {showSearchBar ? (
            <SearchBar onClose={handleToggleSearchBar} />
          ) : (
            <img
              onClick={handleToggleSearchBar}
              src={searchCircleSrc}
              alt="search"
              style={{ cursor: 'pointer', height: '100%' }}
            />
          )}
        </MenuItemWrapper>
      </HeaderMenu>
      <RequestReportModal
        visible={reportModal}
        onClose={() => setReportModal(false)}
      />
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.header.attrs({
  className: 'header',
})`
  grid-area: header;
  background-color: ${({ theme }) => theme.colors.yellow[500]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

const HeaderMenu = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 42px;
`;

const MenuItemWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-left: 10px;
  }
`;

const PopupItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  white-space: nowrap;
`;

const PopupItemText = styled.span`
  color: ${({ theme }) => theme.colors.pink[500]};
  margin-left: 10px;
`;

const HeaderLogo = styled(Link)`
  width: 135px;
  height: 44px;
  display: inherit;
  z-index: 5;
`;
