import styled from 'styled-components';
import { Button } from 'components/atoms';

interface CreateTagProps {
  onCreate: () => void;
}

const CreateTag = ({ onCreate }: CreateTagProps) => {
  return (
    <StyledCreateTag>
      <div className="row">
        <Button primary className="button" onClick={onCreate}>
          Create & Assign
        </Button>
      </div>
      <div className="row">
        <span className="text">No tags found</span>
      </div>
    </StyledCreateTag>
  );
};

export default CreateTag;

const StyledCreateTag = styled.div`
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.grey.white};

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey[1000]};
    }

    .text {
      color: rgba(0, 0, 0, 0.5);
      padding: 16px;
    }

    .button {
      width: 100%;
      text-transform: uppercase;
      margin: 4px 4px 8px;
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
