import { useState, useEffect, ChangeEvent, FocusEvent, useRef } from 'react';
import { useGetTags, useCreateTag } from 'api';
import { TagType, CreatTagPayload } from 'types';
import { Spacing } from 'assets/styles';
import { Tag } from 'components';
import { StyledTagModifier } from './TagModifier.styles';
import TagsList from './TagsList';
import CreateTag from './CreateTag';

interface TagModifierProps extends Spacing {
  selectedTags: TagType[];
  onSelect: (newSelectedTags: TagType[]) => void;
}

const TagModifier = ({
  selectedTags,
  onSelect,
  ...spacing
}: TagModifierProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { data: allTags, refetch: refetchAllTags } = useGetTags();
  const { mutate: createTag } = useCreateTag();
  const [filteredTags, setFilteredTags] = useState<TagType[]>([]);
  const [inputText, setInputText] = useState<string>('');
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    setFilteredTags(allTags);
  }, [allTags]);

  const handleResetAllStates = () => {
    setFilteredTags(allTags);
    setInputText('');
    setShowPopup(false);
  };

  const handleResetInput = () => {
    setInputText('');
    inputRef.current?.focus();
  };

  const handleCreateTag = () => {
    const createTagPayload: CreatTagPayload = { name: inputText };

    createTag(createTagPayload, {
      onSuccess: () => {
        refetchAllTags().then(res => {
          // Select new created tag, after refetch all tags
          // Then pass it to the selectedTags Array
          const newAllTags = res.data as TagType[];
          const newTag = newAllTags[newAllTags.length - 1];
          onSelect([...selectedTags, newTag]);
        });
        handleResetInput();
      },
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setInputText(text);

    const newFilteredTags: TagType[] = allTags.filter(tag =>
      tag.name.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredTags(newFilteredTags);
  };

  const extractTag = (tagsList: TagType[], targetTag: TagType): TagType[] =>
    tagsList.filter(item => item.id !== targetTag.id);

  const handleExistingTagClick = (tag: TagType) => {
    const newSelectedTags = extractTag(selectedTags, tag);
    onSelect(newSelectedTags);
  };

  const handleTagsListClick = (tag: TagType) => {
    const isSelected = selectedTags.some(selected => selected.id === tag.id);

    const newSelectedTags: TagType[] = isSelected
      ? extractTag(selectedTags, tag)
      : [...selectedTags, tag];
    onSelect(newSelectedTags);
  };

  const handleClickOutside = (event: FocusEvent) => {
    const currentTarget = event.currentTarget;
    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        handleResetAllStates();
      }
    });
  };

  return (
    <StyledTagModifier {...spacing}>
      <div className="content-wrapper">
        <label>Add tag</label>
        <div className="input-wrapper" tabIndex={0} onBlur={handleClickOutside}>
          <input
            ref={inputRef}
            className="input"
            placeholder="Search"
            value={inputText}
            onChange={handleInputChange}
            onClick={() => setShowPopup(true)}
          />
          {showPopup && (
            <div className="popup">
              {filteredTags.length !== 0 ? (
                <TagsList
                  tagsList={filteredTags}
                  selectedTags={selectedTags}
                  onItemClick={handleTagsListClick}
                />
              ) : (
                <CreateTag onCreate={handleCreateTag} />
              )}
            </div>
          )}
        </div>
      </div>

      <div className="content-wrapper flex-extend">
        <label className="existing-tags">Existing Tags</label>
        {selectedTags.length === 0 ? (
          <span className="no-result">No tags added</span>
        ) : (
          <div className="tags-wrapper">
            {selectedTags.map(tag => (
              <Tag
                text={tag.name}
                key={tag.id}
                onDelete={() => handleExistingTagClick(tag)}
                mr={1.5}
                mb={1.5}
                size="lg"
              />
            ))}
          </div>
        )}
      </div>
    </StyledTagModifier>
  );
};

export default TagModifier;
