import { useState } from 'react';
import MediaLibrary from 'components/MediaLibrary/MediaLibrary';

interface FacebookProps {
  mediaLimit: number;
  onSelectVideo: (ids: number[]) => void;
}

const Facebook = ({ mediaLimit, onSelectVideo }: FacebookProps) => {
  const [selectedVideos, setSelectedVideos] = useState<number[]>([]);

  const handleSelectVideos = (videoId: number) => {
    let newSelectedVideos: number[] = [];
    const isIncluded = selectedVideos.includes(videoId);

    if (isIncluded) {
      newSelectedVideos = selectedVideos.filter(id => id !== videoId);
    } else {
      newSelectedVideos =
        selectedVideos.length < mediaLimit
          ? [...selectedVideos, videoId]
          : selectedVideos;
    }

    setSelectedVideos(newSelectedVideos);
    onSelectVideo(newSelectedVideos);
  };

  const info =
    selectedVideos.length > 4
      ? ". By adding more creatives don't forget to increase the budget accordingly"
      : '';

  return (
    <>
      <div className="header">
        <span className="title">Add videos</span>
        <span className="hint">
          {`Max ${mediaLimit} videos`} {info}
        </span>
      </div>
      <MediaLibrary
        mediaLimit={mediaLimit}
        selectedMedias={selectedVideos}
        handleSelectMedia={handleSelectVideos}
      />
    </>
  );
};

export default Facebook;
