import { StyledCheckbox } from './Checkbox.styles';

interface CheckboxProps {
  isChecked: boolean;
  isDisabled: boolean;
  accentColor?: string;
  size?: 'sm' | 'lg';
}

const Checkbox = ({
  isChecked,
  isDisabled,
  accentColor,
  size = 'lg',
}: CheckboxProps) => {
  return (
    <>
      <StyledCheckbox
        accentColor={accentColor}
        checked={isChecked}
        disabled={isDisabled}
        checkboxSize={size}
        onChange={() => {}}
      />
      <label></label>
    </>
  );
};

export default Checkbox;
