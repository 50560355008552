import OverviewList, {
  OverviewListRow,
} from 'components/OverviewList/OverviewList';
import styled from 'styled-components';
import { InvoiceItemWithId } from 'types';
import DeleteImg from 'assets/images/Delete_pink.svg';
import DeleteOpenImg from 'assets/images/Bin_Open.svg';
import { useState } from 'react';
import { useFetchStudio } from 'api';
import { useParams } from 'react-router-dom';
import { Icon } from 'assets/styles';

interface AgreementCellProps {
  prototypeName: string;
  agreementName: string;
}
const AgreementCell = ({
  prototypeName,
  agreementName,
}: AgreementCellProps) => {
  return (
    <StyledAgreementCell>
      <span className="title">{prototypeName}</span>
      <span className="subtitle">{agreementName}</span>
    </StyledAgreementCell>
  );
};

interface DeleteIconProps {
  onClick: () => void;
}

const DeleteIcon = ({ onClick }: DeleteIconProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const icon = isHovered ? DeleteOpenImg : DeleteImg;

  return (
    <Icon
      src={icon}
      alt="delete-icon"
      onClick={onClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
    />
  );
};

interface ListItemsProps {
  invoiceItems: InvoiceItemWithId[];
  removeItem: (id: number) => void;
}
const ListItems = ({ invoiceItems, removeItem }: ListItemsProps) => {
  const params = useParams<{
    studioId: string;
  }>();
  const studioId = Number(params.studioId);
  const { data: studio } = useFetchStudio(studioId);

  const currency = studio?.currencySymbol ?? '$';

  const columns = [
    {
      id: 'agreement',
      name: 'Item / Agreement',
      width: 300,
    },
    {
      id: 'quantity',
      name: 'Quantity',
      width: 100,
    },
    {
      id: 'price',
      name: 'Price',
      width: 100,
    },
    {
      id: 'sum',
      name: 'Sum',
      width: 100,
    },
    {
      id: 'delete',
      name: '',
      width: 100,
    },
  ];

  const rows: OverviewListRow[] = invoiceItems.map(
    (invoiceItem: InvoiceItemWithId) => ({
      id: 0,
      link: '',
      rows: [
        {
          columnId: 'agreement',
          content: (
            <AgreementCell
              prototypeName={invoiceItem.prototypeName}
              agreementName={invoiceItem.agreementName}
            />
          ),
        },
        {
          columnId: 'quantity',
          content: (
            <span style={{ maxWidth: 150 }}>{invoiceItem.quantity}</span>
          ),
        },
        {
          columnId: 'price',
          content: (
            <span
              style={{ maxWidth: 150 }}
            >{`${currency}${invoiceItem.price.toLocaleString('en-US')}`}</span>
          ),
        },
        {
          columnId: 'sum',
          content: (
            <span style={{ maxWidth: 150 }}>{`${currency}${(
              invoiceItem.price * invoiceItem.quantity
            ).toLocaleString('en-US')}`}</span>
          ),
        },
        {
          columnId: 'delete',
          content: <DeleteIcon onClick={() => removeItem(invoiceItem.id)} />,
        },
      ],
    })
  );

  return (
    <Container>
      <OverviewList
        columns={columns}
        blocks={[[...rows]]}
        noDataText="No Items added"
      />
    </Container>
  );
};

export default ListItems;

const Container = styled.div`
  max-height: 350px;
  min-height: 200px;
  overflow: auto;
`;

const StyledAgreementCell = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  min-width: 200px;
  max-width: 300px;

  .title {
    font-weight: 700;
    margin-bottom: 5px;
    overflow-wrap: break-word;
  }

  .subtitle {
    font-size: 12px;
    color: #afafaf;
    overflow-wrap: break-word;
  }
`;
