import styled, { css } from 'styled-components';

interface NavigationLinkElementWrapperProps {
  $active?: boolean;
}

export const SidebarLink = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  display: flex;
  cursor: pointer;
  padding: 0 12px;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[1300]};
  }

  ${(props: NavigationLinkElementWrapperProps) =>
    props.$active &&
    css`
      background-color: ${({ theme }) => theme.colors.grey.offWhite};
    `};
`;
