// import { Button } from 'components/atoms';
import { ReactNode, useMemo } from 'react';
import { Content, Step, Steps, Wrapper } from './Stepper.styles';
import { FaCheck } from 'react-icons/fa';
import clsx from 'clsx';

interface StepperControls {
  onBack: (backStep: number) => void;
  onNext: (nextStep: number) => void;
  disableNextButton?: boolean;
}

interface StepperProps {
  steps: string[];
  currentStep: number;
  controls: StepperControls;
  children: ReactNode;
  renderControls: (
    controls: StepperControls,
    isFirstStep: boolean,
    isLastStep: boolean
  ) => JSX.Element;
}

const Stepper = ({
  steps,
  currentStep,
  controls,
  children,
  renderControls,
}: StepperProps) => {
  const isFirstStep = useMemo(() => currentStep === 0, [currentStep]);
  const isLastStep = useMemo(
    () => currentStep === steps.length - 1,
    [currentStep, steps]
  );

  return (
    <Wrapper>
      <Steps>
        {steps.map((step, idx) => {
          const inActiveStep = currentStep >= idx ? false : true;
          return (
            <Step role="navigation" key={idx}>
              <span
                className={clsx('step-number', {
                  'step-number-inactive': inActiveStep,
                })}
              >
                {currentStep <= idx ? (
                  idx + 1
                ) : (
                  <FaCheck data-testid="check-icon" size={16} />
                )}
              </span>
              <span
                className={clsx('step-title', {
                  'step-title-inactive': inActiveStep,
                })}
              >
                {step}
              </span>
            </Step>
          );
        })}
      </Steps>
      <Content>{children}</Content>
      {renderControls(controls, isFirstStep, isLastStep)}
    </Wrapper>
  );
};

export default Stepper;
