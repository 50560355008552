import styled from 'styled-components';

interface SidebarHeaderTextProps {
  title: string;
  subtitle?: string;
}

const SidebarHeaderText = ({ title, subtitle }: SidebarHeaderTextProps) => {
  return (
    <SidebarHeaderTextContainer>
      {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Title>{title}</Title>
    </SidebarHeaderTextContainer>
  );
};

const SidebarHeaderTextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.blue[500]};
  font-size: 18px;
  font-weight: 500;
`;

const Subtitle = styled.span`
  font-family: 'Rubik', sans-serif;
  color: ${({ theme }) => theme.colors.blue[500]};
  font-size: 12px;
  text-overflow: ellipsis;
`;

export default SidebarHeaderText;
