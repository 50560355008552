import { useTheme } from 'styled-components';

export const useSelectCustomStyles = () => {
  const theme = useTheme();
  return {
    control: (provided: any) => ({
      ...provided,
      marginTop: window.innerHeight <= 720 ? '2px' : '8px',
      borderColor: theme.colors.grey.white,
      boxShadow: theme.colors.grey.white,
      '&:hover': {
        borderColor: theme.colors.grey.white,
      },
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      border: state.selectProps.error
        ? `2px solid ${theme.colors.pink[500]}`
        : `1px solid ${theme.colors.grey[900]}`,
      outlineColor: theme.colors.grey[900],
      borderRadius: '4px',
      textIndent: '12px',
      fontFamily: 'Rubik,sans-serif',
      fontSize: '16px',
      margin: '6px 0 20px',
      width: '100%',
      height: window.innerHeight <= 720 ? '44px' : '54px',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#B5B5B5',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: theme.colors.grey.white,
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.selectProps.error
        ? theme.colors.pink[500]
        : theme.colors.blue[500],
    }),
    clearIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.selectProps.error
        ? theme.colors.pink[500]
        : theme.colors.blue[500],
    }),
  };
};

