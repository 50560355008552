import { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Container } from 'assets/styles';
import { TestPageHeader } from 'components/organisms/TestPageHeader';
import {
  CampaignTabs,
  CampaignParams,
  preSelectedCampaignTab,
  CampaignLinkGenerator,
  CampaignDetails,
} from 'types';
import { useFetchCampaign, useFetchGame, campaignLinkGenerator } from 'api';
import { useDocumentTitle } from 'hooks';
import { isNaN } from 'lodash';
import TabHandler from 'components/TabHandler/TabHandler';
import { Activity, Kpi } from 'features';

if (process.env.NODE_ENV !== 'test') {
  ChartJS.register(...registerables);
}

const TestPage = () => {
  const { updateDocumentTitle } = useDocumentTitle();
  const [selectedTab, setSelectedTab] = useState<CampaignTabs>(
    preSelectedCampaignTab
  );

  const { pathname } = useLocation();
  const params = useParams<CampaignParams>();
  const studioId = Number(params.studioId);
  const gameId = Number(params.gameId);
  const campaignId = Number(params.campaignId);

  const isTabNameValid = (tab: CampaignTabs | undefined): boolean =>
    tab && (tab === CampaignTabs.kpi || tab === CampaignTabs.activity)
      ? true
      : false;

  const handleSuccess = (campaign: CampaignDetails) =>
    updateDocumentTitle(campaign.name, campaign.gameName, campaign.studioName);

  const { data: campaign, isSuccess: isCampaignSuccess } = useFetchCampaign(
    gameId,
    campaignId,
    {
      onSuccess: handleSuccess,
    }
  );
  const { data: game } = useFetchGame(gameId, {
    enabled: !isNaN(gameId),
  });

  const redirectToUrl = useCallback(
    ({ studioId, gameId, campaignId, tabName }: CampaignLinkGenerator) => {
      const campaignLink = campaignLinkGenerator({
        studioId,
        gameId,
        campaignId,
        ...(tabName && { tabName }),
      });
      return campaignLink;
    },
    []
  );

  useEffect(() => {
    // Fix for the old URLs break: redirect them to the new URL structure
    if (!pathname.includes('/studio') && game?.studioId) {
      redirectToUrl({ studioId: game.studioId, gameId, campaignId });
    }
  }, [game, campaignId, gameId, redirectToUrl, pathname]);

  useEffect(() => {
    isTabNameValid(params.tabName)
      ? setSelectedTab(params.tabName!)
      : redirectToUrl({ studioId, gameId, campaignId });
  }, [params, studioId, campaignId, gameId, redirectToUrl, pathname]);

  const tabData = [
    {
      tabName: CampaignTabs.kpi,
      tabHeaderText: "TEST KPI'S",
      redirectUrl: redirectToUrl({
        studioId,
        gameId,
        campaignId,
        tabName: CampaignTabs.kpi,
      }),
      selectedTab,
      component: () => <Kpi campaign={campaign} />,
    },
    {
      tabName: CampaignTabs.activity,
      tabHeaderText: 'ACTIVITY',
      redirectUrl: redirectToUrl({
        studioId,
        gameId,
        campaignId,
        tabName: CampaignTabs.activity,
      }),
      selectedTab,
      component: () => <Activity gameId={gameId} campaignId={campaignId} />,
    },
  ];

  return (
    <Container>
      <TestPageHeader media={[]} />
      {isCampaignSuccess ? <TabHandler tabData={tabData} /> : null}
    </Container>
  );
};

export default TestPage;
