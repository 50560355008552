import { Modal, Stepper } from 'components';
import { useCallback, useEffect, useState } from 'react';
import BasicDetailsStep from './BasicDetailsStep/BasicDetailsStep';
import NetworkDetailsStep from './NetworkDetailsStep/NetworkDetailsStep';
import ConfirmationStep from './ConfirmationStep/ConfirmationStep';
import { useNavigate, useParams } from 'react-router-dom';
import { GameParams, GameTabs, ServerQueryKey } from 'types';
import { useCreateGame, useFetchGame, useUpdateGame } from 'api';
import { useGameWizard } from 'store';
import { useQueryClient } from '@tanstack/react-query';
import { map } from 'lodash';
import { toast } from 'react-toastify';
import { Controls } from 'components/Stepper/Stepper.styles';
import { Button } from 'components/atoms';

enum CreateGameStep {
  BASIC_DETAILS = 'Basic details',
  NETWORK_DETAILS = 'Network details',
  CONFIRMATION = 'Confirmation',
}

interface AddGameModalProps {
  isOpen: boolean;
  onClose: () => void;
  isCreateMode?: boolean;
}

const AddGameModal: React.FC<AddGameModalProps> = ({
  isOpen,
  onClose,
  isCreateMode = true,
}) => {
  const navigate = useNavigate();
  const params = useParams<GameParams>();
  const queryClient = useQueryClient();
  const [validationError, setValidationError] = useState<string>('');
  const { mutate: createGame } = useCreateGame();
  const { mutate: updateGame } = useUpdateGame(Number(params.gameId));
  const {
    data: game,
    isSuccess,
    refetch: refetchGame,
  } = useFetchGame(Number(params.gameId));
  const {
    basicDetails,
    networkDetails,
    setBasicDetails,
    setNetworkDetails,
    isBasicDetailsValid,
    isNetworkDetailsValid,
    clearStore,
  } = useGameWizard(state => state);

  useEffect(() => {
    // Set the store initially
    if (isSuccess && !isCreateMode) {
      setBasicDetails({
        playstoreUrl: game.playMarketUrl,
        gameName: game.name,
        logoUrl: game.logo,
        mondayItemId: game.mondayItemId,
        bundleId: game.bundleId,
        appStoreUrl: game.appStoreUrl,
        iosPackageName: game.iosPackageName,
        tags: game.tags,
        orientation: game.orientation,
      });
      setNetworkDetails({
        facebook: {
          appId: game.facebookAppId ?? '',
          appSecret: game.facebookAppSecret ?? '',
          userAccessToken: game.facebookUserAccessToken ?? '',
          installReferrerDecryptionKey: game.installReferrerDecryptionKey ?? '',
        },
        unity: {
          unityId: game.unityId,
        },
      });
    }
  }, [game, setBasicDetails, setNetworkDetails, isSuccess, isCreateMode]);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const steps = [
    CreateGameStep.BASIC_DETAILS,
    CreateGameStep.NETWORK_DETAILS,
    CreateGameStep.CONFIRMATION,
  ];

  const handleBackStep = useCallback((backStep: number) => {
    setValidationError('');
    setCurrentStep(backStep);
  }, []);

  const onError = (error: any) => {
    if (!!error.response.data['facebookUserAccessToken']) {
      setValidationError(error.response.data['facebookUserAccessToken']);
    }
    if (!!error.response.data['unityId']) {
      setValidationError(error.response.data['unityId']);
    }
    if (!!error.response.data['appStoreUrl']) {
      setValidationError(error.response.data['appStoreUrl']);
    }
  };

  const handleNextStep = (nextStep: number) => {
    if (nextStep < steps.length) {
      // all steps except last one
      setCurrentStep(nextStep);
    } else {
      // call the api
      const {
        gameName,
        playstoreUrl,
        bundleId,
        logoUrl,
        mondayItemId,
        tags,
        orientation,
        appStoreUrl,
        iosPackageName,
      } = basicDetails;
      const {
        facebook: {
          appId,
          appSecret,
          userAccessToken,
          installReferrerDecryptionKey,
        },
        unity: { unityId },
      } = networkDetails;
      const payload = {
        name: gameName,
        playMarketUrl: playstoreUrl,
        appStoreUrl: appStoreUrl ?? '',
        iosPackageName: iosPackageName ?? '',
        bundleId: bundleId,
        studioId: params.studioId,
        logo: logoUrl,
        ...(mondayItemId !== null ? { mondayItemId } : {}),
        ...(appId ? { facebookAppId: appId } : {}),
        ...(appSecret && !appSecret.startsWith('*')
          ? { facebookAppSecret: appSecret }
          : {}),
        ...(userAccessToken && !userAccessToken.startsWith('*')
          ? { facebookUserAccessToken: userAccessToken }
          : {}),
        ...(installReferrerDecryptionKey &&
        !installReferrerDecryptionKey.startsWith('*')
          ? { installReferrerDecryptionKey }
          : {}),
        unityId: unityId,
        tags: map(tags, tag => tag.id),
        orientation,
      };

      setValidationError('');
      if (isCreateMode) {
        createGame(payload, {
          onSuccess: (data: any) => {
            queryClient.invalidateQueries([ServerQueryKey.NavGames]);
            navigate(
              `/studio/${params.studioId}/game/${data.id}/${GameTabs.tests}`
            );
            handleOnClose();
            toast.success('The game was successfully created', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          },
          onError,
        });
      } else {
        updateGame(payload, {
          onSuccess: () => {
            queryClient.invalidateQueries([ServerQueryKey.NavGames]);
            refetchGame();
            handleOnClose();
            toast.success('The game was successfully edited', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          },
          onError,
        });
      }
    }
  };

  const handleOnClose = () => {
    clearStore();
    onClose();
  };

  const isBasicDetailsStep =
    steps[currentStep] === CreateGameStep.BASIC_DETAILS;
  const isNetworkDetailsStep =
    steps[currentStep] === CreateGameStep.NETWORK_DETAILS;
  const isConfirmationStep = steps[currentStep] === CreateGameStep.CONFIRMATION;

  const disableNextButton =
    (isBasicDetailsStep && !isBasicDetailsValid()) ||
    (isNetworkDetailsStep && !isNetworkDetailsValid());

  return (
    <Modal
      isOpen={isOpen}
      title={`${isCreateMode ? 'create new' : 'edit'} game`}
      onClose={handleOnClose}
    >
      <Stepper
        steps={steps}
        currentStep={currentStep}
        controls={{
          onBack: handleBackStep,
          onNext: handleNextStep,
          disableNextButton: disableNextButton,
        }}
        renderControls={(controls, isFirstStep, isLastStep) => (
          <Controls>
            {!isFirstStep && (
              <Button onClick={() => controls.onBack(currentStep - 1)}>
                BACK
              </Button>
            )}
            <Button
              style={{ minWidth: '200px' }}
              primary
              onClick={() => controls.onNext(currentStep + 1)}
              disabled={disableNextButton ?? false}
            >
              {isLastStep
                ? isCreateMode
                  ? 'CREATE GAME'
                  : 'CONFIRM DETAILS'
                : 'NEXT'}
            </Button>
          </Controls>
        )}
      >
        {isBasicDetailsStep && <BasicDetailsStep />}
        {isNetworkDetailsStep && <NetworkDetailsStep />}
        {isConfirmationStep && (
          <ConfirmationStep
            isCreateMode={isCreateMode}
            error={validationError}
          />
        )}
      </Stepper>
    </Modal>
  );
};

export default AddGameModal;
