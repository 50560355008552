import styled from 'styled-components';

export const SidebarSearchNoResult = styled.div`
  margin-top: 32px;
  padding: 24px;
  color: ${({ theme }) => theme.colors.grey[1200]};
  text-align: center;
  font-size: 18px;
  font-weight: 500;
`;
