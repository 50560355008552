import { Member } from './member';

export interface Contact {
  email: string;
  name: string;
  phone: string;
}

export interface Studio {
  id: number;
  name: string;
  logo: string;
  locationId: number;
  location: string;
  country: string;
  city: string;
  archived: boolean;
  isFavorite: boolean;
  currencySymbol: string;
  contact: Contact;
  manager: Member;
  partnerSince: string;
  externalUrl: string;
  hasPaymentDetails: boolean;
  invoiceAccess: boolean;
}

export enum StudioTabs {
  games ='games',
  invoices ='invoices',
}
export const preSelectedStudioTab = StudioTabs.games;

export interface StudioLinkGenerator {
  studioId: number;
  tabName?: StudioTabs;
}
export interface FetchStudioResponse {
  data: Studio;
  status: boolean;
}

export type StudioParams ={
  studioId: string;
  tabName: StudioTabs;
}

export interface ValidatePrototypeNameResponse {
  safe: boolean;
  explicitWords?: string[];
}

export interface FetchInvoiceNumberResponse {
  invoiceNumber: string;
}

export const defaultStudio: Studio = {
  id: 0,
  logo: '',
  isFavorite: false,
  currencySymbol: '',
  externalUrl: '',
  archived: false,
  location: '',
  locationId: 0,
  city: '',
  country: '',
  name: '',
  partnerSince: '',
  manager: {
    id: 0,
    name: '',
  },
  contact: {
    name: '',
    email: '',
    phone: '',
  },
  hasPaymentDetails: false,
  invoiceAccess: false,
};

export interface AllPaymentDetails {
  companyName: string;
  bankName: string;
  companyAddress: string;
  accountName: string;
  taxId: string;
  swiftBic: string;
  currency: string;
  bankRoutingNumber: string;
  iban: string;
  accountNumber: string;
  invoicePattern: string;
}

export interface IndianPaymentDetails {
  companyName: string;
  bankName: string;
  companyAddress: string;
  accountName: string;
  taxId: string;
  swiftBic: string;
  currency: string;
  bankRoutingNumber: string;
  ifscCode: string;
  purposeCode: string;
  accountNumber: string;
  invoicePattern: string;
}

export interface LocationOption {
  label: string;
  value: string;
}