import styled from 'styled-components';
import Facebook from './Facebook';
import Unity from './Unity';
import { CampaignTypes } from 'types';

interface AddMediaStepProps {
  mediaLimit: number;
  campaignType: CampaignTypes;
  isUnity: boolean;
  gameId: number;
  onSelectMedia: (ids: number[]) => void;
}

const AddMediaStep = ({
  mediaLimit,
  isUnity,
  gameId,
  onSelectMedia,
}: AddMediaStepProps) => {
  return (
    <StyledAddMediaStep>
      {isUnity ? (
        <Unity gameId={gameId} onSelectPack={onSelectMedia} />
      ) : (
        <Facebook mediaLimit={mediaLimit} onSelectVideo={onSelectMedia} />
      )}
    </StyledAddMediaStep>
  );
};

export default AddMediaStep;

export const StyledAddMediaStep = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .title {
      font-size: 18px;
      font-weight: 500;
    }

    .hint {
      font-style: italic;
      color: ${({ theme }) => theme.colors.pink[500]};
      margin-left: 8px;
    }

    .button {
      all: unset;
      cursor: pointer;
      font-weight: 500;
      margin-left: 16px;
      color: ${({ theme }) => theme.colors.blue[500]};
    }
  }
`;
