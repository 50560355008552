import styled, { css } from 'styled-components';

interface TextProps {
  title: string;
  subtitle?: string;
}

const ListItemText = ({ title, subtitle }: TextProps) => {
  return (
    <Container>
      <Title subtitle={!!subtitle}>{title}</Title>
      {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Container>
  );
};

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: start;
`;

interface TitleProps {
  subtitle: boolean;
}

const Title = styled.h2<TitleProps>`
  color: ${({ theme }) => theme.colors.grey.black};
  font-size: 12px;
  text-overflow: ellipsis;
  max-width:100px ;
  margin-left: 10px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props: TitleProps) =>
    props.subtitle
      ? css`
          margin: 0;
        `
      : ''};
`;

const Subtitle = styled.span`
  font-family: 'Rubik', sans-serif;
  color: ${({ theme }) => theme.colors.grey[900]};
  font-size: 10px;
`;

export default ListItemText;
