import styled from 'styled-components';

export const CenteredWrapper = styled.div`
  text-align: center;
`;

export const StyledWrapper = styled.div`
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  width: 300px;
`;

export const BigErrorBoxWrapper = styled.div`
  display: block;
  width: 100%;
  float: left;
  clear: left;
`;

interface TextSpanProps {
  color?: string;
}

export const TextSpan = styled.span<TextSpanProps>`
  display: block;
  font-style: normal;
  font-variant: normal;
  font-size: 24px;
  line-height: 28px;
  font-family: 'Rubik';
  letter-spacing: 0px;
  color: ${props =>
    props.color ? props.color : props.theme.colors.grey.black};
  margin: 20% auto;
`;
