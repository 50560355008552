import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import NavListItem from './NavListItem';
import { GameTabs, NavCampaign, NavGame, NavStudio } from 'types';

interface SidebarNavListProps {
  data: NavStudio[] | NavGame[] | NavCampaign[];
  type: 'studio' | 'game' | 'campaign';
}

const SidebarNavList = ({ data, type }: SidebarNavListProps) => {
  const navigate = useNavigate();
  const { studioId } = useParams<{ studioId: string }>();

  const handleClick = (data: any) => {
    type === 'studio'
      ? navigate(`/studio/${data.id}`)
      : type === 'game'
      ? navigate(`/studio/${data.studioId}/game/${data.id}/${GameTabs.tests}`)
      : navigate(`/studio/${studioId}/game/${data.gameId}/test/${data.id}`);
  };

  let updatedData;
  if (type === 'studio') {
    updatedData = data as NavStudio[];
  } else if (type === 'game') {
    updatedData = data as NavGame[];
  } else {
    updatedData = data as NavCampaign[];
  }

  return (
    <nav>
      <List>
        {updatedData.map((item: any, idx: number) => {
          return (
            <span
              onClick={() => {
                handleClick(item);
              }}
              key={idx}
            >
              <NavListItem key={idx} data={item} type={type} />
            </span>
          );
        })}
      </List>
    </nav>
  );
};

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export default SidebarNavList;
