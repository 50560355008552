import { camelizeKeys } from 'humps';
import {
  convertToCamelCaseType,
  FacebookCampaignStatus,
  CampaignLinkGenerator,
  preSelectedCampaignTab,
  preSelectedGameTab,
  GameLinkGenerator,
  preSelectedStudioTab,
  StudioLinkGenerator
} from 'types';
import { axiosInstance } from './axios';
import { NavCampaign } from 'types/navigation';

export const convertToCamelCase: convertToCamelCaseType = <
  T extends object,
  U extends object
>(
  value: T
) => camelizeKeys(value) as U;

export const axiosFetch = <RawT extends object, ReturnT extends object>(
  url: string
) => {
  return async (): Promise<ReturnT> => {
    const { data }: { data: RawT } = await axiosInstance.get<RawT>(url);
    return convertToCamelCase(data);
  };
};

export const campaignLinkGenerator = ({
  studioId,
  gameId,
  campaignId,
  tabName = preSelectedCampaignTab,
}: CampaignLinkGenerator) => {
  return `/studio/${studioId}/game/${gameId}/test/${campaignId}/${tabName}`;
};
export const gameLinkGenerator = ({
  studioId,
  gameId,
  tabName = preSelectedGameTab,
}: GameLinkGenerator) => {
  return `/studio/${studioId}/game/${gameId}/${tabName}`;
};

export const studioLinkGenerator = ({
  studioId,
  tabName = preSelectedStudioTab,
}: StudioLinkGenerator) => {
  return `/studio/${studioId}/${tabName}`;
};
export const newCampaignLinkGenerator = (studioId: number, gameId: number) => {
  return `/studio/${studioId}/game/${gameId}/new-test`;
};
export const makeGameLink = ({
  studioId,
  gameId,
}: {
  studioId: number;
  gameId: number;
}): string => {
  return `/studio/${studioId}/game/${gameId}/tests`;
};

export const formatStatusName = ({
  status,
}: {
  status: FacebookCampaignStatus;
}): string => {
  const lower = status.toLowerCase();
  return `${lower[0].toUpperCase()}${lower.substring(1)}`;
};

export const downloadFile = (blob: Blob, filename: string | undefined) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const updateCampaignStatus = (campaign: NavCampaign) => {
  const status =
    campaign.status === FacebookCampaignStatus.pending &&
    campaign.startAt !== null
      ? FacebookCampaignStatus.scheduled
      : campaign.status;
  return {
    ...campaign,
    status,
  };
};
