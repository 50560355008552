import { Button } from 'components/atoms/Button';

interface Props {
  onClick: () => void;
  styles?: Object;
  bgColor: string;
  buttonText: string | React.ReactNode;
  disabled: boolean;
  isSaving: boolean;
}

export const PageHeaderActionButton = ({
  bgColor,
  buttonText,
  onClick,
  disabled,
  isSaving,
  styles = {},
}: Props) => {
  return (
    <Button
      primary
      disabled={disabled || isSaving}
      onClick={onClick}
      bgColor={bgColor}
      style={{ margin: 0, height: 48, ...styles }}
    >
      {isSaving ? 'Please wait ...' : buttonText}
    </Button>
  );
};
