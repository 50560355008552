import styled, { css } from 'styled-components';

interface Props {
  name: string;
  big?: boolean;
  medium?: boolean;
  xSmall?:boolean;
  url?: string | null;
  border?: string;
}

const StudioLogoImage = styled.div`
  width: ${(props: Props) =>
    props.big ? '120px' : props.medium ? '48px' : props.xSmall ? '30px' :'36px'};
  height: ${(props: Props) =>
    props.big ? '120px' : props.medium ? '48px' : props.xSmall ? '30px': '36px'};
  min-width: ${(props: Props) => (props.big ? '120px' : props.xSmall ? '22px' :'36px')};
  min-height: ${(props: Props) => (props.big ? '120px' :props.xSmall ? '22px' : '36px')};
  border-radius: 50%;
  border: ${(props: Props) => (props.border ? props.border : '')};
  ${(props: Props) =>
    props.url
      ? css`
          background-color: transparent;
          background-image: ${(props: Props) => `url(${props.url})`};
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        `
      : css`
          background-color: ${({ theme }) => theme.colors.blue[800]};
          color: ${({ theme }) => theme.colors.grey.white};
          font-family: 'Rubik', sans-serif;
          font-size: ${(props: Props) =>
            props.big ? '60px' : props.medium ? '24px' :props.xSmall ? '16px': '18px'};
          text-align: center;
          line-height: ${(props: Props) =>
            props.big ? '120px' : props.medium ? '44px' :props.xSmall ? '32px' : '36px'}; ;
        `};
`;

export const StudioLogo = (props: Props) => {
  return (
    <StudioLogoImage {...props}>
      {props.url ? '' : props.name.charAt(0)}
    </StudioLogoImage>
  );
};

export const StudioInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
`;
