import styled from 'styled-components';

interface MainProps {
  sidebarVisible: boolean;
}

const Main = styled.main.attrs({
  className: 'main',
})<MainProps>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-areas: ${({ sidebarVisible }) =>
    sidebarVisible
      ? "'header header' 'sidebar content'"
      : "'header' 'content'"};
  grid-template-columns: ${({ sidebarVisible }) =>
    sidebarVisible ? '250px minmax(0, 1fr)' : '1fr'};
  grid-template-rows: 60px minmax(0, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  position: relative;
  transition: grid-template-columns 1s ease-in-out;

  .expand-button {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.grey.white};
    top: 124px;
    left: 0;
    max-width: 18px;
    height: 30px;
    color: #fff;
    border-right: 2px solid ${({ theme }) => theme.colors.grey[1100]};
    border-top: 2px solid ${({ theme }) => theme.colors.grey[1100]};
    border-bottom: 2px solid ${({ theme }) => theme.colors.grey[1100]};
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
    z-index: 100;
    transition: all 1s ease-in-out;
    padding: 0;
  }
`;

export default Main;
