import GamesOverviewGalleryModal from 'components/organisms/GamesOverviewGalleryModal';
import { MouseEvent, useState } from 'react';
import { OverviewGame, OverviewMedia } from 'types';
import {
  CardRow,
  CardRowLeft,
  CardRowRight,
  CardRowStatName,
  CardRowStatValue,
  CardRowThumbnail,
  CardRowTitle,
} from './PublishingCard.styles';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Logo, MediaRenderer, TagList } from 'components';
import { round } from 'lodash';

interface PublishingCardRowProps {
  data: OverviewGame;
}

export const PublishingCardRow = ({ data }: PublishingCardRowProps) => {
  const [selectedVideoIdx, setSelectedVideoIdx] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const handleThumbnailClick = (e: MouseEvent, thumbnailIndex: number) => {
    e.stopPropagation();
    setSelectedVideoIdx(thumbnailIndex);
    setShowModal(true);
  };

  const handleSlide = () => {
    const videos = document.querySelectorAll('video');
    videos.forEach(video => video.pause());
  };

  const filteredMedia =
    data.medias.length > 4 ? data.medias.slice(0, 4) : data.medias;

  const galleryModalItems: ReactImageGalleryItem[] = data.medias.map(
    (media: OverviewMedia) => ({
      original: media.url,
      thumbnail: media.thumbnail,
      description: media.type,
    })
  );
  return (
    <>
      <CardRow>
        <CardRowLeft
          to={`/studio/${data.studioId}/game/${data.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo src={data.logo} name={data.name} />
          <div className="card-block" style={{ flexGrow: 1 }}>
            <CardRowTitle>{data.name}</CardRowTitle>
            <TagList tags={data.tags ?? []} maxVisibleTags={1} />
          </div>
          <div
            className="card-block"
            style={{ textAlign: 'center', width: 80 }}
          >
            <CardRowStatName>IPM</CardRowStatName>
            <CardRowStatValue>
              {data.ipm ? round(data.ipm, 2) : '-'}
            </CardRowStatValue>
          </div>
          <div
            className="card-block"
            style={{ textAlign: 'center', width: 80 }}
          >
            <CardRowStatName>
              Test{data.testCount !== 1 ? 's' : null}
            </CardRowStatName>
            <CardRowStatValue>{data.testCount}</CardRowStatValue>
          </div>
        </CardRowLeft>
        <CardRowRight>
          {filteredMedia.map((media: OverviewMedia, idx: number) => (
            <CardRowThumbnail
              key={media.id}
              imageUrl={media.thumbnail}
              onClick={e => handleThumbnailClick(e, idx)}
            />
          ))}
          {data.medias.length > 4 && (
            <CardRowThumbnail onClick={e => handleThumbnailClick(e, 0)} />
          )}
        </CardRowRight>
      </CardRow>
      <GamesOverviewGalleryModal
        title={{
          ipm: data.ipm,
          testsPassed: data.testCount,
          gameIcon: data.logo,
          gameName: data.name,
          studioName: data.studioName,
        }}
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ImageGallery
          onSlide={handleSlide}
          items={galleryModalItems}
          startIndex={selectedVideoIdx}
          showPlayButton={false}
          showFullscreenButton={false}
          additionalClass="gallery-modal"
          renderItem={MediaRenderer}
        />
      </GamesOverviewGalleryModal>
    </>
  );
};
