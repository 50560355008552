import styled, { css } from 'styled-components';

interface OverviewListHeaderWrapperProps {
  gridTemplateColumns?: string;
  columnCount: number;
  overflowX?: boolean;
}

export const OverviewListHeaderWrapper = styled.div<OverviewListHeaderWrapperProps>`
  margin: 25px 0;
  display: grid;
  grid-template-columns: ${(props: OverviewListHeaderWrapperProps) =>
    props.gridTemplateColumns
      ? props.gridTemplateColumns
      : `repeat(${props.columnCount}, 1fr)`};
  grid-row-gap: 8px;
  ${({ overflowX }) => overflowX && 'overflow-x: auto;'}
`;

interface OverviewListColumnHeaderProps {
  width: number;
  sortable?: boolean;
}

export const OverviewListColumnHeader = styled.div<OverviewListColumnHeaderProps>`
  position: sticky;
  top: 0px;
  z-index: var(--z-index-video-container);
  background-color: ${({ theme }) => theme.colors.grey.offWhite};
  color: ${({ theme }) => theme.colors.grey[900]};
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  font-weight: 600;
  user-select: none;
  white-space: nowrap;
  padding: 0 4px;

  &:first-child {
    text-align: left;
    text-indent: 12px;
  }

  ${(props: OverviewListColumnHeaderProps) =>
    props.sortable &&
    css`
      cursor: pointer; ;
    `};
`;

interface OverviewListRowItemProps {
  ellipsize?: boolean;
  clickable?: boolean;
  height?: string;
}

export const OverviewListRowItem = styled.div<OverviewListRowItemProps>`
${(props: OverviewListRowItemProps) =>
  props.height &&
  css`
  height: 80px;
  `};
  position: relative;
  ${(props: OverviewListRowItemProps) =>
    props.clickable &&
    css`
      cursor: pointer;
    `};
  ${(props: OverviewListRowItemProps) =>
    props.ellipsize
      ? css`
          white-space: nowrap;
          text-overflow: ellipsis;
        `
      : css`
          overflow: inherit;
        `};
`;

interface OverviewListRowInlineImageProps {
  url?: string | null;
}

export const OverviewListRowInlineImage = styled.div<OverviewListRowInlineImageProps>`
  display: inline-block;
  float: left;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  margin: 7px 12px 7px 7px;
  background: ${({ theme }) => theme.colors.grey[1100]};
  border-radius: 4px;

  ${(props: OverviewListRowInlineImageProps) =>
    props.url &&
    css`
      background-image: ${(props: OverviewListRowInlineImageProps) =>
        `url(${props.url})`};
      background-size: cover;
    `};
`;

export const TableBlockPositionTop = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TableOverflowTextWrapper = styled.span`
  font-size: 14px;
  text-wrap: wrap;
  overflow-wrap: anywhere;
`;
