import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import './DateTimePicker.css';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minWidth: '150px', // Set the minimum width as needed
  }),
};

interface DateTimePickerProps {
  showTimeSelect?: boolean;
  onDateChange: (date: Date) => void;
  onTimeChange: (time: string) => void;
}

const DateTimePicker = ({
  showTimeSelect = false,
  onDateChange,
  onTimeChange,
}: DateTimePickerProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<any>(null);

  const handleDateChange = (date: Date) => {
    setStartDate(date);
    onDateChange(date);
  };

  const handleTimeChange = (option: any) => {
    setSelectedTime(option);
    onTimeChange(option.value);
  };

  const minDate = new Date();
  const maxDate = new Date(); // Set max date to current date
  maxDate.setMonth(maxDate.getMonth() + 1);

  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();

  const generateTimeOptions = () => {
    const timeOptions: any = [];
    if (!startDate) {
      return timeOptions;
    }
    const isToday = startDate?.getDate() === now.getDate();
    const startTime = isToday
      ? new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          currentHour,
          currentMinute
        )
      : new Date(0, 0, 0, 0, 0); // Set start time as current time for today's date, otherwise set it as 00:00

    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, '0');
      for (let j = 0; j < 60; j += 15) {
        const minute = j.toString().padStart(2, '0');
        const time = `${hour}:${minute}`;
        const timeValue = new Date(
          startDate?.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          i,
          j
        );

        if (!(isToday && timeValue < startTime)) {
          // Disable time options before current time for today's date
          timeOptions.push({ value: time, label: time });
        }
      }
    }

    return timeOptions;
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 0',
      }}
    >
      <div
        style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}
      >
        <span style={{ marginRight: '10px' }}>Date: </span>
        <DatePicker
          showTimeSelect={showTimeSelect}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat="dd.MM.yyyy"
          selected={startDate}
          onChange={date => date && handleDateChange(date)}
          placeholderText="Select date"
          className="custom-datepicker"
        />
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '10px' }}>Time: </span>
        <Select
          options={generateTimeOptions()}
          value={selectedTime}
          onChange={handleTimeChange}
          isDisabled={!startDate} // Disable time picker until a date is selected
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
