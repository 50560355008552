import { ReactNode } from 'react';
import { CohortBadge, CohortCollapseTitle } from '../Charts.styles';
import viewIcon from 'assets/images/view-icon.svg';

export const cohortCellGenerator = (
  cellValue: number | undefined,
  cohortAvgUnit: string
): ReactNode => {
  if (cellValue || cellValue === 0) {
    let opacity = 1;
    switch (true) {
      case 80 <= cellValue && cellValue <= 100:
        opacity = 1;
        break;
      case 60 <= cellValue && cellValue < 80:
        opacity = 0.8;
        break;
      case 40 <= cellValue && cellValue < 60:
        opacity = 0.6;
        break;
      case 20 <= cellValue && cellValue < 40:
        opacity = 0.4;
        break;
      case 0 < cellValue && cellValue < 20:
        opacity = 0.2;
        break;
        case 0 === cellValue:
        opacity = 0.15;
        break;
      default:
        break;
    }

    return (
      <CohortBadge bgOpacity={opacity}>
        {cohortAvgUnit === '%' || cohortAvgUnit === 's'
          ? cellValue + cohortAvgUnit
          : cohortAvgUnit + cellValue}
      </CohortBadge>
    );
  } else return null;
};

export const cohortCollapseTitle = (
  <CohortCollapseTitle>
    <img className="icon" src={viewIcon} alt="view" />
    <span>Cohort data</span>
  </CohortCollapseTitle>
);
