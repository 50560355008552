import * as Yup from 'yup';

const decimalValidation =
  (decimalDigits: number) => (value: number | undefined) => {
    if (value !== undefined && value !== null) {
      const decimalPart = value.toString().split('.')[1];
      if (decimalPart && decimalPart.length > decimalDigits) {
        return false;
      }
    }
    return true;
  };

export const facebookSchema = Yup.object().shape({
  dailyBudget: Yup.number()
    .typeError('Daily budget must be a number')
    .test(
      'decimalDigits',
      'Maximum two decimal digits allowed',
      decimalValidation(2)
    )
    .min(
      1,
      'The daily budget must be between $1 and $1000. Please, fill in the correct value.'
    )
    .max(
      1000,
      'The daily budget must be between $1 and $1000. Please, fill in the correct value.'
    ),
  daysOfCampaign: Yup.number()
    .typeError('Days of campaign must be a number')
    .min(
      2,
      'The days of the campaign must be between 2 and 7. Please, fill in the correct value.'
    )
    .max(
      7,
      'The days of the campaign must be between 2 and 7. Please, fill in the correct value.'
    ),
  country: Yup.object()
    .shape({
      label: Yup.string().required('Country is required'),
      value: Yup.string().required('Country is required'),
    })
    .nullable(true)
    .required('Country is a required field'),
  // totalBudget is for validation only
  totalBudget: Yup.number().test(
    'greaterThan100',
    'The total budget must be more than $100. Please, increase days of campaigns or daily budget.',
    function () {
      const {
        dailyBudget,
        daysOfCampaign,
      }: { dailyBudget: number; daysOfCampaign: number } = this.parent;
      const product = dailyBudget * daysOfCampaign;
      return product > 100;
    }
  ),
});

export const unitySchema = Yup.object().shape({
  totalBudget: Yup.number()
    .typeError('Total budget must be a number')
    .test(
      'decimalDigits',
      'Maximum two decimal digits allowed',
      decimalValidation(2)
    )
    .min(
      100,
      'The total budget must be between $100 and $7000. Please, fill in the correct value.'
    )
    .max(
      7000,
      'The total budget must be between $100 and $7000. Please, fill in the correct value.'
    ),
  dailyBudget: Yup.number()
    .typeError('Daily budget must be a number')
    .test(
      'decimalDigits',
      'Maximum two decimal digits allowed',
      decimalValidation(2)
    )
    .min(
      1,
      'The daily budget must be between $1 and $1000. Please, fill in the correct value.'
    )
    .max(
      1000,
      'The daily budget must be between $1 and $1000. Please, fill in the correct value.'
    ),
  bid: Yup.number()
    .typeError('Bid must be a number')
    .test(
      'decimalDigits',
      'Maximum 3 decimal digits allowed',
      decimalValidation(3)
    )
    .min(
      0.001,
      'The bid must be between $0.001 and $100. Please, fill in the correct value.'
    )
    .max(
      100,
      'The bid must be between $0.001 and $100. Please, fill in the correct value.'
    ),
  country: Yup.object()
    .shape({
      label: Yup.string().required('Country is required'),
      value: Yup.string().required('Country is required'),
    })
    .nullable(true)
    .required('Country is a required field'),
});
