import { SidebarType } from 'types';

export const sidebarTypeGenerator = (
  path: string,
  isPublisher: boolean = false
): SidebarType =>
  path.includes('/test/') || path.includes('/new-test')
    ? 'campaign'
    : path.includes('/game/') || path.includes('/tests')
    ? 'game'
    : path.includes('/games')
    ? 'studio'
    : path.includes('/studio') && !isPublisher // This 'else if' has split because of the more readability.
    ? 'game'
    : 'studio';

    