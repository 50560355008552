import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: error => {
        // toast.error('The error message will place here.')
        console.log(error);
      },
    },
    mutations: {
      retry: false,
      onError: error => {
        // toast.error('The error message will place here.')
        console.log(error);
      },
    },
  },
});

const ReactQuery = ({ children }: { children: JSX.Element }) => (
  <QueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
  </QueryClientProvider>
);

export default ReactQuery;
