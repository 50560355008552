import { Button } from 'components/atoms';
import styled from 'styled-components';

export const InvoicesOverviewWrapper = styled.div`
  padding: 40px;
`;

export const InvoicesOverviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InvoicesCounter = styled.div`
  color: ${({ theme }) => theme.colors.grey[900]};
  display: block;
  float: left;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 10px;
`;
export const InvoicesSum = styled.div`
  color: ${({ theme }) => theme.colors.grey[900]};
  display: block;
  float: left;
  font-size: 16px;
`;

export const AddInvoicesButton = styled.button`
  border: 2px solid ${({ theme }) => theme.colors.blue[500]};
  border-radius: 4px;
  opacity: 1;
  width: 136px;
  height: 38px;
  font-style: normal;
  font-variant: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.blue[500]};
  background: inherit;
  float: right;

  &:hover {
    background: ${({ theme }) => theme.colors.blue[500]} 0% 0% no-repeat
      padding-box;
    color: ${({ theme }) => theme.colors.grey.white};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
    color: ${({ theme }) => theme.colors.grey.white};
    cursor: default;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #afafaf;
`;
export const InvoiceDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LeftBoxHeader = styled.div`
  display: flex;
  align-items: center;
`;
export const PlatformIcon = styled.img`
  filter: invert(51%) sepia(27%) saturate(1718%) hue-rotate(171deg)
    brightness(104%) contrast(94%);
  height: 19px;
  width: auto;
  margin: 0 5px;
`;
interface CommentIconProps {
  cursor: string;
}
export const CommentIcon = styled.img<CommentIconProps>`
  cursor:  ${props => props.cursor};;
  min-width: 22px;
  margin: 0px 2px;
`;
export const ToggleButton = styled.a`
  color: ${({ theme }) => theme.colors.blue[500]};
  text-transform: uppercase;
  line-height: 60px;
  cursor: pointer;
`;

export const OverviewHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  column-gap: 18px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ActionsContainer = styled.div`
  margin-right: 8px;
`;
export const ModalTitle = styled.div`
  font-size: 18px;
  padding: 12px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-evenly;
`;
export const ModalButton = styled.button`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  background-color: ${props => props.color};
  border-radius: 4px;
  border: 0;
  padding: 10px 24px;
  color: ${({ theme }) => theme.colors.grey.white};
`;

export const ReviewModalHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
  gap: 4px;

  .label {
    font-weight: 600;
  }

  .value {
    font-weight: 400;
  }
`;

export const ApproveModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
`;
export const ApproveModalSpanText = styled.span`
  margin-left: 4px;
  color: #9f9d9d;
  font-size: 16px;
  font-weight: 400;
`;

export const ReviewModalContainer = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
  padding: 40px 20px 5px 20px;
  height: 100%;
`;
export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface StyledButtonProps {
  color: string;
}
export const StyledRejectButton = styled(Button)<StyledButtonProps>`
  color: ${props => props.color};
  border: 2px solid ${props => props.color};
  width: 188px;
  margin-right: 25px;
`;
export const IconButton = styled(Button)`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
`;

export const StyledAgreementCell = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  min-width: 200px;
  max-width: 300px;

  .title {
    font-weight: 700;
    margin-bottom: 5px;
    overflow-wrap: break-word;
  }

  .subtitle {
    font-size: 12px;
    color: #afafaf;
    overflow-wrap: break-word;
  }
`;
export const ApproveModalContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px 20px 5px 20px;
`;
export const TextArea = styled.textarea`
  height: 120px;
  width : 100%;
  padding: 10px;
  border-color: #dfdfdf;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  resize: none;
  outline: none;
  ::placeholder {
    color: #dfdfdf;
  }
`;
