import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-modal-backdrop);
  background-color: ${({ theme }) => theme.colors.grey.black};
  opacity: 0.5;
`;
