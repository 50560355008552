export type BenchmarkLevels = 'High' | 'Medium' | 'Low' | null;

export enum VideoReviewStatus {
  untested = 'UNTESTED',
  inReview = 'IN_REVIEW',
  reviewPassed = 'REVIEW_PASSED',
  reviewFailed = 'REVIEW_FAILED',
  withIssues = 'WITH_ISSUES',
  reviewPassedWithDelay = 'REVIEW_PASSED_WITH_DELAY',
}

export enum VideoReviewStatusTooltip {
  reviewFailed = 'The video has failed to pass Facebook video review.',
  withIssues = 'Ad is misconfigured. Contact your manager to solve this.',
  reviewPassedWithDelay = 'The video has passed review later than the campaign was started. It might get less attention, hence lower KPIs.',
}

export enum MediaType {
  UNITY_LANDSCAPE_VIDEO = 'unityLandscapeVideo',
  UNITY_PORTRAIT_VIDEO = 'unityPortraitVideo',
  FACEBOOK_VIDEO = 'facebookVideo',
  UNITY_IMAGE = 'unityImage',
}

export enum PackMediaEnum {
  portraitVideo = 'portraitVideo',
  landscapeVideo = 'landscapeVideo',
  image = 'image',
}

export interface PackMediaObjType {
  mediaId: number;
  creativePackName: string;
  mediaThumbnail: string;
}

export interface PackMediaByCategoryResponse {
  unityPortraitVideo: PackMediaObjType[];
  unityLandscapeVideo: PackMediaObjType[];
  unityImage: PackMediaObjType[];
}
export enum TestMediaType {
  'VIDEO',
  'CREATIVE_PACK',
}

export enum IPMSortOrder {
  'NONE',
  'ASC',
  'DESC',
}

export type IPM = {
  value: number;
  benchmark: BenchmarkLevels;
} | null;

export interface VideoFile {
  id: number;
  gameId: number;
  type: string;
  name: string;
  url: string;
  thumbnail: string;
  ipm: IPM;
  createdAt: string;
  reviewStatus: VideoReviewStatus;
  hasLowImpressions: boolean;
  updatedAt: string | null;
  deletedAt: string | null;
}

export enum MediaSortType {
  DATE = 'date',
  IPM = 'ipm',
}
export interface FetchMediasProps {
  gameId: number;
  sortBy: MediaSortType;
}

export interface MediaByDateGeneric<T> {
  today: T[];
  'last 7 days': T[];
  'last 30 days': T[];
  old: T[];
}

export const mediaByDateTitle = {
  today: '-- Today --',
  'last 7 days': '-- Last Week --',
  'last 30 days': '-- Last Month --',
  old: '-- Older --',
};

export interface FetchMediasResponse<T> {
  data: T;
  status: boolean;
}

export enum MediaBenchmark {
  high = 'High',
  medium = 'Medium',
  low = 'Low',
}

export interface CreativePackMedia {
  id: number;
  name: string;
  url: string;
  thumbnail: string;
}

interface CreativePackMediaType {
  unityPortraitVideo?: CreativePackMedia;
  unityLandscapeVideo?: CreativePackMedia;
  unityImage?: CreativePackMedia;
}

export interface CreativePackType {
  id: number;
  name: string;
  ipm: IPM;
  reviewStatus: VideoReviewStatus;
  medias: CreativePackMediaType;
}

export interface CreativePackPayload {
  name: string;
  mediaIds: number[];
}
