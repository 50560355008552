import { Line } from 'react-chartjs-2';
import moment from 'moment';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index' as 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    xAxes: {
      offset: true,
      grid: {
        drawBorder: false,
        lineWidth: 0,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
      },
    },
  },
};

export const LineChart = ({
  labels,
  data,
  customScaleOptions = {},
  customElementsOptions = {},
  fill = false,
}: {
  labels: string[];
  data: (number | null)[];
  customScaleOptions?: object;
  customElementsOptions?: object;
  fill?: boolean;
}) => {
  const today = moment(new Date()).format('DD.MM');
  const todayIndex =
    labels.indexOf(today) === -1 ? 1000 : labels.indexOf(today);
  const dottedGenerator = (ctx: any) =>
    ctx.p1DataIndex >= todayIndex ? [6, 6] : [6, 0];

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        borderColor: '#57B4F9',
        pointBorderColor: '#57B4F9',
        pointBackgroundColor: '#57B4F9',
        segment: {
          borderDash: (ctx: any) => dottedGenerator(ctx),
        },
        spanGaps: true,
        fill: fill
          ? {
              target: 'origin',
            }
          : {},
      },
    ],
  };

  return (
    <Line
      options={{
        clip: false,
        ...options,
        scales: { ...options.scales, ...customScaleOptions },
        elements: { ...customElementsOptions },
      }}
      data={chartData}
      height={400}
    />
  );
};
