import styled from 'styled-components';

export const SidebarBackTitle = styled.div`
  font-family: 'Rubik', sans-serif;
  color: ${({ theme }) => theme.colors.grey[900]};
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
