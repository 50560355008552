export interface ArchiveResponse {
  status: boolean;
}

export enum ArchivePageName {
  studio = 'studios',
  game = 'games',
}

export enum InvoiceActions {
  archive = 'archive',
  unarchive = 'unarchive',
}