import { css, FlattenSimpleInterpolation } from 'styled-components';

export interface Margin {
  m?: number;
  mx?: number;
  ml?: number;
  mr?: number;
  my?: number;
  mt?: number;
  mb?: number;
}

export const margin = (
  margin: Margin,
  baseSpacing: number
): FlattenSimpleInterpolation | undefined => {
  const { m, mx, ml, mr, my, mt, mb } = margin;

  const marginAll =
    m !== undefined
      ? css`
          margin: ${m * baseSpacing}px;
        `
      : undefined;

  const marginLeft =
    ml !== undefined
      ? css`
          margin-left: ${ml * baseSpacing}px;
        `
      : mx !== undefined
      ? css`
          margin-left: ${mx * baseSpacing}px;
        `
      : undefined;

  const marginRight =
    mr !== undefined
      ? css`
          margin-right: ${mr * baseSpacing}px;
        `
      : mx !== undefined
      ? css`
          margin-right: ${mx * baseSpacing}px;
        `
      : undefined;

  const marginTop =
    mt !== undefined
      ? css`
          margin-top: ${mt * baseSpacing}px;
        `
      : my !== undefined
      ? css`
          margin-top: ${my * baseSpacing}px;
        `
      : undefined;

  const marginBottom =
    mb !== undefined
      ? css`
          margin-bottom: ${mb * baseSpacing}px;
        `
      : my !== undefined
      ? css`
          margin-bottom: ${my * baseSpacing}px;
        `
      : undefined;

  const margins = css`
    ${marginAll}
    ${marginLeft}
      ${marginRight}
      ${marginTop}
      ${marginBottom}
  `;

  return margins;
};
