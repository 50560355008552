import { PageHeaderContainer } from 'components/atoms';
import {
  StudioContact,
  StudioContacts,
  StudioInfo,
  StudioLogoInfoContainer,
  StudioOptions,
} from './StudioHeader.style';
import Skeleton from 'components/Skeleton/Skeleton';

const StudioHeaderSkeleton = () => {
  return (
    <PageHeaderContainer>
      <StudioLogoInfoContainer>
        <Skeleton
          style={{ borderRadius: '50%' }}
          width="120px"
          height="120px"
        />
        <StudioInfo
          style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
        >
          <Skeleton width="200px" height="38px" />
          <Skeleton width="180px" height="16px" />
          <Skeleton width="180px" height="16px" />
          <Skeleton width="180px" height="16px" />
        </StudioInfo>
      </StudioLogoInfoContainer>
      <StudioContacts>
        <StudioContact style={{ gap: 10, flexDirection: 'column' }}>
          <Skeleton width="140px" height="16px" />
          <Skeleton width="180px" height="24px" />
        </StudioContact>
        <StudioContact style={{ gap: 10, flexDirection: 'column' }}>
          <Skeleton width="140px" height="16px" />
          <Skeleton width="180px" height="24px" />
        </StudioContact>
      </StudioContacts>
      <StudioOptions></StudioOptions>
    </PageHeaderContainer>
  );
};

export default StudioHeaderSkeleton;
