import { ActivityType } from 'types';
import {
  facebookRequest,
  testFinished,
  testProcessed,
  testProcessing,
  testTerminatedFailed,
  userAction,
  videoFailed,
  videoPassed,
} from 'assets/images';

export const activityIcons = {
  [ActivityType.request]: userAction,
  [ActivityType.activated]: userAction,
  [ActivityType.process]: testProcessing,
  [ActivityType.created]: testProcessed,
  [ActivityType.creationFailed]: testTerminatedFailed,
  [ActivityType.videoUploadFailed]: testTerminatedFailed,
  [ActivityType.fbCampaignCreationFailed]: testTerminatedFailed,
  [ActivityType.jtCampaignCreationFailed]: testTerminatedFailed,
  [ActivityType.scbCampaignCreationFailed]: testTerminatedFailed,
  [ActivityType.creativePackAssignFailed]: testTerminatedFailed,
  [ActivityType.scbCampaignUpdateFailed]: testTerminatedFailed,
  [ActivityType.scbCpiBidUpdateFailed]: testTerminatedFailed,
  [ActivityType.adSetCreationFailed]: testTerminatedFailed,
  [ActivityType.terminated]: testTerminatedFailed,
  [ActivityType.cancelled]: testTerminatedFailed,
  [ActivityType.completed]: testFinished,
  [ActivityType.videoUpload]: facebookRequest,
  [ActivityType.videoUploaded]: facebookRequest,
  [ActivityType.getVideoThumbnailsRequest]: facebookRequest,
  [ActivityType.getVideoThumbnailsResponse]: facebookRequest,
  [ActivityType.fbCampaignCreateRequest]: facebookRequest,
  [ActivityType.scbCampaignCreateRequest]: facebookRequest,
  [ActivityType.fbCampaignCreated]: facebookRequest,
  [ActivityType.scbCampaignCreated]: facebookRequest,
  [ActivityType.adSetCreateRequest]: facebookRequest,
  [ActivityType.adSetCreated]: facebookRequest,
  [ActivityType.adCreativeCreateRequest]: facebookRequest,
  [ActivityType.creativePackAssignRequest]: facebookRequest,
  [ActivityType.creativePackAssigned]: facebookRequest,
  [ActivityType.scbUpdateBudgetRequest]: facebookRequest,
  [ActivityType.scbUpdateTargetingOptionsRequest]: facebookRequest,
  [ActivityType.scbTargetingOptionsUpdated]: facebookRequest,
  [ActivityType.scbTargetingOptionsUpdateFailed]: facebookRequest,
  [ActivityType.scbBudgetUpdated]: facebookRequest,
  [ActivityType.scbUpdateCpiBidRequest]: facebookRequest,
  [ActivityType.scbCpiBidUpdated]: facebookRequest,
  [ActivityType.adCreativeCreateResponse]: facebookRequest,
  [ActivityType.videoReviewFailed]: videoFailed,
  [ActivityType.videoReviewPassed]: videoPassed,
  [ActivityType.campaignAdCreateRequest]: facebookRequest,
  [ActivityType.campaignAdCreateResponse]: facebookRequest,
  [ActivityType.justrackCreateCampaignRequest]: facebookRequest,
  [ActivityType.justrackCampaignCreated]: facebookRequest,
  [ActivityType.campaignChangeStatus]: userAction,
  [ActivityType.campaignDescriptionAdd]: userAction,
  [ActivityType.campaignDescriptionEdit]: userAction,
  [ActivityType.campaignDescriptionRemove]: userAction,
  [ActivityType.campaignChangeStatusFailed]: testTerminatedFailed,
} as { [key in ActivityType]: string };
