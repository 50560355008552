import styled from 'styled-components';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey.white};
  padding: 16px;

  .heading {
    margin: 16px 0;
    padding: 16px;
    height: 70px;
    background-color: ${({ theme }) => theme.colors.pink[500]};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 30px;
    }
  }

  .no-data {
    color: ${({ theme }) => theme.colors.grey[1200]};
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }

  .newsletter-spotlight {
    border-radius: 10px;
    height: 600px;
    display: flex;
    flex-direction: column;

    .top {
      padding: 20px 24px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;

      .left {
        height: 36px;
        display: flex;
        flex-direction: column;

        .new-badge {
          background-color: #59bc72;
          width: 60px;
          height: 20px;
          border-radius: 4px;
          padding: 5px 20px;
          margin-left: 10px;
        }
      }

      .img {
        border-radius: 10px;
        position: relative;
        bottom: -30px;
      }

      .right {
        display: flex;
        flex-direction: column-reverse;

        .link {
          height: 60px;
          width: 180px;
          padding: 11px;
          border-radius: 4px;
          color: ${({ theme }) => theme.colors.grey.black};
          background-color: ${({ theme }) => theme.colors.orange[500]};
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .bottom {
      background-color: rgba(255, 255, 255, 0.7);
      color: ${({ theme }) => theme.colors.grey.black};
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 100px;
      text-align: center;
      display: flex;
      flex-direction: column;

      .title {
        margin-top: 30px;
        margin-bottom: 10px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .newsletter-grid {
    margin: 20px 0;
    display: grid;
    grid-gap: 20px;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
