import styled from 'styled-components';
import { IPM } from 'types';

const Wrapper = styled.div`
  position: relative;
  height: 200px;
  width: 140px;
  border-radius: 16px;
  margin: 10px;
`;

export const UploadWrapper = styled(Wrapper)`
  color: ${({ theme }) => theme.colors.blue[500]};
  background-color: ${({ theme }) => theme.colors.blue[700]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px dashed;

  .upload-input {
    display: none;
  }

  .upload-label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .upload-icon {
    font-size: 38px;
  }

  .upload-text {
    font-size: 18px;
  }
`;

export const ProgressWrapper = styled(Wrapper)`
  position: relative;
  color: #bebebe;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ProgressBarProps {
  percentage: number;
}

export const ProgressBar = styled.div<ProgressBarProps>`
  position: absolute;
  bottom: 0;
  background: #eeeeee;
  height: 16px;
  width: 100%;
  pointer-events: none;
  overflow: hidden;
  border-radius: 0 0 16px 16px;

  ::after {
    content: '';
    display: block;
    background: #bebebe;
    width: ${(props: ProgressBarProps) =>
      props.percentage ? `${props.percentage}%` : 0};
    height: 100%;
  }
`;

interface MediaWrapperProps {
  ipm: IPM;
}

export const MediaWrapper = styled(Wrapper)<MediaWrapperProps>`
  cursor: pointer;
  .thumbnail-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 16px;
  }

  .thumbnail-unselected {
    opacity: 0.4;
  }

  .thumbnail-badge {
    position: absolute;
    top: 20px;
    left: 15px;
    height: 20px;
    min-width: 40px;
    padding: 2px 3px;
    border-radius: 4px;

    &:not(.high, .medium, .low) {
      color: #a5acae;
      background-color: #eeeeee;
    }

    &.high {
      color: ${({ theme }) => theme.colors.blue[500]};
      background-color: #d5ecfe;
    }

    &.medium {
      color: ${({ theme }) => theme.colors.orange[500]};
      background-color: #fff2d5;
    }

    &.low {
      color: ${({ theme }) => theme.colors.pink[500]};
      background-color: ${({ theme }) => theme.colors.pink[700]};
    }
  }

  .thumbnail-error-border {
    border: 4px solid #eb3347;
  }

  .thumbnail-error-icon-wrapper {
    position: absolute;
    bottom: -4px;
    right: -6px;

    .thumbnail-error-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 600;
      background-color: #eb3347;
      color: ${({ theme }) => theme.colors.grey.white};
      z-index: var(--z-index-media-error-border);
    }
  }

  .thumbnail-remove-button {
    position: absolute;
    top: -10px;
    right: -10px;
    color: darkgray;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.grey.white};
    border-radius: 50%;

    &:hover {
      color: ${({ theme }) => theme.colors.pink[500]};
    }
  }

  .thumbnail-checkbox {
    position: absolute;
    left: 10px;
    bottom: 6px;
    z-index: 3;
  }

  .thumbnail-checkbox-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 150px;
    background-image: linear-gradient(180deg, transparent, black);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

export const PopupTitle = styled.div`
  padding: 12px;
  color: darkgray;
  font-weight: bold;
  text-align: center;
`;

export const PopupContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const Button = styled.button`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  background-color: ${props => props.color};
  border-radius: 4px;
  border: 0;
  padding: 10px 24px;
  color: ${({ theme }) => theme.colors.grey.white};
`;
