import {
  BusinessChartType,
  ChurnRatePerLevel,
  LevelProgression,
  LevelProgressionResponse,
} from 'types';
import styled from 'styled-components';
import { Button, MultipleCharts, Spinner } from 'components/atoms';
import { chartTitleGenerator } from './utils/GlobalUtilsTsx';
import viewIcon from 'assets/images/view-icon.svg';
import { Collapse } from 'components';
import {
  ChartContainer,
  ChartInnerContainer,
  ChartNoDataContainer,
  ChartInfoContainer,
  CohortCollapseTitle,
} from './Charts.styles';
import { useTheme } from 'styled-components';
import { round } from 'lodash';
import { useState } from 'react';
import OverviewList from 'components/OverviewList/OverviewList';
import { useFetchLevelProgression } from 'api';
import { useParams } from 'react-router-dom';
import { SpinnerContainer } from 'assets/styles';
import { getChartTitleAndTooltip } from './utils/GlobalUtilsTs';

interface ChartProps {
  levelData: LevelProgression[];
  churnData: ChurnRatePerLevel[];
  appVersion: string | number | undefined;
}

interface SourceData {
  level: string;
  churnRatePerLevelData: number;
  levelProgressionData: number;
}

const LevelProgressionChart = ({
  levelData,
  churnData,
  appVersion,
}: Partial<ChartProps>) => {
  const theme = useTheme();
  const params = useParams<{
    gameId: string;
    campaignId: string;
  }>();
  const [size, setSize] = useState(50);
  const [data, setData] = useState({
    levelProgression: levelData,
    churnRatePerLevel: churnData,
  });
  const {
    refetch: fetchLevelProgression,
    isFetching: isLevelPogressionLoading,
  } = useFetchLevelProgression(
    Number(params.gameId),
    Number(params.campaignId),
    size,
    appVersion,
    {
      onSuccess: (data: LevelProgressionResponse) => {
        if (
          data.levelProgression.length > 0 &&
          data.churnRatePerLevel.length > 0
        )
          setData({
            levelProgression: data.levelProgression,
            churnRatePerLevel: data.churnRatePerLevel,
          });
      },
      enabled: false,
    }
  );
  const [collapse, setCollapse] = useState(true);

  const handleSize = () => {
    setSize(size => size + 50);
    fetchLevelProgression();
  };

  // LevelProgression Chart
  const levelProgression = (data.levelProgression ?? []) as LevelProgression[];
  const levelProgressionHasData = levelProgression.length > 0;

  let levelProgressionData = [] as number[];
  let levelProgressionLabel = [] as string[];
  let levelProgressionColor = [] as string[];

  if (levelProgressionHasData) {
    levelProgressionData = levelProgression.map(level =>
      round(level.usersOfStepOne, 1)
    );
    levelProgressionLabel = levelProgression.map(level => level.event);
    levelProgressionColor = levelProgression.map(level =>
      level.event === 'Level_10' || level.event === 'Level 10'
        ? '#8144fd'
        : '#57B4F9'
    );
  }

  // ChurnRatePerLevel Chart
  const churnRatePerLevel = (data.churnRatePerLevel ??
    []) as ChurnRatePerLevel[];
  const churnRatePerLevelHasData = churnRatePerLevel.length > 0;

  let churnRatePerLevelData = [] as number[];
  let churnRatePerLevelLabel = [] as string[];

  if (churnRatePerLevelHasData) {
    churnRatePerLevelData = churnRatePerLevel.map(churnRate =>
      round(churnRate.churnRate, 1)
    );
    churnRatePerLevelLabel = churnRatePerLevel.map(
      churnRate => churnRate.event
    );
  }

  const chartData = {
    labels:
      levelProgressionLabel.length > churnRatePerLevelLabel.length
        ? levelProgressionLabel
        : churnRatePerLevelLabel,
    datasets: [
      {
        type: 'line' as const,
        label: 'Churn Rate Per Level (%)',
        data: churnRatePerLevelData,
        yAxisID: 'churnRatePerLevel',
        pointStyle: 'circle',
        borderColor: '#ffc043',
        backgroundColor: '#ffc043',
      },
      {
        type: 'bar' as const,
        label: 'Level Progression (%)',
        data: levelProgressionData,
        yAxisID: 'levelProgression',
        maxBarThickness: 60,
        backgroundColor:
          levelProgressionColor.length > 0 ? levelProgressionColor : '#57B4F9',
      },
    ],
  };

  const customOptions = {
    levelProgression: {
      type: 'linear',
      position: 'left',
      min: 0,
      max: 100,
      ticks: {
        count: 5,
        color: '#57B4F9',
        callback: function (label: number) {
          return `${label}%`;
        },
      },
    },
    churnRatePerLevel: {
      type: 'linear',
      position: 'right',
      min: 0,
      ticks: {
        count: 5,
        color: `${theme.colors.orange[500]}`,
        callback: function (label: number) {
          return `${label}%`;
        },
      },
    },
  };

  const sourceTableData = churnRatePerLevelData.map(
    (element, index): SourceData => ({
      level: `${index + 1}`,
      churnRatePerLevelData: element,
      levelProgressionData: levelProgressionData[index],
    })
  );

  const invoiceTableRows = (sourceDatas: SourceData[]) => {
    return sourceDatas.map((sourceData: SourceData) => ({
      id: sourceData.level,
      link: '',
      relatedToUser: false,
      rows: [
        {
          columnId: 'level',
          content: <div>{sourceData.level}</div>,
          sortableContent: Number(sourceData.level),
        },
        {
          columnId: 'levelProgressionData',
          content: (
            <div>
              {sourceData.levelProgressionData
                ? `${round(sourceData.levelProgressionData, 1)}%`
                : '-'}
            </div>
          ),
          sortableContent: sourceData.levelProgressionData
            ? sourceData.levelProgressionData
            : 0,
        },
        {
          columnId: 'churnRatePerLevelData',
          content: (
            <div>
              {' '}
              {sourceData.churnRatePerLevelData
                ? `${round(sourceData.churnRatePerLevelData, 1)}%`
                : '-'}{' '}
            </div>
          ),
          sortableContent: sourceData.churnRatePerLevelData
            ? sourceData.churnRatePerLevelData
            : 0,
        },
      ],
    }));
  };

  const hasData = levelProgressionHasData || churnRatePerLevelHasData;

  const { title, tooltip } = getChartTitleAndTooltip(
    BusinessChartType.LEVEL_PROGRESSION
  );
  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
        {hasData && (
          <div>
            <Button onClick={handleSize}>{`See ${
              size === 50 ? 25 : 50
            } more levels`}</Button>
          </div>
        )}
      </ChartInfoContainer>
      <ChartInnerContainer>
        {!hasData && <ChartNoDataContainer>No Data</ChartNoDataContainer>}
        {isLevelPogressionLoading ? (
          <SpinnerContainer>
            <Spinner size={48} color="#bebebe" />
          </SpinnerContainer>
        ) : (
          <MultipleCharts
            chartData={chartData}
            customScaleOptions={customOptions}
          />
        )}
      </ChartInnerContainer>
      {hasData && (
        <CollapsContainer>
          <Collapse
            open={collapse}
            onToggle={setCollapse}
            title={cohortCollapseTitle}
            mt={5}
          >
            <CollapsContainer2>
              <OverviewList
                columns={[
                  {
                    id: 'level',
                    name: `Level`,
                    width: 10,
                    sortable: true,
                    firstColJustify: 'space-evenly',
                  },

                  {
                    id: 'levelProgressionData',
                    name: 'Level Progression',
                    width: 10,
                    sortable: true,
                  },
                  {
                    id: 'churnRatePerLevelData',
                    name: 'Churn Rate Per Level',
                    width: 10,
                    sortable: true,
                  },
                ]}
                blocks={[[...invoiceTableRows(sourceTableData)]]}
                gameCount={25}
                noDataText={'No source Data'}
              />
            </CollapsContainer2>
          </Collapse>
        </CollapsContainer>
      )}
    </ChartContainer>
  );
};

export default LevelProgressionChart;

const cohortCollapseTitle = (
  <CohortCollapseTitle>
    <img className="icon" src={viewIcon} alt="view" />
    <span>Source Data</span>
  </CohortCollapseTitle>
);

const CollapsContainer = styled.div`
  background-color: #f6f6f6;
`;
const CollapsContainer2 = styled.div`
  max-height: 650px;
  overflow-y: auto;
`;
