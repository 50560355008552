import {
  cohortIndexArrayGenerator,
  cohortLabelsGenerator,
  getChartTitleAndTooltip,
} from 'pages/Test/Charts/utils/GlobalUtilsTs';
import { BusinessChartType } from 'types';
import {
  ChartContainer,
  ChartInfoContainer,
  ChartInnerContainer,
} from '../CompareChart.styles';
import { chartTitleGenerator } from 'pages/Test/Charts/utils/GlobalUtilsTsx';
import { Line } from 'react-chartjs-2';
import { chartOptions, formatAppVersion } from '../CompareChart.utils';
import { round } from 'lodash';
import { useCampaignAndAppVersionParams } from 'hooks';

const Colors = {
  campaignOne: '#2da1f8',
  campaignTwo: '#ffc043',
};

interface SundayRetentionCompareProps {
  dataOne: number[];
  dataTwo: number[];
}

const SundayRetentionCompare: React.FC<SundayRetentionCompareProps> = ({
  dataOne,
  dataTwo,
}) => {
  const { campaignName1, campaignName2, appVersion1, appVersion2 } =
    useCampaignAndAppVersionParams();
  const numOfCols = Math.max(dataOne.length, dataTwo.length);
  const labels = cohortLabelsGenerator(
    cohortIndexArrayGenerator(numOfCols, 1),
    'Day '
  ).slice(0, 14);
  const datasets = [
    {
      backgroundColor: Colors.campaignOne,
      borderColor: Colors.campaignOne,
      label: `${campaignName1} - ${formatAppVersion(appVersion1)}`,
      data: dataOne.slice(0, 14),
    },
    {
      backgroundColor: Colors.campaignTwo,
      borderColor: Colors.campaignTwo,
      label: `${campaignName2} - ${appVersion2}`,
      data: dataTwo.slice(0, 14),
    },
  ];

  const { title, tooltip } = getChartTitleAndTooltip(
    BusinessChartType.SUNDAY_RETENTION
  );

  const modifiedChartOptions = {
    ...chartOptions,
    scales: {
      xAxes: chartOptions.scales.xAxes,
      yAxes: {
        ticks: {
          callback: (label: string | number) => `${round(Number(label))}%`,
        },
      },
    },
  };

  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
      </ChartInfoContainer>
      <ChartInnerContainer>
        <Line
          options={modifiedChartOptions}
          data={{ labels, datasets }}
          height={400}
        />
      </ChartInnerContainer>
    </ChartContainer>
  );
};

export default SundayRetentionCompare;
