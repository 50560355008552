import {
  StudioContact,
  StudioContactAction,
  StudioContactActionIcon,
  StudioContactHeader,
  StudioContactName,
  StudioContactNameShortener,
  StudioContacts,
  StudioHeaderLink,
  StudioInfo,
  StudioInfoIcon,
  StudioInfoIconExternal,
  StudioInfoLine,
  StudioInfoLineExternal,
  StudioInfoName,
  StudioInfoString,
  StudioInfoStringExternal,
  StudioLogoInfoContainer,
  StudioOptions,
} from './StudioHeader.style';
import { useRef, useState } from 'react';
import {
  PageHeaderBurgerMenu,
  PageHeaderContainer,
  Popup,
  PopupAnchorButton,
  PopupButton,
  PopupButtonContainer,
  PopupButtonText,
  PopupPosition,
  StudioLogo,
} from 'components/atoms';
import { MdModeEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { InfoModal } from 'components/molecules';
import { Modal } from 'components';
import styled, { useTheme } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useFetchPaymentDetails, usePostArchive, usePostUnarchive } from 'api';
import {
  AccessFeatures,
  AllPaymentDetails,
  ArchivePageName,
  IndianPaymentDetails,
  ModalSize,
  ServerQueryKey,
  Studio,
} from 'types';
import { useFetchUser } from 'api/modules/useUser';
import errorIcon from 'assets/images/error_icon.svg';
import TestError from 'pages/Test/TestError';
import archiveIcon from 'assets/images/pagesHeader/archive.svg';
import { hasPermission } from 'utils/user';
import FeedbackModal from 'components/organisms/FeedbackModal';
import { useStudioWizard } from 'store';
import AddStudioModalV2 from 'components/AddStudioModal/AddStudioModal';

export const ModalTitle = styled.div`
  font-size: 18px;
  padding: 12px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-evenly;
`;

export const Button = styled.button`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  background-color: ${props => props.color};
  border-radius: 4px;
  border: 0;
  padding: 10px 24px;
  color: ${({ theme }) => theme.colors.grey.white};
`;

interface StudioHeaderProps {
  studio: Studio;
}

const StudioHeader = ({ studio }: StudioHeaderProps) => {
  const theme = useTheme();
  const params = useParams<{ studioId: string }>();
  const studioId = Number(params.studioId);
  const { data: user } = useFetchUser();
  const queryClient = useQueryClient();
  const { setStudioDetails, setPaymentDetails } = useStudioWizard(
    state => state
  );
  const { refetch: refetchPaymentDetails } = useFetchPaymentDetails(studio.id, {
    enabled: false,
  });
  const { mutate: postArchive } = usePostArchive(ArchivePageName.studio);
  const { mutate: postUnarchive } = usePostUnarchive(ArchivePageName.studio);

  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [showFeedbackSuccess, setShowFeedbackSuccess] = useState(false);
  const [showFeedbackError, setShowFeedbackError] = useState(false);
  const [showStudioModal, setShowStudioModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showUnarchiveModal, setShowUnarchiveModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const emailRef = useRef<HTMLImageElement>(null);
  const phoneRef = useRef<HTMLImageElement>(null);
  const optionsRef = useRef<HTMLImageElement>(null);

  const onSuccessPaymentDetails = (
    paymentDetailsRes: AllPaymentDetails | IndianPaymentDetails
  ) => {
    let paymentDetails;
    if (studio.country === 'India') {
      paymentDetails = {
        companyName: paymentDetailsRes.companyName,
        bankName: paymentDetailsRes.bankName,
        companyAddress: paymentDetailsRes.companyAddress,
        accountName: paymentDetailsRes.accountName,
        taxId: paymentDetailsRes.taxId,
        swiftBic: paymentDetailsRes.swiftBic,
        currency: paymentDetailsRes.currency,
        bankRoutingNumber: paymentDetailsRes.bankRoutingNumber,
        invoicePattern: paymentDetailsRes.invoicePattern,
        accountNumber: paymentDetailsRes.accountNumber,
        ifscCode: (paymentDetailsRes as IndianPaymentDetails).ifscCode,
        purposeCode: (paymentDetailsRes as IndianPaymentDetails).purposeCode,
      } as IndianPaymentDetails;
    } else {
      paymentDetails = {
        companyName: paymentDetailsRes.companyName,
        bankName: paymentDetailsRes.bankName,
        companyAddress: paymentDetailsRes.companyAddress,
        accountName: paymentDetailsRes.accountName,
        taxId: paymentDetailsRes.taxId,
        swiftBic: paymentDetailsRes.swiftBic,
        currency: paymentDetailsRes.currency,
        bankRoutingNumber: paymentDetailsRes.bankRoutingNumber,
        invoicePattern: paymentDetailsRes.invoicePattern,
        accountNumber: paymentDetailsRes.accountNumber,
        iban: (paymentDetailsRes as AllPaymentDetails).iban,
      } as AllPaymentDetails;
    }

    setPaymentDetails(paymentDetails);
  };

  const handleOpenAddV2Modal = () => {
    if (studio.hasPaymentDetails) {
      refetchPaymentDetails().then(response => {
        if (response.data) {
          onSuccessPaymentDetails(response?.data);
        }
      });
    }

    const studioDetails = {
      studioName: studio.name,
      logo: studio.logo,
      location: { id: studio.locationId, value: studio.location },
      contactName: studio.contact.name,
      contactEmail: studio.contact.email,
      contactPhoneNumber: studio.contact.phone,
      publishingManager: studio.manager,
      mondayURL: studio.externalUrl,
    };
    setStudioDetails(studioDetails);

    setShowBurgerMenu(false);
    setShowStudioModal(true);
  };

  const handleFeedbackSuccess = () => {
    setShowFeedbackSuccess(!showFeedbackSuccess);
  };

  const handleFeedbackError = () => {
    setShowFeedbackError(!showFeedbackError);
  };

  const handleArchiveSuccess = (text: string) => {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleArchiveModalResponse = (isConfirmed: boolean) => {
    if (isConfirmed) {
      postArchive(studioId, {
        onSuccess: () => {
          queryClient.invalidateQueries([ServerQueryKey.NavStudios]);
          queryClient.invalidateQueries([
            ServerQueryKey.StudioDetails,
            studioId,
          ]);
          handleArchiveSuccess('The studio was successfully archived');
        },
        onError: (error: any) => {
          setErrorMsg(error.response.data.message);
          setShowErrorModal(true);
        },
      });
    }
    setShowArchiveModal(false);
  };

  const handleUnarchive = () => {
    setShowBurgerMenu(false);
    setShowUnarchiveModal(true);
  };
  const handleArchive = () => {
    setShowBurgerMenu(false);
    setShowArchiveModal(true);
  };

  const handleUnrchiveModalResponse = (isConfirmed: boolean) => {
    if (isConfirmed) {
      postUnarchive(studioId, {
        onSuccess: () => {
          queryClient.invalidateQueries([ServerQueryKey.NavStudios]);
          queryClient.invalidateQueries([
            ServerQueryKey.StudioDetails,
            studioId,
          ]);
          handleArchiveSuccess('The studio was successfully unarchived');
        },
      });
    }
    setShowUnarchiveModal(false);
  };

  const hasAddStudioAccess = hasPermission(user, AccessFeatures.CREATE_STUDIO);
  const hasEditStudioAccess = hasPermission(user, AccessFeatures.EDIT_STUDIO);
  const hasArchiveStudioAccess = hasPermission(
    user,
    AccessFeatures.ARCHIVE_STUDIO
  );
  const hasAddPaymentDetailsAccess = hasPermission(
    user,
    AccessFeatures.ADD_PAYMENT_DETAILS
  );
  const hasEditPaymentDetailsAccess = hasPermission(
    user,
    AccessFeatures.EDIT_PAYMENT_DETAILS
  );

  const showHamburgerMenu =
    hasAddStudioAccess ||
    hasEditStudioAccess ||
    hasArchiveStudioAccess ||
    hasAddPaymentDetailsAccess ||
    hasEditPaymentDetailsAccess;

  return (
    <>
      <PageHeaderContainer>
        <StudioLogoInfoContainer>
          <StudioLogo big={true} name={studio.name} url={studio.logo} />
          <StudioInfo>
            <StudioInfoName title={studio.name}>{studio.name}</StudioInfoName>
            <StudioInfoLine>
              <StudioInfoIcon src={'/assets/icons/icon_location.svg'} />
              <StudioInfoString>{studio.country}</StudioInfoString>
              <br />
            </StudioInfoLine>
            <StudioInfoLine>
              <StudioInfoIcon src={'/assets/icons/icon_date.svg'} />
              <StudioInfoString>{studio.partnerSince}</StudioInfoString>
              <br />
            </StudioInfoLine>
            {studio?.externalUrl ? (
              <StudioInfoLineExternal>
                <StudioInfoStringExternal>
                  <StudioInfoIconExternal
                    src={'/assets/icons/icon_external_link_small.svg'}
                  />
                  <StudioHeaderLink
                    href={studio.externalUrl}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    Monday.com
                  </StudioHeaderLink>
                </StudioInfoStringExternal>

                <br />
              </StudioInfoLineExternal>
            ) : null}
          </StudioInfo>
        </StudioLogoInfoContainer>
        <StudioContacts>
          <StudioContact>
            <StudioContactHeader>Manager</StudioContactHeader>
            <StudioContactName title={studio.manager?.name}>
              <StudioContactNameShortener title={studio.manager?.name}>
                {studio.manager?.name}
              </StudioContactNameShortener>
            </StudioContactName>
          </StudioContact>
          <StudioContact>
            <StudioContactHeader>Contact</StudioContactHeader>
            <StudioContactName>
              <StudioContactNameShortener
                title={studio.contact?.name}
                style={{
                  width: '140px',
                }}
              >
                {studio.contact?.name}
              </StudioContactNameShortener>
              {studio.contact?.email && (
                <StudioContactAction>
                  <StudioContactActionIcon
                    ref={emailRef}
                    alt="Email Icon"
                    src={'/assets/icons/icon_email.svg'}
                    onClick={() => setShowEmail(!showEmail)}
                  />
                  <Popup
                    visible={showEmail}
                    position={PopupPosition.bottomLeft}
                    onClose={() => setShowEmail(false)}
                    anchor={emailRef}
                  >
                    <PopupAnchorButton
                      href={`mailto: ${studio.contact.email}`}
                      onClick={() => setShowEmail(false)}
                    >
                      {studio.contact.email}
                    </PopupAnchorButton>
                  </Popup>
                </StudioContactAction>
              )}
              {studio.contact?.phone && (
                <StudioContactAction>
                  <StudioContactActionIcon
                    alt="Phone Icon"
                    ref={phoneRef}
                    src={'/assets/icons/icon_phone.svg'}
                    onClick={() => setShowPhone(!showPhone)}
                  />
                  <Popup
                    visible={showPhone}
                    position={PopupPosition.bottomLeft}
                    onClose={() => setShowPhone(false)}
                    anchor={phoneRef}
                  >
                    <PopupAnchorButton
                      href={'tel:' + studio.contact?.phone}
                      onClick={() => setShowPhone(false)}
                    >
                      {studio.contact?.phone}
                    </PopupAnchorButton>
                  </Popup>
                </StudioContactAction>
              )}
            </StudioContactName>
          </StudioContact>
        </StudioContacts>
        <StudioOptions>
          {showHamburgerMenu && (
            <PageHeaderBurgerMenu
              src={'/assets/icons/icon_more_options.svg'}
              onClick={() => setShowBurgerMenu(!showBurgerMenu)}
              ref={optionsRef}
            />
          )}
          <Popup
            containerStyle={{ width: '250px', padding: 0 }}
            visible={showBurgerMenu}
            position={PopupPosition.bottomLeft}
            onClose={() => setShowBurgerMenu(false)}
            anchor={optionsRef}
          >
            <PopupButtonContainer>
              {hasEditStudioAccess && (
                <PopupButton onClick={handleOpenAddV2Modal}>
                  <MdModeEdit size={20} />
                  <PopupButtonText>Edit Details</PopupButtonText>
                </PopupButton>
              )}
              {hasArchiveStudioAccess && (
                <PopupButton
                  onClick={studio.archived ? handleUnarchive : handleArchive}
                  color={theme.colors.pink[500]}
                >
                  <img
                    style={{ cursor: 'pointer' }}
                    src={archiveIcon}
                    alt={''}
                  />
                  <PopupButtonText>
                    {studio.archived ? 'Unarchive' : 'Archive'}
                  </PopupButtonText>
                </PopupButton>
              )}
            </PopupButtonContainer>
          </Popup>
        </StudioOptions>
      </PageHeaderContainer>
      <FeedbackModal
        visible={showFeedbackSuccess}
        onClose={handleFeedbackSuccess}
        onClick={handleFeedbackSuccess}
        modalTitle="Request Invoice"
        contentTitle="Congratulations!"
        content={
          'The invoice has been successfully submitted. Our team is diligently reviewing it to verify all the details. Please allow us some time for this process.'
        }
        footerMsg={'Thank you for your understanding.'}
        buttonTitle="OK"
      />
      <FeedbackModal
        visible={showFeedbackError}
        onClose={handleFeedbackError}
        onClick={handleFeedbackError}
        modalTitle="Request Invoice"
        contentTitle=""
        content={'Oops! Something went wrong. Please, try again!'}
        footerMsg={''}
        buttonTitle="OK"
      />
      {showStudioModal && (
        <AddStudioModalV2
          isOpen={showStudioModal}
          onClose={() => setShowStudioModal(false)}
          isCreateMode={false}
          studioId={studioId}
        />
      )}
      {showUnarchiveModal ? (
        <InfoModal>
          <ModalTitle>
            Unarchiving will make you as Publishing Manager for this Studio.
          </ModalTitle>
          <ModalTitle>Do you wish to proceed?</ModalTitle>
          <ButtonContainer>
            <Button
              color={theme.colors.pink[500]}
              onClick={() => handleUnrchiveModalResponse(true)}
            >
              Yes
            </Button>
            <Button
              color={'darkgray'}
              onClick={() => handleUnrchiveModalResponse(false)}
            >
              No
            </Button>
          </ButtonContainer>
        </InfoModal>
      ) : null}
      {showArchiveModal ? (
        <InfoModal>
          <ModalTitle>
            Are you sure you would like to archive this Studio?
          </ModalTitle>
          <ButtonContainer>
            <Button
              color={theme.colors.pink[500]}
              onClick={() => handleArchiveModalResponse(true)}
            >
              Yes
            </Button>
            <Button
              color={'darkgray'}
              onClick={() => handleArchiveModalResponse(false)}
            >
              No
            </Button>
          </ButtonContainer>
        </InfoModal>
      ) : null}
      {showErrorModal && (
        <Modal
          size={ModalSize.sm}
          isOpen={showErrorModal}
          title="Error"
          onClose={() => setShowErrorModal(false)}
        >
          <TestError message={errorMsg} imgSrc={errorIcon} />
        </Modal>
      )}
      {}
    </>
  );
};

export default StudioHeader;
