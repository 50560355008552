import {
  AddGameButton,
  GameCounter,
  GamesOverviewHeader,
  GamesOverviewWrapper,
  PlatformIcon,
  ToggleButton,
  OverviewHeaderLeft,
} from './GamesOverview.styles';
import { useState, ChangeEvent, useRef, useEffect } from 'react';
import OverviewList, {
  OverviewListRow,
} from 'components/OverviewList/OverviewList';
import { Button } from 'components/atoms';
import { AddGameModal, OverviewSkeleton } from 'components';
import styled, { useTheme } from 'styled-components';
import { SearchInput } from 'components/molecules';
import { AccessFeatures, Game } from 'types';
import { useFetchGames, useFetchUser } from 'api';
import { makeGameLink } from 'api/utils';
import moment from 'moment';
import { GameStatusBadge, Logo, TagList } from 'components';
import { hasPermission } from 'utils/user';

interface GamesOverviewProps {
  studioId: number;
  isStudioArchived: boolean;
}

export const GamesOverview = ({
  studioId,
  isStudioArchived,
}: GamesOverviewProps) => {
  const theme = useTheme();
  const { data: user } = useFetchUser();
  const useFetchGamesQuery = useFetchGames(studioId);
  const [showModal, setShowModal] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const archivedRef = useRef<HTMLDivElement | null>(null);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    const currentRef = archivedRef.current;

    if (showArchived && currentRef) {
      if ('scrollIntoView' in currentRef) {
        currentRef.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [showArchived]);

  const toggleShowArchived = () => {
    setShowArchived(!showArchived);
  };

  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setShowArchived(true);
    } else {
      setShowArchived(false);
    }
    setSearchText(event.target.value);
  };

  if (useFetchGamesQuery.isLoading) {
    return (
      <GamesOverviewWrapper>
        <OverviewSkeleton />
      </GamesOverviewWrapper>
    );
  }

  if (useFetchGamesQuery.isSuccess) {
    const { data: games } = useFetchGamesQuery;

    // filter By Search Text
    const filteredGames: Game[] = games.filter((game: Game) =>
      game.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    );

    const activeGames: Game[] = filteredGames.filter(
      (game: Game) => !game.isArchived
    );
    const archivedGames: Game[] = filteredGames.filter(
      (game: Game) => game.isArchived
    );

    const getTableRows = (games: Game[]) => {
      return games.map((game: Game) => ({
        id: game.id,
        link: makeGameLink({ studioId: studioId, gameId: game.id }),
        relatedToUser: false,
        rows: [
          {
            columnId: 'name',
            content: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <Logo src={game.logo} name={game.name} />
                <div
                  title={game.name}
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    marginLeft: 12,
                  }}
                >
                  {game.name}
                </div>
              </div>
            ),
            sortableContent: game.name,
          },
          {
            columnId: 'status',
            content: <GameStatusBadge gameStatus={game.status} />,
            sortableContent: game.status,
          },
          {
            columnId: 'ipm',
            content: !!game.ipm ? game.ipm.toFixed(2) : '-',
            sortableContent: game.ipm ? game.ipm : 0,
          },
          {
            columnId: 'submitDate',
            content: <div>{game.createdAt}</div>,
            sortableContent: game.createdAt
              ? moment(game.createdAt, 'DD.MM.YYYY').unix().toString()
              : '',
          },
          {
            columnId: 'lastTestStartDate',
            content: !!game.latestTestStartDate ? (
              <div>{game.latestTestStartDate}</div>
            ) : (
              '-'
            ),
            sortableContent: game.latestTestStartDate
              ? moment(game.latestTestStartDate, 'DD.MM.YYYY').unix().toString()
              : '',
          },
          {
            columnId: 'tags',
            content: <TagList tags={game.tags ?? []} maxVisibleTags={1} />,
          },
          {
            columnId: 'storeLinks',
            content: (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'default',
                }}
                onClick={e => e.stopPropagation()}
              >
                {game.playMarketUrl ? (
                  <a
                    href={game.playMarketUrl!}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <PlatformIcon src={'/assets/icons/icon_android.svg'} />
                  </a>
                ) : null}
              </div>
            ),
          },
        ],
      }));
    };

    const activeGamesRows: OverviewListRow[] = getTableRows(activeGames);
    const archivedGamesRows: OverviewListRow[] = getTableRows(archivedGames);

    const activeGamesCount = activeGames.length;
    const archivedGamesCount = archivedGames.length;

    const hasAddGameAccess = hasPermission(user, AccessFeatures.CREATE_GAME);

    return (
      <>
        {!games.length && (
          <ContentContainer>
            <div
              style={{
                fontSize: '24px',
                color: theme.colors.grey[900],
                padding: '0 0 40px 0',
              }}
            >
              There are no Games right now
            </div>
            {hasAddGameAccess && (
              <Button disabled={isStudioArchived} onClick={handleOpen}>
                + New Game
              </Button>
            )}
          </ContentContainer>
        )}
        {!!games.length && (
          <GamesOverviewWrapper>
            <GamesOverviewHeader>
              <OverviewHeaderLeft>
                <GameCounter>
                  {activeGamesCount} active game
                  {activeGamesCount !== 1 ? 's' : null}
                </GameCounter>
                <SearchInput
                  sidenav={false}
                  onChange={onSearchTextChange}
                  placeholder="Search Games"
                />
              </OverviewHeaderLeft>
              {hasAddGameAccess && (
                <AddGameButton disabled={isStudioArchived} onClick={handleOpen}>
                  + New Game
                </AddGameButton>
              )}
            </GamesOverviewHeader>
            <OverviewList
              columns={[
                {
                  id: 'name',
                  name: 'Name',
                  width: 390,
                  ellipsize: true,
                  sortable: true,
                },
                { id: 'status', name: 'Type', width: 100, sortable: true },
                { id: 'ipm', name: 'IPM', width: 100, sortable: true },
                {
                  id: 'submitDate',
                  name: 'Submit Date',
                  width: 200,
                  sortable: true,
                },
                {
                  id: 'lastTestStartDate',
                  name: 'Last Test Start Date',
                  width: 200,
                  sortable: true,
                },
                { id: 'tags', name: 'Tags', width: 140 },
                { id: 'storeLinks', name: 'Store Link', width: 150 },
              ]}
              blocks={[[...activeGamesRows]]}
              gameCount={activeGamesCount}
              noDataText={'No active games'}
            />
            {!!archivedGamesRows.length ? (
              <ToggleButton onClick={toggleShowArchived}>
                {showArchived ? 'Hide' : `Show ${archivedGamesCount}`} archived
                game
                {archivedGamesCount !== 1 ? 's' : null}
              </ToggleButton>
            ) : null}
            <ScrollContainer ref={archivedRef}>
              {!!archivedGamesRows.length && showArchived ? (
                <OverviewList
                  columns={[
                    { id: 'name', name: 'Name', width: 390, ellipsize: true },
                    { id: 'status', name: 'Type', width: 100 },
                    { id: 'ipm', name: 'IPM', width: 100 },
                    { id: 'submitDate', name: 'Submit Date', width: 200 },
                    {
                      id: 'lastTestStartDate',
                      name: 'Last Test Start Date',
                      width: 200,
                    },
                    { id: 'tags', name: 'Tags', width: 140 },
                    { id: 'storeLinks', name: 'Store Link', width: 150 },
                  ]}
                  blocks={[[...archivedGamesRows]]}
                  gameCount={archivedGamesCount}
                />
              ) : null}
            </ScrollContainer>
          </GamesOverviewWrapper>
        )}
        {showModal && (
          <AddGameModal
            isOpen={showModal}
            onClose={handleClose}
            isCreateMode={true}
          />
        )}
      </>
    );
  }
  return null;
};

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ScrollContainer = styled.div`
  min-height: 300px;
`;

export default GamesOverview;
