import { useCallback } from 'react';
import { cohortIndexArrayGenerator } from '../../utils/GlobalUtilsTs';
import { cohortRowDaysGenerator } from '../../utils/CohortTableUtilsTs';

const usePredictedRow = (
  dataAveragePredictedValues: number[] | undefined,
  predictionStartDayIndex: number,
  startingIdx: number,
) => {
  const getPredictedRow = useCallback(() => {
    const predictedVals = dataAveragePredictedValues ?? [];
    const nullFilledPredictedVals = [
      ...Array(predictionStartDayIndex).fill(null),
      ...predictedVals,
    ];
    const labelIdx = cohortIndexArrayGenerator(
      predictionStartDayIndex + predictedVals.length,
      startingIdx
    );
    const rowDays = cohortRowDaysGenerator(
      labelIdx,
      nullFilledPredictedVals,
      3
    );
    const predictedRow = {
      cohortSize: 0,
      installDate: 'Predicted',
      ...rowDays,
    };

    return { labelIdx, predictedRow };
  }, [dataAveragePredictedValues,predictionStartDayIndex,startingIdx]);

  return getPredictedRow();
};

export default usePredictedRow;
