import axios from 'axios';

export const checkTokenExists = (): boolean => {
  const sessionState: string | null = localStorage.getItem('sessionState');
  return !!sessionState;
};

export const getToken = () => {
  const sessionState = localStorage.getItem('sessionState');
  if (!!sessionState) {
    return JSON.parse(sessionState);
  }
};

export const removeToken = (): void => {
  localStorage.removeItem('sessionState');
};

export const setToken = (tokenObj: Object) => {
  localStorage.setItem('sessionState', JSON.stringify(tokenObj));
};

export const setPrevLocation = (location: string) => {
  localStorage.setItem('previousLocation', location);
};

export const getPrevLocation = () => localStorage.getItem('previousLocation');

export const removePrevLocation = () => {
  localStorage.removeItem('previousLocation');
};

interface AuthProps {
  code: string;
}

export interface AuthResponse {
  status: boolean;
  token: string;
  refreshToken: string;
}

export const authenticate = async ({ code }: AuthProps) => {
  return axios.request<AuthResponse | void>({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/authenticate`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      code,
    },
  });
};
