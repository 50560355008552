import { FacebookCampaignStatus } from 'types';
import { Badge } from '../Badge';
import { getBadgeProperties } from './CampaignStatusBadge.utils';

interface CampaignStatusBadgeProps {
  campaignStatus: FacebookCampaignStatus;
  size: 'sm' | 'md' | 'lg';
}

const CampaignStatusBadge = ({
  campaignStatus,
  size,
}: CampaignStatusBadgeProps) => {
  const { text, styles } = getBadgeProperties(campaignStatus, size);
  return <Badge styles={styles}>{text}</Badge>;
};

export default CampaignStatusBadge;
