import styled, { css } from 'styled-components';

interface DateInputProps {
  error?: boolean;
}

export const DateCustomInput = styled.div<DateInputProps>`
  border: ${props =>
    props.error
      ? `2px solid ${props.theme.colors.pink[500]}`
      : `1px solid ${props.theme.colors.grey[900]}`};
  outline-color: ${props =>
    props.error ? props.theme.colors.pink[500] : props.theme.colors.grey[900]};
  border-radius: 4px;
  padding: 0 12px 0 19px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 19px;
  margin: 6px 0 20px;
  width: 100% ;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${() =>
    window.innerHeight <= 720
      ? css`
          height: 44px;
        `
      : css`
          height: 54px;
        `}
`;
