import { CenteredWrapper, StyledWrapper } from './PrivacyPolicyPage.styles';
import { PrivacyPolicy } from 'components/atoms/PrivacyPolicy';

function PrivacyPolicyPage() {
  return (
    <CenteredWrapper>
      <StyledWrapper>
        <div dangerouslySetInnerHTML={{ __html: PrivacyPolicy }}></div>
      </StyledWrapper>
    </CenteredWrapper>
  );
}

export default PrivacyPolicyPage;
