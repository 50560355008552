import { useState, MouseEvent } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi';
import { ActivityType, CampaignActivity } from 'types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Tootltip from 'components/atoms/Tooltip/Tootltip';
import { activityIcons } from './Activity.icons';
import { StyledActivityItem } from './Activity.styles';
import styled from 'styled-components';

const renderSyntaxHighlighter = (content: JSX.Element) => (
  <div className="code">
    <SyntaxHighlighter
      language="json"
      wrapLines
      wrapLongLines
      customStyle={{ maxHeight: 300, margin: 0 }}
      renderer={() => content}
    >
      {}
    </SyntaxHighlighter>
  </div>
);

interface ActivityCodeProps {
  activity: CampaignActivity;
}

const ActivityCode = ({ activity }: ActivityCodeProps) => {
  let content;
  if (activity.type === ActivityType.campaignDescriptionAdd) {
    content = <StyledPre>{activity.jsonDetails?.newDescription}</StyledPre>;
  } else if (activity.type === ActivityType.campaignDescriptionRemove) {
    content = (
      <StyledPre strikeThrough>
        {activity.jsonDetails?.oldDescription}
      </StyledPre>
    );
  } else if (activity.type === ActivityType.campaignDescriptionEdit) {
    const oldDescription = (
      <StyledPre strikeThrough>
        {activity.jsonDetails?.oldDescription}
      </StyledPre>
    );
    const newDescription = (
      <StyledPre>{activity.jsonDetails?.newDescription}</StyledPre>
    );

    return (
      <>
        {renderSyntaxHighlighter(oldDescription)}
        {renderSyntaxHighlighter(newDescription)}
      </>
    );
  } else {
    content = <>{JSON.stringify(activity.jsonDetails, null, 4)}</>;
  }

  return renderSyntaxHighlighter(content);
};

interface StyledPreProps {
  strikeThrough?: boolean;
}

const StyledPre = styled.pre<StyledPreProps>`
  word-wrap: break-word;
  white-space: pre-wrap;
  text-decoration: ${props => (props.strikeThrough ? 'line-through' : 'none')};
`;

interface ActivityItemProps {
  activity: CampaignActivity;
}

const ActivityItem = ({ activity }: ActivityItemProps) => {
  const [showJson, setShowJson] = useState<boolean>(false);
  const spacing = 12;
  const iconSize = 24;

  const handleShowJson = (event: MouseEvent) => {
    event.preventDefault();
    setShowJson(!showJson);
  };

  const testDescriptionStatuses = [
    ActivityType.campaignDescriptionAdd,
    ActivityType.campaignDescriptionEdit,
    ActivityType.campaignDescriptionRemove,
  ];

  return (
    <StyledActivityItem spacing={spacing} iconSize={iconSize}>
      <header className="activity-header">
        <img className="icon" src={activityIcons[activity.type]} alt="icon" />
        <div className="text-wrapper">
          <span className="text">{activity.text}</span>
          {activity.jsonDetails ? (
            <a href="/#" className="toggle-json" onClick={handleShowJson}>
              <span className="toggle-text">
                {showJson
                  ? testDescriptionStatuses.includes(activity.type)
                    ? 'Close Changes'
                    : 'Close Json'
                  : null}
                {!showJson
                  ? testDescriptionStatuses.includes(activity.type)
                    ? 'View Changes'
                    : 'View Json'
                  : null}
              </span>
              {showJson ? (
                <HiOutlineChevronUp className="toggle-icon" />
              ) : (
                <HiOutlineChevronDown className="toggle-icon" />
              )}
            </a>
          ) : null}
        </div>
        <Tootltip placement="left" title={moment(activity.date).format('LLLL')}>
          <span className="date">{moment(activity.date).fromNow()}</span>
        </Tootltip>
      </header>
      {activity.jsonDetails ? (
        <section className={clsx('activity-content', { show: showJson })}>
          <ActivityCode activity={activity} />
        </section>
      ) : null}
    </StyledActivityItem>
  );
};

export default ActivityItem;
