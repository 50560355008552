import { useState, ChangeEvent } from 'react';
import { Modal } from 'components';
import { ModalSize } from 'types';
import { Button } from 'components/atoms/Button';
import styled from 'styled-components';
import clsx from 'clsx';

const reasonMaxLength = 1000;

interface CampaignTerminationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (reason?: string) => void;
}

const CampaignTerminationModal = ({
  isOpen = false,
  onClose,
  onSubmit,
}: CampaignTerminationModalProps) => {
  const [reason, setReason] = useState<string>('');
  const [hasReasonError, setHasReasonError] = useState<boolean>(false);

  const handleReasonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    setReason(text);

    const hasError = text.length >= reasonMaxLength;
    setHasReasonError(hasError);
  };

  const handleSubmit = () => {
    reason === '' || hasReasonError ? onSubmit() : onSubmit(reason);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Cancel test"
      size={ModalSize.sm}
    >
      <ReasonWrapper>
        <span className="title">Reason for cancellation</span>
        <div className="textarea-wrapper">
          <textarea
            placeholder="Enter reason"
            className={clsx('textarea', { 'has-error': hasReasonError })}
            rows={5}
            autoFocus
            maxLength={reasonMaxLength}
            value={reason}
            onChange={handleReasonChange}
          />
          {hasReasonError && (
            <span className="error-message">
              {`The maximum length for this field is ${reasonMaxLength} characters.`}
            </span>
          )}
        </div>
        <Button
          className="submit"
          onClick={handleSubmit}
          disabled={hasReasonError}
        >
          Cancel test
        </Button>
      </ReasonWrapper>
    </Modal>
  );
};

export default CampaignTerminationModal;

// styles
const ReasonWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    margin-bottom: 12px;
  }

  .textarea-wrapper {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .textarea {
      width: 100%;
      padding: 12px;
      border-radius: 4px;
      outline: none;
      resize: none;
      border-width: 1px;
      border-style: solid;

      ::placeholder {
        color: #b6b6b6;
      }

      :not(.has-error) {
        border-color: #c6c6c6;
      }

      &.has-error {
        border-color: ${({ theme }) => theme.colors.pink[500]};
      }
    }

    .error-message {
      color: ${({ theme }) => theme.colors.pink[500]};
      margin-top: 8px;
    }
  }

  .submit {
    background-color: ${({ theme }) => theme.colors.grey.white};
    color: #f066a7;
    border-color: #f066a7;
    margin: 0;
    text-transform: uppercase;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.grey[1000]};
      border-color: ${({ theme }) => theme.colors.grey[1000]};
      color: ${({ theme }) => theme.colors.grey.white};
    }
  }
`;
