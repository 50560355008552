import { Modal } from 'components';
import { Button } from 'components/atoms';
import styled from 'styled-components';
import { ModalSize } from 'types';

interface PlaystoreMissingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const PlaystoreMissingModal = ({
  isOpen,
  onClose,
  onSuccess,
}: PlaystoreMissingModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={ModalSize.sm}
      title="play store url missing"
    >
      <Wrapper>
        <div className="content">
          <span>
            To create new tests, you must add a Play Store URL to this game.
          </span>
          <span>Click "Edit Game" to add it now.</span>
        </div>
        <div className="footer">
          <Button onClick={onClose}>CANCEL</Button>
          <Button onClick={onSuccess} primary>
            EDIT GAME
          </Button>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default PlaystoreMissingModal;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      line-height: 32px;
      font-weight: 400;
      font-size: 18px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
`;
