import { Spacing } from 'assets/styles';
import { StyledLogo } from './Logo.styles';

interface LogoProps extends Spacing {
  src?: string;
  name?: string;
  size?: number;
  borderRadius?: number;
}

const Logo = ({
  src = '',
  name = '',
  size = 48,
  borderRadius = 4,
  ...spacing
}: LogoProps) => {
  return (
    <StyledLogo size={size} borderRadius={borderRadius} {...spacing}>
      {src !== '' ? (
        <img className="image" alt="Logo" src={src} />
      ) : name !== '' ? (
        <span className="name">{name.charAt(0).toUpperCase()}</span>
      ) : null}
    </StyledLogo>
  );
};

export default Logo;
