import { GameOrientation, TagType } from 'types';
import { create } from 'zustand';

// Define the types for basicDetails and networkDetails
interface BasicDetails {
  playstoreUrl: string;
  gameName: string;
  logoUrl: string;
  mondayItemId: number | null;
  bundleId: string;
  appStoreUrl: string;
  iosPackageName: string;
  tags: TagType[];
  orientation: GameOrientation;
}

interface NetworkDetails {
  facebook: {
    appId: string;
    appSecret: string;
    userAccessToken: string;
    installReferrerDecryptionKey: string;
  };
  unity: {
    unityId: string | null;
  };
}

interface State {
  basicDetails: BasicDetails;
  networkDetails: NetworkDetails;
  setBasicDetails: (basicDetails: BasicDetails) => void;
  setNetworkDetails: (networkDetails: NetworkDetails) => void;
  isBasicDetailsValid: () => boolean;
  isNetworkDetailsValid: () => boolean;
  clearStore: () => void;
}

// Define the initial state
const initialState = {
  basicDetails: {
    playstoreUrl: '',
    gameName: '',
    logoUrl: '',
    mondayItemId: null,
    bundleId: '',
    appStoreUrl: '',
    iosPackageName: '',
    tags: [],
    orientation: GameOrientation.portrait,
  },
  networkDetails: {
    facebook: {
      appId: '',
      appSecret: '',
      userAccessToken: '',
      installReferrerDecryptionKey: '',
    },
    unity: {
      unityId: null,
    },
  },
};

// Define the store
const useGameWizard = create<State>()((set, get) => ({
  basicDetails: initialState.basicDetails,
  networkDetails: initialState.networkDetails,
  // Setter for basicDetails
  setBasicDetails: (basicDetails: BasicDetails) =>
    set((state: State) => ({ ...state, basicDetails })),

  // Setter for networkDetails
  setNetworkDetails: (networkDetails: NetworkDetails) =>
    set((state: State) => ({ ...state, networkDetails })),

  isBasicDetailsValid: () => {
    const {
      basicDetails: { gameName, bundleId },
    } = get();
    return gameName.length !== 0 && bundleId.length !== 0;
  },

  isNetworkDetailsValid: () => {
    const isFacebookValid = () => {
      const { appId, appSecret, userAccessToken } =
        get().networkDetails.facebook;

      const userAccessTokenError =
        userAccessToken.length !== 0 &&
        (appId.length === 0 || appSecret.length === 0);

      return !userAccessTokenError;
    };

    return isFacebookValid();
  },

  clearStore: () =>
    set(() => ({
      basicDetails: initialState.basicDetails,
      networkDetails: initialState.networkDetails,
    })),
}));

export default useGameWizard;
