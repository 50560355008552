import { debounce } from 'lodash';
import {
  CSSProperties,
  RefObject,
  useEffect,
  useRef,
  useState,
  FC,
} from 'react';
import styled from 'styled-components';

export type PopupAnchor = RefObject<HTMLDivElement | HTMLButtonElement>;

export enum PopupPosition {
  'bottomLeft',
  'right',
}

interface Props {
  visible?: boolean;
  position?: PopupPosition;
  onClose: () => void;
  anchor: PopupAnchor;
  containerStyle?: CSSProperties;
  children?: React.ReactNode;
  smallFont?: boolean;
}

export const Popup: FC<Props> = ({ visible, anchor, ...props }) => {
  if (!visible || !anchor?.current) {
    return null;
  }

  return <PopupElement anchor={anchor} {...props} />;
};

const PopupElement: FC<Props> = ({
  anchor,
  position,
  onClose,
  containerStyle,
  children,
  smallFont = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleClick = debounce((event: MouseEvent) => {
      const isOutsideClick = () => {
        const target = event.target as HTMLElement;
        const popupElement = ref.current;
        const anchorElement = anchor.current;

        if (!popupElement || !anchorElement) {
          return false;
        }

        const clickedOutsidePopup = !popupElement.contains(target);
        const clickedOutsideAnchor = target !== anchorElement;

        return clickedOutsidePopup && clickedOutsideAnchor;
      };

      if (isOutsideClick()) {
        onClose();
      }
    }, 200);

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [anchor, onClose]);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  }, [anchor, ref]);
  return (
    <Container
      ref={ref}
      style={{
        ...(smallFont ? { fontSize: '11px' } : { fontSize: '14px' }),
        ...(width === 0 ? { opacity: 0 } : {}),
        ...(position === PopupPosition.right
          ? {
              marginLeft: `${
                anchor.current!.getBoundingClientRect().width + 8
              }px`,
            }
          : {}),
        ...(position === PopupPosition.bottomLeft && anchor.current
          ? {
              left: `${anchor.current.offsetLeft - width - 8}px`,
              top: `${
                anchor.current.offsetTop + anchor.current.clientHeight + 8
              }px`,
            }
          : {}),
        ...(containerStyle ?? {}),
      }}
    >
      {children}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.white};
  position: absolute;
  border-radius: 8px;
  z-index: var(--z-index-popup);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;
