import { NoDataContainer } from 'assets/styles';
import { Component, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  state = { hasError: false };
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <NoDataContainer>
          Something went wrong, Please try later.
        </NoDataContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
