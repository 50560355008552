import { useFetchStatus } from 'api';
import { CampaignDetails, CampaignTypes, FacebookCampaignStatus } from 'types';
import TestCompleted from 'components/organisms/TestCompleted';
import { InfoBanner } from 'pages/Test/Charts/Charts.styles';
import TestError from 'pages/Test/TestError';
import cancelledIcon from 'assets/images/cancelled_icon.svg';
import JustrackKpi from './JustrackKpi/JustrackKpi';

const scheduleBannerStatuses = [
  FacebookCampaignStatus.new,
  FacebookCampaignStatus.queued,
  FacebookCampaignStatus.processing,
  FacebookCampaignStatus.review,
  FacebookCampaignStatus.pending,
  FacebookCampaignStatus.scheduled,
];

interface TestRunningProps {
  campaign: CampaignDetails;
}

export const TestRunning: React.FC<TestRunningProps> = ({ campaign }) => {
  const { data: status } = useFetchStatus();

  const isMvp = campaign.type === 'mvp';
  const jtStatus = status?.jtStatus;
  const isScheduled = campaign.startAt;

  return (
    <>
      {isMvp && !jtStatus && (
        <InfoBanner>
          JustTrack is not available at the moment, some data might be
          incomplete
        </InfoBanner>
      )}
      {isScheduled && scheduleBannerStatuses.includes(campaign.status) && (
        <InfoBanner>
          Campaign is scheduled to start at {campaign.startAt}.
        </InfoBanner>
      )}
      {campaign.terminationReason !== '' && (
        <TestError
          message={campaign.terminationReason}
          imgSrc={cancelledIcon}
        />
      )}
      <TestCompleted hideHeader={true} />
      {campaign.type !== CampaignTypes.ipm &&
        campaign.status !== FacebookCampaignStatus.failed && <JustrackKpi />}
    </>
  );
};

export default TestRunning;
