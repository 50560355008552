import { GameStatus } from 'types';
import { memoize } from 'lodash';

const commonStyles = {
  height: '30px',
  width: '64px',
};

const bgColors: Record<GameStatus, string> = {
  [GameStatus.new]: '#6736ca',
  [GameStatus.ipm]: '#fff2d5',
  [GameStatus.mvp]: '#ffc043',
  [GameStatus.scb]: '#e6dafe',
  [GameStatus.ctm]: '#98d8d8',
};

const colors: Record<GameStatus, string> = {
  [GameStatus.new]: '#ffffff',
  [GameStatus.ipm]: '#bf7b49',
  [GameStatus.mvp]: '#ffffff',
  [GameStatus.scb]: '#8144fc',
  [GameStatus.ctm]: '#ffffff',
};

const texts: Record<GameStatus, string> = {
  [GameStatus.new]: 'NEW',
  [GameStatus.ipm]: 'IPM',
  [GameStatus.mvp]: 'MVP',
  [GameStatus.scb]: 'SCB',
  [GameStatus.ctm]: 'CTM',
};

export const getTextByCampaignType = (gameStatus: GameStatus) =>
  texts[gameStatus];

export const getBadgeColorsByCampaignType = (gameStatus: GameStatus) => ({
  backgroundColor: bgColors[gameStatus],
  color: colors[gameStatus],
});

export const getBadgeProperties = memoize((gameStatus: GameStatus) => {
  return {
    styles: {
      ...commonStyles,
      ...getBadgeColorsByCampaignType(gameStatus),
    },
    text: getTextByCampaignType(gameStatus),
  };
});
