import { axiosInstance } from 'api';

interface fetchCitiesProps {
  term?: string;
}

export interface Location {
  id: number;
  city: string;
  country: string;
  area: string;
  label: string;
}

interface FetchCitiesResponse {
  status: boolean;
  locations: Location[];
}

export const fetchCitiesRequest = ({
  term = '',
}: fetchCitiesProps): Promise<Location[]> => {
  return axiosInstance
    .get<FetchCitiesResponse>(`/location/${term}`)
    .then(response => {
      if (response.data?.status !== true) {
        console.log('API returned unexpected data');
        return Promise.reject();
      }
      return Promise.resolve(response.data.locations);
    })
    .catch(error => {
      console.log('Request failed', error.toString());
      return Promise.reject();
    });
};
