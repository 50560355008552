import { useEffect, useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import editIcon from 'assets/images/pagesHeader/Edit_white.svg';
import { usePopper } from 'react-popper';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Icon } from 'assets/styles';
import CampaignDescriptionModal from 'components/CampaignDescriptionModal/CampaignDescriptionModal';
import { useEditDescription } from 'api';

interface CampaignDescriptionProps {
  gameId: number;
  campaignId: number;
  description: string;
  onSuccess: () => void;
}

const CampaignDescription = ({
  gameId,
  campaignId,
  description,
  onSuccess,
}: CampaignDescriptionProps) => {
  const { mutate: editDescription } = useEditDescription(gameId, campaignId);
  const [referenceEl, setReferenceEl] = useState<HTMLElement | null>(null);
  const [tooltipEl, setTooltipEl] = useState<HTMLElement | null>(null);
  const [show, setShow] = useState(false);
  const { styles, attributes } = usePopper(referenceEl, tooltipEl, {
    placement: 'right',
  });
  const [modal, setModal] = useState(false);

  let hoverTimeout: any;

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      setShow(true);
    }, 300);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const handleEditDescription = (text: string) => {
    editDescription(text, {
      onSuccess: () => {
        toast.success('The description was successfully edited', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        onSuccess();
      },
      onError: () =>
        toast.error('Something went wrong. Please, try again later.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        }),
    });
    handleModal();
  };

  useEffect(() => {
    if (show) {
      tooltipEl?.setAttribute('data-show', 'true');
    } else {
      tooltipEl?.removeAttribute('data-show');
    }
  }, [show, tooltipEl]);

  return (
    <>
      <IconWrapper
        ref={setReferenceEl}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <BsInfoCircle size={20} />

        <TooltipContainer
          ref={setTooltipEl}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="text">{description}</div>
          <Icon
            onClick={e => {
              e.preventDefault();
              handleModal();
            }}
            src={editIcon}
            alt="edit-description"
            height={24}
            width={24}
            style={{ paddingTop: '-10px' }}
          />
        </TooltipContainer>
      </IconWrapper>
      {modal && (
        <CampaignDescriptionModal
          onClose={handleModal}
          onSubmit={handleEditDescription}
          modalTitle="change test description"
          descriptionText={description}
        />
      )}
    </>
  );
};

export default CampaignDescription;

const TooltipContainer = styled.div`
  visibility: hidden;
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
  background-color: #757575;
  color: ${props => props.theme.colors.grey.white};
  display: flex;
  z-index: var(--z-index-popup);
  width: max-content;
  min-width: 200px;
  margin: 10px;

  & > img {
    margin: -6px;
  }


  &[data-show='true'] {
    visibility: visible;
  }

  .text {
    min-width: 100px;
    min-height: 40px;
    max-width: 250px;
    word-wrap: break-word;
    white-space: pre-wrap;
    flex-grow: 1;
    margin-right: 10px;
    text-align: left;
  }
`;

const IconWrapper = styled.div`
  color: ${props => props.theme.colors.grey[900]};
  cursor: pointer;
`;
