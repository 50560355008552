import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
  }
`;
