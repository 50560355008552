import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { allGenres, Market } from 'types';
import { ContentWrapper } from 'assets/styles';
import IdeationSidebar from 'components/organisms/IdeationSidebar';
import IdeationContent from 'components/IdeationContent/IdeationContent';
import { useDocumentTitle } from 'hooks';
import Main from 'views/layout/main/layout.styles';
import { Header } from 'views/layout';
import { useFetchUser } from 'api';
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';
import { useTheme } from 'styled-components';

const IdeationPage = () => {
  useDocumentTitle('Ideation');
  const theme = useTheme();
  // url
  const navigate = useNavigate();
  const { market, genreId } = useParams();
  const initialSelectedMarket = market as Market;

  const { data: user } = useFetchUser();

  // selected
  const [selectedMarket, setSelectedMarket] = useState<Market>(
    initialSelectedMarket
  );
  const [selectedGenreId, setSelectedGenreId] = useState<number>(
    Number(genreId)
  );
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  const toggleSidebar = () => setShowSidebar(sidebar => !sidebar);

  const handleSelectedMarket = (selected: Market) => {
    setSelectedMarket(selected);
    setSelectedGenreId(allGenres.id);
    navigate(`/ideation/${selected}/${encodeURIComponent(allGenres.id)}`);
  };

  const handleSelectedGenre = (selectedId: number) => {
    setSelectedGenreId(selectedId);
    navigate(`/ideation/${selectedMarket}/${encodeURIComponent(selectedId)}`);
  };

  return (
    <Main sidebarVisible={showSidebar}>
      <Header profilePicture={user?.picture} name={user?.name!} />
      {showSidebar ? (
        <IdeationSidebar
          selectedMarket={selectedMarket}
          selectedGenre={selectedGenreId}
          onMarketChange={handleSelectedMarket}
          onGenreChange={handleSelectedGenre}
          toggleSidebar={toggleSidebar}
        />
      ) : (
        <button className="expand-button" onClick={toggleSidebar}>
          <MdOutlineKeyboardDoubleArrowRight color={theme.colors.blue[500]} />
        </button>
      )}
      <ContentWrapper>
        <IdeationContent
          selectedMarket={selectedMarket}
          selectedGenre={selectedGenreId}
        />
      </ContentWrapper>
    </Main>
  );
};

export default IdeationPage;
