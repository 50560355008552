import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import { AxiosResponse } from 'axios';
import {
  ActivationTypes,
  CampaignDetails,
  CampaignDetailsOverview,
  ComparingCampaign,
  createCamapignReqBody,
  defaultCampaignDetails,
  FacebookCampaignStatus,
  FetchCampaignResponse,
  FetchCampaignsResponse,
  LevelProgressionResponse,
  OverviewCampaignsResponse,
  OverviewGamesDateRange,
  scheduleCampaignReqBody,
  ServerQueryKey,
  UpdateStatusType,
} from 'types';
import { map } from 'lodash';

const updateStatus = <T extends UpdateStatusType>(campaign: T) => {
  const status =
    campaign.status === FacebookCampaignStatus.pending &&
    campaign.startAt !== null
      ? FacebookCampaignStatus.scheduled
      : campaign.status;
  return {
    ...campaign,
    status,
  };
};

const fetchCampaignsSelector = (campaigns: CampaignDetailsOverview[]) =>
  map(campaigns, campaign => updateStatus(campaign));

// Get Campaigns
const fetchCampaigns = async (
  id: number
): Promise<CampaignDetailsOverview[]> => {
  const {
    data: { campaigns },
  } = await axiosInstance.get<FetchCampaignsResponse>(`/games/${id}/campaigns`);
  return campaigns;
};

export const useFetchCampaigns = (gameId: number, options = {}) => {
  return useQuery(
    [ServerQueryKey.CampaignsList, gameId],
    () => fetchCampaigns(gameId),
    {
      select: fetchCampaignsSelector,
      ...options,
    }
  );
};

// Get Campaign
const fetchCampaign = async (
  gameId: number,
  campaignId: number,
  appVersion?: number | string
) => {
  if (campaignId === -1) {
    return defaultCampaignDetails;
  }

  let url = `/games/${gameId}/campaigns/${campaignId}`;
  if (appVersion) url = `${url}/?appVersion=${appVersion}`;

  const response: AxiosResponse =
    await axiosInstance.get<FetchCampaignResponse>(url);

  const {
    data: { data },
  }: { data: { data: CampaignDetails } } = response;
  return data;
};

export const useFetchCampaign = (
  gameId: number,
  campaignId: number,
  options = {},
  appVersion?: number | string
) => {
  return useQuery(
    [ServerQueryKey.CampaignDetails, campaignId, appVersion],
    () => fetchCampaign(gameId, campaignId, appVersion),
    {
      select: updateStatus,
      ...options,
    }
  );
};

const fetchOverviewCampaigns = async (dateRange: OverviewGamesDateRange) => {
  const { data } = await axiosInstance.get<OverviewCampaignsResponse>(
    `/campaign/overview?days=${dateRange}`
  );

  return data;
};

export const useFetchOverviewCampaigns = (
  dateRange: OverviewGamesDateRange,
  options = {}
) => {
  const query = useQuery(
    [ServerQueryKey.CampignOverviewList, dateRange],
    () => fetchOverviewCampaigns(dateRange),
    options
  );
  const campaigns = query.data ? query.data : { active: [], passed: [] };

  // update-status
  const data: OverviewCampaignsResponse = {
    passed: map(campaigns.passed, campaign => updateStatus(campaign)),
    active: map(campaigns.active, campaign => updateStatus(campaign)),
  };
  return { ...query, data };
};

type updateCampaignStatusReqBody = {
  status: FacebookCampaignStatus;
  reason?: string;
};

const updateCampaignStatus = async (
  gameId: number,
  campaignId: number,
  reqBody: updateCampaignStatusReqBody
) => {
  const { data } = await axiosInstance.put(
    `/games/${gameId}/campaigns/${campaignId}`,
    reqBody
  );
  return data;
};

export const useUpdateCampaignStatus = (gameId: number, campaignId: number) => {
  return useMutation((reqBody: updateCampaignStatusReqBody) =>
    updateCampaignStatus(gameId, campaignId, reqBody)
  );
};

const recreateCampaign = async (reqBody: {
  id: number;
  activationType: ActivationTypes;
  startAt?: string;
}) => {
  const { data } = await axiosInstance.post('/games/start-test', reqBody);
  return data;
};

export const useRecreateCampaign = () => {
  return useMutation(
    (reqBody: {
      id: number;
      activationType: ActivationTypes;
      startAt?: string;
    }) => recreateCampaign(reqBody)
  );
};

const createCampaign = async (reqBody: createCamapignReqBody) => {
  const { data } = await axiosInstance.post('/games/start-test', reqBody);
  return data;
};

export const useCreateCampaign = () => {
  return useMutation((reqBody: createCamapignReqBody) =>
    createCampaign(reqBody)
  );
};

const createRetryCampaign = async (reqBody: { id: number }) => {
  const { data } = await axiosInstance.post('/campaigns/retry-create', reqBody);
  return data;
};

export const useCreateRetryCampaign = () => {
  return useMutation((reqBody: { id: number }) => createRetryCampaign(reqBody));
};

const scheduleCampaign = async (
  gameId: number,
  campaignId: number,
  reqBody: scheduleCampaignReqBody
) => {
  const { data } = await axiosInstance.put(
    `/games/${gameId}/campaigns/${campaignId}/schedule`,
    reqBody
  );
  return data;
};

export const useUpdateScheduleCampaign = (
  gameId: number,
  campaignId: number
) => {
  return useMutation((reqBody: scheduleCampaignReqBody) =>
    scheduleCampaign(gameId, campaignId, reqBody)
  );
};

const editDescription = async (
  gameId: number,
  campaignId: number,
  text: string
) => {
  const reqBody = {
    text,
  };
  const { data } = await axiosInstance.put(
    `/games/${gameId}/campaigns/${campaignId}/description`,
    reqBody
  );
  return data;
};

export const useEditDescription = (gameId: number, campaignId: number) => {
  return useMutation((text: string) =>
    editDescription(gameId, campaignId, text)
  );
};

const fetchLevelProgression = async (
  gameId: number,
  campaignId: number,
  size: number,
  appVersion?: number | string
) => {
  let url = `/games/${gameId}/campaigns/${campaignId}/level-progression?size=${size}`;
  if (appVersion !== 'all') url = `${url}&appVersion=${appVersion}`;
  const { data } = await axiosInstance.get<LevelProgressionResponse>(url);
  return data;
};

export const useFetchLevelProgression = (
  gameId: number,
  campaignId: number,
  size: number,
  appVersion?: number | string,
  options = {}
) => {
  return useQuery(
    [ServerQueryKey.LevelProgression, campaignId],
    () => fetchLevelProgression(gameId, campaignId, size, appVersion),
    options
  );
};

const fetchAppVersions = async (gameId: string, campaignId: string) => {
  const { data } = await axiosInstance.get<number[]>(
    `/games/${gameId}/campaigns/${campaignId}/app-versions`
  );
  return data;
};

export const useFetchAppVersions = (
  gameId: string,
  campaignId: string,
  options = {}
) => {
  return useQuery(
    [ServerQueryKey.AppVersionsList, campaignId],
    () => fetchAppVersions(gameId, campaignId),
    options
  );
};

const fetchCompareAppVersions = async (gameId: string, campaignId: string) => {
  const { data } = await axiosInstance.get<ComparingCampaign[]>(
    `/games/${gameId}/campaigns/${campaignId}/comparing-campaigns`
  );
  return data;
};

export const useFetchCompareAppVersions = (
  gameId: string,
  campaignId: string,
  options = {}
) => {
  return useQuery(
    [ServerQueryKey.CompareCampaigns, campaignId],
    () => fetchCompareAppVersions(gameId, campaignId),
    options
  );
};
