import { useFetchMedias } from 'api';
import { SpinnerContainer } from 'assets/styles';
import { Spinner } from 'components/atoms';
import MediaGalleryModal from 'components/organisms/MediaGalleryModal';
import MediaThumbnail from 'components/Thumbnail/MediaThumbnail';
import ProgressThumbnail from 'components/Thumbnail/ProgressThumbnail';
import UploadThumbnail from 'components/Thumbnail/UploadThumbnail';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useParams } from 'react-router-dom';
import {
  IPMSortOrder,
  MediaSortType,
  VideoFile,
  VideoReviewStatus,
} from 'types';
import { StyledTestMediaLibrary } from './TestMediaLibrary.styles';

interface TestMediaLibraryProps {
  ipmSort: IPMSortOrder;
  hideUntested: boolean;
}

const TestMediaLibrary = ({ ipmSort, hideUntested }: TestMediaLibraryProps) => {
  const params = useParams<{ gameId: string }>();
  const gameId = Number(params.gameId);
  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchMedias,
  } = useFetchMedias(gameId, MediaSortType.DATE);
  const [uploadCompleteMediaFiles, setUploadCompleteMediaFiles] = useState<
    File[]
  >([]);
  const [uploadedMediaFiles, setUploadedMediaFiles] = useState<File[]>([]);
  const [medias, setMedias] = useState<VideoFile[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [curMediaIdx, setCurMediaIdx] = useState<number>(0);

  const handleCurMediaIdx = (idx: number) => {
    setCurMediaIdx(idx);
    setShowModal(true);
  };

  const handleSlide = () => {
    const videos = document.querySelectorAll('video');
    videos.forEach(video => video.pause());
  };

  const handleUploadedMediaFiles = (uploadedFiles: FileList) => {
    let newFiles: File[] = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      if (!uploadedMediaFiles.find(f => f.name === file.name)) {
        newFiles.push(file);
      }
    }
    setUploadedMediaFiles(prevState => [...prevState, ...newFiles.reverse()]);
  };

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleUploadedMediaFiles(event.target.files);
    }
  };

  const uploadFileProcessed = useCallback(
    ({ file, status }: { file: File; status?: Boolean }) => {
      if (status) {
        setUploadCompleteMediaFiles(prevState => [...prevState, file]);
      } else {
        setUploadedMediaFiles(prevState =>
          prevState.filter(f => f.name !== file.name)
        );
      }
    },
    []
  );

  useEffect(() => {
    const ipmVideos: VideoFile[] = [];
    const untestedVideos: VideoFile[] = [];

    (data as VideoFile[])?.forEach((video: VideoFile) =>
      !!video.ipm && !video.hasLowImpressions
        ? ipmVideos.push(video)
        : untestedVideos.push(video)
    );

    if (ipmVideos.length > 0) {
      ipmVideos?.sort((a: VideoFile, b: VideoFile) => {
        const first: number = !!a.ipm ? a.ipm.value : 0;
        const second: number = !!b.ipm ? b.ipm?.value : 0;
        return ipmSort === IPMSortOrder.ASC ? first - second : second - first;
      });

      if (!hideUntested) {
        setMedias([...ipmVideos, ...untestedVideos.reverse()]);
      } else {
        setMedias([...ipmVideos]);
      }
    } else {
      if (!hideUntested) {
        setMedias([...untestedVideos.reverse()]);
      } else {
        setMedias([]);
      }
    }
  }, [ipmSort, hideUntested, data]);

  useEffect(() => {
    if (uploadCompleteMediaFiles.length === uploadedMediaFiles.length) {
      refetchMedias();
    }
  }, [uploadCompleteMediaFiles, uploadedMediaFiles, refetchMedias]);

  const mediaLoading = isLoading || isFetching;

  const galleryItems = medias.map((media: VideoFile) => ({
    original: media.url,
    thumbnail: media.thumbnail,
  }));

  return (
    <StyledTestMediaLibrary data-testid="test-media-library">
      {mediaLoading ? (
        <SpinnerContainer>
          <Spinner size={48} color="#bebebe" />
        </SpinnerContainer>
      ) : (
        <>
          {/* Add Button */}
          <UploadThumbnail
            dropHandler={handleUploadedMediaFiles}
            onUpload={onUpload}
          />

          {/* Processing Media */}
          {uploadedMediaFiles.map((file, idx) => {
            if (
              !uploadCompleteMediaFiles.map(f => f.name).includes(file.name)
            ) {
              return (
                <ProgressThumbnail
                  key={idx}
                  file={file}
                  onFileProcessed={uploadFileProcessed}
                  gameId={gameId}
                />
              );
            }
            return null;
          })}
          {/* Medias */}
          {medias?.map((media: VideoFile, idx: number) => {
            const mediaName = media.url.split('/').pop() ?? media.url;
            return (
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={media.id}
              >
                <MediaThumbnail
                  media={media}
                  hasError={
                    media.reviewStatus === VideoReviewStatus.reviewFailed
                  }
                  isRemovable={true}
                  handleClick={() => handleCurMediaIdx(idx)}
                />
                <span title={mediaName} className="media-title">
                  {mediaName}
                </span>
              </div>
            );
          })}
          <MediaGalleryModal
            open={showModal}
            onClose={() => setShowModal(false)}
          >
            <ReactImageGallery
              items={galleryItems}
              startIndex={curMediaIdx}
              showPlayButton={false}
              showFullscreenButton={false}
              additionalClass="gallery-modal"
              onSlide={handleSlide}
              renderItem={(item: ReactImageGalleryItem) => {
                return (
                  <video className="image-gallery-image" controls={true}>
                    <source src={item.original} />
                  </video>
                );
              }}
            />
          </MediaGalleryModal>
        </>
      )}
    </StyledTestMediaLibrary>
  );
};

export default TestMediaLibrary;
