import { useFetchStudio, useFetchUser, useGetNavigationGames } from 'api';
import ActionButton from 'components/ActionButton/ActionButton';
import { SearchInput } from 'components/molecules';
import { AddGameModal } from 'components';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonContainer } from 'assets/styles';
import SidebarHeader from './SidebarHeader';
import SidebarNav from './SidebarNav';
import { hasPermission } from 'utils/user';
import { AccessFeatures } from 'types';

const GameSidebar = () => {
  const params = useParams<{
    studioId: string;
  }>();
  const studioId = Number(params.studioId);
  const { data: user } = useFetchUser();
  const { data, isLoading, isFetching, isSuccess } = useGetNavigationGames({
    enabled: !!studioId,
  });
  const { data: studio } = useFetchStudio(studioId, { enabled: !!studioId });
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [showModal, setShowModal] = useState(false);

  const toggleArchive = () => {
    setShowArchived(!showArchived);
  };

  const updateSearchText = (e: any) => {
    setSearchText(e.target.value);
  };

  const toggleModal = () => setShowModal(!showModal);

  const hasAddGameAccess = hasPermission(user, AccessFeatures.CREATE_GAME);

  if (isSuccess) {
    return (
      <>
        <SidebarHeader
          type="game"
          showArchived={showArchived}
          toggleArchive={toggleArchive}
          studiodata={data.studio}
        />
        <SearchInput
          sidenav={true}
          onChange={updateSearchText}
          placeholder={'Search Games'}
        />
        <SidebarNav
          type="game"
          data={data.games.filter((game: any) =>
            game.name
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
          )}
          loading={isLoading || isFetching}
          showArchived={showArchived}
        />
        {hasAddGameAccess && (
          <ButtonContainer>
            <ActionButton
              disabled={studio?.archived}
              onClick={toggleModal}
              text={'+ Add Game'}
            />
          </ButtonContainer>
        )}
        {showModal && <AddGameModal isOpen={showModal} onClose={toggleModal} />}
      </>
    );
  }
  return null;
};

export default GameSidebar;
