import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import { GlobalSearch, ServerQueryKey } from 'types';

const globalSearch = async (searchText: string) => {
  const { data } = await axiosInstance.get(`/search?q=${searchText}`);
  return data as GlobalSearch;
};

export const useGlobalSearch = (searchText: string, enabled: boolean) => {
  return useQuery(
    [ServerQueryKey.GlobalSearch],
    () => globalSearch(searchText),
    {
      initialData: {},
      enabled,
    }
  );
};
