import styled, { keyframes } from 'styled-components';

export const SidebarInnerWrapper = styled.div`
  width: 250px;
  border-right: 2px solid ${({ theme }) => theme.colors.grey[1100]};
  background: ${({ theme }) => theme.colors.grey.white};
  height: 100%;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

export const SidebarStatusWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SidebarNavigationLoader = styled.div`
  border: 8px solid ${({ theme }) => theme.colors.grey.white};
  border-top: 8px solid ${({ theme }) => theme.colors.yellow[500]};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  animation-name: ${loaderAnimation};
  display: inline-block;
  margin-top: 100px;
`;

export const SidebarNavigationHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 31px;
`;

export const SidebarGoBackLink = styled.img`
  filter: invert(51%) sepia(27%) saturate(1718%) hue-rotate(171deg)
    brightness(104%) contrast(94%);
  height: 16px;
  width: auto;
  margin: 0 8px 0 0;
  cursor: pointer;
`;

export const SidebarNavigationTitle = styled.span`
  color: ${({ theme }) => theme.colors.grey.black};
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
`;

export const SidebarNavigationActions = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -4px;
`;

interface SidebarNavigationActionProps {
  icon: string;
  active: boolean;
}

export const SidebarNavigationAction = styled.div<SidebarNavigationActionProps>`
  height: 36px;
  width: 36px;
  -webkit-mask-image: url(/assets/icons/icon_${props => props.icon}.svg);
  -webkit-mask-repeat: no-repeat;
  background-color: ${props =>
    props.active ? '#81c6fa' : props.theme.colors.grey[1000]};
  -webkit-mask-position: center;
  cursor: pointer;
`;

export const SidebarActionWrapper = styled.div`
  text-align: center;
  margin: 12px 0 24px;
`;

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  margin: 0 -12px;

  ::-webkit-scrollbar {
    display: none;
  }
`;
