import {
  BenchmarkLevels,
  ChartData,
  GameStatus,
  TagType,
  VideoReviewStatus,
} from 'types';

export enum CampaignTabs {
  kpi = 'kpi',
  activity = 'activity',
}

export const preSelectedCampaignTab = CampaignTabs.kpi;

export type CampaignParams = {
  studioId: string;
  gameId: string;
  campaignId: string;
  tabName: CampaignTabs;
};

export interface CampaignLinkGenerator {
  studioId: number;
  gameId: number;
  campaignId: number;
  tabName?: CampaignTabs;
}

interface ChurnRatePerLevel {
  churnRate: number;
  event: string;
}

interface LevelProgression {
  usersOfStepOne: number;
  event: string;
}

export interface CohortAverage {
  cohortSize: number;
  values: number[];
  predictedValues?: number[];
  predictionStartDayIndex?: number;
}

export interface CohortRow {
  [key: string]: CohortAverage;
}

export interface CohortRawData {
  unit: string;
  average: CohortAverage;
  rows: CohortRow;
  cpiData?: number;
}

export enum CampaignTypes {
  ipm = 'ipm',
  mvp = 'mvp',
  scb = 'scb',
  ctm = 'ctm',
}

export enum ActivationTypes {
  asap = 'asap',
  manual = 'manual',
  scheduled = 'scheduled',
}

export interface CampaignTypesArray {
  type: CampaignTypes;
  label: string;
  description: string;
  hasError: boolean;
  errorMessage: string;
}

export const campaignTypesArray: CampaignTypesArray[] = [
  {
    type: CampaignTypes.ipm,
    label: 'IPM Test',
    description:
      'An initial test in social networks to see the players engagement with the game idea.',
    hasError: false,
    errorMessage: 'No Facebook credentials added',
  },
  {
    type: CampaignTypes.mvp,
    label: 'MVP Test',
    description:
      'A Detailed test with more game levels to understand the player’s retention and how to improve it.',
    hasError: false,
    errorMessage: 'No Facebook credentials added',
  },
  {
    type: CampaignTypes.scb,
    label: 'SCB Test',
    description:
      'An advanced test designed to understand the game performance across various regions.',
    hasError: false,
    errorMessage: 'No Unity credentials added',
  },
  {
    type: CampaignTypes.ctm,
    label: 'CTM Test',
    description:
      'A tailored test to optimize network configurations and achieve maximum results for your specific needs.',
    hasError: false,
    errorMessage: 'No Facebook or Unity credentials added',
  },
];

export interface NetworkKpis {
  networkName: string;
  spend: number;
  cpi: number;
  cpc: number;
  ctr: number;
  cvr: number;
  ipm: number;
  impressions: number;
  installs: number;
}

export interface GenderKpis {
  gender: string;
  spend: number;
  cpi: number;
  cpc: number;
  ctr: number;
  cvr: number;
  ipm: number;
  impressions: number;
  installs: number;
}

export interface AgeKpis {
  age: string;
  spend: number;
  cpi: number;
  cpc: number;
  ctr: number;
  cvr: number;
  ipm: number;
  impressions: number;
  installs: number;
}

export enum CampaignNetworks {
  facebook = 'facebook',
  unity = 'unity',
}

export enum CampaignOs {
  ANDROID = 'android',
  IOS = 'ios',
}

export interface Campaign {
  id: number;
  name: string;
  status: FacebookCampaignStatus;
  os: CampaignOs;
  type: string;
  activeFrom: string | null;
  activeTo: string | null;
  kpis: Kpis;
  description: string;
  startAt: string | null;
}

export enum FacebookCampaignStatus {
  new = 'NEW',
  queued = 'QUEUED',
  processing = 'PROCESSING',
  review = 'VIDEO_REVIEW',
  pending = 'PENDING',
  active = 'ACTIVE', // TESTING
  completed = 'COMPLETED',
  failed = 'FAILED',
  reviewFailed = 'VIDEO_REVIEW_FAILED',
  canceled = 'CANCELED',
  terminated = 'TERMINATED',
  scheduled = 'SCHEDULED',
}

export interface Kpis {
  name?: string;
  ctr: number;
  cvr: number;
  cpc: number;
  spend: number;
  impressions: number;
  installs: number;
  cpi: number;
  ipm: number;
  socialTrafficSplit: number;
  fanTrafficSplit: number;
  socialTrafficSplitImpression: number;
  fanTrafficSplitImpression: number;
  videoUrl: string | null;
  thumbnail?: string;
  dailyKpis?: any;
  benchmarks?: any;
  dayOneRetention?: number | null;
  dayOnePlayTime?: number | null;
  ltv?: number | null;
  splittedKpisByNetwork: NetworkKpis[];
  splittedByAge?: AgeKpis[];
  splittedByGender?: GenderKpis[];
  reviewStatus: VideoReviewStatus;
  isDominant?: boolean;
  medias?: any;
  weightedCpi?: number;
}

export interface AvgAdImpPerUserChartData {
  adInterstitialSuccess?: ChartData;
  adRewardedSuccess?: ChartData;
  interstitialRewardedTotal?: ChartData;
}

export interface PlayTimeChartData {
  dayOnePlayTime?: ChartData;
  dayTwoPlayTime?: ChartData;
  daySevenPlayTime?: ChartData;
}

export interface CampaignDetails {
  id: number;
  name: string;
  type: CampaignTypes;
  platform: CampaignNetworks;
  gameName: string;
  studioName: string;
  status: FacebookCampaignStatus;
  country: string;
  os: CampaignOs;
  activeFrom?: string;
  activeTo?: string | null;
  startAt: string | null;
  isMonetized: boolean;
  kpis?: {
    dayOneRetention: number;
    impressions: number;
    ipm: number;
    ctr: number;
    adSetTestKpis: Kpis;
    adsTestKpis: Kpis[];
    flags: {
      haveSplittedByAgeKpis: boolean;
      haveSplittedByGenderKpis: boolean;
    };
    playTime?: PlayTimeChartData;
    cohortAveragePlaytimePerUser: CohortRawData;
    cohortAveragePlaytimePerUserCumulative: CohortRawData;
    adImpressions?: {
      interstitialRewardedTotal: ChartData;
      adInterstitialSuccess: ChartData;
      adRewardedSuccess: ChartData;
    };
    levelProgression: LevelProgression[];
    levelTenProgression?: number;
    churnRatePerLevel?: ChurnRatePerLevel[];
    sundayScore?: number;
    sevenDayRetention?: CohortRawData;
    arpiCohort?: CohortRawData;
    ltv?: number;
    benchmarks?: {
      dayOnePlayTime: BenchmarkLevels;
      levelTenProgression: BenchmarkLevels;
      ltv: BenchmarkLevels;
      sevenDayRetention: BenchmarkLevels;
    };
    adImpressionsAveragePerUser?: AvgAdImpPerUserChartData;
    updatedAt?: string;
    willBeUpdatedAt?: string;
  };
  description: string;
  errorMessage: string;
  terminationReason: string;
  parameters?: FacebookParameters | UnityParameters;
}

// Response
export interface FetchCampaignResponse {
  data: CampaignDetails;
  status: boolean;
}

export interface FacebookParameters {
  dailyBudget: number;
  country: string;
  countryFullName?: string;
  duration: number;
}

export interface UnityParameters {
  goal: string;
  country: string;
  countryFullName?: string;
  dailyBudget: number;
  totalBudget: number;
  bid: number;
}

export interface createCamapignReqBody {
  gameId: number;
  testType: CampaignTypes;
  testingPlatform: CampaignNetworks;
  mediaIds: number[];
  description: string;
  activationType?: ActivationTypes;
  startAt?: string;
  parameters?: FacebookParameters | UnityParameters;
}

export const defaultCampaignDetails: CampaignDetails = {
  id: -1,
  name: CampaignTypes.ipm,
  gameName: '',
  studioName: '',
  description: '',
  errorMessage: '',
  terminationReason: '',
  type: CampaignTypes.ipm,
  isMonetized: false,
  platform: CampaignNetworks.facebook,
  country: '',
  os: CampaignOs.ANDROID,
  status: FacebookCampaignStatus.new,
  startAt: '',
};

export interface FetchCampaignsResponse {
  campaigns: CampaignDetailsOverview[];
  status: boolean;
}

type CountryDetails = {
  flag: string;
  name: string;
};

export interface CampaignDetailsOverview {
  id: number;
  name: string;
  countryDetails: CountryDetails;
  platform: string;
  gameName: string;
  description: string;
  country: string;
  activeFrom: string;
  activeTo: string;
  status: FacebookCampaignStatus;
  os: CampaignOs;
  type: CampaignTypes;
  startAt: string | null;
  isMonetized: boolean;
  kpis: {
    installs: number;
    impressions: number;
    ipm: number;
    ltv: number;
    cpi: number;
    ctr: number;
    cpc: number;
    dayOnePlayTime: number;
    dayOneRetention: number;
    fanTrafficSplit: number;
    fanTrafficSplitImpression: number;
    spend: number;
    socialTrafficSplit: number;
    socialTrafficSplitImpression: number;
  };
}

export type OverviewCampaign = {
  id: number;
  studioName: string;
  studioId: number;
  gameName: string;
  gameId: number;
  testName: string;
  testType: GameStatus;
  cpi: number;
  ctr: number;
  ipm: number;
  cpc: number;
  impressions: number;
  dayOneRetentionAverage: number;
  logo: string;
  status: FacebookCampaignStatus;
  startedAt: string;
  endedAt: string;
  relatedToUser: boolean;
  description: string;
  startAt: string | null;
  tags: TagType[];
  platform: string;
  isMonetized: boolean;
  countryDetails: CountryDetails;
};

export interface OverviewCampaignsResponse {
  active: OverviewCampaign[];
  passed: OverviewCampaign[];
}

export interface LevelProgressionResponse {
  churnRatePerLevel: ChurnRatePerLevel[];
  levelProgression: LevelProgression[];
}

export interface scheduleCampaignReqBody {
  startAt: string;
}

export type UpdateStatusType =
  | CampaignDetails
  | CampaignDetailsOverview
  | OverviewCampaign;

export interface ComparingCampaign {
  id: number;
  name: string;
  latestAppVersion: number;
}
