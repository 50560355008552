import { useState } from 'react';
import { useGetCreativePacks } from 'api';
import { LightButton, CreativePack, CreativePackGenerator } from 'components';
import styled from 'styled-components';

interface UnityProps {
  gameId: number;
  onSelectPack: (ids: number[]) => void;
}

const Unity = ({ gameId, onSelectPack }: UnityProps) => {
  const {
    data: creativePacks,
    isSuccess,
    refetch: refetchCreativePacks,
  } = useGetCreativePacks(gameId);
  const [selectedPacks, setSelectedPacks] = useState<number[]>([]);
  const [isGeneratorView, setIsGeneratorView] = useState<boolean>(false);

  const handlePackSelect = (packId: number, isSelected: boolean) => {
    const newSelectedPacks = isSelected
      ? [...selectedPacks, packId]
      : selectedPacks.filter(id => id !== packId);
    setSelectedPacks(newSelectedPacks);
    onSelectPack(newSelectedPacks);
  };

  const newPackOnSuccess = () => {
    refetchCreativePacks();
    setIsGeneratorView(false);
  };

  return isGeneratorView ? (
    <>
      <div className="header">
        <span className="title">Add/Select videos</span>
        <button className="button" onClick={() => setIsGeneratorView(false)}>
          ✕ CANCEL
        </button>
      </div>
      <CreativePackGenerator onSuccess={newPackOnSuccess} />
    </>
  ) : (
    <>
      <div className="header">
        <span className="title">Add creative packs</span>
      </div>
      <LightButton fullWidth mb={4} onClick={() => setIsGeneratorView(true)}>
        + Add new
      </LightButton>
      <div className="header">
        <span className="title">
          Or Select from existing creative packs ({creativePacks.length})
        </span>
      </div>

      <CreativePacksWrapper>
        {isSuccess &&
          creativePacks.map(creativePack => (
            <CreativePack
              key={creativePack.id}
              packData={creativePack}
              onPackSelect={handlePackSelect}
            />
          ))}
      </CreativePacksWrapper>
    </>
  );
};

export default Unity;

const CreativePacksWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
`;
