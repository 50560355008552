import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { IoCloseSharp } from 'react-icons/io5';
import { ModalSize } from 'types';
import modelStylesGenerator, {
  CloseIcon,
  Body,
  Header,
  Title,
} from './Modal.styles';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  size?: ModalSize;
  ariaHideApp?: boolean; //for unit-testing
}

const Modal = ({
  isOpen,
  onClose,
  children,
  title = '',
  size = ModalSize.md,
  ariaHideApp = false,
}: ModalProps) => {
  const preventDefaultBehaviour = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => e.preventDefault();
  return (
    <ReactModal
      ariaHideApp={ariaHideApp}
      isOpen={isOpen}
      style={modelStylesGenerator(size)}
      onRequestClose={preventDefaultBehaviour}
    >
      <Header onClick={preventDefaultBehaviour}>
        <Title role="title">{title}</Title>
        <CloseIcon data-testid="close-button" onClick={onClose}>
          <IoCloseSharp />
        </CloseIcon>
      </Header>
      <Body>{children}</Body>
    </ReactModal>
  );
};

export default Modal;
