import { ErrorMessage } from './Charts/Charts.styles';

interface TestErrorProps {
  message: string;
  imgSrc: string;
}

const TestError = ({ message, imgSrc }: TestErrorProps) => (
  <ErrorMessage>
    <img src={imgSrc} alt="error icon" />
    {message}
  </ErrorMessage>
);

export default TestError;
