import styled from 'styled-components';

const CongratulationsWrapper = styled.div`
  height: 100%;
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .image {
    margin: 0 0 10px;
  }

  .title {
    margin: 0 0 10px;
    font-weight: 600;
  }

  .body {
    margin: 0 0 10px;
    font-weight: 300;
    text-align: center;
  }

  .submit {
    margin: 0;
    background-color: #f066a7;
    border-color: #f066a7;
    color: ${({ theme }) => theme.colors.grey.white};
    text-transform: uppercase;
  }
`;

export default CongratulationsWrapper;
