import { TestMediaType } from 'types';
import { StyledTestMedia } from './TestMedia.styles';
import TestMediaContainer from './TestMediaContainer/TestMediaContainer';

const TestMedia = () => {
  return (
    <StyledTestMedia>
      <TestMediaContainer
        title="IPM/MVP VIDEOS"
        testMediaType={TestMediaType.VIDEO}
      />
      <TestMediaContainer
        title="CREATIVE PACKS"
        testMediaType={TestMediaType.CREATIVE_PACK}
      />
    </StyledTestMedia>
  );
};

export default TestMedia;
