import { useMemo } from 'react';
import { BusinessChartType, CohortTableDays, CohortTableRow } from 'types';
import { cohortChartOptionsGenerator } from '../../utils/CohortChartUtilsTs';


const useArpiChartOptions = (
  dataUnit: string,
  predictedRow: CohortTableDays,
  cpiChartData: number[],
  cpiRow: any,
  selectedRows: CohortTableRow[],
  showCpi: boolean,
  predictedValues: number[] | undefined,
) => {
  const chartOptions = useMemo(() => {
    // check for predicted
    let rows = [];
    const avgSelected = !!selectedRows.find(
      (row: CohortTableRow) => row.installDate === 'Average'
    );

    if (avgSelected && predictedValues) {
      if (cpiChartData.length > 0 && showCpi) {
        rows = [...selectedRows, predictedRow, cpiRow];
      } else {
        rows = [...selectedRows, predictedRow];
      }
    } else {
      if (cpiChartData.length > 0 && showCpi) {
        rows = [...selectedRows, cpiRow];
      } else {
        rows = [...selectedRows];
      }
    }

    return cohortChartOptionsGenerator(rows, dataUnit, BusinessChartType.ARPI);
  }, [dataUnit, predictedRow, cpiChartData, cpiRow, selectedRows, showCpi,predictedValues]);

  return chartOptions;
};

export default useArpiChartOptions;
