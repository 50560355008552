import React from 'react';
import styled from 'styled-components';
import { GameConfig } from 'types';
import GameConfigItem from './GameConfigItem';

interface GameConfigsListProps {
  configs: GameConfig[];
  activeConfigKey: string;
  configsJSON: any;
  handleInputChange: (key: string, value: string | null) => void;
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    configKey: string
  ) => void;
  handleOnSave: (key: string) => void;
  handleActiveConfig: (configKey: string) => void;
  handleClear: (key: string) => void;
}

const GameConfigsList = ({
  configs,
  activeConfigKey,
  configsJSON,
  handleInputChange,
  handleKeyDown,
  handleOnSave,
  handleActiveConfig,
  handleClear,
}: GameConfigsListProps) => {
  return (
    <ConfigList>
      {configs.map((config: GameConfig, idx: number) => (
        <GameConfigItem
          key={idx}
          config={config}
          activeConfigKey={activeConfigKey}
          configsJSON={configsJSON}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          handleOnSave={handleOnSave}
          handleActiveConfig={handleActiveConfig}
          handleClear={handleClear}
        />
      ))}
    </ConfigList>
  );
};

const ConfigList = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
`;

export default GameConfigsList;
