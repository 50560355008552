import styled from 'styled-components';

export const CommentsModalContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    font-size: 14px;
    margin: 32px 0;
    padding: 0 32px;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 32px;
    padding-bottom: 0;
    overflow: auto;
  }

  .controls {
    height: 100px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0 32px;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
