import { CSSProperties, FC } from 'react';
import {
  ModalBackdrop,
  ModalCloseIcon,
  ModalContainer,
  ModalHeader,
  ModalHeaderCloseButton,
  ModalHeaderTitle,
  ModalOuterContainer,
} from 'components/atoms';
import { ModalContent } from 'components/atoms/modal/ModalContent';

interface Props {
  title: string;
  onClose: () => void;
  containerStyle?: CSSProperties;
  children?: React.ReactNode;
}

export const Modal: FC<Props> = ({
  children,
  title,
  onClose,
  containerStyle,
}) => {
  return (
    <>
      <ModalBackdrop />
      <ModalOuterContainer>
        <ModalContainer style={{ ...(containerStyle ?? {}) }}>
          <ModalHeader>
            <ModalHeaderTitle>{title}</ModalHeaderTitle>
            <ModalHeaderCloseButton onClick={onClose}>
              <ModalCloseIcon />
            </ModalHeaderCloseButton>
          </ModalHeader>
          <ModalContent>{children}</ModalContent>
        </ModalContainer>
      </ModalOuterContainer>
    </>
  );
};
