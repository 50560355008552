import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { Country, ServerQueryKey } from 'types';

const fetchCountries = async () => {
  const { data } = await axiosInstance.get<Country[]>(
    `/location/countries/list`
  );
  return data;
};

export const useFetchCountries = () => {
  return useQuery([ServerQueryKey.Countries], () => fetchCountries());
};
