import {  CohortTableDays, CohortTableRow } from 'types';
import { cohortIndexArrayGenerator } from '../../utils/GlobalUtilsTs';
import { cohortChartDataGenerator } from '../../utils/CohortChartUtilsTs';

const useArpiChartData = (
  selectedRows: CohortTableRow[],
  predictedValues: number[] | undefined,
  labelIdx: number[],
  cpiChartData: number[],
  showCpi: boolean,
  numOfCols: number,
  startingIdx: number,
  predictedRow: CohortTableDays,
  cpiRow: any
) => {
  const handleChartData = () => {
    let labelIndexes;
    let newRows;

    // check if average is selected
    const avgSelected = !!selectedRows.find(
      (row: CohortTableRow) => row.installDate === 'Average'
    );
    const predictedExists = !!predictedValues;

    if (avgSelected && predictedExists) {
      labelIndexes = labelIdx;
      if (cpiChartData.length > 0 && showCpi) {
        newRows = [
          selectedRows[0],
          predictedRow,
          cpiRow,
          ...selectedRows.slice(1),
        ];
      } else {
        newRows = [selectedRows[0], predictedRow, ...selectedRows.slice(1)];
      }
    } else {
      labelIndexes = cohortIndexArrayGenerator(numOfCols, startingIdx);

      if (cpiChartData.length > 0 && showCpi) {
        newRows = [...selectedRows, cpiRow];
      } else {
        newRows = [...selectedRows];
      }
    }

    return cohortChartDataGenerator(newRows, labelIndexes);
  };

  return { handleChartData };
};

export default useArpiChartData;
