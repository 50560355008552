import styled from 'styled-components';
import { GameOrientation } from 'types';

interface RadioProps {
  type: GameOrientation;
  label: string;
  onChange: () => void;
  isChecked: boolean;
}

const Radio: React.FC<RadioProps> = ({ type, label, isChecked, onChange }) => {
  return (
    <StyledRadio>
      <label htmlFor={type}>
        <input
          className="radio"
          type="radio"
          name="orientation-type"
          id={type}
          checked={isChecked}
          onChange={onChange}
        />
        <span className="label">{label}</span>
      </label>
    </StyledRadio>
  );
};

export default Radio;

const StyledRadio = styled.div`
  label {
    cursor: pointer;
  }
  .radio {
    cursor: inherit;
    transform: scale(1.3);

    &:checked + .label {
      color: ${({ theme }) => theme.colors.blue[500]};
    }
  }

  .label {
    font-size: 18px;
    margin-left: 12px;
  }
`;
