import { useGetNavigationStudios } from 'api';
import { useFetchUser } from 'api/modules/useUser';
import ActionButton from 'components/ActionButton/ActionButton';
import { SearchInput } from 'components/molecules';
import { useRef, useState } from 'react';
import AppliedFilters from './AppliedFilters';
import FilterPopup from './FilterPopup';
import { ButtonContainer } from 'assets/styles';
import SidebarHeader from './SidebarHeader';
import SidebarNav from './SidebarNav';
import { hasPermission } from 'utils/user';
import { AccessFeatures } from 'types';
import AddStudioModalV2 from 'components/AddStudioModal/AddStudioModal';

const StudioSidebar = () => {
  const { data: user } = useFetchUser();
  const { data, isLoading } = useGetNavigationStudios({ initialData: [] });
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [managerName, setManagerName] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const toggleArchive = () => {
    setShowArchived(!showArchived);
  };

  const updateSearchText = (e: any) => {
    setSearchText(e.target.value);
  };
  const toggleModal = () => setShowModal(!showModal);
  const togglePopup = () => setShowPopup(!showPopup);
  const onFilterSubmit = (
    managerName: string | null,
    country: string | null
  ) => {
    setManagerName(managerName);
    setCountry(country);
  };

  const filterBySearchText = (searchText: string, studios: any[]) => {
    return studios.filter((studio: any) => {
      return studio.name
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase());
    });
  };

  const filterByManager = (managerName: string | null, studios: any[]) => {
    return studios.filter((studio: any) => {
      if (managerName === null) return true;
      return studio.manager.name === managerName;
    });
  };

  const filterByCountry = (country: string | null, studios: any[]) => {
    return studios.filter((studio: any) => {
      if (country === null) return true;
      return studio.country === country;
    });
  };

  const filterStudios = (
    studios: any[],
    searchText: string,
    managerName: string | null,
    country: string | null
  ) => {
    return filterByCountry(
      country,
      filterByManager(managerName, filterBySearchText(searchText, studios))
    );
  };

  const hasAddStudioAccess = hasPermission(user, AccessFeatures.CREATE_STUDIO);

  return (
    <>
      <span ref={popupRef}>
        <SidebarHeader
          type={'studio'}
          showArchived={showArchived}
          toggleArchive={toggleArchive}
          togglePopup={togglePopup}
        />
      </span>
      <SearchInput
        sidenav={true}
        onChange={updateSearchText}
        placeholder={'Search Studios'}
      />
      {(managerName || country) && (
        <AppliedFilters
          manager={managerName!}
          country={country!}
          setManager={() => setManagerName(null)}
          setCountry={() => setCountry(null)}
        />
      )}
      <SidebarNav
        type="studio"
        data={filterStudios(data, searchText, managerName, country)}
        loading={isLoading || !data.length}
        showArchived={showArchived}
      />
      {hasAddStudioAccess && (
        <ButtonContainer>
          <ActionButton onClick={toggleModal} text={'+ Add Studio'} />
        </ButtonContainer>
      )}
      {/* <AddStudioModal visible={showModal} onClose={toggleModal} /> */}
      {showModal && (
        <AddStudioModalV2
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          isCreateMode={true}
        />
      )}
      <FilterPopup
        visible={showPopup}
        anchor={popupRef}
        onClose={togglePopup}
        onSubmit={onFilterSubmit}
        currentManager={managerName}
        currentCountry={country}
      />
    </>
  );
};

export default StudioSidebar;
