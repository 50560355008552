import styled, { css } from 'styled-components';

export const ModalHeaderCloseButton = styled.div`
  cursor: pointer;
  font-size: 24px;
  color: #b5b5b5;
  ${() =>
    window.innerHeight <= 720
      ? css`
          padding: 12px 32px 8px 12px;
        `
      : css`
          padding: 32px 32px 24px 24px;
        `}
`;
