import styled, { css } from 'styled-components';

interface StyledCardProps {
  clickable: boolean;
  disabled?: boolean;
}

export const StyledCard = styled.div<StyledCardProps>`
  position: relative;
  height: 150px;
  width: 300px;
  border-radius: 4px;
  box-shadow: ${props => props.theme.colors.grey[1300]} 0px 3px 8px;
  background-color: ${props => props.theme.colors.grey.white};
  cursor: ${props => (props.clickable ? 'pointer' : '')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .content {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: -17px;
  }

  .title {
    font-size: 20px;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      color: ${props => props.theme.colors.grey[500]};
    `}
`;
