import { Tab, TabContainer } from 'components/atoms';
import { useNavigate } from 'react-router-dom';

type TabhandlerData = {
  tabName: string;
  redirectUrl: string;
  selectedTab: string;
  tabHeaderText?: string;
  component: () => JSX.Element;
};
interface TabHandlerCompProps {
  tabData: TabhandlerData[];
}

const TabHandler: React.FC<TabHandlerCompProps> = ({ tabData }) => {
  const navigate = useNavigate();

  return (
    <>
      {tabData.length > 1 ? (
        <TabContainer>
          {tabData.map((tab, index) => (
            <Tab
              key={index}
              active={tab.selectedTab === tab.tabName}
              onClick={() => navigate(tab.redirectUrl)}
            >
              {tab.tabHeaderText
                ? tab.tabHeaderText
                : tab.tabName.toLocaleUpperCase()}
            </Tab>
          ))}
        </TabContainer>
      ) : null}
      {tabData.map(tab =>
        tab.selectedTab === tab.tabName ? tab.component() : null
      )}
    </>
  );
};

export default TabHandler;
