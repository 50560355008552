import styled from 'styled-components';

interface CommentProps {
  name: string;
  date: string;
  commentText: string;
}

const Comment = ({ name, date, commentText }: CommentProps) => {
  return (
    <StyledComment>
      <div className="info">
        <div className="name">{`${name}:`}</div>
        <div className="date">{date}</div>
      </div>
      <div className="comment-text">{commentText}</div>
    </StyledComment>
  );
};

export default Comment;

const StyledComment = styled.div`
  position: relative;
  margin-bottom: 12px;
  border-radius: 4px;
  padding: 24px;
  border: 1px solid var(--neutrals-grey-5, #f3f3f3);
  display: flex;
  flex-direction: column;

  .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .name {
      font-weight: 700;
    }

    .date {
      font-size: 14px;
      color: #979797;
    }
  }

  .comment-text {
    font-size: 14px;
    min-height: 72px;
    color: #333;
    line-height: 20px;
    white-space: pre-wrap;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 24px;
    height: 13px;
    width: 2px;
    background-color: #f3f3f3;
  }

  &:last-child {
    margin-bottom: 5px;

    &::after {
      display: none;
    }
  }
`;
