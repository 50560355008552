import {
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  EmptyLogoCircle,
  ErrorPopupDescription,
  InputContainer,
  LogoRequirements,
  StudioLogoUploadWrapper,
  StyledTextInput,
  TopFormElementsWrapper,
  useStudioSelectStyles,
} from 'components/AddStudioModal/AddStudioModal.styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LocationOption, Member } from 'types';
import { fetchCitiesRequest, fetchMembers } from 'services/api';
import { Button, ErrorPopup, Label } from 'components/atoms';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import 'react-datepicker/dist/react-datepicker.css';
import {
  isImageFormatValid,
  isImageSizeValid,
  isImageDimensionsValid,
  renderFormFieldError,
} from 'utils';
import { useCreateLogo } from 'api';
import { StudioDetails, useStudioWizard } from 'store';
import { SubmitStudioDetailsRef } from '../AddStudioModal';

const validImage = {
  format: ['image/jpeg', 'image/png'],
  size: 1024, // KB
  dimensions: [512, 512], // Pixel
};

interface AddStudioModalProps {
  isCreateMode: boolean;
}

export const getCityName = (location?: string) => {
  return location?.split(',').shift();
};

export const fetchCitiesBySearchTerm = ({
  value,
  setCities,
}: {
  value: string;
  setCities: (value: LocationOption[]) => void;
}): Promise<LocationOption[]> => {
  if (value.length < 3) {
    return Promise.resolve([]);
  }
  return fetchCitiesRequest({ term: value })
    .then(cities => {
      const options = cities.map(city => {
        return { label: city.label, value: city.id.toString() };
      });
      setCities(options);
      return Promise.resolve(options);
    })
    .catch(() => {
      return Promise.resolve([]);
    });
};

const StudioDetailStep = forwardRef<
  SubmitStudioDetailsRef,
  AddStudioModalProps
>(({ isCreateMode }, ref) => {
  const { studioDetails, setIsStudioDetailsValid, setStudioDetails } =
    useStudioWizard(state => state);
  const selectStyles = useStudioSelectStyles();
  const { mutate: uploadLogo } = useCreateLogo('/studios/logo');
  const [managers, setManagers] = useState<Member[]>([]);
  const [cities, setCities] = useState<LocationOption[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [apiError, setApiError] = useState<string>('');
  const [uiSideError, setUiSideError] = useState<string[]>([]);

  const searchCityTimeout = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const uploadLogoButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (studioDetails.location.value) {
      const city = getCityName(studioDetails.location.value);
      if (city) {
        void fetchCitiesBySearchTerm({ value: city, setCities });
      }
    }
  }, [studioDetails]);

  useEffect(() => {
    fetchMembers(true).then(data => setManagers(data));
  }, []);

  const resetPopupError = () => {
    setApiError('');
    setUiSideError([]);
  };

  const postLogo = (logo: File) => {
    setIsUploading(true);
    const reqBody = new FormData();
    reqBody.append('logo', logo);
    uploadLogo(reqBody, {
      onSuccess: (res: any) => {
        setLogoUrl(res.url);
        setIsUploading(false);
      },
      onError: (error: any) => {
        setApiError(error.message);
        setIsUploading(false);
      },
    });
  };

  const handleLogoChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const logo = event.target.files[0];
    resetPopupError();

    const isFormatValid = isImageFormatValid(logo, validImage.format);
    const isSizeValid = isImageSizeValid(logo, validImage.size);
    const isDimensionsValid = await isImageDimensionsValid(
      logo,
      validImage.dimensions
    );

    const areAllValid = isFormatValid && isSizeValid && isDimensionsValid;

    if (areAllValid) postLogo(logo);
    else {
      if (!isFormatValid) setUiSideError(prev => [...prev, 'format']);
      if (!isSizeValid) setUiSideError(prev => [...prev, 'size']);
      if (!isDimensionsValid) setUiSideError(prev => [...prev, 'dimensions']);
    }
  };

  const onErrorPopupClose = () => {
    resetPopupError();
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    cityId: Yup.string().notOneOf(['0'], 'Location is required').required(),
    contactName: Yup.string().required(),
    contactEmail: Yup.string().email().required(),
    contactPhone: Yup.lazy(value => {
      if (!value) {
        return Yup.string();
      }
      return Yup.string().matches(/^(\+[0-9]+)$/);
    }),
    managerId: Yup.string()
      .notOneOf(['0'], 'Publishing manager is required')
      .required(),
    externalUrl: Yup.string().url(),
  });

  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    setLogoUrl(studioDetails.logo);
    const fields = {
      name: studioDetails.studioName,
      cityId: studioDetails.location.id,
      contactName: studioDetails.contactName,
      contactEmail: studioDetails.contactEmail,
      contactPhone: studioDetails.contactPhoneNumber,
      managerId: studioDetails.publishingManager.id,
      externalUrl: studioDetails.mondayURL,
    };
    Object.entries(fields).forEach(([name, value]) => {
      setValue(name, value);
    });
    if (!isCreateMode || (isCreateMode && studioDetails.studioName)) {
      trigger();
    }
  }, [studioDetails, setValue]);

  useEffect(() => {
    setIsStudioDetailsValid(isValid);
  }, [isValid, setIsStudioDetailsValid]);

  const submitStudioDetails = () => {
    const {
      name,
      contactEmail,
      contactName,
      contactPhone,
      externalUrl,
      managerId,
      cityId,
    } = getValues();
    const cityObj = cities.find(city => city.value === String(cityId));

    const location = {
      id: Number(cityObj?.value),
      value: cityObj?.label!,
    };

    const manager = managers.find(manager => manager.id === Number(managerId));
    const studio: StudioDetails = {
      studioName: name,
      logo: logoUrl,
      location: location,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhoneNumber: contactPhone,
      publishingManager: manager!,
      mondayURL: externalUrl,
    };

    setStudioDetails(studio);
  };

  useImperativeHandle(ref, () => ({
    submitStudioDetails,
  }));

  if (!studioDetails.location.value && !isCreateMode) {
    return null;
  }

  return (
    <>
      <TopFormElementsWrapper style={{ columnGap: '90px' }}>
        <InputContainer style={{ marginTop: '20px' }}>
          <Label htmlFor="name">Studio Name *</Label>
          <StyledTextInput
            error={!!errors.name}
            {...register('name')}
            onBlur={() => {
              trigger('name');
            }}
            placeholder="Type something ..."
          />
          {renderFormFieldError(errors, 'name')}
        </InputContainer>
        <StudioLogoUploadWrapper>
          <EmptyLogoCircle url={logoUrl} />
          <div className="upload-button-container">
            <input
              type="file"
              accept={validImage.format.join(',')}
              style={{ display: 'none' }}
              ref={inputRef}
              onChange={handleLogoChange}
            />
            <Button
              style={{
                width: '100px',
                height: '24px',
                fontSize: '12px',
                padding: '4px',
              }}
              ref={uploadLogoButtonRef}
              type="button"
              disabled={isUploading}
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              Upload Logo
            </Button>
          </div>
          <LogoRequirements>
            Only png or jpg files. Max dimensions: 512 x 512 px. Max file size:
            1 MB.
          </LogoRequirements>
          <ErrorPopup
            visible={uiSideError.length > 0 || apiError !== ''}
            anchor={uploadLogoButtonRef}
            onClose={onErrorPopupClose}
            title={'The file has not been uploaded!'}
          >
            <ErrorPopupDescription>
              {uiSideError.length > 0
                ? `The file has an incorrect ${uiSideError.join(', ')}.`
                : apiError}
            </ErrorPopupDescription>
          </ErrorPopup>
        </StudioLogoUploadWrapper>
        <InputContainer>
          <Label htmlFor="cityId">Location *</Label>
          <Controller
            control={control}
            name="cityId"
            defaultValue={studioDetails.location.id.toString()}
            render={({ field: { onChange, value } }) => {
              return (
                <AsyncSelect
                  // @ts-ignore
                  error={!!errors.cityId}
                  styles={selectStyles}
                  defaultOptions={cities}
                  value={cities.find(c => c.value == value)}
                  placeholder="Choose a location..."
                  onChange={entry => {
                    onChange(entry?.value);
                  }}
                  onBlur={() => {
                    trigger('cityId');
                  }}
                  loadOptions={inputValue => {
                    if (searchCityTimeout.current) {
                      clearTimeout(searchCityTimeout.current);
                      searchCityTimeout.current = null;
                    }
                    if (inputValue.length < 3) {
                      return Promise.resolve([]);
                    }
                    return new Promise(resolve => {
                      searchCityTimeout.current = setTimeout(() => {
                        fetchCitiesBySearchTerm({
                          value: inputValue,
                          setCities,
                        })
                          .then(result => resolve(result))
                          .catch(() => resolve([]));
                      }, 1000);
                    });
                  }}
                />
              );
            }}
          />
          {renderFormFieldError(errors, 'cityId')}
        </InputContainer>
        <InputContainer>
          <Label htmlFor="contactName">Contact Name *</Label>
          <StyledTextInput
            error={!!errors.contactName}
            {...register('contactName')}
            placeholder="Type something ..."
            onBlur={() => {
              trigger('contactName');
            }}
          />
          {renderFormFieldError(errors, 'contactName')}
        </InputContainer>
        <InputContainer>
          <Label htmlFor="contactEmail">Contact Email *</Label>
          <StyledTextInput
            error={!!errors.contactEmail}
            {...register('contactEmail')}
            placeholder="Type something ..."
            onBlur={() => {
              trigger('contactEmail');
            }}
          />
          {renderFormFieldError(errors, 'contactEmail')}
        </InputContainer>
        <InputContainer>
          <Label htmlFor="contactPhone">Contact phone number</Label>
          <StyledTextInput
            error={!!errors.contactPhone}
            {...register('contactPhone')}
            placeholder="+49123456789"
            onBlur={() => {
              trigger('contactPhone');
            }}
          />
          {renderFormFieldError(errors, 'contactPhone')}
        </InputContainer>
        <InputContainer>
          <Label htmlFor="managerId">Publishing manager *</Label>
          <Controller
            control={control}
            name="managerId"
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  // @ts-ignore
                  error={!!errors.managerId}
                  styles={selectStyles}
                  onBlur={() => {
                    trigger('managerId');
                  }}
                  options={managers.map(m => ({
                    label: m.name,
                    value: m.id.toString(),
                  }))}
                  placeholder="Choose a person..."
                  value={managers
                    .map(m => ({ label: m.name, value: m.id.toString() }))
                    .find(m => m.value == value)}
                  onChange={entry => {
                    onChange(entry?.value);
                    trigger('managerId');
                  }}
                />
              );
            }}
          />
          {renderFormFieldError(errors, 'managerId')}
        </InputContainer>
        <InputContainer style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <Label htmlFor="externalUrl">Monday.com URL</Label>
          <StyledTextInput
            {...register('externalUrl')}
            error={!!errors.externalUrl}
            placeholder="Type something ..."
            onBlur={() => {
              trigger('externalUrl');
            }}
          />
          {renderFormFieldError(errors, 'externalUrl')}
        </InputContainer>
      </TopFormElementsWrapper>
    </>
  );
});

export default StudioDetailStep;
