import styled, { useTheme } from 'styled-components';
import QuestionMarkImg from 'assets/images/question_mark.png';
import { Icon } from 'assets/styles';

interface DescriptionProps {
  title: string;
  content: string | React.ReactNode;
  error: string | undefined;
}

const Description: React.FC<DescriptionProps> = ({ title, content, error }) => {
  const theme = useTheme();
  return (
    <StyledDescription>
      <div className="header">
        <span>{title}</span>
        <Icon src={QuestionMarkImg} alt="question-mark" />
      </div>
      <div className="content">
        <div>{content}</div>
        {error && (
          <div style={{ color: theme.colors.pink[500], marginTop: '10px' }}>
            * {error}
          </div>
        )}
      </div>
    </StyledDescription>
  );
};

export default Description;

const StyledDescription = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.grey[1400]};

  .header {
    height: 150px;
    font-size: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .content {
    flex: 1;
  }
`;
