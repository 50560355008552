import styled, { css } from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  font-size: 12px;
  ${() =>
    window.innerHeight <= 720
      ? css`
          padding: 12px 48px 32px;
        `
      : css`
          padding: 16px 128px 48px;
        `}
`;
