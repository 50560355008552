import Skeleton from 'components/Skeleton/Skeleton';
import styled from 'styled-components';

interface SidebarNavSkeletonProps {
  type: 'studio' | 'game' | 'campaign';
}

const SidebarNavSkeleton: React.FC<SidebarNavSkeletonProps> = ({ type }) => {
  return (
    <div>
      {new Array(5)
        .fill(0)
        .map((_, idx) =>
          type === 'studio' ? (
            <StudioNavItem key={idx} />
          ) : type === 'game' ? (
            <GameNavItem key={idx} />
          ) : (
            <CampaignNavItem key={idx} />
          )
        )}
    </div>
  );
};

const StudioNavItem = () => {
  return (
    <StyledStudioNavItem>
      <Skeleton style={{ borderRadius: '50%' }} width="36px" height="36px" />
      <Skeleton width="100px" height="18px" />
    </StyledStudioNavItem>
  );
};

const GameNavItem = () => {
  return (
    <StyledGameNavItem>
      <Skeleton width="36px" height="36px" />
      <div className="info">
        <Skeleton width="100px" height="14px" />
        <Skeleton width="30px" height="10px" />
      </div>
    </StyledGameNavItem>
  );
};

const CampaignNavItem = () => {
  return (
    <StyledCampaignNavItem>
      <Skeleton width="80px" height="14px" />
      <Skeleton width="70px" height="14px" />
    </StyledCampaignNavItem>
  );
};

const StyledCampaignNavItem = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #e5e6e7;
  margin-bottom: 10px;
`;

const StyledGameNavItem = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 10px;
  gap: 10px;
  border: 1px solid #e5e6e7;
  margin-bottom: 10px;

  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

const StyledStudioNavItem = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 10px;
  gap: 10px;
  border: 1px solid #e5e6e7;
  margin-bottom: 10px;
`;

export default SidebarNavSkeleton;
