export interface Genre {
  id: number;
  name: string;
}

export const allGenres: Genre = {
  id: 1,
  name: 'All Genres',
};

export type Market = 'sunday' | 'marketplace';

export interface IdeationApp {
  id: number;
  name: string;
  icon: string;
  releaseDate: string;
  publisher: string;
  downloads: number;
  iosStoreUrl: string;
  androidStoreUrl: string;
  screenshots: string[];
}

export const initialGenresData = {
  genres: [],
  updatedAt: '',
  willBeExpiredAt: '',
};

export const initialAppsData = {
  apps: [],
  updatedAt: '',
  willBeExpiredAt: '',
};

export interface GalleryModalTitle {
  icon: string;
  name: string;
  genre: string;
  publisher: string;
  releaseDate: string;
  downloads: string;
}

export const initialGalleryModalTitle = {
  icon: '',
  name: '',
  genre: '',
  publisher: '',
  releaseDate: '',
  downloads: '1,000',
};
