import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import { ArchiveResponse, ArchivePageName, InvoiceActions } from 'types';

const postArchive = async (id: number, pageName: ArchivePageName) => {
  const { data } = await axiosInstance.post(`/${pageName}/archive`, { id });
  return data as ArchiveResponse;
};
const invoiceArchive = async (
  studioId: number,
  invoiceId: number,
  action: string
) => {
  const { data } = await axiosInstance.put(
    `/studios/${studioId}/invoices/${action}/${invoiceId}`
  );
  return data as ArchiveResponse;
};
const invoiceDelete = async (studioId: number, invoiceId: number) => {
  const { data } = await axiosInstance.delete(
    `/studios/${studioId}/invoices/${invoiceId}`
  );
  return data as ArchiveResponse;
};

export const usePostArchive = (pageName: ArchivePageName) =>
  useMutation((id: number) => postArchive(id, pageName));

export const useInvoiceArchive = (studioId: number, action: InvoiceActions) =>
  useMutation((invoiceId: number) =>
    invoiceArchive(studioId, invoiceId, action)
  );

export const useInvoiceDelete = (studioId: number) =>
  useMutation((invoiceId: number) => invoiceDelete(studioId, invoiceId));

const postUnarchive = async (id: number, pageName: ArchivePageName) => {
  const { data } = await axiosInstance.post(`/${pageName}/unarchive`, { id });
  return data as ArchiveResponse;
};

export const usePostUnarchive = (pageName: ArchivePageName) =>
  useMutation((id: number) => postUnarchive(id, pageName));
