import styled, { css } from 'styled-components';

interface WrapperProps {
  hasContent: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  text-align: center;

  ${(props: WrapperProps) =>
    !props.hasContent &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`;

export const CampaignInfo = styled.div`
  display: flex;
  flex-direction: column;

  .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
`;

export const RowWrapper = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  text-align: start;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface RowTitleProps {
  fontWeight?: string | null;
  fontSize?: string | null;
  isHeader?: boolean;
}
export const RowTitle = styled.span<RowTitleProps>`
  margin-bottom: 5px;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${props => (props.fontSize ? props.fontSize : 600)};
  @media (max-width: 1440px) {
    max-width: ${props => (props.isHeader ? '125px' : 'none')};
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const GameBadgeWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 20px;
  margin-bottom: 25px;

  .description-wrapper {
    margin-left: 10px;
  }
`;

export const NoContent = styled.div`
  margin-bottom: 22px;

  ::before {
    content: '-';
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 22px;
`;

export const Separator = styled.hr`
  width: ${window.innerWidth - 350}px;
  position: absolute;
  z-index: 99;
  margin-top: 66px;
  border-style: dashed;
  color: #f6f6f6;
  border-top: 2px;
`;
