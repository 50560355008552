import { Spacing } from 'assets/styles';
import clsx from 'clsx';
import { AiOutlineClose } from 'react-icons/ai';
import { StyledTag } from './Tag.styles';
import { StyledSmallTag } from './Tag.styles';


interface TagProps extends Spacing {
  text: string;
  color?: 'blue' | 'yellow';
  onDelete?: () => void;
  size?: 'sm' | 'lg';
  smallSize?:boolean;
}

const Tag = ({
  text,
  color = 'blue',
  onDelete,
  smallSize= false,
  size = 'sm',
  ...spacing
}: TagProps) => {
  return (
<>
{smallSize ?
  <StyledSmallTag
      className={clsx(
        { blue: color === 'blue' },
        { yellow: color === 'yellow' },
        { 'small-size': size === 'sm' },
        { 'large-size': size === 'lg' }
      )}
      {...spacing}
    >
      <span className="tag-text" title={text}>
        {text}
      </span>
      {onDelete && (
        <AiOutlineClose
          data-testid="delete-button"
          className="tag-icon"
          size={12}
          onClick={onDelete}
        />
      )}
    </StyledSmallTag> :
    <StyledTag
    className={clsx(
      { blue: color === 'blue' },
      { yellow: color === 'yellow' },
      { 'small-size': size === 'sm' },
      { 'large-size': size === 'lg' }
    )}
    {...spacing}
    fontSize='14px'
  >
    <span className="tag-text" title={text}>
      {text}
    </span>
    {onDelete && (
      <AiOutlineClose
        data-testid="delete-button"
        className="tag-icon"
        size={12}
        onClick={onDelete}
      />
    )}
  </StyledTag>
}
</>
  );
};

export default Tag;
