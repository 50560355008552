import { spacing, Spacing } from 'assets/styles';
import styled from 'styled-components';

export const StyledMediaBadge = styled.div<Spacing>`
  min-width: 80px;
  padding: 4px;
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  text-align: center;
  ${spacing}

  &.gray {
    background-color: #eeeeee;
    border-color: #999999;
    color: #999999;
  }

  &.red {
    background-color: ${({ theme }) => theme.colors.pink[700]};
    border-color: ${({ theme }) => theme.colors.pink[500]};
    color: ${({ theme }) => theme.colors.pink[500]};
  }

  &.yellow {
    background-color: #fff2d5;
    border-color: ${({ theme }) => theme.colors.orange[500]};
    color: ${({ theme }) => theme.colors.orange[500]};
  }

  &.green {
    background-color: ${({ theme }) => theme.colors.green[600]};
    border-color: ${({ theme }) => theme.colors.green[500]};
    color: ${({ theme }) => theme.colors.green[500]};
  }
`;
