import { CampaignTypes } from 'types';
import styled from 'styled-components';
import clsx from 'clsx';

interface DescriptionProps {
  selectedType: CampaignTypes | null;
  description: string;
  onDescription: (description: string) => void;
  hasError: boolean;
  maxLength: number;
}

const Description = ({
  selectedType,
  description,
  onDescription,
  hasError,
  maxLength,
}: DescriptionProps) => (
  <>
    <StyledTextarea
      className={clsx('textarea', {
        'has-error': hasError,
      })}
      rows={4}
      placeholder="Type description here."
      maxLength={maxLength}
      value={description}
      onChange={e => onDescription(e.target.value)}
      disabled={selectedType === null}
    />
    {hasError && (
      <StyledErrorMessage>{`The maximum length for this field is ${maxLength} characters.`}</StyledErrorMessage>
    )}
  </>
);

export default Description;

const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  height: 98px;
  padding: 16px;
  resize: none;
  outline: none;
  flex-shrink: 0;

  ::placeholder {
    color: #dfdfdf;
  }

  &:not(.has-error) {
    border-color: #dfdfdf;
  }

  &.has-error {
    border-color: ${({ theme }) => theme.colors.pink[500]};
  }
`;

const StyledErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.pink[500]};
  margin-top: 8px;
`;
