import { memoize } from 'lodash';
import { FacebookCampaignStatus } from 'types';

const bgColors = {
  [FacebookCampaignStatus.new]: '#6736ca',
  [FacebookCampaignStatus.queued]: '#f6f6f6',
  [FacebookCampaignStatus.processing]: '#f6f6f6',
  [FacebookCampaignStatus.review]: '#e2faff',
  [FacebookCampaignStatus.pending]: '#fff2d5',
  [FacebookCampaignStatus.active]: '#e7dbfe',
  [FacebookCampaignStatus.completed]: '#c4ffd2',
  [FacebookCampaignStatus.failed]: '#facce2',
  [FacebookCampaignStatus.reviewFailed]: '#facce2',
  [FacebookCampaignStatus.canceled]: '#facce2',
  [FacebookCampaignStatus.terminated]: '#facce2',
  [FacebookCampaignStatus.scheduled]: '#abcdef',
};

const colors = {
  [FacebookCampaignStatus.new]: '#ffffff',
  [FacebookCampaignStatus.queued]: '#afafaf',
  [FacebookCampaignStatus.processing]: '#afafaf',
  [FacebookCampaignStatus.review]: '#b4cacd',
  [FacebookCampaignStatus.pending]: '#ffc043',
  [FacebookCampaignStatus.active]: '#8242fc',
  [FacebookCampaignStatus.completed]: '#38e360',
  [FacebookCampaignStatus.failed]: '#e6006c',
  [FacebookCampaignStatus.reviewFailed]: '#e6006c',
  [FacebookCampaignStatus.canceled]: '#e6006c',
  [FacebookCampaignStatus.terminated]: '#e6006c',
  [FacebookCampaignStatus.scheduled]: '#ffffff',
};

const texts = {
  [FacebookCampaignStatus.new]: 'New',
  [FacebookCampaignStatus.queued]: 'Queued',
  [FacebookCampaignStatus.processing]: 'Processing',
  [FacebookCampaignStatus.review]: 'Video Review',
  [FacebookCampaignStatus.pending]: 'Pending',
  [FacebookCampaignStatus.active]: 'Testing',
  [FacebookCampaignStatus.completed]: 'Completed',
  [FacebookCampaignStatus.failed]: 'Failed',
  [FacebookCampaignStatus.reviewFailed]: 'Review Failed',
  [FacebookCampaignStatus.canceled]: 'Canceled',
  [FacebookCampaignStatus.terminated]: 'Terminated',
  [FacebookCampaignStatus.scheduled]: 'Scheduled',
};

const getColorsByCampaignStatus = (campaignStatus: FacebookCampaignStatus) => ({
  backgroundColor: bgColors[campaignStatus],
  color: colors[campaignStatus],
});

const getTextByCampaignStatus = (campaignStatus: FacebookCampaignStatus) =>
  texts[campaignStatus];

const getStylesBySize = memoize((size: 'sm' | 'md' | 'lg') => {
  switch (size) {
    case 'sm':
      return {
        width: '70px',
        height: '18px',
        fontSize: '9px',
      };
    case 'md':
      return {
        width: '120px',
        height: '25px',
        fontSize: '12px',
      };
    case 'lg':
      return {
        width: '120px',
        height: '30px',
        fontSize: '16px',
      };
    default:
      return {
        width: '70px',
        height: '18px',
        fontSize: '9px',
      };
  }
});

export const getBadgeProperties = (
  campaignStatus: FacebookCampaignStatus,
  size: 'sm' | 'md' | 'lg'
) => {
  return {
    styles: {
      ...getColorsByCampaignStatus(campaignStatus),
      ...getStylesBySize(size),
    },
    text: getTextByCampaignStatus(campaignStatus),
  };
};
