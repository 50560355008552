import { ButtonGroup, Container } from 'assets/styles';
import { Checkbox } from 'components';
import { Button } from 'components/atoms';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DateLabel,
  HomeDateWrapper,
  HomeFilterWrapper,
  HomeHeader,
  SelectOption,
} from './Home.styles';
import Select, { CSSObjectWithLabel, Theme } from 'react-select';
import CalenderIcon from 'assets/images/Calendar.svg';
import { AccessFeatures, OverviewGamesDateRange } from 'types';
import { useFetchUser } from 'api/modules/useUser';
import { hasPermission } from 'utils/user';
import { useTheme } from 'styled-components';
import { useDocumentTitle } from 'hooks';
import { CampaignOverview, PublishingOverview } from 'features';

const options = [
  {
    value: OverviewGamesDateRange.last2Weeks,
    label: <SelectOption>Last 2 weeks</SelectOption>,
  },
  {
    value: OverviewGamesDateRange.lastMonth,
    label: <SelectOption>Last 1 month</SelectOption>,
  },
  {
    value: OverviewGamesDateRange.last3Months,
    label: <SelectOption>Last 3 months</SelectOption>,
  },
  {
    value: OverviewGamesDateRange.last6Months,
    label: <SelectOption>Last 6 months</SelectOption>,
  },
];

function Home() {
  const theme = useTheme();
  useDocumentTitle('Overview');
  const { data: user } = useFetchUser();
  const [selectedTab, setSelectedTab] = useState<'all' | 'publishing'>('all');
  const [dateRange, setDateRange] = useState<
    OverviewGamesDateRange | undefined
  >();
  const [showNegativeCampaigns, setShowNegativeCampaigns] = useState(false);
  const handleTabChange = () =>
    setSelectedTab(selectedTab === 'all' ? 'publishing' : 'all');
  const handleDateRangeChange = (option: any) => {
    setDateRange(option.value);
    localStorage.setItem('dateRange', option.value);
  };

  const selectTheme = useCallback(
    (theme: Theme) => ({
      ...theme,
      borderRadius: 0,
      border: 'none',
      borderColor: 'white',
      outline: 'none',
      colors: {
        ...theme.colors,
        primary: 'white',
        primary50: 'white',
      },
    }),
    []
  );
  const selectStyles = useMemo(
    () => ({
      dropdownIndicator: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        color: theme.colors.pink[500],
      }),
      option: (baseStyles: CSSObjectWithLabel, state: any) => ({
        ...baseStyles,
        backgroundColor: state.isSelected ? 'white' : 'white',
        color: 'white',
      }),
      control: (baseStyles: CSSObjectWithLabel, state: any) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'white' : 'white',
      }),
      menu: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        zIndex: 'var(--z-index-react-select)',
      }),
    }),
    [theme.colors.pink]
  );

  const selectValue = (() =>
    options.find(option => option.value === dateRange))();

  const hasPublishingOverviewAccess = hasPermission(
    user,
    AccessFeatures.PUBLISHING_OVERVIEW
  );

  useEffect(() => {
    const range = localStorage.getItem('dateRange');

    if (range) {
      setDateRange(range as OverviewGamesDateRange);
    } else {
      setDateRange(OverviewGamesDateRange.last2Weeks);
    }
  }, []);

  return (
    <Container px={3} data-testid="home-container">
      {hasPublishingOverviewAccess && (
        <HomeHeader>
          <ButtonGroup>
            <Button primary={selectedTab === 'all'} onClick={handleTabChange}>
              ALL TESTS
            </Button>
            <Button
              primary={selectedTab === 'publishing'}
              onClick={handleTabChange}
            >
              PUBLISHING
            </Button>
          </ButtonGroup>
          <div className="right-container">
            {selectedTab === 'all' && (
              <HomeFilterWrapper>
                <span>show terminated</span>
                <span
                  onClick={() =>
                    setShowNegativeCampaigns(!showNegativeCampaigns)
                  }
                >
                  <Checkbox
                    isChecked={showNegativeCampaigns}
                    isDisabled={false}
                    accentColor="#EEEEEE"
                    size="sm"
                  />
                </span>
              </HomeFilterWrapper>
            )}

            <HomeDateWrapper>
              <DateLabel>
                <img src={CalenderIcon} alt="calender icon" />
                <span>Date range: </span>
              </DateLabel>
              <Select
                value={selectValue}
                options={options}
                onChange={e => handleDateRangeChange(e)}
                theme={selectTheme}
                styles={selectStyles}
                isSearchable={false}
              />
            </HomeDateWrapper>
          </div>
        </HomeHeader>
      )}
      {selectedTab === 'all' ? (
        <CampaignOverview
          dateRange={dateRange}
          showNegativeCampaigns={showNegativeCampaigns}
        />
      ) : (
        <PublishingOverview dateRange={dateRange} />
      )}
    </Container>
  );
}

export default Home;
