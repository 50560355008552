import styled from 'styled-components';

const StyledSearchBar = styled.div`
  width: 500px;
  height: 42px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: var(--z-index-modal);

  .input-wrapper {
    width: 100%;
    height: 100%;
    padding: 0 4px;
    border-radius: 21px;
    background-color: ${({ theme }) => theme.colors.grey.white};
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    position: relative;
    z-index: 20;

    .icon {
      padding: 4px;
      cursor: pointer;
    }

    .input,
    .number-of-results {
      margin: 0 4px;
    }

    .input {
      flex-grow: 1;
      height: 24px;
      font-size: 18px;
      font-weight: 400;
      border: 0;
      padding: 0;
      outline: none;
      background-color: transparent;
      caret-color: ${({ theme }) => theme.colors.pink[500]};

      &::placeholder {
        color: #cdcdcd;
      }
    }

    .number-of-results {
      color: ${({ theme }) => theme.colors.pink[500]};
      font-size: 16px;
      font-weight: 500;
    }
  }

  .results-wrapper {
    width: 100%;
    border-radius: 21px;
    padding-top: 42px;
    background-color: ${({ theme }) => theme.colors.grey.white};
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    overflow-y: hidden;

    .results {
      display: flex;
      flex-direction: column;
      height: 200px;
      position: relative;
      overflow-y: auto;

      .result {
        display: flex;
        flex-direction: column;
        padding: 8px;
        font-size: 12px;
        position: relative;

        :not(:last-of-type) {
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 16px;
            width: calc(100% - 32px);
            height: 1px;
            background-color: #ebebeb;
          }
        }

        .title {
          color: ${({ theme }) => theme.colors.pink[500]};
          font-weight: 500;
          text-transform: uppercase;
          padding: 8px;
        }

        .items {
          list-style-type: none;
          margin: 0;
          padding: 0;
          font-weight: 400;

          .item {
            display: flex;

            b {
              font-weight: 700;
            }

            .link {
              display: flex;
              align-items: center;
              flex-grow: 1;
              padding: 8px;
              border-radius: 16px;
              outline: none;

              &:hover,
              &:focus {
                background-color: ${({ theme }) => theme.colors.grey.offWhite};
              }

              img {
                margin-left: 5px;
              }
            }
          }
        }
      }

      .loading-results,
      .no-results {
        display: flex;
        height: 100%;
      }

      .no-results {
        flex-direction: column;
        align-items: center;

        .no-results-img {
          width: 150px;
          margin-bottom: 16px;
        }

        .no-results-text {
          font-size: 18px;
          font-weight: 700;
          color: #e6e6e6;
          text-transform: uppercase;
        }
      }
    }
  }
`;

export default StyledSearchBar;
