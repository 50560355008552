import { useEffect, useState, useMemo, CSSProperties } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  RowSelectionState,
  RowData,
} from '@tanstack/react-table';
import StyledDataGrid from './DataGrid.styles';
import clsx from 'clsx';

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    isCustomHeader?: boolean;
    isCustomCell?: boolean;
  }
}

interface DataGridProps<T> {
  rows: T[];
  columns: ColumnDef<T>[];
  selectedRows?: RowSelectionState;
  onRowSelection?: (rows: T[]) => void;
  disableSelectAll?: boolean;
}

const DataGrid = <T extends object>({
  rows,
  columns,
  selectedRows,
  onRowSelection,
  disableSelectAll,
}: DataGridProps<T>) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    selectedRows ?? {}
  );

  const defaultCellWidth = 150;
  const selectClass = 'select';

  const columnsWithSelect = useMemo(
    () => [
      {
        id: selectClass,
        header: props =>
          disableSelectAll ? null : (
            <input
              type="checkbox"
              className="checkbox"
              checked={props.table.getIsAllRowsSelected()}
              onChange={props.table.getToggleAllRowsSelectedHandler()}
            />
          ),
        cell: props => (
          <input
            type="checkbox"
            className="checkbox"
            checked={props.row.getIsSelected()}
            onChange={props.row.getToggleSelectedHandler()}
          />
        ),
      },
      ...columns,
    ],
    [columns, disableSelectAll]
  );

  const table = useReactTable({
    data: rows,
    columns: selectedRows || onRowSelection ? columnsWithSelect : columns,
    state: {
      rowSelection,
    },
    defaultColumn: {
      size: defaultCellWidth,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  });

  const generateCellWidth = (
    cellWidth: number | undefined
  ): CSSProperties | undefined =>
    cellWidth !== defaultCellWidth
      ? { minWidth: cellWidth, maxWidth: cellWidth }
      : undefined;

  const updateRowSelection = () => {
    if (onRowSelection) {
      const rowOriginal = table
        .getSelectedRowModel()
        .rows.map(row => row.original);

      onRowSelection(rowOriginal);
    }
  };

  useEffect(() => {
    updateRowSelection();
  }, [table.getState().rowSelection]);

  return (
    <StyledDataGrid className="table-container" cellWidth={defaultCellWidth}>
      <div className="table">
        <div className="table-head">
          {table.getHeaderGroups().map(headerGroup => (
            <div className="table-row" key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <div
                  className={clsx('table-cell', {
                    [selectClass]: header.id === selectClass,
                  })}
                  style={generateCellWidth(header.column.columnDef.size)}
                  key={header.id}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={
                        header.column.columnDef.meta?.isCustomHeader
                          ? 'custom-cell-content'
                          : 'cell-content'
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="table-body">
          {table.getRowModel().rows.map(row => (
            <div className="table-row" key={row.id}>
              {row.getVisibleCells().map(cell => (
                <div
                  className={clsx('table-cell', {
                    [selectClass]: cell.column.id === selectClass,
                  })}
                  style={generateCellWidth(cell.column.columnDef.size)}
                  key={cell.id}
                >
                  <div
                    className={
                      cell.column.columnDef.meta?.isCustomCell
                        ? 'custom-cell-content'
                        : 'cell-content'
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </StyledDataGrid>
  );
};

export default DataGrid;
