import { useGetCountries, useGetMembers } from 'api';
import { Popup, PopupPosition } from 'components/atoms';
import { RefObject, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Select, { SingleValue } from 'react-select';
import { useSelectCustomStyles } from 'hooks';
import { Option } from 'types';

interface FilterPopupProps {
  visible: boolean;
  anchor: RefObject<HTMLDivElement>;
  onClose: () => void;
  onSubmit: (managerName: string | null, country: string | null) => void;
  currentManager: string | null;
  currentCountry: string | null;
}

const FilterPopup = ({
  visible,
  anchor,
  onClose,
  onSubmit,
  currentManager,
  currentCountry,
}: FilterPopupProps) => {
  const selectStyles = useSelectCustomStyles();
  const theme = useTheme();
  const { data: members } = useGetMembers(false);
  const { data: countries } = useGetCountries();
  const [selectedManager, setSelectedManager] = useState<string | null>(
    currentManager
  );
  const [selectedCountry, setSelectedCountry] = useState<string | null>(
    currentCountry
  );

  useEffect(() => {
    setSelectedManager(currentManager);
    setSelectedCountry(currentCountry);
  }, [currentCountry, currentManager]);

  const onManagerChange = (manager: SingleValue<Option>) => {
    setSelectedManager(manager?.label ? manager.label : null);
  };

  const onCountryChange = (country: SingleValue<Option>) => {
    setSelectedCountry(country?.label ? country.label : null);
  };

  const onFilter = () => {
    onSubmit(selectedManager, selectedCountry);
    onClose();
  };

  const onReset = () => {
    setSelectedManager(null);
    setSelectedCountry(null);
    onSubmit(null, null);
    onClose();
  };

  return (
    <Popup
      visible={visible}
      anchor={anchor}
      onClose={onClose}
      position={PopupPosition.right}
      containerStyle={{
        padding: '12px 0',
      }}
    >
      <PopupTitle>Filter</PopupTitle>
      <PopupContent>
        <Row>
          <Label>Manager</Label>
          <Select
            isClearable={true}
            styles={selectStyles}
            options={members as Option[]}
            value={members.find(
              (member: Option) => member.label === selectedManager
            )}
            placeholder="Choose a manager..."
            onChange={(newValue: any) => onManagerChange(newValue)}
          />
        </Row>
        <Row>
          <Label>Country</Label>
          <Select
            isClearable={true}
            styles={selectStyles}
            options={countries}
            placeholder="Choose a country..."
            value={countries.find(
              (country: { label: string; value: number }) =>
                country.label === selectedCountry
            )}
            onChange={(newValue: any) => onCountryChange(newValue)}
          />
        </Row>
        <BottomRow>
          <Button
            style={{
              marginRight: '8px',
              backgroundColor: theme.colors.pink[500],
            }}
            onClick={onReset}
          >
            Reset
          </Button>
          <Button onClick={onFilter}>Filter</Button>
        </BottomRow>
      </PopupContent>
    </Popup>
  );
};

const PopupTitle = styled.div`
  padding: 12px;
  color: lightgray;
  font-weight: bold;
  text-align: center;
`;

const PopupContent = styled.div`
  padding: 12px 48px;
`;

const Row = styled.div`
  width: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    pointer-events: none;
  }
`;

const BottomRow = styled(Row)`
  justify-content: center;
`;

const Label = styled.label`
  font-size: 12px;
  color: gray;
  width: 80px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.blue[500]};
  border-radius: 4px;
  border: 0;
  padding: 12px 24px;
  color: ${({ theme }) => theme.colors.grey.white};
`;

export default FilterPopup;
