import { Navigate } from 'react-router-dom';
import { routes } from 'configs/routes';

interface DefaultRedirectProps {
  isAuthenticated?: boolean;
}

function DefaultRedirect(props: DefaultRedirectProps) {
  return props.isAuthenticated ? (
    <Navigate to={routes.home} />
  ) : (
    <Navigate to={routes.login} />
  );
}

export default DefaultRedirect;
