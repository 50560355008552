import {
  useEffect,
  useRef,
  useState,
  FC,
  DragEvent,
  CSSProperties,
} from 'react';
import styled from 'styled-components';

interface DragAndDropProps {
  dropHandler: (files: FileList) => void;
  containerStyles?: CSSProperties;
  children?: React.ReactNode;
}

export const DragAndDrop: FC<DragAndDropProps> = ({
  children,
  dropHandler,
  containerStyles,
}) => {
  const [dragging, setDragging] = useState<boolean>(false);
  const [draggedIn, setDraggedIn] = useState<boolean>(false);
  const dropRef = useRef(null);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggedIn(draggedIn => !draggedIn);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };
  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggedIn(draggedIn => !draggedIn);
    if (draggedIn) return;
    setDragging(false);
  };
  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      dropHandler(e.dataTransfer.files);
      e.dataTransfer.clearData();
      setDraggedIn(false);
    }
  };

  useEffect(() => {
    if (!!dropRef.current) {
      const div: any = dropRef.current;

      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', handleDrop);
    }

    return () => {
      if (!!dropRef.current) {
        // eslint-disable-next-line
        const div: any = dropRef.current;
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDrop);
      }
    };
  });

  return (
    <DragAndDropContainer ref={dropRef} style={containerStyles}>
      {dragging && (
        <DragAndDropOverlay>
          <DragAndDropText>
            <div style={{ fontSize: 16 }}>Drop files here to upload</div>
          </DragAndDropText>
        </DragAndDropOverlay>
      )}
      {children}
    </DragAndDropContainer>
  );
};

const DragAndDropContainer = styled.div`
  position: relative;
`;

const DragAndDropOverlay = styled.div`
  border: 4px dashed grey;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-overlay);
`;

const DragAndDropText = styled.div`
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  bottom: 0;
  color: 'grey';
  font-size: 36px;
  text-align: center;
`;
