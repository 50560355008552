import { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import DeleteOpenImg from 'assets/images/Bin_Open.svg';
import DeleteImg from 'assets/images/Delete_black.svg';
import { deleteGray } from 'assets/images';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { Icon } from 'assets/styles';

interface ClearButtonProps {
  isDisabled: boolean;
  onClear: () => void;
}

const ClearButton = ({ isDisabled, onClear }: ClearButtonProps) => {
  const [referenceEl, setReferenceEl] = useState<HTMLElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);
  const [arrowEl, setArrowEl] = useState<HTMLElement | null>(null);
  const [show, setShow] = useState(false);
  const { styles, attributes } = usePopper(referenceEl, popperEl, {
    placement: 'bottom',
    modifiers: [{ name: 'arrow', options: { element: arrowEl } }],
  });

  const handleClick = () => setShow(!show);
  const handleClear = () => {
    onClear();
    handleClick();
  };

  useEffect(() => {
    if (show) {
      popperEl?.setAttribute('data-show', 'true');
    } else {
      popperEl?.removeAttribute('data-show');
    }
  }, [show, popperEl]);

  return (
    <>
      <Button
        data-testid="clear-button"
        disabled={isDisabled}
        ref={setReferenceEl}
        onClick={handleClick}
      >
        {isDisabled ? (
          <Icon src={deleteGray} alt="delete-icon-disable" />
        ) : (
          <Icon src={DeleteImg} alt="delete-icon" />
        )}
        <span>Clear</span>
      </Button>
      <PopperContainer
        data-testid="popper-container"
        ref={setPopperEl}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className="text">
          Are you sure you would like to clear this variable?
        </div>
        <div className="button-container">
          <IconButton
            data-testid="confirm-clear-button"
            onClick={handleClear}
            bgColor={'white'}
            borderRadius="0 0 0 4px"
          >
            <Icon src={DeleteOpenImg} alt="delete-icon" />
            <span>Clear</span>
          </IconButton>
          <IconButton
            bgColor="rgba(235, 51, 137, 0.15)"
            onClick={handleClick}
            borderRadius="0 0 4px 0"
          >
            <FaTimes size={14} />
            <span>Cancel</span>
          </IconButton>
        </div>
        <div ref={setArrowEl} style={styles.arrow} id="arrow" />
      </PopperContainer>
    </>
  );
};

export default ClearButton;

interface IconButtonProps {
  bgColor: string;
  borderRadius: string;
}

const IconButton = styled.button<IconButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 40px;
  width: 50%;
  border-radius: ${props => props.borderRadius};
  color: ${props => props.theme.colors.pink[500]};
  background-color: ${props => props.bgColor};

  span {
    margin-left: 5px;
  }
`;

export const Button = styled.button`
  border: 1px solid #979797;
  margin-right: 10px;
  width: 80px;
  height: 32px;
  background-color: ${props => props.theme.colors.grey.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 4px;

  span {
    margin-left: 5px;
  }
`;

const PopperContainer = styled.div`
  background-color: ${props => props.theme.colors.grey.white};
  width: 250px;
  height: 120px;
  box-shadow: 0 0 6px 2px ${props => props.theme.colors.grey[1200]};
  border-radius: 4px;
  visibility: hidden;
  display: flex;
  flex-direction: column;

  .text {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0px 16px;
  }

  .button-container {
    display: flex;
  }

  &[data-show='true'] {
    visibility: visible;
  }

  &[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
  }

  &[data-popper-placement^='left'] > #arrow {
    right: -4px;
  }

  &[data-popper-placement^='right'] > #arrow {
    left: -4px;
  }

  #arrow,
  #arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: inherit;
  }

  #arrow {
    visibility: hidden;
  }

  &[data-show='true'] {
    #arrow::before {
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }
  }
`;
