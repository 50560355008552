import { Modal } from 'components';
import { Button } from 'components/atoms';
import styled, { useTheme } from 'styled-components';
import { ModalSize } from 'types';

interface ConfirmMonetizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  stepName: string;
  message?: string;
}

const ConfirmMonetizationModal: React.FC<ConfirmMonetizationModalProps> = ({
  isOpen,
  onClose,
  handleConfirm,
  stepName,
  message,
}) => {
  const theme = useTheme();
  return (
    <Modal
      size={ModalSize.sm}
      title="Confirmation"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Container>
        <div>
          <p className="title">
            Do you want to start setting up monetization for <b>{stepName}</b>?
          </p>
        </div>
        {message && (
          <div className="info">
            <b>Info:</b> {message}
          </div>
        )}
        <div className="footer">
          <Button bgColor={theme.colors.pink[500]} onClick={onClose}>
            No
          </Button>
          <Button bgColor={theme.colors.green[500]} onClick={handleConfirm}>
            Yes
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default ConfirmMonetizationModal;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .title {
    font-weight: 400;
    font-size: 18px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .info {
    text-align: center;
  }
`;
