import moment from 'moment';
import { isEmpty, round } from 'lodash';
import {
  ChartData,
  CampaignDetails,
  AvgAdImpPerUserChartData,
  BusinessChartType,
} from 'types';
import { chartTitleGenerator } from './utils/GlobalUtilsTsx';
import {
  chartDataGenerator,
  chartLabelsTodayIndex,
  getChartTitleAndTooltip,
} from './utils/GlobalUtilsTs';
import {
  ChartContainer,
  ChartInnerContainer,
  ChartNoDataContainer,
  ChartInfoContainer,
} from './Charts.styles';
import { Line } from 'react-chartjs-2';

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index' as 'index',
  },
  plugins: {
    legend: {
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        padding: 20,
      },
    },
  },
  scales: {
    xAxes: {
      offset: true,
      grid: {
        drawBorder: false,
        lineWidth: 0,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
      },
    },
  },
};

const lineColors = {
  adInterstitialSuccess: '#57B4F9',
  adRewardedSuccess: '#ffc043',
  interstitialRewardedTotal: '#8242fc',
};

const calculateAvgValue = (
  values: number[],
  labels: string[]
): number | undefined => {
  if (values.length === 0 || labels.length === 0) {
    return undefined;
  }

  const valuesWithoutToday = values.filter(
    (_, index) => index !== chartLabelsTodayIndex(labels)
  );

  if (valuesWithoutToday.length === 0) {
    return undefined;
  }

  const sum = valuesWithoutToday.reduce((a, b) => a + b, 0);
  const totalAvg = round(sum / valuesWithoutToday.length, 1);

  return totalAvg;
};

const getLabels = (adImpressionsData: AvgAdImpPerUserChartData): string[] => {
  let data: ChartData | undefined =
    adImpressionsData.adInterstitialSuccess ??
    adImpressionsData.adRewardedSuccess ??
    adImpressionsData.interstitialRewardedTotal;

  if (!data) {
    return [];
  }

  const labels = Object.keys(data).map(value => moment(value).format('DD.MM'));
  return labels;
};

interface ChartProps {
  campaign: CampaignDetails;
}

const AdImpressionsAvgPerUserChart = ({ campaign }: ChartProps) => {
  const adImpressionsData = campaign.kpis?.adImpressionsAveragePerUser ?? {};

  const hasAdImpressionsData = !isEmpty(adImpressionsData);
  const labels = getLabels(adImpressionsData);
  const adInterstitialSuccessValues = adImpressionsData.adInterstitialSuccess
    ? chartDataGenerator(adImpressionsData.adInterstitialSuccess)
    : [];

  const adRewardedSuccessValues = adImpressionsData.adRewardedSuccess
    ? chartDataGenerator(adImpressionsData.adRewardedSuccess)
    : [];

  const interstitialRewardedTotalValues =
    adImpressionsData.interstitialRewardedTotal
      ? chartDataGenerator(adImpressionsData.interstitialRewardedTotal)
      : [];

  const today = moment(new Date()).format('DD.MM');
  const todayIndex =
    labels.indexOf(today) === -1 ? 1000 : labels.indexOf(today);

  const dottedGenerator = (ctx: any) =>
    ctx.p1DataIndex >= todayIndex ? [6, 6] : [6, 0];

  const getChartData = ({
    label,
    data,
    color,
  }: {
    label: string;
    data: number[];
    color: string;
  }) => {
    return {
      label,
      data,
      borderColor: color,
      pointBorderColor: color,
      pointBackgroundColor: color,
      segment: {
        borderDash: (ctx: any) => dottedGenerator(ctx),
      },
      spanGaps: true,
    };
  };

  const chartDatasets = {
    clip: false,
    labels: labels,
    datasets: [
      ...(adInterstitialSuccessValues.length > 0
        ? [
            getChartData({
              label: 'Interstitial',
              data: adInterstitialSuccessValues,
              color: lineColors.adInterstitialSuccess,
            }),
          ]
        : []),
      ...(adRewardedSuccessValues.length > 0
        ? [
            getChartData({
              label: 'Rewarded',
              data: adRewardedSuccessValues,
              color: lineColors.adRewardedSuccess,
            }),
          ]
        : []),
      ...(interstitialRewardedTotalValues.length > 0
        ? [
            getChartData({
              label: 'Total',
              data: interstitialRewardedTotalValues,
              color: lineColors.interstitialRewardedTotal,
            }),
          ]
        : []),
    ],
  };

  const interstitialRewardedTotalAvg = calculateAvgValue(
    interstitialRewardedTotalValues,
    labels
  );

  const { title, tooltip } = getChartTitleAndTooltip(
    BusinessChartType.AD_IMPRESSIONS_PER_AVG_USER
  );

  return (
    <ChartContainer>
      <ChartInfoContainer>
        {chartTitleGenerator(title, tooltip)}
        {hasAdImpressionsData && interstitialRewardedTotalAvg && (
          <div>{interstitialRewardedTotalAvg}</div>
        )}
      </ChartInfoContainer>
      <ChartInnerContainer>
        {!hasAdImpressionsData && (
          <ChartNoDataContainer>No Data</ChartNoDataContainer>
        )}
        <Line data={chartDatasets} options={chartOptions} height={400} />
      </ChartInnerContainer>
    </ChartContainer>
  );
};

export default AdImpressionsAvgPerUserChart;
