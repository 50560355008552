import TestRunning from 'features/Kpi/TestRunning';
import { NoDataContainer } from 'assets/styles';
import { CampaignDetails, FacebookCampaignStatus } from 'types';
import TestError from 'pages/Test/TestError';
import errorIcon from 'assets/images/error_icon.svg';
import { ErrorBoundary } from 'components';

interface KpiProps {
  campaign: CampaignDetails | undefined;
}

const Kpi = ({ campaign }: KpiProps) => {
  const isQueued = campaign?.status === FacebookCampaignStatus.queued;
  const isProcessing = campaign?.status === FacebookCampaignStatus.processing;

  return (
    <>
      {campaign && campaign.errorMessage !== '' && (
        <TestError message={campaign?.errorMessage} imgSrc={errorIcon} />
      )}
      {isQueued ? (
        <NoDataContainer>
          Your test is queued for processing,
          <br /> status will be updated automatically.
        </NoDataContainer>
      ) : isProcessing ? (
        <NoDataContainer>
          We are processing your test. This can take a while.
        </NoDataContainer>
      ) : (
        campaign && <TestRunning campaign={campaign} />
      )}
    </>
  );
};

const KpiErrorBoundary = (props: KpiProps) => (
  <ErrorBoundary>
    <Kpi {...props} />
  </ErrorBoundary>
);

export default KpiErrorBoundary;
