import { spacing, Spacing } from 'assets/styles';
import styled from 'styled-components';

export const StyledLightButton = styled.button<Spacing>`
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 1.4;
  font-weight: 500;
  border-radius: 6px;
  padding: 8px 16px;
  ${spacing}

  &:not(.full-width) {
    width: fit-content;
  }

  &.full-width {
    width: 100%;
  }

  &.primary {
    background-color: ${({ theme }) => theme.colors.blue[700]};
    color: ${({ theme }) => theme.colors.blue[500]};
  }
`;
