import styled from 'styled-components';

export const GamesOverviewWrapper = styled.div`
  padding: 40px;
`;

export const GamesOverviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GameCounter = styled.div`
  color: ${({ theme }) => theme.colors.grey[900]};
  display: block;
  float: left;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px 0;
`;

export const AddGameButton = styled.button`
  border: 2px solid ${({ theme }) => theme.colors.blue[500]};
  border-radius: 4px;
  opacity: 1;
  width: 136px;
  height: 38px;
  font-style: normal;
  font-variant: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.blue[500]};
  background: inherit;
  float: right;

  &:hover {
    background: ${({ theme }) => theme.colors.blue[500]} 0% 0% no-repeat
      padding-box;
    color: ${({ theme }) => theme.colors.grey.white};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey[1000]};
    border: 2px solid ${({ theme }) => theme.colors.grey[1000]};
    color: ${({ theme }) => theme.colors.grey.white};
    cursor: default;
  }
`;

export const PlatformIcon = styled.img`
  filter: invert(51%) sepia(27%) saturate(1718%) hue-rotate(171deg)
    brightness(104%) contrast(94%);
  height: 19px;
  width: auto;
  margin: 0 5px;
`;

export const ToggleButton = styled.a`
  color: ${({ theme }) => theme.colors.blue[500]};
  text-transform: uppercase;
  line-height: 60px;
  cursor: pointer;
`;

export const OverviewHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  column-gap: 18px;
`;
