import { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';

interface HeaderProfileProps {
  profilePicture?: string;
  name: string;
  onClick: MouseEventHandler;
}

function HeaderProfile(props: HeaderProfileProps) {
  if (props.name === undefined) {
    return <></>;
  }

  return (
    <Wrapper onClick={props.onClick}>
      <HeaderProfileImage {...props}>
        {props.profilePicture ? '' : props.name.charAt(0)}
      </HeaderProfileImage>
      <Arrow>
        <IoIosArrowDown />
      </Arrow>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 70px;
  height: 42px;
  border: 2px solid ${({ theme }) => theme.colors.pink[500]};
  border-radius: 30px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.pink[500]};
`;

const Arrow = styled.span`
  padding: 7px;
  display: flex;
  align-items: center;
`;

const HeaderProfileImage = styled.div`
  position: absolute;
  left: -2px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.pink[500]};

  ${(props: HeaderProfileProps) =>
    props.profilePicture
      ? css`
          background-color: transparent;
          background-image: ${(props: HeaderProfileProps) =>
            `url(${props.profilePicture}?referrerpolicy=no-referrer)`};
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        `
      : css`
          background-color: ${({ theme }) => theme.colors.blue[800]};
          color: ${({ theme }) => theme.colors.grey.white};
          font-family: 'Rubik', sans-serif;
          font-size: 32px;
          text-align: center;
          line-height: 52px;
        `};
`;

export default HeaderProfile;
