import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { CreateReportRequestBody } from 'types';

const createReport = async (reqBody: CreateReportRequestBody) => {
  return await axiosInstance.post('/campaigns/report', reqBody);
};

export const useCreateReport = () => {
  return useMutation((reqBody: CreateReportRequestBody) =>
    createReport(reqBody)
  );
};
