import styled from 'styled-components';

export const SidebarLinkText = styled.div`
  font-family: 'Rubik', sans-serif;
  color: ${({ theme }) => theme.colors.grey.black};
  font-size: 12px;
  padding-left: 12px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
