import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Steps = styled.div`
  height: 48px;
  background-color: #f9f9f9;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Step = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .step-number {
    font-size: 20px;
    border-radius: 50%;
    background-color: #009efe;
    color: ${({ theme }) => theme.colors.grey.white};
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .step-number-inactive {
    color: #dfdfdf;
    background-color: ${({ theme }) => theme.colors.grey.white};
    border: 1px solid #dfdfdf;
  }

  .step-title {
    font-size: 22px;
    margin-left: 10px;
    color: #009efe;
    flex-grow: 1;
  }

  .step-title-inactive {
    color: ${({ theme }) => theme.colors.grey.black};
    opacity: 0.3;
  }
`;

export const Content = styled.div`
  margin: 16px 0;
  height: 100%;
  overflow-y: auto;
`;
export const Controls = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
