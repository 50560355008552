import { axiosInstance } from 'api/axios';

interface FetchMembersResponse {
  status: boolean;
  members: any[];
}

export const fetchMembers = (allMembers: boolean) => {
  return axiosInstance
    .get<FetchMembersResponse>(
      `/organizations/members${allMembers ? '?all=1' : ''}`
    )
    .then(response => {
      if (response.data?.status !== true) {
        console.log('API returned unexpected data');
        return Promise.reject();
      }
      return Promise.resolve(response.data.members);
    })
    .catch(error => {
      console.log('Request failed', error.toString());
      return Promise.reject();
    });
};
