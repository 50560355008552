import { useQueryParams } from 'hooks';

export const useCampaignAndAppVersionParams = () => {
  const queryParams = useQueryParams();
  const campaignId1 = queryParams.get('campaignId1') ?? '';
  const campaignId2 = queryParams.get('campaignId2') ?? '';
  const campaignName1 = queryParams.get('campaignName1') ?? '';
  const campaignName2 = queryParams.get('campaignName2') ?? '';
  const appVersion1 = queryParams.get('appVersion1') ?? '';
  const appVersion2 = queryParams.get('appVersion2') ?? '';

  return {
    campaignId1,
    campaignId2,
    campaignName1,
    campaignName2,
    appVersion1,
    appVersion2,
  };
};
