import { useQueryClient } from '@tanstack/react-query';
import { useDeleteUnFavoriteStudio, usePostFavoriteStudio } from 'api';
import { AiFillStar } from 'react-icons/ai';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ServerQueryKey } from 'types';

interface FavIconProps {
  id: number;
  favorite: boolean;
}
const handleFavIconSuccess = (text: string) => {
  toast.success(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};
const FavIcon = ({ id, favorite }: FavIconProps) => {
  const queryClient = useQueryClient();
  const onSuccess = (text: string) => {
    queryClient.invalidateQueries([ServerQueryKey.NavStudios]);
    handleFavIconSuccess(text)

  };
  const favStudio = usePostFavoriteStudio(id,{
    onSuccess: () => onSuccess('The studio was successfully added to favorites'),
  });
  const unFavStudio = useDeleteUnFavoriteStudio(id, {
    onSuccess: () => onSuccess('The studio was successfully removed from favorites'),
  });
  if (favorite) {
    return (
      <AiFillStar
        color="#ffd700"
        onClick={e => {
          e.stopPropagation();
          unFavStudio.mutate();
        }}
      />
    );
  } else {
    return (
      <NonFavIcon
        onClick={e => {
          e.stopPropagation();
          console.log('Favorite call and refetch navigation');
          favStudio.mutate();
        }}
      />
    );
  }
};

const NonFavIcon = styled(AiFillStar)`
  color: ${({ theme }) => theme.colors.grey.white};
  &:hover {
    color: #ffd700;
  }
`;

export default FavIcon;
