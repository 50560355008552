import clsx from 'clsx';
import styled from 'styled-components';
import { CreativePackMedia } from 'types';
import Caption from './Caption';
import NoMedia from './NoMedia';

interface MediaProps {
  type: 'portrait' | 'landscape' | 'square';
  media?: CreativePackMedia;
  onClick?: (mediaId: number) => void;
}

const Media = ({ type, media, onClick }: MediaProps) => {
  const isImage = type === 'square';

  const handleClick = () => (onClick && media ? onClick(media.id) : undefined);

  return (
    <StyledMedia
      className={clsx(type, {
        clickable: media,
      })}
      onClick={handleClick}
    >
      {media ? (
        <>
          <img className="thumbnail" src={media.thumbnail} alt={type} />
          <Caption icon={isImage ? 'image' : 'video'} name={media.name} />
        </>
      ) : (
        <NoMedia />
      )}
    </StyledMedia>
  );
};

export default Media;

const StyledMedia = styled.article`
  background-color: ${({ theme }) => theme.colors.grey.offWhite};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.portrait {
    grid-area: portrait;
  }

  &.landscape {
    grid-area: landscape;
  }

  &.square {
    grid-area: square;
  }

  &.clickable {
    cursor: pointer;
  }

  .thumbnail {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 10;
    position: absolute;
    object-fit: cover;
  }
`;
