import clsx from 'clsx';
import { Modal } from 'components';
import { Button } from 'components/atoms';
import { ChangeEvent, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ModalSize } from 'types';

const maxLength = 250;

interface CampaignDescriptionModalProps {
  onClose: () => void;
  onSubmit: (text: string) => void;
  modalTitle: string;
  descriptionText: string;
}

const CampaignDescriptionModal = ({
  onClose,
  onSubmit,
  modalTitle,
  descriptionText,
}: CampaignDescriptionModalProps) => {
  const theme = useTheme();
  const [text, setText] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > maxLength) {
      setHasError(true);
      return;
    }
    const value = event.target.value.split('\n');
    if (value.length < 6) setText(event.target.value);
  };

  useEffect(() => {
    setText(descriptionText);
  }, [descriptionText]);

  const disableSave =
    (descriptionText.length === 0 && text.length === 0) ||
    (descriptionText.length !== 0 && descriptionText === text);

  return (
    <Modal
      size={ModalSize.sm}
      isOpen={true}
      title={modalTitle}
      onClose={onClose}
    >
      <Container onClick={e => e.preventDefault()}>
        <span>Test Description</span>
        <TextArea
          className={clsx({
            'has-error': hasError,
          })}
          rows={5}
          value={text}
          // maxLength={maxLength}
          onChange={handleTextChange}
        />
        {hasError && (
          <StyledErrorMessage>{`The maximum length for this field is ${maxLength} characters.`}</StyledErrorMessage>
        )}
        <div className="button-wrapper">
          <StyledButton primary bgColor="#F066A7" onClick={onClose}>
            Cancel
          </StyledButton>
          <StyledButton
            primary
            bgColor={theme.colors.green[500]}
            disabled={disableSave}
            onClick={() => onSubmit(text)}
          >
            Save
          </StyledButton>
        </div>
      </Container>
    </Modal>
  );
};

export default CampaignDescriptionModal;

interface StyledButtonProps {
  bgColor: string;
}

const StyledButton = styled(Button)<StyledButtonProps>`
  background-color: ${props => props.bgColor};
  width: 140px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .title {
    font-size: 20px;
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const TextArea = styled.textarea`
  height: 120px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  resize: none;
  outline: none;
  ::placeholder {
    color: #dfdfdf;
  }

  &.has-error {
    border-color: ${({ theme }) => theme.colors.pink[500]};
  }

  &:not(.has-error) {
    border-color: #dfdfdf;
  }
`;

const StyledErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.pink[500]};
`;
