import { useState } from 'react';
import { DescriptionContent, DescriptionTitle } from './constants';

export enum DescriptionType {
  objective = 'objective',
  dailyBudget = 'dailyBudget',
  daysOfCampaign = 'daysOfCampaign',
  bidStrategy = 'bidStrategy',
  billingEvent = 'billingEvent',
  operatingSystem = 'operatingSystem',
  country = 'country',
  gender = 'gender',
  ageGroup = 'ageGroup',
  goal = 'goal',
  totalBudget = 'totalBudget',
  unityDailyBudget = 'unityDailyBudget',
  bid = 'bid',
  unityBidStrategy = 'unityBidStrategy',
  billingType = 'billingType',
  allowedDevices = 'allowedDevices',
  unityCountry = 'unityCountry',
}

export const useDescription = (type: DescriptionType) => {
  const [descriptionType, setDescriptionType] = useState<DescriptionType>(type);

  const handleDescriptionType = (type: DescriptionType) => {
    setDescriptionType(type);
  };

  const descriptionTitle = DescriptionTitle[descriptionType];
  const descriptionContent = DescriptionContent[descriptionType];

  return {
    descriptionType,
    descriptionTitle,
    descriptionContent,
    handleDescriptionType,
  };
};
