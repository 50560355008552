import styled from 'styled-components';
import imageIcon from 'assets/images/image-icon.svg';
import videoIcon from 'assets/images/video-icon.svg';

interface CaptionProps {
  icon: 'video' | 'image';
  name: string;
}

const Caption = ({ icon, name }: CaptionProps) => (
  <StyledCaption>
    <img
      className="icon"
      src={icon === 'image' ? imageIcon : videoIcon}
      alt={icon}
    />
    <span className="text" title={name}>
      {name}
    </span>
  </StyledCaption>
);

export default Caption;

const StyledCaption = styled.footer`
  width: 100%;
  padding: 8px;
  border-radius: 0 0 10px 10px;
  z-index: 20;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.grey.black};
  background: linear-gradient(to top, var(--color-black), transparent);

  .icon {
    flex-shrink: 0;
  }

  .text {
    font-size: 14px;
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.grey.white};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
