import { AccessFeatures, FullAccessRoles, User, UserRole } from 'types';

const getRolesWithFeatureAccess = (feature: AccessFeatures): UserRole[] => {
  switch (feature) {
    case AccessFeatures.REQUEST_REPORT:
      return [...FullAccessRoles];
    case AccessFeatures.IDEATION:
      return [...FullAccessRoles, UserRole.GUEST];
    case AccessFeatures.PUBLISHING_OVERVIEW:
      return [...FullAccessRoles, UserRole.GUEST];
    case AccessFeatures.CREATE_STUDIO:
      return [...FullAccessRoles];
    case AccessFeatures.EDIT_STUDIO:
      return [...FullAccessRoles];
    case AccessFeatures.ARCHIVE_STUDIO:
      return [...FullAccessRoles];
    case AccessFeatures.ADD_PAYMENT_DETAILS:
      return [...FullAccessRoles];
    case AccessFeatures.EDIT_PAYMENT_DETAILS:
      return [...FullAccessRoles];
    case AccessFeatures.GENERATE_INVOICE:
      return [...FullAccessRoles, UserRole.EXTERNAL];
    case AccessFeatures.SHOW_INVOICE:
      return [...FullAccessRoles, UserRole.EXTERNAL];
    case AccessFeatures.UPLOAD_INVOICE:
      return [...FullAccessRoles];
    case AccessFeatures.CREATE_GAME:
      return [...FullAccessRoles, UserRole.EXTERNAL];
    case AccessFeatures.EDIT_GAME:
      return [...FullAccessRoles, UserRole.EXTERNAL];
    case AccessFeatures.ARCHIVE_GAME:
      return [...FullAccessRoles, UserRole.EXTERNAL];
    case AccessFeatures.GAME_CONFIG:
      return [...FullAccessRoles, UserRole.EXTERNAL];
    case AccessFeatures.CREATE_CAMPAIGN:
      return [...FullAccessRoles, UserRole.EXTERNAL];
    case AccessFeatures.CREATE_CUSTOM_CAMPAIGN:
      return [...FullAccessRoles];
    case AccessFeatures.TERMINATE_CAMPAIGN:
      return [...FullAccessRoles, UserRole.EXTERNAL];
    case AccessFeatures.START_CAMPAIGN:
      return [...FullAccessRoles];
    case AccessFeatures.CAMPAIGN_ACTIVATION:
      return [...FullAccessRoles];
    case AccessFeatures.CTM_CAMPAIGN_ACTIONS:
      return [...FullAccessRoles];
    case AccessFeatures.REVIEW_INVOICE:
      return [...FullAccessRoles];
    case AccessFeatures.MONETIZATION:
      return [...FullAccessRoles];
    default:
      return [];
  }
};

export const hasPermission = (
  user: User | undefined,
  feature: AccessFeatures
) => {
  try {
    const allowedRoles = getRolesWithFeatureAccess(feature);
    return user && allowedRoles.includes(user.role);
  } catch {
    return false;
  }
};
