import styled from 'styled-components';

export const SidebarSectionHeader = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey[900]};
  font-family: 'Rubik', sans-serif;
  padding: 19px 12px;
  font-weight: bold;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.grey.white};
`;
