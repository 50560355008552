import styled from 'styled-components';

interface Props {
  type?: string;
}

export const SidebarLinkStatusText = styled.div<Props>`
  font-family: 'Rubik', sans-serif;
  color: ${props =>
    props.type === 'new'
      ? props.theme.colors.blue[500]
      : props.type === 'mvp'
      ? props.theme.colors.orange[500]
      : props.theme.colors.grey[900]};
  font-size: 10px;
  padding-left: 12px;
  padding-top: 2px;
`;
