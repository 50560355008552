import { useDeleteCreativePack, useFetchGame, useGetCreativePacks } from 'api';
import CreativePack from 'components/CreativePack/CreativePack';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { CreativePackType, IPMSortOrder } from 'types';
import { StyledTestCreativeLibrary } from './TestCreativeLibrary.styles';
import { CreativePackGenerator, Modal } from 'components';

interface TestCreativeLibraryProps {
  ipmSort: IPMSortOrder;
  hideUntested: boolean;
}

const TestCreativeLibrary = ({
  ipmSort,
  hideUntested,
}: TestCreativeLibraryProps) => {
  const params = useParams<{ gameId: string }>();
  const gameId = Number(params.gameId);
  const { data: game } = useFetchGame(gameId);
  const { data, refetch: refetchCreativePacks } = useGetCreativePacks(gameId);
  const { mutate: deletePack } = useDeleteCreativePack(gameId);
  const [creativePacks, setCreativePacks] = useState<CreativePackType[]>([]);
  const [showModal, setShowModal] = useState(false);

  const handleModal = () => setShowModal(!showModal);

  const handleOnSuccessCreativePack = () => {
    handleModal();
    refetchCreativePacks();
  };

  const handleDeleteCreativePack = (packId: number) => {
    deletePack(packId, {
      onSuccess: () => {
        refetchCreativePacks();
        toast.success('Video Pack was successfully deleted', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
    });
  };

  useEffect(() => {
    const ipmPacks: CreativePackType[] = [];
    const untestedPacks: CreativePackType[] = [];

    data.forEach((video: CreativePackType) =>
      !!video.ipm ? ipmPacks.push(video) : untestedPacks.push(video)
    );

    if (ipmPacks.length > 0) {
      ipmPacks?.sort((a: CreativePackType, b: CreativePackType) => {
        const first: number = !!a.ipm ? a.ipm.value! : 0;
        const second: number = !!b.ipm ? b.ipm?.value! : 0;
        return ipmSort === IPMSortOrder.ASC ? first - second : second - first;
      });

      if (!hideUntested) {
        setCreativePacks([...ipmPacks, ...untestedPacks.reverse()]);
      } else {
        setCreativePacks([...ipmPacks]);
      }
    } else {
      if (!hideUntested) {
        setCreativePacks([...untestedPacks.reverse()]);
      } else {
        setCreativePacks([]);
      }
    }
  }, [data, ipmSort, hideUntested]);

  const unityIdExists = !!game?.unityId;

  return (
    <>
      <StyledTestCreativeLibrary data-testid="test-creative-library">
        <button
          className="add-button"
          disabled={!unityIdExists}
          onClick={handleModal}
        >
          + Add New
        </button>
        <div className="grid-container">
          {creativePacks.map((creativePack: CreativePackType) => (
            <CreativePack
              key={creativePack.id}
              packData={creativePack}
              onPackDelete={() => handleDeleteCreativePack(creativePack.id)}
            />
          ))}
        </div>
      </StyledTestCreativeLibrary>
      <Modal isOpen={showModal} title="ADD CREATIVE PACK" onClose={handleModal}>
        <CreativePackGenerator onSuccess={handleOnSuccessCreativePack} />
      </Modal>
    </>
  );
};

export default TestCreativeLibrary;
