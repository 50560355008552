import { ReactNode, MouseEventHandler } from 'react';
import { Spacing } from 'assets/styles';
import { StyledLightButton } from './LightButton.styles';
import clsx from 'clsx';

interface LightButtonProps extends Spacing {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info'; // currently we're using just the 'primary'
  fullWidth?: boolean;
}

const LightButton = ({
  children,
  onClick,
  color = 'primary',
  fullWidth = false,
  ...spacing
}: LightButtonProps) => {
  return (
    <StyledLightButton
      onClick={onClick}
      className={clsx(color, { 'full-width': fullWidth })}
      {...spacing}
    >
      {children ?? 'Button'}
    </StyledLightButton>
  );
};

export default LightButton;
