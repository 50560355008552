import { useFetchInvoice } from 'api';
import { Modal } from 'components';
import {
  Controls,
  IconButton,
  ReviewModalContainer,
  ReviewModalHeader,
  StyledRejectButton,
} from '../InvoicesOverview.styles';
import ReviewListItems from './ListItems';
import { Button } from 'components/atoms';
import { Icon } from 'assets/styles';
import { downloadBlue } from 'assets/images';
import styled from 'styled-components';
import { round } from 'lodash';
import {
  formatInvoiceNumber,
  splitInvoicePattern,
} from 'components/organisms/GenerateInvoiceModal/InvoiceNumber';

interface InvoiceReviewModalProps {
  isOpen: boolean;
  modalTitle: string;
  onClose: () => void;
  downloadPDF: (invoiceId: number) => void;
  openApproveModal: (modalType: string) => void;
  studioId: number;
  invoiceId: number;
  vat: number;
}

const InvoiceReviewModal: React.FC<InvoiceReviewModalProps> = ({
  isOpen,
  onClose,
  modalTitle,
  studioId,
  invoiceId,
  downloadPDF,
  openApproveModal,
  vat,
}) => {
  const { data, isSuccess } = useFetchInvoice(studioId, invoiceId!);
  if (isSuccess) {
    const totalPrice = data.items.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );

    const invoiceTotal = () => {
      return round(totalPrice && (vat / 100) * totalPrice, 2);
    };

    const { patternText, patternHash } = splitInvoicePattern(data.pattern);
    return (
      <>
        {isSuccess && (
          <Modal isOpen={isOpen} title={modalTitle} onClose={onClose}>
            <ReviewModalContainer>
              <ReviewModalHeader>
                <span className="label">Invoice Number: </span>
                <span className="value">{`#${patternText}${formatInvoiceNumber(
                  patternHash.length,
                  data.number
                )}`}</span>
              </ReviewModalHeader>
              <div style={{ flex: 1 }}>
                {!!data.items && (
                  <ReviewListItems
                    currency={data.currencySymbol}
                    invoiceItems={data.items}
                  />
                )}
              </div>
              <SummaryBox>
                <TotalValueWrapper>
                  <ValuesWrapper>
                    {' '}
                    <Text>Sub Total: </Text>
                    <Value>{`${data.currencySymbol}${round(
                      totalPrice,
                      2
                    ).toLocaleString('en-US')}`}</Value>{' '}
                  </ValuesWrapper>
                  <ValuesWrapper>
                    {' '}
                    <Text>{`VAT (${vat}%):`}</Text>
                    <Value>{`${
                      data.currencySymbol
                    }${invoiceTotal().toLocaleString('en-US')}`}</Value>
                  </ValuesWrapper>
                  <ValuesWrapper>
                    {' '}
                    <Text>Invoice Total:</Text>
                    <Value>{`${data.currencySymbol}${(
                      round(totalPrice, 2) + invoiceTotal()
                    ).toLocaleString('en-US')}`}</Value>
                  </ValuesWrapper>
                </TotalValueWrapper>
              </SummaryBox>
              <Controls>
                <div>
                  <IconButton onClick={() => downloadPDF(invoiceId)}>
                    {' '}
                    <Icon
                      style={{ marginRight: '5px' }}
                      src={downloadBlue}
                      alt="download button"
                    />
                    Download
                  </IconButton>
                </div>
                <div>
                  <StyledRejectButton
                    onClick={() => {
                      openApproveModal('reject');
                    }}
                    color="#F066A7"
                  >
                    Reject Invoice
                  </StyledRejectButton>
                  <Button
                    onClick={() => {
                      openApproveModal('approve');
                    }}
                    style={{ backgroundColor: '#2DA1F8', color: '#fff' }}
                  >
                    Approve Invoice
                  </Button>
                </div>
              </Controls>
            </ReviewModalContainer>
          </Modal>
        )}
      </>
    );
  }
  return null;
};

export default InvoiceReviewModal;

const ValuesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;
const TotalValueWrapper = styled.div`
  min-width: 171px;
`;
const Text = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
`;
const Value = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
const SummaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 30px;
`;
