import { css } from 'styled-components';

const app = css`
  body {
    font-family: 'Rubik', sans-serif;
    background: ${({ theme }) => theme.colors.grey.offWhite};
  }

  #root {
    height: 100vh;
  }

  .body-login {
    background-color: ${({ theme }) => theme.colors.grey.white};
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
`;

export default app;
