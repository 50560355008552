import Tootltip from 'components/atoms/Tooltip/Tootltip';
import { ChartTitle } from '../Charts.styles';

export const chartTitleGenerator = (
  chartitle: string,
  tooltipTitle: string
) => (
  <ChartTitle>
    <span className="chart-title">{chartitle.charAt(0).toUpperCase() + chartitle.slice(1)}</span>
    <Tootltip title={tooltipTitle}>
      <span className="tooltip">(?)</span>
    </Tootltip>
  </ChartTitle>
);
