import { useEffect, useRef, useState } from 'react';
import {
  AccordionContainer,
  Header,
  ContentWrapper,
  Content,
  Arrow,
  HeaderText,
} from './CardsAccordion.styles';

interface CardsAccordionProps {
  title: string;
  children: React.ReactNode;
  isDisabled?: boolean;
}

const CardsAccordion: React.FC<CardsAccordionProps> = ({
  title,
  children,
  isDisabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    if (!isDisabled && !isOpen && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
      setIsOpen(!isOpen);
    } else if (!isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  return (
    <AccordionContainer>
      <Header isDisabled={isDisabled} onClick={toggleAccordion}>
        <HeaderText>{title}</HeaderText>
        <Arrow isOpen={isOpen} />
      </Header>
      <ContentWrapper isOpen={isOpen} contentHeight={contentHeight}>
        <Content ref={contentRef}>{children}</Content>
      </ContentWrapper>
    </AccordionContainer>
  );
};

export default CardsAccordion;
