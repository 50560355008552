import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.grey.white};
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  pointer-events: all;

  ${() =>
    window.innerHeight <= 720
      ? css`
          width: 850px;
          margin-top: 20px;
        `
      : css`
          width: 1000px;
          margin-top: 120px;
        `}
`;
