import styled, { css } from 'styled-components';

export const splitInvoicePattern = (pattern: string) => {
  const [patternText, patternHash] = pattern.split(
    /([!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)/
  );

  return {
    patternText: patternText || '',
    patternHash: patternHash || '',
  };
};

export function formatInvoiceNumber(hashLength: number, invoiceNumber: number) {
  let numStr = invoiceNumber?.toString();

  while (numStr?.length < hashLength) {
    numStr = '0' + numStr;
  }

  return numStr;
}

type InvoiceNumberProps = {
  invoiceNumber: number;
  setInvoiceNumber: React.Dispatch<React.SetStateAction<number | undefined>>;
  invoicePattern: string;
  usedInvoiceNumbers: number[];
};

const InvoiceNumber = ({
  invoiceNumber,
  setInvoiceNumber,
  invoicePattern,
  usedInvoiceNumbers,
}: InvoiceNumberProps) => {
  const { patternText, patternHash } = splitInvoicePattern(invoicePattern);

  const handleIncrement = () => {
    let newInvoiceNumber = invoiceNumber + 1;

    const usedInvoiceNumberSet = new Set(usedInvoiceNumbers);

    while (usedInvoiceNumberSet.has(newInvoiceNumber)) {
      newInvoiceNumber++;
    }

    setInvoiceNumber(newInvoiceNumber);
  };

  const handleDecrement = () => {
    let newInvoiceNumber = Math.max(invoiceNumber - 1, 1);

    const usedInvoiceNumberSet = new Set(usedInvoiceNumbers);

    while (usedInvoiceNumberSet.has(newInvoiceNumber) && newInvoiceNumber > 1) {
      newInvoiceNumber--;
    }
    setInvoiceNumber(newInvoiceNumber);
  };

  return (
    <StyledInvoiceNumber>
      <div className="text-container">
        {patternText}
        {formatInvoiceNumber(patternHash.length, invoiceNumber)}
      </div>
      <div>
        <StyledButton onClick={handleDecrement}>-</StyledButton>
        <StyledButton borderLeft onClick={handleIncrement}>
          +
        </StyledButton>
      </div>
    </StyledInvoiceNumber>
  );
};

export default InvoiceNumber;

const StyledInvoiceNumber = styled.div`
  display: flex;
  align-items: center;
  width: 220px;
  height: 25px;
  border-radius: 4px;
  margin-left: 10px;
  border: 1px solid ${props => props.theme.colors.blue[500]};

  .text-container {
    flex: 1;
    padding: 0 10px;
    color: ${props => props.theme.colors.grey[700]};
  }
`;

interface StyledButtonProps {
  borderLeft?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  background: none;
  border: none;
  color: ${props => props.theme.colors.blue[500]};
  font-size: 18px;

  ${props =>
    props.borderLeft &&
    css`
      border-left: 1px solid #eeeeee;
    `};
`;
