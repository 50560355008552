import { ChangeEvent, useRef, useState } from 'react';
import { CreativePackType } from 'types';
import { MediaBadge, MediaRenderer } from 'components';
import { Spacing } from 'assets/styles';
import {
  Button,
  PopupContent,
  PopupTitle,
  StyledCreativePack,
} from './CreativePack.styles';
import clsx from 'clsx';
import Media from './Media';
import { RiDeleteBin6Line } from 'react-icons/ri';
import MediaGalleryModal from 'components/organisms/MediaGalleryModal';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Popup } from 'components/atoms';
import { useTheme } from 'styled-components';

interface CreativePackProps extends Spacing {
  packData: CreativePackType;
  onPackDelete?: (packId: number) => void;
  onPackSelect?: (packId: number, isSelected: boolean) => void;
  onMediaClick?: (packId: number, mediaId: number) => void;
  className?: string;
  disableSelect?: boolean;
}

const CreativePack = ({
  packData,
  onPackSelect,
  onPackDelete,
  onMediaClick,
  className,
  disableSelect = false,
  ...spacing
}: CreativePackProps) => {
  const theme = useTheme();
  const { id: packId, name, medias, ipm } = packData;
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handlePackSelect = (
    event: ChangeEvent<HTMLInputElement>,
    packId: number
  ) => {
    const isChecked = event.target.checked;
    onPackSelect && onPackSelect(packId, isChecked);
  };

  const handlePackDelete = () => setShowPopup(true);

  // Media Gallery Items
  const galleryItems: ReactImageGalleryItem[] = [];
  const image: ReactImageGalleryItem = {
    original: packData.medias.unityImage?.url!,
    thumbnail: packData.medias.unityImage?.thumbnail,
    description: 'img',
  };
  galleryItems.push(image);

  if (!!packData.medias.unityPortraitVideo) {
    const portrait: ReactImageGalleryItem = {
      original: packData.medias.unityPortraitVideo?.url!,
      thumbnail: packData.medias.unityPortraitVideo?.thumbnail,
    };
    galleryItems.push(portrait);
  }

  if (!!packData.medias.unityLandscapeVideo) {
    const landscape: ReactImageGalleryItem = {
      original: packData.medias.unityLandscapeVideo?.url!,
      thumbnail: packData.medias.unityLandscapeVideo?.thumbnail,
    };
    galleryItems.push(landscape);
  }

  const popupRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <StyledCreativePack
        ref={popupRef}
        className={clsx(className)}
        {...spacing}
      >
        <header className="header">
          {onPackSelect && (
            <input
              disabled={disableSelect}
              type="checkbox"
              className="cursor-pointer"
              id={name + packId}
              onChange={event => handlePackSelect(event, packId)}
            />
          )}
          <label
            className={clsx('name', { 'cursor-pointer pl-12': onPackSelect })}
            title={name}
            htmlFor={name + packId}
          >
            {name}
          </label>
          <div className="badge">
            <MediaBadge ipm={ipm} />
          </div>
          {onPackDelete && (
            <button className="delete" onClick={handlePackDelete}>
              <RiDeleteBin6Line />
            </button>
          )}
        </header>
        <section className={clsx('body', { disable: !!onPackSelect })}>
          <Media
            type="landscape"
            media={medias.unityLandscapeVideo}
            onClick={() => setShowModal(true)}
          />
          <Media
            type="square"
            media={medias.unityImage}
            onClick={() => setShowModal(true)}
          />
          <Media
            type="portrait"
            media={medias.unityPortraitVideo}
            onClick={() => setShowModal(true)}
          />
        </section>
        {showPopup && (
          <Popup
            visible={showPopup}
            anchor={popupRef}
            onClose={() => setShowPopup(false)}
            containerStyle={{
              borderRadius: '4px',
              padding: '20px',
              width: '200px',
              height: '150px',
              top: '40px',
              right: '0px',
            }}
          >
            <PopupTitle>Do you want to delete this creative pack?</PopupTitle>
            <PopupContent>
              <Button
                color={theme.colors.pink[500]}
                onClick={e => {
                  e.stopPropagation();
                  onPackDelete && onPackDelete(packId);
                }}
              >
                Yes
              </Button>
              <Button
                color={'darkgray'}
                onClick={e => {
                  e.stopPropagation();
                  setShowPopup(false);
                }}
              >
                No
              </Button>
            </PopupContent>
          </Popup>
        )}
      </StyledCreativePack>
      <MediaGalleryModal open={showModal} onClose={() => setShowModal(false)}>
        <ImageGallery
          items={galleryItems}
          startIndex={0}
          showPlayButton={false}
          showFullscreenButton={false}
          additionalClass="gallery-modal"
          renderItem={MediaRenderer}
        />
      </MediaGalleryModal>
    </>
  );
};

export default CreativePack;
