import { useCreateLogo } from 'api';
import { EmptyLogoSquare } from 'components/AddGame/AddGame.styles';
import { LogoUploadWrapper } from 'components/AddStudioModal/AddStudioModal.styles';
import { Button, ErrorPopup } from 'components/atoms';
import { ChangeEvent, useRef, useState } from 'react';
import { useGameWizard } from 'store';
import styled from 'styled-components';
import {
  isImageDimensionsValid,
  isImageFormatValid,
  isImageSizeValid,
} from 'utils';

const validImage = {
  format: ['image/jpeg', 'image/png'],
  size: 1024, // KB
  dimensions: [512, 512], // Pixel
};

interface LogoProps {
  isButtonDisabled: boolean;
}

const Logo = ({ isButtonDisabled }: LogoProps) => {
  const { basicDetails, setBasicDetails } = useGameWizard(state => state);
  const { mutate: uploadLogo } = useCreateLogo('/games/logo');
  const [isUploading, setIsUploading] = useState(false);
  const [apiError, setApiError] = useState<string>('');
  const [uiSideError, setUiSideError] = useState<string[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const uploadLogoButtonRef = useRef<HTMLButtonElement>(null);

  const resetPopupError = () => {
    setApiError('');
    setUiSideError([]);
  };

  const postLogo = (logo: File) => {
    setIsUploading(true);
    const reqBody = new FormData();
    reqBody.append('logo', logo);

    uploadLogo(reqBody, {
      onSuccess: (res: any) => {
        setBasicDetails({ ...basicDetails, logoUrl: res.url });
        setIsUploading(false);
      },
      onError: (error: any) => {
        setApiError(error.message);
        setIsUploading(false);
      },
    });
  };

  const onErrorPopupClose = () => {
    resetPopupError();
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  const handleLogoChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const logo = event.target.files[0];

    const isFormatValid = isImageFormatValid(logo, validImage.format);
    const isSizeValid = isImageSizeValid(logo, validImage.size);
    const isDimensionsValid = await isImageDimensionsValid(
      logo,
      validImage.dimensions
    );

    const areAllValid = isFormatValid && isSizeValid && isDimensionsValid;

    if (areAllValid) postLogo(logo);
    else {
      if (!isFormatValid) setUiSideError(prev => [...prev, 'format']);
      if (!isSizeValid) setUiSideError(prev => [...prev, 'size']);
      if (!isDimensionsValid) setUiSideError(prev => [...prev, 'dimensions']);
    }
  };

  return (
    <LogoUploadWrapper>
      <EmptyLogoSquare url={basicDetails.logoUrl} />
      <div className="upload-button-container">
        <LogoRequirements>
          Format: png or jpg
          <br />
          Max dimensions: 512 x 512 px
          <br />
          Max file size: 1 MB.
        </LogoRequirements>
        <input
          type="file"
          accept={validImage.format.join(',')}
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={handleLogoChange}
        />

        <Button
          type="button"
          disabled={isUploading || isButtonDisabled}
          ref={uploadLogoButtonRef}
          onClick={() => inputRef.current?.click()}
        >
          Upload Logo
        </Button>
      </div>
      <ErrorPopup
        visible={uiSideError.length > 0 || apiError !== ''}
        anchor={uploadLogoButtonRef}
        onClose={onErrorPopupClose}
        title={'The file has not been uploaded!'}
      >
        <ErrorPopupDescription>
          {uiSideError.length > 0
            ? `The file has an incorrect ${uiSideError.join(', ')}.`
            : apiError}
        </ErrorPopupDescription>
      </ErrorPopup>
    </LogoUploadWrapper>
  );
};

export default Logo;

const LogoRequirements = styled.div`
  color: ${({ theme }) => theme.colors.grey[900]};
  text-align: center;
  line-height: 1.4;
  font-size: 12px;
  margin: 12px 0;
`;

const ErrorPopupDescription = styled.div`
  font-size: 12px;
  margin-top: 12px;
`;
