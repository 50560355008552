import { spacing, Spacing } from 'assets/styles';
import styled from 'styled-components';

export const StyledTagModifier = styled.div<Spacing>`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  position: relative;
  ${spacing};

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .flex-extend {
    flex: 1;
  }

  .existing-tags {
    font-weight: 500;
  }

  .tags-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  .input-wrapper {
    width: 350px;
    height: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }

  .input {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.grey[1000]};
    outline: none;
    font-size: 14px;
    padding: 8px 16px;

    ::placeholder {
      color: #b4b4b4;
    }
  }

  .popup {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 0 4px 2px rgba(218, 218, 218, 0.5);
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 100%;
    margin: 8px 0;
  }

  .no-result {
    color: ${({ theme }) => theme.colors.grey[1200]};
    font-weight: bold;
    font-size: 14px;
  }
`;
